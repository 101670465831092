import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './multiSplitSytems.css'

import multisplit from '../../../img/multisplit_.jpg'
import multi_split from '../../../img/multi_split(1).jpg'

const MultiSplitSytems = () => {
    return (
        <>
            <div className='multi-split-systems'>
                <h1>Мульти сплит-системы</h1>
                <p>Мульти сплит-систему Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку мульти сплит-систем по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Мульти сплит-система - это такая сплит-система, в которой с одним внешним блоком работает не один, а сразу несколько внутренних.</p>
                <p>При этом внутренние блоки могут быть не только разной мощности (обычно от 2 до 5 кВт), но и разных типов: настенные, кассетные, канальные, напольные, напольно-потолочные и подпотолочные, которые великолепно впишутся в любой, даже самый изысканный интерьер.</p>

                <h2>Особенности мульти-сплит систем</h2>
                <p>Каждый внутренний блок поддерживает заданную температуру независимо от других и имеет свой собственный пульт дистанционного управления.</p>
                <p>Мульти сплит-системы одновременно могут охлаждать или нагревать воздух в разных комнатах в зависимости от индивидуального режима работы, набранного на пульте ДУ.</p>
                <p>Каждый компрессор обслуживает свой внутренний блок или группу блоков и имеет свой независимый холодильный контур.</p>
                <img src={multisplit} alt='' />
                <p>Конденсаторы обоих контуров выполнены в виде единого теплообменного блока, обдуваемого одним вентилятором, но с раздельной циркуляцией фреона. Поэтому внутренние блоки (группы блоков) могут включаться и работать в разных режимах независимо друг от друга. Есть только одно ограничение - всё должно работать либо на охлаждение, либо на обогрев.</p>
                <p>Повышенное энергосбережение и комфорт обеспечивают компрессоры наружного блока с инверторным управлением. Практически все ведущие производители климатического оборудования выпускают мульти сплит-системы.</p>
                <p>Благодаря конструкции эти кондиционеры обладают целым рядом достоинств, среди которых высокая эффективность, низкий уровень шума, свобода выбора места расположения и типа внутреннего блока.</p>
                <p>Мульти сплит-система хорошо подходит для кондиционирования квартиры, или небольшого коттеджа, реже применяется для кондиционирования офиса. При больших объемах приходится увеличивать количество систем (и наружных блоков на фасаде), или переходить к более крупным и мощным системам.</p>
                <p>Мульти-сплит кондиционеры являются наиболее подходящим вариантом в тех случаях, когда размещение на фасаде здания большого количества внешних блоков нежелательно и позволяет сохранить архитектурный облик зданий.</p>
                <p>Вопреки распространенному мнению, замена нескольких сплит систем на одну мульти сплит систему не приводит к выигрышу в цене, поскольку стоимость оборудования примерно такая же, а трудоемкость и стоимость монтажа — в 1,5 - 2 раза выше из-за более длинных коммуникаций. Кроме этого, при выходе из строя внешнего блока мульти-сплит системы перестают работать все внутренние блоки — с этой точки зрения надежность нескольких сплит систем выше.</p>
                <p>Поэтому мульти-сплит системы обычно используют только при невозможности размещения нескольких внешних блоков на наружной стене дома.</p>
                <img src={multi_split} alt='' />
                <p>Мульти-сплит система не требует сложного дорогого технического обслуживания. Сервисное обслуживание подобно обслуживанию обычной сплит-системы, и заключается в чистке теплообменников наружного и внутренних блоков, замере давления фреона в системе, чистке дренажных трубопроводов, замене фильтров. Система не требует регулярной дозаправки фреоном, если монтажные работы проведены по технологии, утечки фреона исключены, первоначальная заправка рассчитана на весь срок службы системы.</p>
                <p>Мульти сплит-системы разумно использовать в том случае, когда стоит задача кондиционировать несколько соседних помещений, а если нужно создать комфорт на целом здании или на всем этаже разумно использовать VRV (VRF) - системы.</p>

                <h2>Автоматизация (централизованное управление)</h2>
                <p>Мульти сплит-система может быть оснащена центральным управлением внутренними блоками:</p>
                <ul>
                    <li>Централизованное управление осуществляется специальным контроллером;</li>
                    <li>При централизованном управлении блоки могут включаться/выключаться индивидуально или все вместе;</li>
                    <li>К одному контроллеру может подсоединяться до 16 блоков;</li>
                    <li>Контроллеры могут соединяться в цепочку до 8 контроллеров.</li>
                </ul>
                <p>Мульти сплит-систему Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку мульти сплит-систем по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default MultiSplitSytems