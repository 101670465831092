import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './airConditioningResidentialBuildings.css'

const AirConditioningResidentialBuildings = () => {
    return (
        <>
            <div className='air-conditioning-residential-buildings-page'>
                <h1>Кондиционирование многоэтажных жилых зданий</h1>
                <p>Систему кондиционирования для жилого здания Вы можете заказать, позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Кондиционирование жилых комплексов мультизональной системой VRV</h2>
                <p><strong>Элитное здание</strong> обычно объединяет под своей крышей людей состоятельных, справедливо претендующих на уровень комфорта, соответствующий высокой стоимости квартир. При семизначных числах, фигурирующих в переговорах риэлторов, такие детали, как спутниковое ТВ, выделенный интернет или система кондиционирования воздуха даже не обсуждаются — все должно быть включено в стоимость. Вместе с тем, каждая из семей, заселяющих дом, имеет свои представления о системах, обеспечивающих комфортные климатические условия в их квартирах.</p>
                <p>Иногда диаметрально противоположные пожелания могут быть отнесены к дизайну внутренних устройств, особенностям их расположения (включая их полное визуальное отсутствие), к регулированию климатических параметров. Возможно, что кто-то из обитателей дома предпочитает индивидуальное поддержание комфортных условий в каждой комнате, а кому-то, наоборот, вполне достаточно общего кондиционирования с одним пультом управления для всей квартиры. Не исключено, что некоторые из жильцов не захотят иметь систему кондиционирования вообще и будут против, если им ее будут навязывать, включая в стоимость квартиры.</p>
                <p>Требования к климатическим системам причудливо переплетаются с представлениями каждого владельца квартиры об их стоимости. Сугубо индивидуальные и, порой, трудновыполнимые пожелания жильцов при реализации таких систем встречают ряд объективных ограничений, таких, например, как невозможность размещения наружных устройств на фасадах здания и допустимый предел электрической мощности.</p>
                <p>Если покупатель квартиры все-таки оказался счастливым обладателем центральной системы кондиционирования, то скорее всего он будет отстаивать свое право оплачивать счета за электроэнергию, потребляемую этой системой в соответствии с затраченными на его квартиру мощностями, а не оплачивать фиксированную ежемесячную сумму независимо от того, пользовался ли он кондиционером или нет.</p>
                <p>Маловероятно, чтобы обитатели элитного дома предпочли бы обслуживать кондиционеры своими силами, а не подключились бы к централизованной системе, которая осуществляла мониторинг, дистанционное выявление неисправностей и многое другое, что может дать центральная система диспетчеризации.</p>
                <p>Помимо жильцов, еще одним звеном, заинтересованным в системе кондиционирования, является застройщик, который принимает решение о концепции инженерных сетей здания. Для застройщика, возможно, наиболее выгодно было бы не иметь капитальных затрат на устройство центральной системы кондиционирования при строительстве, а лишь обеспечить возможность установки климат-систем в здании по желанию владельцев квартир.</p>
                <p>В настоящее время удалось адаптировать для российского рынка уникальную концепцию DAIKIN по кондиционированию жилых комплексов (кондоминимумов) с помощью оригинальной разработки компании — центральной интеллектуальной системы Hi-VRV.</p>
                <p>Согласно данной концепции, застройщик и продавец жилья освобождаются от всех отношений и проблем с владельцами квартир по поводу реализации системы кондиционирования воздуха. Конструктивные особенности интеллектуальной системы DAIKIN Hi-VRV таковы, что капитальных затрат на этапе строительных работ и финансовых вложений на первоначальном этапе реализации почти не требуется.</p>
                <p>Будущий владелец квартиры не сталкивается с проблемой оплаты уже проведенного без его ведома «рукава" центральной системы кондиционирования и фанкойла. К квартире подводятся необходимые коммуникации (медные трубки, кабели), к которым владелец может подключать (или не подключать без ущерба для соседей) свой внутренний блок или блоки системы VRV. Эти вопросы он решает уже с поставщиком и установщиком оборудования, которая действует с учетом взаимовыгодного сотрудничества с продавцом жилья.</p>
                <p>Каждый владелец квартиры сам определяет выбор комфортных индивидуальных условий в помещениях и покупает оборудование по своему вкусу. Стоимость системы кондиционирования "под ключ", в зависимости от выбора владельца, может колебаться в широких пределах от 70 до 250 долларов за 1м2. Реализация модуля системы кондиционирования для произвольной квартиры возможна на любом этапе готовности здания и занимает две недели.</p>
                <p>Что касается экономичности системы VRV, то стоимость ее эксплуатации в 2–3 раза меньше энергозатрат обычной бытовой сплит-системы. Если сравнивать ее с прежним "чемпионом экономичности" — центральной чиллерной установкой — то VRV через 5 лет эсплуатации становится дешевле на 13% общей стоимости, включающей инсталляцию, обслуживание и энергопотребление.</p>
                <p>Центральная система кондиционирования Hi-VRV поддерживает актуальные на сегодняшний день стандарты «интеллектуального здания". Согласно этим принципам, все элементы инженерного оборудования квартир и других помещений объединяются в единую центральную систему диспетчеризации, диагностики и управления. Наряду с остальными системами, такими, как освещение, лифты, противопожарные установки, кондиционеры Hi-VRV, посредством компютерной системы D-BACS подключены к центральному пульту управления зданием BMS. "Конвертация" в общую систему управления осуществляется с помощью протокола BACnet.</p>

                <h2>Благодаря центральному управлению системой кондиционирования, владелец квартиры получает множество новых преимуществ.</h2>
                <ul>
                    <li><strong>Во-первых</strong>, забота об обслуживании кондиционера и контроль за его работой автоматически переходят в компетенцию специальной сервисной службы, которая, осуществляя дистанционный мониторинг системы, сама знает, когда ей нужно сменить фильтр или устранить причину отклонений в работе.</li>
                    <li><strong>Во-вторых</strong>, задачу программирования климатических параметров обитатели квартир также могут "переложить на плечи" диспетчера, просто сообщив ему свою любимую температуру — и забыть об управлении кондиционером хоть на месяц, хоть на год.</li>
                    <li><strong>И, в-третьих</strong>, индивидуальный подсчет энергозатрат дает возможность четко отслеживать и регулировать потребление кондиционером электричества в зависимости от необходимости. Уезжая в отпуск, владелец квартиры всегда может отключить свой кондиционер, приехав — вновь подключиться к центральной системе VRV, ощутив преимущества непривычной для классических чиллерных систем оперативности.</li>
                </ul>
                <p><strong>Компактность системы VRV</strong> зачастую является приятным сюрпризом для застройщика. Уже расставшийся с мыслями о подземном гараже из-за необходимости куда-то ставить чиллер, он вдруг выясняет, что есть выгодная альтернатива, позволяющая использовать технические площади по другому назначению. Все машинное оборудование системы кондиционирования VRV может быть размещено на плоской кровле здания.</p>
                <p>Представляется, что предлагаемая вниманию застройщика и жильцов центральная интеллектуальная система кондиционирования Hi-VRV фирмы является наиболее оптимальным решением по созданию индивидуальных комфортных условий для требовательных владельцев.</p>
                <p>Систему кондиционирования для жилого здания Вы можете заказать, позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default AirConditioningResidentialBuildings