import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { menu } from '../../redux/actions/actions'
import Form from '../form/Form'
import './aboutCompany.css'

const AboutCompany = () => {

    const dispatch = useDispatch()
    const changeMenu = (name) => {
        dispatch(menu(name))
    }

    return (
        <>
            <div className='about-company-component'>
                <h1>О компании</h1>
                <p>Компания "FIDCLIMAT" нацелена на предоставление своим клиентам полного спектра услуг, связанных с проектной разработкой, внедрением и последующей эксплуатацией систем кондиционирования, вентиляции, тепло/холодоснабжения, включая автоматизацию и диспетчеризацию инженерных систем.</p>

                <Link to='/' onClick={() => changeMenu('cond')}>
                    <p>Кондиционирование</p>
                </Link>
                <p>Кондиционеры, сплит-системы, мульти сплит-системы, мультизональные VRV и VRF системы, чиллер-фанкойлы, центральные кондиционеры, прецизионные кондиционеры, управление и интеграция систем.</p>

                <Link to='/ventilation' onClick={() => changeMenu('vent')}>
                    <p>Вентиляция</p>
                </Link>
                <p>Энергосберегающая приточно-вытяжная вентиляция, раздельная регулировка подаваемого воздуха, аспирация, системы дымоудаления, чистые помещения, воздушное отопление, управление вентиляцией.</p>

                <Link to='/automation' onClick={() => changeMenu('avto')}>
                    <p>Автоматика</p>
                </Link>
                <p>Автоматика "FIDCLIMAT" - это легкое, эффективное управление параметрами воздуха, позволяющее: оптимизировать работу оборудования, управлять работой климатических систем через Интернет и интегрировать инженерное оборудование в систему управления интеллектуального здания BMS (Building Management System). </p>
                <p><strong>Сервисный отдел</strong> нашего центра состоит из высококвалифицированных бригад, укомплектованных  профессиональным инструментом и высокотехнологичным оборудованием для осуществления ремонта и обслуживания сплит систем в кратчайшие сроки.</p>
                <p>Монтажный отдел представляет собой десять собственных  монтажных бригад, оснащенных самым современным оборудованием для установки кондиционеров и для осуществления граммотного проведения пуско-наладочных работ климатической техники. Все инженеры центра аккредитованны ведущими производителями климатических компаний на территории РФ!</p>

                <h2>Гарантия</h2>
                <p>Компания <strong>«FIDCLIMAT»</strong> является официальным дилером крупнейших производителей климатической техники на территории Узбекистан. Все оборудование, поставляемое компанией <strong>«FIDCLIMAT»</strong>, подлежит гарантийному обслуживанию.</p>
                <p>Гарантия производителя определяется для каждой группы оборудования и составляет от 1 до 5 лет.</p>
                <p>Все гарантийные работы выполняются в специализированных сервисных центрах. При необходимости сервисные специалисты выезжают на объект для ремонта оборудования на месте.</p>
                <p>Стандартная гарантия на монтаж и пусконаладочные работы, выполненные инженерами компании <strong>«FIDCLIMAT»</strong>, составляет 1 год.</p>

                <p><strong>Международный стандарт контроля качества.</strong></p>

                <p><strong>Допуск к объектам капитального строительства.</strong></p>
                <p>«FIDCLIMAT» является действующим членом СРО ЭкспертСтрой и имеет допуск к работам, оказывающим влияние на безопасность объектов капитального строительства.</p>

                <p><strong>Лицензия МЧС.</strong></p>
                <p>Допуск к работам по монтажу, техническому обслуживанию и ремонту средств обеспечения пожарной безопасности зданий и сооружений.</p>

                <p><strong>Участник Ассоциации Предприятий Индустрии Климата.</strong></p>
                <p>Ассоциация является гарантом надежности компании. Наши специалисты регулярно проходят курсы повышения квалификации, что подтверждается сертификатами и дипломами.</p>

                <p><strong>Дополнительная гарантия на оборудование и работы.</strong></p>
                <p>Компания предоставляет дополнительную гарантию на промышленное и полупромышленное оборудование, а также на работы по его монтажу, при соблюдении регламента технического обслуживания.</p>
            </div>

            <Form />
        </>
    )
}

export default AboutCompany