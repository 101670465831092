import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img10 from '../../../img/21-05-2014 19-10-53.jpg'
import img50 from '../../../img/21-05-2014 23-50-06.jpg'
import img44 from '../../../img/21-05-2014 23-49-44.jpg'
import img14 from '../../../img/14040222-05-2014-1-04-16.jpg'

const OfficeVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция в офисах</h1>
                <p>Вентиляцию в офис Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции для офисов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Умственная работоспособность сотрудников компании,  их желание трудиться в полную силу напрямую зависит от качества воздуха в офисных помещениях, решать эти проблемы призваны системы вентиляции и кондиционирования воздуха. Существует множество вариантов решения проблем качества воздуха, однако, лишь немногую их часть можно назвать эффективными.</p>
                <p>Эффективность принятых решений зависит от опыта и квалификации проектировщика. Непосредственное влияние на принятие тех или иных проектных решений оказывают множество нюансов, свойственных лишь конкретному объекту, поэтому двух одинаковых решений, пожалуй, нет и быть не может.</p>
                <p>Вентиляция офисных зданий требует решения нескольких задач в зависимости от типа помещения:</p>
                <ul>
                    <li>Вентиляция помещений рабочих мест;</li>
                    <li>Вентиляция комнаты переговоров и конференц-залов;</li>
                    <li>Вентиляция курительных и сан.узлов;</li>
                    <li>Вентиляция столовой/кафе/ресторана;</li>
                    <li>Вентиляция серверных;</li>
                    <li>Система дымоудаления.</li>
                </ul>
                <p>Ниже рассмотрим примеры проектирования и создания систем вентиляции для офисов с различной площадью.</p>

                <h2>Вентиляция помещений рабочих мест</h2>
                <p>Чистый воздух является одним из важнейших условий существования жизни как таковой. Однако в воздухе всегда содержатся примеси, количество которых зависит от многих причин. Для снижения загрязненности наружного воздуха принимаются различные меры. И в то же время для повышения качества воздуха в офисных помещениях делается очень немного, несмотря на то, что во всех частях света большую часть времени люди проводят в помещении. К примеру, жители Северной Европы проводят в помещении до 90 % времени.</p>
                <p>Ничего лишнего - только воздух...</p>
                <p>Воздух в помещении офиса изначально загрязнен примесями, содержащимися в наружном воздухе - летучими испарениями, выделяемыми строительными материалами, машинами, людьми, животными и прочими источниками загрязнения, находящимися в самом помещении. Современные здания обычно отличаются плотной изоляцией, поэтому внутри быстро накапливаются загрязняющие вещества, если для их удаления не используются специальные системы.</p>
                <p>Где бы ни находились люди, при вдыхании очищенного воздуха их самочувствие и работоспособность улучшаются. Результаты исследований показывают, что с улучшением вентиляции в офисе уменьшается количество заболеваний (а, значит, и отпусков по болезни) среди персонала.</p>
                <img src={img10} alt='' className='img-width' />
                <p>Быстрыми темпами растет количество людей, страдающих различными видами аллергии. Даже современные медицинские исследования не в состоянии объяснить причину столь широкого распространения этого заболевания. Остановить аллергию можно лишь избавив человека от возможности вдыхать аллерген, поэтому очень важным фактором является внутренняя среда помещения – это признано всеми. Грамотно подобранная и установленнная система вентиляции - настоящее спасение от аллергенной пыльцы.</p>

                <h2>Требования к качеству воздуха</h2>
                <p>Требования к качеству воздуха в административных помещениях занижены. Это становится особенно заметно, например, когда в конференц-зале, рассчитанном на десять человек, на пару часов собирается пятнадцать человек. В этом случае воздух быстро становится мало пригодным для дыхания, а самочувствие присутствующих ухудшается. Очень часто в кабинете, рассчитанном на троих, размещается четыре работника. Таких примеров огромное множество.</p>
                <p>Нормы воздухообмена</p>
                <p>При выборе способа вентиляции офиса, кабинета учитывают размер и планировку помещения, количество офисного персонала и техники которое работает в офисе, технические и экономические возможности и основываются при расчетах на СНиП.</p>

                <p>Существуют и другие важные нормы, которые учитываются при расчете и проектировании:</p>
                <ul>
                    <li>минимальное количество свежего воздуха на одного человека рассчитывается 60 м3;</li>
                    <li>подачу воздуха производится непосредственно в административное помещение: кабинет, офис, конференц-зал;</li>
                    <li>для административного помещения с площадью менее 35м2 удаление воздуха можно осуществить перемещением его в общее помещение (коридор, пролет), а при превышении 35м2 воздух должен удаляться непосредственно из помещения;</li>
                    <li>вытяжная вентиляция естественного типа может применяться лишь для зданий с высотой не более 3 этажей и с количеством персонала не превышающим 300 человек;</li>
                    <li>запрещается применение централизованной рециркуляции воздуха в системах вентиляции кабинетов, офисов и других служебных помещений;</li>
                    <li>отдельные (самостоятельные) системы приточной вентиляции проектируются для конференц-залов с механическим побуждением.</li>
                </ul>

                <h2>Оборудование для офиса</h2>
                <ul>
                    <li>Моноблочные приточные установки</li>
                    <li>Индивидуальные приточные установки</li>
                    <li>Индивидуальные приточно-вытяжные установки</li>
                    <li>Канальные приточные установки</li>
                    <li>Приточно-вытяжные установки с рекуперацией тепла</li>
                    <li>Наборные системы вентиляции</li>
                    <li>Вентиляция на базе канального кондиционера</li>
                </ul>
                <p>Любая вентиляция, какой бы совершенной она не была, имеет свои достоинства и недостатки. В первую очередь важны размеры устройства, которое будет очищать воздух и наполнять его влагой. Как правило, само пространство офиса, его конфигурация влияют на выбор вентиляционного оборудования. Также важно, чтобы смонтированная система  полную обеспечивала обработку воздуха: фильтрацию, подогрев, охлаждение, увлажнение, а также равномерно распределяла очищенный воздух по всем кабинетам и помещениям административного здания (офиса). Как правило, вся конструкция крепится на потолке. Воздуховоды тоже располагаются в подвесных скрытых потолках либо в особых контейнерах называемых фальшбалками.</p>

                <h2>Вентиляция комнаты переговоров и конференц-залов</h2>
                <p>Вентиляцию конференц-залов, выставочных залов, зрительных залов, актовых залов можно объединить в одну группу.</p>
                <p>Вентиляция конференц-залов отличается дискретностью и периодичностью своей работы. Она работает максимально интенсивно во время проведения мероприятий, когда человеческая нагрузка усиливается, а в остальное время использует минимум мощности или выключена вовсе, в зависимости от характера использования помещения. Система кондиционирования конференц-залов должна быть рассчитана на большие площади, а действие равномерно распределено по периметру, чтобы не вызывать дискомфорта у сидящих в непосредственной близости людей.</p>
                <img src={img50} alt='' className='img-width' />
                <p>Примечание:</p>
                <p>Для переговорных комнат и конференц-залов регулирование воздухообмена осуществляется по концентрации углекислого газа по сигналу датчика, устанавливаемого в вытяжном воздуховоде. В залах со сценой воздухообмен определяется по расчету, но не менее 20 м3/ч наружного воздуха на 1 зрителя.</p>
                <p>Воздухообмен в помещениях основного и вспомогательного назначения организуется по схеме сверху-вверх, а в конференц-залах сверху-вниз-вверх или сверху-вниз. Установлено, что эффективному действию систем организации воздухообмена помещений способствуют малые дозы рассредоточенных потоков приточных и противоположно размещенных в объеме помещения вытяжных устройств.</p>
                <img src={img44} alt='' className='img-width' />
                <p>При расчёте индивидуальной схемы вентиляции конференц-зала учитываются параметры помещения: высота, ширина, длина. Исходя из полученного объёма, архитектурных особенностей здания, максимальной загрузки залов для конференций, наличия проекционного и презентационного оборудования - выбирается наиболее подходящее вентиляционное оборудование. Применение, дефлекторов, заслонок и диффузоров в вентиляционных каналах разводки коллектора обеспечивает равномерность воздухообмена и сокращает расходы по кондиционированию и отоплению в залах для проведения конференций и семинаров.</p>

                <h2>Вентиляция курительных и санитарных узлов</h2>
                <p>Корректный расчет систем вентиляции помещений, отведенных для курения, должен преследовать следующие цели:</p>
                <ul>
                    <li>предотвращение распространения дыма за пределы участка, отведенного для курения;</li>
                    <li>удаление табачного дыма максимально быстро и эффективно;</li>
                    <li>предотвращение перетекания загрязняющих веществ с рециркуляционным воздухом;</li>
                    <li>обеспечение качества воздуха и концентрации загрязняющих веществ не худших, чем в помещении без отведенного места для курения;</li>
                    <li>обеспечение наилучшего качества воздуха и минимальной концентрации загрязняющих веществ непосредственно в зоне для курения из тех, которые можно обеспечить имеющимися технологическими средствами.</li>
                </ul>

                <h2>Вытесняющая вентиляция - идеальное решение!</h2>
                <p>По совокупности имеющихся данных наиболее целесообразной следует признать вытесняющую вентиляцию.</p>
                <p>Эта технология предусматривает подачу приточного воздуха в нижнюю зону помещения на уровне пола через специальные воздухораспределители с ограниченной скоростью подачи воздуха. Поскольку табачный дым имеет температуру существенно более высокую, чем температура воздуха в помещении, он естественным образом устремляется вверх, стратифицируясь на определенной высоте (там, где образуется участок температурного равновесия). Поднимающаяся вверх однородная масса свежего воздуха выталкивает табачный дым к воздухозаборным устройствам, расположенным в верхней зоне помещения, по возможности не создавая завихрений во избежание перемешивания воздушных масс. Таким образом, осуществляется непрерывная вентиляция помещения с эффективным очищением воздуха от табачных загрязнителей.</p>
                <p>Чтобы предотвратить перетекание табачного дыма в зону для некурящих, необходимо поддерживать на участке для курящих пониженное давление не менее чем на 5 Па, для чего вытяжная установка должна работать с большей мощностью, чем приточная. В идеале следует предусмотреть двойные двери с междверным компенсационным тамбуром — это единственный эффективный барьер, предотвращающий перетекание загрязняющих веществ в другие помещения.</p>
                <p>Что же касается собственно расчета оборудования, результат в любом случае не может быть меньше 30 л/с или 108 м3/ч на человека. По опыту можно утверждать, что данный показатель лишь на первый взгляд кажется весьма высоким, а на самом деле представляет лишь необходимый минимум, обеспечивающий высокую приемлемость качества воздуха (концентрация взвешенных твердых частиц табачного дыма на уровне 100 мкг/м3 или ниже).</p>

                <h2>Решение по вентиляции офиса средних размеров</h2>
                <p>Гораздо сложнее представляется задача, когда необходимо построить систему вентиляции для офиса средних размеров. Алгоритм действий совершенно идентичен рассмотренному в статье "Вентиляция небольшого офиса", однако нюансов, возникающих на всех этапах производства работ, от проектирования до наладки систем, гораздо больше, да и цена ошибки гораздо выше. Поэтому к выбору компании-подрядчика здесь надо подходить особо тщательно. Идеальный вариант как для заказчика, так и для исполнителя работ, когда проектирование и монтаж ведет одна компания, имеющая солидный практический опыт.</p>
                <p><strong>Производительность системы</strong></p>
                <p>Расчет проекта вентиляции офиса подразумевает использование таких параметров, как:</p>
                <ul>
                    <li>Особенности планировки помещений;</li>
                    <li>Площадь помещений, высота потолков;</li>
                    <li>Общее количество используемых в офисе единиц техники;</li>
                    <li>Количество работающих в помещениях сотрудников;</li>
                    <li>Среднее количество принимаемых посетителей.</li>
                </ul>
                <img src={img14} alt='' className='img-width' />
                <p>Для таких офисов возможны несколько вариантов устройства системы вентиляции.</p>
                <p>Рассмотрим, какая система вентиляции необходима конкретному помещению. Норма пространства на 1 человека = 6 м2, то есть если в вашем офисе работает 30 человек, им положено 180 м2 площади. Такое помещение можно безболезненно оборудовать системой вентиляции с электроподогревом. Если же число работников большое, для их обеспечения свежим воздухом нужно проектировать установку системы с водяным подогревом или более дорогой вариант- систему с рекуперацией (утилизацией) тепла. Это в основном оборудование концерна Systemair, куда входят шведская марка Systemair и норвежские Pyrox и Villavent;  среди систем с водяным подогревом часто используется оборудование польской компании VTS Clima.</p>
                <p>Если использовать системы с электричеством, то производительность будет ограничена (благодаря уже упоминавшимся выше лимитам на потребление электроэнергии). Такие установки могут вентилировать средний офис до 500 м2, если же помещение больше этой площади, применяются установки с водяным подогревом. Они также могут быть оборудованы блоками утилизации.</p>
                <p>Как альтернатива достаточно дорогим заводским установкам с электроподогревом и утилизатором тепла можно использовать сборную систему. В нее входят вентилятор, фильтр, электрокалорифер для нагрева воздуха и блок утилизации тепла. Такая сборная система обходится в два раза дешевле, а также она удобно вписывается в пространство над подвесными потолками, в то время как цельная электрическая установка с блоком рекуперации из-за своего уровня шума требует наличия отдельного помещения для вентиляционного блока.</p>
                <p>Любая вентиляция офиса предполагает наличие воздуховодов. Они либо "прячутся" за подвесными потолками, либо разводка идет в коробах по периметру офиса. Некоторые системы имеют витые воздуховоды, которые при желании заказчика и при нужном дизайнерском оформлении помещения могут использоваться даже как элемент интерьера. Воздух плавно подается и удаляется из комнаты либо через вентиляционные решетки в стенах, либо через специальные розетки, установленные на потолке.</p>
                <p>Вентиляцию в офис Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции для офисов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default OfficeVentilation