import { Collapse } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';

const UvlaMenus = () => {

    const { Panel } = Collapse;

    return (
        <Collapse expandIconPosition='end' defaultActiveKey={['1']}>
            <Panel header="Услуги по увлажнению" key="1">
                <Link to='/humidification-design'>Проектирование увлажнения</Link>
                <Link to='/installation-humidification-system'>Монтаж системы увлажнения</Link>
            </Panel>
        </Collapse>
    )
}

export default UvlaMenus