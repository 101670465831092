import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/17617otoplenie-magazina2.jpeg'
import img2 from '../../../img/49878otoplenie-magazina3-579x400.jpeg'
import img3 from '../../../img/29220otoplenie-magazina4.jpeg'

const StoreHeating = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Отопление магазина, торгового помещения</h1>
                <p>Отопление магазина Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления магазинов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Неотъемлемая составляющая комфорта – благоприятный микроклимат, который формируется при определённом сочетании параметров окружающей среды. К таким параметрам относятся температура, относительная влажность воздуха, его состав, подвижность и т.д.</p>
                <p>В задачу отопления входит поддержание температуры внутри помещений на уровне, обеспечивающем комфортное пребывание людей в холодный период года. Такие условия способствуют хорошему самочувствию человека и благотворно влияют на его работоспособность. Выбор системы отопления зависит от источника тепла, размеров здания и помещений, технико-экономических расчётов и других факторов.</p>

                <h2>Вариант с водяным отоплением</h2>
                <img src={img1} alt='' />
                <p>Для обогрева магазина очень часто используется водяное отопление. Источником тепла может быть местная котельная или ТЭЦ. На стадии проектирования необходимо определиться с выбором отопительных приборов и трубопровода. При этом учитывается несколько нюансов:</p>
                <ul>
                    <li>Величина давления в системе.</li>
                    <li>Особенности конструкции здания.</li>
                    <li>Качество теплоносителя.</li>
                    <li>Личные пожелания заказчика.</li>
                </ul>
                <p>Отличным решением будет использовать систему теплых водяных полов. В процессе проектирования учитываются размеры теплопотерь. Отталкиваясь от этого, решается, достаточно будет только теплых полов или потребуется установка дополнительных отопительных предметов.</p>
                <p><i>Сооружать водяной теплый пол в магазине лучше всего на стадии его строительства. Если здание уже построено, то и в таком случае можно реализовать этот проект, просто потребуется больше трудозатрат.</i></p>
                <p>Существует альтернативный вариант водяному отоплению. Если в магазине будет система кондиционирования с подачей теплого воздуха, то ее можно использовать в холодный период. В такой системе ключевую роль играет чиллер с функцией теплового насоса.</p>
                <p>Другой вариант это подключение гидравлического кондиционирования к тепловому пункту. Так, летом будет вырабатываться охлажденный воздух, а зимой будет теплый воздух.</p>

                <h2>Электрическое отопление для магазина</h2>
                <img src={img2} alt='' className='img-width' />
                <p>Если организовать водяное отопление невозможно, как вариант является электрическое отопление. Например, в этом случае монтируется электрический теплый пол. В полы закладывается электрический провод.</p>
                <p><i>Главный недостаток такой системы это дороговизна, так как для коммерческих предприятий цена на электричество завышена.</i></p>
                <p>Можно использовать электрические конвекторы. Современные конвекторы имеют отличный дизайн, поэтому хорошо впишутся в современный интерьер магазина. Если устанавливается несколько конвекторов, то их можно объединить в одну систему с единым управляющим устройством.</p>
                <p>Удобно использовать тепловентиляторы и масляные обогреватели. Эти устройства практичны тем, что при необходимости можно регулировать расход энергии. Некоторые обустраивают свой магазин исключительно инфракрасным отоплением. Их можно использовать в сочетании с другими видами обогрева. Например, некоторые отопительные агрегаты можно разместить в отдельных комнатах магазина: холле, хозпомещениях, в главном зале и тому подобное. В зависимости от размера можно осуществлять индивидуальный обогрев каждого помещения. Также существует вариант с использованием сплит-систем, которые вырабатывают тепловую энергию.</p>
                <p><i>При использовании электричества для обогрева магазина важно убедиться в пригодности проводки, а при необходимости полностью ее заменить.</i></p>

                <h2>Применение воздушного отопления</h2>
                <img src={img3} alt='' />
                <p>Отопление магазина большого объема можно организовать с использованием воздушного отопления. Нагрев воздуха осуществляется в приточных установках кондиционера. Поступает теплый воздух в помещение по системе воздуховода.</p>
                <p><i>Воздушное отопление можно комбинировать с водяным обогревом. Это позволяет экономить энергоресурсы, так как для прогрева большого объема воздуха требуется много ресурсов.</i></p>
                <p>В летний период такую систему можно использовать как кондиционирование торгового зала. Для этой цели вентиляционная установка монтируется секциями, а количество поступающего наружного воздуха должен быть не менее 10 м3/ч. Если в торговом зале имеются пахучие вещества, не допускается рециркуляция. Чтобы в помещение зимой не поступал холодный воздух на уличный вход или в тамбуре внедряются воздушно-тепловые завесы. Эта завеса создает плоский мощный поток воздуха, которая разделяет входные двери на две зоны с холодным и теплым воздухом. Воздушно-тепловые завесы бывают электрические и водяные.</p>

                <h2>Инфракрасное отопление магазина продовольственных товаров</h2>
                <p>Одним из экономных решений по отоплению магазина является применение инфракрасных систем. Их преимущество в том, что они не греют весь объем магазина, а греют лишь площадь, и если магазин находится в подвале, где теплопотери не очень большие, есть смысл остановиться именно на инфракрасных системах. Устанавливать их над кассами и другими постоянными рабочими местами. Конструкций инфракрасных обогревателей не так много, в основном бывают мобильные или переносные ИК-обогреватели, также бывают настенного исполнения и потолочного, выбор типа зависит от различных факторов. При малой высоте потолка удобней их расположить именно там, если потолки высокие то можно на стену, а если рабочее место расположено далеко от ограждающих конструкций, то можноустановит переносной нагреватель на ножке.</p>
                <p><strong>Источники тепла для инфракрасного отопления:</strong></p>
                <ul>
                    <li>Газовые горелки (продукты горения проходят по специальной трубе, которая и отдает тепло в помещение)</li>
                    <li>Водяные панели (горячая вода нагревает излучающую поверхность)</li>
                    <li>Электрические (их существует множество типов, с различными накаливающимися элементами, объединяет их то, что все они работают от электричества)</li>
                </ul>

                <h2>Исходные данные для проектирования отопления в магазине</h2>
                <p>Наибольший объем важной информации для проектирования отопления в магазине содержит в себе техническое задание на разработку проекта. Техзадание должно разрабатываться собственником или арендатором помещения, в котором предполагается устройство системы отопления. Если магазин находится в крупном торговом центре, то арендатору нужно будет согласовать разработанное ТЗ с собственником, прежде чем передавать этот документ нанятым проектировщикам.</p>
                <p>Обогревательная система магазина может работать на основе использования различного отопительного оборудования. Для крупных торговых центров строятся специальные мощные котельные, способные обеспечить теплом все внутренние помещения такого объекта. Небольшие индивидуальные магазины могут работать на основе стандартных систем радиаторного отопления, подключенных к газовому котлу или другому обогревателю. Вне зависимости от особенностей сети техническое задание, составленное заказчиком, должно содержать в себе следующие данные:</p>
                <ol>
                    <li>Адрес объекта, в котором располагается магазин заказчика.</li>
                    <li>Имя собственника объекта, в котором предстоит проводить монтажные работы.</li>
                    <li>Площадь объекта или магазина в торговом центре, в котором необходимо создать отопительную систему.</li>
                    <li>Конструктивные особенности строения – высота потолков, материалы стен, потолков и пола, материалы кровли.</li>
                    <li>Режим работы магазина, число работников и предположительный поток покупателей.</li>
                    <li>Тип, размер и расположение окон в магазине заказчика.</li>
                    <li>Размер стяжки.</li>
                    <li>Наличие подключения объекта к центральной системе газоснабжения населенного пункта.</li>
                    <li>Температурные условия в регионе, где располагается магазин или торговый центр.</li>
                    <li>Требования к температурному режиму во внутренних помещениях магазина заказчика.</li>
                    <li>Тип отопительной сети – подключение к центральной системе отопления торгового центра, организация индивидуальной котельной или радиаторного отопления в магазине. </li>
                </ol>
                <p>Если для обеспечения внутренних помещений магазина планируется строительство индивидуальной котельной, то в техническое задание следует также включить следующие данные:</p>
                <ul>
                    <li>Особенности устанавливаемого теплового агрегата – тип котла, методика его монтажа, наличие во внутренних помещениях центра или магазина помещения, которое можно использовать под устройство котельной.</li>
                    <li>Вид топлива для котельной установки.</li>
                    <li>Необходимость выбора котла, способного работать на других видах топлива.</li>
                    <li>Особенности разрабатываемой системы удаления дыма в котельной, материалы и расположение дымохода.</li>
                    <li>Теплоноситель в системе отопительного трубопровода в магазине (вода, пар).</li>
                    <li>Правила и методика подачи топлива в топку – автоматическая подача или ручная.</li>
                    <li>Особенности устанавливаемого коллекторного оборудования.</li>
                    <li>Автоматизация работы котельной (автоматическое поддержание температурного режима во внутренних помещениях, автоматическая корректировка температуры).</li>
                </ul>
                <p>Если отопление в магазине планируется создать на основе стандартной системы радиаторного отопления, то заказчику нужно будут указать следующую информацию в ТЗ:</p>
                <ul>
                    <li>Температурные условия во внутренних помещениях для различных зон.</li>
                    <li>Тип используемого отопительного устройства (котел, обогреватель).</li>
                    <li>Тип топлива отопительной системы.</li>
                    <li>Двухтрубная или однотрубная отопительная сеть необходима для магазина.</li>
                    <li>Схема подачи теплоносителя в систему отопления.</li>
                    <li>Технические характеристики используемых для организации отопления трубопроводов и радиаторов.</li>
                    <li>Правила автоматизации отопительной сети. </li>
                </ul>
                <p>Магазины, расположенные в торговых центрах, а также на первых этажах многоквартирных домов, нередко подключаются к уже существующим отопительным системам. Если магазин находится в торговом центре, то арендатору необходимо получить технические условия на подключение к существующей в центре системе отопления у собственника объекта. В многоквартирных домах магазин можно подключить к системе отопления дома, в этом случае техусловия собственник может получить у местной эксплуатирующей компании.</p>
                <p>Если для объекта планируется устройство индивидуальной котельной и заказчик выступает собственником помещения, технические условия получать не нужно.</p>
                <p>Чтобы заказать профессиональное проектирование отопительной системы требуются не только технические условия и задания, но также другие документы, содержащие в себе важные данные. К таким документам относятся профессиональные схемы:</p>
                <ul>
                    <li>Архитектурный чертеж объекта, в котором располагается магазин.</li>
                    <li>Схема внутренних помещений магазина, на которой отмечены отдельные зоны магазина, места установки элементов отопительной системы – радиаторов отопления, трубопроводов и т.д.</li>
                    <li>Генеральный план земельного участка, на котором располагается магазин заказчика.</li>
                </ul>
                <p>Отопление магазина Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления магазинов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default StoreHeating