import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img41 from '../../../img/41_small.jpg'
import img27 from '../../../img/27-05-2014 15-26-42.jpg'
import img26 from '../../../img/27-05-2014 15-26-13.jpg'
import img25 from '../../../img/27-05-2014 15-25-45.jpg'
import img24 from '../../../img/26-05-2014 21-41-33.jpg'
import img23 from '../../../img/27-05-2014 15-42-40.jpg'
import img22 from '../../../img/27-05-2014 15-59-05.jpg'
import img21 from '../../../img/27-05-2014 15-45-05.jpg'
import img20 from '../../../img/27-05-2014 15-44-44.jpg'
import img19 from '../../../img/27-05-2014 15-43-44.jpg'
import img18 from '../../../img/27-05-2014 15-47-38.jpg'
import img17 from '../../../img/27-05-2014 15-50-32.jpg'
import img16 from '../../../img/26-05-2014 22-27-59.jpg'
import img15 from '../../../img/27-05-2014 14-58-05.jpg'
import img14 from '../../../img/27-05-2014 14-58-36.jpg'
import img13 from '../../../img/27-05-2014 14-59-01.jpg'
import img12 from '../../../img/27-05-2014 16-13-04.jpg'

const HeatRecoveryVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция с рекуперацией</h1>
                <p>Вентиляцию с рекуперацией Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вентиляции с рекуперацией по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <img src={img41} alt='' />
                <p>В переводе с латинского, рекуперация означает возмещение или обратное получение. В отношении теплообменных реакций, рекуперация характеризуется как, частичный возврат энергии, затраченной на проведение технологического действия с целью применения в этом же процессе. В вентсистеме принцип рекуперации используется для экономии тепловой энергии.</p>
                <p>Особой разновидностью принудительной системы вентиляции является приточная вентиляция с подогревом и рециркуляцией тепла, которая обеспечивает частичное нагревание входного воздушного потока за счет удаляемого из помещения теплого воздуха при помощи специального устройства – рекуператора. При этом основной подогрев наружного воздуха осуществляется обычным воздухонагревателем.</p>
                <p>Рекуперация тепла в приточно-вытяжной вентиляции – явление не новое, но у нас пока малораспространенное. С технической точки зрения рекуперация является самым обычным процессом теплообмена. Само слово «рекуперация» имеет латинское происхождение и означает «возвращение затраченного». Вентиляционные рекуператоры тепла возвращают его часть назад в помещение посредством теплообмена между входящим и выходящим потоком. Обратный процесс происходит в жаркое время, когда исходящий холодный кондиционный воздух охлаждает встречный теплый нару поток. В таком случае это следует называть рекуперацией холода.</p>

                <h2>Для чего нужна рекуперация?</h2>
                <p>Очевидно, что для экономии энергоресурсов в первую очередь. Рекуператор представляет собой устройство, в котором происходит теплообмен входящих и исходящих воздушных масс. При обычной вентиляции разница температур между входящим и выходящим воздухом в холодное и жаркое время года значительная. Если, к примеру, на улице -20°С, а в помещении +24°С то перепад составляет более 40°С. Эту разницу необходимо будет перекрыть за счет системы отопления. Летом разница меньше, но и она добавит нагрузку на кондиционер. Рекуператор позволяет свести эту разницу до минимума. Правильно подобранное оборудование обеспечивает при 0°С наружного воздуха и +20° С в помещении разницу между входящим и выходящим потоком в пределах 4°С, т.е. сократить ее в пять раз. Эффективность рекуперации падает при понижении значений наружной температуры, но, тем не менее, экономия остается весьма ощутимой. Более того, при значительной разнице внутренней и наружной температуры, рекуперация особенно полезна.</p>
                <p>Многие современные строительные технологии предполагают воздухонепроницаемые и паронепроницаемые ограждающие конструкции. Для эффективного проветривания и удаления водяного пара из помещений с герметичными стенами и стеклопакетами необходима принудительная приточно-вытяжная вентиляция. Рекуперация тепла в данном случае является залогом комфортного воздухообмена с минимальными теплопотерями.</p>
                <p>В США и Канаде, еще задолго до появления рекуперационного оборудования, для того, чтобы зимой в помещение попадал не слишком холодный воздух, а летом слишком теплый, придумали использовать грунтовый теплообменник, который впоследствии получил название «канадский колодец».</p>
                <img src={img27} alt='' />
                <p>Его идея заключается в том, чтобы наружный воздух, прежде чем попасть в помещения, прошел по заглубленным в грунт приточным воздуховодам, приобретая температурное значение близкое к +10°С – постоянная температура грунта на глубине от 2 м и более. Канадский колодец, по сути, не является рекуператором, но снижает энергозатраты на отопление и кондиционирование. Вентиляция помещений в традиционной схеме с канадским колодцем естественная, но может быть и принудительной.</p>
                <p>Рекуператоры как элемент вентиляционного оборудования активно используются в европейских странах. Причина их популярности в тех экономических выгодах, которые дает возращение тепла. Существует два вида рекуператоров: пластинчатые и роторные. Роторные являются более эффективными, но также и дорогостоящими. Они способны возвращать 70-90% тепла. Пластинчатые дешевле, но экономят меньше, в пределах 50-80%.</p>
                <img src={img26} alt='' />
                <img src={img25} alt='' />
                <p>Один из факторов, влияющих на эффективность рекуперации, – это тип помещения. Если температура в нем поддерживается выше 23°С, то рекуператор однозначно окупает себя. И чем дороже стоимость энергоносителей, тем короче срок его окупаемости. Срок эксплуатации рекуператоров довольно большой, а при своевременном обслуживании и замене недорогих расходных деталей, он теоретически неограничен. Рекуператоры могут поставляться в виде моноблока или нескольких отдельных модулей.</p>
                <p>Рекуператор представляет собой теплообменник особого типа, к которому подсоединяются входы и выходы приточного и вытяжного каналов системы вентиляции. Удаляемый из помещения загрязненный воздух, проходя через рекуператор, отдает свое тепло поступающему наружному воздуху, непосредственно не смешиваясь с ним. Такой дополнительный обогрев приточной вентиляции позволяет значительно снижать энергозатраты на подогрев входного воздуха, особенно в зимний период.</p>
                <img src={img24} alt='' />
                <p>Пластинчатые рекуператоры устроены таким образом, что воздушные потоки в них не смешиваются, а контактируют между собой через стенки теплообменной кассеты. Эта кассета состоит из множества пластин, отделяющих холодные воздушные потоки от теплых. Чаще всего пластины делают из алюминиевой фольги, которая обладает отличными теплопроводными свойствами. Пластины могут быть также и из специального пластика. Эти дороже алюминиевых, но повышают КПД оборудования.</p>
                <img src={img23} alt='' />
                <p>Пластинчатые теплообменники имеют существенный недостаток: в результате разницы температур на холодных поверхностях выпадает конденсат, который превращается в наледь. Обледеневший рекуператор перестает эффективно работать. Для его размораживания входящий поток автоматически переводится в обход теплообменника и подогревается калорифером. Выходящий теплый воздух тем временем растапливает наледь на пластинах. В таком режиме, конечно же, не происходит экономия энергии, а период размораживания может занимать от 5 до 25 минут в час. Для подогрева входящего воздуха в фазу размораживания используются калориферы мощностью 1-5 кВт.</p>
                <p>В некоторых пластинчатых рекуператорах используется предварительный подогрев входящего воздуха до температуры, исключающей образование наледи. Это снижает КПД рекуператора примерно на 20%.</p>
                <p>Еще одно решение проблемы обледенения – кассеты из гигроскопической целлюлозы. Этот материал поглощает влагу из вытяжного воздушного потока и передает ее входящему, тем самым, возвращая назад еще и влагу. Такие рекуператоры оправданы только в зданиях, где нет проблемы переувлажнения воздуха. Безусловное преимущество гигроцеллюлозных рекуператоров в том, что они не нуждаются в электроподогреве воздуха, а значит, они и более экономичные. У рекуператоров с двойным пластинчатым теплообменником КПД достигает 90%. Наледь в них не образуется, благодаря передаче тепла через промежуточную зону.</p>

                <h2>Схемы и виды рекуператоров</h2>
                <p>Схема устройства приточно-вытяжной вентиляционной установки с пластинчатым  рекуператором тепла</p>
                <img src={img22} alt='' />
                <p>Известные производители пластинчатых рекуператоров:</p>
                <ul>
                    <li>SCHRAG (Германия),</li>
                    <li>MITSUBISHI (Япония),</li>
                    <li>ELECTROLUX,</li>
                    <li>SYSTEМAIR (Швеция),</li>
                    <li>SHUFT (Дания),</li>
                    <li>REMAK, 2W (Чехия),</li>
                    <li>MIDEA (Китай).</li>
                </ul>
                <img src={img21} alt='' />
                <p>Роторные рекуператоры. В отличие от пластинчатых, в них происходит частичное смешивание входящего и исходящего воздуха. Их главный элемент – вмонтированный в корпус ротор, представляющий собой цилиндр, заполненный слоями профилированного металла (алюминий, сталь). Передача тепла происходит во время вращения ротора, лопасти которых нагреваются исходящим потоком и отдают тепло входящему, перемещаясь по кругу. Эффективность теплообмена зависит от скорости вращения ротора, и она регулируется.</p>
                <p>В роторном рекуператоре технически невозможно полностью исключить смешивание входящего и исходящего воздуха. Кроме того, данный тип оборудования из-за наличия движущихся частей нуждается в более частом и более серьезном обслуживании. Тем не менее роторные модели пользуются немалой популярностью, благодаря высоким показателям возврата тепла (до 90%).</p>
                <img src={img20} alt='' />
                <br />
                <img src={img19} alt='' />
                <p>Схема устройства приточно-вытяжной вентиляционной установки с роторным  рекуператором тепла</p>
                <p>С экономической точки зрения рекуператоры тепла рано или поздно обязательно себя оправдают, но многое зависит от того, насколько эффективно будет организованна сама рекуперация. Оборудование является высоконадежным, и потребитель может рассчитывать на долгий период эксплуатации. Многие компании выпускают широкий ассортимент приточных рекуператоров, разработанных специально для квартир. Так приточная установка с рекуперацией тепла для 2-3-комнатной квартиры может обойтись порядка 17 000 рублей. Производительность системы вентиляции в квартирах находится в пределах 100-800 м³/ч. Для загородных коттеджей этот показатель порядка 1000-2000 м³/ч.</p>
                <img src={img18} alt='' />
                <p>Данные теплообменник состоит из двух частей. Одна часть находится в вытяжном канале, другая - в приточном. Между ними циркулирует вода или водно-гликолиевый раствор. Удаляемый воздух нагревает теплоноситель, а тот, в свою очередь, передает тепло приточному воздуху. В данном рекуператоре не существует риска передачи загрязнений из удаляемого воздуха в приточный. Изменение скорости циркуляции теплоносителя может регулировать передачу тепла. У этих рекуператоров нет подвижных частей, но они обладают низкой эффективностью (45-60%). В основном применяются для промышленных объектов.</p>
                <p>Камерные рекуператоры</p>
                <img src={img17} alt='' />
                <p>Заслонка разделяет камеру на две части заслонкой. Одна часть нагревается удаляемым воздухом, затем заслонка изменяет направление воздушного потока. Благодаря этому, приточный воздух нагревается от теплых стенок камеры. Загрязнение и запахи могут передаваться из удаляемого воздуха в приточный. Заслонка - единственная подвижная часть этого теплообменника. Его эффективность достаточно высока (70-80%).</p>
                <p>Тепловые трубки</p>
                <img src={img16} alt='' />
                <p>Данный рекуператор состоит из герметичной системы трубок. Они заполнены фреоном или другим легко испаряющимся компонентом. Эти вещества испаряются от нагревания удаляемым воздухом. Пары конденсируются в другой части трубки и вновь переходят в жидкое состояние. В данном теплообменнике исключена передача загрязнений, нет подвижных частей, эффективность достаточно низкая (50-70%).</p>
                <p>Многие считают, что РЕКУПЕРАТОРЫ - это дорогостоящие, громоздкие, сложно встраиваемые в технологические процессы устройства с непродолжительным сроком службы, а их ремонт останавливает производство на длительный период, делая применение рекуператора малоэффективным. Перечисленные недостатки позволяют скептикам мириться с колоссальными потерями тепловой энергии и экологическими проблемами. В итоге, рекуператоры стоят далеко не на всех предприятиях, где это целесообразно.</p>
                <p>Решением может стать установка  Оребренных Пластинчатых Теплообменников (рекуператоров типа ОПТ™)</p>
                <p>Технические особенности рекуператоров типа ОПТ</p>
                <ul>
                    <li>за счет возврата тепловой энергии сократить затраты на её покупку до 40%;</li>
                    <li>снизить расход топлива за счет увеличения температуры горения отходящими газами (схема отопления котельных, печей и др.);</li>
                    <li>улучшить качественные характеристики горения топлива за счет использования ранее подогретого воздуха, снизить механический недожог топлива в цикле печного нагрева в котельных и других объектах;</li>
                    <li>охлаждать дымовые газы для соблюдения экологических требований и санитарных норм;</li>
                    <li>использовать тепло отходящих газов для отопления помещений, подогревая уличный воздух;</li>
                    <li>для технологических процессов, требующих низких температур, охлаждать отходящие дымовые газы;</li>
                    <li>уменьшить температуры дымовых газов, тем самым сократив расходы на газоочистку;</li>
                    <li>заменить требующие сложного ремонта рекуператоры более надежными;</li>
                    <li>успешно соблюдать требования Закона № 261 ФЗ «Об энергосбережении»;</li>
                </ul>
                <p>Преимущества Оребренных Пластинчатых Теплообменников  перед традиционными пластинчатыми, роторными и кожухо-трубными моделями</p>
                <ul>
                    <li>возможностью использования в агрессивных и абразивных средах, в средах с сильной загазованностью и запылением;</li>
                    <li>увеличинными пределами рабочих температур - до 1250 С, при том что срок службы аналоговых рекуператоров сокращается уже при 800 С;</li>
                    <li>оптимизированными габаритами и массой – в 4-8 раз легче аналоговых рекуператоров;</li>
                    <li>значительно меньшей стоимостью;</li>
                    <li>сокращенными сроками окупаемости;</li>
                    <li>низкими показателями сопротивления при прохождении воздушных потоков по трактам;</li>
                    <li>усовершенствованной конструкцией препятствующей скоплению шлаков;</li>
                    <li>увеличенным сроком эксплуатации;</li>
                    <li>увеличенным рабочим периодом перед профилактическими мероприятиями;</li>
                    <li>улучшенными массогабаритными характеристиками, облегчающими монтаж и транспортировку рекуператоров</li>
                </ul>
                <p>Почему данный тип рекуператора можно считать грамотным выбором?</p>
                <ul>
                    <li>увеличение площади теплопередающей поверхности на единицу объема и массы;</li>
                    <li>высокую надежность используемого рекуператора;</li>
                    <li>значительное снижение возможности выхода рекуператора из строя за счет абразивного износа и термических деформаций;</li>
                    <li>упрощение процессов ремонта и обслуживания рекуператоров;</li>
                    <li>возможность модульного проектирования и сборки рекуператоров</li>
                    <li>Наиболее частые случаи применения рекуператора.</li>
                </ul>
                <img src={img15} alt='' className='img-width' />
                <ul>
                    <li>Возврат тепловой энергии</li>
                    <li>Экономия топлива</li>
                    <li>Снижение стоимости оборудования и увеличение сроков его работы </li>
                    <li>(газоходы, дымовые трубы и прочие)</li>
                    <li>Обеспечение экологических норм</li>
                    <li>Исполнение требований закона №261 ФЗ "Об энергосбережении"</li>
                    <li>Сокращение транспортно-заготовительных расходов</li>
                    <li>Снижение стоимости газоочистки</li>
                    <li>Обеспечение стабильности технологических параметров</li>
                    <li>Химический и механический дожог топлива</li>
                    <li>Увеличение производительности технологических процессов</li>
                    <li>Короткие сроки окупаемости</li>
                </ul>
                <img src={img14} alt='' className='img-width' />
                <ul>
                    <li>Возврат тепловой энергии</li>
                    <li>Экономия топлива</li>
                    <li>Снижение стоимости оборудования и увеличение сроков его работы </li>
                    <li>(газоходы, дымовые трубы и прочие)</li>
                    <li>Обеспечение экологических норм</li>
                    <li>Исполнение требований закона №261 ФЗ "Об энергосбережении"</li>
                    <li>Сокращение транспортно-заготовительных расходов</li>
                    <li>Снижение стоимости газоочистки</li>
                    <li>Обеспечение стабильности технологических параметров</li>
                    <li>Увеличение производительности технологических процессов</li>
                    <li>Короткие сроки окупаемости</li>
                </ul>
                <img src={img13} alt='' className='img-width' />
                <p>Теплообменники газ-газ используются во многих сферах, которые условно можно разделить на следующие категории:</p>
                <p>Процессы, имеющие низкий уровень температуры теплоносителя:</p>
                <p>Интервал от 20 до 60°C</p>
                <ul>
                    <li>при малых объемах газов, к примеру, как утилизатор дымовых газов при работе газовых котлов в небольшом помещении, где теплообменник используется в системе вентиляции.</li>
                    <li>при больших объемах газов, к примеру, в системе вентиляции цехов, концертных залов, крытых стадионов и других больших помещениях.</li>
                </ul>
                <p>Интервал от 60 до 200°C</p>
                <ul>
                    <li>при малых объемах газов, к примеру, для вывода дымового продукта сгорания топлива, который выделяется в виде газа при множестве технологичных процессов.</li>
                    <li>при больших объемах газов, к примеру, использование теплообменника газового возможно в системе вентиляции сушильных и покрасочных цехов.</li>
                </ul>
                <p>Процессы, имеющие средний уровень температуры теплоносителя.</p>
                <p>Интервал от 200 до 600°C, примером может стать утилизация тепла дымовых газов при работе котельных, а также возможна экономия угля путем перенаправления избыточного тепла на прогрев подаваемого в топку воздуха.</p>
                <p>Процессы, имеющие высокий уровень температуры теплоносителя.</p>
                <ul>
                    <li>Интервал от 600 до 800°C, для примера при производстве пластмасс может пригодиться теплообменник для охлаждения газа или для утилизации тепла, носителем которого становятся дымовые газы.</li>
                    <li>Интервал до 1000°C и выше, которые наблюдаются при производстве стекла, в металлургии, нефте- и газопереработке и других сферах производства, где теплообменник станет основой решения такой проблемы, как экономия угля, или выступит в качестве утилизатора образующихся дымовых газов.</li>
                </ul>
                <p>Стоит отметить, что использование теплообменника типа газ-газ при температуре отходящих газов 45-50°C требует отдельного расчета эффективности.</p>
                <p><strong>Выводы</strong></p>
                <p>Установки с рекуперацией тепла позволяют снизить расходы энергии на отопление помещений в два раза. Их установка часто окупается в первый же отопительный сезон. Установка рекуператоров при строительстве и реконструкции позволяет частично снизить нагрузку на систему отопления всего здания и отказаться от значительной части традиционного отопительного оборудования. Расходы на установку рекуператоров - это инвестиции не только в снижение затрат на отопление, но и в обеспечение оптимальных климатических условий в помещениях и, в конечном счете, в здоровье людей.</p>
                <img src={img12} alt='' className='img-width' />
                <p>Приборы, способные экономить тепловую и прочие виды энергии, становятся все более важными, так как постоянно растут цены на энергоресурсы. Также мы давно не сомневаемся в необходимости дышать свежим чистым воздухом в помещениях. Отрицательную роль в строительстве сыграла установка популярных пластиковых окон и герметичных дверей. Они нарушают воздухообмен и приводят к нежелательным последствиям. На фоне всех этих факторов, на помощь к нам приходят системы вентиляции с рекуперацией тепла. Они не только экономят наши деньги, но и охраняют наше здоровье.</p>
                <p>Вентиляцию с рекуперацией Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вентиляции с рекуперацией по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HeatRecoveryVentilation