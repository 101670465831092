import React from 'react'
import Form from '../form/Form'
import './harranty.css'

const Harranty = () => {
    return (
        <>
            <div className='harranty-component'>
                <h1>Гарантия</h1>
                <p>Компания <strong>«FIDCLIMAT»</strong> является официальным дилером крупнейших производителей климатической техники на территории Узбекистан. Все оборудование, поставляемое компанией <strong>«FIDCLIMAT»</strong>, подлежит гарантийному обслуживанию.</p>
                <p>Гарантия производителя определяется для каждой группы оборудования и составляет от 1 до 5 лет.</p>
                <p>Все гарантийные работы выполняются в специализированных сервисных центрах. При необходимости сервисные специалисты выезжают на объект для ремонта оборудования на месте.</p>
                <p>Стандартная гарантия на монтаж и пусконаладочные работы, выполненные инженерами компании <strong>«FIDCLIMAT»</strong>, составляет 1 год.</p>

                <p><strong>Международный стандарт контроля качества.</strong></p>
                <p>Система менеджмента качества обслуживания нашей компании сертифицирована и соответствует стандарту ГОСТ Р ИСО 9001-2015.</p>

                <p><strong>Допуск к объектам капитального строительства.</strong></p>
                <p>«FIDCLIMAT» является действующим членом СРО ЭкспертСтрой и имеет допуск к работам, оказывающим влияние на безопасность объектов капитального строительства.</p>

                <p><strong>Лицензия МЧС.</strong></p>
                <p>Допуск к работам по монтажу, техническому обслуживанию и ремонту средств обеспечения пожарной безопасности зданий и сооружений.</p>

                <p><strong>Участник Ассоциации Предприятий Индустрии Климата.</strong></p>
                <p>Ассоциация является гарантом надежности компании. Наши специалисты регулярно проходят курсы повышения квалификации, что подтверждается сертификатами и дипломами.</p>

                <p><strong>Дополнительная гарантия на оборудование и работы.</strong></p>
                <p>Компания предоставляет дополнительную гарантию на промышленное и полупромышленное оборудование, а также на работы по его монтажу, при соблюдении регламента технического обслуживания.</p>
            </div>
            <Form />
        </>
    )
}

export default Harranty