import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img3 from '../../../img/3_small.jpg'
import img8 from '../../../img/80162005.jpg'
import img11 from '../../../img/11602552b52b-vent_prinud_kvart_00252.jpg'
import img1 from '../../../img/-1montag_vue_office.jpg145701261545822'

const ApartmentVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Система вентиляции для квартиры</h1>
                <p>Систему вентиляции для квартиры Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции для квартиры по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <img src={img3} alt='' />
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Вентиляция квартиры во многом сформирована еще по советским стандартам. Вытяжку обеспечивают вентиляторы, установленные на кухне и в санузле. Однако вентиляция воздуха в квартире не ограничивается системой вывода отработанного воздуха, наибольшее значение имеет приток. И эту функцию традиционно выполняют щели в окнах и дверях. В особо старых домах – еще и появившиеся со временем щели в стенах. </p>

                <h2>Какие виды вентиляции существуют?</h2>
                <p>Когда Вы начинаете задумываться об установке системы вентиляции для квартиры, неизбежно возникает вопрос: какую выбрать? Конечно, первой в голову приходит полноценная система вентиляции, с крупными шахтами, как те, по которым тайно путешествуют хитрые герои боевиков. </p>
                <p>Однако, если установить такую махину в городской квартире или даже в частном доме – жить там будет она, так как места для Вас уже не останется. Кроме того, в качестве бытового устройства это решение имеет еще ряд минусов:</p>
                <img src={img8} alt='' />
                <ul>
                    <li>Необходимость капитального ремонта для ее установки.</li>
                    <li>Ограниченная совместимость: такая система вентиляции может быть установлена далеко не в каждой квартире.</li>
                    <li>Высокая стоимость. Не как Ламборгини, конечно, но и не Ока.</li>
                    <li>Избыточная функциональность: если в каждой комнате у Вас при намертво заложенных окнах не живут по 15 гастарбайтеров, то вентиляция такой мощности Вам просто не нужна. А если живут – то вряд ли Вас волнуют вопросы вентиляции. </li>
                </ul>
                <p>Большинство жилых многоквартирных домов оборудовано вентиляционными системами естественного типа. Состоит она из нескольких вытяжных воздуховодов, подключенных к единому выводящему каналу, который проходит вдоль стояка. В роли приточных каналов служат окна, двери, форточки и балконы, т. е. все объекты через которые воздух может свободно поступать в квартиру.</p>
                <p>Подобная система воздухообмена является довольно эффективной и обеспечивает создание оптимальных условий для жизни людей. Однако с течением времени ее функциональность постепенно снижается, что может привести к нарушению микроклимата в квартире. Главной причиной снижения эффективности системы служит недостаточное обслуживание коммуникаций, что приводит к их заполнению мусором и уменьшению диаметра.</p>
                <img src={img11} alt='' className='img-width' />
                <p>Принцип действия принудительной вентиляции основан на использовании различных механизмов, усиливающих воздушный поток и обеспечивающих его очистку. Сегодня существует несколько видов подобных систем, позволяющих нормализовать соотношение влажности и термических показателей внутри квартиры. Выглядят они следующим образом:</p>
                <ul>
                    <li>вытяжная;</li>
                    <li>приточная;</li>
                </ul>
                <p>Приведенные выше модели способны в полном объеме удовлетворить потребности помещения в свежем воздухе и обеспечить полноценное удаление отработанных масс.</p>

                <h2>Каким должен быть воздух в квартире</h2>
                <p>Оптимальные показатели для температуры воздуха в зимнее время составляет 20-22°С, в летнее время до 25°С. Очень важно поддерживать состояние слизистой носа в комфортной для неё состоянии. Имеется в виду комфортный режим температуры и влажности воздуха. Это такие параметры, которые человек может сам регулировать.</p>
                <p>Нормальная влажность для слизистой в помещении должна быть в диапазоне 40-60 %. Таким образом необходимо заботиться о том, чтобы в помещении был постоянный приток свежего воздуха. Современные пластиковые окна полностью предотвращают естественную циркуляцию воздуха, а некоторые еще и создают эффект конденсации на поверхности стекла.</p>
                <p>В квартирах с нарушением вентиляции лабораторные исследования отмечают высокое уровень содержания микроорганизмов, которые появляется в процессе человеческой жизнедеятельности.</p>

                <h2>Как сделать вентиляцию</h2>
                <p>Существует три способа создании вентиляции в квартире.</p>
                <ul>
                    <li>естественная вытяжная вентиляция плюс периодическое проветривание окон</li>
                    <li>механическая вытяжная вентиляция плюс проветривание</li>
                    <li>механическая вытяжная вентиляция плюс приток свежего воздуха</li>
                </ul>
                <p><strong>Естественная вытяжная вентиляция</strong></p>
                <p>Удаление воздуха происходит через существующие вытяжные каналы, приток свежего воздуха происходит через периодически открываемые окна.</p>
                <p><strong>Плюсы.</strong> Самый простой и бесплатный вариант.</p>
                <p><strong>Минусы.</strong> Вытяжные каналы должны быть рабочими, то есть в них должна быть тяга. Летом в таких каналах очень слабая тяга, иногда она полностью отсутствует. Чтобы процесс рециркуляции воздуха в квартире произошел, для удаления воздуха нужно, чтобы этот воздух в квартиру поступил. То есть необходимо приоткрывать иногда окна. Если окно не открывать, то и удаляться ничего не будет. Если летом с этим проблем может и не быть, то зимой постоянно держать окна не будешь.</p>
                <p><strong>Механическая вытяжная вентиляция и проветривание</strong></p>
                <p>В вытяжные каналы в туалете, ванной и на кухне устанавливаются вытяжные вентиляторы. Приток воздуха происходит через пассивные проветриватели оконного или стенового типа.</p>
                <p><strong>Плюсы.</strong> Сравнительно недорогой вариант, который может обеспечить требуемый воздухообмен в квартире. Даже летом такая вентиляция будет достаточно эффективно работать, так как в квартире будет создаваться разрежение при работе вытяжных вентиляторов. А компенсироваться будет свежим воздухом, который будет поступать в квартиру через проветриватели. При этом в таких проветривателях воздух проходит фильтрацию.</p>
                <p><strong>Минусы.</strong> В зимний период года использование проветривателей повышает нагрузку на отопление, воздух который поступает в квартиру снижает температуру внутреннего воздуха на 1-2 С. Сравнить это можно с контролируемым сквозняком. Иногда при очень низких наружных температурах приходится полностью закрывать проветриватели.</p>
                <p>Механическая приточно-вытяжная система вентиляции</p>
                <p>В вытяжные каналы устанавливаются вентиляторы. Для притока воздуха в жилых помещениях используются приточные или приточно-вытяжные установки с рекуперацией тепла. В холодный период года воздух с улицы подогревается с помощью электрических нагревателей или рекуператора.</p>
                <img src={img1} alt='' className='img-width' />
                <p>Один из более простых вариантов подачи свежего воздуха это использование механических проветривателей с рекуперацией тепла. Для них необходимо только выполнить отверстие в наружной стене.</p>
                <p><strong>Плюсы.</strong> Самая правильная схема создания воздухообмена 24 часа в сутки. Вопрос вентиляции в квартире полностью решен.</p>
                <p><strong>Минусы.</strong> Для его реализации необходимо произвести комплекс монтажных работ. Для того чтобы воздух забирать с улицы, необходимо в наружной и внутренней стене выполнить отверстия. Для перемещения воздуха в квартиру требуется проложить небольшую систему воздуховодов с решетками.</p>
                <p>При использовании механических проветривателей не требуется использование воздуховодов, но в каждой комнате необходима установка такого проветривателя.</p>

                <h2>Почему может ухудшиться вентиляция в квартире</h2>
                <p>Проблемы с вентилированием квартиры могут наступить вследствие таких факторов:</p>
                <ul>
                    <li>Установки современных герметичных окон – металлопластиковых, деревянных или алюминиевых;</li>
                    <li>Установки герметичных межкомнатных дверных блоков;</li>
                    <li>Неправильного монтажа кухонной вытяжки или вентиляторов;</li>
                    <li>Разрушения или сильного загрязнения вентиляционного канала.</li>
                </ul>
                <p>Все эти проблемы легко устранить, если правильно подойти к делу. Чтобы была должная вытяжная вентиляция в квартире, необходима хорошая работа вентиляционного канала. Если он завален мусором или в нем построили гнезда птицы, то поток воздуха не будет хорошо циркулировать внутри системы. Если канал забит – его требуется тщательно расчистить, иногда для этого приходится разбирать стенку со стороны подъезда.</p>
                <p>Новые окна нужно заказывать с функцией зимнего проветривания, в таком случае створка рамы открывается на пару миллиметров. На оконном блоке стоит установить приточный клапан. Желательно это делать во время монтажа оконного блока, но можно выполнить и позже. Такой клапан имеет сеточки, решетки, противошумное устройство и специальные регулировки.</p>
                <p>Межкомнатные двери должны быть смонтированы на расстоянии не менее 2 см от уровня пола. Если нет желания обрезать дверное полотно, то можно сделать сквозные отверстия и закрыть их специальными решетками.</p>
                <p>На вытяжные отверстия вентиляционных каналов требуется установить вентиляторы, то же самое касается и монтажа кухонной вытяжки. Такие вентиляторы помогают отводить плохой воздух через отверстие в стене или окно.</p>

                <h2>Какую вентиляцию стоит делать в санузле и ванной комнате</h2>
                <p>Установка вентиляции в квартире подразумевает размещение в каждой из комнат наиболее приемлемого варианта вентилирования. В туалетной и ванной комнатах уместным будет сделать вытяжную вентиляцию. В таких помещениях обычно сильно повышенная влажность, поэтому вентилятор, установленный на потолке или стене, и соединенный с вентиляционным каналом, легко решит проблему осушения комнат.</p>
                <p>Такие устройства можно сделать как автоматические, чтобы вентилятор включался при выключении света, или закрытии двери, так и с механической регулировкой.</p>

                <h2>Как еще можно вентилировать жилые комнаты</h2>
                <p>Бывает так, что в определенный момент в жилых комнатах может стать слишком жарко или слишком сыро, в таком случае на выручку придет кондиционер. Лучше устанавливать сплит-системы, у них много преимуществ:</p>
                <ul>
                    <li>Невысокая стоимость;</li>
                    <li>Большой ассортимент;</li>
                    <li>Легкость монтажа;</li>
                    <li>Бесшумность;</li>
                    <li>Возможность экономичного режима работы;</li>
                    <li>Легкость управления с помощью пульта управления.</li>
                </ul>
                <p>При помощи кондиционера легко проветрить, осушить и охладить помещение. Тяжелый, отработанный воздух выводится на улицу, а свежий и очищенный поступает в комнаты. Если сплит-система установлена подходящих параметров, то без труда можно будет провентилировать небольшую квартиру.</p>

                <h2>Вентиляция кухни</h2>
                <p>Кухня, чаще других комнат, нуждается в дополнительной вентиляции. Выполняя монтаж вентиляции в квартире, стоит сразу задуматься об установке кухонной вытяжки. Вытяжки бывают разных видов:</p>
                <ol>
                    <li>Подвесные;</li>
                    <li>Встраиваемые;</li>
                    <li>Каминные.</li>
                </ol>
                <p>Самым простым и дешевым вариантом являются подвесные модели, однако в них необходимо периодически менять угольные фильтры. Встраиваемые модели более мощные и оснащены многоразовым, моющимся фильтром из тонкого алюминия. Каминные или купольные вытяжки самые мощные, чаще всего их устанавливают в больших квартирах и домах.</p>

                <h2>Типы приточных систем вентиляции для квартиры</h2>
                <p>Можно выделить два типа приточных систем вентиляции:</p>
                <p>Компактные приточные системы вентиляции для квартиры. Это система, которая работает на приток чистого воздуха. Плюсы — имеет маленький размер, низкую цену, легкость в монтаже. Их можно монтировать внутри помещения, под окном или на балконе. Они имеют низкий порог шума, а также отлично вписываются в любой интерьер. Минусы – это неравномерная циркуляция воздуха и маленький охват площади. Мощность таких систем может охватить до 45 квадратных метров. Некоторые модели компактных приточных систем вентиляции для квартиры оснащены подогревом воздуха.</p>
                <p>Полноценные приточные системы вентиляции для квартиры. Это усовершенствованные устройства, которые имеют большие размеры и дорого стоят. Такая система не подойдет для маленькой квартиры, а вот для установки в помещениях с большой площадью — оптимальный вариант.</p>
                <p>К плюсам такой системы можно отнести:</p>
                <ol>
                    <li>Приток и обратная тяга вентиляции в квартире.</li>
                    <li>Распределение воздуха по всей площади.</li>
                    <li>Улучшение качества воздуха (фильтрация, увлажнение, подогрев или охлаждение).</li>
                    <li>Автоматический климат-контроль.</li>
                </ol>
                <p>Систему вентиляции для квартиры Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции для квартиры по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ApartmentVentilation