import React from 'react'
import '../heatingPages.css'
import Form from '../../../components/form/Form'
import { Link } from 'react-router-dom'

const HeatingMaintenance = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Обслуживание системы отопления</h1>
                <p>Обслуживание системы отопления Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Периодичность обслуживания систем отопления</h2>
                <p>В нашей стране работа системы отопления имеет ярко выраженную сезонность, связанную с зимним периодом. В отопительный сезон нагрузка на систему возрастает, а в летний период она наоборот простаивает или работает только на приготовление горячей воды. Такой режим работы нельзя назвать оптимальным. В связи с этим обслуживание систем отопления выполняют два раза в год. Осенью перед началом отопительного сезона и весной после его завершения.</p>
                <p>В некоторых случаях, например, если котельная работает на дизельном топливе или в доме выдуться строительные работы некоторые виды обсаживания необходимо производить чаще. Периодичность очистки дизельного котла во многом зависит от качества используемого топлива, даже у проверенных поставщиков случаются «проколы» и приходиться вызывать специалиста для проведения внеплановых работ. При проведении в здании строительных работ образуется много пыли. Пыль оседает на внутренних поверхностях горелки и отопительного котла, приводя к их поломке, Поэтому при проведении в здании строительных работ котел желательно остановить. Если такой возможности нет, за оборудованием следят особенно тщательно, очистку горелки и котла проводят по мере необходимости иногда раз в месяц и даже чаще.</p>
                <p>Периодичность проведения технического обслуживания системы отопления определяется совместно с заказчиком в зависимости от особенностей объекта и закрепляется в сервисном договоре. Стандартным является обслуживание дважды в год - весной и осенью.</p>

                <h2>Состав работ по обслуживанию отопления</h2>
                <p>Сервисное обслуживание отопления состоит из осмотра всех элементов системы, проверки ряда параметров (давление газа, напряжение в электросети и т.д.), замены расходных материалов, например, фильтров и т.д. В случае необходимости проводиться ремонт, настройка или замена оборудования. Основными задачами технического обслуживания являются: обеспечение надежной и безопасной работы системы, предупреждение аварийных ситуаций, а так же повышение эффективности для того чтобы свести расходы к минимуму расходы на топливо для котельной. Далее мы приведем стандартный перечень работ, выполняемых при обслуживании системы отопления. В зависимости от типа котельной и установленного оборудования перечень может незначительно отличаться от приведенного ниже.</p>
                <p>Система отопления:</p>
                <ul>
                    <li>осмотр трубопроводов и оборудования на предмет протечек теплоносителя, воды, газа. Осмотр позволяет проверить герметичность отопительных контуров и топливной системы, а так же составить общее представление о её техническом состоянии</li>
                    <li>проверка работы расширительных баков. Расширительные баки являются обязательным элементом безопасности отопительной системы, защищающим её температурных перепадов давления</li>
                    <li>проверка так называемой группы безопасности котла и бойлера ГВС. Устройства, входящие в данную категорию, обеспечивают безопасность эксплуатации оборудования, защищают его от перегрева, неконтролируемого роста давления и т.д.</li>
                    <li>проверка насосного оборудования, сервоприводов, задвижек и т.д.</li>
                    <li>проверка давления газа в подающем газопроводе (для газовых котельных), а так же напряжения в электросети</li>
                    <li>ревизия вентиляции и дымохода</li>
                </ul>
                <p>Отопительный котел:</p>
                <ul>
                    <li>проверка состояния топочной камеры отопительного котла. При необходимости выполняется её очистка.</li>
                    <li>проверка параметров работы и настройка горелки. При помощи газоанализатора инженер определяет состав дымовых газов. На основании анализа производиться настройка работы горелки. При необходимости производиться чистка устройства.</li>
                    <li>проверка и настройка автоматики котла</li>
                    <li>проверка системы розжига</li>
                    <li>замена либо очистка топливных и грязевых фильтров, а так же топливных форсунок</li>
                </ul>
                <p>Для инициации обследования отопительной системы дома необходимо подать заявление в управляющую компания. В нем обязательно указывается причина — плохая работа теплоснабжения, аварийная ситуация или несоответствие текущих параметров системы нормам.</p>
                <p>Согласно текущих норм во время аварии представители управляющей компании должны в течение максимум 6 часов ликвидировать ее последствия. Также после этого составляется документ о причиненном ущербе собственникам квартир из-за аварии. Если причиной является неудовлетворительное состояние – УК должна за свой счет восстановить квартиры или выплатить компенсацию.</p>
                <p>Нередко во время реконструкции системы отопления здания необходимо выполнить замену некоторых ее элементов на более современные. Затраты определяются фактом – на чьем балансе состоит отопительная система. Восстановлением трубопроводов и других компонентов, не находящихся в квартирах должна заниматься управляющая компания.</p>
                <p>Если же собственник помещения захотел поменять старые чугунные батареи на современные — следует предпринять такие действия:</p>
                <ol>
                    <li>В управляющую компанию составляется заявление, в котором указывается план квартиры и характеристики будущих отопительных приборов.</li>
                    <li>По истечении 6 дней УК обязана предоставить технические условия.</li>
                    <li>Согласно им выполняется подбор оборудования.</li>
                    <li>Монтаж осуществляется за счет собственника квартиры. Но при этом должны присутствовать представители УК.</li>
                </ol>
                <p>В любом общественном здании должны быть созданы комфортные условия, ведь там также какое-то время пребывают люди. Тепло, уют, все необходимые условия для комфорта сейчас стараются создать в большинстве общественных зданий. Ведь непрофессионально налаженное отопление торгового центра, например, может даже негативно повлиять на количество покупателей.</p>
                <p>То же самое можно сказать и о ресторане, банке, кинотеатре и других общественных помещениях. Также очень важно качественное отопление школы или детского сада, ведь без надёжной системы обогрева помещения и дети, и воспитатели могут заболеть. Что не говори, но – важный вопрос, который должен быть решен задолго до того, как в помещениях будут находиться люди.</p>
                <p>В компании «FIDCLIMAT» качественно и слаженно оборудуют систему отопления для любого общественного объекта. Это может быть отопление для офиса или гостиницы, теплоснабжение помещений общепита, а также других комплексов и учреждений. Наши мастера уверены в своём профессионализме, так как работают в сфере прокладки инженерных сетей уже много лет.</p>
                <p>Обслуживание системы отопления Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HeatingMaintenance