import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/32976be80d3fb1f8a65ee6b8b83e21d649230.jpg'
import img2 from '../../../img/416663fe0b2ffdeb38f2153cbc8d64d28a0e6.jpg'

const InstallationDehumidifiers = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Монтаж осушителей в Ташкент</h1>
                <p>Монтаж системы осушения Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем осушения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Что входит в монтаж осушителя</h2>
                <p>Основные этапы монтажа:</p>
                <ul>
                    <li>Выбор места и его подготовка.</li>
                    <li>Прокладка электролинии и дренажной системы.</li>
                    <li>Создание сети воздуховодов (для канальных моделей).</li>
                    <li>Непосредственно установка осушителя (настенная или напольная).</li>
                    <li>Подключение к коммуникациям.</li>
                    <li>Пуско-наладочные работы и тестирование.</li>
                </ul>

                <h2>Монтаж осушителей любых типов</h2>
                <p>Квалификация персонала монтажного подразделения компании «FIDCLIMAT» достаточно высока для монтажа любых систем осушения. Нами устанавливается как промышленное, так и бытовое осушительное оборудование. Для повышения качества монтажных операций специалисты используют профессиональный инструмент и качественную оснастку. При установке и монтаже осушителей используются только высококачественные метизы, элементы электропроводки и прочие расходные материалы.</p>
                <p>Наши мастера выполняют монтажные работы быстро и аккуратно. Последний фактор весьма важен, когда осуществляется монтаж осушения в жилых помещениях и офисах. Монтируя устройство, скажем, в гостиной или бассейне частного дома, специалист выполнит все необходимые действия без причинения повреждений отделке. Вы получите два существенных плюса. Во-первых, установка и монтаж осушителя позволят создать в вашем доме по-настоящему комфортный микроклимат, во-вторых, интерьер помещения останется эстетически привлекательным.</p>

                <p><strong>Бытовые осушители</strong></p>
                <p>Устройства, предназначенные для квартиры, частного дома, небольших офисных и коммерческих помещений, имеют компактные размеры и небольшую мощность. В основном, они устанавливаются на объектах, расположенных в зонах с влажным климатом или вблизи природных водоемов, могут использоваться для просушки помещений после ремонта или затопления. Такие домашние осушители бывают мобильные и стационарные.</p>
                <img src={img1} alt='' className='img-width' />
                <p>Установка мобильного прибора не требует никаких монтажных работ. Он устанавливается в любом удобном месте и подключается к электропитанию. Для сбора конденсата такие модели имеют специальную емкость, которую опорожняется по мере наполнения.</p>
                <p>Монтаж стационарного бытового осушителя в доме или коттедже производится с прокладкой электрических и дренажных коммуникаций. Дренаж может выводиться на улицу или прямо в канализацию. В зависимости от мощности подключение может производиться к силовой линии на 220 В или 380 В.</p>
                <p><strong>Осушители воздуха для бассейнов</strong></p>
                <p>Помещения больших бассейнов и аквапарков требуют постоянной работы систем влагоудаления. Для них подбираются устройства высокой производительности, способные работать в течение длительного времени. Установка осушителей воздуха для бассейнов обязательно включает в себя проведение предварительных расчетов в зависимости от площади объекта и размеров зеркала воды, проектирование, обустройство дренажной системы для отвода больших объемов жидкости.</p>
                <img src={img2} alt='' className='img-width' />
                <p>Такой прибор монтируется неподалеку от бассейна или в отдельно выделенном помещении. Он может просто устанавливаться на пол или навешиваться на стену. На крупных объектах проводится установка канальных воздушных осушителей, для которых необходимо разработать оптимальную схему прокладки воздуховодов, идущих из технического помещения, и продумать вопрос с подключением к общей системе приточно-вытяжной вентиляции.</p>
                <p><strong>Промышленные осушители</strong></p>
                <p>Наибольшую сложность представляет собой монтаж промышленных осушителей воздуха. Как правило, это громоздкие устройства, требующие использования специальной грузоподъемной техники. Чаще всего их электропитание осуществляется от трехфазной сети, а отвод больших объемов дренажа (в конденсационных агрегатах) – через подключение к канализационной системе здания. Важное значение имеет координация работы с общеобменной вентиляцией, позволяющей обеспечивать приток свежего воздуха.</p>
                <p>Помещения, в которых устанавливаются рефрижераторные или адсорбционные осушители (производственные цеха, склады, ангары) имеют большую высоту. Для монтажа устройств и прокладки воздуховодов во многих случаях требуется спецтехника и промышленные альпинисты, что значительно увеличивает стоимость работ. Составление проекта, включающего подбор оборудования и монтажную схему, в данном случае является обязательным условием.</p>
                <p>Наша компания предлагает отличную возможность не только выгодно купить оборудование, отвечающее всем поставленный задачам по влагоудалению, но и заказать их профессиональный монтаж. Специалисты «FIDCLIMAT» недорого и в короткие сроки выполнят следующие виды работ:</p>
                <ol>
                    <li>Изучение объекта и проектирование, составление схемы размещения.</li>
                    <li>Подбор устройств в соответствии с проектными характеристиками.</li>
                    <li>Доставка оборудования на объект, сборка узлов и воздуховодов.</li>
                    <li>Установка и подключение любой модели любого производителя.</li>
                    <li>Создание дренажной системы, заземление силовой линии.</li>
                    <li>Настройка необходимых рабочих параметров.</li>
                </ol>

                <h2>Монтаж фильтра осушителя</h2>
                <p>При установке фильтра направление стрелки на его этикетке должно совпадать с направлением течения хладагента в магистрали. Фильтр-осушитель может иметь различную ориентацию в пространстве, но при этом необходимо учитывать следующее:</p>
                <ul>
                    <li>вертикальный монтаж с нисходящим потоком хладагента позволяет осуществлять быстрое опорожнение/вакуумирование холодильной системы;</li>
                    <li>вертикальный монтаж с восходящим потоком хладагента увеличивает время опорожнения/вакуумирования холодильной системы, поскольку хладагент должен испариться из фильтра-осушителя.</li>
                </ul>
                <p>Сердечник фильтра прочно закреплен в корпусе фильтра. Фильтры-осушители компании Данфосс способны противостоять вибрациям до 10 g*.</p>
                <p>Убедитесь, что трубы достаточно прочно удерживают фильтр и защищают его от воздействия вибрации. В противном случае закрепите фильтр хомутом или просто установите его в более безопасное место.</p>
                <p>*10 g — десятикратное значение силы притяжения Земли.</p>
                <p>Фильтры типа DCR (с заменяемым твердым сердечником) устанавливаются таким образом, чтобы входной штуцер был направлен вверх или горизонтально. Такой монтаж позволяет легко удалять грязь из кожуха при замене сердечника. При монтаже нового фильтра DCR оставляйте место, достаточное для замены сердечника.</p>
                <p>Не распаковывайте фильтры-осушители или сердечники, если они не предназначены для непосредственного монтажа. Запакованные фильтры лучше сохраняются. Не вакуумируйте и не храните фильтры при избыточном давлении.</p>
                <p>Пластиковые гайки, установленные на соединительных штуцерах, обеспечивают полную герметичность и гарантируют сухость содержимого фильтра.</p>
                <p>При пайке фильтра-осушителя используйте защитный газ, например, азот N2. Убедитесь, что поток защитного газа совпадает с направлением потока хладагента, указанном на фильтре. В этом случае тепло отводится от места пайки, что предотвращает разрушение полиэфирной сетки фильтра.</p>
                <p>Монтаж системы осушения Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем осушения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default InstallationDehumidifiers