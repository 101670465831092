import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img37 from '../../../img/37186montazh-ventilyacii-pravila-i-algoritm-vypolneniya-rabot-54.jpg'
import img45 from '../../../img/45790montazh-ventilyacii-pravila-i-algoritm-vypolneniya-rabot-19.jpg'
import img18 from '../../../img/29-05-2014 18-22-29.jpg'
import img42 from '../../../img/42817montazh-ventilyacii-pravila-i-algoritm-vypolneniya-rabot-32.jpg'
import img54 from '../../../img/54294montazh-ventilyacii-pravila-i-algoritm-vypolneniya-rabot-33.jpg'
import img40 from '../../../img/40093montazh-ventilyacii-pravila-i-algoritm-vypolneniya-rabot-35.jpg'

const InstallationVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Монтаж систем вентиляции</h1>
                <p>Монтаж систем вентиляции Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Монтаж вентиляции – это процесс сборки, установки вентиляционных конструкций и механизмов, который должен быть не только качественно произведенным, но и соответствующим определенным требованиям и нормам СНиП и СН.</p>
                <p>Наши специалисты производят весь комплекс монтажных работ на объектах различного назначения:</p>
                <ul>
                    <li>Общественно-культурные здания (театры, клубы, музеи, кинозалы, развлекательные центры...)</li>
                    <li>Административные здания (бизнес-центры, офисы...)</li>
                    <li>Промышленные, производственные и складские здания (заводы, логистические центры, цеха, склады)</li>
                    <li>Детские, дошкольные учреждения, школы, профессиональные училища, ВУЗыСерверные, радиостанции, телецентры и другие объекты специального назначения</li>
                    <li>"Чистые помещения" (предприятия медицины, фармакологии, электронной промышленности)</li>
                    <li>Торговые центры, гипермаркеты и магазины</li>
                    <li>Предприятия индустрии питания (рестораны, кафе, бары, кондитерские...)</li>
                    <li>Жилые здания, элитное жилье, квартиры, коттеджи, загородные дома, пентхаусы, таунхаусы</li>
                    <li>Спортивные учреждения, SPA- и фитнес-центры, плавательные бассейны, бани</li>
                    <li>Санатории, больницы, пансионаты, дома отдыха</li>
                    <li>Автостоянки, автосервисы, АЗС.</li>
                </ul>
                <p>Профессиональный монтаж вентиляции представляет собой целый комплекс услуг по его устройству, заключающийся:</p>
                <ul>
                    <li>в выборе оптимального варианта вентиляции для конкретного здания;</li>
                    <li>в расчете величины воздухообмена необходимого для эффективной работы систем;</li>
                    <li>в «умном» проектировании, учитывающем архитектурные особенности здания;</li>
                    <li>в качественном монтаже вентиляционных систем;</li>
                    <li>в аккуратном демонтаже в случае необходимости;</li>
                    <li>в добросовестном гарантийном и послегарантийном сервисном обслуживании.</li>
                </ul>
                <p>Только грамотный монтаж вентиляционных систем гарантирует любому помещению создание той здоровой атмосферы и очищенной воздушной среды, которая оптимально соответствует необходимым гигиеническим нормам.</p>
                <p>Доверять монтаж следует специалистам, которые знают, как требуется располагать оборудование и вентиляционные отверстия, а также как оптимизировать маршрутизацию воздухоотводов, чтобы находящиеся в здании люди в любое время года могли наслаждаться комфортными условиями.</p>

                <h2>Основные правила монтажа</h2>
                <p>Монтажные работы можно выполнить самостоятельно либо с привлечением подрядных организаций, но в любом случае начинать нужно с согласования проекта и составления списка необходимых манипуляций. На подготовительном этапе осуществляется выбор подрядчика, который должен согласовать и утвердить разработанное техническое задание. Далее совместно с заказчиком составляется график работ, где подробно описываются сроки, поставки комплектующих и расходных материалов, тип оборудования и его установка.</p>
                <img src={img37} alt='' className='img-width' />
                <p><strong>Перед началом работ следует проверить соблюдение следующих факторов:</strong></p>
                <ul>
                    <li>что техническое задание разработано;</li>
                    <li>проект утвержден и согласован с подрядчиком, состоящим в государственном реестре СРОС;</li>
                    <li>все основные и межкомнатные стены, межэтажные перекрытия установлены;</li>
                    <li>готовность площадок для установки внутреннего и наружного вентиляционного оборудования обеспечена;</li>
                    <li>установлена гидроизоляция, если схемой предусмотрены мокрые фильтры;</li>
                    <li>выполнены все воздушные отверстия и каналы, заложенные в проекте;</li>
                    <li>присутствует основная вентиляционная шахта для естественной циркуляции воздуха;</li>
                    <li>в наличии опоры для крышных вентиляторов, предусмотренных в схеме;</li>
                    <li>стены воздуховодов покрыты штукатуркой.</li>
                </ul>
                <p>Если при проверке чего-то не оказалось, то это все фиксируется документально, как и все изменения, вносимые по ходу выполнения работ.</p>
                <img src={img45} alt='' className='img-width' />
                <p><strong>К основным правилам монтажа вентиляционных систем относятся следующие требования:</strong></p>
                <ul>
                    <li>продольные швы должны располагаться вверх;</li>
                    <li>крепления на болтах необходимо затягивать до максимального предела;</li>
                    <li>вентиляционные блоки устанавливаются на подготовленные крепления исключительно после монтажа;</li>
                    <li>крепеж должен учитывать массу самого воздуховода, чтобы она не передавалась механизмам;</li>
                    <li>крепежные элементы обязаны иметь виброизоляцию;</li>
                    <li>жесткие опоры предусмотрены для радиальных вентиляторов, с креплением анкерными болтами;</li>
                    <li>фильтры натягиваются ровно, исключая провисание;</li>
                    <li>электромоторы следует максимально точно подгонять к вентиляторам;</li>
                    <li>вращение крыльев должно быть свободным;</li>
                    <li>вентиляционные отверстия закрываются специальными решетками, размеры ячеек которых не должен превышать 7 см;</li>
                    <li>при устройстве вентиляционной системы количество изгибов необходимо минимизировать.</li>
                </ul>

                <h2>Основные этапы монтажа систем вентиляции</h2>
                <ul>
                    <li>Монтаж вентиляционных систем начинают сразу после утверждения проекта и согласования временных сроков.</li>
                    <li>Временной график предусматривает необходимую последовательность действий, начиная с поставки требуемых материалов и завершая осуществлением монтажа вентиляции.</li>
                    <li>После установления необходимых опорных конструкций производят монтаж сети воздухоотводов и звукоизоляции. При этом воздухоотводы, по которым распределяется приточный воздух, прокладываются в технических коробах, декоративных элементах и за подвесными потолками.</li>
                    <li>На следующем этапе устанавливается автоматическое оборудование, облегчающее управление системами вентиляции и включающее в себя рекуператоры, фильтры, разные виды вентиляторов, шумоглушители и многое другое.</li>
                    <li>Завершив монтаж, можно приступать к пусковым работам, во время которых система проходит испытание в различных режимах с целью выявления возможных неполадок для последующей коррекции настроек.</li>
                </ul>
                <img src={img18} alt='' className='img-width' />
                <p>Убедившись, что система успешно справляется с циркуляцией и очищением воздуха, процесс монтажа завершают подписанием акта о приеме работ. В случае необходимости с жильцами или персоналом объекта проводят краткий, обучающий курс по безопасной эксплуатации системы и ее автоматики.</p>
                <p>По желанию клиентов осуществляется услуга сервисного контроля, которая может исполняться, как на основе разового договора, так и на основе длительного сотрудничества.</p>

                <h2>Схемы установки</h2>
                <p>Выбор систем вентиляции зависит от нескольких параметров, в том числе от строительных материалов стен и перекрытий здания, площади помещения. Немаловажным фактором является степень чистоты наружного воздуха, при высоких показателях загрязненности нет резона закачивать его в дом. Одним из критериев, влияющих на выбор, являются затраты. Проект вентиляции должен учитывать финансовые возможности жильцов, но не противоречить здравому смыслу, иначе, сэкономив на мелочах, можно получить большие проблемы в будущем.</p>
                <p>По принципу работы можно выделить три типа вентиляции:</p>
                <ul>
                    <li>естественная;</li>
                    <li>принудительная;</li>
                    <li>приточно-вытяжная или смешанная.</li>
                </ul>
                <p><i>Перед тем как устанавливать в собственном доме одну из них, следует ознакомиться со всеми достоинствами, недостатками и спецификой монтажных работ.</i></p>
                <p><strong>Естественная</strong></p>
                <p>Данный вид вентиляции работает автономно за счет перепадов температуры и давления. Чаще всего используется в многоквартирных домах, где вдоль всего здания установлены вентиляционные шахты. В каждом помещении в санузлах и кухне оборудованы специальные каналы, которые выводят отработанный воздух наружу. В качестве приточных отверстий раньше предполагались форточки, поэтому часто мы слышим о том, что помещение необходимо проветривать. Также воздух проникает через щели в деревянных рамах. К сожалению, сегодня чаще устанавливают пластиковые оконные блоки, чем нарушают естественную циркуляцию воздушных потоков. В этом случае нужно предусмотреть дополнительную приточную вентиляцию либо чаще открывать окна.</p>
                <p>К плюсам подобного типа систем можно отнести автономную работу, низкую себестоимость установки и дальнейшего обслуживания, обеспечение движения естественных воздушных потоков, более пригодных для нормальной человеческой жизни.</p>
                <p><i>Среди недостатков следует выделить зависимость системы от погодных условий. Поскольку для функционирования вентиляции необходима разность температур, то в жаркую погоду воздушные потоки почти не движутся. Кроме того, во время сильных ветров возникает эффект обратной тяги, но это явление кратковременное.</i></p>
                <img src={img42} alt='' className='img-width' />
                <p><strong>Принудительная</strong></p>
                <p>Работа системы осуществляется за счет специальных электрических вентиляторов, которые вытягивают отработанный воздух и испарения наружу через вентиляционные каналы.</p>
                <p>Принудительная вентиляция чаще устанавливается в помещениях с массовым скоплением людей: производственных площадках, торговых центрах, спортзалах, аэропортах и вокзалах, учебных помещениях и институтских аудиториях.</p>
                <p>Достоинства подобного типа конструкций в том, что они совершенно не зависят от погоды за окном, очищение воздуха в помещении обусловлено системой фильтров, кроме того, можно менять температуру воздушного потока.</p>
                <p>В качестве недостатков специалисты выделяют следующие факторы.</p>
                <ul>
                    <li>Высокая цена на каждом этапе работы, начиная от стоимости самого оборудования и заканчивая монтажом и обслуживанием.</li>
                    <li>Поскольку детали системы чистить постоянно невозможно, а при работе на них оседает большое количество пыли и грязи, они зачастую сами являются рассадниками грибков и бактерий. Вследствие чего возможны заболевания дыхательных путей у жильцов: астма или аллергия. На сегодняшний день разработаны специальные обеззараживающие комплексы для принудительно вентиляции, они ее удорожают, но эффект на самом деле незначительный.</li>
                </ul>
                <img src={img54} alt='' className='img-width' />
                <p><strong>Смешанная</strong></p>
                <p>В данном случае в движении воздушных потоков задействованы естественная и принудительная составляющие. Через одни отверстия воздух заходит в дом за счет разницы давлений, через другие каналы выводится с помощью вентиляторов.</p>
                <img src={img40} alt='' className='img-width' />
                <p>Монтаж систем вентиляции Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default InstallationVentilation