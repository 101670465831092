import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img3 from '../../../img/3_small.png'
import img7 from '../../../img/7808ventilyaciya-s-recirkulyaciej-vozduha_0.jpg'

const RecirculationVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция с рециркуляцией</h1>
                <p>Вентиляцию с рециркуляцией Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вентиляции с рекуперацией по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <img src={img3} alt='' />
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Вентиляции с рециркуляцией воздуха представляет собой систему, где часть забираемого из помещения воздуха смешивается с холодным наружным воздухом, нагревает его до необходимой температуры и затем подает в помещение. Причем, эта система может быть применена только если воздух, поступающий из помещения, не содержит вредных веществ и токсичных примесей. Тогда как объем наружного воздуха в этой смеси должен соответствовать всем санитарно-гигиеническим нормам, указанным в СНиП, и должен быть не меньше значения санитарной нормы, предусмотренной для данного типа помещения.</p>
                <p>Примечание: Рециркуляцией не является перемешивание воздуха в пределах одного помещения, в том числе сопровождаемое нагреванием (охлаждением) отопительными агрегатами (приборами) или вентиляторами. Рециркуляция воздуха - это подмешивание воздуха помещения к наружному воздуху и подача этой смеси в данное или другие помещения.</p>

                <h2>Принципы работы вентиляции с рециркуляцией</h2>
                <p>Общая схема работы приточно-вентиляционной системы с рециркуляцией такова: через приток в помещение подается уличный воздух, который спустя некоторое время затягивается в систему вытяжки. Часть его безвозвратно выбрасывается на улицу, а часть поступает в смесительную камеру. Там воздух перемешивается со свежим притоком, охлаждая или нагревая его (зависит от типа и настроек системы), далее уже поступает в калорифер или кондиционер, из которых по вентиляционным трубам снова поступает в помещение. Основная цель рециркуляции – снижение нагрузки на системы обработки воздуха (калориферы, кондиционеры, проч.).</p>
                <p>Чтобы воздух в помещении оставался свежим и пригодным для дыхания, при использовании рециркуляции в вентиляционной системе необходимо соблюдать такие условия:</p>
                <ul>
                    <li>Объем поступающего извне чистого воздуха должен составлять минимум 10% от производительности приточной установки;</li>
                    <li>В поступающем в помещение воздухе должно содержаться максимум 30% вредных веществ от предельно допустимой их концентрации.</li>
                </ul>

                <h2>Вентиляция с рециркуляцией и нагревом</h2>
                <p>Холодный наружный воздух смешивается с теплым воздухом, забираемым из помещения, нагревается до необходимой температуры, а затем подается в помещение</p>
                <ul>
                    <li>вентиляторы включены</li>
                    <li>клапаны наружного и вытяжного воздуха открыты</li>
                    <li>нагреватель работает</li>
                    <li>приточные и вытяжные вентиляторы включены</li>
                    <li>клапаны наружного, вытяжного, рециркуляционного воздуха открыты, каждый в зависимости от установленного количества наружного воздуха</li>
                    <li>нагреватель работает</li>
                </ul>

                <h2>Вентиляция с рециркуляцией без нагрева</h2>
                <p>В переходный период при повышении температуры наружного воздуха и работающей системе отопления внутри помещения, задача приточной системы вентиляции сводится только к подаче свежего воздуха. При этом можно обойтись без дополнительного нагрева воздуха после рециркуляции.</p>
                <ul>
                    <li>вентиляторы включены</li>
                    <li>клапан рециркуляционного воздуха открывается пропорционально требованиям к температуре приточного воздуха</li>
                    <li>клапан наружного воздуха закрывается пропорционально требованиям к температуре приточного воздуха</li>
                    <li>нагреватель не работает</li>
                    <li>приточные и вытяжные вентиляторы включены</li>
                    <li>клапаны наружного, вытяжного и рециркуляционного воздуха открыты — в зависимости от требований к температуре приточного воздуха</li>
                    <li>нагреватель не работает</li>
                </ul>
                <p>Применение рециркуляции воздуха в системах вентиляции допускается лишь в холодный и переходный периоды года (для установок кондиционирования в любое время года). При этом в помещение должен подаваться наружный воздух в количестве не менее указанного выше.</p>
                <p><strong>Резиркуляция воздуха не допускается:</strong></p>
                <ul>
                    <li>из помещений, в воздухе которых имеются болезнетворные бактерии и грибки в концентрациях, превышающих установленные Госсанэпиднадзором Узбекистан, или резко выраженные неприятные запахи</li>
                    <li>из помещений, в которых максимальный расход наружного водуха определяется массой выделяемых вредных веществ 1-ого и 2-ого классов опасности</li>
                    <li>из помещений, в которых имеются вредные вещества, возгоняемые при соприкосновении с нагретыми поверхностями воздухонагревателей, если перед воздухонагревателем не предусмотрена очистка воздуха</li>
                    <li>из помещений категорий А и Б (кроме воздушных и воздушно-тепловых завес у наружных ворот и дверей)</li>
                    <li>из 5-метровых зон вокруг оборудования, расположенного в помещениях категорий В1-В4, Г и Д, если в этих зонах могут образовываться взрывоопасные смеси из горючих газов, паров, аэрозолей с воздухом</li>
                    <li>из лабораторных помещений научно-исследовательсткого и производственного назначения, в которых могут производиться работы с вредными или горючими газами, парами и аэрозолями</li>
                    <li>из систем местных отсосов вредных веществ и взрывоопасных смесей с воздухом</li>
                    <li>из тамбур-шлюзов</li>
                </ul>

                <h2>Основная схема выполнения приточно-вытяжной вентиляции с рециркуляцией воздуха</h2>
                <p>Чаще всего для организации приточно-вытяжной вентиляции с рециркуляцией применяется схема, основанная на использовании связки фанкойла и чиллера. Фанкойл заменяет внутренний блок кондиционера, работая активной батареей. Это сборный агрегат, в котором располагается дренаж для организации оттока конденсата, образующегося в летний период, вентилятор, теплообменник и воздушный фильтр. Чиллер – водяной калорифер, который в зависимости от времени года подогревает или охлаждает воду, далее передающую свою температуру поступающему воздуху. </p>
                <p>Регулирование температуры теплоносителя в чиллере осуществляется с пульта управления. Данная система позволяет осуществлять полноценное или частичное воздушное отопление зимой и кондиционирование летом. Объем помещения значения не имеет, так как существуют системы, разработанные специально для супермаркетов и прочих больших строений. Преимущество данной системы заключается в возможности вентилирования в едином климатическом режиме большого количества помещений в одном здании. Разводка точек втягивания и выхлопа воздуха от фанкойла осуществляется с помощью стандартных вентиляционных воздуховодов.</p>
                <p>Что касается управления рециркуляцией, то оно осуществляется с помощью дистанционно регулируемых заслонок или решеток, управление которыми выполняется с пульта.  Температура поступающего воздуха различается в зависимости от времени года, тогда как температура приточного воздуха, подаваемого в помещение, должна быть комфортной. Ее необходимое значение выставляется на пульте управления. Чиллер нагревает или охлаждает  уличный воздух до заданного значения, он поступает в теплообменник, смешиваясь с возвращенным из помещения воздухом, в результате чего из приточного диффузора он выходит с оптимальной температурой.</p>
                <p>Количество воздуха, который необходимо забирать из помещения подмешивать к уличному, зависит от заданных параметров температуры в помещении. Именно по данному критерию определяется устанавливаемое положение заслонок. Сами заслонки монтируются в точках забора воздуха из помещения, а также на магистрали забора уличного воздуха. Управление заслонками синхронизировано и осуществляется с пульта. Его параметры настраиваются специалистами в каждом случае индивидуально. </p>

                <h2>Дополнительные схемы рециркуляционной вентиляции</h2>
                <ul>
                    <li>Рециркуляция воздуха внутри помещения при помощи потолочного вентилятора</li>
                </ul>
                <p>Рециркуляция с помощью одного потолочного вентилятора и разведенных в пределах одного помещения воздуховодов не предназначена для подачи или изменения объема притока наружного воздуха. Такие схемы, лишенные фанкойла и подключения к забору уличного воздуха применяются в ряде типов помещений (кафе, магазины, административные здания) исключительно для увеличения подвижности воздуха в рабочей зоне.</p>
                <p>Внимание! Данный вариант нельзя назвать полноценной рециркуляцией, ведь при нем воздух забирается из одной части помещения в другую, чтобы он не застаивался.</p>
                <ul>
                    <li>Рециркуляция воздуха внутри помещения при помощи фанкойла</li>
                </ul>
                <p>Рециркуляция по данной схеме довольно распространена. В фанкойле присутствует теплообменник для охлаждения или подогрева воздуха, и промышленный вентилятор, осуществляющий его перемещение. Фактически, это канальный кондиционер, точнее его аналог. Такая система монтируется отдельно от основной приточно-вытяжной вентиляции и работает следующим образом: в одних зонах помещения забирается воздух, по воздуховодах подается в теплообменник, где подогревается или охлаждается, после чего другой сетью воздуховодов отправляется в другие зоны помещения.</p>
                <p>Применение данной системы можно считать рациональным в малых и средних помещениях, где приточно-вытяжная вентиляция представлена, например, только стеновыми вентиляторами, смонтированными в вентиляционные шахты. Здесь выполнение полноценной комбинированной рециркуляционной вентиляции затруднено и нецелесообразно, а такой подход позволит создать приемлемый микроклимат при минимальных затратах без необходимости полной переделки всей вентиляции.</p>
                <ul>
                    <li>Рециркуляция при помощи фанкойла с подмешиванием уличного воздуха</li>
                </ul>
                <p>Основой здесь выступает та же самая система с фанкойлом, что и в предыдущем случае, с единственным отличием – она имеет возможность забирать воздух и с улицы. Уличный забор регулируется вручную или автоматически управляемой заслонкой. Ее применение оправдано в основном тогда, когда в помещении уже смонтирована эффективная приточно-вытяжная вентиляция, модернизировать которую нет желания или возможности.</p>
                <p>Такая система может применяться для нагрева или охлаждения воздуха в помещении, а также в качестве вспомогательной приточной установки.</p>

                <h2>Помещение, где запрещено использование рециркуляции</h2>
                <p>Согласно нормативному документу СНиП 41-01-2003 вентиляция с рециркуляцией воздуха не может быть установлена в помещениях:</p>
                <ul>
                    <li>где расходы воздушных масс определяются из расчета количества образуемых вредных веществ;</li>
                    <li>где присутствуют высокие концентрации различных грибков и болезнетворных микробов;</li>
                    <li>где присутствуют вредные вещества, возгоняемые при непосредственном контакте с нагретыми поверхностями;</li>
                    <li>которые причисленные к категории А и Б;</li>
                    <li>где осуществляются работы, связанные с использованием вредных и горючих газов, а также пара;</li>
                    <li>которые, причислены к категориям В1 и В2 и где может осуществляться выделение вредоносной пыли и аэрозолей;</li>
                    <li>где присутствуют системы, содержащие в себе местные отсосы вредоносных веществ и взрывоопасных смесей; созданных их тамбуров-шлюзов. </li>
                </ul>
                <p><strong>Рециркуляция воздуха ограничивается:</strong></p>
                <ul>
                    <li>пределами одной квартиры, номера в гостинице или одноквартирного дома</li>
                    <li>пределами одного помещения в общественных зданиях</li>
                    <li>пределами одного или нескольких помещений, в которых выделяются одинаково вредные вещества 1-4-ого классов опасности, кроме помещений, приведенных в разделе выше "Рециркуляция воздуха не допускается".</li>
                </ul>

                <h2>Плюсы и минусы системы</h2>
                <ul>
                    <li><strong>ПЛЮС СИСТЕМЫ:</strong></li>
                </ul>
                <p>Система с рециркуляцией воздуха позволяет снизить энергопотребление на нагрев воздуха (иногда и на охлаждение), т. к. тепловая мощность нагревателя или охладителя расходуется в основном на изменение температуры только той части воздуха, которая забирается с улицы.</p>
                <ul>
                    <li><strong>НЕДОСТАТКИ СИСТЕМЫ:</strong></li>
                </ul>
                <p>Не везде можно применять (смотрите ниже СНиП)</p>
                <p>При эксплуатации системы в условиях холодного климата минус системы заключается в недостаточно хорошем смешивании наружного и рециркуляционного воздуха.</p>

                <h2>Вытяжка с рециркуляцией: устройство вытяжного типа и вентиляции</h2>
                <p>Тогда как при использовании проточной вытяжки грязный воздух уходит из помещения через воздуховод, вытяжка с рециркуляцией имеет совершенно другой принцип действия.</p>
                <p>Здесь такой элемент, как воздуховод отсутствует, а само устройство оснащено эффективными фильтрами-уловителями. При проходе через них воздух освобождается от всех вредных микропримесей и нежелательного запаха, а затем снова подается в помещение.</p>

                <p><strong>Как работает рециркуляционная вытяжка</strong></p>
                <p>Вытяжка рециркуляционного типа работает по замкнутому циклу. По сути, вытяжным устройством назвать ее можно только условно, поскольку воздух из помещения она не выводит, а только очищает и возвращает назад. Загрязненный воздух попадает внутрь корпуса устройства под воздействием тяги, создаваемой лопастями вентилятора, совершающего вращение в плоскости горизонтальной к поверхности кухонной плитой.</p>
                <p>Двигаясь по заданной траектории, воздушная масса сталкивается с фильтром грубой очистки. Пройдя через него, она оставляет на его тонкосетчатой поверхности жировые включения, частички сажи и пыли.</p>
                <img src={img7} alt='' />
                <p>Вытяжка такого типа выручит, когда вентиляционная система дома не работает в полную силу или вентканалы засорились настолько, что тяга в них полностью отсутствует</p>
                <p>Далее воздушный поток под воздействием давления проходит через фильтр тонкой очистки, где избавляется от запахов и остатков мельчайших части, затем возвращается в комнату. Этот фильтр представляет собой обыкновенный картридж, наполнителем которого является гранулированный уголь. Угольный фильтр — изделие одноразовое, поэтому по мере загрязнения, он подлежит замене.</p>
                <p>Поскольку этот тип вытяжки не взаимодействует с вентиляционной системой, дизайнерами разработаны телескопические вытяжки. При работе они создают мощную боковую тягу, за счет которой всасываются все грязные воздушные массы. Как только потребность в этом отпадает, устройство прячется в столешнице. Такое решение не только оригинально, но и очень удобно.</p>
                <p>Вентиляцию с рециркуляцией Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вентиляции с рекуперацией по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default RecirculationVentilation