import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img1 from '../../../img/23-05-2014 18-20-41.jpg'
import img2 from '../../../img/23-05-2014 18-19-31.jpg'

const ProductionVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция производства</h1>
                <p>Вентиляцию для производственного помещения Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Каждое промышленное помещение, будь то огромный заводской цех или маленькая частная мастерская, должно быть оборудовано системой вентиляции. Проектирование вентиляции промышленного здания осуществляется на этапе подготовки строительной документации. Расчёт вентиляции производственного помещения производится в соответствии с Санитарными нормами и правилами.</p>

                <h2>Важные особенности вентиляции на производстве</h2>
                <ul>
                    <li>Наличие различных технологических процессов: производства и фасовки жидких моющих средств, порошкообразных моющих средств, пластиковой упаковки и т. д.</li>
                    <li>Большое количество локальных источников, выделяющих вредные вещества.</li>
                    <li>Разнообразие веществ, на ассимиляцию которых производится расчет требуемых воздухообменов.</li>
                </ul>
                <p>При проектировании вентиляции обязательно учитывают:</p>
                <ul>
                    <li>площадь и объём производственного помещения, высоту потолков;</li>
                    <li>категорию работ и производственных операций;</li>
                    <li>количество работающих в помещении людей;</li>
                    <li>продолжительность нахождения людей в производственном помещении;</li>
                    <li>уровень загруженности промышленного помещения;</li>
                    <li>расположение рабочих мест.</li>
                </ul>
                <p>А также ряд других специфических факторов:</p>
                <p>Вентиляция производственных цехов требует учета многих специфических условий, главное из которых – учет типа производства. Если производство связано, например, с выделением большого количества пыли и тепла, то в этом случае  устанавливаются мощные вентиляторы, задачей которых будет удаление выбросов из цеха и отвод излишнего теплового излучения. На предприятиях подобного типа обычно используются воздуховоды больших диаметров (до 6 метров). Кондиционирование в таких цехах экономически нецелесообразно и поэтому достаточно установки только вытяжной вентиляции.</p>
                <p>Если же на предприятии используется высокоточное оборудование, или продукция не должна подвергаться перепадам температур, то в этом случае наилучшим вариантом станет установка вентиляционной системы на основе чиллера, которая способна поддерживать точно заданную температуру.</p>
                <p>Некоторые системы вентиляции способны удалять твердые отходы с места производства (стружка, пыль). Данная система использует специальные устройства, которые отделяют отходы от воздуха и собирают в бункер.</p>
                <p>В сборочных производствах выгодно использовать традиционную приточно-вытяжную вентиляционную систему.</p>
                <img src={img1} alt='' className='img-width' />

                <h2>Способы вентиляции</h2>
                <p>Вентиляция производственных помещений осуществляется несколькими способами:</p>
                <ul>
                    <li>приточным,</li>
                    <li>вытяжным</li>
                    <li>приточно-вытяжным.</li>
                    <li>На крупных производствах применяется исключительно последний вариант.</li>
                    <li>В частных мастерских и небольших цехах обычно устанавливается механическая вытяжная вентиляция.</li>
                </ul>
                <p>Приток свежего воздуха обычно происходит путём аэрации или инфильтрации, как разновидностей естественной вентиляции. В небольшом производственном помещении вытяжной механической вентиляции обычно бывает достаточно.</p>
                <p>По охвату помещений различают местную и общеобменную промышленную вентиляцию. Часто используются одновременно оба типа. Общеобменная вентиляция призвана, в первую очередь, бороться с чрезмерным повышением температуры производственного помещения в процессе протекания технологических процессов. Местная вентиляция устанавливается в местах наиболее значительного выброса ядовитых веществ или технологической пыли. Особые требованияпредъявляются к устройству местной вытяжной вентиляции: она должна быть организована таким образом, чтобы вредные производственные выбросы не оказались в зоне вдыхаемого рабочим воздуха.</p>

                <h2>Расчёт вентиляции</h2>
                <p>Расчёт вентиляции промышленных помещений производится с таким расчётом, чтобы не дать распространится вредным веществам по всему цеху. Благодаря действию общеобменной вентиляции, концентрация вредных веществ приводится к допустимой норме.</p>
                <p>По времени действия вентиляция складов и производственных помещений может быть постоянной и аварийной. Постоянная вентиляция должна работать так, чтобы при штатном режиме работы производства поддерживатьв помещении нормальный микроклимат. Аварийная вентиляция включается в работу тогда, когда при каких-либо обстоятельствахвыходит из строя или не справляется с вентилированием помещения основная вентиляция.</p>
                <img src={img2} alt='' className='img-width' />
                <p>Вентиляцию для производственного помещения Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ProductionVentilation