import React from 'react'
import { Link } from 'react-router-dom'
import './householdSemiAirConditioners.css'
import split_sistemy from '../../../img/split-sistemy_small1_small_small.jpg'
import d4_small from '../../../img/d4_small.jpg'
import d6_small from '../../../img/d6_small.jpg'
import ab357028497_w640_h640_lsm_h_tha2_small from '../../../img/ab357028497_w640_h640_lsm_h_tha2_small.jpg'
import fha35a_small from '../../../img/fha35a_small.jpg'
import d5_small from '../../../img/d5_small.jpg'
import Super_Multi_Plus from '../../../img/Super_Multi_Plus.jpg'
import o16521inv24 from '../../../img/16521inv24.gif'
import o18767826 from '../../../img/18767826.jpg'
import Form from '../../../components/form/Form'

const HouseholdSemiAirConditioners = () => {
    return (
        <>
            <div className='household-semi-air-conditioners-page'>
                <h1>Бытовые кондиционеры и полупромышленные системы кондиционирования</h1>
                <p>Бытовые и полупромышленные кондиционеры Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка кондиционеров по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Бытовые и полупромышленные системы кондиционирования квартиры, дома, коттеджа, офиса, административных и общественных помещений и зданий:</p>

                <Link to='/'>
                    <h2>Сплит-системы</h2>
                </Link>
                <div className='products-wrapper'>
                    <Link to='/'>
                        <div className='product'>
                            <p>Настенные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${split_sistemy})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Кассетные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${d4_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Канальные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${d6_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Напольно-потолочные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${ab357028497_w640_h640_lsm_h_tha2_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Потолочные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${fha35a_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Напольные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${d5_small})` }}></div>
                        </div>
                    </Link>
                </div>

                <Link to='/'>
                    <h2>Мульти сплит-системы</h2>
                    <img src={Super_Multi_Plus} alt='' />
                </Link>
                <p>FIDCLIMAT осуществляет полный комплекс работ по установке бытовых и полупромышленных систем кондиционирования от проектирования и комплектации до монтажа оборудования и сдачи проектов «под ключ».</p>
                <p>После подписания акта приемки-передачи все установленное оборудование принимается на гарантийное обслуживание, а по желанию заказчика - на сервисное техническое обслуживание.</p>
                <p>К Вам приедет наш специалист, который на месте разрешит множество вопросов, о существовании которых Вы, возможно, и не догадывались:</p>
                <ul>
                    <li>учтет все факторы для расчета холодопроизводительности кондиционера;</li>
                    <li>изучит особенности планировки и технического оснащения помещения;</li>
                    <li>сориентирует по моделям кондиционеров, заслуживающих доверия;</li>
                    <li>предостережет от неудачного выбора места установки;</li>
                    <li>произведет необходимые замеры;</li>
                    <li>После этого предложит вам несколько вариантов коммерческого предложения с различной комплектацией оборудования и сделает окончательный расчет стоимости работ;</li>
                    <li>после принятия решения об установке той или иной системы, оборудование доставляется на объект и наши специалисты выполняют его монтаж и пуско-наладку.</li>
                </ul>
                <p>На заметку!  Без выезда специалиста на объект, по телефонной договоренности, монтаж назначается в единичных случаях. От профессионализма менеджера зависит, насколько Вы будете удовлетворены надежной и правильной работой Ваших систем кондиционирования.</p>

                <h2>Общая информация о кондиционерах</h2>
                <p>Кондиционер это устройство для поддержания оптимальных климатических условий в помещениях строительных сооружений, транспортных средств и другой техники.</p>
                <p>Кондиционирование воздуха - это процесс создания и автоматического поддержания внутри закрытого помещения определенных параметров (температуры, уровня относительной влажности, чистоты, скорости движения воздуха), наиболее благоприятных для самочувствия людей и комфортных условий труда.</p>

                <h2>Типы кондиционеров</h2>
                <p>Все кондиционеры можно разделить на 3 группы (в зависимости от области применения):</p>
                <ul>
                    <li>Бытовые - к ним относятся сплит-системы настенного, напольно-потолочного типа и др.Их мощность составляет приблизительно до 6-7 кВт, и они применяются для небольших помещений.</li>
                    <li>Полупромышленные - сплит-системы канального, кассетного и других типов. Они занимают промежуточное положение между бытовыми и промышленными кондиционерами. Как правило, мощность таких кондиционеров составляет от 7 кВт и выше (но есть и исключения). Они могут использоваться как в бытовых, так и в офисных помещениях: в квартирах, коттеджах, офисах, магазинах и т.д.</li>
                    <li>Промышленные кондиционеры - это очень мощные кондиционеры, поэтому они применяются для охлаждения больших площадей, к примеру, для центрального охлаждения целых зданий. К ним относятся мультизональные, прецизионные, центральные, крышные кондиционеры, система чиллер-фанкойл.</li>
                </ul>
                <p>Кондиционеры также делятся еще на две категории - инверторные и не инверторные и работающие на холод/обогрев и только холод.</p>

                <h2>Что такое инверторный кондиционер?</h2>
                <p>Инверторные кондиционеры имеют специальный блок-инвертор, который плавно регулирует частоту оборотов компрессоров в зависимости от необходимой мощности охлаждения, а обычные неинверторные кондиционеры работают короткими включениями на полную мощность. Например, если обычная сплит-система сразу включается на полную мощность и выключает компрессор, когда температура воздуха в помещении достигает заданной отметки, то у инверторных кондиционеров компрессор не выключается, а переходит на «холостые» обороты, тем самым снижая его производительность.</p>
                <img src={o16521inv24} alt='o16521inv24' />
                <p>Достоинства инверторного кондиционера:</p>
                <ul>
                    <li>Охлаждение помещения происходит быстрее;</li>
                    <li>Более низкий уровень шума;</li>
                    <li>Выше срок службы компрессора;</li>
                    <li>Экономия электроэнергии более, чем на 35-44%;</li>
                    <li>Более высокая точность поддержания заданной температуры, чем у неинверторного кондиционера (+ -- 0,5 С˚ от заданной отметки);</li>
                    <li>Морозоустойчивость (т.к. инверторные кондиционеры никогда не выключаются, нет проблем с пуском компрессора при отрицательных температурах).</li>
                </ul>
                <img src={o18767826} alt='' />

                <h2>Холод-обогрев и только холод</h2>
                <p>Кондиционеры способны не только охлаждать воздух, но и нагревать его (холод-обогрев). Они эффективны при положительной температуре на улице в период межсезонья. Тогда они вполне могут заменить обогреватель.Те же кондиционеры, которые работают исключительно в режиме охлаждения (только холод), не способны нагревать воздух. Их эксплуатация возможна только при температуре до 0°С, иначе кондиционер может выйти из строя.</p>
                <p>Сплит-система происходит от английского слова «split» - расщеплять, разделять.Она состоит из двух блоков: один устанавливается в помещении (внутренний), а другой, наиболее шумный, выносится наружу (наружный). Внутренние блоки подразделяются на следующие типы: кассетные, настенные, канальные, колонные, напольные, напольно-потолочные и подпотолочные (подробнее о типах- в разделе «О типах кондиционеров»). К достоинствам сплит-системы можно отнести простоту конструкции, позволяющую получать достаточно низкую стоимость кондиционера, высокую эффективность, низкий уровень шума, быструю и легкую установку. Недостатком же такой системы можно считать невозможность подмеса свежего воздуха в помещение.</p>
                <p>Мульти сплит система - это сплит-система, у которой на один наружный блок приходится два и более внутренних блоков, установленных в разных помещениях. Главный плюс этой системы - это меньшее, по сравнению с обычной сплит-системой, количество наружных блоков, что позволяет экономить место на внешней стене здания и не перегружать ее блоками. Эту систему используют, как правило, когда невозможно разместить несколько наружных блоков на внешней стене объекта.</p>

                <h2>Характеристики кондиционера</h2>
                <p><strong>Мощность охлаждения</strong></p>
                <p>Основной характеристикой при выборе модели кондиционера является его мощность.</p>
                <p><strong>Потребляемая мощность</strong></p>
                <p>Потребляемая мощность это не мощность охлаждения (о ней было сказано выше). Она примерно в 3 раза меньше мощности охлаждения. Именно поэтому кондиционер можно включать в обычную розетку без опасений.</p>
                <p><strong>Уровень шума</strong></p>
                <p>Уровень шума измеряется в Децибелах (дБ) - единице, показывающей во сколько раз один звук громче другого. Для большинства бытовых кондиционеров уровень шума внутреннего блока - 26-36дБ, наружного блока - 38-54 дБ. Шум работающего внутреннего блока не превышает уровень шума офисного помещения, поэтому обращать внимание на уровень шума кондиционера имеет смысл, еслипланируете установить его в тихом помещении (спальня, личный кабинет и т.д.). Любой кондиционер может работать внескольких десятках режимов, и каждый режим имеет свой уровень шума. Поскольку основным источником шума внутреннего блока является поток воздуха, проходящего через вентилятор, радиатор и распределительные жалюзи, то логично измерять уровень шума на самой низкой скорости вентилятора.</p>
                <p><strong>Расход воздуха</strong></p>
                <p>Вентилятор внутреннего блока может вращаться с разной скоростью, таким образом изменяя количество проходящего через внутренний блок воздуха (этот параметр называется расход воздуха, производительность по воздуху или «прокачка» кондиционера, которая измеряется в м3./час). В автоматическом режиме скорость вентилятора выбирается исходя из текущей и заданной температуры - чем больше текущая температура отличается от заданной, тем выше скорость вентилятора.</p>

                <h2>Дополнительные функции кондиционера</h2>
                <p>Помимо охлаждения и обогрева, у кондиционера есть еще и другие функции.</p>
                <p><i>1) «Sleep mode»-таймер сна</i></p>
                <p>Он создает оптимальные условия для отдыха и экономит энергию. В этом режиме скорость вращения вентилятора внутреннего блока держится на минимальном значении, чтобы снизить уровень шума. И температура плавно понижается (режим охлаждения) или повышается (режим обогрева) на 2-3 градуса в течение нескольких часов.</p>
                <p><i>2) «I Feel»</i></p>
                <p>При включении этого режима кондиционер будет поддерживать заданную температуру именно в той точке, где находится пульт управления. В этом случае направление воздушного потока не изменяется. Эту функцию кондиционера стоит использовать только тогда, когда человек остается один в помещении. Иначе можно заморозить всех тех, кто сидит ближе к внутреннему блоку (там зона более низких температур).</p>
                <p><i>3) «Swing» - направление воздушного потока</i></p>
                <p>Данная функция автоматически включает движение воздухораспределительных заслонок или жалюзи внутреннего блока по вертикали (вверх-вниз). Это способствует более равномерному распределению воздушного потока по помещению.</p>
                <p><i>4) Таймер включения/выключения</i></p>
                <p>С помощью 24-часового таймера можно установить время автоматического включения и выключения кондиционера. Допустим, можно включать кондиционер за час до возвращения с работы. В некоторых моделях имеется таймер на 12 часов и один таймер на включение, а другой на выключение.</p>
                <p>5) «Turbo» или «Powerful»</p>
                <p>Эта функция кондиционера позволяет ему работать в режиме охлаждения/обогрева с максимальной скоростью вращения вентилятора до тех пор, пока заданная температура не будет достигнута.</p>
                <p><i>6) Фильтрация или очистка воздуха</i></p>
                <p>В кондиционерах может применяться один или несколько фильтров: воздушные, электростатические и угольные. Первый имеется практически во всех кондиционерах и представляет собой мелкую сетку, задерживающую крупные пылинки и механические примеси (его также называют фильтром грубой очистки). Электростатический фильтр - это сетка из волокон. Он удерживает мелкие заряженные частицы, микроорганизмы, пыльцу. Угольный фильтр устраняет запахи, табачный дым и наиболее мелкие частицы пыли. Последние два фильтра относятся к фильтрам тонкой очистки.</p>
                <p>Сейчас производители климатической техники используют и другие средства фильтрации воздуха, более новые. Катехиновый фильтр у Panasonic, к примеру, который помимо мелких частиц пли, табачного дыма и других обычных загрязнителей воздуха улавливает и «обезвреживает» микроскопические вирусы и бактерии. Или фильтр-ионизатор у LG (система очистки воздуха «Плазма»). Он уничтожает органические загрязнения (вирусы,микробы и т.д.), а более крупные ионизируются и накапливаются на каталитический фильтр. У Samsung активное развитие получило направление био-составляющих бытовой техники («антибактериальная формула» кондиционеров этой марки).</p>
                <p><i>7) Auto Restart</i></p>
                <p>Благодаря этой функции кондиционер возобновляет свою работу в прежнем режиме при кратковременном отключении электроэнергии (параметры настройки сохраняются в памяти в течение 48 часов).</p>
                <p><i>8) Hot Start</i></p>
                <p>Когда кондиционер работает в режиме обогрева при отрицательной температуре на улице, первые несколько минут вентилятор внутреннего блока не включается, чтобы предотвратить подачу холодного воздуха в помещение.</p>
                <p><i>9) Deice или Defrost</i></p>
                <p>Данная опция позволяет размораживать наружный блок сплит-системы в зимний период. Это делается для того, чтобы удалить иней из теплообменника наружного блока. Иначе он будет накапливаться и заблокирует поток воздуха через теплообменник. В результате, наружный блок прекратит отбор теплоты от уличного воздуха.</p>
                <p><i>10) Автоматический режим</i></p>
                <p>Кондиционер сам управляет выбором режима работы (охлаждение, обогрев и т.д.) для поддержания комфортной температуры.</p>
                <p><i>11) Вентиляция</i></p>
                <p>В этом режиме работает только вентилятор внутреннего блока без включения компрессора, что позволяет равномерно распределить воздух по помещению. Это необходимо зимой, когда теплый воздух от батарей и обогревателей скапливается под потолком, а холодный - внизу.</p>
                <p><i>12) Увлажнение</i></p>
                <p>В последнее время появились модели кондиционеров, которые могут не только охлаждать и обогревать, но и увлажнять воздух. Благодаря этому можно поддерживать комфортные значения влажности и температуры воздуха в помещении в течение всего года.</p>
                <p>Бытовые и полупромышленные кондиционеры Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка кондиционеров по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HouseholdSemiAirConditioners