import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './centralAirConditioners.css'

import a2704 from '../../../img/27-05-2014 19-18-19.jpg'
import a2705 from '../../../img/27-05-2014 19-17-41.jpg'
import a2706 from '../../../img/27-05-2014-19-57-43.jpg'
import a2707 from '../../../img/27-05-2014 20-20-58.jpg'
import a2708 from '../../../img/27-05-2014 19-58-40.jpg'
import a2709 from '../../../img/27-05-2014 16-01-13.jpg'
import a2710 from '../../../img/27-05-2014 20-03-29.jpg'
import a2711 from '../../../img/27-05-2014 20-10-51.jpg'
import a2712 from '../../../img/27-05-2014 19-56-02.jpg'
import a2713 from '../../../img/27-05-2014 20-23-39.jpg'
import a2714 from '../../../img/27-05-2014 20-26-03.jpg'

const CentralAirConditioners = () => {
    return (
        <>
            <div className='central-air-conditioners-page'>
                <h1>Центральные кондиционеры</h1>
                <p>Центральный кондиционер Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку центральных кондиционеров по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Центральный кондиционер это устройство, предназначенное решить распространенную проблему больших зданий: создание комфортных условий для жизнедеятельности человека. Сюда входит и подача свежего воздуха, и поддержание необходимого уровня его влажности, температура воздуха в помещении, а также охлаждение, обогрев и очистка приточных воздушных масс.</p>
                <p>Они применяются для поддержания оптимальных климатических параметров в промышленных и бытовых помещениях больших площадей:</p>
                <ul>
                    <li>крупные коммерческие центры,</li>
                    <li>административные здания,</li>
                    <li>плавательные бассейны,</li>
                    <li>больницы,</li>
                    <li>закрытых стадионов и спорткомплексов,</li>
                    <li>промышленные предприятия и др.</li>
                </ul>
                <p>Центральные кондиционеры дают возможность реализовать все необходимые процессы обработки  воздуха:</p>
                <ul>
                    <li>перемещение воздуха,</li>
                    <li>нагрев,</li>
                    <li>охлаждение,</li>
                    <li>увлажнение,</li>
                    <li>осушение,</li>
                    <li>регенерацию и рекуперацию теплоты</li>
                    <li>очистку от пыли, запахов и микроорганизмов,</li>
                </ul>
                <p>Центральный кондиционер является неавтономным, то есть для работы ему необходим внешний источник холода или вода от чиллера, фреон от внешнего компрессорно-конденсаторного блока, горячая вода от системы центрального отопления или бойлера. Центральные кондиционеры предназначены для обслуживания нескольких помещений или одного большого помещения, равного по площади театральному залу, закрытому стадиону, производственному цеху и т.д. При этом могут быть установлены не один, а несколько кондиционеров данного типа.</p>

                <h2>Типы центральных кондиционеров</h2>
                <img src={a2704} alt='' />
                <p>Прямоточные центральные кондиционеры обрабатывают только наружный воздух. Приточная часть включает в себя воздушные заслонки, вентилятор, секции фильтрации, шумоглушения, обогрева, охлаждения, осушения и увлажнения. Вытяжная часть состоит из воздушной заслонки и вентилятора. Воздушные заслонки управляются при помощи сервопривода.</p>
                <p>Недостатком прямоточных центральных кондиционеров является необходимость больших мощностей секций обогрева и охлаждения, а также подача воздуха с одинаковой температурой во все помещения.</p>
                <img src={a2705} alt='' />
                <p>Решением данных проблем может стать использование прямоточной системы с переменным расходом воздуха. В этом случае в каждом помещении устанавливаются отдельные датчики температуры, которые управляют заслонками на входе и выходе воздуха в каждое помещение. Данная система позволяет поддерживать заданную температуру воздуха за счет изменения расхода воздуха, что не приемлемо там, где существуют жесткие требования к кратности воздухообмена.</p>
                <p>Кондиционеры с рециркуляцией воздуха обрабатывают смесь наружного и рециркуляционного т.е. вытяжного воздуха.</p>
                <img src={a2706} alt='' />
                <ul>
                    <li>Первая рециркуляция - подмешивание рециркуляционного воздуха к наружному перед теплообменником первого подогрева, что снижает расход тепла на первый подогрев.</li>
                    <li>Во время второй рециркуляции происходит подмешивание рециркуляционного воздуха к наружному воздуху, прошедшему обработку в воздухоохладителе или камере орошения перед вентилятором. При этом отпадает необходимость включения в работу теплообменника второго подогрева в летний период.</li>
                </ul>
                <img src={a2707} alt='' />
                <p>Центральные кондиционеры с рекуперацией воздуха используют тепло вытяжного воздуха для подогрева приточного. Они дают большую экономию, чем рециркуляционные и при этом сохраняют пропорции свежего воздуха в притоке. Существуют несколько типов рекуператоров, которые позволяют экономить от 10 до 90% тепла, удаляемого из помещения с вытяжным воздухом.</p>
                <img src={a2708} alt='' />
                <ul>
                    <li>Пластинчатый рекуператор представляет собой теплообменник, в котором приточный и вытяжной потоки, не смешиваясь, движутся по каналам, разделенным пластинами, через которые происходит теплообмен. В холодное время года на пластинах рекуператора возможно образование конденсата, а при температуре -10 -150С существует опасность намерзания льда и необходимо срабатывание оттайки. КПД таких рекуператоров в обычном режиме составляет 70%.</li>
                </ul>
                <img src={a2709} alt='' />
                <p>Таким образом, сектора ротора, перемещаясь перпендикулярно потокам, сначала аккумулируют тепло или холод вытяжного воздуха, а затем отдают его приточному. Ротор обычно располагается в стальном корпусе и приводится в движение мотором-редуктором через клиноременную передачу. Основное преимущество рекуператоров данного типа в том, что возможно управление процессом переноса теплоты плавным изменением числа оборотов ротора. Для этого они оснащаются частотными регуляторами, что позволяет также организовать эффективную защиту от обмерзания. Основным недостатком роторных рекуператоров является частичное смешение вытяжного и приточного воздуха (до 5%), что делает не всегда возможным их использование, однако для большинства офисных и бытовых помещений это вполне приемлемо.</p>
                <ul>
                    <li>Рекуператоры с промежуточным теплоносителем применяются там, где приток и вытяжка расположены удаленно. При этом не происходит смешения потоков. В качестве рекуператора используются теплообменники, расположенные в приточном и вытяжном каналах, которые соединены между собой системой трубопроводов, заполненных промежуточным теплоносителем (незамерзающая жидкость, например, 40% раствор этиленгликоля). Теплоноситель забирает тепло с помощью теплообменника, расположенного в вытяжке и передает его приточному воздуху через теплообменник в притоке. КПД таких рекуператоров зависит от разницы температур между потоками и может достигать 60%</li>

                </ul>
                <img src={a2710} alt='' />
                <ul>
                    <li>Рекуператор - "тепловой насос" с фреоновым контуром представляет собой традиционную холодильную установку с компрессором, дросселирующим устройством, испарительным и конденсаторным теплообменниками, которые устанавливаются в приточном и вытяжном воздуховодах. Данная схема является реверсивной. Для этого установка комплектуется 4-х ходовым вентилем. Смешения потоков в данном случае не происходит. Производительность данной системы индивидуальна и зависит от расхода и температуры воздуха.</li>
                </ul>
                <img src={a2711} alt='' />

                <p><strong>Центральные кондиционеры могут подразделяться:</strong></p>
                <p>По напору встроенных вентиляторов:</p>
                <ul>
                    <li>низкого давления (до 100 кг/м2);</li>
                    <li>среднего давления (от 100 до 300 кг/м2);</li>
                    <li>высокого давления (выше 300 кг/м2).</li>
                </ul>
                <p>По времени работы:</p>
                <ul>
                    <li>Сезонные</li>
                    <li>Круглогодичные.</li>
                </ul>
                <p>Возможны различные комбинированные системы на базе центральных кондиционеров.</p>
                <p>В системах кондиционирования, совмещенных с воздушным отоплением здания или помещения и предназначенных для круглогодичной эксплуатации, устанавливается, как правило, не менее двух кондиционеров производительностью по 50% общей производительности системы, при этом секция нагрева должна иметь теплопроизводительность, достаточную для отопления помещений.</p>
                <p>Центральные кондиционеры имеют существенные преимущества перед другими типами промышленных кондиционеров:</p>
                <ul>
                    <li>Гибкость размещения в помещении, обусловленная модульным принципом построения и возможностью выбора  в широком диапазоне размеров сечения центрального кондиционера.</li>
                    <li>Большое количество типоразмеров позволяет выбрать несколько вариантов фронтального сечения. Для одного  номинального расхода воздуха можно подобрать от двух до трех комбинаций  ширины и высоты фронтального сечения.</li>
                    <li>Широкий диапазон воздухопроизводительности от 800 м³/час дo 100 000 м³/час.</li>
                    <li>Реализованы все процессы обработки воздуха: очистка от пыли, вредных газов, запахов и микроорганизмов, нагрев, охлаждение, увлажнение, осушение и перемещение воздуха.</li>
                    <li>Высокое качество тепловой изоляции и герметичность корпуса обеспечивают незначительные потери теплоты и отсутствие конденсации водяных паров на поверхности корпуса. Минеральная вата (изоляция) является не горючим материалом, не усаживается со временем и ее риклеивание к стенкам корпуса способствует его прочности.</li>
                    <li>Простота обслуживания и доступа ко всем элементам, легкость содержания в чистоте. Качественно изготовленный корпус имеет ровную и гладкую внутреннюю поверхность. Узлы крепления функциональных элементов с острыми кромками закругляются по всему контуру.</li>
                    <li>Дополнительная антикоррозионная защита с помощью порошковой окраски или использование нержавеющей стали увеличивает срок службы кондиционера.</li>
                    <li>Возможна произвольная комбинация материалов из листовой стали.</li>
                    <li>Возможен выбор оптимального способа регенерации теплоты и холода удаляемого воздуха.</li>
                </ul>
                <p>Пластинчатый рекуператор.</p>
                <p>Двойной пластинчатый рекуператор.</p>
                <p>Противоточный пластинчатый рекуператор.</p>
                <p>Роторный регенератор.</p>
                <p>Гликолевый рекуператор.</p>
                <p>Тепловой насос.</p>
                <ul>
                    <li>Совершенная технология производственных процессов, высокое и неизменное качество продукции. Соответствие требованиям Европейских машиностроительных норм, директивам для низковольтного оборудования, директивам по ограничению электромагнитного излучения.</li>
                </ul>
                <p>Возможность выбора  устройства, максимально отвечающего требованиям по исполнению, способам обработки воздуха, по месту размещения и геометрическим размерам помещения.</p>
                <p>Принимая во внимание комбинацию функциональных блоков и особенности объекта строительства, наша производственная программа включает следующие варианты размещения центрального кондиционера:</p>
                <ul>
                    <li>Горизонтальный</li>
                    <li>Двухэтажный</li>
                    <li>Параллельный</li>
                    <li>Вертикальный</li>
                    <li>
                        Комбинированный
                        <ul>
                            <li>Возможны и индивидуальные решения.</li>
                            <li>Модули центрального кондиционера</li>
                        </ul>
                    </li>
                </ul>
                <p>Модульность конструкции агрегатов позволяет подобрать индивидуальную установку, отвечающую конкретным требованиямпроекта. В зависимости от функциональных особенностей агрегата возможны различные варианты его компоновки. </p>
                <img src={a2712} alt='' className='img-width' />
                <p>Современные центральные кондиционеры выпускаются в виде набора стандартных модулей, каждый из которых выполняет определенную функцию:</p>
                <ul>
                    <li><strong>Секция охлаждения.</strong> Представляет собой водяной или фреоновый теплообменник. В качестве хладагента может использоваться вода, смесь воды и гликоля подаваемая от чиллера, фреон от внешнего компрессорно - конденсаторного блока.</li>
                    <li><strong>Секция нагрева.</strong> В секции воздухонагревания могут использоваться водяные и электрические нагреватели. Водяной нагреватель выполняется в виде теплообменника, к которому подводится горячая вода от системы центрального отопления или котельного оборудования. Электрические нагреватели выполнены в форме прямоугольного паралелепипида заключенными в корпусе греющими элементами в виде спирали или оребренных ТЭНов.</li>
                    <li><strong>Вентиляторная секция.</strong> Предназначена для забора воздуха в центральный кондиционер и его подачи в обслуживаемые помещения. В кондиционерах применяются радиальные (центробежные) вентиляторы различного исполнения.</li>
                    <li><strong>Секция фильтрации.</strong> Предназначена для очистки проходящего через центральный кондиционер воздуха от дыма и пыли. Центральный кондиционер может включать в себя две секции фильтрации: первичнюю и вторичнюю. Первичная секция фильтрации включает в себя фильтры грубой очистки класса EU1-EU3. Такой класс очистки задерживает до 60 % пыли. Вторичная секция фильтрации включает в себя фильтры тонкой очистки класса EU5-EU9. Такой класс очистки задерживает до 90 % пыли. Эти фильтры также могут работать при температуре 60С. Для контроля загрязнения фильтров рекомендуется применение дифманометров. Дифманометр при определенном перепаде давления сигнализирует о необходимости смены фильтра при его загрязнении.</li>
                    <li><strong>Секция глушения.</strong> Предназначена для снижения уровня шума, создаваемый работой вентилятора в центральном кондиционере. Внутри секции шумоглушения закреплены звукопоглощающие пластины, которые изготавливаются из нескольких слоев минеральной ваты специально подобранной плотности. Внешняя поверхность минеральной ваты усилена стекловолокнистым покрытием.</li>
                    <li><strong>Секция увлажнения.</strong> Увлажнение воздуха осуществляется в секции оросительного увлажнения водой или секции парового увлажнения. В секции форсуночной камере происходит распыление мелкодисперсной водяной взвеси, которая испаряется в проходящем воздухе.</li>
                    <li><strong>Теплоутилизатор.</strong> Для экономии тепла и холода, в центральных кондиционерах иногда устанавливают теплоутилизаторы или рекуператоры. Данные устройствапозволяют экономить до 80 % энергии путем нагрева входящего наружного воздуха за счет тепла воздуха, удаляемого из помещения. Теплоутилизаторы бывают трех типов - перекресточные теплообменники, вращающиеся теплообменники и система с промежуточным теплоносителем, состоящая из двух теплообменников.</li>
                </ul>

                <h2>Исполнение для бассейнов</h2>
                <p>При разработке систем центрального кондиционирования были учтены основные технологические требования к параметрам микроклимата внутри бассейна – автоматическое поддержание заданных значений температуры и относительной влажности воздуха независимо от изменяющихся условий внутри помещения: активности купающихся, работы водных аттракционов, изменяющихся параметров наружного климата, а также требования экономии энергии, затраченной на обеспечение микроклимата.</p>
                <img src={a2713} alt='' className='img-width' />

                <p><strong>Отличительными особенностями являются:</strong></p>
                <ul>
                    <li>Коррозионостойкие материалы</li>
                    <li>Автоматическоe регулированиe заданных параметров с помощью микропроцессорных контроллеров</li>
                    <li>Высокоэффективные рекуперативные теплообменники</li>
                    <li>Энергоэффективные тепловые насосы</li>
                    <li>Энергоэффективные тепловые насосы с высокой осушающей способностью  до 185 кг/ч</li>
                    <li>Высокое качество элементов установки</li>
                    <li>Адаптация к изменяющимся требованиям к микроклимату в помещении</li>
                </ul>

                <h2>Центральные кондиционеры для крупных административных и общественных зданий</h2>
                <p>Центральные кондиционеры применяются для обработки воздуха в общественных зданиях и в технологических помещениях: в больших офисных или жилых зданиях, гостиницах, музеях, крупных государственных учреждениях, предприятиях общественного питания и.т.д.</p>
                <img src={a2714} alt='' className='img-width' />
                <p>Интеллектуальная мультизональная система с равным успехом используется для кондиционирования воздуха в зданиях любой площади и этажности. Это самая экономичная система, работающая в режиме "теплового насоса" и обеспечивающая индивидуальное поддержание температуры воздуха в помещениях. При этом одновременно в одном помещении может происходить охлаждение воздуха, а в другом его подогрев. Нагрев или охлаждение воздуха производятся только в тех помещениях, где это действительно нужно, в то время как в остальных помещениях кондиционеры не работают вовсе, снижая при этом потребление электроэнергии.</p>
                <p>Максимальное расстояние между внутренним и наружным блоком (длина трубопровода) составляет 100 метров. Перепад высот между наружным и внутренним блоком (расстояние между блоками по вертикали) — 50 метров. Таким образом, стало возможным размещать наружный блок кондиционера в любом удобном месте — на крыше, в подвале или даже в нескольких десятках метров от помещения.</p>
                <p>К одному наружному блоку таких систем можно подключать до нескольких десятков внутренних различных типов (настенные, кассетные, канальные и т.д.). В таких системах каждый внутренний блок имеет электронный терморегулирующий вентиль, регулирующий объем поступающего хладагента из общей трассы в зависимости от тепловой нагрузки на этот блок. Благодаря этому, мультизональная система более ровно поддерживает заданную температуру, без перепадов, свойственных обычным кондиционерам, регулирующим температуру воздуха путем периодического включения и выключения.</p>

                <h2>Принцип работы и управления</h2>
                <p>Использование в центральном кондиционере рециркуляции и теплоутилизации позволяет существенно сократить затраты тепловой энергии, связанные с обогревом воздуха в холодное время года.</p>
                <p>Эффект утилизации тепла состоит в использовании энергии вытяжного воздуха для нагрева или охлаждения приточного. Таким образом, в большинстве случаев, отпадает необходимость смешивания наружного и внутреннего воздуха (частичной рециркуляции) для экономии энергозатрат на нагрев воздуха до требуемой температуры.</p>
                <p>Управление установкой осуществляется единой системой микропроцессорной автоматики малогабаритным, монтируемым в удобном для пользователя месте на стене пультом, с помощью которого возможно осуществление следующих функций:</p>
                <ul>
                    <li>настройка желаемой температуры помещения (режимы нагрев/охлаждение)</li>
                    <li>режим чистой вентиляции (подача наружного воздуха без изменения его температурных характеристик)</li>
                    <li>ступенчатое регулирование скорости вентиляторов</li>
                    <li>наличие ТАЙМЕРА – установка дневного/недельного графиков работы установки</li>
                    <li>индикация заданной температуры, температуры в помещении, уличной температуры, режимов работы установки, работы компрессора</li>
                    <li>полностью автоматическое управление, позволяющее пользователю не менять регулировки и настройки в течение всего срока эксплуатации установки независимо от изменений уличной температуры</li>
                </ul>
                <p>При подаче воздуха в несколько помещений, воздушный клапан зонального регулирования позволяет оперативно изменять объем подаваемого воздуха в каждом помещении, что создает дополнительный комфорт и экономию энергетических ресурсов.</p>
                <p>Подкупает легкость монтажа, который заключается в обвязке установки воздуховодами, подключении электроэнергии, подсоединении отвода конденсата и монтированию настенного пульта управления. Все остальные блоки и элементы находятся внутри установки.</p>
                <p>Центральный кондиционер Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку центральных кондиционеров по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default CentralAirConditioners