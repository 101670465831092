import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/271427plastinchatyiy-teploobmennik.png'

const WinterGardenHeating = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Отопление зимнего сада</h1>
                <p>Отопление зимнего сада Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998 (90) 355-90-91'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Для любителей цветущих круглый год растений или спелых овощей и фруктов в любое время года на дачном участке можно построить теплицу, оранжерею или зимний сад.</p>
                <p>На жизнь растений в тепличных условиях влияет множество факторов:</p>
                <ul>
                    <li>герметичность помещения;</li>
                    <li>особенности крыши и стекол, используемых в теплицах;</li>
                    <li>правильное освещение, как естественное, так и искусственное;</li>
                </ul>
                <p>Но особенно важное для таких построек – обеспечение теплом, чтобы зеленым саженцам было максимально комфортно, они могли беспрепятственно расти, цвести и плодоносить. От способа обогрева зимнего сада зависит и система поддержания оптимальной влажности и циркуляции воздуха.</p>

                <h2>Баланс влажности и температуры</h2>
                <p>Для того чтобы пребывание в саду было приятным важно создать правильный тепловой баланс. Поэтому правильно сконструированная система отопления и вентиляции зимнего сада очень важна. Теплый воздух поднимается вверх, ведь он легче холодного. Собираясь под прозрачной крышей, он обеспечивает природную вентиляцию, которая аналогична потоку теплого воздуха в камине. Но чтобы микроклимат соответствовал норме, этого не достаточно.</p>
                <p>В зимнем саду важно поддерживать чистоту воздуха. Поэтому обязательно в крыше должны присутствовать люки, а так же необходимое количество открывающихся окон. Открытие люков можно регулировать либо дистанционными электроподъемниками, либо шестами для ручного подъема. В более продвинутых конструкциях вентиляция продумана до мелочей, воздух поступает через каждую деталь и стыковочный узел. Обеспечение нормы полного обновления воздуха (в десятикратном объеме), способствует уменьшению влажности и охлаждению в жару. Для этого нужно иметь площадь открывающихся окон до 1/6 всей площади.</p>
                <p>В прохладную погоду важен достаточный обогрев помещения. Соответственно, зимнему саду необходима система отопления зимнего сада. Передача тепла в стеклянных помещениях, которые подвергаются воздействию солнечных лучей, основана на свойствах стекла. Именно последние позволяют коротковолновому излучению проникать сквозь стекло, нагревать предметы внутри, но не выпускать энергию инфракрасных волн, которую излучают нагретые предметы. Польза есть не только от прямого солнечного излечения, но и от облачного неба.</p>
                <p>При правильном применении солнечной энергии наиболее эффективно использовать излучение, которое не превышает комфортные границы температуры — 30°С. Тепло аккумулируется в стенах, полу, поступление теплого воздуха происходит из дома, с которым соединяется зимний сад. Для правильной эксплуатации все вышеперечисленные параметры нуждаются в непрестанной регулировке.</p>
                <p>Для обогрева зимнего сада рекомендуют использовать специальные стекла, с теплозащитными свойствами. Необходимая защита от солнца – это наличие системы наружного либо внутреннего затемнения.</p>
                <p>Зимний сад сродни сложному микроорганизму. В нем нужно соблюдать такие характеристики:</p>
                <ul>
                    <li>устойчивость к воздействиям атмосферной среды</li>
                    <li>безопасная конструкция</li>
                    <li>защита внутренней среды от перегрева и переохлаждения</li>
                </ul>
                <p>В сложных случаях помните, что только профессионалы в состоянии комплексно подойти к решению этих вопросов.</p>

                <h2>Отопление зимнего сада</h2>
                <p>Что касается отопления, то зимние сады делятся на отапливаемые и неотапливаемые. Если сад неотапливаемый, то он становится своего рода термо-буфером, соединяющим основное здание с улицей. Таким образом, основное здание отдает меньше тепла наружу, меньшая разница температур между зимним садом и основным зданием позволяет экономить на расходе тепла для отопления. Но при таком варианте круглогодичное использование зимнего сада в качестве жилого помещения невозможно.</p>
                <p>Есть дополнительный источник тепла, который всегда под рукой и его можно использовать достаточно эффективно – солнечная энергия. Получаемое ее количество зависит от нескольких факторов:</p>
                <ul>
                    <li>интенсивность излучения тепла</li>
                    <li>угол падения лучей</li>
                    <li>тип остекления.</li>
                </ul>
                <p>В неотапливаемом зимнем саде в холодное время года, когда температура очень низкая, может образовываться лед и конденсат. Поэтому для садов такого типа рекомендуют использовать теплоизолирующие стекла.</p>
                <p>В случае круглогодичного использования зимнего сада, как жилого помещения, наличие отопительной системы строго обязательно. Для экономии потери энергии необходимо так же правильно выбрать тип остекления.</p>
                <p>Если ваш выбор – отапливаемый зимний сад, то система автономного отопления должна быть запланирована на стадии проектирования дома. Для составления подобного проекта следует обратиться в лицензированные организации, которые в обязательном порядке выезжают на объект. Специалист проанализирует размер помещений, вид и эффективность теплоизоляции, расположение дверных проемов и окон, и составит для вас наиболее оптимальный тип системы, определит ее мощность, количество и длину труб, учитывая возможные теплопотери.</p>
                <p>Для установки отопительного напольного котла, вам понадобится отдельное помещение – котельная. Установку системы отопления, основанную на работе данного оборудования нужно проводить на этапе строительства дома. Если разводка труб отопления предполагается скрытая (в полу или стенах), то монтаж нужно производить до того, как вы приступите к отделочным работам. Если разводка запланирована наружная, то ее выполняют после финальной отделки.</p>
                <p>Альтернативой напольному котлу могут стать радиаторы, подключенные к системе центрального отопления, автономные отопительные приборы, использование нагретого воздуха, теплых полов (электрических и с жидкими теплоносителями). Достаточно часто используют комбинации перечисленных вариантов. При этом не стоит забывать о постоянном и неизменном источнике тепла – инфракрасном излучении.</p>
                <p>Количество требуемых радиаторов рассчитать не так сложно. При определении этого количества нужно принимать во внимание следующее:</p>
                <ul>
                    <li>какая общая площадь помещения</li>
                    <li>количество прозрачных поверхностей и их площадь</li>
                    <li>тепловые и технические характеристики светопропускающих элементов и профилей.</li>
                </ul>
                <p>Кроме того, нужно учитывать и то, где сад находится, насколько сильное тепловое излучение радиатора. А оно может изменяться в зависимости от окружающих его предметов. Если закрыть радиатор подоконником, да еще и повесить декоративный экран, то вы получите гораздо меньшую эффективность в его работе. Так же, мощность радиатора можно регулировать вручную, с помощью специального регулятора.</p>
                <p><strong>Маленькая хитрость:</strong></p>
                <p>Mожно не очень задумываться о сопутствующих факторах и упростить подсчет количества радиаторов. Для этого подсчитайте, сколько вам понадобится радиаторов для обычного помещения с такой же площадью, как у вашего зимнего сада, а потом умножьте его на два. Вы получите нужную цифру.</p>
                <p>Для равномерного прогревания всего помещения зимнего сада, радиаторы необходимо расположить по всему периметру. Причем чем ближе к стеклянным поверхностям, тем лучше, потому что теплый воздух препятствует образованию конденсата. Только обязательным условием должно быть постоянное движение теплого воздуха.</p>
                <p>Еще раз напоминаем: никакого зимнего сада не может быть без грамотно налаженной системы вентиляции. Если вы строите зимний сад в климатической зоне, где зимой выпадает снег, то не забывайте о том, что его нужно будет убирать с крыши строения. Если перепад температур между крышей и окружающей средой достаточный, то снег растает. Поэтому необходимо установить обогрев водосточных труб и желобов, чтобы талая вода могла беспрепятственно уйти, а не превратиться в лед. Такие простые меры помогут снизить воздействие снежной массы на здание, а так же дать доступ солнечным лучам в помещение.</p>
                <p><strong>Водяное и паровое отопление</strong></p>
                <p>Эта отопительная система обеспечивает непрерывное поступление тепла в комнату. Особенно если весь ваш дом отапливается водой, то этот способ будет наиболее приемлемым. Но прокладывать коммуникации лишь для зимнего сада будет нецелесообразным.</p>
                <p>Преимущества:</p>
                <ol>
                    <li>Температура поддерживается постоянно.</li>
                    <li>Затраты на получаемое тепло относительно низкие.</li>
                    <li>Между садом и примыкающими помещениями нет перепада температур. Поэтому, если провести отопление зимнего сада водяным либо паровым способом, то в этом помещении можно устроить столовую либо зону отдыха.</li>
                </ol>
                <p>У этой отопительной системы можно назвать лишь один недостаток: чтобы сделать монтаж отопления зимнего сада, необходимы специальные навыки и инструменты для врезания оборудования. Поэтому здесь будет неуместен вопрос, как провести данный вид отопления в зимний сад своими руками, т. к. без специалиста здесь никак не обойтись.</p>
                <p><strong>Воздушное отопление</strong></p>
                <p>Принцип этого отопления заключается в монтаже в форточке вентилятора, который перегоняет тепло из отапливаемой комнаты в зимний сад. Либо, если вы решили сделать сад в пристроенной к дому хозяйственной постройке, то в доме устанавливают воздухонагреватель и посредством него воздух распространяется по воздуховодам в зимний сад.</p>
                <p>Преимущество у этого вида отопления лишь одно – это невысокая цена обустройства. А недостатков, наоборот, много:</p>
                <ol>
                    <li>Необходимо дополнительно утеплять зимний сад.</li>
                    <li>Затраты на покупку воздухонагревателя и воздуховодов.</li>
                    <li>Необходимо много пространства для этого вида отопления.</li>
                    <li>Ухудшает внешний облик сада.</li>
                    <li>Пересушивает воздух.</li>
                </ol>
                <p><strong>Печное отопление</strong></p>
                <p>Преимущества:</p>
                <ol>
                    <li>Можно применять дешевые дрова и уголь.</li>
                    <li>Создается однозначный колорит.</li>
                </ol>
                <p>Недостатки:</p>
                <ol>
                    <li>Распределение температуры происходит неравномерно, что приводит к перегреву растений.</li>
                    <li>Необходим постоянный контроль.</li>
                    <li>Пожароопасное вид отопления.</li>
                </ol>
                <p>Теперь вы знаете, какие существуют виды отопления зимних садов, но перед тем, как выбрать один из них, необходимо учитывать следующее:</p>
                <ol>
                    <li>Размеры сада. Чем большую площадь он имеет, тем больше потребуется тепла для обогрева.</li>
                    <li>Типы растений. К примеру, экзотические растения отлично приживаются лишь при температуре больше 20°С.</li>
                    <li>Частота посещений зимнего сада. Например, если у вас в саду только морозоустойчивые растения и сами там проводите время лишь иногда, то подойдет обогреватель. А если же у вас зимний сад служит продолжением дома − понадобится целая отопительная система.</li>
                </ol>

                <h2>Отопление при помощи пластинчатого теплообменника</h2>
                <p>С помощью системы пластинчатого теплообменника можно с легкостью обогревать изолировано друг от друга оранжерею и дом, охватывать большие территории, а также регулировать уровень обогрева. Главный недостаток – громоздкость. Монтаж оборудования, системы труб, насосов, запорной и расширительной аппаратуры очень сложный и дорогой процесс.</p>
                <img src={img1} alt='' />

                <h2>Монтаж отопления зимнего сада</h2>
                <p>Установить отопление в зимнем саду своими руками можно при таких видах обогрева, как электрический, воздушный и печной.</p>
                <p>Утепление зимнего сада – это не только устройство тех или иных систем обогрева, но и применение разного рода утепляющих материалов. Обогрев зимнего сада при помощи конфектора</p>
                <p>Рассмотрим основные правила, как отапливать зимний сад с помощью водяных конвекторов, расположенных внутри пола:</p>
                <ol>
                    <li>Оборудование устанавливают в закрытых помещениях.</li>
                    <li>Теплообменник должен находится с той стороны оборудования, которая наиболее удалена от окошка, если он предназначен для обогрева основной жилой комнаты.  А если же необходимо лишь создать воздушную завесу вдоль окон, то к ним нужно «развернуть» теплообменник.</li>
                    <li>Конвектор должен находится на расстоянии от окна – примерно 25−30 см.</li>
                    <li>Размеры монтажной «ниши» для конвектора должны быть больше габаритов оборудования приблизительно на 1−1,5 см.</li>
                    <li>С помощью регулировочных болтов, которые находятся на дне изделия, необходимо сделать регулировку оборудования по высоте.</li>
                    <li>Необходимо применять запорные краны и гибкие шланги как для подвода, так и для отвода теплоносителя.</li>
                    <li>Оборудование необходимо зафиксировать с помощью цементного раствора и монтажной пены.</li>
                </ol>
                <p>И последнее, и самое важное правило: во время монтажа отопления зимнего сада старайтесь избегать механического воздействия на оборудование. Иначе может произойти нарушение равномерного процесса распределения тепла.</p>
                <p>Отопление зимнего сада Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998 (90) 355-90-91'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default WinterGardenHeating