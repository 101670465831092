import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import kontrol from '../../../img/kontrol-za-ochistkoj-sistem-ventilyatsii_j76danpqbt1_small.jpg'
import imh03 from '../../../img/03-06-2014 16-17-22.jpg'
import imh13 from '../../../img/03-06-2014 16-24-20.jpg'
import imh23 from '../../../img/03-06-2014 16-24-07.jpg'

const VentilationMaintenance = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Обслуживание системы вентиляции</h1>
                <p>Обслуживание системы вентиляции Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <img src={kontrol} alt='' />
                <p>Основная цель проведения сервисного обслуживания систем вентиляции и кондиционирования - это обеспечение надежной безотказной работы климатического оборудования в течение всего срока его службы. Высококвалифицированные специалисты Сервисного центра компании «FIDCLIMAT», обладая многолетним опытом работы, выполняют целый комплекс услуг по техническому обслуживанию и ремонту систем бытового, полупромышленного и промышленного кондиционирования и вентиляции. Наши сотрудники регулярно повышают квалификацию в учебных центрах и на площадках компаний-производителей, поэтому мы уверены в профессионализме наших специалистов и отвечаем за качество выполненных работ.</p>
                <img src={imh03} alt='' className='img-width' />
                <p>Наши специалисты проводят весь комплекс работ по сервисному и ремонтному обслуживанию систем вентиляции  и кондиционирования на объектах любого уровня сложности и назначения:</p>
                <ul>
                    <li>Общественно-культурные здания (театры, клубы, музеи, кинозалы...)</li>
                    <li>Административные здания (бизнес-центры, офисы, учреждения образования)</li>
                    <li>Промышленные, производственные и складские здания (заводы, логистические центры, цеха, склады)</li>
                    <li>Серверные, радиостанции, телецентры и другие объекты специального назначения</li>
                    <li>"Чистые помещения"</li>
                    <li>Торговые центры, гипермаркеты и магазины</li>
                    <li>Предприятия индустрии питания (рестораны, кафе, бары, кондитерские...)</li>
                    <li>Жилые здания, элитное жилье, квартиры, коттеджи, загородные дома, пентхаусы, таунхаусы</li>
                    <li>Спортивные учреждения, SPA- и фитнес-центры, плавательные бассейны, бани</li>
                    <li>Санатории, больницы, пансионаты, дома отдыха</li>
                    <li>Автостоянки, автосервисы, АЗС.</li>
                </ul>
                <p><strong>Специалисты компании «FIDCLIMAT» осуществляют:</strong></p>
                <ul>
                    <li>Комплексное обслуживание систем вентиляции – это профессиональный сервис на всем протяжении эксплуатации системы:</li>
                    <li>Техническое обслуживание вентиляции – комплекс работ по очистке внешних и внутренних элементов вентиляционной установки и проверке оборудования на предмет сбоев в работе установки. Рекомендуется проводить ТО не реже, чем 4 раза в год.</li>
                    <li>Планово-предупредительное обслуживание и ремонт вентиляции. В случае поломки вентиляционного оборудования или возникновения аварийной ситуации на объекте, Клиент должен оформить заявку на выезд ремонтной бригады. Плановый выезд на объект специалистов по ремонту систем вентиляции осуществляется в течение 1-2 дней, аварийный – в течение 24 часов. </li>
                    <li>Ремонтные работы осуществляются в 2 этапа. На первом этапе производится полная диагностика вентиляционной системы, благодаря которой специалисты по ремонту могут определить истинную причину поломки. Второй этап работ – это ремонт вентиляционного оборудования.</li>
                    <li>Дезинфекцию систем вентиляции – комплекс мер, направленных на уничтожение болезнетворных бактерий внутри вентиляционных каналов и во внешней среде.</li>
                    <li>Консультационную поддержку</li>
                </ul>

                <h2>Чем грозит несвоевременное сервисное обслуживание вентиляции?</h2>
                <p>Запоздалое или просроченное техническое обслуживание является, в общем-то, чересчур мягким и совершенно неадекватным, а скорее полностью ошибочным названием тому, что в действительности происходит в случае длительного отсутствия сервисного сопровождения климатических систем. Гораздо более подходящий термин: “Катастрофический отказ оборудования”. Результатом надолго отложенного обслуживания  отдельного кондиционера или вентиляционной сети может быть абсолютно все:</p>
                <ul>
                    <li>от незначительной неисправности отдельного компонента,</li>
                    <li>преждевременного выхода из строя основных функциональных узлов,</li>
                    <li>до крупнейшей аварией полной системы вентиляции (кондиционирования) воздуха — со всеми вытекающими отсюда проблемами, неудобствами, снижением производительности труда персонала, потерями клиентов и прибыли.</li>
                </ul>

                <h2>Почему владельцы климатического оборудования это допускают?</h2>
                <p>Почему же некоторые ответственные за объект руководители не считают необходимым проводить регулярное системное обслуживание?</p>
                <p>Как назвать человека, вовремя не доливающего масла в собственный автомобиль, терпеливо ожидая критического момента? Глупость или недостаток элементарных знаний?</p>
                <p>Есть и еще один существенный момент, по которому руководствуются владельцы климатических систем, постоянно откладывая тех. осмотр и игнорирует сервисное обслуживание – это экономия, поскольку “Сервис забирает часть прибыли владельцев предприятия”. Но, как показывает практика, своевременное предотвращение аварии обходится гораздо дешевле её ликвидации, тем более что последствия могут быть необратимыми, приводящими владельца к необходимости закупать и монтировать новое оборудование.</p>
                <p>Еще одна отговорка – это нежелание доверять штатным сервисным инженерам с недостаточной квалификацией для выполнения ремонтных работ. В этом, конечно же, есть смысл, но кто мешает обратиться к профессионалам?</p>
                <img src={imh13} alt='' className='img-width' />
                <p>Так что, если не проводить своевременное сервисное обслуживание кондиционеров, вентиляции, воздушного отопления, осушителей, то в результате это приведет к:</p>
                <ul>
                    <li>Снижению производительности техники</li>
                    <li>Значительному повышению потребления электроэнергии</li>
                    <li>Поломке системы или ее выходу из строя</li>
                    <li>Большим финансовым расходам</li>
                </ul>
                <p>В то время как проведение сервисного обслуживания и своевременного ремонта:</p>
                <ul>
                    <li>Позволяет длительное время сохранять высокую производительность техники</li>
                    <li>Значительно сокращает время простоя системы</li>
                    <li>Продлевает срок эксплуатации  климатического оборудования</li>
                    <li>Снижает затраты владельца на покупку дорогих зап. частей или нового оборудования.</li>
                </ul>
                <p><strong>Выводы: Зачем это нужно?</strong></p>
                <p>Обмерзание – лишь одно из последствий игнорирования регулярных сервисных работ, ведущее к гораздо более серьезным проблемам и неисправностям.</p>
                <p>Существует масса причин для выполнения регулярного технического обслуживания, совершенно не требующих высокого полета фантазии. Одна из основных – сокращение до минимума времени простоя, мелкий ремонт вместо полной замены крупных функциональных узлов, предотвращение снижения производительности, а также максимальное увеличение срока эксплуатации оборудования.</p>
                <p>Грамотные специалисты в состоянии обнаружить и предупредить большинство аварийных ситуаций в процессе профилактических работ, что, в противном случае, могло бы привести к ремонтному вызову, длительному простою оборудования или серьезной неисправности целой системы. Так, например, нередки случаи, когда происходил полный отказ систем кондиционирования воздуха стоимостью 0,000 и выше, на 10 лет раньше ожидаемого срока службы по причине отсутствия сервиса на надлежащем уровне. Появление плесени, грибков, низкое качество воздуха внутри вентилируемого помещения элитных жилых особняках – также  происходит по вине безответственного отношения к системам вентиляции и кондиционирования. И таких примеров огромное множество.</p>
                <p>Не хотите сталкиваться с перечисленными проблемами – своевременно позаботьтесь о сервисном обслуживании систем вентиляции и кондиционирования.</p>

                <h2>Причины поломки и выхода из строя оборудования вентиляции</h2>
                <p>Ни одно здание, будь то небольшой жилой дом или огромное производственное помещение, не может нормально функционировать без качественной системы вентиляции. И если в исправном состоянии многие даже не замечают ее существования, то при возникновении малейшей неисправности это сразу начинает сказываться. Чтобы постоянно следить за ее нормальной работой, необходимо знать основные неисправности системы вентиляции.</p>
                <p>В большинстве случаев причиной выхода оборудования из строя является плохое усвоение правил пользования установкой, приводящее к неточным или совсем ошибочным регулировкам. К этому можно добавить низкую подготовку персонала, производящего различные действия в отношении оборудования.</p>
                <p>Примечание: Были проанализированы данные о 223 зданиях общей площадью 3 000 000 м2, в которых работало 225 000 человек.</p>
                <img src={imh23} alt='' className='img-width' />
                <p>Итак, самые распространенные неисправности — это нарушение целостности шахт и коробов, герметичности дверей, возникновения в соединениях неплотностей, повышение сопротивления воздуховодов и т.д. В результате возникновения хотя бы одной из перечисленных неисправностей, работа системы вентиляции нарушается довольно существенно — наблюдается нарушение воздухообмена, намного быстрее начинает развиваться коррозия металлических кровель, значительно увеличивается расход электроэнергии.</p>
                <p>Неисправности в системах вентиляции очень часто возникают по причине разъедания ржавчиной воздуховодов, также это может быть следствием некачественной установки оборудования, плохой затяжки соединений, отсутствия крепежных элементов, предусмотренных проектом. Неплотности в коробах системы вентиляции можно достаточно просто определить, если поднести к местам соединений зажженную свечу. Если монтаж вентиляции дома выполнен некачественно, либо во время проведения ремонта внутрь попал строительный мусор, то в воздуховодах неизбежно возникает повышенное сопротивление. Данную неисправность можно устранить только прочисткой каналов.</p>
                <p>Довольно часто причиной неисправности системы вентиляции становится неправильная установка вентилятора. В данном случае неисправность можно устранить, выполнив следующие действия:</p>
                <ul>
                    <li>Очистить вентилятор от паутины, грязи и посторонних предметов.</li>
                    <li>Сменить рабочее колесо, но только в том случае, если лопатки у него проржавели или сильно изношены.</li>
                    <li>Отрегулировать зазор между краем патрубка центробежного вентилятора и краем диска рабочего колеса. Данный зазор как в радиальном направлении, так и в осевом не должен быть более чем один процент от диаметра рабочего диска.</li>
                    <li>Изменить расположение выходного и входного патрубков в центробежном вентиляторе.</li>
                    <li>Изменить направление вращения самого вентилятора, у которого асимметричные лопасти.</li>
                    <li>Отрегулировать работу вентилятора таким образом, чтобы вогнутая сторона лопастей захватывала воздух.</li>
                    <li>Ликвидировать неплотности в воздуховодах и прочие неисправности.</li>
                </ul>
                <p>Очень часто при работе вентиляции создаются повышенные шумовые эффекты. Естественно, что такая неисправность нарушает комфортность помещений, и довольно существенно.</p>
                <p>Шум может возникать по нескольким причинам:</p>
                <ul>
                    <li>отсутствие гибких резиновых или брезентовых вставок между воздуховодом и вентилятором;</li>
                    <li>недостаточно прочно закреплены задвижки и клапаны, вследствие чего возникает вибрация;</li>
                    <li>слишком высокие обороты вентилятора;</li>
                    <li>глухая заделка воздуховодов в перекрытиях и стенах;</li>
                    <li>повышенная вибрация при работе оборудования.</li>
                </ul>
                <p>Все вышеперечисленные неисправности системы вентиляции устраняются полной или частичной разборкой системы, устройством гибких вставок, виброизолирующих шлюзов и оснований, заменой вышедших из строя деталей. Для того, чтобы снизить уровень шума, в зависимости от установленной системы вентиляции монтируют пластинчатые, сотовые или трубчатые шумоглушители. Ячейки шумоглушителей соединяют с воздуховодом и между собой посредством фланцев.</p>
                <p>Обслуживание системы вентиляции Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default VentilationMaintenance