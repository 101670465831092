import { Collapse } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';

const OsushMenu = () => {
  
    const { Panel } = Collapse;

    return (
        <Collapse expandIconPosition='end' defaultActiveKey={['1']}>
            <Panel header="Услуги по осушению" key="1">
                <Link to='/dehumidification-design'>Проектирование осушения</Link>
                <Link to='/installation-dehumidifiers'>Монтаж осушителей</Link>
                <Link to='/dehumidifier-maintenance'>Обслуживание осушителей</Link>
            </Panel>

            <Panel header="Типы объектов" key="2">
                <Link to='/pool-dehumidifiers'>Осушители для бассейнов</Link>
            </Panel>
        </Collapse>
    )
}

export default OsushMenu