import { Collapse } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom';

const KondMenus = () => {

    const { Panel } = Collapse;

    return (
        <Collapse expandIconPosition='end' defaultActiveKey={['1']}>
            <Panel header="Выбор кондиционера" key="1">
                <Link to='/how-to-choose-conditioner'>Как выбрать кондиционер?</Link>
                <Link to='/conditioners-with-ventilation'>Кондиционеры с приточной вентиляцией</Link>
                <Link to='/'>Подобрать настенный кондиционер</Link>
                <Link to='/household-semi-industrial-air-conditioners'>Бытовые и полупромышленные кондиционеры</Link>
                <Link to='/commercial-air-conditioners'>Коммерческие кондиционеры</Link>
                <Link to='/industrial-air-conditioners'>Промышленные кондиционеры</Link>
                <Link to='/useful-information'>Полезная информация</Link>
            </Panel>
            
            <Panel header="Услуги по кондиционированию" key="2">
                <Link to='/consultations'>Консультации</Link>
                <Link to='/design-airconditioning-systems'>Проектирование систем кондиционирования</Link>
                <Link to='/ventillation-design'>Проектирование вентиляции</Link>
                <Link to='/installation-air-conditioning-systems'>Монтаж систем кондиционирования</Link>
                <Link to='/air-conditioner-maintenance'>Обслуживание кондиционеров</Link>
                <Link to='/supply-climatic-equipment'>Поставка климатического оборудования</Link>
                <Link to='/commissioning-air-conditioning-systems'>Пуско-наладка систем кондиционирования</Link>
            </Panel>

            <Panel header="Каталог кондиционеров" key="3">
                <Link to='/catalag-air-conditioners'>Каталог кондиционеров</Link>
                <Link to='/split-systems'>Сплит-системы</Link>
                <Link to='/multi-split-systems'>Мульти сплит-системы</Link>
                <Link to='/multizone-vrf-vrv-sytems'>Мультизональные VRF и VRV системы</Link>
                <Link to='/chillers-fancoils'>Чиллеры и фанкойлы</Link>
                <Link to='/roof-air-conditioners'>Крышные кондиционеры</Link>
                <Link to='/precision-air-conditioners'>Прецизионные кондиционеры</Link>
                <Link to='/central-air-conditioners'>Центральные кондиционеры</Link>
            </Panel>

            <Panel header="Решения по типу объекта" key="4">
                <Link to='/office-air-conditioning'>Кондиционирование офисов</Link>
                <Link to='/production-conditioning'>Кондиционирование производства</Link>
                <Link to='/warehouse-air-conditioning'>Кондиционирование склада</Link>
                <Link to='/apartment-air-conditioning'>Кондиционирование квартиры</Link>
                <Link to='/cottage-home-air-conditioning'>Кондиционирование коттеджа или дома</Link>
                <Link to='/store-air-conditioning'>Кондиционирование магазинов</Link>
                <Link to='/shopping-mall-air-conditioning'>Кондиционирование торгового центра</Link>
                <Link to='/air-conditioning-buildings'>Кондиционирование адм. зданий</Link>
                <Link to='/gym-air-conditioning'>Кондиционирование спортзала</Link>
                <Link to='/air-conditioning-residential-buildings'>Кондиционирование жилых зданий</Link>
                <Link to='/school-air-conditioning'>Кондиционирование школы</Link>
                <Link to='/winter-garden-air-conditioning'>Кондиционирование зимнего сада</Link>
                <Link to='/hotel-air-conditioning'>Кондиционирование гостиницы</Link>
                <Link to='/air-conditioning-restaurants-cafes-bars'>Кондиционирование ресторанов, кафе, баров</Link>
            </Panel>
        </Collapse>
    )
}

export default KondMenus