import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './apartmentAirConditioning.css'

import o62237upravlenie from '../../../img/62237upravlenie_sistemoj_kondicionirovanija_v_kvartire_1.jpg'
import o12178sistemy from '../../../img/12178sistemy-i-proekty-kondicionirovaniya-vozduxa-v-kvartirax-otzyvy-300x291.jpg'
import o1mochtnost from '../../../img/-1mochtnost-kindicionera.jpg'
import o95494kak from '../../../img/95494kak-pravilno-ustanovit-kondicioner-v-kvartire-3.jpg'
import o87607kak from '../../../img/87607kak-pravilno-ustanovit-kondicioner-v-kvartire-4.jpg'
import o18723kak from '../../../img/18723kak-pravilno-ustanovit-kondicioner-v-kvartire-6.jpg'
import o13494kak from '../../../img/13494kak-pravilno-ustanovit-kondicioner-v-kvartire-7.jpg'
import o16501kak from '../../../img/16501kak-pravilno-ustanovit-kondicioner-v-kvartire-8.jpg'
import o42831kak from '../../../img/42831kak-pravilno-ustanovit-kondicioner-v-kvartire-9.jpg'
import o73934kak from '../../../img/73934kak-pravilno-ustanovit-kondicioner-v-kvartire-10.jpg'
import o67707kak from '../../../img/67707kak-pravilno-ustanovit-kondicioner-v-kvartire-14.jpg'

const ApartmentAirConditioning = () => {
    return (
        <>
            <div className='apartment-air-conditioning-page'>
                <h1>Системы кондиционирования для квартиры</h1>
                <p>Систему кондиционирования для квартиры Вы можете заказать с монтажом, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Основная цель систем кондиционирования в квартире - создавать и поддерживать наиболее благоприятное для человеческого организма соотношение температуры, влажности, скорости движения воздуха и содержания в нем пыли и микроорганизмов. Именно исходя из соображений комфорта и выбирают кондиционер для квартиры.</p>
                <p>Как правильно выбрать кондиционер в квартиру? Основным документом в котором прописаны санитарно-гигиенические требования по параметрам микроклимата для жилых и общественных сооружений является ГОСТ 30494-2011. Для теплого периода года в нем указаны такие оптимальные и допустимые параметры температуры:</p>
                <ul>
                    <li>оптимальные – 22–25 °C;</li>
                    <li>допустимые – 20–28 °C.</li>
                </ul>
                <p>Этих границах температур человек в жаркое время года чувствует себя комфортнее всего. Но существует и один нюанс. Также важно ориентироваться и на температуру наружного воздуха. Если люди находятся в помещении постоянно не покидают его, то они привыкают к установившейся температуре. Но если приходится выходить на улицу и снова возвращаться в охлажденное помещение, то целесообразно устанавливать температуру не ниже 10 °C от уличной. В таком случае резких перепадов не будет, и человеческому организму легче приспособиться к постоянному изменению окружающей температуры. Исходя из этих ограничений чаще всего и выбирают кондиционер в квартиру. Купить его необходимо после подбора и определения характеристик.</p>

                <h2>Выбор системы кондиционирования. Какие факторы нужно знать?</h2>
                <p>Первое, что Вам нужно учесть при выборе кондиционера в квартиру – какая мощность и технические характеристики Вам подойдут. Для этого нужно рассчитать такие факторы:</p>
                <ul>
                    <li>Объем комнаты, в которую предполагается установка, либо объем всей квартиры (высоту потолков множим на площадь);</li>
                    <li>Площадь комнаты либо размер места установки внутреннего блока (для определения размеров кондиционера);</li>
                    <li>Сколько окон есть в помещении, их размер;</li>
                    <li>Сколько людей проживает в квартире, либо сколько людей регулярно пребывает в помещении;</li>
                    <li>Количество различной техники – она является источником тепла, а это влияет на выбор мощности кондиционера.</li>
                </ul>
                <p>Эти факторы помогут определить, какие технические характеристики должны быть у кондиционера.</p>
                <p>Далее мы должны определить, какие именно функции нужны для создания качественного микроклимата и удобства пользования:</p>
                <ul>
                    <li>Измеряем влажность воздуха в помещении – если она превышает норму (45-60%), значит, нам понадобиться кондиционер с функцией осушения;</li>
                    <li>Анализируем систему вентиляции в квартире. Если воздухообмен совершается с недостаточной мощностью, тогда в кондиционере нам нужна возможность подмеса свежего воздуха. Но лучше, конечно, будет позаботиться о полноценной вентиляции как об отдельной системе.</li>
                    <li>Анализируем систему отопления в нашем жилье. Если зимой Вы ощущаете, что квартира недостаточно отапливается; либо обогревателя не хватает, когда центральное отопление еще отключено – логично будет приобрести такой кондиционер, который будет иметь достаточную мощность по обогреву воздуха.</li>
                    <li>Так же позаботимся об очистке воздуха в квартире. Если Вы живете близко к дороге, либо в другой более загрязненной местности, что приводит к постоянному накоплению загрязнений в Вашем жилье – включите в систему кондиционирования функцию ионизации воздуха, либо позаботьтесь о подключении воздушного фильтра высокого класса очистки.</li>
                    <li>Высокий уровень комфорта и автономность работы обеспечит система Inverter. </li>
                    <li>Так же проанализируйте другие функции кондиционеров, которые облегчили Вам управление его работой, либо обеспечили самостоятельность системы. Существует множество разнообразных функций, которые обеспечат Вам удобство и комфорт.</li>
                </ul>
                <img src={o62237upravlenie} alt='' className='img-width' />
                <p>Стоит сказать, что для нормального функционирования организма человека, воздух в жилом помещении, квартире или доме, должен быть очищен от пыли и содержать не менее 21% кислорода и не более 0,3% углекислого газа при температуре 20-24°С и относительной влажности 45-60%.  </p>
                <p>Хорошая новость заключается в том, что современные модели кондиционеров обеспечивают все вышеперечисленные стандарты для воздуха в квартирах и домах.</p>
                <p>Магазины ТОЛЬКО продают – купленную технику, устанавливают ее нанятые магазином "шабашные бригады"  - в любом случае качество установки может пострадать. Ошибки в монтаже вызывают проблемы с гарантийным ремонтом и могут привести к поломке даже самую дорогую и качественную технику. Неправильно подобранный и установленный кондиционер распространяет охлажденный воздух неравномерно, а это ведет к образованию в комнате зон дискомфорта и сквозняков. В идеале, кондиционер нужно выбирать еще на стадии ремонта квартиры или при составлении дизайн-проекта, обратившись при этом к специалисту.</p>

                <h2>Виды систем кондиционирования квартиры</h2>
                <p><strong>Сплит-системы</strong></p>
                <p>Самым эффективным способом создания в квартире достойного климата является, пожалуй, выбор сплит-системы. Сплит-система представляет собой один наружный блок, устанавливаемый на внешней стене или балконе, и один внутренний. Основной источник шума – компрессор - находится в наружном блоке, поэтому внутренний блок кондиционера работает практически бесшумно.</p>
                <ul>
                    <li>Самой распространенной является настенная сплит-система.</li>
                    <li>Если в квартире предполагается одновременно кондиционировать две-три комнаты с равномерными тепловыми нагрузками, в качестве кондиционера идеально подойдет сплит-система канального типа. Её внутренний блок устанавливается за подвесным или подшивным потолком, а распределение охлажденного воздуха осуществляется по системе воздуховодов, которые размещаются там же. Кроме того, у канального кондиционера есть возможность подачи свежего воздуха с улицы в объемах, необходимых для полноценной вентиляции.</li>
                    <li>Если же в квартире подвесных потолков нет, можно установить сразу несколько сплит-систем настенного типа, по одной на каждую комнату. На сегодняшний день это самый распространенный тип бытовых кондиционеров, его выпускают абсолютно все существующие компании. Настенные сплит-системы хорошо подходят для квартир с неравномерной тепловой нагрузкой, например, когда окна ориентированы на разные стороны света - выходят и на солнечную, и на теневую сторону. С помощью таких кондиционеров микроклимат в каждой комнате может регулироваться индивидуально.</li>
                    <li>Минус такого способа кондиционирования – наружных блоков получается столько же, сколько и внутренних, а это портит внешний вид дома. Наиболее серьезно это сказывается на тех домах, которые находятся в центре города и являются памятниками архитектуры. Кроме того, несколько расположенных рядом внешних блоков на стене дома могут привлечь внимание воров-«домушников».</li>
                </ul>
                <p><strong>Мульти сплит-системы</strong></p>
                <p>Для решения этой проблемы можно использовать мульти сплит-систему - один наружный блок допускает, в зависимости от производителя и модели, использование от 2 до 9 внутренних! К сожалению, такая система или только охлаждает воздух, или только нагревает – делать это одновременно она не умеет. Также как и сплит, мультисплит-система работает почти бесшумно, подразумевает использование разных типов внутренних блоков и, в целом, является хорошим выбором для квартиры.</p>
                <p><strong>Оконные кондиционеры для квартиры</strong></p>
                <img src={o12178sistemy} alt='' />
                <p>Оконные кондиционеры – это нередко используемые системы кондиционирования в квартирах. Их популярность объясняется невысокой ценой и простотой монтажа. Но такая система имеет ряд недостатков. Согласно отзывам о кондиционерах для квартиры, оконные модели уменьшают площадь окна, то есть затеняют помещение. Они довольно громко работают. Завесить шторами окно, в которое встроен кондиционер, не получится, так как занавеска будет препятствовать циркуляции воздуха. К тому же, оконник справляется с охлаждением (обогревом) только одной комнаты.</p>
                <p><strong>Канальные и кассетные системы кондиционирования квартир</strong></p>
                <p>Согласно отзывам на кондиционеры в квартире, имеют несколько преимуществ: при канальном кондиционировании интерьер помещений никак не нарушается оборудованием: воздух поступает в комнаты через воздуховоды. Один наружный модуль обслуживает несколько комнат одновременно. Поставка охлажденного воздуха возможна в самые отдаленные уголки и помещения любой формы.</p>
                <p><strong>Сложные системы кондиционирования квартир</strong></p>
                <p>Если жилище велико, для оптимального кондиционирования в квартире необходимо устанавливать несколько сплит-систем или оборудовать сложную систему вентиляционных труб.</p>
                <p><i>Монтаж нескольких сплит-систем не очень удобен: это портит внешний вид здания снаружи, наружные блоки быстрее выходят из строя под действием солнца, воды и льда. А для монтажа может понадобиться проект кондиционирования квартиры.</i></p>
                <p>Для таких помещений наиболее выигрышной является система «конструктор», имеющая массу положительных отзывов о кондиционерах для квартиры. Монтируется единственный наружный модуль и к нему столько внутренних, сколько требует ситуация.</p>

                <h2>Расчет мощности кондиционера</h2>
                <p>Как выбрать кондиционер для квартиры по площади помещения? Таким вопросом задается себе человек, которому пришла в голову идея самостоятельно рассчитать мощность охлаждения сплит-системы. В принципе такой расчет возможен, но в первую очередь расскажем о том, как подбирается блок с учетом всех правил.</p>
                <p>Так как функцией кондиционера является охлаждение воздуха, то его расчет заключается в том,  чтобы подобрать мощность блока таким образом, чтобы он удалял все поступающие в помещение излишки тепла. Комната в квартире нагревается от разных источников. Они разделяются на внешние и внутренние.</p>
                <p>К внешним относится:</p>
                <ul>
                    <li>солнечное излучение, которая поступает через остекление;</li>
                    <li>тепло, которое передается через стены и другие ограждающие конструкции из-за разницы температуры между внутренним и наружным воздухом;</li>
                    <li>также к наружным причисляют теплопоступления от приточной вентиляции.</li>
                </ul>
                <p>Внутреннее тепло поступает в помещение от людей, электрического и нагревательного оборудования, освещения.</p>
                <p>Основные источники поступления тепла в квартире – это нагрев от солнечного излучения и тепло от бытовой техники и другого оборудования. Людей, внутреннее освещение и вентиляцию часто не принимают во внимание, так как это небольшая доля от общих теплопритоков.</p>
                <p><strong>Внешние теплопритоки</strong></p>
                <p>Но вычисление теплопритоков от солнца довольно трудоемкая задача, для который используется специальная методика и разное количество дополнительных данных. Они зависят от расположения помещения, толщины и конструкции стен, региона, в котором находится квартира. Такое расчет нецелесообразен занимает много времени.</p>
                <p>Для упрощенного подбора кондиционера существует специальная методика, которая привязывает требуемую мощность к объему помещения. Для такого расчета необходимо взять объем комнаты (площадь умноженная на высоту) и умножить на удельную тепловую нагрузку. Удельную тепловую нагрузку для разных ситуаций выбирают таким образом:</p>
                <ul>
                    <li>если в рассматриваемое помещение практически не попадают солнечные лучи и оно находится на неосвещенной стороне – 30–35 Вт/м3;</li>
                    <li>среднее значение – 35 Вт/м3;</li>
                    <li>для помещений с большим остеклением которое выходит на солнечную сторону – 35–40 В/м3.</li>
                </ul>
                <p>То есть, к примеру, для комнаты в жилом доме площадью 20 м3, высотой 3 м , которая расположена на теневой стороне можно подобрать кондиционер 20*3*30=1800 Вт,</p>
                <p>Важно обратить внимание на то, что полученная мощность в Ваттах не является электрической, а обозначает только количество тепла которое способен удалить кондиционер. Этот параметр обычно указан в характеристиках модели и в каталогах кондиционера. Также маркировка проводится по британским тепловым единицам в час (BTU/h), которые связаны со с ваттами по формуле 1 BTU/h =  0,293 Вт. То есть кондиционер 1800 Вт =  6140 BTU/h. По параметрам подойдет кондиционер минимальной мощности из производимых в 2,0 Квт (7000 BTU/h).</p>
                <img src={o1mochtnost} alt='' className='img-width' />
                <p><strong>Внутренние теплопритоки</strong></p>
                <p>Во многих помещениях внутренние теплопритоки составляют существенную часть от общего их количества, но это основном касается общественных, производственных и торговых помещений. В жилье их процент очень мал и в некоторых случаях их можно не учитывать.</p>
                <p>Тепло выделяет электрическое оборудование, моторы, компьютеры, телевизоры и другая бытовая техника. В общем случае тепловыделения от электроники можно принимать как 30% от их потребляемой мощности.</p>
                <p>Тепловыделения от людей в квартирах не учитываются. Что касается вентиляции, то если в квартире устроена система принудительной вентиляции, то при расчете кондиционирования нужно принимать во внимание дополнительное количество тепла, которое поставляется вместе с наружным воздухом в комнату. В среднем, можно при наличии вентиляции мощность кондиционера взять на 20% больше.</p>

                <h2>Расположение, можно ли установить один кондиционер на всю квартиру?</h2>
                <p>Часто возникают вопросы о выборе количества кондиционеров для квартиры. Нужно ли их устанавливать в каждой комнате, или можно разместить один и он будет охлаждать всю квартиру. Например, как выбрать кондиционер для двухкомнатной квартиры?</p>
                <p>Оптимальный вариант, конечно, установить в каждой комнате свой блок. Это делается аналогично как и для приборов отопления. Но при некоторой планировке можно обойтись одним. Это возможно при наличии большого проема между двумя комнатами. В таком случае желательно кондиционер размещать так, чтобы из одной комнаты через дверной проем, часть воздуха попадала в соседнюю. В таком случае в той комнате, где он установлен, будет холоднее, но соседнем помещении в любом случае будет прохладнее, чем без сплит-системы.</p>
                <p>Такой же вариант рассматривается при установке кондиционера в коридоре. При открытых дверях часть воздуха попадает в смежные комнаты, но существует опасность, что кондиционер быстро охладит коридор и выключится. А перетока холодного воздуха будет недостаточно чтобы компенсировать нагрев от наружных. И в том и другом случае кондиционер по мощности должен быть рассчитан на весь объем помещений, которые он призван охладить.</p>
                <p>Также блок необходимо размещать так, чтобы холодный воздух не дул на рабочие места, диваны, кресла и кровати.</p>
                <p><strong>Выбор производителя кондиционера для квартиры и комнаты</strong></p>
                <p>Когда нас спрашивают - какой производитель лучше, мы всегда говорим простые и очевидные вещи: Вы выбираете кондиционер для себя любимых, и конечно хотите, что он прослужил Вам 10-15 лет, а не 2-3 года! </p>
                <p>Требования к техническим характеристикам современных систем кондиционирования очень высоки: </p>
                <ul>
                    <li>минимальное потребление электроэнергии, </li>
                    <li>легкий и удобный монтаж,</li>
                    <li>большой функционал в эксплуатации,</li>
                    <li>качество и надежность,</li>
                    <li>удобство в пользовании,</li>
                    <li>совершенная система управления.</li>
                </ul>
                <p>Именно поэтому мы предлагаем оборудование только зарекомендовавших себя производителей Daikin, Mitsubishi Electric, Mitsubishi Heavy, и General (Fujitsu), все выше перечисленные требования соблюдены и присутствуют в самом лучшем их исполнении в оборудовании этих марок. </p>
                <p>Кондиционеры Daikin и Mitsubishi Electric - это превосходный дизайн , отличные технические характеристики и все самые современные технологические достижения (высокая надежность и самый низкий уровень шума!) </p>
                <p>И самое главное все функции, заявляемые этими производителями в своих кондиционерах реально есть и работают (в отличии от ряда других широко известных марок кондиционеров). </p>
                <p>Особенностью компании Daikin является узкая специализация исключительно в области кондиционирования воздуха, что позволяет создавать уникальные образцы кондиционеров Daikin. </p>

                <h2>Монтаж</h2>
                <p><strong>Как правильно установить внешний блок</strong></p>
                <p>Установка кондиционера всегда начинается с монтажа конденсатора. Исходя из веса внешнего блока, выбираются кронштейны, способные выдержать такую нагрузку. Кронштейны необходимо крепить не к утеплителю либо обшивке дома, а непосредственно к стене — прочному основанию.</p>
                <p>Для защиты устройства от осадков рекомендуется накрыть его козырьком. Это не обязательно, но такая мера продлить дееспособность аппарата. Если же кондиционер находится на одном из первых двух этажей, не лишним будет изготовить для него металлическую решетку-клетку — это защитит устройство от краж.</p>
                <p><i>Согласно правилам установки данного вида климатической техники, дренажная трубка, отходящая от внешнего блока, должна выводиться в канализацию.</i></p>
                <img src={o95494kak} alt='' className='img-width' />
                <p>На практике в наших домах, особенно многоэтажных, это невозможно, потому трубки оставляют свободно висеть. Но звук капающего конденсата может очень не понравиться соседям, живущим ниже. И это естественно — стук капель о подоконник со временем только больше отвлекает и раздражает. Если хочется сохранить с соседями хорошие отношения (а также свои нервы, которые могут уйти на выяснение, кто же прав), стоит предусмотреть этот момент.</p>
                <img src={o87607kak} alt='' className='img-width' />
                <p>Оптимальны выход — удлинить дренажную трубку, чтобы с нее капли падали вдоль стены по линии, где нет оконных проемов либо балконных конструкций. Не забывайте про альтернативный вариант — применение мобильных напольных кондиционеров.</p>
                <p><strong>Где лучше разместить внутренний блок</strong></p>
                <p><i>Расстояние меду блоками прямопропорционально потере мощности устройства. Следовательно, чем меньше расстояние, тем более продуктивно работает кондиционер.</i></p>
                <p>Именно по этой причине внутренний блок предпочтительно устанавливать на той же стене, что и внешний, либо максимально близко к ней.</p>
                <p>При этом важно помнить, что кондиционеры:</p>
                <img src={o18723kak} alt='' />
                <br />
                <img src={o13494kak} alt='' />
                <br />
                <img src={o16501kak} alt='' />
                <ul>
                    <li>не стоит устанавливать над кроватью, диваном либо рабочим столом (местом, где человек долгое время находится — простуды неизбежны);</li>
                    <li>нельзя размещать над окнами либо под потолком, если расстояние до потолка менее 10 см (нарушается естественная циркуляция воздуха);</li>
                    <li>нельзя размещать над батареями (перегрев — частая причина поломок кондиционеров);</li>
                    <li>нельзя размещать в непроветриваемом помещении (в итоге также получается перегрев);</li>
                    <li>не рекомендуется размещать в непосредственной близости от мебели (лучше выдержать дистанцию в 1 м).</li>
                </ul>
                <p>Иногда наиболее удобным вариантом кажется размесить кондиционер над шкафом — его там никто не будет видеть. Но, во-первых, это будет препятствовать нормальной работе устройства, а во-вторых, каждый раз при запуске кондиционера вся пыль с поверхности будет сметаться вниз, и ею будут дышать люди. Потому этот вариант не так хорош, как кажется на первый взгляд.</p>
                <img src={o42831kak} alt='' className='img-width' />
                <p><i>Важно, чтобы к внутреннему блоку кондиционера был обеспечен свободный доступ. Это не только обеспечит более высокую производительность, но и позволит своевременно чистить устройство.</i></p>
                <p>Иногда внутренний блок стремятся разметить за шторами либо ламбрекенами — чтобы не привлекал излишнего внимания. Конечно, он может не подходить к изысканному дизайну интерьера, но такая маскировка препятствует продуктивному охлаждению воздуха.</p>
                <img src={o73934kak} alt='' className='img-width' />
                <p>Лучше на выбор кондиционера потратить немного больше времени, и найти такую модель, испаритель которой будет гармонировать с интерьером.</p>
                <img src={o67707kak} alt='' className='img-width' />
                <p>Современные производители предлагают различные варианты — от лаконичной классики до весьма смелых решений, вплоть до мобильных кондиционеров без воздуховода, потому поиски обязательно увенчаются успехом.</p>
                <p>Систему кондиционирования для квартиры Вы можете заказать с монтажом, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ApartmentAirConditioning