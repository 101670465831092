import { NAVBAR_MENU } from '../types/Types'

const menuName = JSON.parse(window.sessionStorage.getItem('menu'))

const initialState = {
    menu: menuName ? menuName : 'cond'
}

const reducer = (state = initialState, action) => {
    switch( action.type ) {
        case NAVBAR_MENU:
            return {
                ...state,
                menu: action.payload
            }
        default:
            return state
    }
}

export default reducer