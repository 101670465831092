import { Collapse } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';

const OtherMenu = () => {

    const { Panel } = Collapse;

    return (
        <Collapse expandIconPosition='end' defaultActiveKey={['1']}>
            <Panel header="Наши услуги" key="1">
                <Link to='/'>Консультации</Link>
                <Link to='/'>Проектирование инженерных систем</Link>
                <Link to='/'>Монтаж инженерных систем</Link>
                <Link to='/'>Обслуживание инженерных систем</Link>
                <Link to='/'>Обследование инженерных систем</Link>
                <Link to='/'>Поставка оборудования и материалов</Link>
                <Link to='/'>Комплексные решения</Link>
            </Panel>

            <Panel header="Типы объектов" key="2">
                <Link to='/'>Торговый центр</Link>
                <Link to='/'>Магазин, торговое помещение</Link>
                <Link to='/'>Офисное здание</Link>
                <Link to='/'>Склад</Link>
                <Link to='/'>Производство</Link>
                <Link to='/'>Частный дом или коттедж</Link>
                <Link to='/'>Квартира</Link>
            </Panel>
        </Collapse>
    )
}

export default OtherMenu