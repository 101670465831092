import React from 'react'
import { Link } from 'react-router-dom'
import './moisturizing.css'

import breezart from '../../img/breezart-logo_small.png'
import carel_small from '../../img/carel_small.png'
import danvex_small from '../../img/danvex_small.png'

import article12_small from '../../img/article12_small.jpg'
import article123_small from '../../img/article123_small.jpg'
import a553ba73 from '../../img/a553ba73-a9ae-4f77-bbd8-63780df76d0c_small.jpg'
import a96e80977 from '../../img/96e80977-1b2f-467b-b210-ba29f837e75e_small.jpg'
import a700ac16d from '../../img/700ac16d-5751-4485-a815-f771dd91c875_small.jpg'
import e551d946 from '../../img/e551d946-5175-4528-b66e-917e6d0fa483_small.jpg'
import e86cd1ce5 from '../../img/86cd1ce5-5eb4-4fbd-8602-fadf4dc63888_small.jpg'
import e1e47d822 from '../../img/1e47d822-22b0-4ca8-aa86-1a0a7ef08381_small.jpg'
import e7438bff from '../../img/e7438bff-2bfa-40c6-9564-7f238b0ba90f_small.jpg'
import c37e0f6e from '../../img/c37e0f6e-8a7f-4ec5-a38c-dad0deaeec20_small.jpg'
import c3300f701 from '../../img/3300f701-6241-4dca-af14-61c195af8b44_small.jpg'
import b7ffce11 from '../../img/b7ffce11-6b82-4d5f-8640-d67cbd8d9222_small.jpg'
import article1234_small from '../../img/article1234_small.jpg'
import a6e534284 from '../../img/6e534284-99e5-4cad-a53d-374a093f0d26_small.jpg'
import b6e71ee4 from '../../img/b6e71ee4-2f9f-46db-a190-2d4deb796fcf_small.jpg'
import Form from '../form/Form'

const Moisturizing = () => {
    return (
        <>
            <div className='moisturizing-component'>
                <h1>Увлажнение помещений</h1>
                <p>Промышленные увлажнители Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка систем увлажнения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>

                <h2>Производители увлажнителей</h2>
                <div className='products-wrapper'>
                    <Link to=''>
                        <div className='product'>
                            <p>Breezart</p>
                            <div className='bg' style={{ backgroundImage: `url(${breezart})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Carel</p>
                            <div className='bg' style={{ backgroundImage: `url(${carel_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Danvex</p>
                            <div className='bg' style={{ backgroundImage: `url(${danvex_small})` }}></div>
                        </div>
                    </Link>
                </div>

                <h2>Наши услуги</h2>
                <ul>
                    <Link to='/humidification-design'>
                        <li>Проектирование увлажнения</li>
                    </Link>
                    <Link to='/installation-humidification-system'>
                        <li>Монтаж системы увлажнения</li>
                    </Link>
                    <Link to=''>
                        <li>Обслуживание увлажнителей</li>
                    </Link>
                </ul>

                <h2>Увлажнители Carel</h2>
                <p>Оборудование Carel является признанным качественным производителем систем увлажнения. Этому свидетельствует постоянное наблюдение за изменениями у потребителя нужд и требований к климатическому оборудованию Carel. При производстве оборудования компания Carel применяет энергоэффективные решения и использует последние разработки в области инновационных технологий. Каждый элемент увлажнителей тщательно отбирается и испытывается в лабораториях компании Carel. Качество продукции компании Carel подтверждается сертификатами ISO9001 и EUROVENT.  </p>
                <div className='products-wrapper'>
                    <Link to=''>
                        <div className='product'>
                            <p>Изотермические увлажнители</p>
                            <div className='bg' style={{ backgroundImage: `url(${article12_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Адиабатические увлажнители</p>
                            <div className='bg' style={{ backgroundImage: `url(${article123_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Свободнопрограммируемые контроллеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${a553ba73})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Параметрические контроллеры для систем кондиционирования</p>
                            <div className='bg' style={{ backgroundImage: `url(${a96e80977})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Terminals</p>
                            <div className='bg' style={{ backgroundImage: `url(${a700ac16d})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Автоматизация торгового холодильного оборудования</p>
                            <div className='bg' style={{ backgroundImage: `url(${e551d946})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Параметрические контроллеры для холодильного оборудования</p>
                            <div className='bg' style={{ backgroundImage: `url(${e86cd1ce5})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Системы водоподготовки</p>
                            <div className='bg' style={{ backgroundImage: `url(${e1e47d822})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Системы мониторинга и удаленного управления</p>
                            <div className='bg' style={{ backgroundImage: `url(${e7438bff})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Коммуникационное оборудование</p>
                            <div className='bg' style={{ backgroundImage: `url(${c37e0f6e})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Датчики и устройства защиты</p>
                            <div className='bg' style={{ backgroundImage: `url(${c3300f701})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Электронные терморасширительные вентили</p>
                            <div className='bg' style={{ backgroundImage: `url(${b7ffce11})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Решения для управления температурой, влажностью и давлением</p>
                            <div className='bg' style={{ backgroundImage: `url(${article1234_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Регуляторы скорости и инвертеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${a6e534284})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Комплектные щиты управления</p>
                            <div className='bg' style={{ backgroundImage: `url(${b6e71ee4})` }}></div>
                        </div>
                    </Link>
                </div>

                <h2>Что такое влажность?</h2>
                <p>Влажность – это содержание влаги в воздухе. Относительная влажность – это отношение количества влаги, содержащейся в воздухе при данной температуре, к максимальному количеству влаги, которое может содержаться в насыщенном воздухе при той же температуре. Например, при температуре 21 °C 1 кг сухого воздуха может содержать до 15,8 г влаги. В таком случае говорят, что относительная влажность воздуха составляет 100 %. Если при той же температуре 1 кг сухого воздуха содержит 7,9 г влаги, то относительная влажность воздуха составит 50 % (7,9/15,8 = 0,5). Количество влаги, которое может содержать воздух, зависит от температуры и повышается с ее ростом. Если вы возьмете 1 кг сухого воздуха при температуре -18 °С и относительной влажности 100%, с влагосодержанием соответственно 0,92 г, и повысите далее температуру до 21 °C без добавления влаги, то относительная влажность понизится до 6 % (0,92/15,8 = 0,06). Такой сверхсухой воздух является причиной многих проблем.</p>

                <h2>Почему сухой воздух является проблемой?</h2>
                <p>Когда температура наружного воздуха опускается ниже температуры воздуха в помещении, холодный влажный воздух при попадании в теплое здание прогревается и становится сухим. Аналогично тому, как влага из воздуха поглощается материалами, находящимися в здании, теплый сухой воздух вытягивает влагу из всего, с чем он соприкасается, пытаясь достичь «влажностного равновесия».</p>
                <p>Необходимость увлажнения воздуха чаще всего возникает в трех случаях:</p>
                <ul>
                    <li>использование гигроскопических материалов в технологическом процессе или их хранение внутри здания,</li>
                    <li>проблема статического электричества,</li>
                    <li>необходимость создания комфортных условий.</li>
                </ul>
                <p>Системы кондиционирования и холодильное оборудование также способствуют снижению влажности воздуха, когда воздух сначала охлаждается до температуры ниже точки росы, а избыток влаги конденсируется и удаляется. При последующем нагреве воздух становится сухим и вызывает дискомфорт.</p>

                <h2>Гигроскопические материалы</h2>
                <p>В гигроскопических материалах влага проходит через клеточную оболочку и заполняет полость клеток; в результате объем материала увеличивается. Гигроскопические материалы стремятся к выравниванию уровня влажности с окружающей средой. Таким образом, именно перепады относительной влажности, а не температуры воздуха приводят к изменениям объема материалов и непосредственно влияют на свойства материалов и параметры технологических процессов. Аналогичные негативные процессы происходят со всеми гигроскопическими материалами: бумагой, тканями, некоторыми видами пластмасс, воском, фруктами и овощами и т.д.</p>

                <h2>Статическое электричество</h2>
                <p>Процесс образования статического электричества резко падает при поддержании уровня влажности выше 35 %. Люди сталкиваются с этой проблемой во многих сферах деятельности; особенно восприимчивы к электростатическим разрядам электронные устройства – например, компьютеры.</p>

                <h2>Необходимость создания комфортных условий</h2>
                <p>Создание комфортных условий становится все более приоритетной задачей в современном обществе. При поддержании нормальной влажности снижается уровень концентрации пыли, улучшается самочувствие людей, легче переносятся перепады температуры, исключается высыхание и деформация гигроскопичных контактных линз. Кроме того, поддержание комфортного уровня влажности в помещении снижает ощущение сухости кожи, восприимчивость к холоду и позволяет поддерживать температуру в помещении на более низком уровне, что ограничивает энергозатраты и снижает теплопотери всего здания.</p>
                <p>Промышленные увлажнители Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка систем увлажнения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default Moisturizing