import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img11 from '../../../img/11_small (2).jpg'
import img12 from '../../../img/22-05-2014 0-17-48.jpg'
import img13 from '../../../img/22-05-2014 0-08-12.jpg'
import img14 from '../../../img/12613d09fd0a0d09ed095d09c-d092-d0a1d0a2d095d09dd095-min-300x250.jpg'

const SmokeRemoval = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Дымоудаление в системе вентиляции</h1>
                <p>Систему дымоудаления Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем дымоудаления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <img src={img11} alt='' />
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Система противодымной защиты (дымоудаление) – это специально разработанная система удаления дыма. Путем использования объемно-планировочных решений здания, инженерных систем и технических средств, направленных на предотвращение или ограничение опасности задымления здания, сооружения и строения при пожаре, а также воздействия опасных факторов пожара на людей и материальные ценности.</p>

                <h2>Типы систем дымоудаления</h2>
                <p><strong>Системы дымоудаления делятся на:</strong></p>
                <ul>
                    <li>динамические</li>
                    <li>статические</li>
                </ul>
                <p>Системы дымоудаления устанавливаются для нераспространения дыма по помещениям.</p>
                <ul>
                    <li>Динамические системы дымоудаления срабатывают при пожаре и выводят дым из помещений, где они установлены.</li>
                    <li>Статические системы дымоудаления просто отключают все вентиляционные системы помещения, для того чтобы не дать дыму распространяться по вентиляции в другие помещения. В данном случае дымоудаление не осуществляется в полной мере этого слова. По факту все дымоудаление сводиться просто к воспрепятствованию распространения дыма.</li>
                </ul>
                <img src={img12} alt='' />

                <h2>Где нужна система дымоудаления?</h2>
                <p>Системы вытяжной противодымной вентиляции для удаления продуктов горения при пожаре должны быть предусмотрены для:</p>
                <ul>
                    <li>каждого производственного или складского помещения с постоянными рабочими местами без естественного освещения или с естественным освещением через окна и фонари, не имеющие механизированных (автоматически и дистанционно управляемых) приводов для открывания фрамуг в окнах (на уровне 2,2 м и выше от пола до низа фрамуг) и проемов в фонарях (в обоих случаях площадью, достаточной для удаления дыма при пожаре), если помещения отнесены к категориям А, Б, В1 — В3 в зданиях I — IV степени огнестойкости, а также В4, Г или Д в зданиях IV степени огнестойкости;</li>
                    <li>коридоров и холлов жилых, общественных, административно-бытовых и многофункциональных зданий высотой более 28 м. Высота здания определяется разностью отметок поверхности проезда для пожарных автомашин и нижней отметки открывающегося окна (проема) в наружной стене верхнего этажа (не считая верхнего технического);</li>
                    <li>коридоров (туннелей) подвальных и цокольных этажей жилых, общественных, административно-бытовых, производственных и многофункциональных зданий при выходах в эти коридоры из помещений, предназначенных для постоянного пребывания людей (независимо от количества людей в этих помещениях);</li>
                    <li>коридоров длиной более 15 м без естественного освещения зданий с числом этажей два и более: производственных и складских категорий А, Б и В1 — В4; общественных и многофункциональных;</li>
                    <li>общих коридоров и холлов зданий различного назначения с незадымляемыми лестничными клетками;</li>
                    <li>атриумов зданий высотой более 28 м, а также из атриумов высотой более 15 м и пассажей с дверными проемами или балконами, галереями, выходящими в пространство атриумов и пассажей;</li>
                    <li>гардеробных площадью 200 м2 и более;</li>
                    <li>каждого помещения без естественного освещения или с естественным освещением через окна или фонари, не имеющие механизированных (автоматически и дистанционно управляемых) приводов для открывания фрамуг окон и проемов в фонарях, в обоих случаях с площадью, достаточной для удаления дыма при пожаре: общественного, предназначенного для массового пребывания людей; площадью 50 м2 и более с постоянными рабочими местами, предназначенного для хранения или использования горючих веществ и материалов, а также библиотек, книгохранилищ, архивов, складов бумаги; торговых залов магазинов;</li>
                    <li>из помещений для хранения автомобилей закрытых надземных и подземных автостоянок, а также из изолированных рамп этих автостоянок. Допускается проектировать удаление продуктов горения через примыкающий коридор из помещений площадью до 200 м2: производственных категорий В1 — В3, а также предназначенных для хранения или использования горючих веществ и материалов. Для торговых залов магазинов без естественного освещения площадью не более 800 м2 при расстоянии от наиболее удаленной части помещения до ближайшего эвакуационного выхода не более 25 м удаление продуктов горения допускается предусматривать через примыкающие коридоры, рекреации, атриумы.</li>
                </ul>
                <img src={img13} alt='' className='img-width' />
                <p>Выше перечисленные требования по установке систем дымоудаления не распространяются:</p>
                <ul>
                    <li>на помещения (кроме помещений категорий А и Б, и закрытых автостоянок) площадью до 200 м2, оборудованные установками автоматического водяного или пенного пожаротушения;</li>
                    <li>на помещения, оборудованные установками автоматического газового, аэрозольного или порошкового пожаротушения (кроме автостоянок);</li>
                    <li>на коридор и холл, если из всех помещений, имеющих двери в этот коридор или холл, проектируется непосредственное удаление продуктов горения;</li>
                    <li>если на площади основного помещения, для которого предусмотрено удаление продуктов горения, размещены другие помещения, каждое площадью до 50 м2, то удаление продуктов горения из этих помещений допускается не предусматривать;</li>
                    <li>на коридоры без естественного освещения, если во всех помещениях, имеющих выходы в этот коридор, отсутствуют постоянные рабочие места и на выходах из этих помещений в указанный коридор установлены противопожарные двери в дымогазонепроницаемом исполнении с минимальным сопротивлением дымогазопроницанию не менее 1,96·105 м3/кг; фактическое сопротивление дымогазопроницанию противопожарных дверей должно определяться в соответствии с ГОСТ Р 53303.</li>
                </ul>

                <h2>Работа системы дымоудаления</h2>
                <p>Система дымоудаления не используется для тушения пожара. Её главная цель – обеспечить эвакуацию людей из здания, а в частности – обеспечить незадымляемость помещений, коридоров и лестниц. При пожаре самое страшное вовсе не огонь, а именно дым. Окись углерода или «угарный газ» в дозах 0,4% приводит к смерти. За 2-5 минут воздействия плотного слоя дыма человек теряет сознание.</p>
                <p>Система дымоудаления (син. противодымной вентиляции) – это вытяжная и совмещённая с ней приточная противодымная вентиляция. В нормах проектирования до 2009 года приточная противодымная вентиляция не устанавливалась, но на практике чудовищные объемы удаляемого воздуха приводили к всасыванию дверей и затрудняли эвакуацию. Поэтому в 2013 году нормы дополнили.</p>
                <p>На сегодняшний день (2022г.) приточная противодымная вентиляция обязательна!Основная задача притока – компенсировать удаляемый воздух. Мы подаем приточный воздух только для эвакуации людей, ни о каком тушении пожара речи не идет.</p>
                <p>Вытяжная система противодымной вентиляции только из одного коридора удаляет  от 18 000 до 30 000 м3/ч воздуха. Такой объем воздуха сопоставим с общеобменной вентиляцией офисного здания площадью от 3 000 м2. Такой объем воздуха может использоваться для дыхания от 400 до 700 человек.</p>

                <p><strong>Компоненты системы</strong></p>
                <p>Система дымоудаления состоит из объединенных между собой, различных решений вытяжки. Дополнительно применяются также:</p>
                <ul>
                    <li>Специальные пожарные клапаны.</li>
                    <li>Воздуховоды из качественного прочного материала.</li>
                    <li>Вентиляторы, которые закачивают свежий воздух и удаляют дым.</li>
                </ul>
                <p>Работая достаточно слажено, данный комплекс способен с легкостью удалять любую задымленность даже из достаточно больших помещений. Во время работы системы дополнительно вытягивается разнообразные продукты горения современных материалов из полимеров.</p>
                <p>Чтобы повысить возможность ограничения пламени, и таким образом сократить причиненные им убытки, возгорание необходимо локализовать. Система дымоудаления позволяет собрать дымный поток и вывести его наружу.</p>
                <p><strong>Организация системы</strong></p>
                <p>Факторы, которые учитываются в процессе разработки вентиляции:</p>
                <ul>
                    <li>Свойства применяемых в конкретном здании материалов.</li>
                    <li>Перепады давления.</li>
                    <li>Свойства дыма, который выделяется во время горения.</li>
                    <li>Общие размеры конкретного здания.</li>
                </ul>
                <p>Для разработки системы дымоудаления обязательно проводят весьма строгие расчеты, регламентирующие противопожарные требования такой вентиляции. Схема закладывается на самом начале строительства здания.</p>
                <p>Системы дымоудаления, по действующим правилам, должны присутствовать в:</p>
                <ul>
                    <li>Строениях, где больше десяти этажей.</li>
                    <li>Местах, где наблюдается слишком большое скопление людей.</li>
                    <li>Подземных помещениях, где нет естественной вентиляции.</li>
                </ul>

                <h2>Система приточной противодымной вентиляции</h2>
                <p>Приточная система противодымной вентиляции —  система компенсации. Основная цель – обеспечить свободное открытие эвакуационных дверей. Подача воздуха такой системы осуществляется в нижнюю часть помещения, т.е. в часть помещения ниже верхней отсечки дверного проема.</p>
                <p>В качестве притока для компенсации систем механической противодымной вентиляции могут быть использованы:</p>
                <ul>
                    <li>наружные окна в нижних частях помещения с автоматическими приводами;</li>
                    <li>проемы в наружных стенах и шахты с клапанами;</li>
                    <li>механический подпор (с помощью вентилятора).</li>
                </ul>
                <img src={img14} alt='' />
                <p>Первый способ используется крайне редко по той причине, что создает возможность «выгодного недопонимания» со стороны проверяющих органов.</p>
                <p>Второй способ применяется чаще, но имеет одну сложность – огромные габариты шахты. В зарубежных нормативах размер шахты дымоудаления рассчитывается от скорости воздуха не более 1,5 м/с, а в российских – допускается 5-6 м/с.  В случае использования такой шахты, например, на компенсацию дымоудаления из коридора, мы получаем размер воздуховода, как минимум, 1000х600 мм.  Высота воздуховода, прокладываемого под потолком, а именно 600 мм затруднит прокладку смежных коммуникаций и сильно опустит чистовой потолок.</p>
                <p>Третий способ – механический подпор с помощью вентилятора  —  более удобный, но и немногого затратный.</p>
                <p>Размеры воздуховоды в таком случае будет существенно меньше, скажем 800х400 мм. Никаких ограничений по скорости воздуха в механических системах противодымной вентиляции нет и быть не может. Система работает только в случае пожара, поэтому не учитывается в общем балансе электропотребления.</p>
                <p>В случае механического подпора нам придется докупать вентилятор, оборудовать его шкафом автоматизации и частотным пребразователем согласно ГОСТ Р  53302-2009, но это более надежный вариант, чем все остальные.</p>
                <p>Нельзя использовать в качестве притока открывание наружных дверей и ворот, т.к. эвакуационные двери должны оборудоваться устройствами самозакрывания. Данное отступление возможно только в случае атриумов и пассажей.</p>
                <p>Можно использовать в качестве компенсации обычную общеобменную вентиляцию, но на практике это не удобно. Во-первых, объемы приточного воздуха в общеобменной и противодымной вентиляции на порядок отличаются, что приводит к удорожанию вентиляционного оборудования. Во-вторых, требования к системе вентиляции ужесточаются и должны соответствовать требованиям системы противодымной вентиляции. Дешевле сделать две отдельные независимые системы.</p>

                <h2>Естественное дымоудаление</h2>
                <p>В естественной системе дымоудаления, как в любой естественной инженерной системе есть один большой минус и один большой плюс. Плюс в том, что система пассивная, т.е. не требует больших капитальных затрат, не потребляет электроэнергию и имеет минимум рабочих механизмов, которые следует проверять и обслуживать. А минус – в обеспечении стабильной работы такой системы.</p>
                <p>Нормы обязывают нас обеспечить защиту от ветра для таких кровельных клапанов и фрамуг, чего мы совершенно не можем гарантировать.</p>
                <p>Естественное дымоудаление не требует системы компенсации. Расчет системы естественного дымоудаления выполняется в зависимости от формы помещения, вида пожарной нагрузки (что именно горит), площади и возможного расположения очага пожара.</p>
                <p>Система естественного дымоудаления используется только в одноэтажных зданиях: складах, торговых центрах складского типа, производственных цехах. Оборудование такой системы в зданиях этажностью более одного – запрещено.</p>

                <h2>Механическая система дымоудаления (крышной вентилятор и пристенный вентилятор)</h2>
                <p>Механическая система дымоудаления работает от вытяжного вентилятора. Обычно вентиляторы дымоудаления бывают 2 типов — крышной и пристенный. Оба вентилятора выполнять одинаковую роль, но совершенно в разных ситуациях.</p>
                <p>Крышной вентилятор дымоудаления устанавливается поверх шахты дымоудаления на кровле и удаляет дым из всех этажей здания, выбрасывая вертикально вверх. Сложность установки такого вентилятора заключается в сложности конструкции монтажной рамы. Долгое время готовых монтажных рам для подобных вентиляторов не производилось и приходилось разрабатывать дополнительный раздел проектной документации, в котором рассчитывались размеры подобной конструкции. Вторая сложность в типе вентиляторов.</p>
                <p>Крышной вентилятор предназначен для установки на шахту и должен располагаться на высоте 2 метра от кровли, либо на меньшей высоте, но в таком случае необходимо выполнять кровлю только из негорючих материалов.</p>
                <p>Самым простым решением для размещения вентиляторов дымоудаления на кровле, считаю, осевые крышные вентиляторы, либо канальные вентиляторы дымоудаления. Они никак не влияют на гидроизоляцию кровли. Не требует установки дополнительных шахт и рам.</p>
                <p>Воздуховоды систем противодымной вентиляции можно выполнять из любого вида стали, но с огнезащитным покрытием. Можно использовать как сварные, так фальцевые и спирально-навивные воздуховоды с единственным требованием: толщина листа стали не менее 0,8 мм.</p>
                <p>Пристенный вентилятор, в отличии от крышного, является локальным, т.е. может работать на конкретный этаж, и выбрасывать продукты горения через решетку на фасаде здания. Это позволяет не прокладывать воздуховоды через все этажи на кровлю и не оборудовать вытяжную шахту. Вентилятор размещается на наружной стене этажа, либо с улицы, либо внутри помещения.</p>
                <p>Для дымоудаления с парковок, больших торговых площадей, пристенные вентиляторы скорее всего не подойдут. Максимальный расход удаляемого воздуха составляется 35 000- 38 000 м3/ч. Но для дымоудаления из коридоров, небольших офисных и торговых помещений – отличная идея.</p>
                <p>Систему дымоудаления Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем дымоудаления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default SmokeRemoval