import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './winterGardenAirConditioning.css'

import img5 from '../../../img/5_small.jpg'
import img4 from '../../../img/49590zimniy-sad.jpg'

const WinterGardenAirConditioning = () => {
    return (
        <>
            <div className='winter-garden-air-conditioning-page'>
                <h1>Кондиционирование зимнего сада</h1>
                <p>Систему кондиционирования зимнего сада Вы можете заказать с монтажом, позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <img src={img5} alt='' />
                <p>Создание комфортного климата для растений зимнего сада – довольно непростая задача. Одной из проблем является частый полив, а также опрыскивание листьев, приводящее к появлению конденсата. Избежать этого невозможно даже в отапливаемом зимнем саду. Помимо повышенной влажности, которая нежелательна для многих растений, конденсат нарушает изоляцию, в связи с этим приходится создавать идеальный климат искусственно.</p>
                <p>В холодное время года при помощи системы кондиционирования, ионизаторов, очистителей и увлажнителей воздуха можно поддерживать должный уровень климата зимнего сада. Оптимальной температурой для не отапливаемых садов является +7… +8 градусов, для умеренных - +15...+20, а для отапливаемых - +20...+25 градусов тепла. При этом влажность в помещении должна составлять приблизительно 60%. Не рекомендуется применять для обогрева обыкновенные бытовые радиаторы, так как они пересушивают воздух. Наилучшим вариантом для зимнего сада будет система «тёплый пол».</p>

                <h2>Особенности кондиционирования</h2>
                <p>Летом, особенно в период сильной жары, температура может подниматься в нем до предельных значений, в результате чего нежные виды экзотических растений, цветов и карликовых деревьев могут попросту погибнуть. Именно поэтому в зимних садах, так же, как и в наших домах, нужно устанавливать кондиционеры и включать их по мере повышения температуры.</p>
                <p>Для зимнего сада можно использовать мобильный вид системы кондиционирования – моноблок, который, правда, сильно шумит и требует окна для отвода тепла через специальный шланг или сплит-систему – двухблочная система, обладающая явным преимуществом перед моноблоками: такие кондиционеры помимо охлаждения могут подогревать воздух при температуре окружающей среды до -5⁰С (дорогие модели способны поддерживать тепло в помещении при температуре «за окном» до -15⁰С, а некоторые модели кондиционеров Panasonic способны работать и при -25⁰С).</p>
                <p>Наличие функции обогрева является очень важным, когда дом подключен к центральной системе отопления (таунхаусы в городе, квартиры последних этажей, где хозяева также часто устраивают зимний сад или оранжерею).</p>
                <p>При установке кондиционера в зимний сад главное соблюдать одно основное правило – холодный воздух не должен попадать на растения.</p>
                <p>Иначе они могут пострадать, но уже от холода. Нельзя забывать и о вентиляции помещения сада.</p>
                <p>В качестве альтернативного варианта вентиляции можно установить климатический комплекс, например, увлажнитель Sharp, который устранит все бактерии и вредоносные микроорганизмы из воздуха, сохранив здоровье вас и ваших растений. </p>
                <p>Помимо заботы о растениях в зимнем саду установка кондиционеров выполняет и другую не менее важную функцию – создание комфортного микроклимата внутри помещения для владельца сада.</p>
                <p>Например, когда оранжерея планируется в качестве дополнительной комнаты для приема пищи, общения, отдыха, в ней должна быть оптимальная для человека влажность (40-60%) и температура (20-22⁰С).</p>
                <p>Правда здесь необходимо учесть и требования самих обитателей сада, поскольку некоторые растения превосходно себя чувствуют в тропическом микроклимате, который для многих людей может оказаться просто неприемлемым с точки зрения комфорта и состояния здоровья.</p>

                <h2>Что нужно знать при установке кондиционера в зимнем саду?</h2>
                <p>При использовании системы кондиционирования понижается температура помещения, однако уменьшается влажность воздуха и не происходит насыщение его кислородом. При отсутствии приточно-вытяжной вентиляции и увлажнителя-очистителя, в зимнем саду со временем появится запах прохладной затхлости. Важно отметить, что установка кондиционеров в зимних садах из стекла позволяет избежать проблемы образования конденсата на окнах в летний период. При грамотном распределении воздушного потока горячий воздух из верхнего слоя будет охлаждаться и высушиваться, и при соприкосновении его с поверхностью стекла на нем не будет образовываться конденсат. Чтобы предупредить появление конденсата зимой в отапливаемых и неотапливаемых зимних садах, следует продумать систему вентиляции или использовать специальные проветривали.</p>
                <p>И не забывайте ухаживать за кондиционером так же тщательно, как за растениями. Их вы привыкли пропалывать и удобрять самостоятельно, но для обслуживания сплит-системы лучше вызвать профессионалов. Они всегда в своей работе используют фирменную расходку для кондиционеров, что позволяет гарантировать высококачественный монтаж техники и отличное техобслуживание системы.</p>

                <h2>Какой кондиционер установить в зимний сад?</h2>
                <img src={img4} alt='' className='img-width' />
                <p>Комфортный микроклимат в помещении, где живут растения – залог их красоты и здоровья. При несоблюдении рекомендованных условий выращивания, все растения, даже при ежедневном поливе, могут заболеть. Чтобы сохранить свои растения, любимые цветы и деревья в отличном состоянии следует использовать увлажнители воздуха (лучше климатические комплексы, очищающие воздушную массу помещения от бактерий и микробов) и устанавливать кондиционеры в зимние сады.</p>
                <p>Здесь могут возникнуть проблемы выбора. Дело в том, что весь жаркий воздух скапливается в верхней зоне, именно поэтому в качестве кровли оранжереи и зимнего сада используют затемненный поликарбонат или покрытые специальной защитной пленкой материалы. Итак, чтобы прямой поток холодного воздуха не попадал от кондиционера на растения, важно правильно выбрать модель системы охлаждения. Некоторые специалисты рекомендуют устанавливать потолочные кондиционеры или, как минимум, настенные.</p>
                <p>При использовании потолочного варианта теплый воздух будет смешиваться с прохладным, благодаря чему к растениям будет доставляться оптимальный по температуре воздух. Самым эффективным в этом случае является канальный кондиционер, позволяющий равномерно распределить воздушные потоки внутри зимнего сада. Холодный воздух в этом случае отправляется под потолок и расходится по оранжереи/зимнему саду по сети раздвоенных воздуховодов. На практике холодный воздух будет «настилать» на всю площадь сада, мягко окутывая растения. Настенные сплит – системы, которые мы сегодня устанавливаем в свои квартиры и дома, могут оказаться губительными для растений.</p>
                <p>Немаловажным является и расчет кондиционера по площади зимнего сада. Если приобрести сплит-систему недостаточной мощности, добиться идеального микроклимата в помещении не удастся. Кроме того, установка системы кондиционирования должна проводиться профессионалами с использованием высококачественной расходки для кондиционеров, в противном случае растения вместо прохлады могут получить «дозу» фреона, утекающего из прохудившейся трубки. Следует помнить, что сплит-система в зимнем саду также должна проходить техобслуживание с применением фирменной расходки для кондиционеров, иначе кондиционер работать должным образом не будет. И нельзя забывать включать увлажнитель параллельно с кондиционером, в противном случае вы рискуете погубить своих «зеленых питомцев» недостатком влаги.</p>
                <p>Систему кондиционирования зимнего сада Вы можете заказать с монтажом, позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default WinterGardenAirConditioning