import React from 'react'
import { Link } from 'react-router-dom'

import i2_8_small from '../../img/i2_8_small.jpg'
import montazh_sistemy_ventilyatsii_small1_small from '../../img/montazh_sistemy_ventilyatsii_small1_small.jpg'
import kontrol_za_ochistkoj from '../../img/kontrol_za_ochistkoj-sistem-ventilyatsii_j76danpqbt_small.jpg'
import pusko_naladka_ventilyatsii_small1_small from '../../img/pusko_naladka_ventilyatsii_small1_small.jpg'
import pasport_na_ventilyaciyu from '../../img/pasport_na_ventilyaciyu-400x278_small.jpg'
import diagnostika_obsledovanie from '../../img/diagnostika_obsledovanie_sistem_ventilyatsii_small1_small.jpg'
import o6dedf315458094f7606925f97f83217b_small from '../../img/o6dedf315458094f7606925f97f83217b_small.jpg'
import remont_ventilyatsii_small1_small from '../../img/remont_ventilyatsii_small1_small.jpg'
import pritochno_vytyazhnaya_ventilyatsiya_small1_small from '../../img/pritochno_vytyazhnaya_ventilyatsiya_small1_small.jpg'
import b111 from '../../img/111_1_min_small.jpg'
import vytyazhnaya1_sistema_ventilyatsii_small1_small from '../../img/vytyazhnaya1_sistema_ventilyatsii_small1_small.jpg'
import ventilyatsiya0_rekuperatsiey_small1_small from '../../img/ventilyatsiya0_rekuperatsiey_small1_small.jpg'
import i2017 from '../../img/i2017-08-13_22-45-22-300x271_small.png'
import oo11 from '../../img/oo11-1_small.jpg'
import sistemy_dymoudaleniya_small1_small from '../../img/sistemy_dymoudaleniya_small1_small.jpg'
import vavv_sistemy_small1_small from '../../img/vavv_sistemy_small1_small.jpg'
import tsentralnaya_sistema_ventilyatsii_small1_small from '../../img/tsentralnaya_sistema_ventilyatsii_small1_small.png'
import hoval from '../../img/hoval-topvent-ventilation-heating_small.jpg'
import sistemy_aspiratsii_small from '../../img/sistemy-aspiratsii_small.jpg'
import promyshlennaya from '../../img/promyshlennaya-ventilyatsiya_small1_small.jpg'
import prodaja_k from '../../img/prodaja-kvartira-ternopol-bam-zluki-prospekt__61113131f_small.jpg'
import y7_small from '../../img/y7_small.jpg'
import ofis_small from '../../img/ofis_small.jpg'
import produktovyy_magazin_2_small from '../../img/produktovyy_magazin_2_small.jpg'
import u3e2df1e1a73c4f5e30e2139e2d1a6e3d_small from '../../img/u3e2df1e1a73c4f5e30e2139e2d1a6e3d_small.jpg'
import obsch_small from '../../img/obsch_small.jpg'
import o011_kopirovat_small from '../../img/011_kopirovat_small.jpg'
import sklad_small from '../../img/sklad_small.jpg'
import tseh_small from '../../img/tseh_small.jpg'
import ventilyatsiya_servernoj_small from '../../img/ventilyatsiya-servernoj_small.jpg'
import bas_small from '../../img/bas_small.jpg'
import zhil_small from '../../img/zhil_small.jpg'
import a730f30543e_small from '../../img/a730f30543e_small.jpg'
import sport_small from '../../img/sport_small.jpg'
import ventilyatsiya_avtostoyanki_azs_small1_small from '../../img/ventilyatsiya_avtostoyanki_azs_small1_small.jpg'
import kino_small from '../../img/kino_small.jpg'
import b1520 from '../../img/b1520-e1489483955428_small.jpg'
import shkola_small from '../../img/shkola_small.jpg'
import stom_small from '../../img/stom_small.jpg'
import gost_small from '../../img/gost_small.jpg'
import zim_small from '../../img/zim_small.jpg'
import sauna_casual from '../../img/sauna-casual-interiorr-en-abeto-natural_small.jpg'

import daikin_small1_small from '../../img/daikin_small1_small.png'
import ventmashine_small_small from '../../img/ventmashine_small_small.jpg'
import vts_logo_n_small from '../../img/vts_logo_n_small.png'
import breezart from '../../img/breezart-logo_small.png'
import lossney_small_small from '../../img/lossney_small_small.jpg'
import shuft_small from '../../img/shuft_small.png'
import swegon_small from '../../img/swegon_small.png'
import fujitsu_small from '../../img/fujitsu_small.jpg'
import systemair_small from '../../img/systemair_small.png'
import ballu_small from '../../img/ballu_small.png'
import lessar_small from '../../img/lessar_small.png'
import soler from '../../img/u3401-140x55_small.jpg'

import './ventilation.css'
import Form from '../form/Form'

const Ventilation = () => {
    return (
        <>
            <div className='ventilation-component'>
                <h1>Вентиляция</h1>
                <p>Вентиляцию Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Проектирование и поставка вентиляционного оборудования по Узбекистан. Письменную заявку просим Вас отправить на email fidclimat@mail.ru или через <Link to='/form'>форму</Link> на сайте.</p>
                <p>Система вентиляции — это совокупность устройств для обработки, транспортирования, подачи и удаления воздуха.</p>

                <h2>Услуги по вентиляции</h2>
                <div className='products-wrapper'>
                    <Link to='/ventilation-design'>
                        <div className='product'>
                            <p>Проектирование вентиляции</p>
                            <div className='bg' style={{ backgroundImage: `url(${i2_8_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/installation-ventilation'>
                        <div className='product'>
                            <p>Монтаж вентиляции</p>
                            <div className='bg' style={{ backgroundImage: `url(${montazh_sistemy_ventilyatsii_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/ventilation-maintenance'>
                        <div className='product'>
                            <p>Обслуживание вентиляции</p>
                            <div className='bg' style={{ backgroundImage: `url(${kontrol_za_ochistkoj})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Пуско-наладка вентиляции</p>
                            <div className='bg' style={{ backgroundImage: `url(${pusko_naladka_ventilyatsii_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Паспортизация вентиляции</p>
                            <div className='bg' style={{ backgroundImage: `url(${pasport_na_ventilyaciyu})` }}></div>
                        </div>
                    </Link>
                    <Link to='/diagnosis-examination-ventilation'>
                        <div className='product'>
                            <p>Диагностика и обследование вентиляции</p>
                            <div className='bg' style={{ backgroundImage: `url(${diagnostika_obsledovanie})` }}></div>
                        </div>
                    </Link>
                    <Link to='/modernization-reconstruction-ventilation'>
                        <div className='product'>
                            <p>Модернизация и реконструкция вентиляции</p>
                            <div className='bg' style={{ backgroundImage: `url(${o6dedf315458094f7606925f97f83217b_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/ventilation-repair'>
                        <div className='product'>
                            <p>Ремонт вентиляции</p>
                            <div className='bg' style={{ backgroundImage: `url(${remont_ventilyatsii_small1_small})` }}></div>
                        </div>
                    </Link>
                </div>

                <h2>Назначение вентиляции</h2>
                <p>Основное назначение вентиляции — борьба с вредными выделениями в помещении. К вредным выделениям относятся:</p>
                <ul>
                    <li>избыточное тепло;</li>
                    <li>избыточная влага;</li>
                    <li>различные газы и пары вредных веществ;</li>
                    <li>пыль.</li>
                </ul>

                <h2>Типы вентиляции</h2>
                <div className='products-wrapper'>
                    <Link to='/supply-exhaust-ventilation'>
                        <div className='product'>
                            <p>Приточно-вытяжная вентиляция</p>
                            <div className='bg' style={{ backgroundImage: `url(${pritochno_vytyazhnaya_ventilyatsiya_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/forced-ventilation'>
                        <div className='product'>
                            <p>Приточная вентиляция</p>
                            <div className='bg' style={{ backgroundImage: `url(${b111})` }}></div>
                        </div>
                    </Link>
                    <Link to='/exhaust-ventilation'>
                        <div className='product'>
                            <p>Вытяжная вентиляция</p>
                            <div className='bg' style={{ backgroundImage: `url(${vytyazhnaya1_sistema_ventilyatsii_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/heat-recovery-ventilation'>
                        <div className='product'>
                            <p>Вентиляция с рекуперацией</p>
                            <div className='bg' style={{ backgroundImage: `url(${ventilyatsiya0_rekuperatsiey_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/recirculation-ventilation'>
                        <div className='product'>
                            <p>Вентиляция с рециркуляцией</p>
                            <div className='bg' style={{ backgroundImage: `url(${i2017})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Центральные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${oo11})` }}></div>
                        </div>
                    </Link>
                    <Link to='/smoke-removal'>
                        <div className='product'>
                            <p>Дымоудаление</p>
                            <div className='bg' style={{ backgroundImage: `url(${sistemy_dymoudaleniya_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>VAV системы</p>
                            <div className='bg' style={{ backgroundImage: `url(${vavv_sistemy_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/central-ventilation'>
                        <div className='product'>
                            <p>Центральная вентиляция</p>
                            <div className='bg' style={{ backgroundImage: `url(${tsentralnaya_sistema_ventilyatsii_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Децентрализованная вентиляция</p>
                            <div className='bg' style={{ backgroundImage: `url(${hoval})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Системы аспирации</p>
                            <div className='bg' style={{ backgroundImage: `url(${sistemy_aspiratsii_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/industrial-ventilation'>
                        <div className='product'>
                            <p>Промышленная вентиляция</p>
                            <div className='bg' style={{ backgroundImage: `url(${promyshlennaya})` }}></div>
                        </div>
                    </Link>
                </div>

                <h2>Типы вентиляционных систем</h2>
                <p>Системы вентиляции классифицируются по следующим признакам:</p>
                <ul>
                    <li>По способу создания давления и перемещения воздуха: с естественным и искусственным (механическим) побуждением;</li>
                    <li>По назначению: приточные и вытяжные;</li>
                    <li>По способу организации воздухообмена: общеобменные, местные, аварийные, противодымные;</li>
                    <li>По конструктивному исполнению: канальные и бесканальные.</li>
                </ul>
                <p>По количеству воздуха на человека в час. К примеру, в кухне при 4-конфорочной газовой плите 90 м3/ч, в совмещенном санузле 50 м3/ч, в бомбоубежище — не менее 2,5 м³/ч, в офисном помещении — не менее 20 м³ в час для посетителей, находящихся в помещении не более 2 часов, для постоянно находящихся людей — не менее 60 м³ в час. Расчёт вентиляции производится с помощью следующих параметров: производительность по воздуху (м³/ч), рабочее давление (Па) и скорость потока воздуха в воздуховодах (м/с), допустимый уровень шума (дБ), мощность калорифера (кВт). Норматив по воздухообмену регламентируется строительными нормами и правилами (СНиП) и санитарными нормами и правилами (Сан Пин)</p>

                <p><strong>Типы систем по способу побуждения движения воздуха</strong></p>
                <ul>
                    <li><strong>Естественная вентиляция.</strong> При естественной вентиляции воздухообмен осуществляется из-за разницы давления снаружи и внутри здания.Под неорганизованной естественной системой вентиляции понимается воздухообмен в помещении, происходящий за счет разности давлений внутреннего и наружного воздуха и действий ветра через неплотности ограждающих конструкций, а также при открывании форточек, фрамуг и дверей. Организованной естественной вентиляцией называется воздухообмен, происходящий за счет разности давлений внутреннего и наружного воздуха, но через специально устроенные приточные и вытяжные проемы, степень открытия которых регулируется. Для создания пониженного давления в вентиляционном канале может использоваться дефлектор.</li>
                    <li><strong>Механическая вентиляция.</strong> При механической вентиляции воздухообмен происходит за счет разности давления, создаваемой вентилятором или эжектором. Этот способ вентиляции более эффективен, так как воздух предварительно может быть очищен от пыли и доведен до требуемой температуры и влажности. В механических системах вентиляции используются такие приборы и оборудование, как: вентиляторы, электродвигатели, воздухонагреватели, шумоглушители, пылеуловители, автоматика и др., позволяющие перемещать воздух в больших пространствах. Такие системы могут подавать и удалять воздух из локальных зон помещения в необходимом количестве, независимо от изменяющихся условий окружающей воздушной среды. При необходимости воздух подвергают различным видам обработки (очистке, нагреванию, увлажнению и т. д.), что практически невозможно в системах естественной вентиляции. Затраты электроэнергии на их работу могут быть довольно большими.</li>
                </ul>

                <p><strong>Типы систем по назначению</strong></p>
                <ul>
                    <li><strong>Приточная вентиляция.</strong> Приточной системой вентиляции называется система, подающая в помещение определенное количество воздуха, который так же может подогреваться в зимний период.</li>
                    <li><strong>Вытяжная вентиляция.</strong> Вытяжная вентиляция служит для удаления из помещения отработанного воздуха, а также продуктов сгорания природного газа от газовых плит.</li>
                </ul>

                <p><strong>Типы систем по способу организации воздухообмена</strong></p>
                <ul>
                    <li><strong>Общеобменная вентиляция.</strong> Общеобменная система вентиляции предусматривается для создания одинаковых условий и параметров воздушной среды (температуры, влажности и подвижности воздуха) во всём объёме помещения, главным образом в его рабочей зоне (1,5—2,0 м от пола), когда вредные вещества распространяются по всему объёму помещения и нет возможности (или нет необходимости) их уловить в месте образования.</li>
                    <li><strong>Местная вентиляция.</strong> Местной вентиляцией называется такая, при которой воздух подают на определённые места (местная приточная вентиляция) и загрязнённый воздух удаляют только от мест образования вредных выделений (местная вытяжная вентиляция). Местная приточная вентиляция может обеспечивать приток чистого воздуха (предварительно очищенного и подогретого) к определённым местам. И наоборот, местная вытяжная вентиляция удаляет воздух от определённых мест с наибольшей концентрацией вредных примесей в воздухе. Примером такой местной вытяжной вентиляции может быть вытяжка на кухне, которая устанавливается над газовой или электрической плитой. Чаще всего используются такие системы в промышленности.</li>
                    <li><strong>Аварийная вентиляция.</strong> Аварийная система вентиляции устанавливается в помещениях, где возможен неожиданный выброс чрезвычайно опасных вредных веществ в количествах, значительно превышающих ПДК, с целью их быстрого удаления. Аварийная вентиляция необходима для удаления газа в помещениях с газововым пожаротушением, для удаления газа после работы системы.</li>
                    <li><strong>Противодымная вентиляция.</strong> Противодымная система вентиляции устанавливается в производственных зданиях, где применяются технологии с повышенной пожароопасностью, и служит для обеспечения эвакуации людей. С помощью этой системы подается необходимое количество воздуха, препятствующего распространению дыма в помещении. Система работает в начальной стадии пожара.</li>
                </ul>

                <p><strong>Типы систем по конструктивному исполнению</strong></p>
                <ul>
                    <li><strong>Канальная вентиляция.</strong> Канальные системы вентиляции имеют сеть воздуховодов для перемещения воздуха.</li>
                    <li><strong>Бесканальная вентиляция.</strong> При бесканальной системе вентилятор устанавливают в стене, перекрытии.</li>
                </ul>

                <h2>Решения по вентиляции</h2>
                <div className='products-wrapper'>
                    <Link to='/apartment-ventilation'>
                        <div className='product'>
                            <p>Вентиляция квартиры</p>
                            <div className='bg' style={{ backgroundImage: `url(${prodaja_k})` }}></div>
                        </div>
                    </Link>
                    <Link to='/ventilation-house-cottage'>
                        <div className='product'>
                            <p>Вентиляция дома или коттеджа</p>
                            <div className='bg' style={{ backgroundImage: `url(${y7_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/office-ventilation'>
                        <div className='product'>
                            <p>Вентиляция офисов</p>
                            <div className='bg' style={{ backgroundImage: `url(${ofis_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/shop-ventilation'>
                        <div className='product'>
                            <p>Вентиляция магазинов</p>
                            <div className='bg' style={{ backgroundImage: `url(${produktovyy_magazin_2_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/shopping-mall-ventilation'>
                        <div className='product'>
                            <p>Вентиляция торгового центра</p>
                            <div className='bg' style={{ backgroundImage: `url(${u3e2df1e1a73c4f5e30e2139e2d1a6e3d_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/ventilation-system-restaurant-cafe-bar'>
                        <div className='product'>
                            <p>Система вентиляции в ресторане, кафе или баре</p>
                            <div className='bg' style={{ backgroundImage: `url(${obsch_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/production-ventilation'>
                        <div className='product'>
                            <p>Вентиляция производства</p>
                            <div className='bg' style={{ backgroundImage: `url(${o011_kopirovat_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/warehouse-ventilation'>
                        <div className='product'>
                            <p>Вентиляция склада</p>
                            <div className='bg' style={{ backgroundImage: `url(${sklad_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция цеха</p>
                            <div className='bg' style={{ backgroundImage: `url(${tseh_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция серверной комнаты</p>
                            <div className='bg' style={{ backgroundImage: `url(${ventilyatsiya_servernoj_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция бассейна</p>
                            <div className='bg' style={{ backgroundImage: `url(${bas_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/ventilation-residential-buildings'>
                        <div className='product'>
                            <p>Вентиляция жилых зданий</p>
                            <div className='bg' style={{ backgroundImage: `url(${zhil_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция административного здания</p>
                            <div className='bg' style={{ backgroundImage: `url(${a730f30543e_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/gym-fitness-center-ventilation'>
                        <div className='product'>
                            <p>Вентиляция спортзала или фитнес центра</p>
                            <div className='bg' style={{ backgroundImage: `url(${sport_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция автосервиса, автостоянки</p>
                            <div className='bg' style={{ backgroundImage: `url(${ventilyatsiya_avtostoyanki_azs_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция кинотеатра или клуба</p>
                            <div className='bg' style={{ backgroundImage: `url(${kino_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция детского сада</p>
                            <div className='bg' style={{ backgroundImage: `url(${b1520})` }}></div>
                        </div>
                    </Link>
                    <Link to='/school-ventilation'>
                        <div className='product'>
                            <p>Вентиляция школы</p>
                            <div className='bg' style={{ backgroundImage: `url(${shkola_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция чистых помещений</p>
                            <div className='bg' style={{ backgroundImage: `url(${stom_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/hotel-ventilation'>
                        <div className='product'>
                            <p>Вентиляция гостиницы или отеля</p>
                            <div className='bg' style={{ backgroundImage: `url(${gost_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция зимнего сада</p>
                            <div className='bg' style={{ backgroundImage: `url(${zim_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Вентиляция бани или сауны</p>
                            <div className='bg' style={{ backgroundImage: `url(${sauna_casual})` }}></div>
                        </div>
                    </Link>
                </div>

                <h2>Производители вентиляционных систем</h2>
                <div className='products-wrapper'>
                    <Link to=''>
                        <div className='product'>
                            <p>Daikin</p>
                            <div className='bg' style={{ backgroundImage: `url(${daikin_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Ventmachine</p>
                            <div className='bg' style={{ backgroundImage: `url(${ventmashine_small_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>VTS</p>
                            <div className='bg' style={{ backgroundImage: `url(${vts_logo_n_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Breezart</p>
                            <div className='bg' style={{ backgroundImage: `url(${breezart})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Lossney</p>
                            <div className='bg' style={{ backgroundImage: `url(${lossney_small_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Shuft</p>
                            <div className='bg' style={{ backgroundImage: `url(${shuft_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Swegon</p>
                            <div className='bg' style={{ backgroundImage: `url(${swegon_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Fujitsu</p>
                            <div className='bg' style={{ backgroundImage: `url(${fujitsu_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Systemair</p>
                            <div className='bg' style={{ backgroundImage: `url(${systemair_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Ballu Machine</p>
                            <div className='bg' style={{ backgroundImage: `url(${ballu_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Lessar</p>
                            <div className='bg' style={{ backgroundImage: `url(${lessar_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Soler & Palau</p>
                            <div className='bg' style={{ backgroundImage: `url(${soler})` }}></div>
                        </div>
                    </Link>
                </div>
                <p>Вентиляцию Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка вентиляционного оборудования по Узбекистан. Письменную заявку просим Вас отправить на email <a href="mailto:fidclimat@mail.ru">fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default Ventilation