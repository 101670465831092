import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './conditionersWithVentilation.css'

import raschot from '../../../img/raschet-moshchnosti-konditsionera-600x600_small.jpg'
import ururu_Sarara from '../../../img/Ururu_Sarara.jpg'
import FXDQ from '../../../img/FXDQ.jpg'
import o10 from '../../../img/10(36).jpg'
import FDEQ from '../../../img/FDEQ.jpg'
import podmes from '../../../img/podmes.jpg'

const ConditionersWithVentilation = () => {
    return (
        <>
            <div className='conditioners-with-ventilation-page'>
                <h1>Кондиционеры с приточной вентиляцией воздуха</h1>
                <p>Кондиционеры с приточной вентиляцией Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Проектирование и поставка кондиционеров с приточной вентиляцией по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p><strong>При недостаточной вентиляции в закрытых помещениях,</strong> возникающей после установки герметичных стеклопакетов или в силу иных причин, возрастает концентрация углекислого газа и других вредных веществ, повышается влажность, снижается содержание кислорода в воздухе, появляются неприятные запахи. Все это негативно влияет на здоровье людей, вызывает головную боль, сонливость, потерю работоспособности. Кондиционер с подмесом свежего воздуха позволяет снизить относительное содержание СО2 в помещении, что, несомненно, благотворно скажется на вашем самочувствии.</p>
                <img src={raschot} alt='' />
                <p>В отличие от обычных кондиционеров, не подающих свежий воздух в помещение, <strong>кондиционеры с приточной вентиляцией</strong> - это высокоэффективная система кондиционирования и вентиляции воздуха. Вы круглый год поддерживаете в своих комнатах желаемую температуру и поступление очищенного свежего воздуха в соответствии с санитарными нормами.</p>
                <p><strong>К кондиционерам с подмесом воздуха можно отнести некоторые модели настенных и канальных <Link to='/'>сплит-систем.</Link></strong></p>
                <p>Так как производители не стоят на месте, модельный ряд кондиционеров постоянно обновляется и появляется все больше моделей с возможностью подмеса воздуха, <strong>ассортимент и функциональные возможности этих моделей уточняйте у менеджеров FIDCLIMAT.</strong></p>
                <img src={ururu_Sarara} alt='' />

                <h2>Принцип работы сплит-системы с приточной вентиляцией</h2>
                <p><strong>Кондиционер состоит из двух блоков:</strong></p>
                <ol>
                    <li>внутренний блок, включающий фильтр, вентилятор, охладитель и электронную панель управления </li>
                    <li>наружный блок, включающий компрессорно-конденсаторный агрегат с тепловым насосом и модулем микропроцессорного управления</li>
                </ol>
                <img src={FXDQ} alt='' />
                <p><strong>Внутренний блок</strong> устанавливается на стене (настенная сплит-система) либо за подвесным потолком или в коробе (канальная сплит-система), при этом дизайн интерьера не нарушается, потому что все оборудование монтируется за подвесным потолком. В интерьере остаются лишь изящные декоративные решетки для подачи воздуха.</p>
                <p><strong>Наружный блок</strong> устанавливается на улице или в помещении (в случае комплектации блока центробежным вентилятором). Они соединяются тонким фреоновым трубопроводом. К внутреннему блоку подключается электрический или водяной калорифер с электронным управлением.</p>
                <p>Свежий воздух забирается с улицы и через термоизолированный воздуховод подается в смесительную камеру, где он смешивается с воздухом из помещения. Затем воздушная смесь фильтруется и обрабатывается во внутреннем блоке в зависимости от заданного режима (охлаждается, осушается или нагревается). Далее она подается по помещениям.</p>

                <h2>Система микропроцессорного управления</h2>
                <p>Электронная система управления автоматически поддерживает нужные параметры системы кондиционирования и вентиляции в любое время года. Летом воздух охлаждается и в помещении поддерживается заданная температура. Осенью и весной кондиционер переключается в режим "теплового насоса" и эффективно подогревает воздух без включения калориферов (электрического или водяного). Если температура на улице опускается ниже 0С, включается дополнительный калорифер (кондиционер канального типа). Электронный модуль управления калорифера позволяет плавно регулировать его мощность в зависимости от температуры внешнего воздуха, что обеспечивает минимальное потребление электроэнергии.</p>

                <h2>Основные преимущества работы сплит-системы с приточной вентиляцией</h2>
                <p><strong>1. Свежий воздух - круглый год</strong></p>
                <p>Одно из основных преимуществ системы кондиционирования и вентиляции воздуха - стабильная подача свежего воздуха в любое время года. Благодаря системе встроенных калориферов с электронным управлением зимой свежий приточный воздух подогревается до нужной Вам температуры. Уникальное инженерное решение позволяет эффективно работать оборудованию при низких отрицательных температурах.</p>
                <img src={o10} alt='' />
                <p><strong>2. Тщательная очистка воздуха</strong></p>
                <p>Если Вы живете или работаете в центре города или рядом с оживленной дорогой, то возникает проблема очистки поступающего с улицы воздуха, Кондиционеры укомплектованы многоразовыми фильтрами класса (EU-3). Эти фильтры позволяют проводить очистку поступающего воздуха. Кроме того, фильтры легко очищаются и служат длительное время. Круглый год в Ваше помещение поступает чистый, свежий воздух.</p>
                <p><strong>3. Одновременная работа</strong></p>
                <p>Оборудование системы кондиционирования и вентиляции воздуха позволяет кондиционировать и подавать свежий воздух одновременно в несколько комнат, Наши инженеры тщательно изучат Ваши помещения и подберут систему, которая сможет не только создать комфортный климат, но и обеспечить подачу необходимого количества свежего воздуха для каждого человека.</p>
                <img src={FDEQ} alt='' />
                <p><strong>4. Простота управления</strong></p>
                <p>Мощный микропроцессор управляет работой калорифера, внешнего и внутреннего блоков кондиционера. Он анализирует температуру на улице, в помещении и заданную Вами на пульте управления. После этого система сама выбирает оптимальный режим работы всех блоков. Свежий воздух автоматически подогревается или охлаждается. Вы всего лишь задаете на пульте управления скорость вращения вентилятора и желаемую температуру в помещении. Остальное за Вас делает автоматика.</p>
                <p><strong>5. Низкий уровень шума</strong></p>
                <p>Кондиционер устанавливается на стене (настенный кондиционер), либо за подвесным потолком или в коробе (канальный кондиционер) в жилых и офисных помещениях. Бесшумная работа канального блока достигается применением специально подобранных материалов, которые поглощают звук и вибрацию, Кроме того, при монтаже системы используются специальные звукоизолированные воздуховоды.</p>
                <p><strong>Подмес осуществляется с помощью внешнего блока, соединяемого с внутренним блоком гибким шлангом. Подаваемый воздух фильтруется.</strong></p>
                <img src={podmes} alt='' />
                <p>Кондиционеры с приточной вентиляцией Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Проектирование и поставка кондиционеров с приточной вентиляцией по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ConditionersWithVentilation