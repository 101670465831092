import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './commercialAirConditioners.css'

import ftxgj from '../../../img/FTXGJ_2.jpg'
import CMSQA1 from '../../../img/CMSQA1.jpg'

const CommercialAirConditioners = () => {
    return (
        <>
            <div className='commercial-air-conditioners-page'>
                <h1>Коммерческие кондиционеры (системы кондиционирования)</h1>
                <p>Коммерческие кондиционеры Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка коммерческих кондиционеров по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <h2>Какие кондиционеры представлены на рынке?</h2>
                <p>В соответствии с наиболее распространенной классификацией кондиционеров, их принято делить на три группы:</p>
                <ul>
                    <li>Бытовые кондиционеры;</li>
                    <li>Полупромышленные кондиционеры;</li>
                    <li>Системы промышленного кондиционирования и вентиляции воздуха.</li>
                </ul>
                <p>К <Link to='/household-semi-industrial-air-conditioners'>бытовым</Link> относятся кондиционеры, применяемые для охлаждения помещений площадью до 100 кв. м — квартир или комнат в коттеджах, небольших магазинов или офисов. Это настенные сплит-системы и мульти-сплит системы мощностью до 7 — 8 кВт.</p>
                <p>К полупромышленным кондиционерам относятся все сплит-системы и мульти-сплит системы мощностью свыше 8 кВт. Это канальные, кассетные, напольно-потолочные, колонные и некоторые другие типы кондиционеров. При средней мощности 10 — 15 кВт полупромышленные кондиционеры используются в помещениях площадью до 300 — 400 кв. м. Именно такие кондиционеры установлены в большинстве офисов: бытовые кондиционеры часто подходят по площади, но не справляются с дополнительной нагрузкой: люди, компьютерная техника, связь с улицей и т.п.</p>
                <p>К системам промышленной вентиляции и кондиционирования воздуха или просто промышленным кондиционерам относятся системы мощностью свыше 20 — 25 кВт. Это мультизональные VRV и VRF системы, системы центрального кондиционирования, системы чиллер-фанкойл, прецизионные и другие типы кондиционеров. Они используются для обслуживания жилых и административных зданий, больших офисов, торговых залов и спортивных комплексов, производственных и специализированных помещений площадью свыше 300 кв. м. </p>

                <h2>Лучшие в категории</h2>
                <p>Лучшие кондиционеры по соотношению цена - качество в категории кондиционеров для бизнеса мы выделяем несколько марок: <Link to=''>Fujitsu</Link>, <Link to=''>Daikin</Link>, <Link to=''>Mitsubishi Electric</Link>.</p>
                <p>Эти кондиционеры отлично зарекомендовали себя как в обычных так и тяжелых условиях эксплуатации.</p>
                <p>Daikin - один из мировых лидеров в производстве кондиционеров – и этим все сказано.</p>

                <h2>Кондиционирование офисов, ресторанов, магазинов и др.</h2>
                <p>По современным требованиям, для поддержания идеальных комфортных условий в помещениях постоянного нахождения людей воздух, очищенный от минеральной и органической пыли, должен содержать около 21% кислорода и не более 0.3% углекислого газа. Его температура должна быть в пределах 20-24°С, относительная влажность - 50-60%, а подвижность воздушной массы составлять 0.1-0.15 м/с. Добиться таких условий можно только с использованием систем кондиционирования воздуха.</p>
                <img src={ftxgj} alt='' />
                <p>Для начала расскажем о тех кондиционерах, которые могут применяться в малых и средних офисах и магазинах.</p>
                <p>Для больших целесообразно использовать полупромышленное и промышленное оборудование.</p>

                <h2>Сплит-системы</h2>
                <p>Обычно в местах постоянного нахождения людей устанавливают сплит-системы. Кроме стандартных <Link to=''>настенных кондиционеров</Link>, особенно хорошо подойдут для офиса, магазина или ресторана: сплит-системы кассетного, канального  и напольно-подпотолочного типа. Эти системы не создают мертвых зон и имеют вентилятор большей мощности; их внутренние блоки практически полностью находятся за подвесным потолком, а потому не нарушают сложившегося интерьера.</p>
                <p><Link to=''>Канальный кондиционер</Link> устанавливается за подвесным или подшивным потолком, который полностью скрывает внутренний блок. Распределение охлажденного воздуха осуществляется по системе теплоизолированных воздуховодов, которые также размещаются в межпотолочном пространстве. Канальный кондиционер может охлаждать сразу несколько помещений; кроме того, у канального кондиционера есть возможность подачи свежего воздуха с улицы в объёмах, необходимых для полноценной вентиляции. С помощью системы воздуховодов воздух можно выпускать одновременно в двух, трех, пяти, десяти и более местах, делая работу кондиционера практически незаметной.</p>
                <p><Link to=''>Кассетный кондиционер</Link> направляет охлажденный воздух сразу по четырём направлениям, причем по каждому из них сила потока регулируется отдельно. Это позволяет равномернее распределять охлаждённый воздух по помещению и избегать попадания прямого потока на людей - поток воздуха направляется горизонтально вдоль потолка. Такая конструкция позволяет использовать кассетный кондиционер для охлаждения большого помещения, и делает его незаменимым в помещениях сложной формы.</p>
                <p>В ресторанах, магазинах и реже в офисах, где стены не приспособлены для крепления настенных внутренних блоков, а подвесных потолков нет, альтернативой могут стать <Link to=''>напольно-потолочные кондиционеры</Link>. Устанавливаются они, как следует из названия, либо внизу стены, либо непосредственно на потолке. В первом случае, поток воздуха направляется вертикально вверх, а во втором — горизонтально вдоль потолка. Такая конструкция позволяет равномернее распределять охлажденный воздух по помещению и избегать попадания прямого потока на людей.</p>

                <h2>Мульти сплит - системы</h2>
                <p>Если офис, магазин или ресторан состоит из большого количества помещений, хорошим решением могут стать мульти сплит-системы. В этих системах с одним наружным блоком работает, в зависимости от производителя и модели, от 2 до 9 внутренних. Даже если необходимо создать комфорт в полутора десятках помещений, можно обойтись всего двумя - тремя внешними блоками, которые в большинстве случаев можно вынести во двор - в этом случае не пострадает фасад дома. Преимуществом сплит и мульти-сплит систем является большой выбор различных типов внутренних блоков. Среди них выделяют следующие модификации: настенный, канальный, кассетный и потолочный кондиционер. При этом бытовые сплит-системы практически все настенного типа, остальные кондиционеры — полупромышленные.</p>
                <img src={CMSQA1} alt='' />
                <p>Минусами таких систем является: замена нескольких сплит-систем на одну мульти-сплит систему не приводит к выигрышу в цене, поскольку стоимость оборудования примерно такая же, а трудоемкость и стоимость монтажа — в 1.5 - 2 раза выше из-за более длинных коммуникаций.</p>
                <p>Кроме этого, при выходе из строя внешнего блока мульти сплит-системы перестают работать все внутренние блоки — с этой точки зрения надежность нескольких сплит-систем выше.</p>
                <p>Поэтому мульти-сплит системы обычно используют только при невозможности размещения нескольких внешних блоков на наружной стене дома.</p>
            </div>
            <Form />
        </>
    )
}

export default CommercialAirConditioners