import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './designAirConditioningSystems.css'

import oo64 from '../../../img/oo64659ventcalc.jpg'
import t46833proektirovanie from '../../../img/t46833proektirovanie-split-sistem.jpg'
import bb52244professionalnyj from '../../../img/bb52244professionalnyj-proekt-po-kondicionirovaniyu.jpg'
import yy76280proektirovanie from '../../../img/yy76280proektirovanie-rabochego-proekta.jpg'

const DesignAirConditioningSystems = () => {
    return (
        <>
            <div className='design-air-conditioning-systems-page'>
                <h1>Проектирование систем кондиционирования</h1>
                <p>Проектирование системы кондиционирования Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Общие сведения о кондиционируемых помещениях</h2>
                <p>Последнее время наиболее широкое применение получили системы кондиционирования на базе кондиционеров сплит-систем.</p>
                <p>Среди основных преимуществ этих систем, может быть отмечена простота проектирования, фактически сводящаяся к экспресс-методике расчета тепловой нагрузки на помещение и к подбору наиболее близкого типоразмера из стандартного (по холодопроизводительности) ряда кондиционеров.</p>
                <p>Такой упрощенный подход к проектированию, с одной стороны, позволяет заниматься кондиционерным бизнесом (разработкой, поставкой, монтажом) большому количеству организаций, как правило, не располагающих квалифицированными проектировщиками, но, с другой стороны, существенно сужает возможности этих организаций по использованию для кондиционирования иных, более сложных систем с существенно более высоким уровнем возможностей и соответственно требующих иного, более профессионального подхода к этапу разработки и технико-экономического обоснования.</p>
                <p>В данном разделе представлены наиболее известные методики разработки систем кондиционирования и вентиляции, а также даны различные (по глубине проработки) этапы проектных работ.</p>
                <p>Установка систем кондиционирования на базе кондиционеров сплит-систем с приточной вентиляцией, чиллеров-фанкойлов, центральных кондиционеров и т.п. оборудования связана с серьезной предварительной проработкой и проектированием.</p>
                <p>Так, например, для разработки центральных СКВ необходимо иметь следующие материалы (сведения):</p>
                <ul>
                    <li>общие данные, характеризующие проектируемый объект: район (город) расположения объекта, назначение корпуса, ориентировку корпуса по странам света;</li>
                    <li>строительные чертежи здания и помещения: планы и разрезы с указанием всех размеров и отметок высот относительно уровня земли, характеристикой принятых строительных конструкций — стен, перекрытий, покрытия, заполнения оконных и дверных проемов и т.п.;</li>
                    <li>указание категорий помещений (на архитектурных планах) в соответствии с противопожарными нормами;</li>
                    <li>чертежи технологического проекта (планы) с указанием размещения технологического оборудования, его спецификацией с указанием установленных мощностей. Кроме того, необходимо знать характеристику технологического режима — число рабочих смен, количество рабочих в смене либо посетителей в магазине, зрителей в зале и т . д., режим работы оборудования (одновременность работы, коэффициенты загрузки и др.), характеристику, а в отдельных случаях и количество вредных выделений, поверхность смоченного пола ,открытые поверхности парящего оборудования и т. д;</li>
                    <li>освещенность помещения (количество ламп, их мощность);</li>
                    <li>характеристики энергоносителей: теплоснабжение (электричество либо вода с температурой 105-70°С или 95-70°С, пар) , хладоснабжение (хладон 22, вода, этиленгликоль%, пропиленгликоль%);</li>
                    <li>наличие технических площадей с указанием их размеров;</li>
                    <li>расположение и характеристики существующих систем кондиционирования и вентиляции (при реконструкции здания).</li>
                </ul>
                <p>При составлении задания на проектирование необходимо учитывать неравномерность распределения температур в помещении, чтобы при расчете воздухообмена предусмотреть интенсивность перемешивания воздуха. Увеличение воздухообмена выравнивает температуры и влажность в помещении.</p>

                <h2>Расчетные внутренние параметры в кондиционируемых помещениях</h2>
                <p>Выбор параметров воздуха, которые должны поддерживаться системами кондиционирования, зависит от многих факторов, как, например, климатических условий местности, характера производственной деятельности людей, продолжительности их пребывания в помещениях (акклиматизации) и др.</p>
                <p>Кроме фактора акклиматизации, на выбор внутренних параметров воздуха в помещениях существенное влияние оказывает выполнение людьми физической работы. В помещениях, где люди выполняют физическую работу, требуется более низкая температура воздуха, чем в помещениях, в которых люди находятся в спокойном состоянии.</p>
                <p>Для холодного и теплого периода года внутреннюю температуру в помещениях</p>
                <p>следует принимать по строительным нормам и правилам, с учетом особенностей каждого помещения. Относительная влажность принимается в пределах 40-60 %.</p>
                <p>Метеорологические условия в некоторых общественных помещениях должны удовлетворять не только требованиям комфорта, но и специальным условиям. Так, например, в библиотеках и книгохранилищах, учитывая условия хранения книг, принимается температура воздуха зимой 18°С и летом 28°С, а относительная влажность 40-60%. Для читальных залов рекомендуется температура зимой 18°С и летом 24°С, а влажность воздуха — 40%. Для зрительных залов театров можно принимать: зимой температуру 20°С, а летом 24°С и влажность воздуха 50-55%.</p>
                <p>При выборе параметров воздуха в помещении необходимо иметь в виду, что стоимость устройства и эксплуатации систем кондиционирования воздуха неоправданно увеличится, если выбранные значения температуры и влажности будут завышены для холодного периода или занижены для теплого периода года.</p>
                <p>Расчетные параметры наружного воздуха.</p>
                <p>Выбор системы кондиционирования воздуха и ее функционирование во многом зависит от внешней среды. Такие факторы, как температура, влажность (либо энтальпия) воздуха, интенсивность солнечной радиации, скорость и направление ветра, количество выпадающих осадков (дождя, снега, тумана и т.п.), наряду с факторами внутренней среды зданий и сооружений, влияют на тепловлажностный баланс помещений. От них существенно зависят поступления или потери тепла и влаги через ограждающие конструкции. Кроме того, от них, в первую очередь от температуры и влажности, зависит сам процесс кондиционирования, выбор способов обработки приточного воздуха, установочная мощность СКВ и ее энергопотребление, выбор систем управления и автоматического регулирования СКВ.</p>
                <p>Расчетные внешние метеорологические условия для зимнего и летнего времени года выбираем согласно СНиП2. 04.05-91*.</p>
                <p>На холодный период года для СКВ всех классов в качестве расчетных следует принимать параметры воздуха "Б".</p>
                <p>На теплый период года:</p>
                <ul>
                    <li>для СКВ 1-го класса — параметры "Б";</li>
                    <li>для СКВ 2-го класса — параметры "Б", сниженные на 2°С;</li>
                    <li>для СКВ 3-го класса — параметры "А".</li>
                </ul>
                <p>Параметры наружного воздуха для жилых, общественных, административно-бытовых и производственных помещений для систем вентиляции следует принимать:</p>
                <ul>
                    <li>для теплого периода года — параметры "А";</li>
                    <li>для холодного периода — параметры "Б".</li>
                </ul>
                <p>На основе исходных данных проводятся расчеты тепло- и влаговыделений в помещении. На основании тепловлажностного баланса производим расчет воздухообмена, т.е. определяем количество воздуха, необходимое для ассимиляции избытков тепла и влаги в помещении и создания оптимальных или допустимых параметров воздуха.</p>

                <h2>Проект</h2>
                <p>Проектирование кондиционирования помещения начинается с тщательного изучения документов, предоставленных заказчиком.</p>
                <p>Для начала проектных работ необходимо следующее:</p>
                <ul>
                    <li>архитектурно-строительный план здания;</li>
                    <li>технологический проект (если речь идет о местах торговли, пищевого или промышленного производства);</li>
                    <li>список требований и пожеланий заказчика к системе кондиционирования здания.</li>
                </ul>
                <p>На основании этих данных производятся инженерные расчеты соотношения внутренних и внешних температур, качества обработки воздуха, нагрузки на оборудование; выбирается схема организации воздухообмена.</p>
                <p>Далее с учетом мнения заказчика происходит подбор необходимого оборудования: кондиционеров, сплит-систем, чиллеров, фанкойлов, частей системы трубопроводов и других необходимых рабочих элементов; подготавливается и выдается полностью готовый к использованию проект и вся сопутствующая документация.</p>
                <p>Проектирование системы кондиционирования - это сложные расчеты, от правильности которых зависят не только материальные затраты на монтаж самой системы, но и ее дальнейшая эффективность и надежность в эксплуатации.</p>

                <h2>Программы для разработки проекта</h2>
                <p>Существует множество компьютерных программ, которые позволяют наиболее точно разработать проект по кондиционированию и вентиляции воздуха в помещении. На основе заложенных данных, программа сделает максимально верные расчеты и позволит осуществить подбор требуемого оборудования и сам процесс монтажа в кратчайшие сроки.</p>
                <p>Заказчику производства работ важно иметь начальные представления о различных типах систем компьютерных расчетов, чтобы выбрать наиболее подходящую программу для решения конкретной задачи по проектированию системы кондиционирования воздуха в зависимости от типа помещения и требований к эффективности работы кондиционеров и сплит-систем.</p>
                <img src={oo64} alt='' />
                <p>К числу наиболее популярных программ, используемых в проектировании систем кондиционирования и вентиляции можно отнести: «VentCalc», «Поток», «VSV», «RTI», «KALOR», «BOLER», «STOL», «VIBROS».</p>
                <p>Однако какими бы простыми не были эти программы в использовании, сделать самостоятельный расчет будущего проекта кондиционирования непрофессионалу будет не под силу – только проектные организации с большим опытом работы в сфере климатического оборудования смогут осуществить весь спектр проектных работ с подбором соответствующей документации и максимальными разъяснениями по проекту.</p>

                <h2>Особенности проектирования</h2>
                <p>Если говорить об установке сплит-систем, которые пользуются стабильным спросом на рынке климатотехники, то проектирование такой системы кондиционирования сводится к грамотному подбору размеров и типа оборудования для конкретного помещения. Специалисты по проектированию предоставят заказчику максимально точные расчеты, из которых будет ясно, какая нагрузка по теплу ляжет на то или иное помещение и дадут рекомендации по выбору оборудования.</p>
                <img src={t46833proektirovanie} alt='' />
                <p>Сплит-системы работающие на базе приточной вентиляции и системы кондиционирования на основе чиллеров-фанкойлов требуют более детальной проработки проекта и обработки следующих данных:</p>
                <ol>
                    <li>Общая информация о проектируемом объекте, включая экологические и техногенные характеристики его местонахождения;</li>
                    <li>Отдельные чертежи не только всего помещения, но и каждой отдельной комнаты, включая дополнительные планы и разрезы с характеристиками строительных конструкций;</li>
                    <li>Определение типа помещения согласно архитектурным планам и нормам безопасности, согласно требованиям пожарных инстанций;</li>
                    <li>Схематичный технологический проект, в котором будет информация о расположении оборудования и рекомендации по мощности.</li>
                    <li>Учет освещенности помещения;</li>
                    <li>Если система не устанавливается, а реконструируется, то должны быть представлены данные обо всех ранее установленных кондиционерах и сплит-системах.</li>
                    <li>Общая энергетическая характеристика помещения.</li>
                </ol>
                <p>При правильном составлении проекта по кондиционированию помещения обязательно рассматривается возможная разница температур в каждом отдельно взятом помещении.Тогда расчеты будут максимально точны и смогут помочь добиться уравновешивания показателей влажности, тепла и притока воздуха в оптимальном соотношении.</p>
                <p>Обязательно учитываются все нюансы помещения, а также виды деятельности, под которые отводится та или иная комната. Например, помещение, в котором предполагается сделать спортивный зал должно охлаждаться сильнее, чем рабочий кабинет или спальня.</p>
                <img src={bb52244professionalnyj} alt='' />
                <p>Помимо всего прочего, профессиональный проект по кондиционированию помещения обязательно учитывает особенности внешней среды, так как наружные показатели влажности и температуры оказывают значительное влияние на общую работу системы кондиционирования и нужно будет подобрать подходящее оборудование исходя из критериев мощности и требуемой энергоэффективности.</p>

                <h2>Этапы проектирования</h2>
                <p>Проектирование систем кондиционирования воздуха предполагает два основных этапа:</p>
                <ol>
                    <li>Проект техническо-экономического обоснования. На этом этапе осуществляется определение расположения кондиционеров, подбор их типа, расчеты показателей тепла и воздуха и прочих важных параметров. На основе всей совокупности данных разрабатывается первичная схема и согласовывается с заказчиком.</li>
                    <li>После одобрения заказчиком первичной схемы начинается проектирование рабочего проекта, процесс которой состоит в обработке данных планировки помещения, тепловых характеристик помещения и собственно технологического задания. Производится непосредственный расчет воздухообмена для каждой комнаты помещения, выводятся показатели нужного напора в сети и тепловыделений. Чертятся все необходимые планы по будущим местам монтажа оборудования и разводке сетей. Проводится окончательный подбор климатотехники и составление спецификаций на него и все требуемые в процессе работ материалы.</li>
                </ol>
                <img src={yy76280proektirovanie} alt='' />
                <p>После того, как одобрение заказчика получит и рабочий проект – его нужно согласовать в СЭС и в пожарной инспекции. Проектная стадия работ по кондиционированию может считаться законченной.</p>
            </div>
            <Form />
        </>
    )
}

export default DesignAirConditioningSystems