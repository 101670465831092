import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/33302kapitalnyj-remont-sistemy-otopleniya-mnogokvartirnogo-doma_1.jpg'
import img2 from '../../../img/118217design_center_1170x780.jpg'
import img3 from '../../../img/12711tak-nasos-vyglyadit-razobrannym1-300x227.jpg'

const HeatingRepair = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Ремонт системы отопления</h1>
                <p>Ремонт системы отопления Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Типы ремонтных работ</h2>
                <p>Можно выделить три основных категории ремонтов систем отопления.</p>
                <ul>
                    <li>Аварийный. Цель — восстановление функциональности отопительной системы после, как несложно догадаться, аварии: порыва стояка, отрыва подводки радиатора, разморозки подъездного отопления.</li>
                    <li>Текущий. Здесь речь идет о выявлении и устранении мелких неисправностей, плановой проверке работоспособности запорной арматуры, ее ревизии и замене. Часть мелких неисправностей выявляется жильцами квартир, часть обнаруживается при плановых обходах, часть — в ходе подготовки системы отопления к отопительному сезону.</li>
                </ul>
                <p>Обратите внимание: основная часть текущих работ по подготовке отопительного оборудования проводится летом, после окончания промывки и остановки циркуляции.</p>
                <ul>
                    <li>Капитальный ремонт подразумевает полную или частичную замену отопительного оборудования. Сюда может входить как демонтаж всех труб и их замена на металлопластиковые, так и просто замена, к примеру, радиаторных пластин, срок эксплуатации которых истек.</li>
                </ul>
                <img src={img1} alt='' className='img-width' />
                <p>Здесь полумерами явно не обойтись.</p>
                <p>В этом порядке мы их и рассмотрим.</p>

                <h2>Проблемы и решения</h2>
                <p><strong>Аварийный ремонт</strong></p>
                <p>Давайте рассмотрим наиболее частые проблемы, с которыми сталкиваются аварийные бригады слесарей, и их типичные решения.</p>
                <ul>
                    <li>Нет отопления по стояку. Работа начинается с проверки состояния вентилей и сбросов: часто причиной являются несогласованные ремонтные работы, проводимые жильцами и наемными рабочими. Если они в рабочем положении — стояки перегоняются на сброс в обоих направлениях для локализации проблемы.</li>
                </ul>
                <p>Причиной часто оказывается кусочек шлака в изгибе трубы или запавший клапан винтового вентиля. Наконец, если вода свободно проходит через стояк — предстоит просто подняться на верхний этаж и стравить там воздух.</p>
                <ul>
                    <li>Свищ в трубе отопления. Если угрозы полного разрушения стояка или подводки нет — аварийная бригада просто накладывает бандаж, устраняя течь. Сварочные работы выполняются бригадой текущего ремонта.</li>
                    <li>Течь контргайки перед радиатором. При сброшенном стояке резьба перематывается. Если она разрушена коррозией — выполняется замена сгона на подводке с помощью сварки или ручной нарезки резьбы.</li>
                    <li>Сильная течь между секциями батарей отопления. Ее причиной может быть только лопнувший ниппель. После сброса стояков радиатор демонтируется и перебирается.</li>
                    <li>Не закрывается после промывки радиатора промывочный кран. Стояк сбрасывается, после чего на кране меняется прокладка.</li>
                    <li>Разморожено подъездное отопление, в подъезд поступает горячая вода. После отключения стояка поврежденные секции демонтируются, оставшаяся часть радиатора запускается. При необходимости аварийная служба выполняет сварочные работы по восстановлению подводок, регистров и т.д.</li>
                </ul>
                <p><strong>Текущий ремонт</strong></p>
                <p>Теперь рассмотрим ремонт отопительных систем, который выполняется работниками жилищно-коммунальных служб в рамках подготовки к отопительному сезону.</p>
                <p>Будем реалистами: в нынешнем состоянии ЖКХ, при остром дефиците кадров и многократно увеличившихся после распада Союза нормах обслуживания, часть этих работ не выполняется регулярно. К сожалению, сейчас мы говорим о недостижимом идеале.</p>
                <ul>
                    <li>Ревизия запорной арматуры в элеваторном узле отопления. В ее рамках проводится проверка работы всех сбросных и контрольных вентилей, задвижек и, при необходимости, их ремонт. Кроме того, выполняется периодическое обслуживание: набиваются сальники и смазываются штоки.</li>
                </ul>
                <p>Ремонт вентилей в большинстве случаев включает простую замену прокладки, которую при минимуме навыков даже новичок легко может выполнить своими руками; а вот ревизия и ремонт задвижек куда сложнее.</p>
                <p>При необходимости выполняется замена распорного клина между щечками или его наваривание, притирка зеркал в корпусе и на щечках, восстановление штока, замена прижимного кольца на сальнике и многие другие работы. Цена новой задвижки достаточно велика, чтобы затраты времени окупались.</p>
                <p>Ревизия чугунной задвижки на стенде. Честно говоря, по ее внешнему виду трудно предположить, что она нуждается в ремонте.</p>
                <ul>
                    <li>Ревизия и опять-таки ремонт запорной арматуры на стояках — не менее важная задача. При их неисправности для устранения пустяковой течи приходится сбрасывать весь дом, что в сильные морозы чревато разморозкой участков контура (прежде всего подъездов).</li>
                    <li>Перемотка контргаек на сгонах стояков тоже требуется с определенной периодичностью. Сантехнический лен со временем выгорает;  к сожалению, последние дома, в которых резьбы герметизировались не боящимся выгорания льном с олифой и свинцовым суриком, были сданы в конце шестидесятых.</li>
                    <li>Замена стояков отопления, устранение разнообразных мелких течей труб и сварных швов между ними — еще одна вечная проблема. Метод устранения выбирается по обстоятельствам: в квартире обычно небольшой свищ заваривается, а сильно поврежденный коррозией участок трубы меняется целиком. В подвале незначительные свищи чаще всего бандажируются — хомутом с прокладкой или даже просто кусочком плотной резины и отрезком отожженной проволоки.</li>
                    <li>Наконец, в обязанности бригад текущего ремонта входит обслуживание отопительной системы: запуск и остановка отопления, удаление воздушных пробок (разумеется, в тех случаях, когда жильцы верхних этажей не могут сделать это самостоятельно) и ежегодная гидропневматическая промывка отопления.</li>
                </ul>
                <p><strong>Капремонт</strong></p>
                <p>Существует определенный порядок заключения договоров на капитальный ремонт отопления:</p>
                <p>Перед вами образец такого документа. Судя по его содержанию, речь идет о частичной замене запорной арматуры в элеваторном узле.</p>
                <ul>
                    <li>Объявляется тендер на поставку оборудования и (или) собственно выполнение ремонта. В нем может участвовать любое муниципальное или частное предприятие, у которого в перечне предлагаемых услуг при регистрации был указан «ремонт отопительной системы» (код ОКДП 453).</li>
                    <li>С выигравшей тендер организаций заключается договор, включающий полный перечень предоставляемых услуг, порядок расчета и контроля, гарантии и ответственность сторон и еще десяток очень, без сомнения, важных пунктов.</li>
                    <li>Затем несколько недель или месяцев работы могут, в зависимости от результата, закончиться обоюдной удовлетворенностью сторон или скандалами и судебным разбирательством.</li>
                </ul>
                <p>Однако на практике договор зачастую заключается между обслуживающей организацией и ее  собственными бригадами аварийного или текущего ремонта, которые выполняет ремонтные работы во время отпусков и выходных. Практику можно только одобрить: у исполнителя есть очень веский стимул делать свою работу хорошо, поскольку решать проблемы некачественного исполнения придется им же.</p>
                <p>Какие работы могут выполняться в рамках капремонта? Их список невелик:</p>
                <ul>
                    <li>Полная или частичная замена стояков и подводок отопления.</li>
                    <li>Полная или выборочная замена отопительных приборов.</li>
                    <li>Замена элеваторного узла целиком или запорной арматуры в нем.</li>
                    <li>Полная или частичная замена розливов отопления.</li>
                </ul>

                <h2>Ремонт котлов отопления и пластинчатых теплообменников</h2>
                <img src={img2} alt='' className='img-width' />
                <p><strong>Довольно часто проблемы с отопительной системой вызваны неисправностью котельного оборудования. Причинами нестабильной работы этого элемента чаще всего являются:</strong></p>
                <ul>
                    <li>Непрофессиональная регулировка горелки;</li>
                    <li>Высокая концентрация пыли в помещении, отсутствие или неисправность приточной вентиляции;</li>
                    <li>Нарушения в работе циркуляционного насоса;</li>
                    <li>Перебои в электроснабжении;</li>
                    <li>Загрязненные теплообменники;</li>
                    <li>Загрязненные магистрали и отопительные сети.</li>
                </ul>
                <p><strong>В зависимости от причины неисправности, могут потребоваться следующие методы их устранения:</strong></p>
                <ul>
                    <li>Установка стабилизатора напряжения или использовать дополнительный источник электропитания (к примеру, дизельный генератор);</li>
                    <li>Наладка работы горелки и циркуляционного насоса согласно предписаниям инструкции;</li>
                    <li>Организация вентиляции помещения или очистка загрязненных вентиляционных отверстий;</li>
                    <li>Регулярная промывка теплообменного оборудования.</li>
                </ul>

                <h2>Ремонт циркуляционных насосов</h2>
                <p>Для увеличения скорости движения теплоносителя в теплоснабжении устанавливают циркуляционный насос. Его поломка не только ухудшит теплообмен, но и негативным образом повлияет на работоспособность всей системы.</p>
                <img src={img3} alt='' />
                <p>Несмотря на кажущуюся сложность конструкции можно сделать ремонт насоса отопления своими руками. Для этого сначала определяют причину неполадки и разрабатывают оптимальную схему для ее устранения. Перед тем как делать ремонт циркуляционного насоса отопления следует отключить систему и дождаться пока теплоноситель не остынет. Этого можно избежать, если установлен байпас на насосном узле. Достаточно перенаправить движение горячей воды по запасному контуру.</p>
                <p>Рассмотрим наиболее распространенные из них, а также способы решения проблемы:</p>
                <ul>
                    <li>Не работает силовая установка. Сначала необходимо проверить состояние клемм – предохранитель на них часто перегорает из-за скачков напряжения в сети. Решение – замена предохранителя;</li>
                    <li>Повышенный шум при работе. Для ремонта насосов для отопления в этом случае необходимо удалить воздух из трубы. Лучше всего заранее установить автоматический воздухоотводчик;</li>
                    <li>Сильная вибрация при работе. Скорее всего вышел из строя подшипник на валу. Для ремонта циркуляционного насоса отопления понадобится его замена;</li>
                    <li>Неправильное вращение лопастей. Следует проверить фазу подключения, а также правильность монтажа насоса;</li>
                    <li>Небольшой напор. Чаще всего это связано с засорением фильтра. В этом случае потребуется не ремонт насоса теплоснабжения, а чистка фильтра.</li>
                </ul>
                <p>После длительной эксплуатации некоторые компоненты насоса теряют свои герметические свойства – изменяется геометрия крыльчатки, вала. Для ремонта циркуляционного отопительного насоса сначала проверяется состояние этих элементов. Практически все модели рассчитаны на замену компонентов. Но перед приобретением необходимо правильно подобрать комплектующие, выписав марку насоса и его модель.</p>

                <h2>Ремонт расширительных баков отопления</h2>
                <p>Расширительный бак входит в группу безопасности отопления, обеспечивая нормальную работу системы. В открытых схемах он может выполнять несколько функций – контроль уровня теплоносителя и узел добавления воды. Он практически не выходит из строя. Но в закрытых схемах теплоснабжения поломка расширительного бака – явление частое.</p>
                <p>Чаще всего ремонт расширительного бака отопления обусловлен разрушением эластичной мембраны. Но следует учитывать, что не все модели имеют разборную конструкцию. Баки небольших объемов изготавливаются сварным методов – две части стального корпуса соединены неразборным способом. В таком случае единственный вариант ремонта расширительного бака отопления – приобретение нового.</p>
                <p>Реже встречается повреждения корпуса из-за механического воздействия или ржавления. В последнем случае конструкцию также невозможно восстановить. Если разгерметизация корпуса носит небольшой характер – можно сделать ремонт расширительного бака теплоснабжения методом дуговой или аргоновой сварки.</p>
                <p>Ремонт системы отопления Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HeatingRepair