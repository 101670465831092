import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './commissioningAirConditioningSystems.css'

const CommissioningAirConditioningSystems = () => {
    return (
        <>
            <div className='commissioning-air-conditioning-systems-page'>
                <h1>Пуско-наладка систем кондиционирования и вентиляции</h1>
                <p>Пуско-наладку системы кондиционирования и вентиляции Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование, поставку, пуско-наладку систем кондиционирования. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Пуско-наладочные работы – это последний и очень важный этап монтажа системы кондиционирования. Эта процедура представляет собой ряд мероприятий, направленных на проверку работоспособности и соответствия системы установленным стандартам и расчетам. Выполнить такого рода работы может компания с большим опытом. «FIDCLIMAT» для проведения проверки и запуска в работу системы кондиционирования использует измерительную технику, сертифицированную в установленном порядке.Важность проведения пуско-наладочных работ перед началом эксплуатации оборудования объясняется следующим:</p>
                <ul>
                    <li>Проводятся испытания по определению соответствия системы и оборудования параметрам;</li>
                    <li>Определяется эффективность работы системы;</li>
                    <li>Регулируется поток воздушных масс;</li>
                    <li>Проводится оформление документации;</li>
                    <li>Выдается паспорт.</li>
                </ul>

                <h2>Этапы проверки системы кондиционирования</h2>
                <p>Проверка системы кондиционирования специалистами "FIDCLIMAT" производиться в несколько этапов. Изначально изучается проект, обследуется объект, где установлена система. После изучается технологический процесс и проводится осмотр оборудования. Обязательно производиться проверка оборудования и его соответствие установленным нормам и стандартам. По окончанию этой процедуры составляется соответствующий документ, в котором указываются все недостатки и выявленные дефекты</p>
                <p>Специалисты компании «FIDCLIMAT» имеют высокую квалификацию и многолетний опыт в проведении обслуживания, ремонта и монтаже систем кондиционирования и вентиляции.</p>

                <h2>Для чего необходимы пнр климатических систем?</h2>
                <p>Если установленное оборудование даст сбой – кондиционеры начнут работать плохо, из-за этого будут затронуты и другие системы коммуникаций объекта. Важность данных работ заключается в следующем:</p>
                <ul>
                    <li>Выявление соответствия проектных расходов готовому результату;</li>
                    <li>Выдача технического паспорта оборудования, как залог гарантии исправного функционирования;</li>
                    <li>Выдача актов опробования при проведении пнр кондиционирования подтверждает факт полной готовности системы к эксплуатации.</li>
                </ul>
                <p>Исполнитель и заказчик подписывают акт сдачи-приемки работы на конечном этапе, после чего можно полностью использовать аппаратуру по назначению.</p>
                <p>Если установленное оборудование соответствует паспортным данным, то в процессе наладки регулируется:</p>
                <ul>
                    <li>вентиляционная установка на расчетную производительность по воздуху и напору;</li>
                    <li>сеть воздуховодов и воздухораспределительные устройства;</li>
                    <li>максимальная производительность калориферов первого и второго подогревов и зональных подогревателей;</li>
                    <li>требуемая по проекту производительность воздухоохладителя или оросительной камеры;</li>
                    <li>характеристики регулирующих устройств по воздуху, воде и пару;</li>
                    <li>производительность источника холода.</li>
                </ul>
                <p>Пусконаладочные работы выполняются по договору с заказчиком. К договору прилагают календарный план производства пусконаладочных работ, в котором указаны: объекты, подлежащие наладке; сроки начала и окончания работ по объектам (помещениям, сооружениям) и в целом сметная стоимость, в том числе по этапам работ, где должны быть указаны обязанности сторон по исполнению договора.</p>

                <h2>ПНР. Подготовительные работы </h2>
                <p>Если ПНР осуществляет организация, не проводившая монтаж, то подготовительные работы будут включать следующие пункты:</p>
                <ul>
                    <li>ознакомление с чертежами, схемами и расчетами;</li>
                    <li>изучение технической документации предприятий-изготовителей оборудования;</li>
                    <li>проверку проектных решений и рабочих чертежей;</li>
                    <li>составление замечаний по проектным решениям и расчетам;</li>
                    <li>разработку мероприятий по устранению замечаний, контроль за их выполнением.</li>
                </ul>
                <p>Далее выполняется разработка программы пусконаладочных работ, которая включает:</p>
                <ul>
                    <li>проверку наличия исполнительной документации, полученной от монтажной организации, и ее соответствие нормативно-техническим требованиям;</li>
                    <li>проверку наличия исполнительной документации, полученной от монтажной организации, и ее соответствие нормативно-техническим требованиям;</li>
                    <li>проверку выполненных монтажных работ и их качества на соответствие проекту, требованиям предприятий-изготовителей, действующим нормативам;</li>
                    <li>составление перечня замечаний, разработку мероприятий по их устранению и контроль за устранением замечаний;</li>
                    <li>проверку комплектности оборудования, запасных частей, инструмента и приспособлений, правильности расстановки оборудования.</li>
                </ul>

                <h2>ПНР. Проведение испытаний</h2>
                <p>Испытания прецизионных кондиционеров должны соответствовать требованиям СП 75.13330, ГОСТ 12.2.233, ГОСТ 28564, ПОТ Р М 015-2000, РД, ППР и инструкциям предприятия-изготовителя.</p>
                <p>Испытание на прочность и плотность для внутреннего испарительно-компрессорного блока допускается не проводить в том случае, если давление и температура насыщенных паров хладагента в холодильном контуре соответствует температуре окружающего воздуха, и контроль внешним осмотром и проверкой течеискателем не выявил возможных утечек хладагента.</p>
                <p>Испытание наружного конденсаторного блока рекомендуется проводить в составе единой системы с присоединенными трубопроводами холодильного контура.</p>
                <p>Испытания прецизионного кондиционера включают:</p>
                <ul>
                    <li>испытания холодильного контура;</li>
                    <li>испытания системы удаления конденсата;</li>
                    <li>испытание системы управления и электроснабжения.</li>
                </ul>
                <p>Испытания холодильного контура проводят в следующей последовательности</p>
                <ul>
                    <li>испытания на прочность;</li>
                    <li>испытания на плотность (герметичность);</li>
                    <li>испытания системы в целом.</li>
                </ul>
                <p>Первоначальный пуск прецизионного кондиционера после монтажа, а также после ремонта, длительной остановки или после срабатывания приборов защиты и вывод его на рабочий режим должен осуществляться под наблюдением работников, обслуживающих это оборудование. Каждый прецизионный кондиционер должен иметь Эксплуатационный журнал, в котором фиксируются мероприятия по техническому обслуживанию и параметры его работы. В случае, если имеется несколько однотипных кондиционеров, допускается иметь один журнал. В Эксплуатационном журнале и паспорте должна быть отметка о марке хладагента и смазочного масла. Смазочные масла, в том числе и при дозаправке холодильных компрессоров, должны применяться в соответствии с требованиями предприятия-изготовителя компрессоров.</p>
                <p>После окончания монтажных работ и до передачи оборудования Заказчику должны быть выполнены испытания системы удаления конденсата. Выдержавшей испытание считается система, если при ее осмотре не обнаружено течи через стенки трубопроводов и места соединений. Испытания отводных трубопроводов, проложенных в подпольных каналах, должны выполняться до их закрытия наполнением водой до уровня пола. Испытания участков системы удаления конденсата, скрываемых при последующих работах, должны выполняться проливом воды до их закрытия с составлением акта освидетельствования скрытых работ. Испытание системы удаления конденсата следует производить наполнением их водой до уровня наивысшей водосточной воронки. Продолжительность испытания должна составлять не менее 10 мин.</p>
                <p>Все измерения, испытания и опробования в соответствии с действующими нормативно-техническими документами и инструкциями предприятий-изготовителей произведенные персоналом монтажных организаций непосредственно перед сдачей Заказчику оформляются Протоколами испытаний.</p>

                <h2>ПНР. Комплексное опробование</h2>
                <p>При комплексном опробовании прецизионного кондиционера необходимо обеспечить его работу с достижением и поддержанием устойчивого проектного режима в течение 24 ч (совместно с персоналом Заказчика) при номинальной тепловой нагрузке серверной комнаты и функционировании всех внутренних инженерных систем и нормальных условиях окружающей среды. По окончании комплексного опробования проводится инструктаж специалистов службы эксплуатации Заказчика по основным правилам безопасной эксплуатации оборудования. Далее передается вся техническая и исполнительная документация. Оборудование передается Заказчику в эксплуатацию по Акту сдачи-приемки оборудования.</p>

                <h2>Паспортизация</h2>
                <p>В соответствии с действующими нормами все системы после завершения всех работ должны быть паспортизованы. При паспортизации, предоставляется полный комплект документов, необходимых как для нормальной эксплуатации, обслуживания и ремонта оборудования, так и для успешной сдачи контролирующим органам в соответствии с существующими нормами и стандартами.</p>

                <h2>Задачи специалистов при модернизации существующих систем</h2>
                <p>Обследование существующих инженерных систем (при модернизации):</p>
                <ul>
                    <li>определение фактических параметров функционирования систем;</li>
                    <li>поиск и выявление дефектов инженерных систем;</li>
                    <li>составление отчета с фотофиксацией выявленных повреждений;</li>
                    <li>разработка рекомендаций по модернизации существующих инженерных систем</li>
                </ul>
                <p>В состав службы пуско-наладки входят высококвалифицированные инженеры различных специальностей - по системам кондиционирования, вентиляции, отопления, котельному оборудованию, автоматизации и диспетчеризации систем и др., обладающие большим опытом эксплуатации, наладки и ремонта самого сложного инженерного оборудования.</p>
                <p>Пуско-наладку системы кондиционирования и вентиляции Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование, поставку, пуско-наладку систем кондиционирования. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default CommissioningAirConditioningSystems