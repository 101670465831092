import React from 'react'
import '../ventilationPages.css'
import Form from '../../../components/form/Form'
import { Link } from 'react-router-dom'

import img26 from '../../../img/26-05-2014 22-15-51_small.jpg'
import img41 from '../../../img/26-05-2014 22-17-41.jpg'
import img16 from '../../../img/26-05-2014 22-22-16.jpg'
import img20 from '../../../img/20-05-2014 18-44-36.jpg'
import img50 from '../../../img/26-05-2014 21-32-50.jpg'

const SupplyExhaustVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Приточно-вытяжная вентиляция</h1>
                <p>Приточно-вытяжную систему вентиляции Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <img src={img26} alt='' />
                <p>Приточно-вытяжная вентиляция является комбинацией из двух видов: приточного и вытяжного типа. Система забирает из помещения, в котором она установлена, отработанный воздух и осуществляет приток нового, свежего кислорода.</p>
                <p>Обычно такой вид вентиляции обустраивают в помещениях, где скапливается отработанный воздух (кухня, ванная) и большое количество людей (рестораны, кафе) и др. </p>
                <p>Несмотря на то, что приточно-вытяжные системы вентиляции отличаются высокой стоимостью, а также сложной методикой циркуляции воздуха, преимущества, которые можно выделить из её работы, на порядок покрывают расходы. Среди плюсов можно отметить:</p>
                <ul>
                    <li>Высокий уровень комфорта, который создаётся, благодаря чистому воздуху в помещении.</li>
                    <li>Все компоненты системы выполнены из экологически чистых материалов. Кроме того, работа подобного рода вентиляции не предполагает выброса грязных частиц в атмосферу.</li>
                    <li>Вентиляция такого типа полностью безопасна.</li>
                </ul>

                <h2>Устройство приточно-вытяжной вентиляции</h2>
                <p>Элементы приточно-вытяжной установки могут быть</p>
                <ul>
                    <li>моноблочными</li>
                    <li>наборными</li>
                </ul>
                <p>Моноблочные приточно-вытяжные вентиляционные системы практически полностью готовы к монтажу, поэтому специальных навыков при их установке не требуется. Для их установки агрегат закрепляют на стене и подводят к нему воздуховоды и электропитание. Но такие вентиляционные системы стоят дороже наборных.</p>
                <img src={img41} alt='' />
                <p>В состав наборных приточно-вытяжных вентиляционных систем входят следующие элементы:</p>
                <ul>
                    <li>Приточный вентилятор;</li>
                    <li>Вытяжной вентилятор;</li>
                    <li>Роторный или пластичный рекуператор;</li>
                    <li>Электрический нагреватель;</li>
                    <li>Воздушные фильтры;</li>
                    <li>Система автоматики</li>
                </ul>
                <img src={img16} alt='' className='img-width' />
                <p>Приточно-вытяжные вентиляционные системы нашли широкое применение, их используют в квартирах, домах, офисах промышленных зданиях... В зависимости от того, какой объём помещения необходимо вентилировать, устройство приточно-вытяжной вентиляции может усложняться или, наоборот, упрощаться. Главная сложность заключается в том, чтобы правильно провести непростую схему каналов из воздухораспределителей.</p>
                <p>Кроме них, в строение указанного типа системы входят калорифер, охладитель, шумопоглотители, фильтра угольного типа, а также различного рода электронные датчики для регулирования температуры внутри помещения, регулировки углекислого газа и прочие.</p>

                <h2>Краткое описание основных элементов приточно-вытяжной вентиляции</h2>
                <p>Система приточно-вытяжной вентиляции конструктивно включает в себя два независимых канала подачи и удаления воздуха, каждый из которых содержит несколько отдельных устройств, соединенных между собой воздуховодами.</p>
                <p><strong>Приточно-вытяжная принудительная вентиляция обычно включает в свой состав:</strong></p>
                <ul>
                    <li>Воздухозаборные решетки, обеспечивающие поступление в систему наружного воздуха и предохраняющие от попадания в воздуховоды посторонних предметов.</li>
                    <li>Воздушные клапаны, регулирующие расход поступающего наружного воздуха и препятствующие проникновению в систему холодного воздуха при отключении системы.</li>
                    <li>Воздушные фильтры, очищающие поступающий наружный воздух от различных примесей: насекомых, пыли и пр.</li>
                    <li>Вентиляторы, обеспечивающие направленное перемещение воздушного потока по воздуховодным каналам.</li>
                    <li>Шумоглушители для снижения аэродинамических шумов, возникающих при работе вентиляторов.</li>
                    <li>Воздуховоды и их фасонные части, соединяющие все элементы системы в единую воздухораспределительную сеть.</li>
                    <li>Воздухораспределители, служащие для распределения подаваемого свежего воздуха внутри помещений.</li>
                    <li>Системы автоматики, управляющие работой отдельных элементов вентиляционной сети и контролирующие ее основные параметры.</li>
                </ul>
                <img src={img20} alt='' className='img-width' />
                <p>При необходимости схема приточно-вытяжной вентиляции может также включать дополнительные элементы: дроссельные клапаны, воздухонагреватели, охладители, рекуператоры, осушители и увлажнители воздуха и т.д. Применение таких устройств обеспечивает не только поступление в помещения свежего воздуха, но и позволяет регулировать его основные параметры: температуру, влажность и т.д.</p>
                <img src={img50} alt='' className='img-width' />
                <p>Проектирование приточно-вытяжной вентиляции – этап, с которого начинается установка вентиляционной системы. Прежде чем произвести фактическую установку, необходимо на бумаге просчитать сколько требуется метров трубы для оттока воздуха, сколько требуется воздуховодов для притока воздуха, где будут стоять все узлы и детали системы, где будут установлены решетки и воздухозаборы. На этапе проектировании следует учитывать не только местонахождение, но и размеры воздуховодов (диаметр труб), чем больше диаметр – тем больший поток воздуха можно обеспечить, однако современное жилье редко отличается большой высотой потолков, поэтому установить довольно широкие трубы не получится. Минус узких воздуховодов – высокая шумность, поэтому при расчете приточной вентиляции обычно находят компромисс между показателями шума и размерами труб.</p>

                <h2>Принцип работы системы</h2>
                <p>На первый взгляд может показаться, что приточно-вытяжная система вентиляции очень сложная, однако на самом деле она простая. В ней по всему дому прокладываются специальные каналы, по которым поступает воздушный поток в помещение, а также прокладываются вытяжные каналы. На улице устанавливается специальный вентилятор, который обеспечивает поступление воздуха.</p>
                <p>В самом помещении устанавливается специальный конвектор, который не только очищает уличный воздух, но также в зависимости от времени года и внутренней и наружной температуры, также может его либо нагревать, либо остужать, до такого уровня, который указывается при настройке.</p>
                <p>Данная система имеет очень интересный принцип работы. В ней воздух принудительно нагнетается в комнату при помощи мощного вентилятора. В результате чего, создается перепад давления и «старый» воздух, пытаясь саморегулировать этот дисбаланс, выходит на улицу через специальные вытяжные каналы.</p>
                <p>Говоря о данной системе, следует четко понимать, о каком доме идет речь, так как ее монтаж в частном доме существенно отличается от многоквартирного дома. Так, например, в частном доме хозяин может индивидуально проложить все необходимые вентиляционные каналы, в то время как в многоэтажном доме она чаще всего единственная обеспечивающая вентиляцию всего дома. Таким образом, можно сказать, что она общественного назначения. Тем не менее, никто не запрещает установить у себя в квартире индивидуальную приточно-вытяжную систему.</p>
                <p>В этой системе очень интересно устроен способ фильтрации уличного воздуха. При этом основной фильтр производит фильтрацию при помощи ультрафиолетовой лампы, кроме этого дополнительно можно установить сантониновый или поролоновый фильтр.</p>

                <h2>Преимущество приточно-вытяжной вентиляции</h2>
                <p>Во-первых, она принудительно обеспечивает замену воздуха в любом помещении, во-вторых, производит всю необходимую обработку, то есть и нагрев и очищение. В некоторых системах предусмотрено увлажнение воздуха, это происходит в определенных пределах за счет поступающей влаги, выделяющейся в канале в поток приточного свежего воздуха. Так как все механизмы издают определенные шумы, в комплект с системами могут входить специальные шумоглушители или шумозащитные короба. С ее помощью из помещений повышенной влажности, ванных комнат, санузла и кухни удаляется избыточная влага. Такая вентиляция в квартире может быть осуществлена в виде моноблоков с небольшим размером, имея при этом приятный, красивый и стильный дизайн. Систему такого вида можно навесить прямо на поверхность стены, и она хорошо впишется в интерьер любого помещения.</p>
                <p>Там где установлена вентиляция, человек никогда не испытает дискомфорта и переизбытка углекислой кислоты. В таких помещениях всегда свежий и чистый воздух, нет неприятных запахов, спертости и духоты, избыточной высокой влажности. Перед установкой приточно-вытяжной вентиляции обязательно следует провести ряд подготовительных работ, произвести необходимые расчеты и выбрать оборудование оптимального типа с учетом всех свойств данного помещения. При правильном монтаже такая вентиляция станет залогом постоянного хорошего настроения и самочувствия.</p>

                <h2>Приточно-вытяжная вентиляция. Виды вентиляционных систем</h2>
                <p>В зависимости от требований, предъявляемых к различным помещениям, приточно-вытяжная вентиляция классифицируется по комплектации и функциональным особенностям.</p>
                <p><strong>Система приточно-вытяжной вентиляции с рекуператором</strong></p>
                <p>Основная функция приточно-вытяжной вентиляции с рекуперацией тепла состоит в обеспечении свежим и в то же время теплым воздухом в холодный сезон, и охлажденным – в жаркий период.</p>
                <p>Эта система устроена так, что в зимний период поток теплого отработанного воздуха не просто выводится наружу, а проходит через теплообменный блок и отдает свое тепло входящему холодному воздуху. В результате этого помещение насыщается свежим, но уже теплым воздухом. В летнее время происходит обратный процесс, при котором поступающий воздух уже охлаждается. Причем схема установки такова, что оба потока (входящий и удаляемый) не смешиваются между собой.</p>
                <p>Процесс рекуперации тепла обеспечивается специальным устройством – рекуператором. Различают два вида рекуператора: роторный и пластинчатый. Пластинчатый рекуператор тепла включают в проект вентиляции в регионах, где зимы не столь суровы. Такое устройство экономично по стоимости и вполне справляется с утилизацией тепла при минусовой температуре до 15 градусов. Для вентиляционных установок, применяемых в областях с более холодными зимами, используют роторные устройства. Они более затратные, но эффективные, и окупаются за счет экономии на отоплении помещений.</p>
                <p>Системы приточно-вытяжной вентиляции с рекуперацией тепла широко используются на различных объектах: дома, загородные коттеджи, офисы, сети гостиниц и ресторанов, спортивные комплексы, производственные здания.</p>
                <p><strong>Система приточно-вытяжной вентиляции с кондиционированием</strong></p>
                <p>Приточно-вытяжная вентиляция с кондиционированием объединяет в себе систему вентиляции и кондиционер в одном теплоизолированном блоке со встроенной автоматикой. Такая система вентиляции обеспечивает прямоточную подачу нагретого или охлажденного воздуха. Имеет несколько режимов мощности вентилирования и кондиционирования, которые устанавливаются автоматически. Это происходит благодаря регуляторам, которые проверяют состояние микроклимата в помещении и, в зависимости от этого, назначается тот или иной режим.</p>
                <p>Установки приточно-вытяжной вентиляции с кондиционированием отличаются высокой производительностью и имеют ряд существенных достоинств:</p>
                <ul>
                    <li>нет необходимости во внешних сетях трубопровода и выносных блоках;</li>
                    <li>незначительная степень шума;</li>
                    <li>небольшие габариты и вес;</li>
                    <li>простота в управлении.</li>
                </ul>
                <p>Автоматика позволяет сделать настройку так, что пользователю не понадобится изменять регулировки на протяжении всей эксплуатации прибора независимо от сезона.</p>
                <p><strong>Система вентиляции с принудительным охлаждением</strong></p>
                <p>Приточно-вытяжные установки могут быть использованы для эффективного охлаждения воздуха в летний жаркий период. Такие установки дополнительно оснащаются различными видами оборудованием для охлаждения:</p>
                <ul>
                    <li>блок водяного охлаждения;</li>
                    <li>компрессорно-конденсаторный блок;</li>
                    <li>блок сочетания холодильной машины и теплового насоса (система чиллер фанкойл).</li>
                </ul>
                <p>Одним из наиболее используемых в вентиляции способов охлаждения является подключение к холодному водоснабжению, за счет которого охлаждается до нужной температуры приточный поток воздуха. Такая системы наиболее рациональны для больших объектов или помещений, где предусмотрено хранение продуктов, и не используется в домах и офисах.</p>

                <h2>Системы общеобменной приточно-вытяжной вентиляции</h2>
                <p>Общеобменная вентиляция применяется в случае, когда образующиеся в помещении влага, тепло, газы, пары, запахи, распространяются по всему помещению. Проектом рассчитывается такое количество удаляемого (вытяжного) воздуха, при котором обеспечивались бы санитарно-гигиенические показатели вдыхаемого человеком воздуха.</p>
                <p>Традиционно объем извлекаемого воздуха равен объему приточного. Однако при вредных производствах вытягивается больший объем для блокирования распространения загрязненного вредного потока по всему помещению. Тогда недостающее количество воздуха компенсируется за счет притока (подкачки) из близлежащих, более чистых, помещений или путем открытия окон и дверей.</p>
                <p>Если в помещении наблюдается недостаток тепла, общеобменная вентиляция устраивается с помощью механического способа и подогрева всего приточного потока воздуха. Кроме этого, наружный воздух подвергается очищению.</p>
                <p>Одним из простых способов общеобменной вентиляции является применение вентилятора для приточно-вытяжной вентиляции. Его устанавливают в оконном проеме или стеновом отверстии. Такой монтаж позволяет извлекать воздух из ближайшей загрязненной зоны для осуществления общего воздухообмена.</p>
                <p>Если при производстве в цехе выделяются вредные тяжелые газы, проектом предлагается сделать воздуховоды вытяжки на полу или в подпольных каналах. В таких помещениях, где нет возможности локализовать вредные выделения, используют общеобменные системы вытяжной вентиляции.</p>
                <p>Приточно-вытяжную систему вентиляции Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default SupplyExhaustVentilation