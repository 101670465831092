import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './storeAirConditioning.css'

import img21 from '../../../img/21_small.jpg'
import img52 from '../../../img/52179g41.jpg'

const StoreAirConditioning = () => {
    return (
        <>
            <div className='store-air-conditioning-page'>
                <h1>Системы кондиционирования магазинов, торговых помещений</h1>
                <p>Систему кондиционирования для магазина Вы можете заказать с монтажом, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <img src={img21} alt='' />
                <p>Прежде чем приступить к установке кондиционера в магазине, необходимо определить требуемую мощность охлаждения. Это зависит от количества холодильников и иной техники, размера окон, освещения, примерного количества посетителей, системы отопления и др. Если есть возможность, параметры кондиционирования и вентиляции следует устанавливать еще на стадии проектировки помещения – это позволит избежать целого ряда осложнений. Хороший специалист предложит такую систему кондиционирования, которая будет соответствовать всем климатическим требованиям и не нарушит дизайн магазина.</p>
                <p>Для того, чтобы обеспечить комфортные условия, необходимо придерживаться простого правила: летом в должно быть качественное кондиционирование магазинов и приятная прохлада, а зимой температура должна быть комфортной, но не слишком жаркой, чтобы одетые в верхнюю одежду люди не чувствовали себя как в печке.</p>

                <h2>Особые условия для разных помещений</h2>
                <p>Главной задачей является организация такой системы вентиляции, которая позволяет обеспечить различные условия для отдельных зон. В частности, зоны продуктовой торговли и зоны продажи одежды существенно отличаются по климатическим требованиям. Также распространённой практикой является применение одних и тех же климатических установок для организации работы холодильных витрин наряду с кондиционированием магазинов в целом.</p>
                <p>Благодаря продуманной системе воздуховодов, обеспечивающих индивидуальный температурный режим для каждого подключенного агрегата, а также системе управляемых при помощи интеллектуальных электронных контроллеров чиллеров или фанкойлов обеспечивается нужный режим в каждой секции магазина.</p>

                <h2>Климат-контроль, который помогает бизнесу</h2>
                <p>Как известно, в хорошем торговом предприятии каждая деталь служит повышению продаж, и кондиционирование магазинов – не исключение. Создавая приятные и комфортные условия для посетителей, владельцы торговых площадей повышают вероятность продаж. Учитывая возможности тонкой настройки всех параметров воздуха, в том числе возможность контролировать влажность, отфильтровывать пыль, настраивать объем притока свежего воздуха и уровень давления воздушного потока, владельцы торговых площадей получают тонкий инструментарий для подбора условий, которые лучше всего будут стимулировать посетителей к совершению покупок.</p>
                <p>Единых 100% рабочих рецептов нет: для магазинов одежды это будут одни условия, для магазинов ювелирных изделий – другие, для продуктовых – третьи, для заведений общественного питания – четвертые. В каждом случае необходимо подбирать условия методом проб и ошибок, адаптивно. Но главное, чтобы техника позволяла эти условия создавать.</p>

                <h2>Оптимальные климатические системы</h2>
                <p>Считается, что лучшими для магазинов являются мульти-сплит-системы и канальные кондиционеры. Второй вариант предполагает наличие системы воздуховодов, которые устанавливаются под подвесными потолками. Это позволяет:</p>
                <ul>
                    <li>эффективно охлаждать даже очень большой зал,</li>
                    <li>выпускать воздух сразу в нескольких местах, создавая максимум комфорта для посетителей, находящихся в разных уголках магазина,</li>
                    <li>охлаждать сразу несколько помещений,</li>
                    <li>подавать с улицы свежий воздух в необходимых для данного помещения объемах.</li>
                </ul>
                <p>Однако при этом следует помнить, что канальные кондиционеры стоят дороже обычных сплит-систем, и при этом наличие подвесного потолка в магазине – обязательное требование, а его может не быть в некоторых помещениях.</p>
                <p>Также подвесной потолок потребуется для тех, кто выбирает кассетные кондиционеры. Они способны направлять охлажденный воздух вдоль потолка, распределяя его в четырех направлениях. В этом случае сила каждого потока регулируется отдельно, и можно сделать так, чтобы ни один из них не попадал напрямую на людей. Такой кондиционер лучше всего расположить в центре торгового зала, что позволит держать максимально ровный температурный фон во всем магазине. </p>
                <p>Если же подвесного потолка в торговом зале нет и не предвидится, лучше использовать настенную сплит-систему. Правда, такой кондиционер способен охлаждать только одно помещение, но для маленького магазина этого будет вполне достаточно.</p>
                <img src={img52} alt='' />
                <p>Еще один вариант – потолочные кондиционеры. Они отличаются от канальных и кассетных, поскольку их не следует устанавливать под подвесными потолками. Такой вариант идеален для магазина-«стекляшки», в котором нельзя установить другие виды климатического оборудования. Такой агрегат крепится к потолку, направляя холодный воздушный поток вдоль него, что позволяет посетителям чувствовать себя комфортно.</p>
                <p>При использовании канального кондиционера распределение воздуха по помещениям осуществляется по системе воздуховодов, которые размещаются под подвесным потолком. С помощью воздуховодов можно выпускать охлажденный или нагретый воздух в двух, трёх, пяти, десяти и более местах, при необходимости распределяя его сразу на несколько помещений. Кроме того, у канального кондиционера есть возможность подачи свежего воздуха с улицы в объёмах, необходимых для полноценной вентиляции. Существенный минус «канальников» - они стоят несколько дороже остальных сплит-систем, а кроме того, для их установки необходимо наличие подвесных потолков.</p>
                <p>Кассетные кондиционеры также устанавливаются за подвесным потолком. Кассетные кондиционеры направляют охлажденный или нагретый воздух горизонтально вдоль потолка сразу по четырём направлениям. Сила потока по каждому направлению регулируется отдельно, что позволяет равномернее распределять воздух по помещению и избегать попадания прямого потока на людей. Размещается кассетный кондиционер над центром торгового зала, благодаря чему в магазине поддерживается максимально ровный температурный фон.</p>
                <p>В небольших магазинах можно использовать сплит-систему настенного типа. На сегодняшний день это самая распространённая модель кондиционеров, её выпускают абсолютно все существующие компании. Правда использовать настенную сплит-систему можно только в одном помещении - на два, или тем более три, его уже не хватит.</p>
                <p>Тем не менее, в каждом конкретном случае выбор кондиционера требует индивидуального подхода, и лучше всего обратиться за советом к специалисту.</p>
                <p><strong>Независимые кондиционеры для различных отделов магазинов</strong></p>
                <p>В магазинах возможно расположение нескольких торговых залов различного назначения, например, продовольственного и книжного отделов. В данном случае целесообразна установка двух автономных канальных кондиционеров, которые позволят осуществить независимую регулировку параметров рабочей среды и организуют отличное распределение воздушных потоков.</p>
                <p>При не очень большой площади залов (до 40 м2) возможна установка сплит-систем, однако равномерность обработки помещений существенно пострадает. Если объем помещений достаточно велик, то в данной ситуации возможно применение системы из чиллера и нескольких фанкойлов.</p>

                <h2>Исходные данные для кондиционирования в магазинах</h2>
                <p>Требования к системам кондиционирования и другим инженерным сетям сегодня достаточно высоки, потому в магазинах такие коммуникации должны строиться на основе разработанного профессиональными специалистами проекта. Для подготовки проектной документации на устройство системы кондиционирования проектировщикам потребуются различные исходные данные, описывающие особенности строения, помещений магазина и требования заказчика к функциональности сети. Техническое задание – самый важный и объемный документ, предоставляемый проектировщикам на начальном этапе сотрудничества. Техническое задание должно разрабатываться собственником сооружения, потому если вы являетесь арендатором магазина в крупном торговом центре, составленное ТЗ на разработку инженерных систем нужно будет предварительно согласовать с владельцем объекта.</p>
                <p>Грамотное техзадание на разработку проекта кондиционирования в магазине должно включать в себя следующую информацию:</p>
                <ol>
                    <li>Адрес объекта, в котором расположен магазин заказчика проектирования системы кондиционирования воздуха.</li>
                    <li>Имя и контактные данные собственника объекта.</li>
                    <li>Технические характеристики, назначение и особенности системы кондиционирования, которую необходимо установить в магазине. Кондиционирование воздуха может быть обеспечено за счет установки кондиционеров, мультизональных систем кондиционирования, сплит-систем и другого подобного оборудования.</li>
                    <li>Площадь помещений магазина, материалы, из которых изготовлены стены, потолок и пол, высота потолка.</li>
                    <li>Количество и расположение оконных, дверных проемов.</li>
                    <li>Толщина полов, материалы кровли, технические характеристики материалов.</li>
                    <li>Желаемые места установки оборудования системы кондиционирования.</li>
                    <li>Схема отдельных зон внутренних помещений магазина с указанием мест работы сотрудников.</li>
                    <li>Список зон, для которых требуются индивидуальные условия кондиционирования.</li>
                    <li>Список электрических устройств в магазине, способных нагреваться и влиять на температурные условия во внутренних помещениях, места их расположения.</li>
                    <li>Все имеющиеся пожелания у заказчика, касающиеся технических характеристик системы кондиционирования воздуха, функциональности сети, экономичности, особенностей ее эксплуатации. </li>
                </ol>
                <p>Чтобы нанятые проектировщики смогли создать для вашего магазина качественную и надежную систему кондиционирования воздуха, им также потребуются следующие документы:</p>
                <ul>
                    <li>Проект системы вентиляции воздуха в магазине.</li>
                    <li>План внутренних помещений магазина с разделением площади на отдельные зоны, здесь должны быть указаны места расположения электрических устройств, стеллажей, дверей и окон.</li>
                    <li>Архитектурный план объекта, где располагается магазин заказчика.</li>
                </ul>

                <h2>Регулирование кондиционером влажности воздуха</h2>
                <p>При расчете кондиционера поступления влаги от посетителей определяются так же, как и при легкой физической работе (0,125 W, кг/ч при 200С). Поступления влаги от персонала соотносят с работой средней тяжести (0,180 W, кг/ч при 200С). Для определения выделения углекислого газа пользуются упрощенной методикой – один индивидуум «надышит» примерно 20 литров СО2 за один час, независимо от интенсивности работы и времени года (что небесспорно, но всеми принято).</p>
                <p>Объем приточного воздуха, поставляемого кондиционером, и удаляемого воздуха определяется при помощи таблиц в соответствии с санитарно-гигиеническими нормами и условиями окружающей среды, «разбитыми» на группы по основным параметрам. Это удел инженеров вентиляции и нам окунаться в расчетную часть проблемы не стоит.</p>

                <h2>Проектирование кондиционирования магазина</h2>
                <p>Проектирование кондиционирования магазина должно разрабатываться с учетом специфики объекта. И справиться с этой сложной работой смогут только профессиональные мастера, имеющие достаточный опыт работы, обладающие высокой квалификацией и знанием нормативной базы. Ведь в конечном итоге проект должен пройти согласование.</p>
                <p>Разработка рабочего проекта дает возможность определиться с выбором типа системы кондиционирования нужной мощности и производительности. В процессе проектирования могут возникнуть разные решения. Это связано с тем, что есть несколько вариантов выбора оборудования. Вместе с заказчиком выбирается наиболее оптимальная и приемлемая для него система.</p>
                <p>Система кондиционирования не только обеспечивает обмен воздуха в помещении, но и поддерживает определенные климатические параметры, регулирует уровень тепла и влаги. Если в здании магазина производят одновременно продажу продовольственных и промышленных товаров, то система кондиционирования должна быть раздельной.</p>
                <p><strong>Что лежит в основе проектирования</strong></p>
                <ul>
                    <li>Точный расчет теплового баланса и воздухообмена. При этом следует учитывать все возможные факторы, влияющие на температуру внутри помещения, влажность. Для этого нужно знать тепловые нагрузки – внутренние и внешние;</li>
                    <li>Проект должен соответствовать санитарно-гигиеническим нормам, исходя из которых, выбирается необходимое оборудование. При составлении проекта учитываются архитектурные и технические особенности здания;</li>
                    <li>В процессе работы составляют рабочий план и выполняют разработку чертежей будущего монтажа оборудования.</li>
                </ul>
                <p><strong>С чего начинается проектирование</strong></p>
                <p>До начала выполнения расчетов необходимо сделать тщательный осмотр объекта с учетом его особенностей и пожеланий заказчика. Делаются нужные замеры, обговариваются технические моменты и составляется техническое задание на проект. Этот документ имеет большое значение, особенно на начальном этапе, который обязательно согласовывается с клиентом.  На его основе инженеры производят необходимые расчеты.</p>
                <p><strong>Содержание технического задания</strong></p>
                <ul>
                    <li>Точный адрес расположение объекта, в данном случае магазина;</li>
                    <li>Особенности и технические характеристики системы кондиционирования, выбор приемлемого варианта. Это может быть сплит-система, мультизональное или центральное кондиционирование;</li>
                    <li>Точные параметры размеров помещения, высоты потолка, наличия окон;</li>
                    <li>Указание зон, требующих индивидуальную систему;</li>
                    <li>Предпочтительные места установки кондиционеров;</li>
                    <li>Схема подсобных помещений магазина, указание числа работающего персонала;</li>
                    <li>Перечень электрооборудования, выделяющего тепло, и указание мест установки;</li>
                    <li>Предложения заказчика о технических характеристиках системы, ее эффективности и экономичности.</li>
                </ul>
                <p><strong>Этапы проектирования кондиционирования магазина</strong></p>
                <ul>
                    <li>Расчет количества тепла. Тепловой баланс рассчитывается с учетом поступлений и потерь внутреннего и наружного тепла. Разность температур между двумя видами тепловых нагрузок зависит от сезонности. Летом она будет положительной, так как приток теплого воздуха поступает снаружи внутрь помещения, а зимой – отрицательной. На работу кондиционеров влияет поток наружного воздуха. Его свойства также меняются со сменой климатических сезонов;</li>
                    <li>Разработка схемы размещения кондиционеров. Большую роль в этом играет планировка помещения. Так, например, если в здании есть подвесной потолок, то можно использовать кассетный кондиционер. Его мощные струи холодного воздуха равномерно распределяются по всем четырем направлениям. Если здание магазина большое, а внешние блоки не должны нарушать архитектурный дизайн, то можно использовать канальный кондиционер. Распределение воздуха происходит с помощью воздуховодов;</li>
                    <li>Изготовление чертежей системы. Габариты оборудования и его внешний вид могут нарушить дизайн помещения. Поэтому заказчик предварительно должен ознакомиться с чертежами и моделями кондиционеров;</li>
                    <li>Разработка чертежей смежных работ и их описание. В процессе установки климатической системы выполняется ряд смежных работ сантехниками, электриками и строителями. Для них дается в проектировании специальная информация с перечнем работ.</li>
                </ul>
                <p>Систему кондиционирования для магазина Вы можете заказать с монтажом, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default StoreAirConditioning