import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './ventillationDesign.css'

import oo62 from '../../../img/oo62120moshhnost-ventilyacii.jpg'
import y77634snip from '../../../img/y77634snip-41-01-2003.jpg'
import b168511normativy from '../../../img/b168511normativy-vozduxoobmena.jpg'

const VentillationDesign = () => {
    return (
        <>
            <div className='ventillation-design-page'>
                <h1>Проектирование вентиляции</h1>
                <p>Проектирование вентиляции Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Проектирование вентиляции позволяет еще до начала реализации проекта проработать и учесть все нюансы до самых, казалось бы, незначительных, которые могли бы впоследствии каким-либо образом повлиять на успешную реализацию проекта.</p>

                <h2>Объекты, требующие грамотного проектирования:</h2>
                <ul>
                    <li>Общественно-культурные здания (театры, клубы, музеи, кинозалы...)</li>
                    <li>Административные здания (бизнес-центры, офисы, учреждения образования)</li>
                    <li>Промышленные, производственные и складские здания (заводы, логистические центры, цеха, склады)</li>
                    <li>Серверные, радиостанции, телецентры и другие объекты специального назначения</li>
                    <li>"Чистые помещения"</li>
                    <li>Торговые центры, гипермаркеты и магазины</li>
                    <li>Предприятия индустрии питания (рестораны, кафе, бары, кондитерские...)</li>
                    <li>Жилые здания, элитное жилье, квартиры, коттеджи, загородные дома, пентхаусы, таунхаусы</li>
                    <li>Спортивные учреждения, SPA- и фитнес-центры, плавательные бассейны, бани</li>
                    <li>Санатории, больницы, пансионаты, дома отдыха</li>
                    <li>Автостоянки, автосервисы, АЗС.</li>
                </ul>

                <h2>Преимущества проектирования</h2>
                <p>Комплексный индивидуальный подход, учитывающий все нюансы помещения (здания). В итоге достигается полное согласование между собой всех разделов инженерных систем, учитываются особенности каждого помещения, которые объединяются в единое целое: дизайн проекта, системы вентиляции, кондиционирования, автоматика, водоснабжение, отопление и канализация, пожарная сигнализация, пожаротушение, теплоснабжение, электроосвещение и прочее...</p>
                <p>Проектирование систем вентиляции дает возможность выбрать самое оптимальное место для расположения вентиляционной коммуникации.</p>
                <p>Благодаря предварительному проектированию можно заранее продумать грамотное сечение воздуховодов, чтобы организовать правильный воздухообмен.</p>
                <p>Проектирование позволяет создать эффективное распределение воздуха в любом помещении.</p>
                <p>Заказывая проект, вы можете рассчитывать на то, что мастера в точности рассчитают, сколько материалов понадобится для всей системы, и подберут необходимое оборудование, а вам заблаговременно будет известна стоимость всей работы.</p>
                <p>Разработанный проект будет использоваться как основной документ во время монтажа устройств.</p>
                <p>Предварительное проектирование позволяет согласовывать с клиентом все этапы будущего процесса работы.</p>
                <ul>
                    <li>Экономическая выгода, предполагающая сокращение финансовых вложений</li>
                    <li>Снижение рисков</li>
                    <li>Экономия времени на реализацию проекта</li>
                </ul>

                <h2>Этапы создания проекта</h2>
                <p>Создание проекта проходит в несколько этапов.</p>
                <ol>
                    <li>На первом этапе происходит создание общей схемы во всех деталях.</li>
                    <li>Далее следует составление в моделях расположения воздухопроводных сетей и других коммуникаций.</li>
                    <li>На третьем этапе рассчитываются теплопритоки, воздухообменные параметры, воздухообмен в деталях по каждому помещению, где предполагается наличие вентиляционной системы.</li>
                    <li>Затем идут аэродинамические расчеты, согласно которым определяется конечный расход и общее воздушное давление в проектируемой системе вентиляции.</li>
                    <li>И наконец, ведутся заключительные расчетные работы, целью которых является определение оптимальных параметров сечения воздуховодов.</li>
                </ol>

                <h2>Что входит в полный пакет проектной документации</h2>
                <p>Любой проект вентиляции должен включать полный пакет документации, куда входят: входит монтажная схема вентиляции, расчёт воздухообмена, аэродинамический расчет, расчет теплопритоков, акустический расчет; лист общих данных и характеристик принятых систем ОВиК; планы всех этажей с разводкой систем вентиляции и кондиционирования с указанием всех сечений (диаметров) воздуховодов, расчетных расходов, типов и количества воздухораспределительных устройств, привязок оборудования и решеток и другой необходимой информацией для монтажа систем ОВиК; аксонометрические схемы систем ОВиК; смета спецификации вентиляционного оборудования и материалов, пояснительная записка, другие расчеты и сведения. Проект вентиляции, в соответствие с ГОСТ, СНиП, СанПин – это полная нормативная документация с содержанием всех параметров и характеристик устанавливаемого оборудования.</p>
                <p>В результате заказчик получает ряд подготовленных документов:</p>
                <ol>
                    <li>рабочий проект в бумажном виде (2 экз.) и электронном (1 экз.)</li>
                    <li>техническое задание на проектирование (1 экз.)</li>
                    <li>техническое задание для смежных работ (строительных, электрике, слабые токи...)</li>
                    <li>лицензия СРО на проектирование.</li>
                </ol>

                <h2>Основы проектирования</h2>
                <p>Если владелец решил заняться вопросом составления проекта вентиляционной системы, то необходимо соблюсти ряд требований, среди которых:</p>
                <ul>
                    <li>санитарные;</li>
                    <li>противопожарные;</li>
                    <li>эксплуатационные;</li>
                    <li>строительно-архитектурные.</li>
                </ul>
                <p>Проектирование вентиляции начинается с расчёта производительности системы. Первым шагом является определение достаточного воздухообмена и его кратности, что в свою очередь представляет собой количество смен полного объёма воздушной среды в здании за единицу времени (один час). Воздухообмен вычисляется в индивидуальном порядке для каждой комнаты, а затем суммируется. Существуют нормы значений этого параметра для помещений специального назначения.</p>
                <p>При расчётах обязательно учитываются нормы проектирования вентиляции, оговоренные в специальной документации. Так, при определении достаточного количество приточного воздуха, следует руководствоваться нормативным документом СНиП 41-01-2003, в соответствии с которым количество воздуха, расходуемое одним человеком, составляет в среднем 60 м3/час. В ночное время человеку требуется меньшее количество кислорода, а значит, значение данного параметра уменьшается вдвое. Но проектирование систем вентиляции всегда лучше выполнять с некоторым запасом.</p>
                <p>Расчёт воздухообмена выполняется, исходя из того, сколько людей в среднем пребывают в помещении. Для этого необходимо умножить нормированное значение расхода воздуха на количество человек. Кратность воздухообмена вычисляется следующим образом: объём помещения умножается на коэффициент (нормируемую кратность воздухообмена), который разнится в зависимости от назначения помещения:</p>
                <ul>
                    <li>для санузла — 7;</li>
                    <li>для кухни — от 5 до 10;</li>
                    <li>для жилой комнаты — до 2;</li>
                    <li>для офиса — до 3.</li>
                </ul>
                <p>Проектирование не обходятся без определения сечений воздуховодов. При этом потребуется два параметра: рассчитанный ранее воздухообмен, а также средняя скорость движения воздуха. В норме, воздушный поток не должен перемещаться быстрее 0,5 м/с, так как любые значения выше указанного приведут к ощутимому сквозняку.</p>

                <h2>Краткие указания по проектированию вентиляции</h2>
                <p>При проектировании вентиляции необходимо определить:</p>
                <ul>
                    <li>способ вентилирования отдельных помещений и всего здания в целом;</li>
                    <li>количество и месторасположение приточных и вытяжных систем;</li>
                    <li>размер помещений, в которых должны быть размещены приточные вентиляционные установки;</li>
                    <li>количество тепла, расходуемого на нагрев приточного воздуха;</li>
                    <li>количество и мощность электродвигателей, приводящих в действие вентиляторы.</li>
                </ul>

                <h2>Расчет проекта</h2>
                <p>Мощность вентиляции выражается в кубометрах в час (м3/ч) и показывает, какой объем воздуха способна пропустить через себя вся вентиляционная система в единицу времени. Эта величина зависит от двух параметров – необходимой кратности воздухообмена данного объема и максимального количества находящегося в помещении, обслуживаемом проектируемой приточно-вытяжной вентиляцией, людей, а также их рода деятельности. Проект вентиляции требует расчета мощности в зависимости от обоих параметров, после чего выбирается большее значение.</p>
                <img src={oo62} alt='' />
                <p>Кратность воздухообмена – безразмерная величина, показывающая, сколько раз в течение часа происходит полный воздухообмен в данном помещении. Строительные нормы и правила (СНиП) предусматривают разные нормы кратности воздухообмена для помещений различного функционального назначения. Для жилых помещений в соответствии со СНиП кратность воздухообмена равна 1, но не менее 30м3 на человека в час. Для кухонь и санузлов кратность воздухообмена составляет 1,5, но не менее 60 м3 на человека в час. Необходимая мощность вентиляции в зависимости от кратности воздухообмена рассчитывается по формуле</p>
                <p>L = n * V, где:</p>
                <p>L –необходимая мощность вентиляции; n – нормативная кратность воздухообмена; V – объем помещения, равный площади помещения, умноженной на высоту потолка.</p>
                <p>В зависимости от количества человек необходимую мощность вентиляции рассчитывают по следующей формуле</p>
                <p>L = N * LN, где:</p>
                <p>N – максимальное количество человек, находящихся в помещении; LN– нормативное часовое потребление воздуха каждым человеком, в зависимости от функционального назначения помещения (которое подразумевает соответствующий род занятий людей в данном помещении).</p>
                <p>СНиП 41-01-2003 предусматривает следующие значения LN:</p>
                <img src={y77634snip} alt='' />
                <p>Более гибко оценить объем необходимого воздухообмена, а значит, и мощность вентиляции, позволяют нормативы, составленные различными общественными и профессиональными международными и федеральными организациями, такими как АВОК, ASHRAE и другие. Составленные ими нормативы носят справочно-рекомендательный характер, внесение их в проект вентиляции необязательно, однако их полезность для повышения эффективности проектирования и монтажа сложно переоценить.</p>
                <img src={b168511normativy} alt='' />
                <p>Обычно необходимая мощность вентиляционной системы находится в пределах: 100-500 м2/ч для квартир; 1000-2500 м2//ч для частных коттеджей; 1000-15000 м2/ч для производственных, складских и административных помещений.</p>
                <p>При необходимой мощности 1000 м2/ч и более в проекте предусматривают отдельное помещение – вентиляционную камеру, в которой производится монтаж основного оборудования, а также автоматического блока, который сможет руководить всей системой в соответствии с заложенной программой. Венткамера должна соответствовать следующим параметрам: плюсовая температура в любое время года, достаточная шумоизоляция, обеспечение достаточной мощности электропитания. Если в качестве нагревательного элемента интегрированного в систему вентиляции воздушного отопления используется водяной калорифер, необходим также подвод воды и монтаж гидроизоляции помещения.</p>
                <p>При проектировании и монтаже приточно-вытяжной вентиляции жилых помещений шумоизоляции следует уделить особое внимание. Уровень шума, который едва заметен днем, ночью может казаться совершенно нестерпимым.</p>
                <p>В соответствие с расчетной мощностью подбирается оборудование вентиляционной системы, протяженность и сечение воздуховодов. При наличии воздушного отопления, воздуховоды должны иметь слой теплоизоляции.</p>
                <p><i>Стоимость проектирования вентиляции будет напрямую зависеть от сложности технического задания.</i></p>
                <p>По итогам разработки техзадания, на генеральный чертеж дома наносится схема вентиляционной системы (если система вентиляции проектируется до начала строительства). Либо, в случае, если вентиляционная система разрабатывается для готового дома, составляется отдельный чертеж системы вентиляции загородного дома.</p>
                <p>Проектирование вентиляции Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default VentillationDesign