import React from 'react'
import { Link } from 'react-router-dom'
import rr27 from '../../img/rr27_05_2014 23-52-05.jpg'
import r127 from '../../img/r127-05-2014 23-48-57.jpg'
import r227 from '../../img/r227-05-2014 23-50-15.jpg'
import tt28 from '../../img/tt28-05-2014 13-44-54.jpg'
import ii28 from '../../img/ii28-05-2014 14-07-02.jpg'
import ll28 from '../../img/ll28-05-2014 15-40-44.jpg'
import oo28 from '../../img/oo28-05-2014 15-45-29.jpg'
import aa28 from '../../img/aa28-05-2014 14-06-20.jpg'
import vts_logo_n_small from '../../img/vts_logo_n_small.png'
import breezart from '../../img/breezart-logo_small.png'
import shuft_small from '../../img/shuft_small.png'
import swegon_small from '../../img/swegon_small.png'
import carel_small from '../../img/carel_small.png'
import './automation.css'
import Form from '../form/Form'

const Automation = () => {
    return (
        <>
            <div className='automation-component'>
                <h1>Автоматика вентиляции и кондиционирования</h1>
                <p>Системы автоматики вентиляции и кондиционирования Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка автоматики по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <p>Автоматизация вентиляции и кондиционирования в первую очередь актуальна для больших промышленных объектов: производственных площадей, фермерских хозяйств, спортивных комплексов, торговых и бизнес центров, мест массового общественного отдыха, но может с успехом применяться и в зданиях жилого фонда. От качества автоматики систем вентиляции и ее рабочих алгоритмов зависят безопасность и надежность работы всей вентиляционной системы.</p>
                <p>Автоматика приточной вентиляции также позволяет <strong>снизить энергопотребление</strong> за счет циклов своевременного включения-отключения различных групп сетевого оборудования.</p>
                <img src={rr27} alt='' className='img1' />
                <p>Система автоматики вентиляции воздуха – это совокупность устройств и алгоритмов, призванных обеспечить поддержание заданных климатических условий и управление ими, в соответствии с принятыми нормами (СНиП, ТУ) и другой нормативно-технической документацией. Система автоматики во многом определяет такие функциональные параметры систем вентиляции как:</p>
                <ul>
                    <li>надежность,</li>
                    <li>экономичность,</li>
                    <li>эффективность,</li>
                    <li>долговечность работы.</li>
                </ul>
                <p>Автоматизация систем вентиляции, как показывает практика, позволяет экономить от 13% до 20% теплопотребления и хладопотребления, а в итоге и электропотребления.</p>
                <div className='imgs-wrap'>
                    <img src={r127} alt='' />
                    <img src={r227} alt='' />
                </div>
                <p>Системы автоматики вентиляции можно условно разделить на <strong>три раздела:</strong></p>
                <ul>
                    <li>Система автоматики модульных систем вентиляции;</li>
                    <li>Система автоматики систем пожарной вентиляции.</li>
                    <li>Система автоматики центрального кондиционирования воздуха</li>
                </ul>
                <p>Некоторые до сих пор считают, что диспетчеризация систем вентиляции — это нечто лишнее и ненужное. Между тем, основная задача диспетчеризации систем вентиляции  состоит в улучшении условий эксплуатации систем и сокращении при этом обслуживающего персонала, чему способствует централизация органов включения-отключения систем, органов управления воздушными и водяными клапанами, сигнализация работы и аварийного останова систем, а также централизация контроля микроклимата в обслуживаемых зонах.</p>
                <h2>Система автоматики для модульных систем вентиляции</h2>
                <p>Модульная система вентиляции - это система вентиляции, которая набирается из отдельных элементов для создания требуемых условий в помещении (вентиляторы, калориферы, фильтры, шумоглушители и решетки, воздуховоды). Такие системы просты в монтаже, надежны, дешевы и наиболее распространены.</p>
                <p><strong>Система автоматики вентиляции должна обеспечивать:</strong></p>
                <ul>
                    <li>регулирование скорости вращения вентилятора;</li>
                    <li>защиту водяного калорифера от замерзания;</li>
                    <li>поддержание заданной температуры воздуха в воздуховодах или помещении;</li>
                    <li>индикацию степени загрязнения фильтров.</li>
                </ul>
                <img src={tt28} alt='' className='img2' />

                <p><strong>Система автоматики вентиляции включает в себя такие основные элементы:</strong></p>
                <ul>
                    <li>датчики - это элементы систем автоматики, с помощью которых производят измерение различных параметров (температуры, давления, влажности и т.д.) регулируемой системы в реальном времени. Выбор датчиков автоматического управления вентиляцией осуществляется по условиям эксплуатации, диапазону и требуемой точности измерений. Изменение параметров системы вентиляции фиксируется датчиком и с помощью электрического сигнала информация подается на регулятор;</li>
                    <li>регуляторы - это один из основных элементов системы, обеспечивающий управление исполнительными механизмами по показаниям различных датчиков;</li>
                    <li>устройства ввода - периферийное оборудование для занесения  данных или сигналов в электронное устройство системы вентиляции во время его работы;устройства ввода - периферийное оборудование для занесения  данных или сигналов в электронное устройство системы вентиляции во время его работы;</li>
                    <li>управляющие устройства (контроллеры) -  устройства управления в электронике вентиляционной системы;</li>
                    <li>исполнительные механизмы представляют собой приводную часть исполнительного устройства (привода, смесительные узлы и др.). Исполнительные механизмы делятся на электрические, пневматические и гидравлические.</li>
                </ul>
                <p>Элементы автоматического управления вентиляцией и некоторые исполнительные элементы обычно объединяются в щит автоматики. Щиты автоматики изготавливаются с использованием сертифицированного оборудования ведущих мировых производителей, таких как:</p>
                <ul>
                    <li>ABB,</li>
                    <li>Legrand,</li>
                    <li>Siemens,</li>
                    <li>Schneider Electric,</li>
                    <li>Finder,</li>
                    <li>Allen-Bradley,</li>
                    <li>General Electric,</li>
                    <li>Entrelec,</li>
                    <li>Phoenix Contact,</li>
                    <li>Regin и других.</li>
                </ul>
                <img src={ii28} alt='' className='img3' />
                <p>Столь широкий выбор щитов автоматики вентиляции, имеющих высокое качество, позволяет Заказчику проводить гибкую и экономически эффективную подборку устройств, предлагаемых разными производителями.</p>
                <p>Автоматизация позволяет повысить производительность труда, улучшить качество продукции, как уже говорилось оптимизировать процессы управления, отстранить человека от производств, опасных для здоровья. При этом, автоматизация, за исключением простейших случаев, требует комплексного, системного подхода к решению задачи. В состав систем автоматизации входят датчики (сенсоры), устройства ввода, управляющие устройства (контроллеры), исполнительные устройства, устройства вывода, компьютеры.</p>

                <h2>Система автоматики для систем пожарной вентиляции</h2>
                <p>Пожарная автоматика это комплекс технических средств для предупреждения, обнаружения и тушения пожаров, обеспечения безопасности людей при пожаре и автоматической блокировки систем пожарной безопасности, инженерных систем жизнеобеспечения и технологического оборудования по заданному алгоритму.</p>
                <img src={ll28} alt='' className='img4' />
                <p>Пожарная автоматика - общее название комплекса автоматических систем противопожарной защиты (СПЗ), которыми оборудуются строения, сооружения, здания и помещения с повышенной пожарной опасностью. В комплекс систем противопожарной защиты включаются автоматические установки пожаротушения (АУПТ), сигнализации, оповещения и управления эвакуацией, противодымной защиты. Общим для систем, включаемым в понятие пожарной автоматики, является автоматический режим работы по заданной программе. При этом предусматривается дистанционное и ручное управление систем.</p>
                <p>Автоматические системы и установки пожарной защиты должны обеспечить выполнение основных функций, а именно: обнаружение и тушение пожара, информирование о пожаре, оповещение людей, находящихся в зоне пожара и обеспечение их безопасной эвакуации, ограничение распространения пожара. Блокировка систем пожарной автоматики предусматривается для подачи на тушение пожара дополнительного количества огнетушащих средств (водопровод), ограничения развития пожара (противопожарные преграды, вентиляция, технологическое оборудование), исключения опасности для людей (энергосистемы).</p>

                <p><strong>В комплекс систем противопожарной защиты включаются:</strong></p>
                <ul>
                    <li>автоматическая пожарная сигнализация;</li>
                    <li>автоматическое пожаротушение;</li>
                    <li>внутренний противопожарный водопровод;</li>
                    <li>оповещение о пожаре и управление эвакуацией людей;</li>
                    <li>противодымная защита;</li>
                    <li>устройства, ограничивающие распространение огня и дыма;</li>
                    <li>лифты для пожарных подразделений.</li>
                </ul>
                <img src={oo28} alt='' className='img5' />
                <p>Основными элементами систем пожарной автоматики являются устройства для обнаружения (извещатели), приборы приема, обработки и выдачи информационных сигналов, формирования управляющих сигналов и передачи их исполнительным органам, а также исполнительные устройства, обеспечивающие выполнения функциональных задач, исходя из назначения системы пожарной защиты.</p>
                <h2>Система автоматики центрального кондиционирования воздуха</h2>
                <p>Системы автоматики, входящие в этот раздел служат для управления оборудованием систем HVAC (от англ. Heating, Ventilation, & Air Conditioning — теплоснабжение, вентиляция и кондиционирование воздуха), к ним относятся холодильные машины, чиллеры, центральные и прецизионные кондиционеры и др. Это оборудование, как правило, служит для обеспечения климатических условий на предприятиях, в офисах, промышленных цехах, складах, гостиницах, торговых и спортивных комплексах и прочих зданиях и сооружениях. Системы автоматики для оборудования систем HVAC обычно уже встроены или поставляются вместе с оборудованием. Такая автоматика проектируется и производится заводом изготовителем под каждый конкретный объект по предоставленному заказчиком техническому заданию и включает в себя целый набор различных устройств и программного обеспечения.</p>
                <img src={aa28} alt='' className='img6' />

                <h2>Производители систем автоматики</h2>
                <div className='products-wrapper'>
                    <Link to=''>
                        <div className='product'>
                            <p>VTS</p>
                            <div className='bg' style={{ backgroundImage: `url(${vts_logo_n_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Breezart</p>
                            <div className='bg' style={{ backgroundImage: `url(${breezart})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Shuft</p>
                            <div className='bg' style={{ backgroundImage: `url(${shuft_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Swegon</p>
                            <div className='bg' style={{ backgroundImage: `url(${swegon_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Carel</p>
                            <div className='bg' style={{ backgroundImage: `url(${carel_small})` }}></div>
                        </div>
                    </Link>
                </div>
            </div>
            <Form />
        </>
    )
}

export default Automation