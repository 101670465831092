import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img1 from '../../../img/27-05-2014 21-49-54.jpg'
import img2 from '../../../img/27-05-2014 22-03-25.jpg'

const CalculationVentilationSystem = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Расчет системы вентиляции</h1>
                <p>Вентиляцию Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>При проектировании систем вентиляции каждый инженер проводит расчеты согласно вышеупомянутых норм.</p>
                <p>Для расчета воздухообмена в жилых помещениях  следует руководствоваться этими нормами. Рассмотрим  самые простые методы нахождения воздухообмена:</p>
                <ul>
                    <li>по площади помещения,</li>
                    <li>по санитарно-гигиеническим нормам,</li>
                    <li>по кратностям</li>
                </ul>

                <h2>Расчет по площади помещения</h2>
                <p>Это самый простой расчет. Расчет вентиляции по площади делается на основании того, что для жилых помещений нормы регламентируют подавать 3 м3/час свежего воздуха на 1 м2 площади помещения, независимо от количества людей.</p>

                <h2>Расчет по санитарно-гигиеническим нормам</h2>
                <p>По санитарным нормам для общественных и административно-бытовых зданий на одного постоянно пребывающего в помещении человека необходимо 60 м3/час свежего воздуха, а на одного временного 20 м3/час.</p>
                <p><strong>Рассмотрим на примере:</strong></p>
                <p>Предположим, в доме живут 2 человека, проведем расчет по санитарным нормам согласно этим данным. Формула расчета вентиляции, включающая нужное количество воздуха выглядит так:</p>
                <p>L=n*V (м3/час) , где</p>
                <ul>
                    <li>n – нормируемая кратность воздухообмена, час-1;</li>
                    <li>V – объём помещения, м3</li>
                </ul>
                <p>Получим, что для спальни L2=2*60=120 м3/час, для кабинета примем одного постоянного жителя и одного временного L3=1*60+1*20=80 м3/час. Для гостиной принимаем двух постоянных жителей и двух временных (как правило, количество</p>
                <p>постоянных и временных людей, определяется техническим заданием заказчика) L4=2*60+2*20=160 м3/час, запишем полученные данные в таблицу.</p>
                <p>Составив уравнение воздушных балансов ∑ Lпр = ∑ Lвыт:360 525 м3/час, видим, что количество вытяжного воздуха превышает приточный на ∆L=165 м3/час. Поэтому количество приточного воздуха необходимо увеличить на 165 м3/час. Поскольку помещения спальни, кабинета и гостиной сбалансированы то воздух необходимый для санузла, ванны и кухни можно подать в помещение смежное с ними, к примеру, в коридор, т.е. в таблицу добавится Lприт.коридор=165 м3/час. Из коридора воздухбудет перетекать в ванную, санузлы и кухню, а оттуда посредством вытяжных вентиляторов (если они установлены) или естественной тяги удалятся из квартиры. Такое перетекание необходимо для предотвращения распространения неприятных запахов и влаги. Таким образом, уравнение воздушных балансов ∑ Lпр = ∑ Lвыт: 525=525м3/час - выполняется.</p>

                <h2>Расчет по кратностям</h2>
                <p>Кратность воздухообмена - это величина, значение которой показывает, сколько раз в течение одного часа воздух в помещении полностью заменяется на новый. Она напрямую зависит от конкретного помещения (его объема). То есть, однократный воздухообмен это когда в течение часа в помещение подали свежий и удалили «отработанный» воздух в количестве равном одному объему помещения; 0,5 -кранный воздухообмен – половину объема помещения.</p>
                <p>В нормативном документе ДБН В.2.2-15-2005 «Жилые здания» есть таблица с приведенными кратностями по помещениям. Рассмотрим на примере, как производится рассчет по данной методике.</p>
                <p><strong>Последовательность расчета вентиляции по кратностям следующая:</strong></p>
                <ol>
                    <li>Считаем объем каждого помещения в доме (объем=высота*длина*ширина).</li>
                    <li>Подсчитываем для каждого помещения объем воздуха по формуле: L=n*V (n – нормируемая кратность воздухообмена, час-1; V – объём помещения, м3)</li>
                </ol>
                <img src={img1} alt='' className='img-width' />
                <p>Для этого предварительно выбираем из таблицы "Санитарно-гигиенические нормы. Кратности воздухообмена в помещениях жилых зданий" норму по кратности воздухообмена для каждого помещения. Для большинства помещений нормируется только приток или только вытяжка. Для некоторых, например, кухня-столовая и то и другое. Прочерк означает, что в данное помещение не нужно подавать (удалять) воздух.</p>
                <p>Для тех помещений, для которых в таблице вместо значения кратности воздухообмена указан минимальный воздухообмен (например, ≥90м3/ч для кухни), считаем требуемый воздухообмен равным этому рекомендуемому. В самом конце расчета, если уравнение баланса (∑ Lпр и ∑ Lвыт) у нас не сойдется, то значения воздухообмена для данных комнат мы можем увеличивать до требуемой цифры. Если в таблице нет какого-либо помещения, то норму воздухообмена для него считаем, учитывая что для жилых помещений нормы регламентируют подавать 3 м3/час свежего воздуха на 1 м2  площади помещения. Т.е. считаем воздухообмен для таких помещений по формуле: L=Sпомещения*3. Все значения L округляем до 5 в большую сторону, т.е. значения должны быть кратны 5.</p>
                <p>Суммируем отдельно L тех помещений, для которых нормируется приток воздуха, и отдельно L тех помещений, для которых нормируется вытяжка. Получаем 2 цифры: ∑ Lпр и ∑ Lвыт</p>
                <p>Составляем уравнение баланса ∑ Lпр = ∑ Lвыт. Если ∑ Lпр  ∑ Lвыт , то для увеличения ∑ Lвыт до значения ∑ Lпр увеличиваем значения воздухообмена для тех помещений, для которых мы в 3 пункте приняли воздухообмен равным минимально допустимому значению.</p>
                <p>Если ∑ Lпр  ∑ Lвыт , то для увеличения ∑ Lвыт до значения ∑ Lпр увеличиваем значения воздухообмена для помещений.</p>
                <h2>Рассчет основных параметров при выборе оборудования</h2>
                <p>При выборе оборудования для системы вентиляции необходимо рассчитать следующие основные параметры:</p>
                <ul>
                    <li>Производительность по воздуху;</li>
                    <li>Мощность калорифера;</li>
                    <li>Рабочее давление, создаваемое вентилятором;</li>
                    <li>Скорость потока воздуха и площадь сечения воздуховодов;</li>
                    <li>Допустимый уровень шума.</li>
                </ul>
                <p>Ниже приводится упрощенная методика подбора основных элементов системы приточной вентиляции, используемой в бытовых условиях.</p>
                <img src={img2} alt='' className='img-width' />

                <h2>Производительность по воздуху</h2>
                <p>Проектирование системы вентиляции начинается с расчета требуемой производительности по воздуху или «прокачки», измеряемой в кубометрах в час. Для этого необходим поэтажный план помещений с экспликацией, в которой указаны наименования (назначения) каждого помещения и его площадь. Расчет начинается с определения требуемой кратности воздухообмена, которая показывает сколько раз в течение одного часа происходит полная смена воздуха в помещении.</p>
                <p>Например, для помещения площадью 50 м2 с высотой потолков 3 метра (объем 150 кубометров) двукратный воздухообмен соответствует 300 кубометров/час. Требуемая кратность воздухообмена зависит от назначения помещения, количества находящихся в нем людей, мощности тепловыделяющего оборудования и определяется СНиП (Строительными Нормами и Правилами).</p>
                <p>Для определения требуемой производительности необходимо рассчитать два значения воздухообмена: по кратности и по количеству людей, после чего выбрать большее из этих двух значений.</p>
                <p><strong>Расчет воздухообмена по кратности:</strong></p>
                <p>L = n * S * H, где</p>
                <ul>
                    <li>L — требуемая производительность приточной вентиляции, м3/ч;</li>
                    <li>n — нормируемая кратность воздухообмена: для жилых помещений n = 1, для офисов n = 2,5;</li>
                    <li>S — площадь помещения, м2;</li>
                    <li>H — высота помещения, м;</li>
                </ul>
                <p><strong>Расчет воздухообмена по количеству людей:</strong></p>
                <p>L = N * Lнорм, где</p>
                <ul>
                    <li>L — требуемая производительность приточной вентиляции, м3/ч;</li>
                    <li>N — количество людей;</li>
                    <li>Lнорм — норма расхода воздуха на одного человека:</li>
                </ul>
                <p>в состоянии покоя — 20 м3/ч;</p>
                <p>"офисная работа"  — 40 м3/ч;</p>
                <p>при физической нагрузке — 60 м3/ч.</p>
                <p>Рассчитав необходимый воздухообмен, выбираем вентилятор или приточную установку соответствующей производительности. При этом необходимо учитывать, что из-за сопротивления воздухопроводной сети происходит падение производительности вентилятора. Зависимость производительности от полного давления можно найти по вентиляционным характеристикам, которые приводятся в технических характеристиках оборудования. Для справки: участок воздуховода длиной 15 метров с одной вентиляционной решеткой создает падение давления около 100 Па.</p>
                <p>Типичные значения производительности систем вентиляции:</p>
                <ul>
                    <li>Для квартир — от 100 до 500 м3/ч;</li>
                    <li>Для коттеджей — от 1000 до 5000 м3/ч;</li>
                </ul>

                <h2>Мощность калорифера</h2>
                <p>Калорифер используется в приточной системе вентиляции для подогрева наружного воздуха в холодное время года. Мощность калорифера рассчитывается исходя из производительности системы вентиляции, требуемой температурой воздуха на выходе системы и минимальной температурой наружного воздуха. Два последних параметра определяются СНиП.</p>
                <p>Температура воздуха, поступающего в жилое помещение, должна быть не ниже +18°С. Минимальная температура наружного воздуха зависит от климатической зоны, например, для Москвы  она равна -26°С (рассчитывается как средняя температура самой холодной пятидневки самого холодного месяца в 13 часов). Таким образом, при включении калорифера на полную мощность он должен нагревать поток воздуха на 44°С. Поскольку сильные морозы в Ташкент непродолжительны, в приточных системах допускается устанавливать калориферы, имеющие мощность меньше расчетной. Но при этом приточная система должна иметь регулятор производительности для уменьшения скорости вентилятора в холодное время года.</p>
                <p>При расчете мощности калорифера необходимо учитывать следующие ограничения:</p>
                <ul>
                    <li>Возможность использования однофазного (220 В) или трехфазного (380 В) напряжения питания. При мощности калорифера свыше 5 кВт необходимо 3-х фазное подключение, но в любом случае 3-х фазное питание предпочтительней, так как рабочий ток в этом случае меньше.</li>
                    <li>Максимально допустимый ток потребления. Величину тока (А), потребляемого калорифером, можно вычислить по формуле:</li>
                </ul>
                <p>I = P / U, где</p>
                <ul>
                    <li>I — максимальный потребляемый ток, А;</li>
                    <li>Р — мощность калорифера, Вт;</li>
                    <li>U — напряжение питания: (220 В — для однофазного питания; для трехфазной сети расчёт несколько иной).</li>
                </ul>
                <p>В случае, если допустимая нагрузка электрической сети меньше чем требуемая, можно установить калорифер меньшей мощности. Температуру, на которую калорифер сможет нагреть приточный воздух, можно рассчитать по формуле:</p>
                <p>T = 2,98 * P / L, где</p>
                <ul>
                    <li>T — разность температур воздуха на входе и выходе системы приточной вентиляции,°С;</li>
                    <li>Р — мощность калорифера, Вт;</li>
                    <li>L — производительность вентиляции, м3/ч.</li>
                </ul>
                <p>Типичные значения расчетной мощности калорифера — от 1 до 5 кВт для квартир, от 5 до 50 кВт для офисов и загородных домов. Если использовать электрический калорифер с расчетной мощностью не представляется возможным, следует установить калорифер, использующий в качестве источника тепла воду из системы центрального или автономного отопления (водяной или паровой калорифер). В любом случае, если есть возможность, лучше использовать водяные или паровые калориферы. Экономия на обогреве в этом случае получается колоссальная.</p>
                <p>Рабочее давление, скорость потока воздуха в воздуховодах и допустимый уровень шума</p>
                <p>После расчета производительности по воздуху и мощности калорифера приступают к проектированию воздухораспределительной сети, которая состоит из воздуховодов, фасонных изделий (переходников, разветвителей, поворотов) и распределителей воздуха (решеток или диффузоров). Расчет воздухораспределительной сети начинают с составления схемы воздуховодов. Далее по этой схеме рассчитывают три взаимосвязанных параметра — рабочее давление, создаваемое вентилятором, скорость потока воздуха и уровень шума.</p>
                <p>Требуемое рабочее давление определяется техническими характеристиками вентилятора и рассчитывается исходя из диаметра и типа воздуховодов, числа поворотов и переходов с одного диаметра на другой, типа распределителей воздуха.</p>
                <p>Чем длиннее трасса и чем больше на ней поворотов и переходов, тем больше должно быть давление, создаваемое вентилятором. От диаметра воздуховодов зависит скорость потока воздуха. Обычно эту скорость ограничивают значением от 2,5 до 4 м/с. При больших скоростях возрастают потери давления и увеличивается уровень шума. В тоже время, использовать «тихие» воздуховоды большого диаметра не всегда возможно, поскольку их трудно разместить в межпотолочном пространстве и стоят они дороже. Поэтому, при проектировании вентиляции часто приходится искать компромисс между уровнем шума, требуемой производительностью вентилятора и диаметром воздуховодов.</p>
                <p>Для бытовых систем приточно-вытяжной вентиляции обычно используются воздуховоды диаметром 160...250 мм или сечением 400х200мм...600х350мм и распределительные решетки размером 100200 мм — 1000500 мм.</p>
                <p>Вентиляцию Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default CalculationVentilationSystem