import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/-1e768dd5a3e14f4c7d0e6eb70fc43a3cf.jpg'
import img2 from '../../../img/-1b7e2355b4f257a8e996d5fc02faf2030.jpg'
import img3 from '../../../img/-1073b9a50adc3a6488ed972e6b0206e1d.jpg'

const HeatingResidentialBuildings = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Отопление жилых зданий</h1>
                <p>Отопление жилых зданий Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Чем отличается отопление многоквартирного дома от аналогичной автономной системы частного коттеджа или дачи? Прежде всего – наличием сложной схемы разводки трубопроводов и радиаторов нагрева. Помимо этого в систему входят уникальные устройства контроля и безопасности работы. Рассмотрим подробнее, чем характеризуется отопление жилых домов: нормы, стандарты, расчет и промывка.</p>

                <h2>Общие нормативные документы для отопления</h2>
                <p>Для проектирования отопления жилого многоквартирного дома необходимо знать текущие нормы. Они подробно изложены в соответствующих документах – ГОСТах, СНиПах. Без них невозможен ввод в эксплуатацию любого жилого здания.</p>
                <p>Существуют определенные нормативы отопления жилых помещений, которые обязательно необходимо знать при проектировании теплоснабжения. В них указываются критические уровни температуры в жилых помещениях, определяются погрешности в зависимости от погодных условий и времени суток. Определяющими документами для организации отопления жилых домов являются:</p>
                <ul>
                    <li>СНиП 2301-99. В нем описывается уровень нагрева воздуха в квартирах, жилых и нежилых помещениях;</li>
                    <li>СНиП 4101-2003. Информация о нормах вентиляции и теплоснабжения в зависимости от типа здания;</li>
                    <li>СНиП 2302-2003. Указываются данные о требуемой степени теплоизоляции. Без этой информации невозможен корректный расчет отопления жилого помещения;</li>
                    <li>СНиП 4102-2003. Нормы и требования к централизованному отоплению.</li>
                </ul>
                <p>Помимо этих документов нужно учитывать содержание и других, которые относятся к конкретным отопительным приборам. В частности – установка и подключение газового оборудования, организации котельной и т.д.</p>
                <p>Но для потребителей важно знать те параметры, которыми должна обладать система отопления многоквартирного жилого дома. Суммируя все требования из вышеописанных документов можно выделить основные характеристики теплоснабжения жилых зданий.</p>
                <p>Чаще всего страдает отопление лестничных клеток жилых домов. Именно в них из-за больших тепловых потерь температура в зимний период практически всегда ниже нормы. Поэтому жильцы дома вправе пожаловаться в управляющую компанию для исправления ситуации.</p>

                <h2>Когда начинается отопительный период?</h2>
                <p>Рассматривая нормы включения отопления, становится понятным, что отопительный сезон начинается с того момента, когда среднесуточная температура за окном на протяжении 5 дней не превышает +8 градусов. Подача же тепла прекращается, если наружная температура воздуха находится выше отметки +8 градусов, и данная ситуация длится более пяти дней.</p>
                <p>Но стоит отметить, что объекты социальной сферы, учебные заведения могут подавать заявки на запуск отопления ранее срока. Обычно за 1,5-2 месяца до начала отопительного периода осуществляется проверочный пуск отопления в городских домах. На всех подъездах расклеиваются объявления о сроках проведения проверочного запуска. Делается это с целью проверки исправности системы.</p>
                <p>В указанное время жильцам квартир лучше остаться дома. Ведь во время пробного запуска может случиться протечка системы. В обязательном порядке проводят пусконаладочные работы системы отопления и после монтажа системы теплоснабжения либо ее ремонта и модернизации. Это позволяет проверить готовность инженерных систем. А также вывести их на нужные рабочие параметры.</p>

                <h2>Центральное отопление многоквартирных домов</h2>
                <p>По магистральным трубопроводам теплоноситель из центральной котельной подается на тепловой узел многоквартирного дома и дальше распределяется по квартирам. Дополнительную регулировку степени подачи горячей воды в таком случае производят непосредственно на тепловом пункте, для чего используют циркулярные насосы. Данный способ подачи теплоносителя конечному потребителю называют независимым (подробнее: «Централизованное отопление это одновременно плюсы и минусы «).</p>
                <p>Кроме этого в многоквартирных домах используют зависимые отопительные системы. В таком случае теплоноситель транспортируют в квартирные батареи без дополнительного распределения прямо с ТЭЦ. При этом температура воды находится вне зависимости от того, подается она через распределительный пункт или непосредственно потребителям.</p>
                <img src={img1} alt='' className='img-width' />
                <p>В последнем варианте теплоноситель с ТЭЦ или центральной котельной после попадания в распределительный пункт подается раздельно на отопительные радиаторы и на горячее водоснабжение. В открытых системах подобное разделение конструкцией не предусмотрено и подогретая вода для нужд жильцов поставляется с магистральной трубы, поэтому потребители вне отопительного сезона остаются без горячего водоснабжения, что вызывает немало нареканий в адрес коммунальных служб. Читайте также: «Счетчик тепла на батарею «.</p>

                <h2>Виды подключений к системам отопления</h2>
                <p>Схему централизованного контура передвижения теплоносителя невозможно изменить. По этой причине регулировка отопления в многоквартирном доме доступна только в поквартирном варианте. Довольно редко, но иногда встречаются ситуации, когда собственными силами жильцы дома переделывают в нем отопительную систему, однако неизменными остаются принципы циркуляции теплоносителя, при которых задействуют одну или две трубы. Читайте также: «Независимая система отопления «.</p>
                <p><strong>Однотрубная отопительная система</strong></p>
                <p>Однотрубное теплоснабжение многоквартирного дома имеет массу недостатков, главным среди которых являются значительные потери тепла в процессе транспортировки горячей воды. В данном контуре теплоноситель подают снизу вверх, после чего он попадает в батареи, отдает тепло и возвращается назад в ту же самую трубу. К конечным потребителям, проживающим на верхних этажах, прежде горячая вода доходит в еле теплом состоянии.</p>
                <p>Бывают случаи, когда однотрубную систему еще дополнительно упрощают, стараясь увеличить температуру теплоносителя в радиаторах. Для этого батарею врезают напрямую в трубу. В итоге, кажется, что радиатор является ее продолжением. Но от подобного подключения больше тепла получают только первые пользователи системы, а к последним потребителям вода доходит практически холодной (прочитайте также: «Система поквартирного отопления — характеристика «). Кроме этого однотрубное теплоснабжение многоквартирного дома делает невозможной регулировку радиаторов – после уменьшения подачи теплоносителя в отдельной батарее также снижается водоток по всей длине трубы.</p>
                <img src={img2} alt='' />
                <p>Еще одним недостатком такого теплоснабжения является невозможность замены радиатора в отопительный сезон без слива воды со всей системы. В подобных случаях необходима установка перемычек, благодаря чему появляется возможность отключить батарею, а теплоноситель направить по ним.</p>
                <p>Таким образом, с одной стороны в результате установки контура однотрубной отопительной системы получается экономия, а с другой – возникают серьезные проблемы относительно распределения тепла по квартирам. В них жильцы зимой мерзнут.</p>
                <p><strong>Двухтрубная отопительная система</strong></p>
                <p>Открытая и закрытая система отопления многоквартирного дома может быть двухтрубной (см. фото), позволяющей сохранять температуру теплоносителя в радиаторах, расположенных в квартирах на всех этажах. Устройство двухтрубного контура подразумевает, что остывшая в радиаторе горячая вода не попадает назад в ту же трубу. Она поступает в так называемую «обратку» или в возвратный канал. Читайте также: «Элеваторный узел системы отопления: что это такое «.</p>
                <p>Не имеет значения, каким образом подключена батарея – к трубе стояка или лежака, теплоноситель имеет постоянную температуру на всем пути его транспортировки по трубам подачи.</p>
                <p>Одним из важных преимуществ двухтрубных водяных контуров считается регулировка системы отопления многоквартирного дома на уровне каждой отдельной батареи путем установки на ней кранов с термостатом (прочитайте также: «Регулировка системы отопления — подробности из практики «). В результате в квартире обеспечивается автоматическое поддержание нужного температурного режима. В двухтрубном контуре доступно использование радиаторов отопления как с подключением нижним, так и с боковым. Также можно применять разное движение теплоносителя — тупиковое и попутное.</p>
                <p><strong>Горячее водоснабжение в системах отопления</strong></p>
                <p>ГВС в многоэтажных домах обычно является централизованным, при этом вода нагревается в котельных. Подключают горячее водоснабжение от контуров отопления, причем и от однотрубных, и от двухтрубных. Температура в кране с горячей водой по утрам бывает теплой или холодной, что зависит от количества магистральных труб. Если имеется однотрубное теплоснабжение многоквартирного дома высотой в 5 этажей, то при открытии горячего крана сначала в течение полминуты из него пойдет холодная вода.</p>
                <p>Причина кроется в том, что ночью редко кто из жильцов включает кран с горячим водоснабжением, и теплоноситель в трубах остывает. В результате наблюдается перерасход ненужной остывшей воды, поскольку она сливается напрямую в канализацию.</p>
                <img src={img3} alt='' className='img-width' />
                <p>В отличие от однотрубной системы в двухтрубном варианте циркуляция горячей воды происходит непрерывно, поэтому вышеописанной проблемы с ГВС там не возникает. Правда, в некоторых домах через систему горячего водоснабжения закольцовывают стояк с трубами – полотенцесушителями, которые даже в летнюю жару горячие.</p>
                <p>Многих потребители интересует проблема с ГВС после того, как завершился отопительный сезон. Иногда горячая вода пропадает на длительное время. Дело в том, что коммунальные службы обязаны соблюдать правила отопления многоквартирных домов, согласно которым необходимо производить постотопительные испытания систем теплоснабжения (прочитайте также: «Акт гидравлического испытания системы отопления и трубопроводов «). Такая работа не выполняется быстро, особенно если обнаружатся повреждения, которые нужно устранить.</p>
                <p>В летний период испытаниям подвергается вся система, обеспечивающая центральное отопление в многоквартирном доме. Коммунальные службы проводят текущие и капитальные ремонтные работы на теплотрассе, отключая при этом на ней отдельные участки. Накануне предстоящего отопительного сезона отремонтированная тепловая магистраль повторно подвергается испытаниям (подробнее: «Правила подготовки к отопительному сезону жилого дома «).</p>
                <p>Особенности подачи тепла в многоквартирном доме, детали на видео:</p>
                <p><strong>Радиаторы для систем отопления многоэтажек</strong></p>
                <p>Привычными для многих жильцов многоэтажных домов являются чугунные радиаторы, которые ранее использовались не один десяток лет. При необходимости заменить такую отопительную батарею ее демонтируют и устанавливают аналогичную, которую требует система отопления в многоквартирном доме. Такие радиаторы для централизованных отопительных систем считаются лучшим решением, поскольку они без проблем выдерживают достаточно высокое давление. В паспорте к чугунной батарее указываются две цифры: первая из них говорит о рабочем давлении, а вторая обозначает испытательную (опрессовочную) нагрузку. Обычно это значения — 6/15 или 8/15.</p>
                <p>Чем выше жилой дом, тем больше величина рабочего давления. В девятиэтажных зданиях оно достигает 6-ти атмосфер, таким образом, чугунные радиаторы для них подходят. Но когда это 22-этажный дом, то для рабочего функционирования централизованных систем отопления потребуется 15 атмосфер. В таком случае нужны стальные или биметаллические отопительные приборы.</p>
                <p>Специалисты не рекомендуют использовать при централизованном отоплении алюминиевые радиаторы — они не способны выдержать рабочего состояния водяного контура. Также профессионалы советуют владельцам недвижимости при проведении капитального ремонта в квартирах в случае замены батарей менять трубы развода теплоносителей на ½ или ¾ дюйма. Обычно они находятся в плохом состоянии и вместо них желательно ставить изделия экопласт.  У некоторых видов радиаторов (стальных и биметаллических) водотоки уже, чем у чугунных изделий, поэтому они забиваются и в дальнейшем теряют мощность. Поэтому в месте подачи теплоносителя в батарею следует установить фильтр, который обычно монтируют перед водомером.</p>

                <h2>Почему температура в квартире ниже нормы и что делать?</h2>
                <p>Если запуск системы отопления многоэтажного дома произошел, а батареи остаются холодными либо еле теплыми надо обращаться в коммунальную контору для установления причин неэффективного обогрева. Работники службы должны приехать, зафиксировать в акте отклонение температуры от установленной нормативом. В течение недели проблема должна быть решена. В противном случае жильцы имеют право обращаться в высшие инстанции.</p>
                <p>Частой причиной холодных батарей является воздушная пробка, образовавшаяся в системе.</p>
                <p>Если произошло отключение отопления в отопительный сезон, то скорей всего это временно, и связано с ремонтными работами. А, возможно, кто-нибудь из соседей решил поменять батарею или добавить новые секции, что привело к необходимости отключать отопление.</p>
                <p>В помещениях с центральным типом отопления чаще всего устанавливают чугунные радиаторы. Иногда в многоквартирном доме стояки отопления засоряются и необходима их промывка. Заниматься этим должны только профессионалы. Ведь для этого необходимы особые знания, навыки, опыт. Потребуется специальное гидропневматическое оборудование, смесь из воды и сжатого воздуха. Чистка обогревательных систем может выполняться и без промывки стояков. Эту процедуру хозяин квартиры может провести и самостоятельно. Правда занимает работа немало времени и требует демонтажа батарей. Все это позволяет повысить эффективность функционирования системы.</p>
                <p>Плохой обогрев может быть связан и с тем, что мощность батареи отопления низкая. Такая ситуация может быть вызвана особенностями монтажа оборудования. Например, если установить радиатор в нише, то его тепловая мощность станет в разы меньше. Если секций батареи недостаточно, то система также не будет способна отопить все помещение так, как требуется. Поэтому так важно устанавливать обогревательные агрегаты нужной мощности. Согласно нормам, мощность радиатора должна составлять 1 кВт на 10 кв.м.</p>
                <p>Некоторые жильцы для отопления используют электроэнергию. Конечно, на такое отопление дома цена достаточно высокая. Но данный способ обогрева является наиболее простым в эксплуатации, а также самым надежным. Электрическое отопление может быть чисто электрическим, водяным и комбинированным. Рассмотрим, сколько киловатт нужно для отопления дома с использованием электрического типа обогрева. Данная величина зависит от того, что планирует применять хозяин квартиры для отопления: электрический котел, конвектор либо систему «теплый пол».</p>
                <p>Электрокотел лучше выбирать трехфазный. Мощность оборудования бывает разной. Для определения необходимой мощности котла, надо площадь дома разделить на 10. Так, если площадь дома составляет 140 кв.м., потребуется котел, мощность которого 14 кВт. Для экономии можно устанавливать двухтарифный режим использования электрической энергии. Для конвекторов расчет проводится по аналогичной схеме.</p>
                <p>Система «теплый пол» — самый удобный вариант обогрева. Поскольку для каждой комнаты можно устанавливать определенную температуру. Для дома общей площадью в 90 кв.м. расход электроэнергии составит от 5,5 до 9 кВт.</p>
                <p>Таким образом, существуют установленные законом нормы температуры в квартирах. И если данные нормы не соблюдаются, системы отопления многоквартирного дома недостаточно обогревают помещение, жильцы дома имеют право обратиться в ЖЭК для выявления причины сложившейся ситуации и потребовать улучшения качества теплоснабжения.</p>
                <p>Отопление жилых зданий Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HeatingResidentialBuildings