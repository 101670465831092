import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../avtomationPages.css'

import img1 from '../../../img/28-05-2014 15-38-44.jpg'
import img2 from '../../../img/28-05-2014 15-37-17.jpg'

const AutomationVentilationConditioning = () => {
    return (
        <>
            <div className='avtomation-pages'>
                <h1>Система автоматики промышленной вентиляциии кондиционирования</h1>
                <p>Автоматику промышленной системы вентиляции и кондиционирования Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>В современных требованиях к автоматизированным системам вентиляции (СВ) и кондиционирования воздуха (СКВ) содержится два противоречивых условия:</p>
                <ul>
                    <li>первое - простота и надежность эксплуатации,</li>
                    <li>второе - высокое качество функционирования.</li>
                </ul>
                <p>Основным принципом в технической организации автоматического управления CВ и СКВ является функциональное оформление иерархической структуры подлежащих выполнению задач защиты, регулирования и управления.</p>

                <h2>Система автоматики промышленной вентиляции</h2>
                <ul>
                    <li>Первый уровень автоматизации СКВ. Всякая промышленная СКВ должна быть снабжена элементами и устройствами автоматического пуска и останова, а также устройствами защиты от аварийных ситуаций.</li>
                    <li>Второй уровень автоматизации СКВ - уровень стабилизации режимов работы оборудования.</li>
                    <li>Третий уровень автоматизации СКВ. Решение задач данного уровня управления связано с обработкой информации и формированием управляющих воздействий путем решения дискретных логических функций или проведения ряда определенных вычислений.</li>
                </ul>
                <img src={img1} alt='' className='img-width' />
                <p>Трехуровневая структура технической реализации управления и регулирования работой СКВ позволяет осуществить организацию эксплуатации систем в зависимости от специфики предприятия и его служб эксплуатации. Регулирование систем кондиционирования воздуха основано на анализе стационарных и нестационарных тепловых процессов. Дальнейшая задача состоит в автоматизации принятой технологической схемы управления СКВ, которая автоматически обеспечит заданный режим работы и регулирования отдельных элементов и системы в целом оптимальном режиме.</p>
                <p>Реальное или совокупное поддержание заданных режимов работы СКВ проводятся приборами и устройствами автоматики, образующими как простые локальные контуры регулирования, так и сложные многоконтурные системы автоматического регулирования (САР). Качество работы СКВ определяется главным образом соответствием создаваемых параметров микроклимата в помещениях здания или сооружения их требуемым значениям и зависит от правильности выбора как технологической схемы и ее оборудования, так и элементов системы автоматического управления этой схемы.</p>
                <p><strong>Ко всем установкам предлагается широкий ряд элементов автоматики: </strong></p>
                <ul>
                    <li>блоки управления различным типом установок; </li>
                    <li>устройства управления и защиты (защитные реле, устройства плавного пуска, щиты управления вентиляторами, щиты управления воздушными завесами с водяным и электрическим нагревом, устройство дистанционного управления); </li>
                    <li>регуляторы оборотов (электронные, трансформаторные регуляторы, частотные преобразователи); </li>
                    <li>датчики температуры, датчики давления, термостаты, гидростат, датчик качества воздуха; </li>
                    <li>приводы воздушных заслонок;</li>
                    <li>смесительные узлы;</li>
                    <li>трёхходовые клапаны и приводы трёхходовых клапанов;</li>
                    <li>циркуляционные насосы.</li>
                </ul>
                <img src={img2} alt='' />
                <p>Автоматику промышленной системы вентиляции и кондиционирования Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default AutomationVentilationConditioning