import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img4 from '../../../img/42271d0a1d185d0b5d0bcd0b0-d0bcd0b5d185d0b0d0bdd0b8d187d0b5d181d0bad0bed0b9-d0bfd180d0b8d182d0bed187d0bdd0be-d0b2d18bd182d18fd0b6d0bdd0bed0b9-d0b2d0b5d0bdd182d0b8d0bbd18fd186d0b8d0b8.jpg'

const WarehouseVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Система вентиляции для склада</h1>
                <p>Вентиляцию склада Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции для складов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Вентиляция склада представляет собой комплекс устройств и систем, служащих для организации качественного воздухообмена внутри помещения, главная цель которых — обеспечить такие макроклиматические условия, которые отвечают технологическим и санитарно-гигиеническим нормативам.</p>
                <p>Вентиляция складских помещений нацелена на выполнение важной задачи — создание оптимальной среды для сохранности продукции и материальных ценностей, находящихся внутри. Соблюдение качественных условий хранения и сроков годности продукции можно обеспечить, правильно подобрав необходимую температуру, показатели относительной влажности и подвижности воздушных масс.</p>

                <h2>Естественная и принудительная вентиляция склада</h2>
                <p>Если используется естественная вентиляция, то более холодный воздух с улицы через заборные отверстия поступает в помещения, а теплый воздух уходит через вытяжные отверстия. Приточные отверстия делают на расстоянии 0,3-1,8 метра от земли. Но при этом нужно оградить их от заметания снегом.</p>
                <p>Естественная вентиляция складов – как правило, применяется при хранении продукции, сохранность которой не зависит (мало зависит) от температуры окружающей среды. В этом случае отопления склада, как правило, не требуется.</p>
                <p>В случаях, когда склад отапливается (до +10..11С), применение естественной вентиляции так же распространено — как самый простой и дешевый способ нормативного воздухообмена.</p>
                <p>Принудительная вентиляция склада применяется в случае проектирования и строительства склада с требуемыми климатическими условиями (температура, влажность). К примеру, склад пищевой продукции (нескоропортящейся) – в любом случае будет оснащен, кроме отопления, принудительной вентиляцией.</p>
                <p>Логистические центры – в подавляющем большинстве имеют вентилируемые и отапливаемые складские площади с изменяемыми параметрами внутреннего микроклимата.</p>
                <p>Монтаж принудительной вентиляции требует затрат на покупку оборудования, в процессе использования возникают дополнительные затраты (электрическая и тепловая энергия, эксплуатационные расходы). Размещать вентиляционное оборудование нужно в соответствии с проектной документацией.</p>
                <p>В случае пожара вентиляция отключается, включаются вентиляторы дымоудаления и подпора воздуха. Подробнее о дымоудалении мы напишем в одной из наших следующих статей.</p>

                <h2>Принцип создания перепадов давления</h2>
                <p>При естественной вентиляции, называемой аэрацией, перемещение воздуха происходит под давлением, которое возникает из-за разности температур внутри и вне помещения, в результате воздействия ветра на здание или при одновременном действии обеих причин. Аэрация не применяется в случаях, когда необходима предварительная обработка приточного воздуха или когда он вызывает туман или конденсат в помещении.</p>
                <p>А длина участков воздуховодов горизонтального типа  – не больше 3 метров, скорость в них воздуха в них – не выше 1 м/с. Естественная вентиляция проста в применении и не требует использования дорогостоящего оборудования. Однако её зависимость от множества переменных факторов, таких как: температура, направление и влажность воздуха, ветер, давление, делает аэрацию неспособной создать определённые условия на складе.</p>
                <img src={img4} alt='' />
                <p>
                    <i>Схема механической приточно-вытяжной вентиляции: 1 — воздухозаборная шахта; 2 — пылеосадочная камера; 3 — масляный фильтр; 4 — калориферы; 5 — увлажнительные сопла; 6 — каплеотделитель; 7 — вентилятор; 8 — приточные каналы; 9 — вытяжной вентилятор; 10 — вытяжные каналы; 11 — вытяжная камера; 12 — приточная камера.</i>
                </p>
                <p>Механическая система вентиляции использует специальное оборудование:</p>
                <ul>
                    <li>фильтры;</li>
                    <li>вентиляторы;</li>
                    <li>пылеуловители;</li>
                    <li>шумоглушители;</li>
                    <li>электродвигатели;</li>
                    <li>воздухонагреватели;</li>
                    <li>вентиляционные установки.</li>
                </ul>
                <p>И позволяет перемещать воздух на нужное расстояние, удалять воздух и подавать его независимо от окружающей среды. При этом воздух может подвергаться дополнительной обработке – очистке, увлажнению, нагреванию. Такая обработка невозможна при аэрации. Воздух в системах такой вентиляции перемешается с помощью электрических вентиляторов. Наибольшим спросом пользуются центробежные, которые ещё называют радиальными и осевые. Осевые вентиляторы создают небольшое давление. А радиальные делятся на три категории: низкого, среднего и высокого давления. На складах используют преимущественно вентиляторы с низким давлением. На практике чаще всего используют смешанные системы, то есть механическую и естественную одновременно.</p>

                <h2>Факторы, влияющие на выбор вентиляции</h2>
                <p>В приоритете каждой организации извлечь максимальную выгоду при вводе в строй вентиляционной системы склада. Однако многие нормативы и стандарты заставляют заказчика балансировать между приоритетами и правилами. Если учитывать, что вентсистема разрабатывается в установленных пределах этих правил, то для заказчика в приоритете решение следующих задач:</p>
                <ul>
                    <li>Эффективность. Наибольшая пропускная способность вентиляции способствует эффективному воздухообмену при минимальных затратах ресурсов на производство.</li>
                    <li>Долговечность. Удачный подбор архитектуры вентсистемы, на которую будет оказываться низкое влияние от вредных веществ и деятельности складского помещения, позволит обеспечить долговечность сооружения.</li>
                    <li>Энергопотребление. Если для вентшахты отводится лишь ограниченная роль в обеспечении воздухообмена, важно правильно подобрать тепловое оборудование. Система очищения воздуха, реализуемая с внедрением электрообогревателей, при должном подходе потребует минимального электроснабжения.</li>
                    <li>Смета расходов. Наиболее важный аргумент, из которого исходит любой заказчик. В проектировании вентиляции профессиональные инженеры разрабатывают надежное сооружение, которое потребует минимальных материальных и трудовых ресурсов.</li>
                </ul>
                <p>В редких случаях, когда бюджет не позволяет или сооружение должной вентиляции не оправдывает затраты, заказчик рассматривает вопрос о перепланировке/переоборудовании/модернизации самого склада.</p>

                <h2>Вентиляция общетоварного склада</h2>
                <p>На складе этого типа может быть вполне достаточно естественной циркуляции воздуха – в соответствии с техническим заданием на проектирование.</p>
                <p>Если для хранения продукции нужные условия таким способом не могут быть получены, то не обойтись без вентиляции принудительного типа. При этом:</p>
                <ul>
                    <li>Оборудование системы должно находиться в специально отведенных камерах (венткамерах), которые защищают огнестойкими преградами с пределом огнестойкости в соответствии с проектной документацией.</li>
                    <li>Венткамеры для автоматики и вентиляционного оборудования снабжаются отдельным входом снаружи склада.</li>
                </ul>
                <p>Если общетоварный склад находится в климатическом поясе, где расчётная температура зимой опускается ниже минус 15оС, то для ворот предусматриваются воздушные завесы – необходимость воздушно-тепловых завес определяется проектной документацией. Это делают для того, чтобы при погрузке и разгрузке холодный воздух с улицы не попадал внутрь помещения.</p>

                <h2>Вентиляция продуктового склада</h2>
                <p>Продуктовые склады условно можно разделить на несколько подтипов:</p>
                <ul>
                    <li>сухую сыпучую продукцию;</li>
                    <li>фрукты и овощи;</li>
                    <li>консервы (бакалея).</li>
                </ul>
                <p>Главный параметр для хранения пищевых продуктов – температура и влажность. Она должны быть не выше плюс 15оС, либо поддерживаться на том уровне, который требуется по условиям хранения. Значит, проект отопления и вентиляции данных складов выполняется в соответствии с техническим заданием.</p>
                <p>Если же на складе хранится сыпучая продукция – то, с целью уменьшения ее температуры и выравнивания влажности, то условия регламентируются Приказом от 24 июня 1988 г. N 185 об утверждении инструкции по хранению зерна, семян, муки, крупы; также условия хранения регламентируются иными нормативами, в соответствии с которыми будет выполнена проектная документация.</p>
                <p>К примеру, если влажность риса достигает 13%, а относительная влажность наружного воздуха составляет 55%, то дальнейшая просушка крупы запрещается. Если продолжить просушку, на выходе получится рис с трещинами.</p>
                <p>Фрукты и овощи должны храниться при температуре 1-2 оC. В больших количествах они выделяют очень много влаги. Поэтому при проектировании системы вентиляции должны быть предусмотрены данные условия (для овощехранилища), также как и все условия хранения овощей, прописываются в Техническом задании на проектирование.</p>
                <h2>Вентиляция склада с алкогольной продукцией</h2>
                <p>Основное требования для склада – механическая приточно вытяжная вентиляция с кратностью воздухообмена 8. Это означает, что за один час весь объем воздуха на складе должен смениться восемь раз.</p>
                <p>Есть одна особенность – при хранении шампанского и игристых вин, температура воздуха должна быть 16оС. Поэтому на таких складах кроме вентиляционной системы должна быть предусмотрены кондиционеры для охлаждения воздуха летом. Такие же условия хранения должны быть на складах с медовухой, сидором и пивом.</p>
                <p>Основные документы, в которых содержаться требования к складам алкогольной продукции:</p>
                <ul>
                    <li>ВНТП 35-93;</li>
                    <li>ВНТП 04-94;</li>
                </ul>
                <p>Все особенности должны быть указаны в проектной документации и выполнены в соответствии с ней (в разделе ОВиК).</p>

                <h2>Вентиляция фармацевтического склада</h2>
                <p>Хранение лекарственных препаратов требует оборудования склада приточно-вытяжной системой вентиляции. Как правило, каждому лекарственному средству соответствуют свои условия хранения, которые зависят от производителя и сырья.</p>
                <p>Согласно Пособию по проектированию учреждений здравоохранения, температура воздуха должна быть 18оС. Соответственно должны быть предусмотрены системы отопления и кондиционирования.</p>
                <p>Кратность воздуха также зависит от хранящейся продукции и прописана в Пособии по проектированию учреждений здравоохранения. К примеру, если на складе хранится растительное сырье, то показатель – 4, ядовитых препаратов и наркотических средств – 4 и т. д.</p>
                <p>Вентиляция складов фармацевтической промышленности требует серьезной проработки проектной документации по вентиляции и отоплению, как в части Технического задания, так и в части исполнения собственно раздела ОВиК (применяемого оборудования, требований и т.п.).</p>
                <p>Вентиляцию склада Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции для складов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default WarehouseVentilation