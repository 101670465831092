import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img13 from '../../../img/137914485_ventilyaciya.jpg'

const ModernizationReconstructionVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Модернизация и реконструкция вентиляции</h1>
                <p>Модернизацию (реконструкцию) системы вентиляции Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Проблема роста цен на электроэнергию выводит стремление к эффективному энергосбережению на передний план: системы вентиляции и кондиционирования затрачивают довольно серьезные энергоресурсы. Кроме того, такие системы на большинстве предприятий давно морально устарели, изношены и не обеспечивают необходимые режимы работы. Полная или частичная модернизация оборудования ставит владельцев перед серьезными задачами, решить которые под силу только специалистам. К числу таких задач относятся: ограниченное место для установки систем вентиляции, сложность монтажа в условиях существующего объекта, проектная работа, автоматизация систем вентиляции и кондиционирования в целях минимизации эксплуатационных затрат и увеличения срока полезного использования таких систем.</p>

                <h2>Причины для модернизации и реконструкции</h2>
                <p>Основными показаниями для модернизации оборудования являются:</p>
                <ul>
                    <li>Износ оборудования;</li>
                    <li>Оборудование морально устарело;</li>
                    <li>Изменение параметров помещения (увеличение или уменьшение производственных площадей);</li>
                    <li>Перепланировка производственных помещений;</li>
                    <li>Увеличение или снижение списочной численности персонала;</li>
                    <li>Изменение условий работы;</li>
                    <li>Действующее оборудование не соответствует нормам действующего законодательства;</li>
                </ul>
                <p>Вентиляционное оборудование и системы кондиционирования могут быть заменены полностью или подлежать частичной реконструкции. В первом случае причинами могут быть износ, поломка или устаревание. Во втором случае проводится обследование системы на возможные поломки и несоответствия, в ходе тестирования определяется, подлежит ли система ремонту, наладке или частичной замене составляющих.</p>
                <img src={img13} alt='' className='img-width' />

                <h2>Этапы реконструкции</h2>
                <ol>
                    <li>Полная оценка рабочего состояния оборудования. Анализ причин, которые вызвали отказы, сбои в работе и поломки оборудования.</li>
                    <li>Составление списка оборудования и частей, подлежащих обновлению, согласование данного перечня с клиентом. В случае необходимости комплекса работ по реконструкции вентиляции и системы кондиционирования разрабатывается и составляется проект реконструкционных работ.</li>
                    <li>Работы по монтажу оборудования. При необходимости – устанавливаются автоматизированные системы управления.</li>
                    <li>Пуско-наладочные мероприятия.</li>
                    <li>Сдача систем кондиционирования и вентиляции в эксплуатацию, передача объекта клиенту.</li>
                </ol>
                <p><strong>В результате проведенных работ заказчик имеет:</strong></p>
                <ul>
                    <li>Комплексно модернизированные системы кондиционирования воздуха и вентиляции;</li>
                    <li>Экономия энергетических ресурсов в течение каждого месяца. Работа современных систем управления дает возможность на автоматике производить контроль за режимом работы всех установок, контролировать температуру, расход, уровень давления и обеспечивать нормальный микроклимат в помещениях;</li>
                    <li>Экономия временного ресурса и собственных средств на проведение ремонта. После многолетнего использования оборудование начинает приходить в негодность. Полная оценка и комплексный подход к замене изношенных частей способствует в несколько раз увеличить межремонтные промежутки.</li>
                </ul>
                <p>Наша компания уже длительный период времени специализируется на проведении реконструкции и модернизации вентиляционных систем и кондиционирования разных типов. Персонал нашей компании имеет достаточно много навыков и умений по организации и проведению модернизационных работ. Все проводимые мероприятия согласованы в законодательном порядке, оговорены и утверждены с клиентами и заинтересованными компаниями, исполнение - качественное и продуктивное.</p>
                <p>Реконструкция систем вентиляции и кондиционирования является трудоемкой и сложной процедурой, которая требует специальных знаний, опыта, внимательности, грамотного ведения расчетов с целью выявления уровня потребности, нагрузки, местонахождения объектов и прочих факторов, влияющих на эффективную работу оборудования. Реконструкция систем вентиляции и кондиционирования - подразумевает не только замену устаревших частей, но и монтаж контроллеров и устройства регуляторов, которые поддерживают стабильный уровень температуры и давления теплоносителя. Для этих целей можно использовать как импортное оборудование, так и отечественного производства в зависимости от пожеланий клиента, его бюджета и тех.условий.</p>
                <p><strong>При модернизации вентиляционных систем и кондиционирования проводят проверку следующих элементов и систем (чтобы выявить необходимость замены или ремонта):</strong></p>
                <ul>
                    <li>Вентиляторы и воздухонагревательные устройства;</li>
                    <li>Насосное оборудование;</li>
                    <li>Арматура регулирующего типа и запорная холодо- и теплоносителей;</li>
                    <li>Клапаны регулировки подачи воздуха и обратного действия;</li>
                    <li>Грязевики и фильтрационное оборудование;</li>
                    <li>Электроустройство;</li>
                    <li>Приборы автоматики и КИПиА, а также прочие элементы.</li>
                </ul>

                <h2>Примеры модернизации</h2>
                <p><strong>Пример модернизации приточно-вытяжной установки не медицинских помещений.</strong></p>
                <p>В зимний период уличный воздух подаваемый в здание должен успеть нагреться примерно на 60 градусов( с -38 до + 22 градусов), моментально, потратив огромное количество тепловой энергии и буквально через 20-30 минут этот нагретый, очищенный воздух вылетает на улицу. Установив систему рециркуляции и контрольную автоматику вы получите снижение затрат на 70%-90%, не потеряв в качестве воздуха. Согласитесь,  экономия в 7-9  раз это существенно.</p>
                <p><strong>Пример модернизации приточно-вытяжной установки медицинских помещений.</strong></p>
                <p>Существуют такое оборудование как рекуператор, принцип которого основан на передаче тепла от вытяжного воздуха уличному воздуху через многочисленные металлические пластины. Смешение воздуха при этом практически не происходит. Бывают пластинчатые рекуператоры – снижение затрат до 45-50% и роторные рекуператоры снижение затрат до 60-70%.</p>
                <p>Относительно недорогим и эффективным решением является использование гликолевых рекуператоров, принцип работы которых заключается в следующем: вытяжной теплый воздух проходит через дополнительно установленный медно-алюминиевый теплообменник, отдавая при этом свое тепло незамерзающей жидкости, которая в свою очередь передает тепло на другой теплообменник, установленный уже на притоке, подогревая тем самым холодный воздух. Гликолевые рекуператоры обеспечивают снижение затрат до 30-40%.</p>
                <p><strong>Пример модернизации системы вентиляции конференц- зала.</strong></p>
                <p>Системы вентиляции конференц- зала рассчитываются на нормативную кратность воздухообмена при максимальной наполненности  зала. А ведь не всегда зал заполнен на максимум, чаще совсем простаивает, а киловатты тепла и электричества расходуются на максимум. Достаточно всего лишь установить в зале датчик, контролирующий количество выдыхаемого людьми углекислого газа, установить и настроить автоматику и системы вентиляции будут подавать в зал столько воздуха сколько необходимо для находящихся в нем людей, при этом сохраняя комфортные условия. При отсутствии людей можно настроить работу на 10%-20% от номинальной мощности или для поддержания температурного режима. Снижение затрат на эксплуатацию составит 60%-80%.</p>

                <h2>Просто факты</h2>
                <p>Интересным решением является использование температуры подземных вод для охлаждения здания. Температура подземной воды и зимой и летом около + 9 градусов, можно пропустить ее через теплообменники вентиляции или отопления, охладить здание, а воду использовать для полива газонов, деревьев либо просто вернуть в землю. Просто и эффективно.</p>
                <p>Всего лишь простая перестановка управляющего датчика с  притока на вытяжку даст снижение расходов на 5-10%.</p>
                <p>Утепление и герметизация магистральных воздуховодов позволит снизить расходы на 30-40%.</p>
                <p>При проектировании нормы воздуха колеблются от 20 до 60 м3/ч на 1 человека. Обычно подбирают оборудование на максимум. В результате эксплуатационные расходы почти в три выше необходимых.</p>
                <p>Всего лишь установка в воздуховоды бактерицидных ультрафиолетовых фильтров позволит всем сотрудникам болеть в разы меньше, своевременная чистка и замена воздушных фильтров обеспечит всех чистым воздухом, своевременное и грамотное техническое обслуживание продлит в несколько раз ресурс оборудования, позволит работать без аварий и остановок. Недаром говорят: «Болезнь легче предупредить, чем лечить».</p>
                <p>Модернизацию (реконструкцию) системы вентиляции Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ModernizationReconstructionVentilation