import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './warehouseAirConditioning.css'

import s2150097d5564a7893301c8ec79501808885dfb5df3c2a7 from '../../../img/2150097d5564a7893301c8ec79501808885dfb5df3c2a7.jpg'

const WarehouseAirConditioning = () => {
    return (
        <>
            <div className='warehouse-air-conditioning'>
                <h1>Системы кондиционирования склада</h1>
                <p>Систему кондиционирования для склада Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования складов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Поддержание определенного микроклимата в складе – это залог сохранности продукции. Создание комфортной температуры и достаточного воздухообмена важно и для нормальной работы обслуживающего персонала. Для этого организуют кондиционирование воздуха в складских помещениях.</p>
                <p>Под «кондиционированием воздуха» принято понимать искусственное создание и дальнейшее сохранение в помещении оптимальных показателей микроклимата, таких как температура, влажность, экологические параметры и степень движения воздушных масс. Достижение и поддержание вышеописанных параметров осуществляется посредством системы специальных технических средств, которые называют – системы кондиционирования воздуха (СКВ). Системы кондиционирования воздуха отвечают за очистку, нагрев, охлаждение, увлажнение или удаление излишней влаги из воздуха и состоит из отдельных типовых секций, герметично соединенных между собой, каждая секция выполняет определенную функцию: охлаждение, нагрев, вентиляция, фильтрация, увлажнение, рекуперация и т.д. В зависимости от объема воздуха, обрабатываемого кондиционером, меняется размер секций (блоков).</p>

                <h2>Требования к системам промышленного кондиционирования</h2>
                <p>Склады – это всегда помещения большого объема. Для кондиционирования воздуха в них используют целый комплекс технических устройств. Необходимы аппараты для очистки, нагрева, охлаждения, увлажнения или осушения, принудительного движения потоков. Монтаж воздуховодов и оборудования производится в строгом соответствии с действующими нормами. Регламенты прописаны в нескольких документах:</p>
                <ul>
                    <li>СНиП 41-01-2003 «Отопление, вентиляция и кондиционирование»;</li>
                    <li>СНиП 23-01-99 «Строительная климатология»;</li>
                    <li>СНиП 23-02-2003 «Тепловая защита зданий»;</li>
                    <li>СНиП 21-01-97 «Пожарная безопасность зданий и сооружений»;</li>
                    <li>СНиП 31-04-2001 «Складские здания»;</li>
                    <li>ГОСТ 12.1.005-88 «Общие санитарно-гигиенические требования к воздуху рабочей зоны».</li>
                </ul>
                <p>Кондиционирование устраивается, если общеобменная вентиляционная система не способна обеспечить заданные параметры хранения грузов. Техническое обоснование приводится как часть проекта. Дополнительно к системе кондиционирования устанавливают тепловые завесы в проемах ворот, предусматривают современные системы контроля. Автоматика способна поддерживать температуру, оптимальную для хранения груза, вне зависимости от погоды на улице.</p>

                <h2>Выбор систем кондицинирования</h2>
                <p>Для выбора оптимального варианта СКВ в складских помещениях необходимо провести технико-экономический анализ нескольких возможных вариантов (двух-трех и более). Для этого всесторонне рассматривают и оценивают объект по предъявляемым к нему требованиям, основными из которых являются санитарные, технологические, строительные, противопожарные, эксплуатационные, экономические. Особенно важны требования к надежности оборудования при точном поддержании микроклиматических параметров различных технологических процессов хранения.</p>
                <p>Санитарные требования:</p>
                <ul>
                    <li>поддержание определенной температуры или температуры и влажности;</li>
                    <li>подача в помещение свежего воздуха (естественным или механическим путем) или использование рециркуляционных систем;</li>
                    <li>удаление воздуха через местные отсосы или общеобменной вытяжкой.</li>
                </ul>
                <p>Строительные требования:</p>
                <ul>
                    <li>возможность установки наружного кондиционера на фасаде здания центрального кондиционера, например на техническом этаже, и т. д.;</li>
                    <li>возможность прокладки по зданию или помещению коммуникаций – воздуховодов, трубопроводов (особенно в реконструируемых зданиях);</li>
                </ul>
                <p>Противопожарные требования:</p>
                <ul>
                    <li>устанавливаются исходя из категории помещений (нормальные условия – помещения категории Д, пожароопасные – категории В или взрывоопасные – категорий А и Б и соответствующих этим категориям проектных решений (установка обратных и огнезадерживащих клапанов, раздельная установка блоков оборудования, разные схемы прокладки коммуникаций).</li>
                </ul>
                <p>Эксплуатационные требования:</p>
                <ul>
                    <li>допустимость обслуживания и управления системой с центрального пульта управления либо автономное управление (регулировка параметров вручную).</li>
                </ul>
                <p>Экономические требования:</p>
                <ul>
                    <li>оптимизация цены путем сравнения в проекте оборудования разных производителей и разных классов;</li>
                    <li>разработка для объекта нескольких принципиальных вариантов систем на базе различных типов оборудования и проведение их сравнительной оценки.</li>
                </ul>
                <p>Технологические требования:</p>
                <ul>
                    <li>создание и автоматическое поддержание в складских помещениях оптимальных параметров микроклимата.</li>
                </ul>

                <h2>Виды систем кондиционирования воздуха</h2>
                <p>Схемы составляются индивидуально для каждого складского помещения, поэтому классифицировать их однозначно очень сложно. Вариантов огромное количество. Системы кондиционирования воздуха можно классифицировать по назначению помещения:</p>
                <ul>
                    <li>комфортные. Оборудование предназначено для создания и поддержки оптимального температурно-влажностного режима, предусмотренного санитарно-гигиеническими требованиями;</li>
                    <li>технологические. Система создает микроклимат, соответствующий технологии хранения.</li>
                </ul>
                <p>По принципу размещения:</p>
                <ul>
                    <li>местные. Оборудование монтируют непосредственно в складском помещении, другие зоны оно не обслуживает;</li>
                    <li>центральные. Системы большой производительности, которые могут обеспечить воздухообмен до 250000 м3/ч. Они обслуживают все здание склада.</li>
                </ul>
                <p>По наличию теплообменников:</p>
                <ul>
                    <li>автономные. Кондиционер имеет собственную систему тепло- или холодоснабжения;</li>
                    <li>не автономные. Генераторы тепла и холода являются общими для всего объекта, централизованными. От них по разветвленным трубопроводам тепло- или хладоноситель поступает к местным кондиционерам.</li>
                </ul>
                <p>По принципу действия:</p>
                <ul>
                    <li>прямоточные. Кондиционеры работают на забор воздуха с улицы. Он проходит систему фильтрации, подогрев/охлаждение и затем поступает в помещение;</li>
                    <li>рециркуляционные. Системы могут работать без притока воздуха снаружи или на частичном притоке;</li>
                    <li>комбинированные. В зависимости от требований к параметрам среды в помещении кондиционер работает с уличным воздухом или осуществляет рециркуляцию.</li>
                </ul>
                <img src={s2150097d5564a7893301c8ec79501808885dfb5df3c2a7} alt='' className='img-width' />
                <p>По способу регулировки:</p>
                <ul>
                    <li>качественное управление (однотрубное);</li>
                    <li>количественное управление (двухтрубное).</li>
                </ul>
                <p>По степени обеспечения метеорологических параметров:</p>
                <ul>
                    <li>системы первого класса. Кондиционер поддерживает температуру и влажность, регламентированные технологией хранения;</li>
                    <li>системы второго класса. Оборудование обеспечивает микроклимат, соответствующий санитарно-техническим или технологическим нормам;</li>
                    <li>системы третьего класса. Кондиционер поддерживает параметры на уровне допустимых, когда обычная вентиляция не справляется с этой задачей. Например, в летний период оборудование включают для охлаждения приточного воздуха.</li>
                </ul>
                <p>По числу обслуживаемых помещений:</p>
                <ul>
                    <li>однозональные. Мощные кондиционеры, предназначенные для обслуживания одного помещения большой площади. При этом склад должен характеризоваться равномерным испарение и распределением тепла;</li>
                    <li>многозональные. Кондиционеры обслуживают большие помещения со сложным технологическим оборудованием, когда выделение тепла и влаги происходит не равномерно по всей площади.</li>
                </ul>
                <p>По давлению, нагнетаемому вентиляторами:</p>
                <ul>
                    <li>системы низкого давления. Показатель до 100 кгс/м2;</li>
                    <li>системы среднего давления. Показатель 100-300 кгс/м2;</li>
                    <li>системы высокого давления. Показатель 300 кгс/м2 и более.</li>
                </ul>
                <p>Наиболее востребованными являются централизованные не автономные кондиционеры. Это комплексное оборудование, состоящее из стандартных секций. В каждом из модулей производится определенная операция:</p>
                <ul>
                    <li>принудительное движение воздуха;</li>
                    <li>охлаждение или нагрев;</li>
                    <li>фильтрация;</li>
                    <li>увлажнение;</li>
                    <li>шумопоглощение;</li>
                    <li>утилизация тепла.</li>
                </ul>
                <p>Секции герметично соединены в единую систему и заключены в корпус. Унифицированный размер позволяет добавлять или убирать блоки при необходимости. Автоматические системы контроля проводят периодическое тестирование и самодиагностику системы. С помощью чувствительных датчиков своевременно обнаруживается аварийная ситуация, производится остановка и запуск оборудования.</p>

                <h2>Особенности каждого вида</h2>
                <p>Крышные кондиционеры – блок, в конпусе которого расположены сексии охлаждения. Теплый воздух попадает в эту систему, после чего поступает на склад уже охлажденным.</p>
                <p>Плюсы:</p>
                <ul>
                    <li>низкая стоимость системы;</li>
                    <li>не нужно устанавливать воздуховод;</li>
                    <li>система проста в эксплуатации.</li>
                </ul>
                <p>Минусы:</p>
                <ul>
                    <li>не везде можно установить такие системы. Обычно они устанавливаются на крышах, но некоторые конструкции попросту не в состоянии удержать систему кондиционирования.</li>
                </ul>
                <p>Чиллер-фанкойл оснащен насосами и баком-аккумулятором, таким образом воздух охлаждается с помощью холодной воды.</p>
                <p>Плюсы:</p>
                <ul>
                    <li>система охлаждает воздух в складе любого размера;</li>
                    <li>не нужно использовать кровлю.</li>
                </ul>
                <p>Минусы:</p>
                <ul>
                    <li>высокая стоимость;</li>
                    <li>сложная конструкция.</li>
                </ul>
                <p>VRF-система работает без перерывов в отличии от других систем кондиционирования, которые поддерживают уровень влажности и температуры в помещении благодаря включению и отключению прибора. Есть датчик, который реагирует на температуру окружающей среды.</p>
                <p>Плюсы:</p>
                <ol>
                    <li>Одного прибора достаточно для охлаждения всего склада.</li>
                    <li>Экономия электроэнергии по сравнению с другими кондиционерами.</li>
                    <li>Установить прибор можно в любом месте склада.</li>
                    <li>Возможно автоматическое управление системой и ее контроль из одного места.</li>
                </ol>
                <p>Особой популярностью пользуются централизованные системы, что объясняется их свойством осуществлять кондиционирование воздуха в условиях больших площадей. Что представляет собой подобная система? Комплект отдельных модулей, которые устанавливаются с учетом особенностей помещения. Это позволяет установить необходимый микроклимат. Работа кондиционерной системы не повлияет на температурный баланс среды, поскольку такие системы поглощают холод и тепло извне.</p>
                <p>Автономным системам кондиционирования отдают предпочтение тогда, когда, согласно проекту, склад будет осуществлять хранение товаров с разными свойствами. Поэтому здесь имеет большое значение правильное планирование и расчет баланса климата в разных частях склада.</p>

                <h2>Сырьевые склады для хранения непищевой продукции (в т. ч. склады для хранения стройматериалов)</h2>
                <p>Представляют собой достаточно простой случай с технической точки зрения. Как правило, температурные нормы хранения таких материалов не отличаются большой жесткостью, но есть определенные требования к уровню влажности. Обычно такие помещения имеют большую площадь, поэтому роль кондиционеров для них играет система естественной вентиляции в виде вытяжных шахт, а обогрев осуществляется при помощи горячих труб системы отопления и водопровода. Вместе с тем, если в помещениях хранится дорогостоящая бытовая техника и чувствительные к перепадам температурно-влажностного режима строительные материалы (обои, сухие строительные смеси и т. п.) есть повод задуматься об установке кондиционеров потолочного или кассетного типа.  </p>

                <h2>Складские помещения для хранения продукции металлообработки и инструментов</h2>
                <p>Основная задача, которая стоит перед системой кондиционирования таких помещений, – поддержание соответствующего уровня влажности, препятствующего коррозии металла. Поэтому, если для поддержания такого микроклимата недостаточно естественной приточно-вытяжной системы вентиляции, в складских помещениях дополнительно устанавливаются промышленные или полупромышленные потолочные кассетные или канальные системы вентиляции.</p>

                <h2>Складские помещения для хранения пищевых продуктов и высокоточного оборудования и приборов</h2>
                <p>Наиболее требовательный с точки зрения установления оптимального микроклимата тип складских помещений,  для которого установка системы кондиционирования является обязательной, поскольку ни одна система естественной вентиляции не сможет обеспечить поддержание установленных параметров температурного и влажностного режима. Такие складские помещения, как правило, требуют постоянного присутствия персонала, поэтому требования СНИПов и ГОСТов к микроклимату таких помещений более жесткие, как и требования, выдвигаемые производителями таких ТМЦ. Разработку проектов систем вентиляции и кондиционирования этих помещений целесообразнее всего осуществлять в индивидуальном порядке, а в качестве климатического оборудования в таких проектах могут выступать любые типы промышленных и полупромышленных кондиционеров, чьи технические и функциональные возможности отвечают заданным параметрам. </p>
                <p>Систему кондиционирования для склада Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования складов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default WarehouseAirConditioning