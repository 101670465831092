import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/234247termocom-service_deh.jpg'

const DehumidifierMaintenance = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Обслуживание осушителей воздуха</h1>
                <p>Обслуживание системы осушения Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем осушения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Для стабильного обеспечения производства качественным сжатым воздухом требуется производить своевременное техническое обслуживание рефрижераторных, адсорбционных и тандемных установок осушения сжатого воздуха. Например, при неисправности всего одного соленоидного клапана и несвоевременном обнаружении данной неисправности на адсорбционном осушителе возможно потребуется замена всего сушильного средства. </p>

                <h2>Перечень возможных неисправностей осушителей сжатого воздуха</h2>
                <p><strong>1. Не горит подсветка выключателя на контрольной панели</strong></p>
                <ul>
                    <li>проверить наличие напряжения в сети электропитания</li>
                    <li>проверить монтаж электропроводки</li>
                </ul>
                <p><strong>2. Не включается компрессор</strong></p>
                <ul>
                    <li>проверить монтаж электропроводки и работоспособность блока управления</li>
                    <li>срабатывание устройства тепловой защиты внутри компрессора, выждать 1 час и проверить еще раз. Если неисправность сохраняется, остановить осушитель и вызвать специалиста по холодильному оборудованию</li>
                    <li>проверить состояние электрооборудования компрессора</li>
                    <li>короткое замыкание в компрессоре: заменить компрессор</li>
                </ul>
                <p><strong>3. Не вращается вентилятор</strong></p>
                <ul>
                    <li>Проверить монтаж электропроводки</li>
                    <li>Проверить наличие питания на вентиляторе</li>
                    <li>Срабатывание устройства тепловой защиты внутри вентилятора, выждать 30 минут и проверить еще раз, если неисправность сохраняется - заменить вентилятор</li>
                </ul>
                <p><strong>4. Не происходит слив конденсата</strong></p>
                <ul>
                    <li>Проверить монтаж электропроводки</li>
                    <li>Загрязнение фильтра предварительной очистки в устройстве для слива конденсата - почистить фильтр</li>
                    <li>Перегорела катушка электромагнитного клапана в устройстве для слива конденсата - заменить катушку</li>
                    <li>Проверить работоспособность блока управления - если неисправность сохраняется, заменить</li>
                </ul>
                <p><strong>5. Постоянная утечка воздуха через устройство для слива конденсата</strong></p>
                <ul>
                    <li>Заклинивание электромагнитного клапана в устройстве для слива конденсата - клапан почистить или заменить</li>
                    <li>Проверить значение интервала слива конденсата, настроенное в программе блока управления</li>
                </ul>
                <p><strong>6. Наличие воды в трубо-проводах после осушителя.</strong></p>
                <ul>
                    <li>Осушитель не работает, включить его.</li>
                    <li>Открыта обходная магистраль (если она имеется в составе осушителя), закрыть ее.</li>
                    <li>Температура на дисплее блока управления выше или ниже номинальной</li>
                </ul>
                <p><strong>7. Температура на дисплее блока управления выше номинальной.</strong></p>
                <ul>
                    <li>Проверить, не перепутаны ли входной и выходной патрубки осушителя при подсоединении его к системе подачи сжатого воздуха.</li>
                    <li>Не работает компрессор</li>
                    <li>Не работает вентилятор</li>
                    <li>Температура воздуха на входе в осушитель превышают значения, указанные в паспорте</li>
                    <li>Температура окружающей среды превышает значение, указанное в паспорте</li>
                    <li>Контрольный датчик температуры либо неправильно установлен, либо вышел из строя - проверить</li>
                    <li>Утечка газа в холодильном контуре - остановить осушитель и вызвать специалиста по холодильному оборудованию.</li>
                </ul>
                <p><strong>8. Температура на дисплее контрольной панели ниже номинальной</strong></p>
                <ul>
                    <li>Проверить монтаж электропроводки.</li>
                    <li>Контрольный датчик температуры либо неправильно уста-новлен, либо вышел из строя, проверить.</li>
                    <li>Перегорела катушка соленоидного вентиля, заменить катушку.</li>
                    <li>Осушитель не соответствует пропускной способности воздуха, указанной в паспорте.</li>
                </ul>

                <h2>Нюансы сервисного обслуживания осушителей воздуха</h2>
                <p>Некоторые операции сервисного обслуживания и ремонта осушителей воздуха могут быть связаны с частичной разборкой устройства. В каждом осушителе есть фильтры, улавливающие пыль и прочие механические частицы. Для обеспечения высокой производительности техники во время обслуживания осушителя необходима замена этих фильтров.</p>
                <p>Другая особенность касается лишь адсорбционных осушителей, но поскольку эти модели в последние годы используются всё более активно, сказать об этом аспекте их обслуживания необходимо. Основной и самой дорогостоящей деталью адсорбционного осушителя является силикагелевый ротор, который, как губка, впитывает влагу. При неграмотной эксплуатации и соприкосновении с вредностями ротор может загрязняться. Профессиональное обслуживание осушителя воздуха помимо прочего предусматривает очистку ротора при ухудшении поглощающих свойств адсорбента. Главным преимуществом поставляемых нами осушителей DST Seibu Giken является моющийся ротор, при мойке ротора осушителя сохраняется эффективность впитывающих свойств и целостность ротора. Роторы других производителей могут быть повреждены при мойке, поэтому при падении производительности им обычно требуется замена.</p>
                <p>В качестве третьего нюанса, присущего, впрочем, почти всем современным системам вентиляции и кондиционирования воздуха, можно назвать необходимость обслуживания электроники, которая обеспечивает автоматическое функционирование оборудования. В большинстве случаев ремонт осушителя при выходе из строя электроники сводится к замене нескольких компонентов осушителя, а не замены всего блока.</p>
                <img src={img1} alt='' className='img-width' />

                <h2>Как и когда производится техническое обслуживание осушителя воздуха?</h2>
                <p>Минимальный срок между сервисом - один месяц. На протяжении всего установленного договором срока наши специалисты должны осуществлять регулярную гарантийную проверку электроники и аппаратной части осушителя воздуха. Что же касается внеплановых работ, то необходимо немедленно вызывать специалистов нашей компании при наличии следующих признаков:</p>
                <ol>
                    <li>Падение мощности подачи и сушки сжатого воздуха.</li>
                    <li>Осушитель не обеспечивает требуемые показатели влажности в помещении.</li>
                    <li>Частое срабатывание электрозащиты.</li>
                    <li>Посторонние шумы при работе и другие нехарактерные признаки.</li>
                </ol>
                <p><strong>Раз в месяц: </strong></p>
                <ol>
                    <li>Внешний осмотр оборудования, проверка креплений конструкций осушителя воздуха; </li>
                    <li>Проверка электропитания по фазам (проверка дисбаланса по напряжению, проверка дисбаланса по току);</li>
                    <li>Контроль и запись - избыточного давления испарения, температуры испарения, - избыточного давления конденсации, температуры конденсации (в случае несоответствия, производится дозаправка фреоном);</li>
                </ol>
                <p><strong>Раз в два месяца:</strong></p>
                <ol>
                    <li>Проверка работы дренажной системы Оборудования и по необходимости осуществлять чистку дренажа;</li>
                    <li>Тестирование пульта управления;</li>
                    <li>Проверка работы осушителя во всех режимах;</li>
                    <li>Проверка отсутствия слоя инея на теплообменнике;</li>
                    <li>Очистка входных и выходных жалюзи блока;</li>
                    <li>Чистка корпуса и передней панели блока;</li>
                    <li>Чистка фильтров блока;</li>
                </ol>
                <p><strong>Раз в квартал:</strong></p>
                <ol>
                    <li>Проверка состояний силовых и управляющих цепей Оборудования, по необходимости производить подтяжку резьбовых соединений;</li>
                    <li>Диагностика и устранение посторонних шумов;</li>
                    <li>Проверка лопастей вентиляторов;</li>
                </ol>
                <p><strong>Раз в полгода: </strong></p>
                <ol>
                    <li>Химическая чистка дренажа конденсата;</li>
                    <li>Проверка исправности электрооборудования и автоматики блока;</li>
                    <li>Проверка состояния теплоизоляции хладоновых трубопроводов и устранение неисправностей (для систем с выносным конденсатором); </li>
                    <li>Сезонная отладка режимов работы осушителя или сезонная консервация (расконсервация) оборудования (для систем с выносным конденсатором); </li>
                    <li>Подтягивание резьбовых соединений проводов на клемных коробах, при необходимости замена предохранителей, наконечников, зачистка контактов;</li>
                    <li>Чистка теплообменника и других узлов блока;</li>
                </ol>
                <p><strong>Раз в год:  </strong></p>
                <ol>
                    <li>Химическая очистка теплообменника;</li>
                    <li>Контроль износа подшипников электродвигателей вентиляторов воздушного теплообменника;</li>
                    <li>Проверка герметичности соединений контура прохождения хладона;</li>
                    <li>Тестирование рабочего контура Оборудования на кислотность масла и влажность фреона;</li>
                    <li>Введение в состав масла компрессорного агрегата антифрикционных присадок.</li>
                </ol>
                <p>Обслуживание системы осушения Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем осушения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default DehumidifierMaintenance