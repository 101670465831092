import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

const InstallationHumidificationSystem = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Монтаж системы увлажнения</h1>
                <p>Монтаж системы увлажнения Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем увлажнения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Почему важен профессиональный монтаж систем увлажнения воздуха</h2>
                <p>Увлажняющее оборудование необходимо не только выбрать с учётом специфики здания, но и правильно установить. Технически грамотный монтаж исключает непредусмотренный рост энергопотребления, уменьшение охвата объёма помещений, снижение эффективности работы системы.</p>
                <p>Специалисты, проводящие монтажные работы, должны иметь соответствующий уровень квалификации. Компания Климат Проект гарантирует качественное выполнение монтажа систем увлажнения воздуха благодаря высокому уровню профессионализма сотрудников, имеющих все необходимые допуски и регулярно проходящих повышение квалификации. Монтажные работы проводятся только штатными монтажными бригадами, поэтому каждый заказ сопровождается «от и до» специалистами компании без привлечения стороннего персонала.</p>

                <h2>Системы увлажнения воздуха в промышленных помещениях</h2>
                <p>С учётом специфики промышленных помещений можно выделить следующие основные типы увлажняющих систем:</p>
                <ul>
                    <li>паровые увлажняющие установки</li>
                    <li>ультразвуковое распыляющее оборудование</li>
                    <li>распыляющие системы высокого и низкого давления</li>
                    <li>традиционные системы увлажнения воздуха</li>
                </ul>
                <p>Паровые установки обладают большим энергопотреблением по сравнению с другими типами систем увлажнения воздуха, однако имеют два существенных плюса в эксплуатации – они бесшумны и обеспечивают чистоту выбрасываемого пара от микроорганизмов благодаря интенсивной тепловой обработке. Кроме того, этот вид увлажняющего оборудования не требует обязательного использования дистиллированной воды, так как не имеет сменных фильтров или форсунок, забивающихся солевым налётом и грязью.</p>
                <p>Ультразвуковые системы работают бесшумно, сокращают расход энергии на охлаждение воздуха в помещении, поскольку сами понижают его температуру в процессе работы. Использование ультразвукового увлажняющего оборудования позволяет получить наиболее мелкие водяные капли, что ускоряет их испарение.</p>
                <p>Распыляющие системы высокого и низкого давления, или иначе системы туманообразования, позволяют снизить быстро снизить температуру и повысить влажность воздуха в помещении, при этом имея привлекательную цену и простоту как монтажа, так и контроля.</p>
                <p>Традиционные системы увлажнения воздуха предотвращают образование на поверхностях солевого налёта, и, как и паровые, нетребовательны к качеству используемой воды. Этот тип увлажняющего оборудования самостоятелен и не требует встраивания в систему приточно-вытяжной вентиляции.</p>

                <h2>Монтаж увлажнителей и систем увлажнения воздуха</h2>
                <p>В отличие от осушителей, где иногда достаточно подключить оборудование к электросети и дренажу для слива конденсата, и оно готово к работе, монтаж увлажнителя включает в себя комплекс мероприятий, требующих определённых знаний и навыков. Особенно это касается газовых увлажнителей. </p>
                <p>Итак, мы получили оборудование, проверили целостность упаковки, отсутствие повреждений - можно приступать к установке. При выборе оптимального месторасположения руководствуйтесь следующим правилом – длина паропровода должна быть минимальной. Увлажнитель устанавливается на твёрдую ровную поверхность. Далее осуществляем гидравлические соединения. Большое внимание следует уделить качеству подаваемой воды: для разных увлажнителей, работающих по разным принципам, разные требования к качеству воды. Например, в случае с электродным увлажнителем, ни в коем случае не допускается умягчать воду, так как это приведёт к вспениванию внутри парового цилиндра и сбоям в работе системы. А в случае с тэновым увлажнителем, наоборот, рекомендуется провести деминерализацию воды перед подачей в цилиндр, что позволит уменьшить количество образовываемой накипи и продлит срок службы увлажнителя. </p>
                <p>Следующий шаг - обеспечение беспрепятственного слива воды в дренаж. Все увлажнители оборудованы системой пролива, которая препятствует увеличению концентрации минеральных веществ в рабочей воде и пенообразованию. После того, как датчики фиксируют превышение допустимого уровня одного из этих параметров, часть воды, находящейся в цилиндре, сливается в дренаж, а в цилиндр подаётся такое же количество свежей. Дело в том, что температура дренажной воды может достигать 100° С, поэтому дренажный трубопровод должен быть выполнен из термостойкого материала. Для обеспечения самотёка прокладываем трубку под уклоном 5°. </p>
                <p>Подключение электропитания. Следует учесть, что данную работу должен осуществлять только квалифицированный специалист. Параметры источника питания должны соответствовать характеристикам, указанным на шильдике на боковой панели увлажнителя. Далее, выбирается тип управления увлажнителем, и в соответствии с выбранным типом подключаются внешние датчики. И в завершение, осуществляется подключение нагревательных элементов цилиндра. Все контакты должны быть надёжно соединены во избежание искрения, перегрева и короткого замыкания. </p>
                <p>В случае с газовым увлажнителем, все работы по подключению газовой магистрали должны осуществляется исключительно персоналом, имеющим разрешение на проведение данного вида работ. </p>
                <p>После того, как увлажнитель смонтирован и выбран способ, которым пар будет подаваться в помещение, начинаются работы по монтажу парораспределителей. Это могут быть линейные или вентиляторные распределители. Линейные парораспределители монтируются в воздуховоде приточной системы. При монтаже вентиляторных распределителей обязательно соблюдать минимально допустимые расстояния, во избежание попадания увлажненного воздуха на людей, электроприборы, фальш-потолки и т.д. </p>
                <p>Особое внимание следует уделить монтажу парораспределительных трубок. Для монтажа рекомендуется использовать только специально предназначенные для этих целей трубки. Применение аналогов может привести к их растрескиванию и утечке пара. Паропровод следует прокладывать под наклоном в сторону увлажнителя. Не допускается образование карманов или ловушек, в которых может скапливаться конденсат. Предусмотреть слив конденсата, образующегося в паропроводе. В сливе обязательно предусмотреть гидравлический затвор, который должен быть заполнен водой до начала работы увлажнителя. </p>
                <p>Монтаж системы увлажнения Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем увлажнения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default InstallationHumidificationSystem