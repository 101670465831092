import React from 'react'
import Form from '../form/Form'
import './discountStock.css'

const DiscountStock = () => {
    return (
        <>
            <div className='discount-stock-component'>
                <h1>Скидки и акции</h1>
                <h2>АКЦИЯ НА ПРОЕКТНЫЕ РАБОТЫ</h2>
                <p>ВЫЕЗД СПЕЦИАЛИСТА И ПРОЕКТНЫЕ РАБОТЫ - БЕСПЛАТНО!</p>

                <h2>АКЦИЯ НА БЫТОВЫЕ КОНДИЦИОНЕРЫ</h2>
                <p>СКИДКА 15%</p>
            </div>
            <Form />
        </>
    )
}

export default DiscountStock