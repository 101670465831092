import React from 'react'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img2 from '../../../img/2_small (1).jpg'
import img_1 from '../../../img/-1115-1.jpg'
import img26 from '../../../img/26653vent_mall2.gif'
import img21 from '../../../img/21045vent_mall3_3.gif'
import imgvent from '../../../img/-1vent_nezhil_005.jpg'

import { Link } from 'react-router-dom'

const ShoppingMallVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция торгового центра</h1>
                <p>Вентиляцию торгового центра Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции для торговых центров по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <img src={img2} alt='' />
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Наличие мощной приточно-вытяжной промышленной системы вентиляции и кондиционирования в торговом центре является обязательной инженерной конструкцией, которая должна соответствовать СНиП 2.04.05-91. Благодаря ее правильной работе создается микроклиматическая зона комфорта в торговых залах, несмотря на большое скопление людей. А вот комфорт, прежде всего, влияет на то, сколько времени человек пробудет в магазине.</p>

                <h2>Общие требования к вентиляционным системам крупных торговых площадок</h2>
                <p>Требования к вентиляции как в крупном торговом центре, так и в семейном супермаркете одинаковые. Она должна обеспечивать:</p>
                <ul>
                    <li>Требуемый воздухообмен на всей площади магазина.</li>
                    <li>Своевременный нагрев приточного воздуха или его охлаждение.</li>
                    <li>Подачу очищенного от механических загрязнений воздуха.</li>
                    <li>Комфортный микроклимат при раздаче приточных воздушных потоков.</li>
                    <li>Не создавать шума.</li>
                    <li>Иметь простое и доступное управление .</li>
                </ul>
                <p>Чтобы организовать систему вентиляции, которая отвечает всем заявленным требованиям, нужно провести тщательные расчеты, провести анализ здания, учитывая его архитектурные, конструктивные и функциональные особенности.</p>

                <h2>Устройство вентиляции</h2>
                <p>Самым простым вариантом организации воздухообмена является установка вентиляционных каналов с вентиляторами, работающих на приток и вывод. Она также включает в себя вытяжки и приточные каналы, которые обеспечивают механическую циркуляцию воздуха в помещении. Однако в большинстве случаев, проект не ограничивается таким набором устройств и включает в себя также:</p>
                <ul>
                    <li>воздушные фильтры в приточных и вытяжных каналах;</li>
                    <li>система кондиционирования воздуха (теплообменники, нагреватели, тепловые завесы);</li>
                    <li>увлажнители различного типа.</li>
                </ul>
                <p>Клапаны, работающие на приток, устанавливают в нижней части помещения, а вытяжные отверстия — под потолком (труба при этом выводится над крышей). Такая технология обеспечивает естественную циркуляцию воздуха в помещении и основана на физическом законе, согласно которому тёплый воздух, становясь легче, поднимается.</p>
                <img src={img_1} alt='' className='img-width' />
                <p>В качестве устройств кондиционирования крупных помещений чаще всего использует систему чиллер-фанкойл, при которой теплоноситель циркулирует в системе и охлаждает воздух во всех комнатах. В небольших торговых точках устанавливают бытовые кондиционеры.</p>
                <p>Нормы СНиП по установке систем воздухообмена:</p>
                <ul>
                    <li>Если торговая точка находится в здании общего пользования (жилом доме, торговом центре, офисном здании и др.), она оборудуется отдельной принудительной системой вентиляции.</li>
                    <li>Трубы вытяжки выводятся через крышу не менее, чем на 100 см над поверхностью кровли.</li>
                    <li>Приточные клапаны естественного побуждения размещают не ниже 20 см от поверхности земли. Установка ведётся в местах наименьшего загрязнения (как можно дальше от мусоропроводов, проезжей части, парковочных мест). Клапаны должны быть оборудованы фильтрами и подогревом.</li>
                    <li>Отдельными приточно-вытяжными установками оборудуются холодильные камеры для хранения овощей, фруктов и скоропортящихся продуктов, фасовочные помещения и мойки.</li>
                </ul>

                <h2>Особенности проектировки систем вентиляции</h2>
                <p>Создание проекта вентиляции и отопления – важнейший этап работ, который выполняется еще до начала закладки фундамента здания. Для того чтобы в помещении торгового комплекса было комфортно как персоналу так и посетителям при разработке проектной документации следует учесть что:</p>
                <ul>
                    <li>Воздухообмен для каждого посетителя торгового зала должен быть не менее чем 20 куб.м., а для персонала не меньше 60 куб.м. за один час.</li>
                    <li>Если на одной продовольственной площадке расположены продовольственный и непродовольственный отделы то они должны быть изолированы друг от друга и обеспечены разными вентиляционными системами.</li>
                    <li>При проектировании системы вентиляции необходимо особое внимание уделить персоналу, который находится в помещении большую часть дня. При недостаточно качественном прогреве воздух в зимнее время сотрудники торгового комплекса могут начать болеть, что приведет к возникновению множества проблем.</li>
                </ul>

                <h2>Создание проекта вентиляционной системы для общественного здания</h2>
                <p>Для того чтобы создать проект вентиляционной системы которая будет полностью справляться со своими функциями специалистам прежде всего необходимо получить точные сведения о:</p>
                <ul>
                    <li>Площади всех помещений в здании и их функциональное назначение.</li>
                    <li>Этажности постройки и ее расположение относительно сторон света.</li>
                    <li>Архитектурную сложность здания.</li>
                    <li>Характеристики стройматериалов, которые были использованы для возведения и отделки объекта.</li>
                    <li>Точные данные об ожидаемом количестве посетителей в течение суток.</li>
                    <li>Особые пожелания заказчика.</li>
                </ul>
                <p>После получения точной информации и характеристиках объекта специалисты приступают к созданию схемы прокладки основных воздуховодов для подачи и выведения воздуха. Завершающим этапом проектирования является выбор основных агрегатов, которые будут использоваться для охлаждения, нагрева, фильтрации, подачи и выведения воздушных масс.</p>
                <p>Любая установка вентиляции торгового зала и вспомогательных систем должна начинаться с разработки проекта при учете следующих данных:</p>
                <ol>
                    <li>план каждого этажа здания, со всеми функциональными зонами;</li>
                    <li>местоположение здания по отношению к сторонам света;</li>
                    <li>источники теплоносителя;</li>
                    <li>информацию о строительных материалах и вспомогательных элементах, используемых при возведении здания;</li>
                    <li>пример предполагаемой нагрузки на каждый этаж, режим и степень посещаемости.</li>
                </ol>
                <p>Проектирование оговаривается и утверждается сначала с архитектором, затем с дизайнером, при этом учитываются все нюансы и исключительные особенности постройки. На завершающем этапе проект содержит подробный план воздухопроводной системы, ее характеристика, перечень оборудования и требуемых работ по его установке. Это изначально поможет определить предполагаемую стоимость.</p>
                <p>Вентиляция торгового центра, расположенного на 1-м этаже сооружения, проектируется с использованием автономного кондиционирования и вентиляции, не зависящего от системы вентиляции всего здания. Вычислив объем тепла, что исходит от потока посетителей, торговой техники и солнечного излучения, возможно сделать расчеты кратности воздухообмена в торговых залах магазина.</p>

                <h2>Схемы вентиляции торговых центров</h2>
                <p>Все большие торговые помещения условно делятся на однообъемные супермаркеты (магазины-склады) и многофункциональные торговые комплексы, многоэтажные с разделением на зоны торговли и развлечений. Мы рассмотрим только схемы вентиляции торговых комплексов с большим количеством отдельных помещений.</p>
                <p>Конкретную схему построения системы вентиляции торгового центра проектировщики выбирают, исходя из фактических условий. Чаще всего в торговых центрах системы вентиляции, отопления и кондиционирования объединяются в одну систему. Это позволяет использовать общую сеть воздуховодов и оперативно реагировать на изменение температуры. В качестве оборудования для вентиляции в торговых комплексах чаще всего используются центральные кондиционеры.</p>
                <p>Вот пример системы вентиляции и кондиционирования торгового центра, базирующейся на центральном кондиционере с утилизацией тепла вытяжного воздуха. </p>
                <img src={img26} alt='' />
                <p>Секция вытяжного вентилятора включена в состав центрального кондиционера. В нем же имеется и система утилизации тепла из вытяжного воздуха. Охлаждение воздуха производится с помощью хладагента, поступающего из установленного на крыше чиллера. В систему также входят:</p>
                <ul>
                    <li>насосная станция, перекачивающая хладоноситель между чиллером и теплообменником кондиционера;</li>
                    <li>перекрестно-точный теплообменник, утилизирующий тепло вытяжного воздуха.</li>
                </ul>
                <p>Центральный кондиционер устанавливается в техническом помещении здания, а чиллер и насосная станция – на крыше здания. Работа оборудования контролируется системой автоматики и диспетчеризации. С ее помощью можно устанавливать параметры работы системы вентиляции на определенные временные интервалы, контролировать объем и температуру подаваемого в помещения воздуха, контролировать загрязнение фильтров и защищать оборудование от перегрузок.</p>
                <p>В последнее время все чаще используются комбинированные схемы вентиляции и кондиционирования. В их состав входят:</p>
                <ul>
                    <li>приточная вентиляционная установка с теплообменником для охлаждения воздуха;</li>
                    <li>чиллер;</li>
                    <li>фанкойлы.</li>
                </ul>
                <p>По помещениям торгового центра разводятся воздуховоды от вентиляционной установки. Воздуховоды заканчиваются фанкойлами – теплообменниками с централизованным подводом тепло- или хладагента, через которые продувается воздух от вентиляционной установки. Количество подаваемого хладагента регулируется индивидуально на каждом фанкойле. Холодная вода к фанкойлам и теплообменнику вентиляционной установки подается от чиллера, установленного на крыше. </p>
                <img src={img21} alt='' />
                <p>Такая схема позволяет регулировать температуру индивидуально для каждого помещения торгового центра. Если использовать четырех-трубные фанкойлы, то даже появляется возможность одновременно в разных помещениях вести и охлаждение и обогрев. Фанкойлы удобно прячутся за подвесным потолком и абсолютно не портят интерьер помещений торгового центра.</p>

                <h2>Управление в автоматическом режиме</h2>
                <p>Для того, чтобы централизованно контролировать систему вентиляции, производится дополнительная автоматизация. Составляющие помогают управлять всеми сопутствующими оборудованиями.</p>
                <p>Благодаря этому, есть возможность регулировки объема и температуры приходящего воздушного потока, установки режима работы на заданный промежуток времени, контроля стадии загрязнения фильтров.</p>
                <p>На сегодняшний день востребована интеллектуальная автоматика, состоящая из датчиков и микроконтроллера. Такие датчики используются для анализирования содержимых СН и СО в воздухе, определения температуры и числа посетителей. Благодаря полученным сведениям, конструкция может рассчитать и задать мощность работы вентиляторов, а также секций по нагреву и охлаждению.</p>

                <h2>Воздушные тепловые завесы</h2>
                <p>Очень часто вентиляция в магазине дополняется механизмами воздушных тепловых завес, которые располагаются у входа. Они необходимы в том случае, когда количество людей, ежедневно проходящий через вход, превышает 2500 чел. Также такие завесы монтируются в случаях, если рабочие места расположены около входа и сотрудники находятся там целый день.</p>
                <img src={imgvent} alt='' className='img-width' />
                <p>Устанавливают завесы в погрузочно-разгрузочных и входных зонах. Для магазина продовольственного типа определен стандарт в 1500 м², непродовольственные магазины — 2500 м².</p>

                <h2>Готовые решения по вентиляции торговых залов:</h2>
                <ul>
                    <li>Магазин-склад. Для обеспечения необходимой подвижности воздуха в данном случае будет достаточно установить крышные промышленные кондиционеры. В таком случае отпадет необходимость в обустройстве вытяжных каналов, а вентиляционные шахты для распределения поступающего воздуха будут иметь простую схему без лишних разветвлений. Для подогрева воздуха до комфортной температуры в состав системы можно включить несколько калориферов и устройства рекуперации.</li>
                    <li>Торговый центр. Для того чтобы обеспечить необходимый микроклимат в отдельных помещениях торгового центра целесообразней всего использовать центральный кондиционер. Такой агрегат может одновременно обслуживать большое количество помещений и обеспечивать как нагрев, так и охлаждение воздуха. Центральные кондиционеры используются совместно с системой чиллер-фанкойл, которая устанавливается на крыше обслуживаемого здания. Применение такой системы позволяет выставлять температурный режим индивидуально для каждого помещения кроме того в центральном кондиционере с чиллером-фанкойлом нет ограничений по протяженности трубопровода и перепада его высот.</li>
                </ul>
                <p>Вентиляцию торгового центра Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции для торговых центров по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ShoppingMallVentilation