import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './usefulInformation.css'

const UsefulInformation = () => {
    return (
        <>
            <div className='useful-information-page'>
                <h1>Полезная информация по системам кондиционирования</h1>
                <p>Кондиционеры Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default UsefulInformation