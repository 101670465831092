import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../avtomationPages.css'

import img1 from '../../../img/28-05-2014 21-04-15.jpg'
import img2 from '../../../img/28-05-2014 14-07-02.jpg'
import img3 from '../../../img/28-05-2014 20-01-36.jpg'
import img4 from '../../../img/28-05-2014 20-21-25.jpg'
import img5 from '../../../img/28-05-2014 21-20-22.jpg'
import img6 from '../../../img/28-05-2014 20-09-19.jpg'

const PopularAutomationVentilationConditioningSystems = () => {
    return (
        <>
            <div className='avtomation-pages'>
                <h1>Популярная автоматика для систем вентиляции и кондиционирования</h1>
                <p>Автоматику для систем вентиляции и кондиционирования Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>На автоматизацию систем вентиляции возлагаются следующие функции:</p>
                <ul>
                    <li>обеспечение работы системы вентиляции по заданным временным параметрам, т.е. только лишь в рабочее время и т.д.;</li>
                    <li>контроль текущих параметров воздуха (таких как температура и влажность) и их поддержание на требуемом уровне, управление производительностью вентиляционной установки;</li>
                    <li>контроль в режиме реального времени состояния оборудования вентиляционной системы: вентиляторов, фильтров, компрессоров, нагревателей, охладителей, воздушных клапанов, электродвигателей, рекуператоров и пр.;</li>
                    <li>учет часовой наработки и подача сигналов о необходимости текущих профилактических работ (например, промывки фильтров или прочистки воздуховодов);</li>
                    <li>остановка работы или смена алгоритма работы в случае возникновения нештатных (аварийных) ситуаций: задымления или пожара;</li>
                    <li>визуализация параметров технологического процесса при помощи устройств индикации;</li>
                    <li>дистанционное управление работой всей группы вентиляционного оборудования.</li>
                </ul>
                <p><strong>Автоматику систем вентиляции  представляют следующие устройства:</strong></p>

                <h2>Датчики</h2>
                <p>Датчики – они выполняет функцию своего измерителями в схеме автоматики вентиляции. Они осуществляют контроль параметров обрабатываемого воздуха, работы и состояния сетевого оборудования и выдают информацию на шкафы автоматики.</p>
                <img src={img1} alt='' className='img-width' />
                <ul>
                    <li>Датчики температуры</li>
                </ul>
                <p>Делятся на два типа, по методу измерения:</p>
                <ul>
                    <li>термоэлектрические преобразователи или термопары (действие основано на измерении термоэлектродвижущей силы, развиваемой термопарой)</li>
                    <li>термосопротивления или термисторы (действие основано на зависимости электрического сопротивления материала от температуры окружающей его среды). Различают два типа таких датчиков - NTC термисторы (сопротивление материала снижается с повышением температуры) и PTC термисторы (сопротивление материала повышается с повышением температуры).</li>
                </ul>
                <p>Датчики температуры могут быть как комнатного, так и наружного исполнения, канальными (измеряют температуру воздуха в воздуховодах), накладными (измеряют температуру поверхности трубопровода) и так далее. Выбирая датчик нужно обратить внимание на температурные характеристики чувствительного элемента, они должны совпадать с рекомендуемыми в описании регулятора температуры.</p>
                <ul>
                    <li>Датчики влажности</li>
                </ul>
                <p>Это электронные устройства, измеряющие относительную влажность по изменению электрической емкости в зависимости от относительной влажности воздушной среды. Датчики влажности делят на два типа: комнатные и канальные. Друг от друга они отличаются конструкцией. При установке датчика нужно выбирать место со стабильной температурой и скоростью движения окружающего воздуха, а также нежелательно располагать датчик возле окон, под прямыми солнечными лучами и вблизи отопительных приборов.</p>
                <ul>
                    <li>Датчики давления</li>
                </ul>
                <p>Различают два типа датчиков давления - аналоговые датчики давления и реле давления. Оба типа датчиков могут измерять давление как в одной точке, так и разность давлений в двух точках. В этом случае датчик называется дифференциальным датчиком давления.</p>
                <p>Примером использования реле давления в климатических системах может послужить датчик давления, служащий для защиты компрессора от слишком низкого или высокого давления фреона. Также, дифференциальные манометры применяются для определения степени засора в фильтрах систем вентиляции. При помощи же аналоговых датчиков определяется давление в точке измерения. Измеренное давление конвертируется в электрический сигнал вторичным преобразователем датчика.</p>
                <ul>
                    <li>Датчики потока</li>
                </ul>
                <p>Принцип работы датчика потока состоит в следующем: в первую очередь измеряется скорость движения газа или жидкости в воздуховоде или трубопроводе, после чего измеренный сигнал преобразуется во вторичном преобразователе в электрический, затем рассчитывается расход газа или жидкости в вычислительном блоке. Наиболее востребованы такие датчики в сфере учета тепловой энергии. По принципу действия первичных преобразователей датчики потока делятся на лопастные устройства, сужающие, турбинные, вихревые, роторные, ультразвуковые и электромагнитные.</p>
                <p>В системах вентиляции и кондиционирования наиболее распространены датчики-реле протока. Они реагируют на скорость газа, создающего напор на лопасть датчика, которая приводит в действие микропереключатель с сухим контактом. В тот момент, когда скорость потока достигает заданного порога переключения, происходит замыкание контактов. Когда же скорость потока падает ниже этого порога, контакты размыкаются. Порог переключения можно регулировать.</p>
                <ul>
                    <li>Датчики концентрации углекислого газа</li>
                </ul>
                <p>По содержанию углекислого газа в воздухе принято оценивать газовый состав воздуха в помещении. В системе вентиляции и кондиционирования концентрация углекислого газа может быть объектом регулирования. (Нормой содержания углекислого газа в воздухе считается значение от 600 до 800 ppm).</p>
                <p>Выбирают датчики на основе следующих данных:</p>
                <ul>
                    <li>условия эксплуатации</li>
                    <li>диапазон</li>
                    <li>требуемая точность измерения физического параметра</li>
                </ul>

                <h2>Шкаф автоматики</h2>
                <p>Шкаф автоматики - является главной управляющей составляющей в автоматизированной системы вентиляции.</p>
                <img src={img2} alt='' />
                <p><strong>Функциональные возможности:</strong></p>
                <ul>
                    <li>автоматическое регулирование температуры приточного воздуха в зимний период;</li>
                    <li>автоматическое регулирование температуры обратного теплоносителя в дежурном режиме;</li>
                    <li>автоматическая защита калорифера от  замораживания по воде и по воздуху;</li>
                    <li>контроль работоспособности приточного вентилятора (по термоконтакту двигателя, датчику-реле перепада давления);</li>
                    <li>контроль запыленности воздушного фильтра (по датчику-реле перепада давления);</li>
                    <li>контроль положения воздушного клапана (по состоянию концевого выключателя лектропривода);</li>
                    <li>отключение вентиляционной системы  при пожаре с сохранением работоспособности цепей защиты от замораживания в активном состоянии;</li>
                    <li>работа системы по индивидуальному расписанию;</li>
                    <li>сигнализация нормальной работы и аварийного состояния на щите автоматики</li>
                </ul>

                <h2>Контроллеры</h2>
                <p>Контроллеры - их применение наиболее актуально, когда важно:</p>
                <ul>
                    <li>управление переходными процессами в реальном времени с использованием мощных микропроцессоров;</li>
                    <li>возможность сохранения событий во флэш-памяти (сигналов тревоги, показателей температуры, давления) в течение продолжительного времени;</li>
                    <li>настраиваемый вид пользовательского интерфейса;</li>
                    <li>обмен данными с большинством широко используемых стандартов связи посредством встроенного мультипротокольного программного обеспечения;</li>
                    <li>гибкость использования различных функций и алгоритмов,</li>
                </ul>
                <img src={img3} alt='' className='img-width' />
                <p><strong>Универсальные конфигурируемые контроллеры для систем кондиционирования</strong></p>
                <p>Система конфигурируемых контроллеров  представляет собой результат десятилетий работы в области проектирования и производства конфигурируемых контроллеров для устройств вентиляции и кондиционирования воздуха. Система  составлена из конфигурируемых контроллеров – как для панельного монтажа, так и для монтажа на направляющих стандарта DIN, – локальных и дистанционных пользовательских интерфейсов, интерфейсов связи, входных/выходных модулей расширения и приводов электронных ТРВ. Конфигурируемые контроллеры могут быть адаптированы к широкому диапазону вариантов применения за счет настройки различных параметров для устройств охлаждения/отопления: воздух/вода, вода/вода, воздух/воздух, крышные агрегаты, двухконтурные системы, максимум с 3-мя компрессорами на контур.</p>
                <p><strong>Преимущества:</strong></p>
                <ul>
                    <li>исключительно компактная конструкция;</li>
                    <li>возможность подключения к дистанционному терминалу;</li>
                    <li>высокая надежность;</li>
                    <li>управление электронными ТРВ;</li>
                    <li>эргономичная и высокоэффективная индикация с использованием пиктографических изображений – «иконок»;</li>
                    <li>простота электромонтажа;</li>
                    <li>модульная архитектура.</li>
                </ul>
                <img src={img4} alt='' className='img-width' />
                <p><strong>Основные функции:</strong></p>
                <ul>
                    <li>пропорциональное регулирование температурой обратной и выходной воды/воздуха с использованием синхронизированной логики;</li>
                    <li>пропорционально-интегральное регулирование;</li>
                    <li>ступенчатое регулирование в каждом контуре;</li>
                    <li>управление конденсатором/испарителем;</li>
                    <li>управление с подключением различных обмоток;</li>
                    <li>автоматическое поддержание низкого давления;</li>
                    <li>постепенное размораживание в режиме отопления;</li>
                    <li>ступень электроподогрева как автономная дополнительная функция размораживания испарителя;</li>
                    <li>контроль продолжительности работы компонентов и выдача предупреждений;</li>
                    <li>возможность работы с частичной нагрузкой по высокому давлению в режиме охлаждения;</li>
                    <li>профилактическая вентиляция при включении в условиях высокой наружной температуры воздуха;</li>
                    <li>останов компрессора при низких температурах наружного воздуха;</li>
                    <li>работа с частичной нагрузкой по низкому давлению (в режиме отопления);</li>
                    <li>низкий уровень шума при работе в режиме охлаждения и обогрева;</li>
                    <li>изменение установки и Включение/Отключение по заданному временному интервалу;</li>
                    <li>управление приводом электронного ТРВ;</li>
                    <li>регистрация событий: тревог по принципу «первый пришел – первый вышел»;</li>
                    <li>регистрация данных по испарителю, а также температуры конденсации и давления (последние 100 тревог);</li>
                    <li>ключ программирования – загрузка файлов зарегистрированных данных в компьютер;</li>
                    <li>отправка сигналов тревоги в виде SMS;</li>
                    <li>автоматическая настройка;</li>
                    <li>самодиагностика;</li>
                    <li>автоматическое переключение;</li>
                    <li>функция интеллектуального размораживания;</li>
                    <li>ключ программирования.</li>
                </ul>

                <h2>Регуляторы</h2>
                <p>Регулятор температуры обеспечивает управление исполнительными механизмами по показаниям всевозможных датчиков и является одним из основных элементов системы. Простейшим типом регуляторов являются термостаты, они предназначены для контроля и поддержания заданной температуры в различных технологических процессах. Термостаты разделяются по принципу действия, способу применения и конструкции. По принципу действия они делятся на:</p>
                <ul>
                    <li>биметаллические</li>
                    <li>капиллярные</li>
                    <li>электронные</li>
                </ul>
                <p>Принцип действия биметаллических термостатов основан на срабатывании биметаллической пластины под воздействием температуры. Их применяют в основном для защиты электронагревателей от перегрева и поддержания заданной температуры в помещении.</p>
                <img src={img5} alt='' />
                <p>Капиллярные термостаты используют для контроля температуры теплообменников в системах кондиционирования и вентиляции и предотвращения их разрушения из-за замерзания теплоносителя. Составляющие такого термостата - капиллярная трубка, заполненная фреоном R134A, соединенная с диафрагмированной камерой, которая, в свою очередь, механически связана с микропереключателем.</p>
                <p>В системах вентиляции капиллярный термостат угрозы замораживания может запускать следующие процессы:</p>
                <ul>
                    <li>остановка вентилятора</li>
                    <li>закрытие заслонки наружного воздуха</li>
                    <li>запуск циркуляционного насоса теплоносителя</li>
                    <li>включение аварийного сигнала</li>
                </ul>
                <p>Для помещений в глубине зданий применяют электронные термостаты, имеющие релейный выход. Поддерживать заданную температуру термостаты могут как по встроенному, так и по выносному датчику.</p>
                <p>Беспроводные комнатные терминалы - беспроводное решение для управления климатическими параметрами (температурой и влажностью) в зданиях. Такой подход гарантирует энергосбережение и оптимизацию системы управления. Устройство оптимально подходит для систем кондиционирования воздуха (крышных кондиционеров, приточно-вытяжных установок), и может быть адаптировано для других систем (например, для теплого пола).</p>
                <p><strong>Система состоит из:</strong></p>
                <ul>
                    <li>терминала со встроенными датчиками температуры и влажности;</li>
                    <li>датчика температуры и влажности;</li>
                    <li>точки доступа, используется для сбора информации с беспроводных терминалов и датчиков и передачи ее в систему управления зданием, которая строится либо на основе контроллера  и сервера системы диспетчеризации, либо с использованием центрального блока управления;</li>
                    <li>повторителя, который обеспечивает расширение зоны покрытия радиосигналом для обеспечения обмена данными между беспроводными терминалами и датчиками, расположенными в удаленных местах объекта.</li>
                </ul>
                <img src={img6} alt='' />
                <p><strong>Преимущества:</strong></p>
                <ul>
                    <li>Гибкость: Возможность легко менять структуру управления инженерным оборудованием, например, в случае необходимости изменения планировки супермаркета или офиса без внесения изменения в существующие коммуникационные каналы.</li>
                    <li>Упрощенное переоснащение исторических или иных зданий, где затруднены или недопустимы строительные работы, связанные с вскрытием полов, стен, и т.д.</li>
                    <li>Более низкая стоимость монтажа и эксплуатации.</li>
                    <li>Упрощенная пуско-наладка системы.</li>
                    <li>Интеграция с большинством распространенных систем управления зданием BMS.</li>
                    <li>Поддержание заданных параметров в индивидуальных зонах помещения (способствует снижению энергозатрат).</li>
                    <li>Сотовая структура обмена данными между точками доступа и устройствами обеспечивает высокую надежность передачи данных внутри сети.</li>
                </ul>

                <h2>Исполнительные механизмы</h2>
                <p>Исполнительные механизмы - относятся электроприводы воздушных клапанов и заслонок, вентиляторов, насосов, компрессорных установок, а также калориферы, охладители, задвижки, заслонки, электроприводыи прочее оборудование.</p>
                <p>Исполнительным механизмом называют приводную часть исполнительного устройства. Исполнительные механизмы делятся на гидравлические, электрические и пневматические. В частности электрические могут быть соленоидные (электромагнитные) и с электродвигателями (электрические)</p>
                <ul>
                    <li>Клапаны и заслонки</li>
                </ul>
                <p>Клапаны двухходовые и трехходовые делятся на резьбовые и фланцевые. Клапаны с фланцевым подключением как правило комплектуются монтажным набором с уплотнителем, а с резьбовым - фитингами и уплотняющими шайбами. В качестве проходных, изменяющих расход рабочей среды используются двухходовые клапаны. Они монтируются в системе трубопроводов или воздуховодов так, чтобы направление потока совпадало с направлением стрелки на корпусе клапана. Типичный пример использования такого клапана - контур с локальным циркуляционным насосом.</p>
                <p>Трехходовые клапаны служат в качестве смесительных, разделительных и проходных клапанов. Эти клапаны широко применяются в системах  холодоснабжения. Клапаны "бабочка" монтируются на фланцевом соединении. Рабочая часть таких клапанов - укрепленный на вращающейся оси диск. Величина просвета между диском и внутренней поверхностью клапана меняется в зависимости от угла поворота оси. Клапаны такой конструкции чаще всего используются в жидкостных трубопроводах большого диаметра. На воздуховодах как круглого, так и прямоугольного сечения применяются воздушные дроссельные заслонки. Они используются для регулирования воздушных потоков при небольшом статическом давлении. Обратные клапаны нужны для предотвращения движения потока жидкости или газа в обратном направлении, в частности их используют в жидкостных и всасывающих трубопроводах чиллеров и автономных кондиционеров.</p>
                <ul>
                    <li>Электроприводы воздушных заслонок</li>
                </ul>
                <p>Для управления воздушными заслонками часто недостаточно вручную переключать положения клапанов, поэтому используются электроприводы, управляемые дистанционно или автоматически. Электроприводы классифицируются по:</p>
                <ul>
                    <li>величине питающего напряжения (24В AC/DC или 230В 50Гц)</li>
                    <li>величине крутящего момента (необходимое значение определяется площадью воздушного клапана, на который устанавливается привод)</li>
                    <li>способу управления (плавное, двухпозиционное или трехпозиционное)</li>
                    <li>способу возврата в исходное положение (при помощи пружины или с помощью реверсивного электродвигателя)</li>
                    <li>наличию дополнительных переключающих контактов</li>
                </ul>
                <p>Автоматику для систем вентиляции и кондиционирования Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default PopularAutomationVentilationConditioningSystems