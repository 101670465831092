import React from 'react'
import '../heatingPages.css'
import Form from '../../../components/form/Form'
import { Link } from 'react-router-dom'

import img1 from '../../../img/2803824cf4729273580647f4ca3689752d917f.jpg'

const HumidificationDesign = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Проектирование системы увлажнения</h1>
                <p>Проектирование системы увлажнения Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем увлажнения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>При том, что для большинства производств требуется минимальная влажность воздуха в производственных помещениях, существует несколько областей деятельности, когда, напротив, необходимо использование увлажнителей воздушной среды. Такими отраслями являются, например, полиграфия, некоторые бумажные производства, ряд медицинских областей. И, конечно, насыщение воздушной среды влагой осуществляется в некоторых помещениях жилых домов, к примеру, в спальнях.</p>

                <h2>Чем опасны отклонения от нормы влажности в помещении?</h2>
                <p>Чрезмерная влажность является причиной появления конденсата на поверхностях в помещении, риску коротких замыканий, порче хранимого товара, усложнению условий работы персонала, может привести к росту грибка и плесени в труднодоступных и плохо вентилируемых местах здания.</p>
                <p>Опасность слишком низкой влажности не менее серьёзна: ухудшение здоровья сотрудников, растрескивание деревянных деталей оборудования и усадка печатного материала в полиграфическом производстве, уменьшение массы хранимой продукции и потеря её товарного вида, высокая концентрация пыли и вредоносных микроорганизмов в воздухе, появление и быстрое увеличение щелей в стенах здания при неблагоприятных климатических условиях в регионе. Отдельной опасностью при низкой влажности является накопление статического электричества металлическими элементами оборудования. Системы увлажнения воздуха способны предотвратить эти проблемы ещё до их возникновения.</p>

                <h2>Проектирование систем увлажнения воздуха – почему этот этап так важен?</h2>
                <p>На этапе проектирования внимательно изучаются потребности помещения в коррекции показателей влажности, учитывается назначение здания, проводится подбор необходимого оборудования в соответствии требуемыми показателями на выходе и экономическими возможностями, создаётся система увлажнения, способная удовлетворить нужды конкретного объекта при минимальных энергозатратах. Профессиональный подход и наличие практического опыта у специалистов – весомое преимущество на этой стадии, поскольку, только зная реальное поведение оборудования в тех или иных условиях, можно подобрать оптимально подходящие модели.</p>
                <p>Проектный отдел компании Климат Проект способен справиться с задачами любой сложности, спроектировав системы увлажнения в соответствии с требованиями заказчика. Наши специалисты имеют колоссальный практический опыт, максимально полные знания о продукции лидеров рынка в области систем увлажнения воздуха, о преимуществах и слабых сторонах того или иного оборудования. Это позволяет давать рекомендации и разрабатывать проектные решения с полной уверенностью в оптимальном результате.</p>

                <h2>Проектирование систем увлажнения воздуха специалистами компании "FIDCLIMAT"</h2>
                <p>В процессе проектирования наша компания использует предоставляемое компаниями-производителями программное обеспечение, что даёт возможность из первых рук узнавать о характеристиках оборудования, их габаритах и энергопотреблении. Также использование информации, полученной напрямую от производителей, позволяет быстрее и точнее рассчитать экономические затраты на создание системы увлажнения воздуха с использованием конкретных моделей. Точное знание технической спецификации и характеристик моделей даёт возможность, не меняя бренда, сократить конечную стоимость системы увлажнения воздуха.</p>
                <p>Проектный отдел ведёт проработку каждого проекта в специализированных программах, сводя к минимуму риск ошибки или недоработки и значительно сокращая сроки подбора решения.</p>
                <p>FIDCLIMAT предоставляет инжиниринговые услуги в сфере кондиционирования, вентиляции и систем увлажнения, осуществляет монтаж и пуско-наладочные работы, а также сервисное обслуживание и гарантийный ремонт оборудования. Безошибочно и квалифицированно проведённый полный комплекс работ предотвратит излишние затраты энергии и снижение эффективности системы по сравнению с расчетной, повысит надёжность и срок службы оборудования, облегчит настройку и автоматизацию, сэкономит время и поможет создать в производственном помещении необходимый микроклимат.</p>
                <p><strong>Методика расчета системы форсуночного увлажнения воздуха ABF</strong></p>
                <p>Исходные данные, которые необходимо знать для расчета системы:</p>
                <ul>
                    <li>Все размеры помещения (Д*Ш*В)</li>
                    <li>Производительность вытяжной вентиляции (либо кратность воздухообмена)</li>
                    <li>Относительная влажность % без увлажнения в наихудший период (как правило зимой)</li>
                    <li>Требуемая относительная влажность % в помещении</li>
                    <li>Температура воздуха, °С</li>
                </ul>
                <p>Упрощенный расчет необходимого количества воды (в л/мин.) для поддержания требуемой относительной влажности в помещении производится по формуле:</p>
                <ul>
                    <li>Q=(V*N)*1.2*((X2-X1)/10+1)/60000</li>
                </ul>
                <p>где</p>
                <ul>
                    <li>(V*N) – производительность вентиляции</li>
                    <li>V – объем помещения</li>
                    <li>N – кратность воздухообмена в помещении (какое количество раз в 1 час поменяется полный объем)</li>
                    <li>1.2 -удельный вес воздуха (кг/м3) (при температуре 21 °С и атмосферном давлении 1013 мбар)</li>
                    <li>X1 – Относительная влажность % без увлажнения в наихудший период (как правило зимой)</li>
                    <li>X2 – Требуемая относительная влажность % в помещении</li>
                </ul>
                <img src={img1} alt='' className='img-width' />
                <p>В зависимости от полученных данных, мы подбираем комплект оборудования, которое обеспечит нужную производительность системы увлажнения воздуха. Кроме промышленных увлажнителей, в помещениях должны быть установлены приборы контроля микроклимата, а деревообрабатывающему производству не помешают влагомеры, с помощью которых осуществляется мониторинг влажности древесины. </p>

                <h2>Этапы проектирования </h2>
                <ul>
                    <li>разработка технического задания;</li>
                    <li>разработка решения по системе вентиляции с воздушным увлажнением;</li>
                    <li>разработка проектной документации;</li>
                    <li>подбор требуемого оборудования и материалов с учетом пожелания заказчика по стоимости и качеству;</li>
                    <li>согласование проекта;</li>
                    <li>авторский надзор за проведением монтажных работ.</li>
                </ul>
                <p>Виды систем воздушного увлажнения, по которым мы можем предоставить услуги проектирования:</p>
                <ul>
                    <li>адиабатическое увлажнение воздуха;</li>
                    <li>паровое увлажнение воздуха;</li>
                    <li>ультразвуковое увлажнение воздуха.</li>
                </ul>
                <p>Конструкции систем увлажнения воздуха:</p>
                <ul>
                    <li>бытовые переносные увлажнители;</li>
                    <li>увлажнительные камеры приточных установок.</li>
                </ul>
                <p>Проектирование системы увлажнения Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем увлажнения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HumidificationDesign