import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../avtomationPages.css'

import img1 from '../../../img/28-05-2014 21-47-37.jpg'

const AutomationControlFunctions = () => {
    return (
        <>
            <div className='avtomation-pages'>
                <h1>Управляющие функции автоматизации вентиляции и кондиционирования</h1>
                <p>Управляющие функции обеспечивают выполнение заложенных алгоритмов нормального функционирования системы. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Управляющие функции обеспечивают выполнение заложенных алгоритмов нормального функционирования системы.</p>
                <ul>
                    <li>последовательность пуска;</li>
                    <li>последовательность останова;</li>
                    <li>резервирующие и дополняющие.</li>
                </ul>
                <img src={img1} alt='' className='img-width' />

                <h2>Последовательность пуска</h2>
                <p>Для обеспечения нормального пуска кондиционера необходимо соблюдать следующую последовательность:</p>
                <p><strong>1. Предварительное открытие воздушных заслонок</strong></p>
                <p>Предварительное открытие воздушных заслонок до пуска вентиляторов выполняется в связи с тем, что не все заслонки в закрытом состоянии могут выдержать перепад давлений, создаваемый вентилятором, а время полного открытия заслонки электроприводом доходит до 2 мин. Входное напряжение управления электроприводом может быть 0-10 В (пропорциональное позиционное управление при плавном регулировании) или ~24 В (~220 В) — двухпозиционное управление (открыто/закрыто).</p>
                <p><strong>2. Разнесение моментов запуска электродвигателей</strong></p>
                <p>Асинхронные электродвигатели имеют большие пусковые токи. Так, компрессоры холодильных машин имеют пусковые токи, в 7-8 раз превышающие рабочие (до 100 А). Если одновременно запустить вентиляторы, холодильные машины и другие приводы, то из-за большой нагрузки на электрическую сеть здания сильно упадет напряжение, и электродвигатели могут не запуститься. Поэтому запуск электродвигателей необходимо разносить по времени.</p>
                <p><strong>3. Предварительный прогрев калорифера</strong></p>
                <p>Если включить кондиционер, не прогрев водяной калорифер, то при низкой температуре наружного воздуха может сработать защита от замораживания. Поэтому при включении кондиционера необходимо открыть заслонки приточного воздуха, открыть трехходовой клапан водяного калорифера и прогреть калорифер. Как правило, эта функция включается при температуре наружного воздуха ниже 12 °С.</p>
                <p>В системах с вращающимся рекуператором сначала включается вытяжной вентилятор, затем начинает вращаться колесо рекуператора, а после его прогрева вытяжным воздухом включается приточный вентилятор.</p>
                <p>Таким образом, последовательность включения должна быть следующей: вытяжная заслонка — вытяжной вентилятор — приточная заслонка — рекуператор — трехходовой клапан — приточный вентилятор. Время запуска в летний период составляет 30-40 с, в зимний — до 2 мин.</p>

                <h2>Последовательность остановки</h2>
                <p><strong>1. Задержка остановки вентилятора приточного воздуха</strong></p>
                <p>В установках с электрокалорифером необходимо после снятия напряжения с электрокалорифера охлаждать его неко- торое время, не выключая вентилятор приточного воздуха. В противном случае нагревательный элемент калорифера (тепловой электрический нагреватель — ТЭНов) может выйти из строя.</p>
                <p><strong>2. Задержка выключения холодильной машины</strong></p>
                <p>При выключении холодильной машины хладагент сосредоточится в самом холодном месте холодильного контура, т. е. в испарителе. При последующем пуске возможен гидроудар. Поэтому перед выключением компрессора сначала закрывается клапан, устанавливаемый перед испарителем, а затем при достижении давления всасывания 2,0-2,5 бар компрессор выключается. Вместе с задержкой выключения компрессора производится задержка выключения приточного вентилятора.</p>
                <p>3. Задержка закрытия воздушных заслонок</p>
                <p>Воздушные заслонки закрываются полностью только после остановки вентиляторов. Так как вентиляторы останавливаются с задержкой, то и воздушные заслонки закрываются с задержкой.</p>

                <h2>Резервирующие и дополняющие функции</h2>
                <p>Дополняющие функции закладываются при работе в схеме нескольких одинаковых функциональных модулей (электрокалориферов, испарителей, холодильных машин), когда в зависимости от затребованной производительности включаются один или несколько элементов.</p>
                <p>Для повышения надежности устанавливаются резервные вентиляторы, электронагреватели, холодильные машины. При этом периодически (например, через 100 ч) основной и резервный элементы меняются функциями.</p>
                <p>Управляющие функции обеспечивают выполнение заложенных алгоритмов нормального функционирования системы. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default AutomationControlFunctions