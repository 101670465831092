import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img312 from '../../../img/312_small.jpg'
import img334 from '../../../img/33441home-ventilation-systems.jpg'
import img289 from '../../../img/289062ducted-illustration.jpg'
import img817 from '../../../img/81795home_vent-diagram-50.jpg'

const CentralVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Центральная система вентиляции</h1>
                <p>Центральную систему вентиляции Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку центральной вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <img src={img312} alt='' />
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>При централизованной системе вентиляции обслуживание нескольких помещений или целого дома происходит через главную систему вентиляции от которой отходят большое количество меньших воздуховодов приточной и вытяжной систем. Такие системы необходимо проектировать на стадии проектирования дома или переоборудования существующего помещения. При переоборудовании существующих домов или комнат затраты на монтаж и установку возрастают. Но невзирая на затраты установка такой рекуперативной системы вентиляции окупается примерно за три года. Установка таких систем вентиляции имеет смысл в домах или квартирах где проведены все возможные работы по ликвидации утечек тепла.</p>
                <p><strong>Центральная система вентиляции включает:</strong></p>
                <ul>
                    <li>центральные, большие приточные или приточно-вытяжные установки, которые могут размещаться либо в специально оборудованном помещении – венткамере, либо на крыше здания;</li>
                    <li>магистральные воздуховоды (вертикальные и горизонтальные);</li>
                    <li>воздуховоды в помещениях;</li>
                    <li>воздухораздающие и воздухозаборные устройства. </li>
                </ul>

                <h2>Особенности</h2>
                <p>Установку размещают на балконе,лоджии, в коридоре. Для забора наружного воздуха в наружной стене выполняют отверстие. Воздух с помощью вентилятора поступает в установку, где проходит фильтрацию и при необходимости подогрев. Далее он при помощи напора вентилятора поступает в сеть воздуховодов, смонтированную у потолка квартиры, где через решетки подается в помещение. Воздуховоды в квартире после монтажа зашиваются гипсокартоном или прячутся за навесным потолком.</p>
                <p>Удаление отработанного воздуха происходит в помещениях кухни, санузла и ванной с помощью бытовых вентиляторов установленных в вентиляционных каналах здания. Для перетекания воздуха из комнат в эти помещения в межкомнатных дверях устанавливаются снизу переточные решетки или оставляют зазор между дверью и полом. С помощью такой схемы вентиляции, исключается попадание запахов из кухни в жилые помещения.</p>
                <p>Вентиляция осуществляет круглый год, с помощью автоматики установки можно задавать время включения и выключения системы вентиляции в зависимости от требований потребителя.</p>
                <p>Как уже сказано выше, для подогрева воздуха, который поступает в квартиру в установке используется электрический нагреватель. Приточно-вытяжные установки с рекуперацией позволяют уменьшить требуемую электрическую мощность для подогрева свежего воздуха за счет использования тепла от удаляемого воздуха.</p>
                <img src={img334} alt='' className='img-width' />
                <p>В последнее время такие установки хоть и дороже обычных приточных, но позволяют значительно экономить электроэнергию, уменьшить нагрузку на электросеть. В странах Европы, в виду высоких цен на электроэнергию (в Германии 1 кВт для населения стоит около 0.4 у.е.), активно используется рекуперацию практически во всех установках приточно-вытяжной вентиляции. Помимо экономического эффекта, рекуперация позволяет «оставлять» большую часть тепла в жилище, которое обычно уходит с вытяжным воздухом из квартиры.</p>
                <p>Конструкция рекуператора такова, что перекрестные потоки теплого (вытяжного) и холодного (свежего) воздуха будучи разделены стенками пластин теплообменника, не соприкасается друг с другом, благодаря чему исключается передача одним потоком другому загрязнений, запахов, микроорганизмов. Количество тепловой энергии, отдаваемой вытяжным воздухом приточному, зависит только от теплопроводности материалов и разницы температур между двумя потоками. При этом теплый вытяжной воздух охлаждается, а холодный приточный – нагревается.</p>
                <p>Процесс рекуперации в установке происходит следующим образом: чистый холодный воздух с улицы по воздуховодам поступает в установку, в фильтре происходит фильтрация поступающего воздуха, далее он проходит через теплообменник и при помощи приточного вентилятора по воздуховодам подается в помещения. Удаляемый из помещения воздух по воздуховодам поступает в установку, в фильтре осуществляется фильтрация, далее он проходит через теплообменник и при помощи вытяжного вентилятора по воздуховодам выбрасывается на улицу. В теплообменнике происходит обмен тепловой энергией теплого загрязненного воздуха, поступающего из комнаты, с чистым холодным воздухом, поступающим с улицы.</p>
                <p>Этот вариант осуществления вентиляции жилища наиболее, с точки зрения эффективности, правильный. Помимо подачи чистого воздуха в помещение, система удаляет отработанный воздух из помещения. Для транспортировки воздуха используется, как и в предыдущем варианте, система воздуховодов и воздухораспределительных решеток. Установка размещают в помещении лоджии, балкона.</p>
                <p>Для управления системы используется автоматика, которая следит за работой оборудования, сигнализирует о несправностях, необходимости сервисного обслуживания. Автоматика также отключает установку в случае выявления неправильной работы компонентов системы и сигнализирует об этом. Наличие недельного таймера позволяет владельцу самостоятельно задать режим включения и выключения системы вентиляции исходя из своих пожеланий.</p>

                <h2>Централизованная механическая приточно-вытяжная вентиляция</h2>
                <p>Приток в помещение осуществляется от централизованной приточной установки, в состав которой входят фильтры для очистки наружного воздуха и воздухонагреватели, обеспечивающие нагрев до требуемой температуры.</p>
                <p>Для снижения энергопотребления в случае механической приточно-вытяжной вентиляции, как правило, используется (утилизируется) тепло удаляемого воздуха для предварительного подогрева приточного воздуха. Возможны следующие способы утилизации тепла удаляемого воздуха:</p>
                <ul>
                    <li>с использованием встроенного перекрестного теплоутилизатора - рекуператора тепла ( эффективность теплоутилизации составляет до 70%, зависит от разности температур между потоками воздуха, влажности и отношения расходов воздуха);</li>
                    <li>с использованием гликогелевых теплообменников ( эффективность теплоутилизации составляет до 45%).</li>
                </ul>

                <h2>Централизованная приточно-вытяжная вентиляция</h2>
                <p>Централизованная вентиляция обеспечивается за счет высокоэффективного прямоточного вентилятора с регулируемым потоком воздуха. При этом отработанный воздух отводится, а свежий — поступает в здание.</p>
                <p>Центральное управление обеспечивает высокоэффективную рекуперацию тепла: тепло вытяжного воздуха проходит через теплообменник и передается приточному. Чем лучше теплоизоляция здания, тем быстрее окупается подобная установка.</p>
                <p>Повторное использование до 95% тепловой энергии обеспечивает высокоэффективное энергосбережение. При этом теплообменник должен быть оснащен функцией предотвращения образования конденсата и промерзания. Системы централизованной вентиляции снабжены фильтрами, задерживающими пыль. </p>

                <h2>Приточная вентиляция принудительного типа</h2>
                <img src={img289} alt='' className='img-width' />
                <p>Централизованная система вентиляции обеспечивает вытяжку за счет системы воздуховодов и центрального вытяжного вентилятора. Это — первый контур системы. Второй контур системы вентиляции — приточная вентиляция, осуществляет забор и подогрев воздуха с улицы, подачу ее в помещения. </p>
                <p>Воздуховоды располагаются за потолком, в техническом помещении или на чердаке.</p>
                <p>Можно встроить не только электрический подогреватель, но и использовать эффект рекуперации, когда рекуператор будет за счет тепла внутреннего воздуха нагревать воздух входящий, это экономит энергию, затрачиваемую на отопление загородного дома. Система относительно тихая.</p>
                <p>По желанию, можно установить разные модули воздухоочистки, существуют даже встроенные ионизаторы воздуха и системы для обеззараживания. Чисто теоретически, можно создать систему, которая будет очищать воздух, как для операционной, но стоимость ее может доходить до нескольких миллионов рублей.</p>
                <img src={img817} alt='' className='img-width' />
                <p>Хорошо спроектированная система обеспечивает интенсивный воздухообмен и хороший микроклимат в доме. </p>
                <p>У системы центральной принудительной вентиляции тоже есть свои минусы:</p>
                <ol>
                    <li>Лучше всего предусмотреть ее установку еще на этапе разработки проекта дома, чтобы воздуховоды можно было спрятать за потолком.</li>
                    <li>В случае, если дом уже построен, установка системы проходит не без сложностей: нужно понять, где она будет расположена. Воздуховодов много. Крупные воздуховоды могут испортить весь интерьер, нужно предусмотреть, как их спрятать. Это, в свою очередь, может привести к значительному понижению потоков в доме. Если хочется сделать второй свет, реечный потолок, оставить балки — эта система не подойдет.</li>
                    <li>Стоимость системы в стандартной комплектации без тонкой фильтрации, ионизатора, датчиков CO² и других дополнительных элементов составит от 300 до 500 тысяч рублей минимум, точная цена зависит от комплектации и площади дома.</li>
                    <li>Система работает постоянно, со стандартными настройками для всех помещений. вне зависимости от того, сколько людей где находится, какой уровень углекислого газа, нужно ли проветривание в той или иной комнате вообще. Нет никакой возможности сделать индивидуальные настройки. Есть регулировка скоростей вентилятора, но это довольно примитивно.</li>
                </ol>
                <p>Последний из перечисленных минусов не следует недооценивать: потребности у каждого члена семьи могут быть разными. Кто-то хочет засыпать в прохладной спальне, при температуре 17−22 С ̊. Кому-то, наоборот, хочется быть в тепле. Днем хочется, чтобы в помещениях было теплее, а ночью — прохладнее. В спальне пока никого нет, в гостиной собралось много гостей, в детской — новорожденный. </p>
                <p>Всё это составляет разные требования к микроклимату, часто разница составляет 1−5 С ̊, на такие тонкие колебания централизованная система не способна.Если вы надолго уезжаете из дома — систему надо отключить. К сожалению, нельзя ее настроить так, чтобы она включалась для профилактического проветривания самостоятельно.</p>
                <p>Это уже функционал «умного дома», такая система обойдется значительно дороже, так как в ней предусмотрено очень многое, в том числе и сигнализация.</p>

                <h2>Локальная вентиляция принудительного типа</h2>
                <p>Функциональность централизованной системы вентиляции достаточно широка, например, она может обеспечивать фильтрацию поступающего в помещение воздуха. При этом обустройство таких систем связано с определенным набором минусов:</p>
                <ul>
                    <li>дорогостоящее оборудование;</li>
                    <li>необходимость обращения к услугам профессионалов для укладки воздуховодов большого сечения;</li>
                    <li>потребность в техническом помещении для установки центрального блока, что связано не в последнюю очередь с шумностью его работы.</li>
                </ul>
                <p>В связи с этим в некоторых случаях, особенно применительно это к небольшим домам, выгоднее установить локальную вентиляцию рассматриваемого вида.</p>
                <p>Вентустановки локального типа – это малогабаритные устройства, которые своим внешним видом напоминают кондиционеры. При этом они оснащаются вентиляторами, рекуператором, подогревателем и т. д. Подключение к ним воздуховодов не предусмотрено.</p>
                <p>В части жилых помещений дома такие устройства способны закрыть проблему вентиляции, чего нельзя сказать в отношении ванной комнаты, кухни, подвала и других подобных помещений. Применительно к ним потребуется монтаж воздуховодов естественной вентиляции. Совмещая два ее упомянутых вида, вполне доступно собственными силами, так как существующие инструкции и схемы по этому поводу дают ясное понимание процесса предполагаемых работ.</p>
                <p>Центральную систему вентиляции Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку центральной вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default CentralVentilation