import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './airConditioningBuildings.css'

import img213 from '../../../img/21309-s19-3.jpg'
import img134 from '../../../img/134061-multi_split_sistema_kondicionirovanija_dlja_nebolshogo_ofisa.png'
import img155 from '../../../img/155105-multizonalnaja_sistema_kondicionirovanija_cena_preimushhetsva_princip_raboty.png'
import img132 from '../../../img/132273-centralnyj_kondicioner_princip_raboty_pvu_s_sekciej_ohlazhdenija.png'

const AirConditioningBuildings = () => {
    return (
        <>
            <div className='air-conditioning-buildings-page'>
                <h1>Кондиционирование административного здания</h1>
                <p>Систему кондиционирования административного здания Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Установка системы кондиционирования в административное здание заслуживает особого внимания. Она призвана обеспечить качественный воздухообмен и должна поддерживать нужную температуру при довольно сложной системе распределения воздуха. Вдобавок, всё это должно соблюдаться в рамках законодательства РФ.</p>
                <p>Системы кондиционирования могут насчитывать до 400 внутренних блоков - это говорит о том, что количество помещений в здании особой роли не играет. Самые распространенные системы кондиционирования на сегодняшний день - мультизональные VRV и VRF, а также чиллер-фанкойл.</p>

                <h2>Требования к системе кондиционирования административных зданий</h2>
                <p>К системам кондиционирования применяют достаточно строгие требования, которые должны обеспечивать комфортное нахождение людей в помещении административного здания.</p>
                <p>Основные требования к системе вентиляции административного здания: </p>
                <img src={img213} alt='' />
                <ul>
                    <li>Очистка от органической и минеральной пыли;</li>
                    <li>Обеспечение содержание в составе воздуха кислорода около 21% и не более 0.3% углекислого газа;</li>
                    <li>Обеспечение температуры в пределах 20-24°С;</li>
                    <li>Обеспечение относительной влажности воздуха - 50-60%;</li>
                    <li>Обеспечение подвижности воздушной массы 0.1-0.15 м/с.</li>
                </ul>
                <p>Данные требования для поддержания идеальных комфортных условий в помещениях могут быть реализованы только с использованием систем кондиционирования административных зданий.</p>

                <h2>Важные нюансы, которые помогут создать правильную систему</h2>
                <p>Если Вы уже приобрели кондиционеры или другие подобные приборы, соблюдайте такие вот условия:</p>
                <ul>
                    <li>Монтируйте агрегаты в правильных местах. Важно, чтобы потоки воздуха кондиционера не были направлены непосредственно на Ваших сотрудников, иначе обдув холодным воздухом приведет к простуде, а это грозит Вам больничными.</li>
                    <li>Если приборы уже установлены, Вы должны знать и то, как о них заботиться (обслуживание). По принципу работы установки, охлажденный воздух сначала проходит сквозь воздушный фильтр, который очищает от пыли, грязи и аллергенов, а уже потом выходит в помещение. Важно регулярно чистить его – всего лишь промыть водой и дать высохнуть – иначе вся грязь с фильтра будет попадать к Вам в офис.</li>
                    <li>Обслуживания требуют и другие важные элементы, к примеру, турбина, которая забирает воздух, а потом заново выдает его в помещение, тоже загрязняется и выдает все в воздух. Для ее очистки важно вызывать мастера.</li>
                    <li>Всем известен факт про то, что нельзя открывать окна и двери при работающем кондиционере иначе может возникнуть поломка, либо воздух не будет охлаждаться – но мы предпочли все же напомнить об этом во избежание проблем.</li>
                    <li>Если Вы не хотите заболеть, то когда приходите с улицы, после жары – не включайте сразу же систему охлаждения, иначе резкое переохлаждение сразу даст о себе знать.</li>
                </ul>

                <h2>Виды систем кондиционирования административных зданий</h2>
                <p><strong>Сплит-система</strong></p>
                <p>* Один наружный блок + 1 внутренний</p>
                <p>Минусы: если Вы размещаете более 5 блоков, то фасад здания теряет свой внешний вид из-за множества навешанных наружных блоков; длина фреоно-провода составляет всего 15 м, а это сужает выбор места установки.</p>
                <p>Преимущества: бюджетный вариант, отлично подойдет для небольших или арендуемых помещений; хорошая эффективность (если подобрать подходящие технические характеристики); большое разнообразие моделей с различным функционалом.</p>
                <p>Для каких помещений подходит: одна модель подойдет под офис площадью 20 м2, и так можно поставить по одной в каждый такой кабинет.</p>
                <p><strong>Мульти сплит – система</strong></p>
                <p>* Один наружный блок + несколько внутренних (до 5)</p>
                <p>Минусы: можно подключить только до 5 внутренних блоков, поэтому подойдет не для всех помещений, так же все внутренние блоки должны быть только одного типа (либо канальные, либо настенные и т.д.). Фреоно-провода идут от каждого внутреннего блока непосредственно к наружному (в мультизонольной немного удобнее это предусмотрено), а общая длина, если приплюсовать каждый такой провод, должна составлять 70 м.</p>
                <p>Преимущества: идеально подойдет для средних помещений, где набора сплит-систем будет недостаточно, а слишком большая установка не нужна. Практически все внутренние блоки инверторного типа, а это большой плюс для комфорта. Средний вариант по цене, между сплит-системой и мультизональной. Такой тип потребляет не много электроэнергии, поэтому Вы сможете даже немного сэкономить.</p>
                <p>Для каких помещений подходит: для средних офисных помещений площадью от 100 до 150 м2.</p>
                <img src={img134} alt='' className='img-width' />
                <p><strong>Мультизональная система кондиционирования (VRV/VRF)</strong></p>
                <p>* Один наружный блок + множество внутренних (от 2 до 64)</p>
                <p>Минусы: это дорогая установка, но для больших офисов или центров будет идеальным решением; все блоки должны работать либо на тепло, либо на холод (один блок обогревать воздух не может, пока остальные охлаждают).</p>
                <p>Преимущества: все модели типа Inverter, что является большим плюсом; к одному внешнему блоку можно монтировать очень много разных внутренних блоков, то есть можно использовать и канальные, и настенные, и потолочные модели, другие. Если Вам важна работа на обогрев, то такая система будет гораздо лучше работать на тепло, нежели другие вышеуказанные, эффективнее. Так же отличается строение фреонопроводов: все внутренние блоки подключаются к одному проводу, который ведет к наружному прибору, а общая протяжность кабеля составляет 1 км.</p>
                <p>Для каких помещений подходит: подойдет такая система для всех, но лучше всего устанавливать в офисы площадью от 200 м2, ведь не логично покупать дорогую систему для маленьких помещений до 50 м2. Можно монтировать в большие офисные помещения, офисные здания, бизнес-центры и другие подобные.</p>
                <img src={img155} alt='' className='img-width' />
                <p><strong>Приточно-вытяжная вентиляция с секцией охлаждения</strong></p>
                <p>* приточно-вытяжная установка + секция охлаждения + чиллер или тепловой насос</p>
                <p>Такая система предназначена для кондиционирования свежего нового воздуха, то есть воздух поступает с улицы в ПВУ, фильтруется, далее попадает в секцию охлаждения, где температура чистого воздуха снижается до требуемой температуры и распространяется по помещению за счет воздуховодов или фанкойлов. У такой установки есть один нюанс – она не может охлаждать (либо нагревать) воздух самостоятельно, поэтому «секцию холода» сначала остужает чиллер с помощью фреона или воды, а потом уже секция охлаждает воздух. Такая установка так же называется центральный кондиционер.</p>
                <p>Преимущества: полноценный воздухообмен (приток свежего и вытяжка загрязненного воздуха) в тандеме с качественным охлаждением, подходит для больших объектов.</p>
                <p>Минусы: требует дополнительного снабжения по холоду или теплу; сложный монтаж с прокладкой коммуникаций по зданию.</p>
                <p>Для каких помещений подходит: Это неплохой вариант, если Вы владелец большого бизнес-центра или офисного здания больших объемов.</p>
                <img src={img132} alt='' className='img-width' />
                <p>Систему кондиционирования административного здания Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default AirConditioningBuildings