import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './airConditionerMaintenance.css'

const AirConditionerMaintenance = () => {
    return (
        <>
            <div className='air-conditioner-maintenance-page'>
                <h1>Обслуживание кондиционеров (систем кондиционирования)</h1>
                <p>Обслуживание кондиционеров Вы можете заказать, позвонив по телефону в Ташкент: <a href='te:+998903559091'>+998 (90) 355-90-91</a>. Проектирование, поставка и обслуживание систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>FIDCLIMAT осуществляет комплексный подход к техническому обслуживанию и ремонту климатических систем: от планового технического обслуживания до быстрого реагирования в экстренных ситуациях при выходе оборудования из строя.</p>
                <p>Мы заключаем контракты и учавствуем в тендерах на техническое обслуживание по всем видам промышленного, коммерческого, бытового оборудования и инженерных систем, включая офисы, склады, производственные предприятия, общественные здания и объекты здравоохранения, жилищных товариществ.</p>
                <p>Специалисты сервисного департамента оперативно и эффективно реагируют на любые чрезвычайные ситуации.</p>
                <p>FIDCLIMAT предлагает обслуживание и ремонт 7 дней в неделю, 24 часа в сутки. Мы предоставляем разнообразные пакеты обслуживания и предоставляем наиболее конкурентоспособные цены в отрасли.</p>
                <p>Специалисты Сервисного Департамента FIDCLIMAT осуществляют комплексное техническое обслуживание: контроль технического состояния, периодические осмотры, гарантийный и текущий ремонт, послегарантийное обслуживание всех видов климатического оборудования и инженерных систем:</p>
                <ul>
                    <li>Комплексное техническое обслуживание систем кондиционирования, холодоснабжения, вентиляции и теплоснабжения;</li>
                    <li>Обслуживание отдельных систем и оборудования (чиллеры, центральные кондиционеры, холодильные установки, VRV и VRF-системы, приточные установки, фанкойлы, сплит-системы, осушители, увлажнители воздуха  и т.п.);</li>
                    <li>Электронное тестирование и контроль параметров систем кондиционирования и вентиляции с использованием современных приборов;</li>
                    <li>Диагностика и полное комплексное тестирование климатической техники и инженерных систем;</li>
                    <li>Проведение пуско-наладочных работ на вновь смонтированном и/или временно законсервированном оборудовании;</li>
                    <li>Гарантийный и текущий ремонт оборудования любой сложности;</li>
                    <li>Аварийные выезды, связанные с ремонтом систем кондиционирования, вентиляции, холодоснабжения и автоматики;</li>
                    <li>Бесплатные консультации высококвалифицированных сервис - инженеров.</li>
                </ul>

                <h2>Гарантийный ремонт</h2>
                <p>FIDCLIMAT предоставляет гарантию на все реализованное оборудование и выполненные работы. В течение гарантийного срока мы бесплатно устраняем неисправности, вызванные заводским дефектом оборудования.</p>
                <p>Сервисный центр обеспечивает выполнение гарантийных обязательств компании перед покупателями, данных им при совершении сделки.</p>
                <p>В случае подтверждения гарантийного статуса, Сервисный Центр осуществляет:</p>
                <ul>
                    <li>диагностику неисправного оборудования;</li>
                    <li>устранение неисправности;</li>
                    <li>в случае невозможности ремонта сервисный центр выполняет замену неисправного оборудования на аналогичное новое, либо оформляет возврат. </li>
                </ul>

                <h2>Сервисное техническое обслуживание</h2>
                <p>Любое сложное инженерное оборудование, а особенно такое, как климатическое, работающее в условиях воздействия окружающей среды, требует обязательного проведения профилактических работ.</p>
                <p>После истечения гарантийного периода заказчик может заключить договор на послегарантийное обслуживание.</p>
                <p>Целью технического обслуживания является содержание систем кондиционирования и вентиляции в пределах технических условий, необходимых для поддержания выходных характеристик в заданных пределах, диагностики технического состояния и предупреждение отказов инженерных систем и оборудования.</p>
                <p>Компании-производители гарантируют безотказную работу оборудования при условии  проведении планового технического обслуживания, а так же своевременного определения износа деталей и узлов. Чтоб техника служила долго и безотказно, не стоит пренебрегать этими требованиями. Главная задача сервисного центра - обеспечение надежной работы оборудования как в период гарантийной, так и послегарантийной эксплуатации. Кроме того, мы занимаемся анализом статистических данных по результатам эксплуатации поставляемого оборудования. Все это позволяет оперативно реагировать на проблемы, возникающие в ходе эксплуатации оборудования и систем.</p>
                <p>При заключении сервисного контракта мы берем на себя обязанности по техническому обслуживанию и ремонту Вашей климатической техники и инженерных систем.</p>

                <h2>Авторизованный сервисный центр</h2>
                <p>На базе FIDCLIMAT открыт авторизованный сервисно - технический центр, осуществляющий сервисное обслуживание, ремонт, профессиональную диагностику, наладку и модернизацию всего спектра поставляемой техники. Постоянное наличие на собственном складе всех необходимых расходных материалов и запчастей позволяет выполнять оперативное техническое обслуживание и ремонт оборудования в максимально сжатые сроки. Всем клиентам, обратившимся по телефону, сервисный центр оказывает бесплатные технические консультации.</p>

                <h2>Ремонт оборудования</h2>
                <p>Работы выполняются на территории заказчика. Сложные виды ремонта выполняются в специализированной мастерской сервисного центра, оснащенной самыми современными диагностическими приборами и инструментом.</p>

                <h2>Программы обслуживания</h2>
                <p>Сервисный Центр FIDCLIMAT работает с партнерами как на долгосрочной основе в рамках сервисных пакетов абонентского обслуживания (год, полугодие, квартал, ежемесячно), так и при разовом обращении, гарантируя при этом оперативное реагирование, качество и надежность обслуживания, ремонт техники и инженерных систем.</p>

                <h2>Опыт и квалификация</h2>
                <p>Благодаря наличию истории всего проданного, ремонтного и обслуживаемого оборудования, в распоряжении сотрудников имеется архив систематизированной технической документации, что позволяет ещё до выезда на объект составить представление о вышедшем из строя оборудовании, ознакомиться с его техническими характеристиками и особенностями.</p>
                <p>Специалисты сервисного центра поддерживают контакты с российскими и зарубежными компаниями-производителями, что позволяет оперативно вносить какие-либо технические изменения в конструкцию или алгоритм работы оборудования. Это повышает эксплутационные характеристики и обеспечивает бесперебойную работу техники в любых климатических условиях.</p>
                <p>Сотрудники сервисного центра регулярно повышают квалификацию в учебных центрах компаний-производителей.</p>
                <p>Разработанная специально для служб сервиса информационная система позволяет персонифицировать ответственность специалистов за выполняемые ими операции и гарантирует оперативность и качество выполненных услуг.</p>
                <p>Специалисты проводят консультирование и обучение эксплуатационной службы партнеров основным правилам использования и ухода за оборудованием и климатическими системами.</p>

                <h2>Оснащение специалистов</h2>
                <p>Обслуживание и ремонт производят квалифицированные инженеры сервисного центра, оснащенные профессиональным инструментом и оборудованием.</p>
                <p>В своей работе сервисная служба компании FIDCLIMAT использует специальное высокотехнологичное измерительное, сервисное оборудование известных фирм производителей: REFCO, CPS, WIGAM, ITE, ROTHENBERGER, позволяющее производить замеры любых параметров, осуществлять диагностику, производить ремонт, пуско-наладку систем кондиционирования, вентиляции и теплоснабжения любой категории сложности. Для чистки теплообменных поверхностей применяется оборудование KARCHER: мойки высокого давления, парогенераторы, воздушные компрессоры.</p>
            </div>
            <Form />
        </>
    )
}

export default AirConditionerMaintenance