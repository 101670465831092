import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './chillersFancoils.css'

import chiller from '../../../img/Chiller5_1.gif'
import EWWDCJYNN from '../../../img/EWWDCJYNN(1).jpg'
import FWVC from '../../../img/FWVC.jpg'
import Chiller7_1FWVC from '../../../img/Chiller7_1.gif'
import EWADBJYNN_A from '../../../img/EWADBJYNN_A.gif'

const ChillersFancoils = () => {
    return (
        <>
            <div className='chillers-fancoils-page'>
                <h1>Система "чиллер - файнкойл"</h1>
                <p>Систему чиллер-файнкойл Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем чиллер-файнкойл по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Особенности системы "чиллер-фанкойл"</h2>
                <p>Системы с чиллерами и фанкойлами позволяют обеспечить независимое регулирование температуры одновременно в большом количестве помещений, например, в гостиницах, офисах и т.д. Фанкойлы могут произвольно включаться и выключаться, изменять свою холодно- или теплопроизводительность.</p>
                <p>Кроме фанкойлов, в качестве потребителей могут быть теплообменники центрального кондиционера, какое-либо технологическое оборудование.</p>
                <img src={chiller} alt='' />
                <p>Отличительной особенностью системы чиллер-фанкойл является теплоноситель. Охлаждение производится жидкостью, циркулирующей по системе трубоповодов от источника холода к конечному потребителю. Источником холода является охладитель жидкости  - так называемы чиллер.</p>
                <p>Системы с чиллер - фанкойлы позволяют вводить здание в эксплуатацию, постепенно наращивая количество потребителей.</p>
                <p>Чиллер - холодильная машина, предназначенная для охлаждения воды, используемая в центральных системах кондиционирования. Она охлаждает или подогревает теплоноситель (тосол, вода) и подает его по системе трубопроводов в фанкойлы (или другие теплообменники). Чиллеры охватывают большой диапазон мощностей: от нескольких единиц до нескольких тысяч киловатт и различаются: по конструктивному исполнению (со встроенным или выносным конденсатором); типу охлаждения конденсатора (воздушное или водяное); наличию теплового насоса. </p>
                <img src={EWWDCJYNN} alt='' />
                <p>Фанкойл - внутренний блок, включающий в себя: вентилятор, теплообменник, фильтр для очистки воздуха и пульт управления. Фанкойлы устанавливаются в помещении под окном, на стене, под потолком, в потолке в зависимости от модификации и типа. В фанкойле вторичный воздух, или смесь первичного и рециркуляционного воздуха в зависимости от периода года охлаждается или нагревается в теплообменнике. К фанкойлам по системе трубопроводов подводится холодная вода в теплый период года или горячая вода в переходный или холодный периоды года.</p>
                <p>Если система кондиционирования воздуха устраивается в реконструируемом здании, где имеется система отопления, то фанкойлы работают в режиме охлаждения в теплый период и нагревания в холодный.</p>
                <img src={FWVC} alt='' />
                <p>Системы чиллер - фанкойл являются идеальным решением для установки в офисах, торговых комплексах и отелях, где требуется зональное управление параметрами окружающего воздуха, так как каждый фанкойл имеет индивидуальную схему управления. Чиллер может быть расположен наверху, внизу, или на любом из этажей в соответствии с архитектурными особенностями здания и выполняемой задачей. При правильно подобранном водяном насосе длина трубопроводов (расстояние от чиллера до фанкойла) может быть практически любой.</p>

                <h2>Преимущества системы "чиллеров-фанкойлов"</h2>
                <p>Система чиллеров-фанкойлов имеет следующие преимущества:</p>
                <ul>
                    <li>Система обладает большой гибкостью при кондиционировании большого количества помещений. К одному чиллеру может подключаться большое количество фанкойлов, а также теплообменники центрального кондиционера или приточной вентиляционной установки. Каждый потребитель может работать практически независимо друг от друга - изменять режим работы, включаться или отключаться.</li>
                    <li>Можно задавать не только общий тепловой режим всей системы, но и регулировать режим работы каждого фанкойла с выносного пульта управления либо вмонтированного в фанкойл, либо установленного на стене помещения.</li>
                    <li>Можно постепенно наращивать мощность потребителя, что позволяет вводить объект в эксплуатацию постепенно, отдельными этапами.</li>
                    <li>Предельное расстояние между чиллером и фанкойлом не лимитируется и определяется возможностями насосной станции и теплоизоляцией трубопроводов.</li>
                </ul>
                <img src={Chiller7_1FWVC} alt='' />
                <p>Основным конкурентом системы кондиционирования чиллер - фанкойл на небольших и средних объектах высшей категории является VRF и VRV  система. Это более интеллектуальная система с фреоновым теплоносителем. VRF и VRV  системы более дороги, но открывают более широкие возможности в центральном мониторинге и управлении, а также в сфере энергосбережения.</p>
                <p>В заключение отметим, что система чиллер-фанкойл твердо занимает свою нишу и конкуренция с VRF и VRV  происходит в основном в строящихся бизнес-центрах и больших богатых загородных домах.</p>
                <p>Из особенностей системы можно выделить простой монтаж, но сложное проектирование. Также нельзя не отметить гибкость и изменяемость конфигурации, возможность применения фанкойлов любых видов и марок.</p>
                <p>В сравнении с VRF и VRV  системами коммуникации системы чиллер-фанкойл занимают большее пространство, но сами водяные трубы гораздо дешевле фреоновых медных труб. Что касается обслуживания, то по сравнению с VRF и VRV  системами, чиллер требует более квалифицированного и многочисленного сервисного персонала.</p>

                <h2>Принцип работы чиллера и область применения</h2>
                <p>Chiller (англ.) – водоохлаждающая машина. Базовый элемент систем центрального кондиционирования. Одна из основных схем компоновки такой системы – “чиллер и фэнкойл” (водоохлаждающая машина и кондиционер доводчик / вентиляторный конвектор).</p>
                <p>Применяются как для комфортного (жилые, общественные, административные здания), так и для технологического (производственные здания) кондиционирования воздуха.</p>
                <p>Центральные системы кондиционирования и вентиляции (ЦСКВ) являются на сегодняшний день самой совершенной концепцией централизованного кондиционирования; позволяют “индивидуализировать” климат в конкретном помещении и на определённом пространстве / площади, и одновременно обеспечивают многофакторное и глубокое регулирование параметров микроклимата. В их функции входят: очистка воздуха, нагрев или охлаждение уличного (подаваемого) воздуха, который затем распределяется по внутренним помещениям с помощью системы воздуховодов (система раздачи воздуха).</p>
                <img src={EWADBJYNN_A} alt='' />
                <p>Чиллер является главным узлом в системе центрального кондиционирования, может не только охлаждать воздух, но, при наличии теплового насоса, способен нагнетать теплый воздух внутрь помещения.</p>
                <p><strong>Чиллеры можно разделить на два основных типа:</strong></p>
                <ul>
                    <li>с воздушным охлаждением конденсатора,</li>
                    <li>с водяным охлаждением конденсатора.</li>
                </ul>
                <p>Оба типа чиллеров могут быть выполнены как со встроенной насосной станцией (гидравлическим контуром), так и без нее.</p>
                <p><strong>Чиллеры с воздушным охлаждением можно разделить на три группы по их расположению (установке) относительно помещения:</strong></p>
                <ul>
                    <li>чиллеры с осевым вентилятором для охлаждения конденсатора, устанавливаются вне помещения: на балконах, улице, плоских крышах;</li>
                    <li>чиллеры с центробежным вентилятором для охлаждения конденсатора, могут устанавливаться в замкнутых помещениях внутри зданий (подвалах, чердаках, служебных помещениях);</li>
                    <li>чиллеры с выносным конденсатором (их также можно устанавливать в замкнутых помещениях, а конденсаторный блок инсталлировать вне помещения).</li>
                </ul>

                <h2>Центральный кондиционер, который подает свежий и охлажденный воздух по системе воздуховодов (системе раздачи воздуха)</h2>
                <p>Внутренние блоки (фанкойлы – кондиционеры-доводчики), являются конечным звеном этой системы и соединяются с центральным блоком с помощью труб, по которым циркулирует охлажденная вода или специальный раствор. Фанкойлы могут быть различных моделей и мощностей (основная характеристика – “полная явная холодопроизводительность”) и напоминают по внешнему виду внутренние блоки сплит-систем.</p>
                <p>И чиллер, и кондиционер могут располагаться в подвале, на техническом этаже, чердаке, что позволяет сохранить внешний облик здания в первозданном виде, а, значит, оптимизировать визуальную концепцию здания.</p>
                <p>К функциям центрального кондиционера относится не только охлаждение воздуха, но и его нагрев, что может полностью заменить систему парового отопления в зимнее время, для этого в фанкойлы подается горячая вода по второму контуру или производится переключение системы с чиллера на отопительный котел.</p>

                <h2>Общий обзор и основные исполнения чиллеров</h2>
                <ol>
                    <li>Стандартные установки. Для установки как снаружи, так и внутри помещения</li>
                    <li>Установки с переключением в режим теплового насоса. Применяются в период отключения отопления здания и в прохладную погоду, для обогрева помещений в переходный и тёплый периоды года для возможного нагрева помещений при отсутствии централизованного теплоснабжения.</li>
                    <li>Установки со свободным охлаждением.  Применяются для охлаждения помещений с круглогодичными тепловыделениями.</li>
                    <li>Установки с рекуперацией тепла. Применяются в переходные периоды при мотивированном использовании вторичного тепла (с нагревом воды до температуры около 40°С).</li>
                    <li>Специальные малошумные и сверхмалошумные модели. Применяются для наружной установки при наличии ограничений по уровню шума, например: вблизи жилых зданий, больниц и т.д.</li>
                    <li>Установки со встроенным гидромодулем.  Имеют встроенную насосную станцию и накопительный бак. Серийные установки со встроенным одним или двумя циркуляционными насосами и буферным баком.</li>
                </ol>
                <p>Виды применяемых компрессоров: герметичные, полугерметичные, спиральные (или scroll-компрессоры), винтовые.</p>

                <h2>Абсорбционные чиллеры</h2>
                <p><strong>Абсорбционные чиллеры</strong> – применяются в условиях дефицитности электроэнергии. Характеризуются наличием термохимического компрессора. Состоят из теплового генератора, конденсатора, испарителя, абсорбера и насосов. Позволяют использовать вторичные энергоресурсы (“сбросное” тепло горячей воды, перегретого водяного пара, сгорания природного газа, мазута, других нефтепродуктов). Применяется теплоиспользующий принцип, в отличие от традиционных компрессионных машин.</p>
                <p><strong>Конструктивные особенности исполнений</strong></p>
                <p>Несущие каркасы из оцинкованной стали с защитным порошковым покрытием. Компрессоры со смотровым глазком контроля уровня масла. Электропитание моделей от 230 В / 1 фаза / 50 Гц (для менее мощных исполнений) до 400 В / 3 фазы / 50 Гц. Виброизолирующие шасси в большинстве исполнений, высококачественная элементная база, микропроцессорные блоки, системы автоматики, многофакторная аварийная защита. Высококачественные компрессоры, качественная сварка трубопроводов с многоуровневым контролем. </p>
                <p><strong>Вы всегда можете быть уверены, что при постановке задачи по выбору того или иного чиллера, решение может включать целый спектр оборудования с учетом всех ваших требований по цене, энергопотреблению и энергоэффективности, опциональной насыщенности системы в целом и даже общей визуальной концепции.</strong></p>
                <p>Систему чиллер-файнкойл Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем чиллер-файнкойл по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ChillersFancoils