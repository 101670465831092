import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './hotelAirConditioning.css'

import img11 from '../../../img/11234_small.jpg'
import img947 from '../../../img/94700sac-welcoming-atmosphere-hotels-feature-images-1.jpgorigin_jpg'
import img848 from '../../../img/84894sac-welcoming-atmosphere-hotels-feature-images-2.jpgorigin_jpg'
import img702 from '../../../img/70203sac-welcoming-atmosphere-hotels-feature-images-4.jpgorigin_jpg'
import img758 from '../../../img/75831sac-welcoming-atmosphere-hotels-feature-images-5.jpgorigin_jpg'
import img805 from '../../../img/80506sac-welcoming-atmosphere-hotels-feature-images-6.jpgorigin_jpg'

const HotelAirConditioning = () => {
    return (
        <>
            <div className='hotel-air-conditioning-page'>
                <h1>Системы кондиционирования гостиниц или отелей</h1>
                <p>Систему кондиционирования гостиницы или отеля Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования гостиниц по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <img src={img11} alt='' />
                <p>Специфика гостиничного бизнеса выдвигает довольно строгие требования как к техническим характеристикам, так и к дизайну климатической техники: для любого туриста или совершающего деловую поездку бизнесмена, гостиница - это, прежде всего, место отдыха, поэтому оборудование должно работать гораздо тише, чем в офисах или кафе. Ко всему прочему, обращение с климатической техникой необходимо сделать предельно простым и доступным: человек, никогда в жизни не имевший дела с кондиционером, должен легко разобраться с управлением. Учесть все эти требования и подобрать оптимальный способ кондиционирования сможет только профессионал.</p>

                <h2>Особенности систем кондиционирования воздуха в гостиничном</h2>
                <p>Правительством РФ и субъектов РФ принято и реализуется множество программ по развитию и усовершенствованию зданий туристического комплекса в различных городах Узбекистан. В связи с выполнением таких программ строительство гостиниц растет высокими темпами.</p>
                <p>Потребность этого рынка велика как в гостиницах высокой так и средней категории. Каждая категория гостиниц имеет общие с остальными и индивидуальные технические требования к инженерным системам, в частности к системам вентиляции и кондиционирования. К единому перечню технических требований к климатическим системам относятся основные (обеспечение оптимальных параметров по поддержанию температуры, скорости движения воздуха, его чистоты) и индивидуальные (дополнительная очистка, поддержание влажности и т. д.). Помимо нормативных документов, регламентирующих расчет систем вентиляции и кондиционирования воздуха, заказчик в большинстве случаев составляет свое техническое задание, выдвигая дополнительные требования. Согласно нормативам, расчетная температура для стандартного кондиционируемого гостиничного номера составляет 20–22 0С, приток свежего воздуха — 60 куб. м/час. на человека, относительная влажность — 45–50%</p>
                <p>В помещение подается, согласно санитарной норме, приток свежего воздуха, а удаление отработанного воздуха осуществляется через помещение санузла. Кондиционирование обеспечивается, как правило, с помощью канальных низконапорных блоков, которые были разработаны с учетом архитектурных особенностей гостиничных номеров. Зачастую их называют канальными блоками гостиничного типа.</p>
                <p>Для обеспечения поддержания требуемых условий микроклимата применяют три основные принципиальные схемы кондиционирования воздуха. Схема с переменным расходом воздуха, при реализации которой в помещение подается воздух, обработанный в центральном кондиционере, в количестве, необходимом для поглощения теплоизбытков в помещении. К недостаткам такой схемы относятся: отсутствие возможности индивидуального регулирования температуры в каждом обслуживаемом помещении, большие расходы воздуха, большие диаметры воздуховодов и необходимость их дополнительной теплоизоляции, повышенное энергопотребление из-за сложности учета коэффициента загруженности номерного фонда. К достоинствам относятся: относительно низкие капитальные затраты, простота обслуживания и реализации проекта.</p>
                <p>В силу приведенных выше недостатков использование такой схемы для гостиниц высокой категории практически не приемлемо, и вследствие этого наиболее распространенными в мировой практике являются схемы с раздельной обработкой свежего приточного воздуха и автономными температурными доводчиками, обеспечивающими поддержание комфортной температуры индивидуально в каждом помещении. Согласно такой схеме, свежий воздух проходит обработку в центральном кондиционере (очистку не ниже класса EU4, подогрев/охлаждение, увлажнение/осушение) и подается в обслуживаемые помещения. Поддержание комфортной температуры обес-печивается индивидуально в каждом номере за счет установленного температурного доводчика (фанкойла или внутреннего блока системы с непосредственным охлаждением). К таким схемам кондиционирования можно отнести центральные фреоновые системы с непосредственным охлаждением (VRV) или системы с использованием промежуточного контура — так называемые чиллер-фанкойлы. </p>

                <h2>Варианты кодиционирования</h2>
                <p>Существуют на рынке климатической техники и кондиционеры, созданные специально для небольших отелей и туристских центров. Они представляют собой сплит-системы канального типа, внутренние блоки которых легко могут быть установлены в пространство подшивного потолка таким образом, чтобы из помещения были видны только декоративные решетки, причем цвет и форма этих решеток могут меняться в зависимости от интерьера. Такой способ кондиционирования наиболее оптимально подходит для гостиниц, где создание комфортного климата является непременным условием. Однако, главное достоинство гостиничных кондиционеров – они практически бесшумны. По звуку даже нельзя определить, где находится оборудование. Тем не менее, для полной тишины внутренний блок этих систем чаще всего располагают в прихожей номера, чтобы ничто не нарушало покой в комнате.</p>
                <p>В гостиницах квартирного типа и небольших пригородных мотелях можно обойтись обычными сплит-системами настенного типа. Это самый распространенный тип кондиционеров, его выпускают абсолютно все существующие сегодня компании. При относительно невысокой стоимости они замечательно справляются со своими функциями.</p>
                <p><strong>Инверторная мульти-сплит система</strong></p>
                <p>Такой тип организации системы кондиционирования хорошо подходит для гостиниц с небольшим номерным фондом (10-15 номеров), где имеется хотя бы немного места на наружных фасадах для размещения внешних блоков. Один внешний блок обслуживает работу пяти устройств, при этом внутренние блоки в этой системе работают автономно друг от друга, регулируются индивидуально в номере с использованием дистанционного ИК пульта.</p>
                <p>Инверторная технология достаточно экономна в эксплуатации, оборудование может работать с частичной загрузкой. К тому же покупка и установка одной такой системы на пять номеров обойдется заметно дешевле, чем суммарно пяти индивидуальных кондиционеров на каждый номер.</p>
                <p><strong>Система чиллер-фанкойл</strong></p>
                <p>Данная система кондиционирования гостиниц представляет собой разветвлённую сеть трубопроводов по которым течет холодная вода (наподобие системы отопления, только вместо котла выступает чиллер – холодильная машина, а вместо отопительных приборов – фанкойлы) с внутренними блоками кондиционирования гостиничных номеров – фанкойлами и внешним блоком чиллером (холодильной машиной – источником холода).</p>
                <p>Осноные плюсы данной системы кондиционирования гостиниц:</p>
                <ul>
                    <li>система кондиционирования гостиниц чиллер-фанкойл как правило дешевле чем vrv-система на 15-20% по капитальным затратам.</li>
                    <li>система чиллер-фанкойл кондиционирования гостиниц позволяет отключать кондиционирование в каждом номере отдельно (к примеру, для ремонта)</li>
                    <li>данная система кондиционирования гостиниц не имеет ограничений по длине трасс между внутренним и наружным блоком.</li>
                    <li>данная система кондиционирования гостиниц не имеет ограничений по мощности (путем параллельной установки чиллеров можно получить любую мощность холодоснабжения в одной системе)</li>
                </ul>
                <p>Минусы системы кондиционирования гостиниц чиллер-фанкойл:</p>
                <ul>
                    <li>данная система кондиционирования гостиниц тяжелей в эксплуатации чем vrv система кондиционирования офисов.</li>
                    <li>система кондиционирования гостиниц чиллер-фанкойл дороже в эксплуатации чем vrv система кондиционирования офисов</li>
                    <li>система чиллер-фанкойл занимает довольно много места в шахтах и конструкции подшивного потолка коридора (относительно vrv-системы кондиционирования гостиниц).</li>
                </ul>
                <p><strong>Центральное кондиционирование</strong></p>
                <p>Здесь все достаточно сложно и просто одновременно. В этом случае на всю гостиницу (или ее корпус) устанавливается один центральный кондиционер, от которого подготовленный воздух разводится по помещениям. Применение такой системы целесообразно только в больших комплексах. К тому же при выборе системы приходится жертвовать довольно большим количеством места под расположение самого центрального кондиционера и всей сети воздуховодов.</p>
                <p><strong>VRF система</strong></p>
                <p>Также неплохой вариант для среднего и большого отеля (15 номеров и более). Это на данный момент самая перспективная система. 10-15 внутренних устройств работают с одним общим внешним блоком, причем блок этот достаточно компактен и может устанавливаться на земле, на фасаде или крыше здания.</p>
                <p>Этот вариант предлагает более широкий опционал. Общая протяженность трасс с теплоносителем ограничивается примерно 1000 м, а максимальная отдаленность внешнего и внутреннего блока может достигать 90 м по горизонтали 70 м по высоте.</p>
                <p>По стоимости такая система обойдется где-то на 50% дороже, чем мульти-сплит система. VRF система менее энергозатратна чем описанные выше системы, она компактна.</p>
                <p>Существует большой выбор типов таких систем, некоторые из них обладают техническим ресурсом для бесперебойной работы с номерным фондом в несколько тысяч номеров. Возможна дополнительная опция удаленного управления и настройки через Интернет.</p>

                <h2>Какая должна быть система кондиционирования в отелях и гостиницах</h2>
                <p><strong>Совершенная система управления и высокие рабочие характеристики</strong></p>
                <p>Большинство помещений гостиниц существенно различаются по требованиям к кондиционированию. Системы кондиционирования должна обеспечивать широкий выбор наружных и внутренних блоков и контролеров с учетом объема помещений. Будут ли это гостевые комнаты, требующие стильного и индивидуального круглосуточного контроля или большие комфортные зоны, например рестораны или служебные офисы, требующие поддержания экономичной комфортности, решение Samsung способно удовлетворить всем этим требованиям.</p>
                <img src={img947} alt='' className='img-width' />
                <p><strong>Независимое управление отдельными зонами</strong></p>
                <p>Гостиницы могут нести убытки от повышенного расхода энергии, если у них нет возможности гибкой регулировки режима работы системы кондиционирования с учетом требований для каждой из рабочих зон и поэтому, должны обеспечивать эффективное независимое управление температурой и воздушным потоком в каждом помещении. Это особенно важно для секционных гостиничных зданий с офисами, конференц - залами, холлами и зонами инфраструктуры.</p>
                <img src={img848} alt='' className='img-width' />
                <p><strong>Простота настройки и управления</strong></p>
                <p>Обычные системы HVAC оснащены централизованной системой управления, что делает невозможным индивидуальную регулировку режима работы кондиционеров в разных помещениях. В системе кондиционирования используются индивидуальные контроллеры, которые позволяют осуществить персонализированное управление в конкретных помещениях, например, гостевых номерах. Центральный контроллер и простая система управления могут быть использованы для мониторинга и отслеживания условий в различных основных зонах отела, например в холле.</p>
                <p><strong>Создайте теплую и уютную атмосферу для гостей</strong></p>
                <p>Самое первое впечатление, которое получает посетитель при входе в отель или зону отдыха, очень важно и гость должен испытывать ощущение комфорта и уюта! Система кондиционирования охлаждает или нагревает воздух в определенной зоне используя центральную систему управления, что позволяет гостям всегда чувствовать себя комфортно. Минимизация перетока воздуха даже при большом количестве входящих и выходящих людей позволяет сохранять температуру в помещении стабильной.</p>
                <img src={img702} alt='' className='img-width' />
                <p><strong>Индивидуальная регулировка в номерах</strong></p>
                <p>Спальни в номерах - это те места, где гости хотят полностью расслабиться в комфортных условиях. Именно поэтому в системе кондиционирования должна быть предусмотрена возможность индивидуальной регулировки температуры каждым гостем с учетом его личных предпочтений. Кроме того, в системе возможно предусмотреть наряду с контролем температуры воздуха, нагрев воды без задействования отдельного нагревателя, а система Virus Doctor, способствует уменьшению количества потенциально опасных вирусов.</p>
                <img src={img758} alt='' className='img-width' />
                <p><strong>Свежий воздух в ресторанах отеля</strong></p>
                <p>Никому не хочется во время обеда в ресторане вдыхать застарелые запахи еды. Создайте свежую и приятную атмосферу без сквозняков для посетителей. Система кондиционирования должна предотвращать распространение запахов. Объединение в одной системе притока свежего воздуха и кондиционирования великолепно подходит для использования на кухне ресторана.</p>
                <img src={img805} alt='' className='img-width' />
                <p><strong>Тишина для повышения эффективности проведения собраний</strong></p>
                <p>Во время представления на собрании различных презентаций необходимо полное отсутствие посторонних шумов, чтобы аудитория не отвлекалась. Бесшумная работа системы кондиционирования позволяет сделать атмосферу в конференц-залах комфортной и обеспечивает результативное и эффективное общение. Эта система также обеспечивает быстрое равномерное охлаждение и нагрев воздуха в помещениях и может индивидуально контролироваться с помощью простого пульта дистанционного управления.</p>
                <p>Систему кондиционирования гостиницы или отеля Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования гостиниц по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HotelAirConditioning