import React from 'react'
import { Link } from 'react-router-dom'

import b1_small from '../../img/b1_small.jpeg'
import y796361514914685_elektro from '../../img/y796361514914685_elektro.jpg'
import u30062estestvennaja_cirkuljacija_1 from '../../img/u30062estestvennaja_cirkuljacija_1.jpg'
import b10131estestvennaja_cirkuljacija_2 from '../../img/b10131estestvennaja_cirkuljacija_2.jpg'
import i21557prinuditelnaja_cirkuljacija_1 from '../../img/i21557prinuditelnaja_cirkuljacija_1.jpg'
import i13781prinuditelnaja_cirkuljacija_2 from '../../img/i13781prinuditelnaja_cirkuljacija_2.jpg'
import yy7507konvektivnoe from '../../img/yy7507konvektivnoe-otoplenie-181x150.jpg'
import o6297radiator from '../../img/o6297radiator-registr_iz_trub-300x158.jpg'
import s8401kamennyj_radiator from '../../img/s8401kamennyj_radiator-213x300.jpg'
import b5900teplyj from '../../img/b5900teplyj-plintus-300x200.jpg'
import b12686centralizovannoe_vozdushnoe_otoplenie from '../../img/b12686centralizovannoe_vozdushnoe_otoplenie-300x213.jpg'

import './heating.css'
import Form from '../form/Form'

const Heating = () => {
    return (
        <>
            <div className='heating-component'>
                <h1>Системы отопления</h1>
                <p>Систему отопления Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href="mailto:fidclimat@mail.ru">fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <p>Отопление — это искусственный обогрев помещений с целью возмещения в них теплопотерь и поддержания на заданном уровне температуры, отвечающей условиям теплового комфорта и/или требованиям технологического процесса.</p>
                <img src={b1_small} alt='' className='img1' />

                <h2>Наши услуги</h2>
                <ul>
                    <Link to='/'>
                        <li>Проектирование отопления</li>
                    </Link>
                    <Link to='/'>
                        <li>Монтаж отопления</li>
                    </Link>
                    <Link to='/'>
                        <li>Обслуживание отопления</li>
                    </Link>
                    <Link to='/'>
                        <li>Ремонт отопления</li>
                    </Link>
                </ul>
                <p>Предстоит выбрать тип системы отопления экономически выгодной. В наших условиях нужно определится с тем, что доступно для сжигания, и окажется в лидеры по соотношению цена-удобство.</p>
                <p>Именно удобство играет очень большую роль, ведь отопление не должно превратиться во вторую работу, желательно чтобы процесс шел на автомате. Например, из-за самого лучшего удобства иногда выбирают электрический котел как главный теплогенератор, несмотря на то, что это в 5 раз дороже чем топить дровами.</p>
                <p>Схема включения электрокотла в отопительную сеть дома. Удобство достигается с буферной емкостью, — электрокотел может на всю мощь работать на ночном тарифе…</p>
                <img src={y796361514914685_elektro} alt='' className='img2' />

                <h2>Принцип работы системы отопления</h2>
                <p>Нагретый в котле теплоноситель движется по системе, постепенно отдавая тепло трубам и отопительным приборам, и далее - нагреваемому помещению.</p>
                <p>Поскольку трубы, котёл и радиаторы образуют замкнутую систему, то теплоноситель постоянно движется по кругу.</p>

                <h2>Три основных типа отопления</h2>
                <ul>
                    <li>Первый — это система так называемого «каминного» отопления. Она будет идеальной для вас, если вы строите небольшой по площади домик, к тому же в районе, где нет проблем с древесным, угольным, торфяным или другим топливом.</li>
                    <li>Второй тип отопления заключается в эксплуатации электрических конвекторов. Он приемлем и хорош, если вы хотите построить малогабаритный дом, в котором планируете проживать не постоянно, а от случая к случаю. В данном случае каждый электрический конвектор можно будет при необходимости включать или выключать, как любой другой электрический прибор.</li>
                    <li>Третий тип — это наиболее широко распространенная в нашей стране система водяного отопления, с использованием независимых источников тепла. Такая система отопления может использоваться практически в любых вариантах коттеджного строительства и подходит как для небольших по габаритам домов, так и для более больших построек.</li>
                </ul>

                <h2>Виды систем отопления</h2>
                <p>Cистемs отопления можно разделить по способу циркуляции теплоносителя на две большие группы:</p>
                <ul>
                    <li>с естественной циркуляцией (конвективная система);</li>
                    <li>с принудительной циркуляцией (от насоса).</li>
                </ul>
                <p>Рассмотрим, как работает каждая система.</p>

                <h2>Система отопления с естественной циркуляцией</h2>
                <p>Это самая простая (в смысле состава, но не монтажа!) система отопления:</p>
                <img src={u30062estestvennaja_cirkuljacija_1} alt='' className='img3' />
                <p>Теплоноситель нагревается в котле. Т. к. плотность воды при нагревании уменьшается, то она движется вверх по вертикальной трубе – подающему стояку. Вверху находится расширительный бак, куда вытесняется вода, увеличивая свой объём при нагреве. Затем вода растекается сверху вниз по нисходящим трубам (которые почему-то называются горизонтальными стояками (?), хотя стоять можно вроде бы только вертикально, ну да ладно) – обратным стоякам, и далее к отопительным приборам (радиаторам). Плотность остывшей воды больше, поэтому она из радиаторов стекает вниз, в "обратку", по которой возвращается в котёл.</p>
                <p>Диаметр вертикальных стояков должен быть достаточно большим, чтобы в системе возникла  побудительная сила к циркуляции теплоносителя.</p>
                <img src={b10131estestvennaja_cirkuljacija_2} alt='' className='img4' />
                <p><i>Важно! В системах отопления с естественной циркуляцией обязательно нужно учитывать уклоны!</i></p>
                <p>Во-первых, уклон от главного стояка в сторону отопительных приборов. Во-вторых, в «обратке» уклон должен быть в сторону котла. Если такие уклоны не будут соблюдены, система работать не будет.</p>

                <h2>Система отопления с принудительной циркуляцией</h2>
                <img src={i21557prinuditelnaja_cirkuljacija_1} alt='' className='img5' />
                <p>Теплоноситель в такой системе движется благодаря действию циркуляционного насоса 5 (см. рис. выше). Насосы для систем отопления выпускаются разных мощностей. О том, как выбрать мощность насоса для конкретной системы отопления, рассказывается в отдельной статье.</p>
                <img src={i13781prinuditelnaja_cirkuljacija_2} alt='' className='img6' />
                <p>Как видно на схеме, теплоноситель нагревается в котле; по подающему трубопроводу под действим циркуляционного насоса теплоноситель движется к приборам отопления. На схеме на каждом радиаторе также показаны вентили, за счёт которых можно регулировать температуру каждого радиатора. Вентили могут быть ручные либо автоматические, но об этом подробно рассказано в других материалах сайта.</p>
                <p>На радиаторах стоят специальные краны Маевского для удаления воздуха из системы. И по обратному трубопроводу ("обратке") охлаждённый теплоноситель возвращается в котёл.</p>
                <p>Закономерный вопрос: какую систему выбрать для своего дома, с принудительной циркуляцией или с естественной? Для этого рассмотрим преимущества и недостатки каждой системы.</p>

                <h2>Достоинства и недостатки разных видов систем отопления</h2>
                <p>Рассмотрим плюсы и минусы систем с естественной и принудительной циркуляцией теплоносителя.</p>

                <p><strong>Достоинства системы с принудительной циркуляцией:</strong></p>
                <ul>
                    <li>возможность автоматического управления тепловым потоком от радиаторов: можно задать температуру отдельно для каждой комнаты, и заданный температурный режим будет автоматически поддерживаться;</li>
                    <li>такая система более экономична в плане потребления топлива - как раз из-за возможности автоматического регулирования;</li>
                    <li>можно применять пластиковые трубы вместо стальных, что уменьшит стоимость материалов и монтажных работ;</li>
                    <li>дизайн помещения не портится видом трубопровода, т. к. пластиковые трубы зачастую даже скрывают в стенах.</li>
                </ul>

                <p><strong>Недостатки системы с принудительной циркуляцией:</strong></p>
                <ul>
                    <li>зависимость работы системы отопления от электропитания, т. к. циркуляционный насос работает от электросети.</li>
                </ul>

                <p><strong>Преимущества системы с естественной циркуляцией:</strong></p>
                <ul>
                    <li>система не привязана к источнику электроэнергии, так как в ней отсутствует циркуляционный насос.</li>
                </ul>

                <p><strong>Недостатки системы с естественной циркуляцией:</strong></p>
                <ul>
                    <li>невозможно автоматическая регулировка теплового режима отопительных приборов;</li>
                    <li>как правило, перерасход топлива;</li>
                    <li>использование труб большого диаметра (чтобы сопротивления потоку теплоносителя были как моно меньше) и только стальных, что обойдётся дороже: и стоимость самих труб больше и при недостаточной квалификации такую систему не собрать самостоятельно;</li>
                    <li>смотрятся толстые трубы в интерьере помещения не очень эстетично;</li>
                    <li>в системе с естественной циркуляцией невозможно использовать бойлер косвенного нагрева;</li>
                    <li>нельзя такую систему использовать с водяными теплыми полами.</li>
                </ul>
                <p>Вот теперь, пользуясь полученной информацией, вы можете решить, по какому принципу будет работать ваша система отопления. После чего можно переходить к проектированию.</p>

                <h2>Конвективное и лучистое отопление</h2>
                <p>Конвективное отопление. К нему относятся все виды отопления, в которых тепловая энергия передается благодаря перемещению объемов горячего и холодного воздуха. Теплый воздушный поток устремляется вверх, холодный/остывший воздух опускается вниз. Отсюда и основной недостаток конвективного отопления - большой перепад температур в помещении, т.е. высокая температура воздуха под потолком и низкая у пола. Самым ярким примером является отопление с помощью тепловых пушек и тепловентиляторов.</p>
                <img src={yy7507konvektivnoe} alt='' style={{ marginBottom: '20px' }} />
                <p>Инфракрасное (лучистое) отопление – вид отопления, при котором тепло передается излучением. Этакое комнатное солнышко. Отопительные приборы размещают непосредственно над или под обогреваемой зоной. Инфракрасные обогреватели – самый ''лучистый’’ вид отопления. Основной недостаток - то, что при неправильном расчете (монтаже) и эксплуатации (длительное использование) можно получить перегрев предметов и тела человека.</p>
                <p>Конвективно-лучистое. Большинство отопительных приборов (радиаторы, конвекторы, теплые полы и стены) являются конвективно – лучистыми, но соотношение конвекции и излучения у всех разное.</p>
                <p>При выборе способа отопления важно учесть, что оптимальным и наиболее комфортным считается примерно равное (50/50) соотношение конвективного и лучистого тепла.</p>
                <p><strong>Теплоноситель для систем отопления</strong></p>
                <p>Теплоноситель - вещество, применяемое для передачи тепловой энергии. По типу теплоносителя системы отопления можно разделить на водяные (жидкостные), паровые, воздушные и комбинированные. В некоторых случаях теплоноситель отсутствует, например инфракрасное отопление.</p>

                <h2>Системы водяного отопления</h2>
                <p>Самый распространенный, на данный момент, вид систем отопления. Отсюда такое количество вариантов, схем, материалов и способов исполнения.  Коротко приведем основную классификацию и перейдем к "частным случаям".</p>
                <img src={o6297radiator} alt='' className='img6' />
                <p>Классификация видов систем водяного отопления:</p>

                <ul>
                    <li>
                        По способу создания циркуляции:
                        <ul>
                            <li>С естественной циркуляцией/гравитационные (за счет разности давления в контуре).</li>
                            <li>С принудительной циркуляцией/насосные (с помощью циркуляционного насоса).</li>
                        </ul>
                    </li>
                    <li>
                        Виды разводки систем отопления:
                        <ul>
                            <li>Верхняя</li>
                            <li>Нижняя</li>
                            <li>Комбинированная</li>
                            <li>Горизонтальная</li>
                            <li>Вертикальная</li>
                        </ul>
                    </li>
                    <li>
                        Виды труб для разводки отопления:
                        <ul>
                            <li>Стальные трубы</li>
                            <li>Полипропиленовые трубы</li>
                            <li>Металлопластиковые трубы</li>
                            <li>Гофрированная нержавеющая труба</li>
                            <li>Медные трубы</li>
                            <li>PEX-труба (сшитый полиэтилен)</li>
                        </ul>
                    </li>
                    <li>
                        По ходу движения теплоносителя в магистральных трубопроводах:
                        <ul>
                            <li>Тупиковые</li>
                            <li>Попутные</li>
                        </ul>
                    </li>
                    <li>
                        По способу подключения приборов отопления:
                        <ul>
                            <li>Однотрубные</li>
                            <li>Двухтрубные</li>
                            <li>Коллекторные</li>
                            <li>Комбинированные</li>
                        </ul>
                    </li>
                    <li>
                        По способу присоединения системы к тепловой сети:
                        <ul>
                            <li>Независимая</li>
                            <li>Зависимая</li>
                        </ul>
                    </li>
                </ul>
                <p>Итак, с классификацией в стиле Википедии мы закончили. Перейдем к более простому и понятному разделению.</p>
                <p><strong>Отопительные приборы систем водяного отопления</strong></p>
                <p><i>Отопительный прибор — устройство для обогрева помещения путём передачи теплоты от теплоносителя, поступающего от источника теплоты, в окружающую среду. (Wiki)</i></p>
                <p>По виду этих "устройств" мы получаем самое распространенное разделение систем водяного отопления:</p>
                <ul>
                    <li>радиаторное отопление;</li>
                    <li>система «теплый пол (стены)»;</li>
                    <li>плинтусное отопление;</li>
                    <li>инфракрасное водяное отопление;</li>
                    <li>комбинированные системы.</li>
                </ul>
                <p>Стоит отметить, что такая классификация применима и к электрическим системам без теплоносителя. Но, пока, чуть подробнее рассмотрим водяные системы.</p>

                <p><strong>Радиаторное водяное отопление</strong></p>
                <p>Первое на что все обращают внимание – это вид радиаторов (батарей) отопления. Не будем их сравнивать в этой статье, просто перечислим:</p>
                <ul>
                    <li>Чугунные радиаторы</li>
                    <li>Алюминиевые радиаторы (цельные и секционные)</li>
                    <li>Биметаллические радиаторы</li>
                    <li>Стальные (панельные и секционные) радиаторы</li>
                    <li>Каменные и керамические радиаторы</li>
                    <li>Гладкотрубные приборы — одна, или несколько соединенных вместе стальных труб.</li>
                    <li>Конвекторы</li>
                </ul>
                <img src={s8401kamennyj_radiator} alt='' style={{ marginBottom: '20px' }} />
                <p>Пожалуй, радиаторное водяное отопление - это самый распространенный вид отопления на территории бывшего СССР. Большая часть централизованных систем отопления выполнена в виде радиаторного отопления. В частном (автономном) варианте такая система может быть реализована на любом энергоносителе, хотя применение альтернативных источников энергии не всегда целесообразно.</p>

                <p><strong>Теплый водяной пол</strong></p>
                <p>Эта система продолжает набирать популярность, хотя она сложнее в расчете и монтаже, чем та же радиаторная система. По сути, теплый пол — один большой отопительный прибор. Качественными преимуществами  теплого пола являются: равномерное распределение температур (не греем потолок, плюс ногам тепло), свободные от радиаторов стены и близкое к оптимальному соотношение лучистого и конвективного тепла.</p>
                <p>Теплые стены устроены по тому  же принципу что и теплые полы, с некоторыми техническими особенностями. Эта система имеет свои плюсы  и призвана решать специфические конструкционные и технические задачи.</p>

                <p><strong>Плинтусное отопление</strong></p>
                <p>Относительно новая в Узбекистан система отопления. По утверждению производителей теплоотдача идет в и сторону пола, и в сторону стен. Так же встречается утверждение, что это лучистая система отопления. Это не совсем так, ведь нагрев стен происходит за счет теплого воздуха, поднимающегося от плинтуса, т.е. за счет конвекции. Каждая секция теплого плинтуса – это небольшой конвектор с кожухом.  Монтаж секции похож на монтаж обычного радиатора.</p>
                <img src={b5900teplyj} alt="" style={{ marginBottom: '20px' }} />

                <p><strong>Водяное инфракрасное отопление и теплый потолок</strong></p>
                <p>Ещё один вариант для инфракрасного обогрева помещения. Обычно такие системы реализуются с помощью водяных инфракрасных обогревателей. Теплый водяной потолок – большая инфракрасная панель, реализованная, как зеркальное отражение системы теплого пола. Преимуществом является то, что такую систему можно использовать для отопления зимой и для охлаждения летом.</p>

                <p><strong>Паровое отопление</strong></p>
                <p>Сейчас паровое отопление в жилых и общественных зданиях не применяется, из-за травмоопасности (температура пара 130С?).  Чаще оно встречается на предприятиях, где пар применяется для производственных нужд или является побочным продуктом производства. Хотя, запрета на применение парового отопления в частных домах нет.  Для парового отопления можно использовать все виды энергоносителей, кроме альтернативных (во всяком случае, пока).  В качестве отопительных приборов используются радиаторы, конвекторы или трубы. С появлением инфракрасных панелей, возможно, паровое отопление найдет новое применение.</p>

                <h2>Воздушные системы отопления</h2>
                <p>К воздушным системам относят системы, в которых теплоносителем является нагретый воздух. Они делятся на централизованные системы и локальные (местные).</p>

                <p><strong>Местные системы воздушного отопления</strong></p>
                <p>В локальных системах нагревание и подача воздуха производится непосредственно в отапливаемом помещении при помощи отопительных и отопительно-вентиляционных приборов.</p>
                <p>По сути, в большинстве местных воздушных систем теплоноситель отсутствует (нет переноса тепловой энергии от источника тепла), поэтому к системам с воздушным теплоносителем их можно отнести лишь условно. Примером локальной системы воздушного отопления являются установленные в каждой комнате тепловентиляторы. Так же сюда относятся тепловые завесы, тепловые пушки и калориферы.</p>

                <p><strong>Центральные системы воздушного отопления</strong></p>
                <p>В централизованных системах воздух нагревается в воздухонагревательной установке и по каналам подается в помещения. В качестве топлива в таких системах можно использовать все виды энергоносителей. Альтернативные источники энергии используют как дополнительный источник тепла, чтобы сэкономить на отоплении (особенно в межсезонье), т.к. их мощности не хватит на полный обогрев.</p>
                <img src={b12686centralizovannoe_vozdushnoe_otoplenie} alt='' className='img6' />
                <p>Классификация центральных систем воздушного отопления:</p>
                <p>По способу циркуляции воздуха:</p>
                <ul>
                    <li>Центральная система воздушного отопления с полной рециркуляцией</li>
                    <li>Центральная система воздушного отопления с частичной рециркуляцией и вентиляцией</li>
                    <li>Прямоточная центральная система воздушного отопления</li>
                </ul>
                <p>Последние две могут быть:</p>
                <ul>
                    <li>Без рекуперации</li>
                    <li>С рекуперацией</li>
                </ul>
                <p>По способу нагрева воздуха:</p>
                <ul>
                    <li>Системы воздушного отопления прямого нагрева</li>
                    <li>Системы воздушного отопления косвенного нагрева.</li>
                </ul>
                <p>Достоинством централизованной системы воздушного отопления является то, что в одной системе можно реализовать отопление, вентиляцию, кондиционирование, очистку и увлажнение воздуха.</p>
                <p>Системы воздушного отопления «теплый пол» и «теплые стены»</p>
                <p>Принцип действия таких систем очень похож на водяные теплые полы (стены), только теплоносителем является воздух. Такие системы довольно экзотичны и встречаются редко. Но что-то в этой идее есть:)</p>

                <p><strong>Огневоздушное отопление</strong></p>
                <p>К этому виду отопления относятся печное и каминное отопление.  В таком отоплении теплоноситель либо практически отсутствует, либо им являются горячие дымовые газы. Примерами тепловых агрегатов служат различного вида кирпичные (русская, шведка, голландка и т.д.) и металлические печи (буржуйки, Булерьян, Профессор Бутаков , «бубафоня», печь на отработке и пр.), открытые и закрытые камины. В зависимости от конструкции агрегата, топить можно практически чем угодно, лишь бы горело.</p>

                <h2>Системы отопления без теплоносителя</h2>
                <p><strong>Электрические системы отопления</strong></p>
                <p>Большая часть систем без теплоносителя – электрические. В таких системах электрическая энергия, преобразуясь в тепловую, нагревает помещение, а не теплоноситель. К таким системам можно отнести тепловентиляторы и электроконвекторы, однако выше мы их отнесли к  местному воздушному отоплению. Более показательными примерами будут электрические теплые полы, панельные инфракрасные обогреватели, инфракрасные излучатели и пленочные инфракрасные нагреватели (ПЛЭН).</p>

                <p><strong>Электрические теплые полы</strong></p>
                <p>Теплый электрический пол отличается от водяного тем, что его нагревательные элементы - это имеющие два слоя изоляции, экранированные одножильные или двужильные кабели. По сравнению с водяными, электрические теплые полы проще (и дешевле) при монтаже, не требуют дополнительного оборудования, просты в управлении.</p>
                <p><strong>Пленочные инфракрасные нагреватели (ПЛЭН)</strong></p>
                <p>В основе их работы лежит принцип нагрева элементов из карбона, которые запаяны в полимерную пленку. К характеристикам такой пленки следует отнести: прочность, влагонепроницаемость и термостойкость. Основные достоинства – быстрый монтаж, отсутствие дополнительного оборудования и коммуникаций (только электричество) и легкая регулировка.</p>

                <p><strong>Газовые ИК обогреватели и конвекторы</strong></p>
                <p>В этих приборах тепло вырабатывается при сгорании газо-воздушной смеси. Поэтому можно отнести их к огневоздушному отоплению без теплоносителя (тепло передается через твердую среду корпуса прибора). Конвекторы из-за способа теплообмена (конвекция) относятся так же к воздушному отоплению. Вот такая перекрестная классификация.</p>

                <p><strong>Инфракрасные газовые обогреватели</strong></p>
                <p>«Светлые» Процесс горения у светлых излучателей происходит непосредственно на излучающей поверхности, т.е. открыто. Обычно применяются в больших вентилируемых помещениях или на открытом пространстве.</p>
                <p>«Темные» Процесс горения у темных излучателей происходит в полностью закрытом пространстве. Принцип таких излучателей состоит в том, что высокотемпературные продукты сгорания газа проходят внутри теплоизлучающих труб. Средняя температура на поверхности трубы составляет 450 — 500 °C.</p>

                <h2>Видео: виды отопления</h2>
                <iframe src="https://www.youtube.com/embed/JoHhPHy8ubI" title="Видеоурок # 2. Какие виды систем отопления существуют?" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <p>Систему отопления Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href="mailto:fidclimat@mail.ru">fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default Heating