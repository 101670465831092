import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img112 from '../../../img/112_small.jpg'
import img113 from '../../../img/26-05-2014 13-03-50.jpg'
import img114 from '../../../img/26-05-2014 16-42-45.jpg'
import img115 from '../../../img/26-05-2014 18-18-22.jpg'
import img116 from '../../../img/26-05-2014 16-43-45.jpg'
import img117 from '../../../img/26-05-2014 18-18-56.jpg'
import img118 from '../../../img/26-05-2014 18-17-12.jpg'
import img119 from '../../../img/26-05-2014 18-23-26.jpg'
import img120 from '../../../img/26-05-2014 18-22-53.jpg'
import img121 from '../../../img/25-05-2014 21-16-06.jpg'
import img122 from '../../../img/26-05-2014 18-26-17.jpg'
import img123 from '../../../img/25-05-2014 21-17-08.jpg'
import img124 from '../../../img/21-05-2014 14-37-43.jpg'
import img125 from '../../../img/21-05-2014 15-39-03.jpg'
import img126 from '../../../img/21-05-2014 13-59-19.jpg'
import img127 from '../../../img/26-05-2014 13-06-38.jpg'
import img128 from '../../../img/121616713e67856db53391a995234bbe802d96-500x450.jpg'

const ForcedVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Приточная система вентиляции</h1>
                <p>Приточную систему вентиляции Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку приточной вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <img src={img112} alt='' />
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Приточные системы вентиляции предназначены для подачи воздуха в помещение. Свежий воздух подается, как правило, после предварительной подготовки, которая может включать: подогрев, очистку,  охлаждение и увлажнение.</p>

                <h2>Обработка и фильтрация воздуха</h2>
                <p>Воздух, перед тем как попасть в помещение, проходит большое количество обработок. После того как он пройдет через воздушную решетку, попадает на фильтры с разными степенями очистки от загрязняющих элементов и посторонних примесей. Данные фильтры будут очищать воздух от пыли, грязи, вредоносных газов и газов, появившихся от вредных производственных процессов. Также воздух очищается от автомобильных выбросов и иных посторонних запахов. Из всего этого следует, что такая вентиляция очищает воздух от вредных веществ, защищает ваше здоровье от инфекций, бактерий и пагубного влияния технических отходов и газов. Как и обычные системы кондиционирования, приточная вентиляция может проводить практически любые манипуляции с воздухом. Можно изменить влажность и или температуру воздуха как большую, так и в меньшую степень.</p>
                <p>Можно подбирать и другие качественные показатели воздуха, подходящие для вашего конкретного случая. Именно по той причине, что приточная вентиляция не только «гоняет» воздух, но и изменяет его свойства, такие системы устанавливаются в основном в жилых помещениях, таких как дачи, квартиры, дома и коттеджи. Чтобы нагреть воздух, приточная вентиляция использует приточный калорифер, который может изменять свою мощность. Мощность, которую необходимо использовать на нагрев воздуха, он высчитывает сам, в зависимости от внешних условий среды и объема поступающего воздуха.</p>
                <p>На сегодняшний день практически все современные производители данного оборудования делают калориферы двух типов: водяные и электрические. Необходимо ответственно подходить к выбору калорифера, так как от его показателей будет зависеть качество поступающего воздуха в помещение и, соответственно, самочувствие людей, находящихся в нем.</p>

                <h2>Классификация видов приточной вентиляции</h2>
                <p>Приточные системы вентиляции классифицируются по ряду признаков:</p>
                <ul>
                    <li>
                        <strong>По наличию или отсутствию воздуховодов:</strong>
                        <ul>
                            <li><strong>бесканальные,</strong> в которых свежий воздух проникает в помещение непосредственно через приточные отверстия в стенах или окнах здания;</li>
                            <li><strong>канальные,</strong> в которых свежий воздух подается в помещения по системе воздуховодов.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>По конструкции вентиляционной сети:</strong>
                        <ul>
                            <li><strong>сборные,</strong> состоящие из отдельных элементов, соединенных между собой воздуховодами;</li>
                            <li><strong>моноблочные,</strong> в которых используется установка приточной вентиляции, конструктивно объединяющая в отдельном корпусе несколько элементов системы (фильтр, калорифер, вентилятор, воздухонагреватель  (решетки, диффузоры и др.), охладитель, клапаны, аппаратура системы управления и т.д.).</li>
                        </ul>
                    </li>
                    <li>
                        <strong>По способу вентиляции:</strong>
                        <ul>
                            <li><strong>местные,</strong> которые подают поток свежего воздуха к определенным местам в помещении;</li>
                            <li><strong>комплексные,</strong> осуществляющие полный и равномерный воздухообмен в помещении.</li>
                            <li><strong>аварийные (противодымные) системы,</strong> препятствующие распространению дыма и способствующие безопасному удалению людей из здания при пожаре.</li>
                        </ul>
                    </li>
                </ul>
                <p>Производительность приточных установок может изменяться от нескольких десятков (мини-приточки) до нескольких десятков тысяч (центральные приточные установки) кубических метров воздуха в час.</p>
                <p>Нагревающий элемент (калорифер) обеспечивает в зимнее время подогрев свежего воздуха до температуры подачи в помещение (от 18-20° до 27-29°С).</p>
                <img src={img113} alt='' />

                <h2>Как работает приточная система вентиляции</h2>
                <p>Данная вентиляционная система содержит существенные отличия от систем кондиционирования. Оборудование не только остужает воздух, но и насыщает комнату очищенным, обогащенным кислородом, воздухом. Система использует уличный воздух, в отличие от кондиционеров. Поэтому закономерен тот факт, что установка приточной вентиляции стоит на порядок дороже и требует больших затрат времени, но это окупается пользой и эффективностью системы.</p>
                <p>Спертый воздух – это угроза не только настроению, эмоциональному состоянию, но и здоровью. Поэтому рекомендуется позаботиться об обустройстве системы.  Приточная система – это один из типов механической вентиляции, когда в помещение подается свежий воздух в принудительном порядке. Отработанные воздушные массы выводятся наружу при помощи системы вентиляции.</p>
                <p>Типовая приточная механическая вентиляционная система состоит из следующих компонентов:</p>
                <ul>
                    <li><strong>Воздухозаборная решетка.</strong> Через нее наружный воздух поступает в систему вентиляции. Эти решетки, как и другие элементы вентиляционной системы, бывают круглой или прямоугольной формы. Воздухозаборные решетки не только выполняют декоративные функции, но и защищают систему вентиляции от попадания внутрь капель дождя и посторонних предметов.</li>
                    <img src={img114} alt='' />
                    <br />
                    <img src={img116} alt='' />
                    <li><strong>Воздушный клапан.</strong> Служит для предотвращения попадания в помещение наружного воздуха при выключенной системе вентиляции. Воздушный клапан особенно необходим зимой, поскольку без него в помещение бесконтрольно будут проникать холодный воздух и снег. Как правило, в приточных системах вентиляции устанавливаются воздушные клапаны с электроприводом, что позволяет полностью автоматизировать управление системой: при включении вентилятора клапан открывается, при выключении – закрывается.</li>
                    <img src={img115} alt='' />
                    <br />
                    <img src={img117} alt='' />
                    <br />
                    <img src={img118} alt='' />
                    <li><strong>Фильтр.</strong> Этот элемент необходим для защиты как самой системы вентиляции, так и вентилируемых помещений от пыли, пуха, насекомых. Обычно в системе устанавливается один фильтр грубой очистки, который задерживает частицы величиной более 10 мкм. Если к чистоте воздуха предъявляются повышенные требования, то дополнительно могут быть установлены фильтры тонкой очистки (для частиц до 1 мкм) и особо тонкой очистки (задерживают частицы до 0,1 мкм). Фильтрующим материалом в фильтре грубой очистки служит ткань из синтетических волокон, например акрила. Фильтр необходимо периодически очищать от грязи и пыли, обычно не реже 1 раза в месяц.</li>
                    <img src={img119} alt='' className='img-width' />
                    <br />
                    <img src={img120} alt='' />
                    <li>Калорифер или воздухонагреватель. Предназначен для подогрева воздуха, который подается с улицы в зимнее время. Калорифер может быть водяным (температура горячей воды должна быть не менее 70°С) или электрическим. Для небольших приточных установок выгоднее использовать электрические калориферы, поскольку установка такой системы требует меньших затрат. Для больших помещений (площадью более 100 м2) желательно использовать водяные нагреватели, иначе затраты на электроэнергию окажутся очень большими. Для значительного снижения затрат на подогрев холодного воздуха используется рекуператор – устройство, в котором холодный приточный воздух нагревается за счет теплообмена с удаляемым теплым воздухом. Воздушные потоки при этом не смешиваются.</li>
                    <img src={img121} alt='' />
                    <br />
                    <img src={img122} alt='' />
                    <li><strong>Шумоглушитель.</strong> Поскольку вентилятор является источником шума, рядом обязательно устанавливают шумоглушитель, чтобы предотвратить распространение шума по воздуховодам. Основным источником шума при работе вентилятора являются турбулентные завихрения воздуха на его лопастях, то есть аэродинамические шумы.</li>
                    <img src={img123} alt='' />
                    <li><strong>Вентилятор.</strong> Служит для подачи свежего воздуха в помещение и создания необходимого давления воздушного потока в сети.</li>
                    <img src={img124} alt='' />
                    <br />
                    <img src={img125} alt='' />
                    <br />
                    <img src={img126} alt='' />
                    <li><strong>Воздуховоды.</strong> После выхода из шумоглушителя обработанный воздушный поток готов к распределению по помещениям.</li>
                </ul>
                <p>Для этих целей используется воздухопроводная сеть, состоящая из воздуховодов и фасонных изделий (тройников, поворотов, переходников). Основными характеристиками воздуховодов являются площадь сечения, форма (круглая или прямоугольная) и жесткость (бывают жесткие, полугибкие и гибкие воздуховоды).</p>
                <p>Скорость потока в воздуховоде не должна превышать определенного значения, иначе воздуховод станет источником шума. Поэтому размер воздуховодов подбирается исходя из расчетного значения воздухообмена и максимально допустимой скорости воздуха. Жесткие воздуховоды изготавливаются из оцинкованной жести и могут иметь круглую или прямоугольную форму. Полугибкие и гибкие воздуховоды имеют круглую форму и изготавливаются из многослойной алюминиевой фольги. Круглую форму таким воздуховодам придает каркас из свитой в спираль стальной проволоки. Такая конструкция удобна тем, что воздуховоды при транспортировке и монтаже можно складывать «гармошкой». Недостатком гибких воздуховодов является высокое аэродинамическое сопротивление, вызванное неровной внутренней поверхностью, поэтому их используют только на участках небольшой протяженности.</p>
                <ul>
                    <li><strong>Распределители воздуха.</strong> Через воздухораспределители воздух из воздуховода попадает в помещение.</li>
                </ul>
                <p>По конструктивному исполнению воздухораспределители и устройства воздухоудаления весьма разнообразны:</p>
                <ul>
                    <li>Воздухораспределительные решетки;</li>
                    <li>Плафоны (диффузоры);</li>
                    <li>Перфорированные панели</li>
                    <li>Щелевые воздухораспределительные устройства;</li>
                    <li>Сопловые воздухораспределители и др.</li>
                </ul>
                <p>По геометрической форме струи, подаваемые воздухораспределительными устройствами, могут быть:</p>
                <ul>
                    <li>Компактные струи образуются при выпуске воздуха из цилиндрических труб, круглых и прямоугольных отверстий.</li>
                    <li>Плоские струи формируются при истечении воздуха из щелевых каналов воздушных завес, воздуховодов активной раздачи, прямоугольных вытянутых отверстий.</li>
                    <li>Веерные струи образуются при раздаче воздуха через насадки с плоским диском, установленные поперек потока.</li>
                    <li>Неполные веерные струи формируются при выпуске воздуха через решетки с расходящимися под некоторым углом лопатками. Такая струя постепенно трансформируется в компактную струю. Конические струи (разновидность веерных) создаются при выпуске воздуха через насадки с конусом или плафоны виде диффузоров в них плоским отражательным диском.</li>
                </ul>
                <p>Как правило, в качестве воздухораспределителей используют решетки (круглые или прямоугольные, настенные или потолочные) или диффузоры (плафоны). Помимо декоративных функций воздухораспределители служат для равномерного рассеивания воздушного потока по помещению, а также для индивидуальной регулировки воздушного потока, направляемого из воздухораспределительной сети в каждое помещение.</p>
                <ul>
                    <li>Системы регулировки и автоматики. Последним элементом вентиляционной системы является электрический щит, в котором обычно монтируют систему управления вентиляцией.</li>
                </ul>
                <p>В простейшем случае система управления состоит только из выключателя с индикатором, позволяющего включать и выключать вентилятор. Однако чаще всего используют систему управления с элементами автоматики, которая включает калорифер при понижении температуры приточного воздуха, следит за чистотой фильтра, управляет воздушным клапаном и т. д. В качестве элементов системы управления используют термостаты, гигростаты, датчики давления и т. п.</p>

                <h2>Управление приточной системой</h2>
                <p>Для вентиляции квартир и небольших офисов сегодня активно используются небольшие вентиляционные системы: сеть воздуховодов с приточной установкой. Управлять современным оборудованием удобно и просто. При помощи выносного пульта управления можно осуществить пятиступенчатую регулировку подачи воздуха, в зимнее время плавно регулировать температуру от 5 до 28°С. Кроме того, некоторые приборы имеют таймер, осуществляющий включение установки в определенный день недели или время суток.</p>
                <img src={img127} alt='' className='img-width' />
                <p>Предусмотрена также возможность подключения внешнего вытяжного вентилятора. Автоматика обеспечит синхронное включение и выключение приточного и вытяжного вентиляторов. На случай аварии управление оснащено системой защиты. Агрегат надежно защищен от перегрева. В обмотку электродвигателя вентилятора встроена тепловая защита. При срабатывании она останавливает вентилятор, сигнализируя аварийной лампой. Нагревательные элементы калорифера оборудованы термостатами, предохраняющими от перегрева и пожара.</p>
                <p>Также приточные системы дополнительно можно укомплектовать дифференциальным манометром, предназначенным для измерения перепада динамического давления в воздушном потоке на участке воздушного фильтра, и отсечным клапаном, монтируемым на приточном участке воздуховодов. При остановке работы агрегата заслонка при помощи привода автоматически блокирует поступление наружного холодного воздуха в помещение.</p>

                <h2>Недостатки вентиляционных систем приточного варианта</h2>
                <p>И хотя приточная вентиляция обладает массой достоинств, стоит выделить ее слабые стороны при обустройстве вентилирования большого пространства:</p>
                <ul>
                    <li>трудность в установке при монтировании в уже застроенном доме, не с нуля;</li>
                    <li>не всегда можно найти достаточно место для установки, которая отличается немалыми габаритами;</li>
                    <li>оборудование издает сильные шумы и вибрации, необходимо позаботиться о шумоизоляции, существует большое количество современных материалов, предназначенных для этих целей, но при обустройстве шумоизоляции, полезная площадь будет занята еще больше;</li>
                    <li>необходимо оборудовать готовое здание воздуховодами, создать грамотный проект;</li>
                    <li>требуются дополнительные материальные затраты при устройстве системы.</li>
                </ul>
                <p>Кажется, все очень просто – сделал отверстие, готов источник поступления свежего воздуха. На практике, все совсем иначе. С одной стороны, внешняя температура практически круглогодично далека от приятной и есть еще целый перечень проблем:</p>
                <ul>
                    <li>воздушные массы без специального подогрева не очень комфортны, даже если за окном не холодно, чтобы избежать неудобств, устанавливаются нагревательные элементы;</li>
                    <li>без использования фильтров помещение будет наполняться грязным и пыльным воздухом;</li>
                    <li>если отверстие в стене не оборудовать шумоизоляционными материалами, пребывание в помещение будет отравляться посторонними уличными звуками;</li>
                    <li>воздушные массы с улицы пребывают в постоянном изменении уровня влажности, поэтому обязателен контроль влажности, способный регулировать показатели до комфортных;</li>
                    <li>невозможно оставить установку без теплоизоляции – внешние перепады воздуха станут причиной образования конденсата, как результат, плесени и грибка.</li>
                </ul>
                <p>Ни одним из описанных моментов пренебречь нельзя, иначе задумка улучшить качество жизни обитателей квартиры, закончится обратным процессом.</p>
                <img src={img128} alt='' className='img-width' />

                <h2>Где рациональнее всего разместить приточную вентиляцию</h2>
                <p>Все больше собственников жилищ устанавливают вентиляцию в своих владениях. Благодаря данной системе вентиляции можно отказаться от необходимости постоянного открывания-закрывания окон. Это решает не только вопрос с сохранением работоспособности оконной фурнитуры, но и проблему внешнего шума, пыли и вредных примесей. Работа клапана регулируется, это позволяет использовать систему максимально рационально.</p>
                <p>Установка приспособления внутри может быть скрыта шторами. Прикреплять клапан можно около батарей. В таком случае, воздушные массы будут обогреваться. Установка вверху позволит смешивать потоки воздуха.</p>
                <p>Если же речь идет о приточной вентиляции всего дома или квартиры, необходимо составить проект. На схеме должны быть указаны места размещения ПУ, воздуховодов, решеток. Следует рассчитать потоки воздуха, направление движения воздушных масс, чтобы избежать ошибок. Неправильно продуманная система может стать причиной распространения неприятных запахов из кухни, санузла по всему помещению. Требует тщательного расчета мощность подогрева, учитывается степень отапливаемости дома, погода в конкретной местности.</p>

                <h2>Приточная вентиляция с рекуперацией</h2>
                <p>Особой разновидностью принудительной системы вентиляции является приточная вентиляция с подогревом и рециркуляцией тепла, которая обеспечивает частичное нагревание входного воздушного потока за счет удаляемого из помещения теплого воздуха при помощи специального устройства – рекуператора. При этом основной подогрев наружного воздуха осуществляется обычным воздухонагревателем.</p>
                <p>Рекуператор представляет собой теплообменник особого типа, к которому подсоединяются входы и выходы приточного и вытяжного каналов системы вентиляции. Удаляемый из помещения загрязненный воздух, проходя через рекуператор, отдает свое тепло поступающему наружному воздуху, непосредственно не смешиваясь с ним. Такой дополнительный обогрев приточной вентиляции позволяет значительно снижать энергозатраты на подогрев входного воздуха, особенно в зимний период.</p>
                <p>Когда в помещении работает кондиционер с приточной вентиляцией, то в жаркую погоду удаляемый прохладный воздух за счет рекуператора может охлаждать подаваемый с улицы теплый воздух, создавая в помещении необходимую комфортную температуру.</p>

                <h2>Вентиляция помещений с пластиковыми окнами</h2>
                <p>Оконная приточная вентиляция в домах старой постройки традиционно играет важную роль в вентилировании помещений, обеспечивая поступление свежего воздуха через неплотности деревянных оконных рам и открытые форточки.</p>
                <p>Однако жители городских квартир и загородных домов все чаще устанавливают в своих жилищах металлопластиковые окна со стеклопакетами, отличающиеся высокой тепло- и звуконепроницаемостью, практичностью в использовании и эстетичным внешним дизайном.</p>
                <p>Установка таких окон, особенно в зданиях с воздухонепроницаемыми стенами, не имеющими дополнительных приточных устройств, часто вызывает нарушение естественного притока свежего наружного воздуха. Это приводит к повышению влажности воздуха, образованию конденсата на внутренней поверхности стекол, вызывает чувство духоты и недостатка кислорода у находящихся в таком помещении людей. Для предотвращения таких неприятных явлений необходима приточная вентиляция пластиковых окон.</p>
                <p>Встроенная приточная вентиляция на пластиковых окнах обеспечивается установкой на оконные рамы специальных приточных клапанов, которые способны регулировать необходимый температурно-влажный режим внутри помещения и препятствовать образованию конденсата на стеклах. Регулировка клапана может производиться в ручном режиме или автоматически, с использованием встроенного гигроскопического датчика, измеряющего влажность поступающего наружного воздуха.​</p>
                <p>Приточную систему вентиляции Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку приточной вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ForcedVentilation