import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/75683nastenniy.jpg'
import img2 from '../../../img/33242bytovoy-napoln.jpg'
import img3 from '../../../img/36000napoln-poluprom.jpg'
import img4 from '../../../img/39246bytovoy-nasten.jpg'
import img5 from '../../../img/29003za-stenoy.jpg'

const PoolDehumidifiers = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Промышленные осушители для бассейнов</h1>
                <p>Осушители для бассейнов Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем осушения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>При проектировании вентиляции бассейна, в основном, уделяют внимание поддержанию температуры и относительной влажности воздуха.</p>
                <p>Повышенная влажность создает эффект «тяжелого воздуха». Дискомфорт также может быть вызван неправильным температурным режимом, наличием сквозняков или наоборот, отсутствием необходимого притока свежего воздуха.</p>
                <p>Неверно подобранное оборудование, помимо влияния на самочувствие человека, может оказать неблагоприятное воздействие и на конструкцию здания — отсутствие вентиляции может привести к разрушению отделки, намоканию стен. Длительная повышенная влажность чревата образованием конденсата на окнах, потолке и появлением плесени.</p>

                <h2>Как работают осушители</h2>
                <p>Осушители для бассейнов представляют собой фреоновые установки. Предназначены для поддержания уровня влажности путем осушения воздуха в помещении. Контроль уровня осуществляется при помощи встроенного датчика влажности, по показаниям которого контроллер включает и выключает компрессор.</p>
                <p>Влажный воздух через решетку на панели поступает на испаритель, где фреон забирает теплоту и уменьшает содержание влаги в воздухе. Конденсат, выделившийся при отдаче тепла, с помощью дренажной трубки выводится из оборудования. Охлажденный воздух, проходя через конденсатор того же холодильного контура, нагревается, его относительная влажность становится низкой, и затем воздух возвращается в посещение. При этом создается дополнительный нагрев за счет рассеивания электрической мощности, поступающей в компрессор.</p>

                <h2>Как выбрать осушитель для бассейна</h2>
                <p>Для начала нужно определиться с тем, какой вид осушителя лучше всего подойдет для конкретного помещения:</p>
                <ul>
                    <li>Настенный – монтируется на стену, обладает средней мощностью, прост в установке и нуждается лишь в подведении электричества и системы водоотведения. Настенные осушители удобны для установки в небольших и средних бассейнах, занимают мало места и хорошо смотрятся при любом оформлении помещения.</li>
                </ul>
                <img src={img1} alt='' className='img-width' />
                <ul>
                    <li>Напольный (колонный) – устанавливается прямо на пол, не требует монтажа и имеет специальные противовибрационные опоры. Удобен для размещения в бассейнах, располагающих достаточной площадью, так как может быть расположен в любом месте, а при необходимости и перенесен.</li>
                    <li>Канальные осушители – мощные системы, устанавливаемые в отдельных комнатах, требующие подведения специальных коммуникаций и монтажа. Канальными осушителями, как правило, оборудуются большие бассейны и водные комплексы, имеющие возможность выделить особое помещение под систему. К преимуществам относятся: тихая работа, возможность быстрого доступа для ремонта и обслуживания, эстетичность элементов, выведенных в зал для купаний.</li>
                </ul>
                <p>Большинство настенных и напольных моделей рекомендуется устанавливать в бассейнах с площадью зеркала воды до 60 кв. метров. Большие объемы эффективнее обслуживают канальные агрегаты, хотя, возможна установка и системы из нескольких настенных или напольных осушителей.</p>
                <p>Расчет мощности и воздухообмена: одной из главных задач при покупке осушителя для бассейна, станет правильный подбор его мощности (производительности). Купленная система должна полностью справляться с удалением избытков влаги, но переплачивать за слишком сильный агрегат смысла не имеет.</p>
                <p>Расчет требуемой мощности можно произвести самостоятельно, а можно заказать в специализированных компаниях. При самостоятельном расчете необходимо учитывать:</p>
                <ol>
                    <li>Температуру воздуха в бассейне.</li>
                    <li>Нужный % влажности.</li>
                    <li>Интенсивность испарения воды в бассейне.</li>
                </ol>
                <p>Формула расчета W = Е х S х (Рнас — Руст) г/ч, Где Е – коэффициент теплообмена, S – площадь бассейна, Рнас — Руст – разница давлений водяных паров при разной насыщенности и температуре. Значение W и будет объемом жидкости, который осушитель должен выводить в течение часа.</p>
                <p>Не менее важно определить объем воздушного потока, который осушителю необходимо пропускать через себя за час. В идеале, воздух в помещении должен обновляться 5-6 раз за 60 минут. Нужный параметр воздухообмена высчитывается путем умножения площади помещения на его высоту и на желаемое количество циклов обновления.</p>

                <h2>Общие требования к осушителям воздуха для бассейнов</h2>
                <p>Определившись с главными параметрами, не стоит забывать и о других важных свойствах, которыми должны обладать осушители воздуха для бассейнов.</p>
                <p>Во-первых, корпус любого прибора для бассейна должен быть произведен пластика или металла с антикоррозийной окраской. То есть, его поверхность не должна иметь уязвимых стыковых швов. Желательна и дополнительная защита от влаги в виде слоя эмали.</p>
                <p>Во-вторых, предпочтительно выбирать осушители простых форм, конфигурация которых исключает застой влаги на каких-либо элементах.</p>
                <p>В-третьих, поверхность осушителя должна быть идеально гладкой, а трубы прочно состыкованы без зазоров и шероховатостей.</p>
                <p>Дополнительные функции осушителей: довольно часто, производители оснащают приборы для осушения воздуха рядом дополнительных функций. Среди них есть и такие полезные, как обогрев помещения, фильтрация воздуха от загрязнений и его ионизация, самодиагностика работы и отключение при неисправностях. Большинство моделей имеют электронный дисплей, показывающий уровень влажности, температуру воды и воздуха.</p>
                <p>Современный рынок позволяет без труда подобрать и купить осушитель воздуха для бассейна с нужным набором характеристик, для того чтобы сделать свой бассейн максимально комфортным для отдыха или оздоровления людей.</p>

                <h2>Место установки</h2>
                <p>По месту установки осушители делятся на настенные, напольные и модели для установки в соседнем помещении. Напольные, в свою очередь, подразделяются на мобильные и стационарные.</p>
                <img src={img2} alt='' />
                <p>Мобильные осушители легко перемещаются по помещению и могут быть быстро задействованы там где это необходимо. Габариты, вес, колеса для перемещения - вот что делает такие устройства мобильными и очень удобными в работе. Многие осушители мобильного типа имеют выход для слива конденсата и нет необходимости постоянно следить за уровнем жидкости и периодически сливать ее. Из недостатков можно выделить лишь то, что они подходят только для однообъемных помещений и есть вероятность, что они не впишутся в интерьер.</p>
                <img src={img3} alt='' />
                <p>Напольные осушители не требуют монтажа. Таким образом, если в бассейне не спроектирована системы вентиляции и осущения, то зачастую это единственный способ исправить положение.</p>
                <img src={img4} alt='' />
                <p>Настенные осушители воздуха отличаются компактными размерами, что позволяет использовать их для установки в помещениях бассейнов небольшой площади. Поэтому если ваш бассейн небольшой рекомендуется купить именно настенный осушитель воздуха. Установка такого оборудования выполняется на поверхности стены с помощью специальных кронштейнов. К основным преимуществам настенных осушителей можно отнести невысокую стоимость, легкость обслуживания, простоту монтажа. </p>
                <img src={img5} alt='' />
                <p>Осушители воздуха, устанавливаемые в соседнее помещение, отличаются бесшумностью работы, равномерным распределением воздуха, высокой производительностью. Она может выполняться скрытно, благодаря чему не портится внешний вид помещения, однако установку такого осушителя необходимо предусмотреть на стадии проектирования здания бассейна.</p>
                <p>Осушители для бассейнов Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем осушения по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default PoolDehumidifiers