import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img7 from '../../../img/791300obsledovanie-sistem-ventilyacii-4.jpg'

const DiagnosisExaminationVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Диагностика и обследование систем вентиляции</h1>
                <p>Диагностику и обследование системы вентиляции Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Обследование вентиляции – неотъемлемая составляющая технического обслуживания. Своевременное проведение аудита помогает поддерживать оптимальный микроклимат в помещениях и предотвращает возникновение непредвиденных сбоев в работе вентиляционной системы.</p>
                <p>Обязательность проверок предусмотрена ГОСТом 12.4.021-75 и СНиП 3.05.01-85, регулирующими производственный контроль оборудования. Согласно их требованиям, на каждом предприятии должны составляться графики ежедневного осмотра вентиляционного оборудования, результаты которого заносят в специальный журнал.</p>

                <h2>Для чего необходимы обследования вентиляции?</h2>
                <p>Законодательство РФ, оговаривающее вопросы санитарно-эпидемиологического благополучия граждан (в частности, № 52-ФЗ в редакции от 3 июля 2016 года), гласит, что необходимо специально заниматься мониторингом состояния воздуховодов и прочих элементов конструкции. Обследование систем вентиляции и кондиционирования — мера, которая необходима для предупреждения распространения опасных болезней, профилактики повреждений и поддержания оптимальных микроклиматических параметров внутри зданий. Поэтому мониторинг, технический аудит и изучение состояния всех конструкций и оборудования — обязательная часть многих официальных процессов. Вентиляцию требуется обследовать в следующих случаях:</p>
                <ul>
                    <li>утере техдокументации на нее — проекта, исполнительных схем монтажа;</li>
                    <li>выяснении ее эффективности — это могут потребовать проверяющие органы;</li>
                    <li>сертификации объектов для получения лицензий, разрешений на применение технологий — для этого нужно пройти паспортизацию вентиляции, что необходимо, в частности, промышленным цехам, детсадам и поликлиникам, кафе и ресторанам и так далее;</li>
                    <li>получении заключения о промышленной безопасности сооружений, цехов и зданий;</li>
                    <li>выяснении причин возгораний, аварий, случаев массовых заболеваний.</li>
                </ul>
                <p>Обследование систем вентиляции проводят не только для получения допусков, разрешений или из-за случившихся чрезвычайных ситуаций. Своевременная, регулярная экспертиза, которую проводят с частотой два-четыре раза в год, это способ:</p>
                <ul>
                    <li>выявить существующие неполадки в работе венткомплекса и исправить их до возникновения опасных ситуаций;</li>
                    <li>определить «проблемные» места, которые в будущем приведут к поломкам и расходам на починку;</li>
                    <li>сделать верные прогнозы о дальнейшем использовании вентиляционной системы — необходимости замены элементов, проведения очистки;</li>
                    <li>увеличить срок эксплуатации всего комплекса;</li>
                    <li>обеспечить соответствие параметров воздуха существующим нормам и, таким образом, чувствовать себя уверенно при внезапных проверках, нештатных ситуациях.</li>
                </ul>
                <p>Техническое обследование вентиляции потребуется при проведении ее очистки. Закон оговаривает необходимость регулярного проведения данной процедуры, и аудит состояния каналов, шахт, кондиционеров и установок — обязательный предварительный этап. Он дает возможность определить степень загрязнения системы, выбрать методику очистки, инструментарий, верно оценить уровень расходов. К вопросу о финансах — цена обследования систем вентиляции всегда, при любых обстоятельствах компенсируется минимизацией рисков ее выхода из строя, величиной возможных штрафов, размерами затрат на ремонт и восстановление. Поэтому ненужная экономия на мониторинге только ухудшит положение.</p>
                <img src={img7} alt='' className='img-width' />

                <h2>Услуга по диагностике вентиляционной системы </h2>
                <ol>
                    <li>Определения типа вентиляционной системы (при заключении договора на диагностику с новыми клиентами) и ее рабочего ресурса</li>
                    <li>Оценка фактического технического состояния оборудования</li>
                    <li>Проверка текущего состояния воздуховодов и воздухозаборных фильтров</li>
                    <li>Замеры существующей разницы температур и давления внутри здания и за его пределами</li>
                    <li>Анализ воздушных потоков на входе, выходе и в определенных участках системы</li>
                    <li>Осмотр воздухораспределительных и воздухозаборных решеток</li>
                    <li>Тестирование автоматики (пультов, реле и т.д.), а также электроцепей оборудования</li>
                    <li>Проверка работоспособности основных узлов вентиляторов, наличия в них нормативного количества смазки</li>
                    <li>Комплексная проверка системы отключения вентиляции при возникновении аварийных ситуаций.</li>
                </ol>
                <p>Диагностика вентиляции проводится сотрудниками компании с применением специализированного телеметрического и автоматизированного оборудования перед началом эксплуатации объекта, в профилактических целях, при плановом ремонте или модернизации уже функционирующих систем. По ее результатам специалисты компании достоверно оценивают необходимость проведения чистки всей системы вентиляции или ее отдельных участков.</p>
                <p>После выполнения работ, согласно предварительно составленного и утвержденного технического задания, заказчику выдается акт обследования и комплект документов, которые содержат перечень рекомендуемых мероприятий по устранению выявленных дефектов.</p>

                <h2>Санитарно-эпидемиологическое обследование вентиляции</h2>
                <p>Согласно СанПиН 2.1.3.2630-10 для медицинских учреждений, контроль над состоянием вентиляционной системы должно осуществлять руководство предприятия. Регулярные санитарно-эпидемиологические осмотры систем вентиляции с последующим составлением акта предотвращают распространение возбудителей тяжелых заболеваний дыхательных органов. Проверки, согласно законодательству, необходимо проводить не реже одного раза в 6 месяцев, акт обследования вентиляции составляется по установленному образцу.</p>
                <p>В ходе санитарно-эпидемиологических обследований вентиляционной системы в акт вносятся следующие данные:</p>
                <ul>
                    <li>о параметрах микроклимата;</li>
                    <li>о результатах анализа смывов с внутренних стенок вентканалов.</li>
                </ul>
                <p>Весь процесс обследования систем вентиляции регламентируют соответствующие нормативные акты. Организации, имеющие право на подобные работы, получают лицензию в МЧС РФ. Акт обследования системы вентиляции требуют многие городские службы. Поэтому организация, осуществляющая работы, должна выдать клиенту:</p>
                <ul>
                    <li>копию лицензии Министерства по чрезвычайным ситуациям;</li>
                    <li>копию свидетельства чистильщика, проделавшего работу;</li>
                    <li>акт обследования системы вентиляции установленного образца.</li>
                </ul>
                <p>Часто к акту обследования вентиляционной системы прикладываются поясняющие фотоматериалы.</p>
                <p>На основании акта заполняется «Журнал учета дезинфекций и чисток вентиляционной системы». Данные из акта обследования каналов вентиляции используются для составления графика дезинфекций и спецобработок вентиляционных и климатических систем. Журнал учета обязателен для организаций, отапливаемых газовыми котельными.</p>

                <h2>Акт осмотра вентиляции</h2>
                <p>Одной из распространенных причин ухудшения воздушной тяги является завал воздуховодов строительным мусором, пылью или его разрушение. Обнаружить причину можно лишь при полном исследовании состояния вентиляции.</p>
                <p>Во время обследования систем вентиляции в целях оформления акта проводятся следующие мероприятия:</p>
                <ul>
                    <li>поиск завалов в вентканалах;</li>
                    <li>видеопроверка всех воздуховодов с целью обнаружения перепланировок;</li>
                    <li>контроль над температурой, влажностью, скоростью движения воздуха и тягой в вентканалах;</li>
                    <li>выявление и определение эффективности пожарных развилок;</li>
                    <li>обследование состояния выходов вентиляционных труб на крыши домов, наличие деревьев или строений влияющих на ветровой подпор.</li>
                </ul>
                <p><strong>Акт осмотра</strong></p>
                <p>По окончанию работ заполняется акт осмотра системы вентиляции. Для тестирования и проверок используются:</p>
                <ul>
                    <li>подвес на веревке;</li>
                    <li>видеокамера и монитор для осмотра состояния каналов в реальном времени;</li>
                    <li>термоанемометры;</li>
                    <li>термогигроанемометры.</li>
                </ul>
                <p>Видеоконтроль очень удобен, он позволяет обнаружить мусор и обвалы, несанкционированные перепланировки. Чаще всего этот метод обследования  состояния каналов используется в ходе судебных разбирательств. С камеры изображение подается сразу на монитор компьютера. Весь процесс записывается и в дальнейшем его запись можно использовать в качестве подтвердительного документа к акту обследования технического состояния. Каналы исследуют по направлению из дымохода вниз, горизонтальные шахты через смотровые оконца. Сведения, полученные с помощью видео, используются при начислении стоимости ремонтов, штрафов или претензий по неправильной кладке вентканалов.</p>
                <p><strong>Обследование коммуникаций</strong></p>
                <p>Во время обследования коммуникаций и заполнения акта осмотра вентиляции уделяется внимание:</p>
                <ul>
                    <li>наличию паспортных и проектных документов;</li>
                    <li>выполнению санитарных требований и нормативов;</li>
                    <li>соответствию созданной конструкции проектным данным;</li>
                    <li>качеству установки и запуска вентиляционной системы;</li>
                    <li>действенности работы вентиляционного оборудования;</li>
                    <li>возможности чистки, починки и дезинфекции внутренних поверхностей воздушных каналов (наличие сервисных дверец);</li>
                    <li>загрязнению воздушных каналов, вентиляторов пылью и другим мусором;</li>
                    <li>качеству предыдущих сервисных работ по дезинфекции и чистке каналов и вентиляторов, указанных в образцах актов обследования вентиляции;</li>
                    <li>действенности работы воздушных фильтров или ультрафиолетовых облучателей воздуха (если они есть).</li>
                </ul>
                <p>Диагностику и обследование системы вентиляции Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default DiagnosisExaminationVentilation