import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './supplyClimaticEquipment.css'

const SupplyClimaticEquipment = () => {
    return (
        <>
            <div className='supply-climatic-equipment-page'>
                <h1>Поставка климатического оборудования</h1>
                <p>Поставку климатического оборудования Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998 (90) 355-90-91'>+998 (90) 355-90-91</a>. Проектирование, поставка климатического оборудования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>FIDCLIMAT осуществляет поставки оборудования с заводов-изготовителей и официальных представительств компаний-производителей в Узбекистан, что позволяет оперативно комплектовать проекты партнеров компании и объекты любой сложности.</p>
                <p>Вопрос грамотной и своевременной поставки оборудования, материалов, комплектующих изделий на объект является одним из важнейших для стабильной и ритмичной работы по оснащению объектов инженерными системами.</p>

                <h2>Перечень оборудования</h2>
                <p>По специфике деятельности мы сотрудничаем практически со всеми крупнейшими производителями климатической техники и предлагаем оборудование любого типа, мощности и сложности. Что обеспечивает гибкость в решении поставленных задач, позволяет укомплектовывать системы в полном соответствии со спецификой каждого конкретного объекта.</p>
                <ul>
                    <li>Комплектуем объекты под ключ;</li>
                    <li>Гарантируем сроки комплектации и поставки оборудования;</li>
                    <li>Предоставляем гарантийные обязательства на все типы поставляемого оборудования.</li>
                </ul>
                <p>За годы работы на больших и сложных объектах группой комплектации была отработана и оптимизирована схема поставок оборудования и материалов, работа с которой позволяет минимизировать стоимость комплектующих для инженерных систем, обеспечить своевременную поставку их на объект без отступления от сроков и технических условий.</p>
                <p>Мы осуществляем поставку оборудования и материалов на объект как единовременно, так и по этапам выполнения работ, закрепленном в договоре и приложениях к нему, в соответствии с сетевом графиком и календарном планом выполнения работ на объекте.</p>
                <p>Сроки поэтапной поставки зависят от строительной готовности объекта, планируемых сроках проведения монтажных работ, а также сроках поставки оборудования, комплектующих и расходных материалов.</p>

                <h2>Стоимость оборудования</h2>
                <p>Стоимость оборудования включает в себя:</p>
                <ul>
                    <li>стоимость оборудования на заводе изготовителе;</li>
                    <li>таможенные платежи;</li>
                    <li>налог на добавленную стоимость.</li>
                </ul>
                <p>По запросу клиентов мы готовы в кратчайшие сроки предоставить вам подробный каталог с самыми эффективными образцами оборудования. Многолетний опыт работы в этой области и контакты с ведущими мировыми производителями вентиляционной техники, прямые связи с фирмами-изготовителями вентиляционной техники позволяют нам предлагать наиболее выгодные решения для каждого заказчика, с учетом его индивидуальных требований и в соответствии с мировыми стандартами и нормативами, принятыми в Узбекистан.</p>
                <p>Вентиляционное и климатическое оборудование позволит обеспечить в здании оптимальный и благоприятный микроклимат. После оформления заказа поставка вентиляции проводится в короткие сроки. Наша компания гарантирует своим клиентам высокое качество продукции и качественное сервисное обслуживание. Мы предлагаем нашим клиентам поставку:</p>
                <ul>
                    <li>климатических систем,</li>
                    <li>оборудования для кондиционирования,</li>
                    <li>теплового оборудования,</li>
                    <li>систем очищения и увлажнения воздуха,</li>
                    <li>систем вентиляции.</li>
                </ul>
                <p>Поставка климатического оборудования и вентиляции осуществляется как оптом, так и в розницу. Индивидуальный подход к каждому клиенту дает нам возможность ответить на любые запросы со стороны заказчиков. Опытные и квалифицированные сотрудники помогут подобрать варианты, подходящие именно вам.</p>
                <p>Ключевые принципы нашей работы – профессионализм и ответственность. Соблюдение интересов наших клиентов, своевременное выполнение взятых на себя обязательств и добросовестная работа позволили компании «FIDCLIMAT» занять заметное место в сфере реализации климатического оборудования. Мы рады взаимовыгодному сотрудничеству с компаниями и организациями, а также частными лицами.</p>
                <p>Поставку климатического оборудования Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998 (90) 355-90-91'>+998 (90) 355-90-91</a>. Проектирование, поставка климатического оборудования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default SupplyClimaticEquipment