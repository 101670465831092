import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/17451dymohod-s-reviziyey.jpg'
import img2 from '../../../img/17456elektricheskiy-kotel.jpg'
import img3 from '../../../img/34836image00001-e1463139815517.jpg'

const BoilerRoom = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Котельная</h1>
                <p>Котельную Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Котельная (котельная установка) - это расположенное в одном помещении сооружение, в котором происходит нагрев теплоносителя (жидкости или пара) для нужд отопления, вентиляции, горячего водоснабжения, а также технологических нужд. Затем теплоноситель от котельной поступает к потребителям с помощью тепловых или паровых сетей. Тепловые сети делят на магистральные, квартальные и местные.</p>

                <h2>Виды котельных</h2>
                <p>В современной энергетике работают самые различные виды котельных. Они могут быть классифицированы по типу топлива, типу теплоносителя, типу размещения, уровню механизации. Определенный вид котельной выбирается в зависимости от целей и задач, условий эксплуатации и требований заказчика.</p>
                <p><strong>По виду топлива</strong></p>
                <ul>
                    <li>Газовые. Преимущество такого типа котельных заключается в том, что газ - один из самых экономичных и экологичных видов топлива. Газовые котельные не требуют сложного и громоздкого оборудования топливоподачи и шлакоудаления и могут быть полностью автоматизированы.</li>
                    <li>Жидкотопливные. Эти котельные могут работать на отработанном масле, мазуте, дизельном топливе, нефти. Они достаточно быстро вводятся в эксплуатацию, не требуют специальных разрешений, согласования подключения, получения лимитов на газ (в отличие от газовых).</li>
                    <li>Твердотопливные. К твердому топливу относится уголь, торф, дрова, пеллеты и брикеты из отходов лесопереработки и сельского хозяйства. Преимуществом этого вида котельных является доступность и низкая цена топлива, но требуется установка систем топливоподачи и золошлакоудаления.</li>
                </ul>
                <p><strong>По типу теплоносителя</strong></p>
                <ul>
                    <li>Паровые. В такой котельной теплоносителем является пар, который используется преимущественно для обеспечения производственных процессов на промышленных предприятиях.</li>
                    <li>Водогрейные. Этот вид котельных предназначен для отопления и горячего водоснабжения жилых зданий, промышленных и коммунальных объектов. Теплоносителем является вода, нагреваемая до +95 +115 °С.</li>
                    <li>Комбинированные. В таких котельных размещаются как паровые, так и водогрейные котлы. Горячая вода используется для покрытия нагрузки на горячее водоснабжение, отопление и вентиляцию, а пар поступает для обеспечения технологических нужд предприятия.</li>
                    <li>На диатермическом масле. В этой котельной в качестве теплоносителя используются органические высокотемпературные жидкости, температура которых может достигать +300 °С.</li>
                </ul>
                <p><strong>По типу размещения</strong></p>
                <ul>
                    <li>Блочно-модульные. Этот вид котельных становится все более популярным в Узбекистан в последние годы благодаря множеству преимуществ в сравнении со стационарными котельными: быстрые сроки монтажа и пуска в эксплуатацию, заводская готовность модулей, возможность увеличения мощности за счет добавления блоков, автономность работы, высокий КПД, мобильность. В зависимости от места размещения блочно-модульные котельные могут быть отдельностоящими, пристроенными, встроенными, крышными, подвальными.</li>
                    <li>Стационарные. Стационарные котельные, как правило, строятся, когда требуемая мощность превышает 30 МВт либо строительство блочно-модульной котельной по каким-то причинам невозможно. Стационарные котельные отличает капитальный характер строительства (фундаменты, стены и перегородки, кровля). Монтаж оборудования выполняется на месте.</li>
                </ul>
                <p><strong>По уровню механизации</strong></p>
                <p>В зависимости от степени механизации/автоматизации процессов выделяют следующие виды котельных:</p>
                <ul>
                    <li>Ручные. Небольшие котельные могут быть оборудованы котлами, подача топлива в которые производится операторами вручную. В помещение котельной топливо подается тележкой или в некоторых случаях через бункер с наружной загрузкой. Зола и шлак из зольного бункера также удаляются оператором вручную и вывозятся за пределы котельной с помощью вагонетки.</li>
                    <li>Механизированные. Современные твердотопливные котельные оборудуются средствами механизации, значительно облегчающими труд оператора котельной. Топливоподача осуществляется с помощью транспортеров или скиповых подъемников. Уголь проходит предварительную обработку на угледробилках, метало- и щепоуловителях. Зола и шлак могут удаляться различными методами - механическим, гидравлическим, пневматическим или их комбинацией.</li>
                    <li>Автоматизированные. Этот вид котельных предполагает полную автоматизацию и минимальное присутствие человеческого фактора. Полностью автоматизируются, как правило, газовые котельные.</li>
                </ul>

                <h2>Нормативные требования к помещению котельной</h2>
                <p>И вот Вы определились, какая система отопления Вам нужна (для проживания в доме эпизодично или круглогодично). Затем, Вы выбрали котел.</p>
                <p>Надо отметить, что выбор котла, в основном зависит от доступности инженерных сетей около участка застройки, характеристик участка и дома, а также финансовых возможностей застройщика.</p>
                <p>Но на один из самых важных вопросов при строительстве котельной - какие требования предъявляются к помещению, в котором будет установлен котел, ответ найти достаточно сложно.</p>
                <p>Внимание! При проектировании и монтаже отопительного оборудования необходимо строго руководствоваться требованиями строительных норм и правил к помещениям котельных.</p>
                <p>Давайте рассмотрим площадь и размеры котельной в частном доме, которые требуют эти нормы и правила в зависимости от применяемых видов энергоносителя в системе отопления.</p>
                <p>1. Для газового отопительного оборудования (по материалам ДБН В.2.5-20-2001 Газоснабжение).</p>
                <p>Котлы можно размещать:</p>
                <ul>
                    <li>на кухне при мощности теплового агрегата для отопления до 30 кВт включительно, независимо от наличия газовой плиты и газового водонагревателя;</li>
                    <li>в отдельном помещении на любом этаже (в том числе в цокольном этаже или подвале), а также пристроенных к жилым зданиям помещениях при суммарной мощности для систем отопления и горячего водоснабжения более 30 кВт до 200 кВт включительно;</li>
                    <li>в отдельном помещении первого, цокольного или подвального этажа, а также в помещениях пристроенных к жилому дому или отдельно стоящих, при их суммарной мощности для системы отопления и горячего водоснабжения до 500 кВт включительно.</li>
                </ul>
                <p><strong>Для отопительного оборудования работающем на твердом или жидком топливах.</strong></p>
                <p>Указанные выше требования к помещению котельной для газовых котлов относятся и к помещению топочной для котлов, работающих на твердом и жидком топливе.</p>
                <p>Однако существует ряд особенностей, которые необходимо учитывать при проектировании и строительстве котельной, а именно:</p>
                <ol>
                    <li>Отдельное помещение для котельной (топочной), где будут установлены котлы, работающие на твердом и жидком топливе, следует предусматривать на стадии проектирования дома. Помещение котельной должно быть не менее 7—8 м2. Не редки случаи, когда дом уже построен, а в отведенном помещении для котельной отсутствуют вентиляционный канал и дымоход, место для складирования топлива, а при значительном весе котлов - фундамент и т. д. Приходится выполнять дополнительные строительные работы.</li>
                    <li>В помещении должно быть предусмотрено окно или отверстие для притока наружного воздуха размером около 8 см2 на 1 кВт номинальной мощности котла. Под потолком котельной должен быть вход в вентиляционный канал, а в стене — входное отверстие в дымоход.</li>
                    <li>Ниже входного отверстия на 25—30 см выполняется отверстие для ревизии и прочистки дымохода.</li>
                    <img src={img1} alt='' />
                    <li>Площадь сечения дымохода не должна быть меньше площади выходного сечения дымохода котла, т.е. внутренний диаметр дымохода должен быть не меньше, чем диаметр горловины котла. Диаметр горловины котла указывается в паспорте на котел, или его можно определить простым замером. Надо помнить, что по всей своей длине дымовая труба должна иметь одинаковое сечение и на пути дымовых газов должно быть как можно меньше колен и изгибов. Круглая форма трубы - идеальна, так как газы выходят ровно и без завихрений.</li>
                    <li>Дымоход должен быть газонепроницаемым, чтобы дым не проникал в комнаты. Лучше его оштукатурить изнутри либо заложить внутрь дымохода асбоцементную трубу нужного диаметра (чем большую мощность будет иметь котел, тем больший диаметр должна иметь труба).</li>
                    <li>Для котла должно быть достаточно места, чтобы обеспечить поступление к нему свежего воздуха и нормальное его обслуживание.</li>
                    <li>Основание (пол) под котлом должно быть выполнено из негорючего материала.</li>
                    <li>К помещению надо подвести трубу с холодной водой для подпитки системы отопления и приготовления горячей воды для бытовых нужд, канализационную трубу для отвода сбросов аварийных стоков котла и бойлера.</li>
                    <li>При устройстве котельной, работающей на угле, электропроводка должна быть скрытой, а лучше в стальных трубах. Осветительная арматура должна быть выполнена в герметичном исполнения, с завинчивающимся стеклом и желательно металлической сеткой для защиты от механических повреждений. Выключатели и штепсельные розетки д.б. герметичного исполнения. Розетки должны питаться от сети пониженного напряжения – до 42 В. </li>
                </ol>
                <p>Это связано с взрывоопасностью угольной пыли в помещении котельной при ее определенной концентрации.</p>
                <p><strong>Для электрического отопительного оборудования.</strong></p>
                <p>Электрические котлы, предназначенные для работы в системах отопления, имеют немало преимуществ. Главным преимуществом этих котлов является то, что не требуется отдельное помещение под котельную. Не требуются дымоходы и по цене эти котлы сравнительно недороги. Все бы хорошо, если бы не высокая стоимость электроэнергии, особенно в сравнении с другими видами топлива. Из-за этого недостатка такие котлы дороги в эксплуатации, поэтому использовать их целесообразно для отопления помещений небольшого размера, в которых неэффективно производить дорогостоящие работы по подключению других видов отопительного оборудования, либо в качестве резервного котла в системах с газовым котлом, как альтернативный источник тепла на непродолжительное время.</p>
                <p>Установка электрических котлов схожа с установкой газовых настенных котлов.</p>
                <p>При установке электрокотла обязательно наличие предохранительных автоматов и, скорее всего, прокладка отдельной электролинии.</p>
                <p>Мощность настенных электрических котлов колеблется в пределах 5 – 60 кВт, отапливаемая ими площадь – от 50 до 600 м2, что должно быть вполне достаточно для дачного дома или коттеджа.</p>
                <p>Электропитание котлов малой мощности осуществляется от однофазной 220 В или трехфазной 380 В электрической сети. Для эксплуатации котлов мощностью более 12 кВт используется трехфазная сеть. Это обстоятельство требует оформления разрешительных документов с соответствующими органами на использование этого напряжения.</p>
                <img src={img2} alt='' />

                <h2>Оборудование котельной</h2>
                <p>Котельная представляет собой отдельное сооружение или помещение в здании, в котором установлено оборудование для нагрева воды и распределения ее по трубопроводным магистралям, осуществляющим подачу тепла в здания.</p>
                <p>Главным устройством котельной установки является котел, в этом устройстве происходит образование тепла в результате сгорания топлива. Кроме того, в котельной устанавливается такое оборудование:</p>
                <ul>
                    <li>топочное устройство;</li>
                    <li>насосы и инжекторы, которые обеспечивают подачу воды в котел, называют питательными устройствами;</li>
                    <li>тягодутьевые приспособления обеспечивают подачу необходимого количества воздуха в топку, к ним относятся воздуховоды, дымоходы и дутьевые вентиляторы;</li>
                    <li>вспомогательное оборудование позволяет повышать продуктивность работы котельной установки.</li>
                </ul>
                <p>Существующие котельные установки могут служить для отопления домов, производства горячей воды, также существуют энергетические и производственные котельные.</p>
                <img src={img3} alt='' />

                <h2>Проектирование котельной</h2>
                <p>Проектирование и монтаж оборудования котельной - самый важный этап в процессе ее строительства.</p>
                <p>Чтобы избежать ошибок, которые могут обернуться для Вас финансовыми потерями, правильным решением будет обратиться в специализированную проектно-монтажную организацию (далее - подрядчик) этого профиля. Необходимо помнить, что подрядчик должен иметь все разрешительные документы на выполнение проектно-монтажных работ по строительству котельной. Вместе с подрядчиком Вы составите так называемое "техническое задание",- перечень Ваших пожеланий к устройству котельной.</p>
                <p>На основании технического задания специалисты могут предложить инженерные решения по строительству котельной, составят технико-экономическое обоснование, помогут при оформлении технических условий, разработают проект, рассчитают нагрузки на отопление, горячее водоснабжение (ГВС) и т.д.</p>
                <p>Эта же организация - подрядчик может выполнить согласование и регистрацию проекта в соответствующих государственных органах.</p>
                <p>В последующем, при Вашем желании, подрядчик может обеспечить комплектацию котельной оборудованием, ее монтаж, пусконаладочные работы, сдачу в эксплуатацию и сервисное обслуживание.</p>
                <p>В зависимости от Ваших возможностей, квалификации и понятия вопроса, объем работ, выполняемых подрядчиком можно заказать от «нуля» до сдачи «под ключ», или на этап проектирования и согласования с органами надзора необходимых документов. При этом Вы оставляете за собой право выполнять строительство помещения котельной (если это отдельно стоящая или пристроенная котельная), монтажа оборудования и пуско-наладочные работы. Можно рассматривать и другие варианты с объемом подрядных работ этих организаций. То есть, подойти к привлечению квалифицированных специалистов дифференцированно.</p>
                <p>В общем виде проектирование котельных делится на этапы:</p>
                <ul>
                    <li>получение технического задания;</li>
                    <li>расчет и подбор основного оборудования;</li>
                    <li>выполнение рабочих чертежей котельной;</li>
                    <li>выполнение пояснительной записки;</li>
                    <li>согласование и сдача проекта.</li>
                </ul>
                <p>Стандартный проект котельной состоит из следующих частей:</p>
                <ul>
                    <li>пояснительная записка (ПЗ);</li>
                    <li>теплотехническая часть (ТМ);</li>
                    <li>электрика и освещение котельной (ЭО);</li>
                    <li>автоматика безопасности, регулирования и контрольно измерительная аппаратура (КИП);</li>
                    <li>внутреннее газоснабжение котельной (ГСВ)</li>
                    <li>водопровод и канализация котельной (ВК);</li>
                    <li>отопление и вентиляция котельной (ОВ);</li>
                    <li>перечень мероприятий по оценке воздействия на окружающую среду (ПМ ООС);</li>
                    <li>узлы учета тепловой энергии (УУТ);</li>
                    <li>пожарная и охранная сигнализации (АСУ).</li>
                </ul>

                <h2>Обслуживание</h2>
                <p>Современное оборудование, которое применяют для обустройства котельных, имеет автоматическое управление. Чтобы следить за работой отопительного комплекса достаточного одного или двух человек. В их обязанности входит обеспечение контроля за работой приборов.</p>
                <p>Обслуживание котельных установок заключается в профилактическом или текущем ремонте. Проведение таких работ позволяет предотвратить возможные поломки. Чаще всего котельные установки реализуются и устанавливаются одной компанией, которая обеспечивает дальнейшее сервисное обслуживание оборудования.</p>
                <p>Перед запуском мастера производят наладку оборудования, выставляют необходимые настройки и проводят пробный запуск. В процессе работы котельной возникает необходимость в замене или очистке от копоти и сажи фильтров, внутренней поверхности оборудования.</p>
                <p>Для обеспечения безопасности регулярно проводятся проверки на срабатывание автоматического отключения всех основных систем. В случае обнаружения проблем необходимо срочное устранение неполадок.</p>
                <p>Особый контроль ведется за химическим составом топлива и воды. Эти параметры оказывают большое влияние на качество работы установки, а также на производительность и срок службы оборудования.</p>
                <p>Котельную Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default BoilerRoom