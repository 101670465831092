import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './gymAirConditioning.css'

import img67 from '../../../img/67382sport2110.jpg'
import img12 from '../../../img/122174ventiltion-conditionning_fitnes-klub_gym_trenazhernyiy_zal.jpg'

const GymAirConditioning = () => {
    return (
        <>
            <div className='gym-air-conditioning-page'>
                <h1>Кондиционирование спортзала, фитнес-центра, спортклуба</h1>
                <p>Систему кондиционирования спортзала, фитнес-центра Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Спортивный зал является особенным местом, где по «закону жанра» люди получают оздоровление и физическое, и психологическое. Исходя из этого само собой разумеется, что в помещении такой специфики благоприятные условия, тем более что это регламентировано нормами и стандартами. И касаются они в первую очередь устройство эффективной вентиляционной системы, ведь при повышенных физических нагрузках для группы людей в спортзале скапливается неприятный запах, снижается уровень кислорода.</p>
                <p>В процессе занятий спортом люди ведут более активную деятельность, чем сотрудники в офисе, а значит, для них важно обеспечить постоянный приток свежего воздуха. Если такой возможности нет, обеспечьте спортсменом комфортную обстановку без сквозняков, равномерно распределив кондиционеры в зале.</p>
                <p>Эффективность работы и производительность зависят от правильности проведенных расчетов мощности. Устанавливая мощные кондиционеры в фитнес-центр и спортзал, убедитесь, что вы выбрали действительно оптимальный вариант. Чрезмерно мощная техника, также как и с низким уровнем мощности, негативно сказывается на продолжительности службы системы. Консультанты интернет-магазина «Ситиклимат» помогут грамотно выбрать наиболее подходящий тип кондиционера, исходя из полученной от вас информации относительно максимальной загрузки помещения, его площади и т.д.</p>

                <h2>Особенности</h2>
                <p>Особенностью кондиционирования спортивных залов является повышенный воздухообмен: в несколько раз больше, чем в офисе или квартире. Это объясняется тем, что при повышенных физических нагрузках человеческий организм потребляет больше кислорода и выделяет больше тепла.</p>
                <p>Популярность спортивного зала среди посетителей теперь зависит не только от оборудования и профессиональных инструкторов, но и от правильной вентиляции. Если в помещении спортзала спертый воздух, а неприятные запахи не проветриваются, люди вряд ли захотят прийти в него снова.</p>
                <p>Кроме неприятных ощущений, плохое кондиционирование в спортзале может вызывать у занимающихся слабость: особенно, если в зале одновременно находится много человек, что приводит к скоплению углекислого газа и испарений. Монтаж кондиционеров позволяет создать для посетителей максимально комфортную обстановку и постоянно подавать в зал свежий воздух.</p>
                <img src={img67} alt='' />
                <p>Часто для этих целей устанавливают приточно-вытяжные системы. При проектировании учитывают интенсивность нагрузок, среднее количество посетителей и размеры окон в зале. Хотя воздухообмен в помещении должен быть большим, скорость циркуляции воздуха, напротив, должна быть минимальной. В зале не должны образовываться сквозняки. Для этого применяются воздуховоды с большим сечением.</p>
                <p>В маленьких тренажерных и гимнастических залах можно установить моноблочные проточно-вытяжные системы, оснащенные фильтрами наружного воздуха и способные подогревать его зимой. Их можно использовать вместе с кондиционерами или отдельно. Главное, чтобы кондиционеры были подобраны из расчета дополнительного объёма приточного воздуха.</p>
                <p>Для крупных спортивных залов и комплексов подходят центральные и автономные кондиционеры. Они позволяют охлаждать, нагревать, очищать, осушать и увлажнять приточный воздух. Для экономии пространства громоздкие устройства для больших залов монтируются на крыше. Их можно объединять в одну систему, управление которой осуществляется автоматически при помощи дистанционного пульта. Все параметры воздуха можно будет устанавливать и поддерживать через него.</p>

                <h2>Требования и нормы к вентиляции закрытых спортивных комплексов</h2>
                <p>Действующий СНиП 2.08-02-89* «Общественные здания и сооружения» и справочное пособие к СНиП «Проектирование спортивных залов, помещений для физкультурно-оздоровительных занятий и крытых катков с искусственным льдом» предусматривают подачу 80 м3/ч на спортсмена и 20 м3/ч на зрителя.</p>
                <p>Параметры внутреннего воздуха практически ничем не отличаются от действующих допускаемых и оптимальных условий микроклимата. </p>
                <p>Спортивные залы, как правило, окружены обслуживающими помещениями: раздевалками, душевыми, помещениями для тренеров, массажистов и инвентаря, где воздухообмен считается по нормативной кратности.</p>
                <p>Примечание: обычно при расчете воздухообмена по кратностям создается таблица поэтажного баланса по воздуху и, как правило, нормативная вытяжка превышает приток. Для устранения дебаланса в коридоры обслуживаемых помещений подается приточный воздух в количестве равном поэтажному дебалансу.</p>
                <p>Воздухораздача приточного воздуха в спортивные залы выполняется обычно через воздухораспределители, установленными на высоте 3,5–4 м над полом наклонными компактными струями с перепадом температур t, °С:</p>
                <p>t = tр.з. – tn ? (4 ? 5) °C</p>
                <p>Удаление воздуха из верхней зоны зала, где он имеет несколько повышенную температуру и влагосодержание, выполняемое либо вытяжными системами, либо, что чаще, системами естественной вытяжки, оборудованными утепленными клапанами с электроподогревом створок и поддонами с дренажом для слива возможного конденсата.</p>
                <p>Рециркуляция воздуха вполне применима для спортивных залов, но с обязательным сохранением норматива подачи свежего воздуха.</p>
                <p>В помещениях, оборудованных клеедеревянными спортивными конструкциями, необходимо постоянно поддерживать относительную влажность воздуха не ниже 45%, а температуру воздуха – не выше 35°С.</p>
                <p>Подвижность воздуха в зонах нахождения занимающихся принимается не более:</p>
                <ul>
                    <li>0,3 м/с — в спортивных залах для борьбы, настольного тенниса и в крытых катках;</li>
                    <li>0,5 м/с — в остальных спортивных залах.</li>
                </ul>

                <h2>Выбор системы кондиционирования спортзала, фитнес-центра</h2>
                <p>Система кондиционирования спортивного зала определяется исходя из различных факторов: размера и месторасположения зала, его архитектурных особенностей, предполагаемых видов спорта, возможностей объекта и т.п.</p>
                <p>Скорость воздухообмена вентиляции спортзала или фитнес-центра рассчитывается, из условия необходимого тепло отведения избытков тепла, влаги и углекислого газа от людей, а также избыточного тепла привносимых освещением и тепловым солнечным излучением.</p>
                <img src={img12} alt='' className='img-width' />
                <p>Скорость движения воздуха в зоне занимающихся имеет значение поскольку она определят интенсивность теплообмена между телом человека и воздухом в помещении. Подача приточного воздуха в спортивные залы и фитнесс-центры выполняется обычно, через воздухораспределители, наклонными струями в зону занимающихся. Подвижность воздуха в этих зонах принимается не более 0,5 м/с. Удаление отработанного воздуха, имеющего более высокую температуру и влажность производится из верхней части помещения.</p>
                <p>Для сохранения тепла в холодное время года имеет смысл использовать рекуперацию (использование тепла выходящего воздуха для подогрева приточного в специальном обменнике). Такое устройство повышает первоначальные затраты при проектировании и монтаже системы вентиляции спортзала, но позволяет экономить в процессе эксплуатации от 50 до 70% тепловой энергии на нагревание приточного воздуха.</p>
                <p>Экономию повышает, также, автоматическое регулирование производительности климатической системы в зависимости от количества занимающихся людей. Так как тепловлажностная нагрузка зависит от воличества людей и неравномерна в течение дня.</p>
                <p>Современные фитнес-центры и спортивные залы, часто являются многофункциональными комплексами, которые кроме спортивных и тренажерных залов, включают бассейны, рестораны и бары, зоны релаксации, солярии, сауны, турецкие бани. При проектировании и монтаже систем вентиляции фитнес-центры и спортзалов необходимо также предусмотреть в данных помещениях системы вентиляции и кондиционирования воздуха.</p>
                <p>Систему кондиционирования спортзала, фитнес-центра Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default GymAirConditioning