import React from 'react'
import Form from '../form/Form'
import './contact.css'

const Contact = () => {
    return (
        <>
            <div className='contact-component'>
                <h1>Контакты</h1>
                <p>
                    <strong>Телефон: </strong>
                    <a href="tel:+998903559091">+998 (90) 355-90-91</a>
                </p>
                <p><strong>Режим работы:</strong> Пн.-пт. с 09.00 до 18.00, Сб.-Вс.: выходной</p>
                <p>
                    <strong>E-mail: <a href="mailto:fidclimat@mail.ru">fidclimat@mail.ru</a> - Уважаемые заказчики, пожалуйста, указывайте контактный телефон для оперативного уточнения деталей по вашей заявке. Заявки без телефона могут не рассматриваться.</strong>
                </p>
                <p><strong>Адрес офиса в Ташкент:</strong> Toshkent sh. Sergili t. Nilufar 2- tor koʻcha 40 - uy</p>

                <p></p>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d697.6655426929242!2d69.2603025!3d41.2060634!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae6129639ff4db%3A0xb7e0b823655b4f75!2sFid%20Climat!5e1!3m2!1sen!2s!4v1681846158854!5m2!1sen!2s" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='Map'></iframe>
            </div>
            <Form />
        </>
    )
}

export default Contact