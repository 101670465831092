import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img1 from '../../../img/uroven.jpg'

const SchoolVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция школы</h1>
                <p>Вентиляцию школы Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailt:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Система вентиляции в школах должна обеспечивать учащихся, преподавательский и обслуживающий персонал чистым и свежим воздухом, согласно действующим нормам. Школы состоят из большого количества помещений различного назначения: аудитории, комнаты преподавателей, столовые, спортивный зал и раздевалки, санитарные помещения и мастерские. Все эти помещения имеют свои нормы воздухообмена. Разная наполняемость помещений, в течение дня сильно осложняют процесс проектирования систем проветривания в таких образовательных учреждениях.</p>
                <p>В школах в основном применяют системы вентиляции с естественным и механическим побуждением. Очень распространены в них и комбинированные решения с обеспечением механического притока и естественной вытяжкой. Удаление загрязненного воздуха происходит через коридоры и неплотности в окнах и дверях. Кратность воздухообмена, при использовании такой схемы, принимают как однократную. </p>

                <h2>Душно в классе: чем дышит ребенок во время урока?</h2>
                <p>Обычно школы располагаются в городской черте, по соседству с шумными и загазованными дорогами. Поэтому окна во время урока обычно закрыты. В холодное время года закрывают окна еще и из-за сквозняков. Давайте посмотрим, что при этом происходит с воздухом в кабинете.</p>
                <img src={img1} alt='' className='img-width' />
                <p>Перед вами график: как меняется уровень СО2 в течение уроков (на примере двух больших уроков по 2 часа и перерывом между ними).</p>
                <p>Красная линия показывает, сколько СО2 в кабинете с закрытыми окнами и без хорошей приточной вентиляции. Уже во время первого урока значение превышает 3000 ppm. Для полноценного проветривания 15-минутной перемены недостаточно – на второй паре углекислого газа становится еще больше.</p>
                <p>В случае с 45-минутными уроками и более частыми перерывами картина будет более оптимистичной, но все равно уровень СО2 скорее всего будет выше допустимого. Как вы считаете: справедливо требовать от детей хороших оценок в таких условиях?</p>
                <p>Синяя линия – это изменение концентрации СО2 в классе с исправной вентиляцией. Окна закрыты, но воздух в помещении постоянно обновляется. Количество углекислого газа может изредка превышать условный максимум 1000 ppm, но с правильной вентиляцией в классе уровень СО2 скоро придет в норму.</p>

                <h2>Особенности вентиляции учебных заведений</h2>
                <p>Ключевой особенностью, которая должна учитываться при проектировании вентиляции школ, профессионально-технических и высших учебных заведений, является большое число помещений, имеющих абсолютно разное предназначение. Учебные аудитории, преподавательские, столовые, спортивные и актовые залы, лаборатории, подсобные комнаты, трудовые мастерские и проч. имеют собственные нормы воздухообмена.</p>
                <p>Кроме того, процесс организации качественной вентиляции осложняется тем фактом, что в течение учебного дня, все эти помещения имеют нестабильную наполняемость. Чтобы соблюсти все требования и избежать претензий со стороны проверяющих инстанций, необходимо следовать нормативно-технической базе, которая регламентирована в СНиПах и ГОСТах.</p>
                <p>Санитарно-гигиенические нормы и требования, предъявляемые к вентиляции университетов, школ и ПТУ. Главные критерии, характеризующие правильно спроектированную и организованную вентиляцию учебных заведений, касаются следующих параметров:</p>
                <ul>
                    <li>Качество воздуха</li>
                </ul>
                <p>Достаточный приток свежего и хорошо очищенного воздуха позволяет сконцентрироваться на учебном и преподавательском процессе.</p>
                <ul>
                    <li>Комфорт пребывания</li>
                </ul>
                <p>Учитывая, что вся аудитория пребывает в относительно малоподвижном состоянии в течение достаточно длительного времени, комфортные условия пребывания важны для хорошего самочувствия людей.</p>
                <ul>
                    <li>Уровень шума</li>
                </ul>
                <p>Создавая проект вентиляции школы, ВУЗов, техникумов, ПТУ и прочих учебных заведений, следует помнить, что все вентиляционные системы должны работать не только эффективно, но и бесшумно.</p>
                <p>На базе нормативных документов СНиП 41-01*2003, СНиП II-Л 65-73, СНиП 31-06*2009, за основу берутся следующие показатели:</p>
                <ul>
                    <li>Норма воздухообмена из расчета на 1 человека в час:  20 м3/ч для аудиторий и учебных классов: 80 м3/ч — для спортзалов; 20 м3/ч для пищеблоков.</li>
                    <li>Min. и max. t воздуха — 16°С и 22°С соответственно.</li>
                    <li>Предельно допустимый уровень шума — 110 дБ.</li>
                </ul>
                <p>Это обобщенные требования, но у каждого из типов учебных заведений есть свои нюансы организации качественной вентиляции. Они скорее относятся к конструктивным особенностям и расположению тех или иных помещений. Таким образом, проект вентиляции в школе является по своей сути каждый раз уникальной разработкой, чтобы сделать систему наиболее удобной, эффективной и экономически грамотной, что немаловажно для вентиляции частных школ, где финансовая составляющая является приоритетной. </p>

                <h2>Нормы вентиляции в школе</h2>
                <ul>
                    <li>Температурный режим варьируется в пределах от +16°С до +22°С;</li>
                    <li>Показатели влажности — от 30 до 65%.</li>
                    <li>Концентрация СО2 — не более 1 л. на 1 кубометр площади.</li>
                </ul>
                <p> * Помимо того, что эти показатели считаются оптимальными для комфортного пребывания людей в помещениях, это позволяет еще и предотвратить преждевременное разрушение материалов, которые были задействованы при строительстве и отделке здания и появление грибковой плесени. При превышении ПДК СО2 могут наблюдаться такие явления как сонливость, головокружение и общий упадок сил.</p>

                <h2>Виды вентиляции</h2>
                <p><strong>Вытяжная вентиляция с естественным притоком</strong></p>
                <p>Суть такой системы в том, что в отдельном помещении школы устанавливается мощная силовая установка, работающая как вытяжка. К каждому классу подводятся отдельные воздуховоды для отбора воздуха.</p>
                <p>Подача свежего воздуха осуществляется естественным путём за счёт естественной инфильтрации через окна или сквозь специальные приточные клапаны.</p>
                <p>На практике подобный подход оказался малоэффективным, поскольку естественный приток через пластиковые окна в закрытом состоянии практически отсутствует, а забор воздуха через вентиляционные клапаны сводит на нет все усилия по энергосбережению.</p>
                <p>Более того, в холодное время года такое решение чревато возникновением особо холодных зон возле окон, что также может привести к ухудшению здоровья учащихся.</p>
                <p>Тем не менее, из всех типов механизированных воздухообменных систем проект вытяжной вентиляции имеет минимальную стоимость.</p>
                <p><strong>Централизованные приточно-вытяжные системы</strong></p>
                <p>Классическое решение проблемы недостаточного воздухообмена - это установка полномасштабной вентиляционной системы, предполагающей подвод к классам как вытяжных, так и приточных воздуховодов.</p>
                <p>В этом случае можно обеспечить полный контроль над параметрами воздушной смеси:</p>
                <ul>
                    <li>температурную нормализацию (как нагрев, так и охлаждение);</li>
                    <li>осушение или увлажнение до требуемого уровня;</li>
                    <li>очистку от загрязнений.</li>
                </ul>
                <p>Для новых зданий такой подход стал практически стандартом и применяется, как правило, совместно с рекуператорными модулями.</p>
                <p>Однако, установка приточно-вытяжной системы в существующих зданиях сталкивается с большими сложностями строительного характера.</p>
                <p>Необходимо отметить, что существует две базовые разновидности приточно-вытяжной вентиляции:</p>
                <ul>
                    <li>смесительная;</li>
                    <li>вытесняющая.</li>
                </ul>
                <p>Смесительная схема предполагает, что подача и забор воздушных масс осуществляется через потолочные диффузоры, при этом входной поток, как правило, охлаждается (до 130C). В результате смешения с активным и нагретым воздухом, получается рабочая смесь приемлемой температуры (20-22 0C) с минимальным содержанием углекислого газа.</p>
                <p>В вытесняющей модели вентиляции входной поток подаётся без охлаждения (19-200C), через диффузоры, расположенные в нижней части помещения. В результате чего в активной зоне помещения формируется чистый слой воздуха, а нагретые и насыщенные CO2 воздушные массы вытесняются в потолочную зону, откуда откачиваются через заборные воздуховоды.</p>
                <p><strong>Локальная рекуперация</strong></p>
                <p>Все описанные выше методики обладают одним недостатком – их трудно применить к существующим зданиям, архитектура которых не предусматривала установку сетей из воздуховодов.</p>
                <p>Ввиду чего, школы стали первым типом зданий, в которых начали массово применяться локальные рекуператорные системы.</p>
                <p>Напомним, что понятие «рекуператор» в вентиляции означает приточно-вытяжную вентиляцию с переносом тепла от исходящих потоков на входящие.</p>
                <p>Рекуператорные теплообменные модули могут быть смонтированы как часть централизованной системы вентиляции, так и в виде локальных автономных блоков, монтируемых на стену или в оконном проёме.</p>
                <p>Наиболее яркий пример такого подхода – установка во всех школах Голландии рекуператорных вентиляционных блоков, с автоматической реакцией на превышение уровня CO2.</p>

                <h2>Готовые системы вентиляции для школы</h2>
                <p>Представим вашему вниманию готовые вентиляционные системы, которые идеально подойдут для школьных помещений и других учебных заведений.</p>
                <ol>
                    <li>Утепленные воздуховоды. Они способны подавать наружный воздух внутрь системы. Изнутри размешен канал утеплителя, предназначенный для защиты от промерзаний.</li>
                    <li>Дистанционная система управления. Благодаря такой системе можно выбирать вручную скорости вращения вентиляторов, это позволяет более точно расчитывать уровень проветривания, учитывая количество учащихся. Также можно регулировать температуру нагрева. Данная система выдает предупредительный сигнал, когда фильтр загрязнен и подлежит замене.</li>
                    <li>Фильтрующие системы. На современном рынке оборудования для вентиляции существует множество фильтров, которые могут оказаться незаменимыми в школе. Фильтры способны задерживать снаружи:</li>
                </ol>
                <ul>
                    <li>пыль, грязь, выхлопные газы, вещества вызывающие аллергию;</li>
                    <li>вирусные организмы, бактерии, колонии плесени;</li>
                    <li>газы, являющиеся отходами промышленной деятельности, дым от лесных пожаров;</li>
                </ul>
                <p>Вентиляцию школы Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailt:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default SchoolVentilation