import React from 'react'
import { Link } from 'react-router-dom'

import splitSystemJpg from '../../img/split-system.jpg'
import multiSplitSystemJpg from '../../img/multi-split-sistemy_small1_small.jpg'
import multiZonalniyJpg from '../../img/multizonalnye-vrv-i-vrf-sistemy-konditsionirovaniya_small1_small.jpg'
import chilleryJpg from '../../img/chillery1_small12_small.jpg'
import rooftopJpg from '../../img/rooftop_packaged_units1_small.jpg'
import pretsizionnyeJpg from '../../img/pretsizionnye-konditsionery_small_small.jpg'
import tsentralnyeJpg from '../../img/tsentralnye-konditsionery_small_small.jpg'
import ismalJpg from '../../img/i_small.jpg'
import min11Jpg from '../../img/111_1_min_small.jpg'
import pritochnoJpg from '../../img/pritochno-vytyazhnaya.jpg'
import vytyazhnayaJpg from '../../img/vytyazhnaya-sistema.jpg'
import ventilyatsiyaRekuperatsieyJpg from '../../img/ventilyatsiya_rekuperatsiey_small1_small.jpg'
import ventilyatsiyaRetsirkulyatsiyJpg from '../../img/ventilyatsiya-retsirkulyatsiy_2017-08-13_22-45-22-300x271_small.png'
import sentralnayaKonditsionerJpg from '../../img/sentralnaya_konditsioner.jpg'
import dymoudaleniyaJpg from '../../img/dymoudaleniya_small1_small.jpg'
import vavSistemyJpg from '../../img/vav_sistemy_small1_small.jpg'
import tsentralnayaSistemaVentilyatsiiJpg from '../../img/tsentralnaya-sistema-ventilyatsii_small1_small.png'
import hovalTopventJpg from '../../img/hoval-topvent-ventilation-heating_small.jpg'
import sistemyAspiratsiiJpg from '../../img/sistemy-aspiratsii_small.jpg'
import promyshlennayaVentilyatsiyaJpg from '../../img/promyshlennaya-ventilyatsiya_small1_small.jpg'
import article12_smallJpg from '../../img/article12_small.jpg'
import article123_smallJpg from '../../img/article123_small.jpg'
import a553ba73Jpg from '../../img/a553ba73-a9ae-4f77-bbd8-63780df76d0c_small.jpg'
import a96e8Jpg from '../../img/96e80977-1b2f-467b-b210-ba29f837e75e_small.jpg'
import terminalsJpg from '../../img/700ac16d-5751-4485-a815-f771dd91c875_small.jpg'
import e551d946Jpg from '../../img/e551d946-5175-4528-b66e-917e6d0fa483_small.jpg'
import a86cd1ce5Jpg from '../../img/a86cd1ce5-5eb4-4fbd-8602-fadf4dc63888_small.jpg'
import e47d822Jpg from '../../img/e47d822-22b0-4ca8-aa86-1a0a7ef08381_small.jpg'
import e7438bffJpg from '../../img/e7438bff-2bfa-40c6-9564-7f238b0ba90f_small.jpg'
import c37e0f6eJpg from '../../img/c37e0f6e-8a7f-4ec5-a38c-dad0deaeec20_small.jpg'
import b3300f701Jpg from '../../img/b3300f701-6241-4dca-af14-61c195af8b44_small.jpg'
import b7ffce11Jpg from '../../img/b7ffce11-6b82-4d5f-8640-d67cbd8d9222_small.jpg'
import article1234_smallJpg from '../../img/article1234_small.jpg'
import e6e534284Jpg from '../../img/e6e534284-99e5-4cad-a53d-374a093f0d26_small.jpg'
import b6e71ee4Jpg from '../../img/b6e71ee4-2f9f-46db-a190-2d4deb796fcf_small.jpg'

import './equipmentCatalog.css'
import Form from '../form/Form'

const EquipmentCatalog = () => {
    return (
        <>
            <div className='equipment-catalog-component'>
                <h1>Климатическое оборудование</h1>

                <h2>Системы кондиционирования</h2>
                <div className='products-wrapper'>
                    <Link to='/split-systems'>
                        <div className='product'>
                            <p>Сплит-системы</p>
                            <div className='bg' style={{backgroundImage: `url(${splitSystemJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/multi-split-systems'>
                        <div className='product'>
                            <p>Мульти сплит-системы</p>
                            <div className='bg' style={{backgroundImage: `url(${multiSplitSystemJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/multizone-vrf-vrv-sytems'>
                        <div className='product'>
                            <p>Мультизональные VRF и VRV системы</p>
                            <div className='bg' style={{backgroundImage: `url(${multiZonalniyJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/chillers-fancoils'>
                        <div className='product'>
                            <p>Чиллеры и фанкойлы</p>
                            <div className='bg' style={{backgroundImage: `url(${chilleryJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/roof-air-conditioners'>
                        <div className='product'>
                            <p>Крышные кондиционеры</p>
                            <div className='bg' style={{backgroundImage: `url(${rooftopJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/precision-air-conditioners'>
                        <div className='product'>
                            <p>Прецизионные кондиционеры</p>
                            <div className='bg' style={{backgroundImage: `url(${pretsizionnyeJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/central-air-conditioners'>
                        <div className='product'>
                            <p>Центральные кондиционеры</p>
                            <div className='bg' style={{backgroundImage: `url(${tsentralnyeJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Компрессорно-конденсаторный блок</p>
                            <div className='bg' style={{backgroundImage: `url(${ismalJpg})`}}></div>
                        </div>
                    </Link>
                </div>

                <h2>Системы вентиляции</h2>
                <div className='products-wrapper'>
                    <Link to='/supply-exhaust-ventilation'>
                        <div className='product'>
                            <p>Приточно-вытяжная вентиляция</p>
                            <div className='bg' style={{backgroundImage: `url(${pritochnoJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/forced-ventilation'>
                        <div className='product'>
                            <p>Приточная вентиляция</p>
                            <div className='bg' style={{backgroundImage: `url(${min11Jpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/exhaust-ventilation'>
                        <div className='product'>
                            <p>Вытяжная вентиляция</p>
                            <div className='bg' style={{backgroundImage: `url(${vytyazhnayaJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/heat-recovery-ventilation'>
                        <div className='product'>
                            <p>Вентиляция с рекуперацией</p>
                            <div className='bg' style={{backgroundImage: `url(${ventilyatsiyaRekuperatsieyJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/recirculation-ventilation'>
                        <div className='product'>
                            <p>Вентиляция с рециркуляцией</p>
                            <div className='bg' style={{backgroundImage: `url(${ventilyatsiyaRetsirkulyatsiyJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Центральные кондиционеры</p>
                            <div className='bg' style={{backgroundImage: `url(${sentralnayaKonditsionerJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/smoke-removal'>
                        <div className='product'>
                            <p>Дымоудаление</p>
                            <div className='bg' style={{backgroundImage: `url(${dymoudaleniyaJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>VAV системы</p>
                            <div className='bg' style={{backgroundImage: `url(${vavSistemyJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/central-ventilation'>
                        <div className='product'>
                            <p>Центральная вентиляция</p>
                            <div className='bg' style={{backgroundImage: `url(${tsentralnayaSistemaVentilyatsiiJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Децентрализованная вентиляция</p>
                            <div className='bg' style={{backgroundImage: `url(${hovalTopventJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Системы аспирации</p>
                            <div className='bg' style={{backgroundImage: `url(${sistemyAspiratsiiJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/industrial-ventilation'>
                        <div className='product'>
                            <p>Промышленная вентиляция</p>
                            <div className='bg' style={{backgroundImage: `url(${promyshlennayaVentilyatsiyaJpg})`}}></div>
                        </div>
                    </Link>
                </div>

                <h2>Увлажнители</h2>
                <div className='products-wrapper'>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Изотермические увлажнители</p>
                            <div className='bg' style={{backgroundImage: `url(${article12_smallJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Адиабатические увлажнители</p>
                            <div className='bg' style={{backgroundImage: `url(${article123_smallJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Свободно программируемые контроллеры</p>
                            <div className='bg' style={{backgroundImage: `url(${a553ba73Jpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Параметрические контроллеры для систем кондиционирования</p>
                            <div className='bg' style={{backgroundImage: `url(${a96e8Jpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Terminals</p>
                            <div className='bg' style={{backgroundImage: `url(${terminalsJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Автоматизация торгового холодильного оборудования</p>
                            <div className='bg' style={{backgroundImage: `url(${e551d946Jpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Параметрические контроллеры для холодильного оборудования</p>
                            <div className='bg' style={{backgroundImage: `url(${a86cd1ce5Jpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Системы водоподготовки</p>
                            <div className='bg' style={{backgroundImage: `url(${e47d822Jpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Системы мониторинга и удаленного управления</p>
                            <div className='bg' style={{backgroundImage: `url(${e7438bffJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Коммуникационное оборудование</p>
                            <div className='bg' style={{backgroundImage: `url(${c37e0f6eJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Датчики и устройства защиты</p>
                            <div className='bg' style={{backgroundImage: `url(${b3300f701Jpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Электронные терморасширительные вентили</p>
                            <div className='bg' style={{backgroundImage: `url(${b7ffce11Jpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Решения для управления температурой, влажностью и давлением</p>
                            <div className='bg' style={{backgroundImage: `url(${article1234_smallJpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Регуляторы скорости и инвертеры</p>
                            <div className='bg' style={{backgroundImage: `url(${e6e534284Jpg})`}}></div>
                        </div>
                    </Link>
                    <Link to='/moisturizing'>
                        <div className='product'>
                            <p>Комплектные щиты управления</p>
                            <div className='bg' style={{backgroundImage: `url(${b6e71ee4Jpg})`}}></div>
                        </div>
                    </Link>
                </div>
            </div>
            <Form />
        </>
    )
}

export default EquipmentCatalog