import React from 'react'
import p5099visa from '../../img/p5099visa-mc.png'
import Form from '../form/Form'
import './deliveryPayment.css'

const DeliveryPayment = () => {
    return (
        <>
            <div className='delivery-payment-component'>
                <h1>Доставка и оплата</h1>
                <p>Часы доставки:</p>
                <ul>
                    <li>Круглосуточно / ежедневно</li>
                </ul>

                <p>Бесплатная доставка:</p>
                <ul>
                    <li>распространяется на отдельные группы оборудования.</li>
                </ul>

                <p>Платная доставка:</p>
                <ul>
                    <li>Бытовые системы — 1000 руб.</li>
                    <li>Промышленные системы — от 1000 руб.</li>
                    <li>За МКАД - 1км. / 35 руб.</li>
                </ul>

                <p>Сроки доставки:</p>
                <ul>
                    <li>Со склада — на следующий день после заказа</li>
                    <li>Под заказ — от 2-х рабочих дней. Некоторое оборудование не только ввозится, но и изготовляется под заказ. Срок доставки фиксируется в договоре, прописывается финансовая гарантия в случае его нарушения.</li>
                </ul>

                <p>Доставка в регионы РФ и страны ТС:</p>
                <ul>
                    <li>По тарифам выбранной транспортной компании-партнера (Деловые линии, Желдорэкспедиция и другие, удобные покупателю)</li>
                    <li>Доставка до транспортной компании тарифицируется как доставка по Ташкент, если не указано иное</li>
                </ul>
                <p>Умный климат имеет собственные склады во многих городах Узбекистан, что сокращает сроки и стоимость доставки.</p>

                <h2>Способы оплаты:</h2>
                <ul>
                    <li>Все способы без комиссии. Цены на сайте включают НДС.</li>
                </ul>

                <p>Наличными</p>
                <ul>
                    <li>Оплачивайте наличными при получении заказа у Вас дома, в отделении ближайшего банка по квитанции или в нашем офисе.</li>
                </ul>

                <p>Безналичный расчет</p>
                <ul>
                    <li>Пришлите нам реквизиты Вашей организации для выставления счета.</li>
                </ul>

                <p>Банковской картой онлайн </p>
                <img src={p5099visa} alt='' className='rasm1' />
                <ul>
                    <li>Любой заказ Вы можете оплатить картой VISA, MASTERCARD или МИР. Оформите заказ через корзину или по телефону. Сервис предоставлен ПАО Сбербанк, наш магазин не будет иметь доступа к Вашим платежным данным.</li>
                </ul>

                <p>В кредит онлайн</p>
                <ul>
                    <li>Вы можете оплатить заказ полностью или частично при помощи онлайн-кредита на сумму до 200 000 рублей. Сервис предоставлен АО «Тинькофф Банк», наш магазин не будет иметь доступа к Вашим платежным данным.</li>
                </ul>

                <p>В рассрочку без переплаты</p>
                <ul>
                    <li>Некоторые товары можно купить в рассрочку. Сумма рассрочкки до 200 000 рублей для физических лиц. Для дополнительной информации обратитесь к консультанту.</li>
                </ul>
                <p>Возможны иные способы оплаты по договоренности.</p>

                <h2>Возврат и обмен товара</h2>
                <p>Покупатель вправе вернуть в магазин или обменять любой купленный товар в течение четырнадцати дней, кроме тех товаров, которые были поставлены под заказ. К заказным товарам относятся:</p>
                <ul>
                    <li>оборудование, изготовленное по уникальным техническим условиям для нужд покупателя;</li>
                    <li>оборудование, импортируемое на территорию РФ специально для нужд покупателя;</li>
                </ul>
                <p>В таких случаях условия отмены заказа и возврата товара оговариваются в договоре поставки.</p>
                <p>Во всех остальных случаях возврат и обмен, если это не обращение по гарантии, осуществляется при соблюдении следующих условий:</p>
                <ul>
                    <li>товар не монтировался, не подключался и не был в употреблении;</li>
                    <li>сохранен товарный вид изделия;</li>
                    <li>все потребительские свойства товара сохранены;</li>
                    <li>сохранены пломбы, упаковка и фабричные ярлыки товара;</li>
                    <li>имеется документ, подтверждающий покупку (приходно-кассовый ордер, расходная накладная, кассовый чек);</li>
                    <li>товар полностью укомплектован;</li>
                </ul>
                <p>При возврате или обмене товара по инициативе покупателя все расходы по перемещению товара оплачиваются покупателем, стоимость доставки не возвращается. Возвращаемый товар следует доставить по адресу расположения центрального офиса и склада.</p>
                <p>Возврат средств производится тем же способом, каким была осуществлена оплата товара, при оплате товара банковской картой, деньги возвращаются на ту же банковскую карту. Возврат средств осуществляется в течение 10 рабочих дней с момента принятия положительного решения о возврате.</p>
            </div>
            <Form />
        </>
    )
}

export default DeliveryPayment