import React from 'react'
import { Link } from 'react-router-dom'
import logoPng from '../../img/logo.png'
import './footer.css'

const Footer = () => {
    return (
        <div className='footer-component'>
            <div className='top'>
                <Link to='/'>
                    <img src={logoPng} alt='' />
                </Link>
                <div>
                    <a href='tel:+998903559091' className='tel'>+998 (90) 355-90-91</a>
                    <p>Пн.-пт.: 09.00 — 18.00</p>
                </div>
                <div>
                    <p>Toshkent, Sergeli</p>
                    <Link to='/contact'>Контакты</Link>
                </div>
            </div>
            <p>© Copyright 2022 Вентиляция и кондиционирование.</p>
        </div>
    )
}

export default Footer