import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img1 from '../../../img/12691325-05-2014-22-05-38.jpg'
import img2 from '../../../img/5504125-05-2014-21-34-44.jpg'
import img3 from '../../../img/25-05-2014 21-59-30.jpg'
import img4 from '../../../img/25-05-2014 22-02-46.jpg'
import img5 from '../../../img/25-05-2014 22-23-03.jpg'

const HotelVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция гостиницы или отеля</h1>
                <p>Вентиляцию гостиницы или отеля Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <img src={img1} alt='' className='img-width' />
                <img src={img2} alt='' className='img-width' />

                <h2>Вентиляция и кондиционирование воздуха</h2>
                <ul>
                    <li>Воздуховоды должны монтироваться вне зависимости от наличия технологического оборудования в соответствии с проектными привязками и отметками. Присоединение воздуховодов к технологическому оборудованию должно производиться после его установки.</li>
                    <li>Воздуховоды, предназначенные для транспортирования увлажненного воздуха, следует монтировать так, чтобы в нижней части их сечения не было продольных швов. Участки воздуховодов, в которых возможно выпадение конденсата из транспортируемого влажного воздуха, следует прокладывать с уклоном от 0,010 до 0,015 в сторону дренирующих устройств.</li>
                    <li>Уплотнительные прокладочные материалы между фланцами не должны выступать внутрь воздуховодов. В качестве уплотнительных прокладочных материалов могут применяться:</li>
                </ul>
                <p>ленточная пористая или монолитная резина толщиной от 4 до 5 мм или полимерный мастичный жгут (ПМЖ) для воздуховодов, по которым перемещаются воздух, пыль или отходы материалов с температурой до 343 К (70 °С) включ.;</p>
                <p>паронит, термостойкая монолитная резина — для воздуховодов, по которым перемещаются воздух, пыль или отходы материалов с температурой выше 343 К (70 °С);</p>
                <p>кислотостойкая резина или кислотостойкий уплотнительный пластик — для воздуховодов, по которым перемещается воздух с парами кислот;</p>
                <p>асбестовый шнур или асбестовый картон — для воздуховодов противодымной защиты при пожаре.</p>
                <p>Для герметизации бесфланцевых соединений воздуховодов следует применять:</p>
                <ul>
                    <li>герметизирующую ленту «Герлен Д» — для воздуховодов, по которым перемещается воздух </li>
                    <li>с температурой до 333 К (60 °С) включ.;</li>
                    <li>невысыхающую мастику по ГОСТ 24025 — для воздуховодов, транспортирующих воздушную смесь с температурой выше 363 К (90 °С).</li>
                </ul>
                <p>Допускается применение других прокладочных материалов, изготовленных по соответствующим ТНПА.</p>
                <ul>
                    <li>Болты во фланцевых соединениях должны быть затянуты, гайки должны быть расположены с одной стороны фланца. При вертикальной установке болтов гайки, как правило, должны быть расположены с нижней стороны соединения. Воздуховоды из оцинкованной стали должны быть укомплектованы метизами и фланцами из оцинкованного проката. Соединения из гнутого углового профиля, выполненные из оцинкованной листовой стали, стягиваются между собой скобами.</li>
                    <li>Крепление воздуховодов следует выполнять в соответствии с проектной документацией или допускается устанавливать кронштейны для подвесок непосредственно на воздуховод путем крепления кронштейнов при помощи заклепок или саморезов (по технологии изготовления).</li>
                </ul>
                <p>Крепления горизонтальных металлических неизолированных воздуховодов (хомуты, подвески, опоры и др.) на бесфланцевом соединении следует устанавливать на расстоянии не более 4 м одно от другого при диаметре воздуховода круглого сечения или размере большей стороны воздуховода прямоугольного сечения менее 400 мм и на расстоянии не более 3 м одно от другого — при диаметре воздуховода круглого сечения или размере большей стороны воздуховода прямоугольного сечения 400 мм и более.</p>
                <p>Крепления горизонтальных металлических неизолированных воздуховодов на фланцевом соединении круглого сечения диаметром до 2000 мм включ. или прямоугольного сечения при размере его большей стороны до 2000 мм включ. следует устанавливать на расстоянии не более 6 м одно от другого. Расстояния между креплениями изолированных металлических воздуховодов любых размеров поперечных сечений, а также неизолированных воздуховодов круглого сечения диаметром более 2000 мм или прямоугольного сечения при размере его большей стороны более 2000 мм должны назначаться проектной документацией.</p>
                <p>Хомуты должны плотно охватывать металлические воздуховоды.</p>
                <p>Крепления вертикальных металлических воздуховодов следует устанавливать на расстоянии не более 4 м включ. одно от другого.</p>
                <p>Крепление вертикальных металлических воздуховодов внутри помещений многоэтажных корпусов с высотой этажа до 4 м включ. следует выполнять в междуэтажных перекрытиях.</p>
                <p>Крепление вертикальных металлических воздуховодов внутри помещений с высотой этажа более 4 м и на кровле здания должно назначаться проектной документацией.</p>
                <p>Крепление растяжек и подвесок непосредственно к фланцам воздуховода не допускается. Натяжение регулируемых подвесок должно быть равномерным.</p>
                <p>Чертежи нетиповых креплений воздуховодов должны входить в комплект проектной документации.</p>
                <ul>
                    <li>Свободно подвешиваемые воздуховоды должны быть расчалены путем установки двойных подвесок через каждые две одинарные подвески при длине подвески от 0,5 до 1,5 м включ. При длине подвесок более 1,5 м двойные подвески следует устанавливать через каждую одинарную подвеску.</li>
                    <li>Воздуховоды должны быть закреплены так, чтобы их вес не передавался на вентиляционное оборудование. Воздуховоды, как правило, должны присоединяться к вентиляторам через виброизолирующие гибкие вставки из материала, обеспечивающего гибкость, плотность и долговечность, а для систем противодымной защиты при пожаре — и требуемый предел огнестойкости. Виброизолирующие гибкие вставки следует устанавливать непосредственно перед испытанием (обкаткой) на холостом ходу вентиляционного оборудования.</li>
                    <li>При монтаже вентиляционных систем в помещениях категорий А и Б, а также систем местных отсосов, удаляющих взрывоопасные смеси, необходимо устанавливать перемычки заземления между фланцами воздуховодов для соединения их в непрерывную электрическую цепь с присоединением к контуру заземления помещения, в соответствии с проектной документацией, требованиями и ГОСТ 30331.3.</li>
                    <li>Вентиляторы радиальные на виброоснованиях и на жестком основании, устанавливаемые на фундаменты, должны закрепляться фундаментными болтами. При установке вентиляторов на пружинные виброизоляторы последние должны иметь равномерную осадку. Виброизоляторы не требуется крепить к полу.</li>
                    <li>При установке вентиляторов на металлоконструкции виброизоляторы следует крепить к ним. Элементы металлоконструкций, к которым крепятся виброизоляторы, должны совпадать в плане с соответствующими элементами рамы вентиляторного агрегата. При установке на жесткое основание станина вентилятора должна плотно прилегать к звукоизолирующим прокладкам.</li>
                    <li>Зазоры между кромкой переднего диска рабочего колеса и кромкой входного патрубка радиального вентилятора как в осевом, так и в радиальном направлении не должны превышать 1 % диаметра рабочего колеса. Валы радиальных вентиляторов должны быть установлены горизонтально (валы крышных вентиляторов — вертикально), отклонение от горизонтали или вертикали не должно превышать ±0,5 мм/м, вертикальные стенки кожухов центробежных вентиляторов не должны иметь перекосов и наклона. Прокладки для составных кожухов вентиляторов следует применять из того же материала, что  и прокладки для воздуховодов данной системы.</li>
                    <li>Электродвигатели должны быть точно выверены с установленными вентиляторами и закреплены. Оси шкивов электродвигателей и вентиляторов при ременной передаче должны быть параллельными, а средние линии шкивов должны совпадать. Салазки электродвигателей должны быть взаимно параллельны и установлены по уровню. Опорная поверхность салазок должна соприкасаться по всей плоскости с фундаментом. Соединительные муфты и ременные передачи следует ограждать.</li>
                    <li>Всасывающее отверстие вентилятора, не присоединенное к воздуховоду, необходимо защищать металлической сеткой.</li>
                    <li>Фильтрующий материал матерчатых фильтров должен быть натянут без провисов и морщин, а также плотно прилегать к боковым стенкам. При наличии на фильтрующем материале начеса последний должен быть расположен со стороны поступления воздуха.</li>
                    <li>Воздухонагреватели кондиционеров следует собирать на прокладках из листового и шнурового асбеста. Остальные блоки, камеры и узлы кондиционеров должны собираться на прокладках из ленточной резины толщиной от 3 до 4 мм, поставляемой в комплекте с оборудованием.</li>
                    <li>Кондиционеры должны быть установлены горизонтально, стенки камер и блоков не должны иметь вмятин, перекосов и наклонов. Лопатки клапанов должны свободно (от руки) поворачиваться. При положении «Закрыто» должна быть обеспечена плотность прилегания лопаток к упорам и между собой. Опоры блоков камер и узлов кондиционеров должны устанавливаться вертикально. Бытовые кондиционеры должны монтироваться в соответствии с инструкцией изготовителя.</li>
                    <li>Гибкие воздуховоды следует применять в соответствии с проектной документацией в качестве фасонных частей сложной геометрической формы, а также для присоединения вентиляционного оборудования, воздухораспределителей, шумоглушителей и других устройств, расположенных в межпотолочном пространстве над подвесными потолками и в камерах.</li>
                    <li>Воздуховоды, выполненные из полимерных материалов, необходимо крепить на подвесных или сплошных опорах. Каждый прямой участок или фасонная деталь должны иметь отдельную опору или подвеску. Расстояние между подвесками не должно превышать 2,5 м для горизонтальных воздуховодов и 3 м — для вертикальных. Необходимо устанавливать прокладки из резины или полимерного материала толщиной от 3 до 5 мм между воздуховодом и опорой (хомутом).</li>
                    <li>При прокладке воздуховодов из полимерных материалов должны быть предусмотрены такие крепления, которые обеспечивали бы возможность температурного удлинения воздуховодов при колебаниях температуры транспортируемой среды.</li>
                    <li>При соединении воздуховодов из полимерных материалов в звенья продольные швы воздуховодов должны быть расположены со смещением, а при монтаже звеньев необходимо применять средства временного усиления.</li>
                    <li>Фланцевые соединения воздуховодов из полимерных материалов необходимо собирать на стальных или винипластовых болтах с подкладкой шайб, как под головку болта, так и под гайку.</li>
                    <li>Огнезадерживающие клапаны необходимо устанавливать непосредственно в преграде или за ее пределами, обеспечивая на участке воздуховода от преграды до клапана предел огнестойкости, равный огнестойкости преграды, а также на каждом транзитном воздуховоде (на расстоянии не более 1 м от ближайшего к вентилятору ответвления).</li>
                    <li>Воздуховоды противодымной защиты при пожаре монтируются в той же последовательности, что и воздуховоды общеобменной вентиляции.</li>
                    <li>Все запорно-регулирующие устройства, смонтированные в системах вентиляции и кондиционирования воздуха, должны быть установлены в положении «Открыто».</li>
                    <li>Элементы крепления деталей систем вентиляции должны иметь предел огнестойкости не менее чем требуемый предел огнестойкости воздуховодов.</li>
                    <li>Приборы учета, контрольно-измерительные приборы и автоматика должны быть смонтированы в соответствии с проектной документацией.</li>
                </ul>
                <p>Сегодня гостиничный бизнес – это быстроразвивающаяся и конкурентоспособная среда. Для того чтобы данный бизнес процветал, руководителям крайне важно обеспечить максимальное удобство для своих постояльцев.  </p>
                <p>Гостиницы могут быть разными по своему классу, размеру (от небольших мини отелей до внушительных гостиничных комплексов), но в любом случае система вентиляции в них должна работать: </p>
                <ul>
                    <li>гибко и индивидуально – в каждом помещении, номере устанавливается свой микроклимат.</li>
                    <li>качественно – не должно быть «впитывания» запахов мебелью, ковровыми покрытиями, гардинами и т.д.; </li>
                    <li>бесшумно – большинство помещений являются спальными номерами;</li>
                    <li>надежно – система вентиляции гостиниц должна функционировать бесперебойно и быть легко доступной для обслуживания.</li>
                </ul>
                <img src={img3} alt='' className='img-width' />
                <p>От грамотно спланированной вентиляции гостиниц и мини-отелей напрямую зависит комфорт ее гостей, поэтому проектированием системы вентиляции и кондиционирования необходимо заняться до начала строительства здания. И только после проектных работ и выбора оптимального решения производить установку инженерных систем.  </p>
                <p>Гостиница – довольно сложный объект. Он состоит из различных по назначению помещений: номерного фонда, ресторана, кухни, тренажерного зала, прачечной, конференц-зала и т.д., иначе, помещений, где должны быть обеспечены индивидуальные условия вентиляции и кондиционирования.   </p>
                <img src={img4} alt='' className='img-width' />
                <p>В случае с проектированием вентиляции гостиниц и мини-отелей самым выгодным вариантом является комбинация механической приточной вентиляции и естественной вытяжной. Такая комбинация исключит возможность появления в номерах гостиниц избыточного давления, создаваемого разностью подаваемого и удаляемого из помещений воздуха.  </p>
                <img src={img5} alt='' />
                <p>Также для создания климатической комфортности в гостинице можно дополнять систему вентиляции системой кондиционирования «чиллер-фанкойл» с центральным кондиционером. Такой вариант позволит сэкономить место и не нарушить внешнего дизайна здания, т.к. центральный кондиционер размещается в подвале, чиллер – на крыше здания, фанкойлы (внутренние блоки) - в фальш-потолке. Кроме того, данная система работает на воде и является энергоэффективным решением. И в отличие от других систем, позволяет снизить затраты на обслуживание.</p>
                <p>Воздух, охлажденный летом или нагретый зимой в кондиционере по системе воздуховодов подается к каждому фанкойлу. С помощью фанкойлов обеспечивается индивидуальное поддержание заданной температуры в каждом помещении гостиницы. В свою очередь теплообменник центрального кондиционера снабжается охлажденной водой (или этиленгликолем) от чиллера. В системе чиллер-фанкойлы циркуляцию воды  (теплообменник центрального кондиционера) обеспечивает насосная станция, так же, как и чиллер, установленная на кровле здания, и регулирует индивидуальный тепловой режим в каждом помещении.  Удаление воздуха из санузлов и умывальных комнат осуществляется по сети воздуховодов централизованно крышным вентилятором. </p>
                <p>В данной системе внутренние блоки можно подобрать любого типа, но чаще всего отдают предпочтение настенным или канальным.  </p>
                <p>Для  кондиционирования и вентиляции небольших гостиниц или мини отелей чаще всего применяют приточно-вытяжные установки вместе с мультисплит-системой - это сплит-система, у которой на один наружный блок приходится два и более внутренних блоков, установленных в разных помещениях. Главный плюс этой системы – это меньшее, по сравнению с обычной сплит-системой, количество наружных блоков, что позволяет экономить место на внешней стене здания и не перегружать ее блоками. Эту систему используют, как правило, когда невозможно или нежелательно по каким-либо причинам разместить несколько наружных блоков на внешней стене объекта. </p>
                <p>Вентиляцию гостиницы или отеля Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HotelVentilation