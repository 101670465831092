import { Collapse } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom';

const AvtoMenus = () => {

    const { Panel } = Collapse;

    return (
        <Collapse expandIconPosition='end' defaultActiveKey={['1']}>
            <Panel header="Информация" key="1">
                <Link to='/protective-functions-requirements'>Защитные функции и требования к системам автоматики</Link>
                <Link to='/supply-ventilation-automation'>Автоматизация приточной вентиляции</Link>
                <Link to='/automation-ventilation-conditioning'>Автоматика промышленной вентиляции и кондиционирования</Link>
                <Link to='/popular-automation-ventilation-conditioning-systems'>Популярная автоматика для систем вентиляции и кондиционирования</Link>
                <Link to='/automation-control-functions'>Управляющие функции автоматизации</Link>
            </Panel>
        </Collapse>
    )
}

export default AvtoMenus