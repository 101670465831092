import { Collapse } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';

const HeatMenu = () => {

    const { Panel } = Collapse;

    return (
        <Collapse expandIconPosition='end' defaultActiveKey={['1']}>
            <Panel header="Услуги по отоплению" key="1">
                <Link to='/heating-design'>Проектирование отопления</Link>
                <Link to='/heating-installation'>Монтаж отопления</Link>
                <Link to='/heating-maintenance'>Обслуживание отопления</Link>
                <Link to='/heating-repair'>Ремонт отопления</Link>
            </Panel>

            <Panel header="Типы систем отопления" key="2">
                <Link to='/thermal-point'>Тепловой пункт</Link>
                <Link to='/boiler-room'>Котельная</Link>
            </Panel>

            <Panel header="Типы объектов" key="3">
                <Link to='/heating-private-house-cottage'>Отопление частного дома или коттеджа</Link>
                <Link to='/warehouse-heating'>Отопление склада</Link>
                <Link to='/heating-industrial-premises'>Отопление производственных помещений</Link>
                <Link to='/workshop-heating'>Отопление цеха</Link>
                <Link to='/office-building-heating'>Отопление офисного здания</Link>
                <Link to='/store-heating'>Отопление магазина</Link>
                <Link to='/shopping-mall-heating'>Отопление торгового центра</Link>
                <Link to='/heating-residential-buildings'>Отопление жилых зданий</Link>
                <Link to='/winter-garden-heating'>Отопление зимнего сада</Link>
            </Panel>
        </Collapse>
    )
}

export default HeatMenu