import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/2790819dfc4d1542db65193128124a08e405d8.jpg'
import img2 from '../../../img/17542kalk1-300x235.jpg'
import img3 from '../../../img/1643013-300x221.jpg'

const OfficeBuildingHeating = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Отопление офисного здания и офисных помещений</h1>
                <p>Отопление офисного здания Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления зданий по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Очевидно, в условиях российского климата наличие системы отопления в офисе просто необходимо. Система отопления офиса создает и поддерживает оптимальную для людей температуру внутри офисных помещений в течение отопительного периода. Благодаря системе отопления создаются комфортабельные условия работы офисных сотрудников в холодное время года. Существуют различные виды систем отопления в офисных и административных зданиях и помещениях. Выбор конкретного вида системы отопления зависит от множества условий, пожеланий заказчика и особенностей рассматриваемого объекта, по этому окончательное решение по выбору вида системы отопления должны принимать профессиональные инженеры.</p>

                <h2>Требования к отоплению жилых и административных зданий</h2>
                <p>Следует сразу отметить, что проект отопления административного здания должен выполняться соответствующим бюро. Специалисты оценивают параметры будущего здания и согласно требованиям нормативных документов выбирают оптимальную схему теплоснабжения.</p>
                <p>Независимо от выбранных видов систем отопления зданий, к ним предъявляются жесткие требования. Они базируются на обеспечении безопасности функционирования теплоснабжения, а также эффективности работы системы:</p>
                <ul>
                    <li>Санитарно-гигиенические. К ним относятся равномерное распределение температуры во всех помещениях дома. Для этого предварительно выполняется расчет тепла на отопление здания;</li>
                    <li>Строительные. Работа отопительных приборов не должна ухудшаться из-за особенностей конструктивных элементов здания как внутри, так и снаружи его;</li>
                    <li>Монтажные. При выборе технологических схем установки рекомендовано выбирать унифицированные узлы, которые можно будет оперативно заменить на аналогичные в случае выхода из строя;</li>
                    <li>Эксплуатационные. Максимальная автоматизация работы теплоснабжения. Это является первичной задачей наряду с теплотехническим расчетом отопления здания.</li>
                </ul>
                <p>На практике используют проверенные схемы проектирования, выбор которых зависит от типа отопления. Это является определяющим фактором для всех последующих этапов работы по обустройству отопления административного или жилого здания.</p>

                <h2>Ключевой вопрос отопления: центральная или автономная </h2>
                <p>Эксперты настаивают на том, что система отопления должна закладываться в здание еще на этапе разработки проектной документации. При этом первый шаг, который должен сделать заказчик, - определиться с выбором одного из двух стратегических вариантов построения отопительной системы: подключение к городской теплоцентрали или создание автономной системы. Монтаж систем отопленияв уже функционирующее здание часто невозможен без нарушения норм, но даже если и возможен, это обойдется в несколько раз дороже по сравнению с установлением системы в процессе строительства здания, в проекте которого разработаны все внутренние инженерные системы, включая отопление". </p>
                <p>На сегодняшний день в мире большинство владельцев офисных и торговых центров предпочитает использовать автономную систему отопления, подразумевающую наличие собственного отопительного котла. Для такой позиции собственников существует несколько причин, первая и основная из которых - полная независимость от городской отопительной инфраструктуры. Кроме того, обустройство автономной системы решает не только проблему отопления, но и вопросы, связанные с подачей в отдельные офисные помещения горячей воды. </p>
                <p>Следующий, но не менее важный аргумент - экономия. Владельцам или арендаторам помещений, оборудованных системами автономного отопления, не нужно оплачивать теплопотери, возникающие при доставке тепла от бойлерной или теплоэлектроцентрали до их батарей, авариях на магистралях и прорывах труб. </p>
                <p>Практика показывает, что расходы на обогрев объекта офисной и торговой недвижимости, оборудованного собственной котельной, в среднем в 2,5 раза меньше, чем при использовании центрального отопления. Если же речь идет об отдельно взятом офисе площадью до 350 м2, который имеет возможность подключения к газовой сети, то установка в нем двухконтурного навесного газового котла позволяет уменьшить расходы на отопление в 5 раз. </p>
                <p>Автономное отопление также дает владельцам возможность самостоятельно устанавливать начало и конец отопительного сезона и регулировать температуру в здании. Автоматика котлов и мини-котельных поддерживает в помещениях стабильную температуру на протяжении всего холодного периода, понижая расход топлива при оттепелях и повышая в период сильных морозов. Такие системы позволяют устанавливать желаемую температуру в разных комнатах одного и того же офиса. Более низкий уровень обогрева устанавливается для машинных залов, кладовых и складских помещений. При этом все здание отапливается одним источником - котлом. </p>
                <p>Отопление в коммерческом сегменте недвижимости имеет некоторые особенности, связанные с архитектурным своеобразием и функциональной спецификой использования помещений. Офисные, торговые и складские здания отличаются большими пространствами с высокими потолками. Кроме того, эксперт акцентирует внимание на специфике режима отопления, согласно которому офисные здания в основном эксплуатируются в рабочее время, таким образом, логично применять автоматику управления температурой для различного времени суток. </p>
                <p>Автоматика программируется на выполнение необходимых задач (например, переход в режим пониженной температуры в 19:00 и отключение этого режима в 07:00) и далее работает самостоятельно. </p>
                <p>В целом, использование автономного отопления избавляет от следующих недостатков централизованного решения отопления: </p>
                <ul>
                    <li>практическое отсутствие регулирования подачи теплоты на отопление зданий в переходные периоды, когда особенно большое влияние на тепловой режим отапливаемых помещений оказывают ветер, солнечная радиация, бытовые тепловыделения; </li>
                    <li>перерасход топлива и перегрев зданий в теплые периоды отопительного сезона; </li>
                    <li>большие потери тепла при его транспортировке, которые, по оценкам экспертов, зачастую превышают 10%; </li>
                    <li>нерациональный расход электроэнергии на перекачку теплоносителя, обусловленный самим принципом центрального качественного регулирования; </li>
                    <li>длительная эксплуатация подающих трубопроводов теплосети в неблагоприятном режиме температур, характеризующемся нарастанием коррозионных процессов и др. </li>
                </ul>
                <p><strong>Автономная система отопления</strong></p>
                <p>Сердцем любой автономной отопительной системы является котел. Котлы классифицируются в зависимости от видов потребляемого топлива на: </p>
                <ul>
                    <li>электрические (источник тепловой энергии - электричество);</li>
                    <li>газовые (газ); </li>
                    <li>жидкотопливные (солярка); </li>
                    <li>твердотопливные (дрова, уголь, торф); </li>
                    <li>комбинированные (различные виды топлива). </li>
                </ul>
                <p>Что же касается вреда, приносимого окружающей среде, то специалисты сравнивают мини-котельные с дымом одной выкуренной сигареты, причем при таком сравнении котельные даже несколько выигрывают, поэтому устанавливать их можно даже в центральных густонаселенных районах города. </p>
                <p>По мнению экспертов, в офисных зданиях нашей страны более эффективно использовать газовые котлы, поскольку такие факторы, как высокая стоимость топлива и продолжительность отопительного сезона делают применение всех остальных их видов нерентабельным. </p>
                <p>Также стоит остановиться на котлах с использованием комбинированного топлива, поскольку только они обеспечивают полную автономность отопительной системы здания. Комбинированные котлы имеют встроенный прибор автоматического регулирования работы, который может оснащаться электронными блоками, например, для программирования работы котла в зависимости от режима работы системы отопления, автоматического регулирования температуры нагреваемой воды в соответствии с температурой наружного воздуха и т.п. Кроме того, следует отметить, что комбинированные котлы на жидком и газообразном топливе обладают повышенной надежностью по сравнению с газовыми котлами, так как при перебоях с подачей газа, которые могут быть на газораспределительных сетях, особенно в периоды сильных морозов, когда возможно замерзание газового конденсата, система отопления способна переключиться на другой вид топлива. </p>
                <img src={img1} alt='' className='img-width' />
                <p>Единственным уязвимым звеном в цепи надежности комбинированных котлов на жидком и газообразном топливе могут быть перебои с подачей электроэнергии, при которых прекратится работа топливного насоса, дутьевого вентилятора, запального устройства, прибора автоматического регулирования, и котел автоматически отключится. Но по мере роста популярности систем бесперебойного электропитания в оснащении офисных центров данный недостаток в скором времени можно будет сбросить со счетов. </p>
                <p>Управление автономными котельными, как правило, осуществляется при помощи эквитермических систем, которые осуществляют выбор режима подачи газа в топку, исходя из данных датчика температуры наружного воздуха. Это позволяет поддерживать внутри здания стабильную комфортную температуру. Котлы в правильно спроектированных системах имеют высокий уровень КПД. Для гарантии "спокойного тепла" и отсутствия проблем с контролирующими службами эксперты рекомендуют заказчику удостовериться в наличии у предлагаемого оборудования соответствующих сертификатов и разрешений на эксплуатацию Госкомохрантруда. </p>
                <p>Мини-котельные делятся на два основных типа: крышные и пристроенные. Стоимость первых, как правило, несколько выше, однако их применение позволяет сэкономить место на прилегающей к зданию территории. Они занимают чердачное помещение, за счет чего их легче оградить от потенциального вандализма и возможного воровства. Однако, размещая котельную на крыше, необходимо соблюдать требования пожарной службы, первое из которых - обеспечение свободного доступа в котельную при возникновении аварийной ситуации. В современной отечественной практике известны случаи получения разрешений на установку крышной котельной на 16- и 20-этажных зданиях. </p>

                <h2>Современные системы отопления офиса</h2>
                <p>По состоянию на сегодняшний день для обогрева офисных помещений используют разные способы, которые отличаются друг от друга эффективностью, энергоэкономностью и сложностью монтажа. Самые популярные системы отопления офиса следующие:</p>
                <ul>
                    <li>Водяные;</li>
                    <li>Воздушные;</li>
                    <li>Электрические;</li>
                    <li>Инфракрасные.</li>
                </ul>
                <p>Водяной обогрев, в свою очередь, может быть радиаторным или представлять собой «теплый пол». Радиаторные водяные отопительные системы отлично подходят для обогрева офисных зданий. Основным их рабочим элементом выступает отопительный аппарат – газовый, твердотопливый или электрический котел, который подогревает циркулирующий по замкнутому трубопроводу теплоноситель. Выбрать ту или иную схему нужно на этапе проектирования и только после этого приступать к закупке оборудования.</p>
                <p>Современные батареи отопления, установленные в полу и под окном Водяные теплые полы в офисных помещениях устанавливаются крайне редко. Они больше подходят для частных домов и квартир. Суть этого типа отопительной системы состоит в установке нагревательных приборов под напольным покрытием, что бывает необходимым в помещениях с панорамными окнами, супермаркетах и ТЦ. Современные батареи отопления, установленные в полу и под окном, идеально подходят для монтажа обогрева в просторных помещениях, которые в лютый мороз бывает сложно обогреть.</p>
                <p>Воздушный вариант отлично подойдет для больших офисных помещений, рассчитанных на огромное количество сотрудников. Его можно встретить в крупных торговых центрах и прочих общественных помещениях с большой проходимостью. Оно представляет собой уникальную канальную разводку, в которой основным рабочим элементом все также выступает отопительный котел. Но подогревает он не жидкий теплоноситель, а воздух, который циркулирует по специальным, вмонтированным в пол, стены и потолок воздуховодам, повышая тем самым температуру в обогреваемом помещении.</p>
                <p>Водяной предполагает установку специальных конвекторов – нагревательных приборов с естественной или принудительной конвекцией. Принцип их работы состоит в перемещении холодных воздушных масс к теплообменнику, их подогреве и перемещении обратно вглубь помещения. Они могут быть настенными и внутрипольными. В больших и просторных офисных помещениях зачастую устанавливаются и те, и другие их виды.</p>
                <p>Инфракрасный обогрев, работающий по принципу теплового излучения, устанавливается в современных офисах крайне редко. Чаще всего оно используется для временного обогрева помещения на время проведения монтажных работ. Панельные инфракрасные обогреватели актуальны в больших производственных помещениях, цехах, складах и т.д. Каждый тип обогрева офиса по-своему хорош, если серьезно подойти к его проектированию.</p>

                <h2>Водяное отопление</h2>
                <p>Наиболее часто для обогрева офисных зданий и помещений используют водяное отопление. Источником тепла в этом случае может служить ТЭЦ или местная котельная. В котельной могут быть использованы котлы различного типа. Выбор системы трубопроводов и отопительных приборов (радиаторов или конвекторов) определяется на стадии проектирования исходя из величины напора в системе, конструктивных особенностей здания, качества воды и пожелания Заказчика.</p>
                <p>При желании для отопления офиса можно использовать систему водяной тёплый пол. Данный вид отопления способен обеспечить наиболее комфортные условия для пребывания человека. В зависимости от величины тепловых потерь помещения на стадии проектирования определяется, достаточно ли для отопления только тёплого пола или потребуется комбинированная система отопления с использованием дополнительно приборов отопления. Обустройство тёплого пола с водяным контурам лучше предусматривать в офисных помещениях во вновь строящихся зданиях. Однако технически возможно применение водяного напольного отопления и в существующих зданиях. В этом случае потребуются определённые капитальные затраты, поэтому предпочтительнее проводить такие работы, если офисные помещения не арендуемые, а находятся в собственности.</p>
                <p>Если в офисных помещениях планируется система кондиционирования  «чиллер-фанкойлы», то возможно решение использования данной системы в холодный период года для отопления. Для этого необходим чиллер с функцией теплового насоса. Ещё один вариант – возможность подключения гидравлической системы кондиционирования к тепловому пункту здания. Таким образом, летом в фанкойлы будет поступать охлаждённая в чиллере вода (либо раствор антифриза), а зимой будет возможность подключения с помощью перемычек к тепловому пункту.</p>

                <h2>Электрическое отопление</h2>
                <p>При отсутствии возможности организовать водяное отопление в качестве источника тепла используют электрическую энергию. Можно, например, установить электрический тёплый пол – в этом случае вместо водяного контура в конструкцию пола закладывается специальный электрический кабель. Недостаток данной системы только в относительно высокой стоимости электроэнергии.</p>
                <p>В качестве отопительных приборов для системы электрического отопления удобно использовать электрические конвекторы. Они легко монтируются на стене помещения и имеют современный приятный внешний вид. Многие современные электроконвекторы можно объединять в систему электрического отопления с единым управляющим устройством.</p>
                <p>Тепловентиляторы и масляные обогреватели удобно использовать, если по каким либо причинам мощности существующей системы отопления не хватает, что может случится, например, при её разрегулировке или иных неполадках.</p>
                <p>Инфракрасные обогреватели также можно использовать в сочетании с другой системой отопления, либо для отдельных помещений большой площади, например для демонстрационных залов или холлов, согласно проектных решений.</p>
                <p>В межсезонье для отопления офисных помещений также можно использовать сплит-систему, имеющую функцию теплового насоса, т.е. способную работать в обратном цикле: переносить тепло не из помещения на улицу, как в обычном режиме, а наоборот. Подобные модели есть практически у каждого производителя кондиционеров. Этот способ наиболее эффективен при температуре наружного воздуха +3-5 о С. При таких условиях показатель преобразования электроэнергии в тепло равен 2,2-2,6. Т.е. при затрате, например, 100 Вт электроэнергии вырабатывается 220-260 Вт тела. При температуре наружного воздуха ниже 0 о С показатель преобразования приближается к единице, т.е. становится близким к режиму прямого использования электроэнергии для нагрева воздуха.</p>

                <h2>Энергосбережение</h2>
                <p>Для сокращения тепловых потерь, а как следствие уменьшения мощности системы отопления, сопротивление теплопередаче наружных ограждений должно быть не менее требуемого по нормативным документам.</p>
                <p>Входные тамбуры здания можно оборудовать воздушно-тепловыми завесами, которые могут быть водяными или электрическими. В случае с водяными воздушными завесами стоит предусмотреть, помимо автоматики регулирования работы, защиту от замораживания водяного теплообменника.</p>
                <p>В целях энергосбережения применяются также автоматические терморегуляторы на отопительных приборах. Терморегуляторы позволяют поддерживать температуру внутреннего воздуха на заданном значении, осуществляя тем самым экономию тепловой энергии.</p>
                <p>Можно сказать, что в настоящее время многообразие товаров на рынке отопительного оборудования способствует реализации различных инженерно-технических решений с учётом индивидуальных особенностей строительных объектов. Поэтому для создания хорошего микроклимата в помещениях советуем обращаться к профессионалам, которые предложат оптимальные варианты решения для каждого конкретного случая.</p>

                <h2>Выбираем оборудование отопления офиса</h2>
                <p>Монтажу отопительной системы в офисном помещении предшествует составление его рабочего проекта. В нем большую роль играет выбор основного рабочего элемента – отопительного прибора. Обогрев в офисе может быть разным:</p>
                <ul>
                    <li>Газовым;</li>
                    <li>Электрическим;</li>
                    <li>Твердотопливным;</li>
                    <li>Инфракрасным.</li>
                </ul>
                <p>Существуют также альтернативные методы обогрева офисного помещения, предполагающие задействование гелиосистем.</p>
                <p>Самым бюджетным вариантом оборудование отопление офиса будет установка газового котла, но при условии того, что здание подключено к общей газовой магистрали. Электричество дорогое, но высокоэффективное. Твердое топливо используется, преимущественно, в сельской местности, а инфракрасное больше подойдет для обогрева промышленных объектов. В одном и том же офисе допускается установка двух и более отопительных аппаратов, подключенных к одной или к разным системам. Это позволяет исключить риск размерзания системы при возникновении аварийной ситуации или выходе из строя одного из отопительных котлов.</p>
                <p><strong>Эффективное отопление под окном и в полу</strong></p>
                <p>Для более эффективного обогрева офисных помещений используется отопление под окном и в полу. Этот вариант выбирают те, кто понимает, насколько работоспособность сотрудников зависит от их комфорта нахождения на рабочем месте.</p>
                <p>Наличие двух отопительных систем – настенной и внутрипольной, особенно актуально при использовании конвекторного обогрева офиса. Эти уникальные в своем роде нагревательные приборы с естественной и принудительной конвекцией устанавливаются и под окном и встраиваются в специальные ниши в напольном покрытии. Сверху внутрипольные конвектора накрываются декоративной решеткой, которая отлично впишется в любой интерьер и не будет препятствовать свободному прохождению подогретых воздушных масс в центр офисного помещения.</p>
                <p>Купить внутрипольные и настенные электрические конвекторы от отечественных и импортных производителей можно в нашем интернет магазине, где они представлены в большом ассортименте. Им отведен отдельный каталог, где каждой модели присвоен свой уникальный артикул, позволяющий, не выходя из дома, приобрести понравившийся вариант, оформив прямо на нашем сайте онлайн заказ. У нас самые удобные условия заказа, демократичные цены и быстрая доставка товара в любой регион РФ.</p>
                <p><strong>Альтернативное отопление офисных зданий</strong></p>
                <p>В поисках наиболее выгодного решения многие собственники решаются на альтернативное отопление офисных зданий с использованием:</p>
                <ul>
                    <li>Тепловых насосов;</li>
                    <li>Использующих энергию солнца гелиосистем;</li>
                    <li>Кавитационных теплоагрегатов.</li>
                </ul>
                <p>Данные способы обогрева офисов на сегодняшний день еще не получили широкого распространения. Использовать их в качестве единственной отопительной системы дорого, рискованно и экономически не выгодно.</p>
                <h2>Типы расчета теплоснабжения зданий</h2>
                <img src={img2} alt='' />
                <p>На первом этапе необходимо выполнить расчет тепловой энергии на отопление здания. Суть этих вычислений состоит в определении тепловых потерь дома, подборе мощности оборудования и теплового режима работы отопления.</p>
                <p>Для корректного выполнения этих вычислений следует знать параметры здания, учитывать климатические особенности региона. До появления специализированных программных комплексов все расчеты количества тепла на отопление здания выполнялись вручную. При этом была высока вероятность ошибки. Теперь же, применяя современные методы вычислений, можно получить следующие характеристики для составления проекта отопления административного здания:</p>
                <ul>
                    <li>Оптимальная нагрузка на теплоснабжение в зависимости от внешних факторов – температуры на улице и требуемой степени нагрева воздуха в каждой комнате дома;</li>
                    <li>Правильный подбор компонентов для комплектации отопления, минимизация затрат на его приобретение;</li>
                    <li>Возможность в дальнейшем провести обновление теплоснабжения. Реконструкция системы отопления здания выполняется только после согласования старой и новой схем.</li>
                </ul>
                <p>Делая проект отопления административного или жилого здания нужно руководствоваться определенным алгоритмом вычислений.</p>
                <p><i>Характеристики системы теплоснабжения должны отвечать действующим нормативным документам. Их перечень можно взять в государственной архитектурной организации.</i></p>
                <p><strong>Вычисление тепловых потерь зданий</strong></p>
                <p>Определяющим показателем отопительной системы является оптимальное количество вырабатываемой энергии. Она же определяется тепловыми потерями в здании. Т.е. фактически работа теплоснабжения призвана компенсировать это явление и поддерживать температуру на уровне комфортной.</p>
                <p>Для корректного расчета тепла на отопление здания необходимо знать материал изготовления наружных стен. Именно через них происходит большая часть потерь. Основной характеристикой является коэффициент теплопроводности строительных материалов – количество энергии, проходящей через 1 м² стены.</p>
                <p>Технология расчета тепловой энергии на отопление здания заключается в следующих этапах:</p>
                <ol>
                    <li>Определение материала изготовления и коэффициента теплопроводности.</li>
                    <li>Зная толщину стены можно рассчитать сопротивление теплопередачи. Это величина обратная теплопроводности.</li>
                    <li>Затем выбирается несколько режимов работы отопления. Это разница между температурой в подающей и обратной трубе.</li>
                    <li>Деля получившеюся величину на сопротивление теплопередачи получаем тепловые потери на 1 м² стены.</li>
                </ol>
                <p>Коэффициент сопротивления теплопередачи стен</p>
                <p>Для такой методики нужно знать, что стена состоит не только из кирпича или ж/б блоков. При расчете мощности котла отопления и теплопотерь здания обязательно учитываются теплоизоляция и другие материалы. Общий коэффициент сопротивления телепередачи стены не должен быть меньше нормированного.</p>
                <p>Только после этого можно приступать к вычислению мощности отопительных приборов.</p>
                <p>Для всех полученных данных для расчета отопления по объему здания рекомендуется прибавить поправочный коэффициент 1,1.</p>
                <p><strong>Расчет мощности оборудования для отопления зданий</strong></p>
                <img src={img3} alt='' />
                <p>Для вычисления оптимальной мощности теплоснабжения следует начала определиться с его типом. Чаще всего затруднения возникают при расчете водяного отопления. Для корректного вычисления мощности котла отопления и тепловых потерь в доме учитывается не только его площадь, но и объем.</p>
                <p>Самый простой вариант – это принять соотношение, что для обогрева 1 м³ помещения потребуется 41 Вт энергии. Однако такое вычисление количества тепла на отопление здания будет не совсем корректно. Оно не учитывает тепловые потери, а также климатические особенности конкретного региона. Поэтому лучше всего воспользоваться методикой, описанной выше.</p>
                <p>Для расчета теплоснабжения по объему здания важно знать номинальную мощность котла. Для этого необходимо знать следующую формулу:</p>
                <p>W=S*K</p>
                <p>Где W – мощность котла, S – площадь дома, К – поправочный коэффициент.</p>
                <p>Последний является справочной величиной и зависит от региона проживания. Данные о нем можно взять из таблицы.</p>
                <p>Такая технология позволяет выполнить точный теплотехнический расчет отопления здания. Одновременно выполняется проверка мощности теплоснабжения относительно тепловых потерь в здании. Кроме этого учитывают назначение помещений. Для жилых комнат уровень температуры должен составлять от +18°С до +22°С. Минимальный уровень нагрева площадок и бытовых комнат равен +16°С.</p>
                <p>Выбор режима работы отопления практически не зависит от этих параметров. Он определит будущую нагрузку на систему в зависимости от погодных условий. Для многоквартирных домов расчет тепловой энергии на отопление делается с учетом всех нюансов и согласно нормативной технологии. В автономном теплоснабжении подобных действий выполнять не нужно. Важно, чтобы суммарная тепловая энергия компенсировала все тепловые потери в доме.</p>
                <p>Отопление офисного здания Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления зданий по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default OfficeBuildingHeating