import { Collapse } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';

const VentMenus = () => {

    const { Panel } = Collapse;

    return (
        <Collapse expandIconPosition='end' defaultActiveKey={['1']}>
            <Panel header="Услуги по вентиляции" key="1">
                <Link to='/consultations-ventilation'>Консультации</Link>
                <Link to='/ventilation-design'>Проектирование вентиляции</Link>
                <Link to='/installation-ventilation'>Монтаж вентиляции</Link>
                <Link to='/ventilation-maintenance'>Обслуживание вентиляции</Link>
                <Link to='/diagnosis-examination-ventilation'>Диагностика и обследование вентиляции</Link>
                <Link to='/modernization-reconstruction-ventilation'>Модернизация и реконструкция вентиляции</Link>
                <Link to='/ventilation-repair'>Ремонт вентиляции</Link>
            </Panel>

            <Panel header="Типы вентиляции" key="2">
                <Link to='/supply-exhaust-ventilation'>Приточно-вытяжная вентиляция</Link>
                <Link to='/forced-ventilation'>Приточная вентиляция</Link>
                <Link to='/exhaust-ventilation'>Вытяжная вентиляция</Link>
                <Link to='/heat-recovery-ventilation'>Вентиляция с рекуперацией</Link>
                <Link to='/recirculation-ventilation'>Вентиляция с рециркуляцией</Link>
                <Link to='/smoke-removal'>Дымоудаление</Link>
                <Link to='/central-ventilation'>Центральная вентиляция</Link>
                <Link to='/industrial-ventilation'>Промышленная вентиляция</Link>
            </Panel>
            
            <Panel header="Решения по типу объекта" key="3">
                <Link to='/apartment-ventilation'>Вентиляция квартиры</Link>
                <Link to='/ventilation-house-cottage'>Вентиляция дома или коттеджа</Link>
                <Link to='/office-ventilation'>Вентиляция офисов</Link>
                <Link to='/shop-ventilation'>Вентиляция магазинов</Link>
                <Link to='/shopping-mall-ventilation'>Вентиляция торгового центра</Link>
                <Link to='/ventilation-system-restaurant-cafe-bar'>Система вентиляции в ресторане, кафе или баре</Link>
                <Link to='/production-ventilation'>Вентиляция производства</Link>
                <Link to='/warehouse-ventilation'>Вентиляция склада</Link>
                <Link to='/ventilation-residential-buildings'>Вентиляция жилых зданий</Link>
                <Link to='/gym-fitness-center-ventilation'>Вентиляция спортзала или фитнес центра</Link>
                <Link to='/school-ventilation'>Вентиляция школы</Link>
                <Link to='/hotel-ventilation'>Вентиляция гостиницы или отеля</Link>
            </Panel>

            <Panel header="Дополнительная информация" key="4">
                <Link to='/energy-efficient-ventilation'>Энергоэффективная вентиляция</Link>
                <Link to='/calculation-ventilation-system'>Расчет системы вентиляции</Link>
            </Panel>
        </Collapse>
    )
}

export default VentMenus