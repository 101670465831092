import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './catalagAirConditioners.css'

import split_system from '../../../img/split-system.jpg'
import multi_split_system from '../../../img/multi-split-sistemy_small1_small.jpg'
import multizonalnye from '../../../img/multizonalnye-vrv-i-vrf-sistemy-konditsionirovaniya_small1_small.jpg'
import chillery1_small12_small from '../../../img/chillery1_small12_small.jpg'
import rooftop_packaged_units1_small from '../../../img/rooftop_packaged_units1_small.jpg'
import pretsizionnye from '../../../img/pretsizionnye-konditsionery_small_small.jpg'
import tsentralnaya_sistema_ventilyatsii_small1_small from '../../../img/tsentralnye-konditsionery_small_small.jpg'
import split from '../../../img/split-sistemy_small1_small_small.jpg'
import d4_small from '../../../img/d4_small.jpg'
import d6_small from '../../../img/d6_small.jpg'
import d5_small from '../../../img/d5_small.jpg'
import q357028497_w640_h640_lsm_h_tha2_small from '../../../img/q357028497_w640_h640_lsm_h_tha2_small.jpg'
import fha35a_small from '../../../img/fha35a_small.jpg'

const CatalagAirConditioners = () => {
    return (
        <>
            <div className='catalag-air-conditioners-page'>
                <h1>Каталог систем кондиционирования</h1>
                <p>Поставку систем кондиционирования Вы можете заказать, позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Проектирование, поставка систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Системы кондиционирования</h2>
                <div className='products-wrapper'>
                    <Link to='/split-systems'>
                        <div className='product'>
                            <p>Сплит-системы</p>
                            <div className='bg' style={{ backgroundImage: `url(${split_system})` }}></div>
                        </div>
                    </Link>
                    <Link to='/multi-split-systems'>
                        <div className='product'>
                            <p>Мульти сплит-системы</p>
                            <div className='bg' style={{ backgroundImage: `url(${multi_split_system})` }}></div>
                        </div>
                    </Link>
                    <Link to='/multizone-vrf-vrv-sytems'>
                        <div className='product'>
                            <p>Мультизональные VRF и VRV системы</p>
                            <div className='bg' style={{ backgroundImage: `url(${multizonalnye})` }}></div>
                        </div>
                    </Link>
                    <Link to='/chillers-fancoils'>
                        <div className='product'>
                            <p>Чиллеры и фанкойлы</p>
                            <div className='bg' style={{ backgroundImage: `url(${chillery1_small12_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/roof-air-conditioners'>
                        <div className='product'>
                            <p>Крышные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${rooftop_packaged_units1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/precision-air-conditioners'>
                        <div className='product'>
                            <p>Прецизионные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${pretsizionnye})` }}></div>
                        </div>
                    </Link>
                    <Link to='/central-air-conditioners'>
                        <div className='product'>
                            <p>Центральные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${tsentralnaya_sistema_ventilyatsii_small1_small})` }}></div>
                        </div>
                    </Link>
                </div>

                <h2>Бытовые и полупромышленные сплит-системы</h2>
                <div className='products-wrapper'>
                    <Link to=''>
                        <div className='product'>
                            <p>Настенные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${split})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Кассетные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${d4_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Канальные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${d6_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Напольно-потолочные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${q357028497_w640_h640_lsm_h_tha2_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Потолочные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${fha35a_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Напольные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${d5_small})` }}></div>
                        </div>
                    </Link>
                </div>
                <p>Поставку систем кондиционирования Вы можете заказать, позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Проектирование, поставка систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default CatalagAirConditioners