import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './consultations.css'

import xx52 from '../../../img/xx52ec984cc72302fd412e2aa145a6526c_xl1_small.jpg'

const Consultations = () => {
    return (
        <>
            <div className='consultations-page'>
                <h1>Консультации по инженерным системам, вентиляции и кондиционированию</h1>
                <p>Консультации по инженерным системам, вентиляции и кондиционированию Вы можете получить, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку инженерных систем по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>FIDCLIMAT оказывает консалтинговые услуги Заказчикам на различных этапах разработки рабочей документации проекта, в процессе подготовки технической документации, монтажных и пусконаладочных работ, при экспертизе технико-коммерческих предложений.</p>
                <img src={xx52} alt='' />
                <p>Предметной областью консалтинга являются инженерные системы, их индивидуальные особенности, структуры и функции. Объективная необходимость в системно-техническом консалтинге наступает при создании, реконструкции, техническом перевооружении производств, зданий и других объектов.</p>

                <h2>Консультации по системам вентиляции и кондиционирования</h2>
                <p>Приехав на объект, инженер очно проводит обследование помещения, что позволяет оптимально подобрать самое подходящее решение по вентиляции и (или) кондиционированию. Мы сами считаем очный выезд консультанта важным и рекомендуем его клиентам по следующим причинам:</p>
                <ul>
                    <li>Специалист имеет при себе оборудование для мониторинга качества воздуха, которое наглядно и объективно покажет, какие проблемы с воздухообменом присутствуют в помещении: уровень СО2, уровень влажности, эффективность работы вытяжки и т.д.</li>
                    <li>Очное обследование помещения инженером дает однозначные ответы на такие вопросы, как толщина и материал стен здания, особенности планировки помещения, работоспособность имеющейся вентиляции. Это поможет и подобрать наилучшее решение, и определить подходящее место для монтажа, и заранее учесть все нюансы, связанные с установкой и подключением оборудования.</li>
                    <li>В ситуациях, когда требуется комплексное решение — оснащение климатической техникой всей квартиры, дома, офиса и проч. — очный осмотр поможет сэкономить значительные средства как на составлении проекта вентиляции, так и на покупке и монтаже оборудования. Тот же самый алгоритм действует в нестандартных ситуациях (изолированная комната, панорамные окна, монтаж через балкон или лоджию и т.п.)</li>
                </ul>
                <p>В нашей компании работают специалисты с большим опытом в сфере вентиляции и кондиционирования, что позволяет им быстро сориентироваться на месте и не тратить время клиента. Но если вам неудобно или дискомфортно приглашать инженера к себе, мы можем составить достаточно полное представление об объекте и предложить оптимальное решение, задав вам несколько вопросов и изучив схему и (или) фотографии помещения.</p>

                <h2>Системно-технический консалтинг</h2>
                <ul>
                    <li>комплексное обследование технологического объекта;</li>
                    <li>проведение необходимых исследовательских, аналитических и информационно-поисковых работ;</li>
                    <li>оценку организационно-технической готовности компании;</li>
                    <li>разработку обоснованных предложений по удовлетворению нужд и потребностей Заказчика;</li>
                    <li>разработку вариантов концепции создания (реконструкции, техперевооружения).</li>
                    <li>Инженерное консультирование по климатическим системам и оборудованию;</li>
                    <li>Консультации по вопросам применяемых в отрасли передовых технологий, оборудования и материалов;</li>
                    <li>Оптимизация  финансовых и энергозатрат проекта;</li>
                    <li>Расчет и подбор  необходимого оборудования;</li>
                    <li>Оказание квалифицированных консультаций эксплу­атационным службам заказчика с целью организации качественной эксплуатации оборудования;</li>
                    <li>Экспертиза установленного оборудования с рекомендациями по эксплуатации и замене;</li>
                    <li>Экспертиза готовых проектов и подбор оборудования;</li>
                    <li>Комплектация объектов по готовым проектам;</li>
                    <li>Предоставление рекомендаций по сервисному обслу­живанию установленных систем оборудования.</li>
                </ul>
                <p>На заметку! Большинство неудачных решений в бизнесе вызвано тем, что важные стратегические решения принимаются либо в условиях недостатка информации, либо на основе ошибочных или устаревших данных.</p>
                <p>Консультации по инженерным системам, вентиляции и кондиционированию Вы можете получить, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку инженерных систем по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default Consultations