import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../form/Form'
import business2_small from '../../img/business2_small.jpg'
import kopirovat_small from '../../img/011_kopirovat_small.jpg'
import industry2_small from '../../img/industry2_small.jpg'
import prodaja_small from '../../img/prodaja-kvartira-ternopol-bam-zluki-prospekt__61113131f_small.jpg'
import home3_small from '../../img/home3_small.jpg'
import produktovyy_magazin_2_small from '../../img/produktovyy_magazin_2_small.jpg'
import u3e2df1e1a73c4f5e30e2139e2d1a6e3d_small from '../../img/u3e2df1e1a73c4f5e30e2139e2d1a6e3d_small.jpg'
import y730f30543e1_small from '../../img/y730f30543e1_small.jpg'
import sport_small from '../../img/sport_small.jpg'
import zhil_small from '../../img/zhil_small.jpg'
import shkola_small from '../../img/shkola_small.jpg'
import zim_small from '../../img/zim_small.jpg'
import gost_small from '../../img/gost_small.jpg'
import obsch_small from '../../img/obsch_small.jpg'

import sistemy_small1_small_small from '../../img/split-sistemy_small1_small_small.jpg'
import d4_small from '../../img/d4_small.jpg'
import d6_small from '../../img/d6_small.jpg'
import q357028497_w640_h640_lsm_h_tha2_small from '../../img/q357028497_w640_h640_lsm_h_tha2_small.jpg'
import fha35a_small from '../../img/fha35a_small.jpg'
import d5_small from '../../img/d5_small.jpg'
import e599_n4hl from '../../img/e599_n4hl-tl_small.jpg'
import multi_split from '../../img/multi-split-sistemy_small1_small.jpg'
import multizonalnye from '../../img/multizonalnye-vrv-i-vrf-sistemy-konditsionirovaniya_small1_small.jpg'
import chillery1_small12_small from '../../img/chillery1_small12_small.jpg'
import rooftop_packaged_units1_small from '../../img/rooftop_packaged_units1_small.jpg'
import pretsizionnye from '../../img/pretsizionnye-konditsionery_small_small.jpg'
import tsentralnye from '../../img/tsentralnye-konditsionery_small_small.jpg'
import i_small from '../../img/i_small.jpg'

import mitsubishi_heavy_industries_logo from '../../img/mitsubishi_heavy_industries_logo.svg_small.png'
import daikin_small1_small from '../../img/daikin_small1_small.png'
import fujitsu_small from '../../img/fujitsu_small.jpg'
import systemair_small from '../../img/systemair_small.png'
import ballu_small from '../../img/ballu_small.png'
import lessar_small from '../../img/lessar_small.png'
import hitachi_small from '../../img/hitachi_small.png'
import kentatsu_small from '../../img/kentatsu_small.png'
import dantex from '../../img/dantex-logo_small.png'
import gree_small from '../../img/gree_small.jpg'
import haier_small from '../../img/haier_small.jpg'

import './conditioning.css'

const Conditioning = () => {
    return (
        <>
            <div className='conditioning-component'>
                <h1>Кондиционирование</h1>
                <p>Кондиционеры Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href="mailto:fidclimat@mail.ru">fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <p>Мы гарантируем надежность, безотказность работы и удобство в эксплуатации установленного климатического оборудования.</p>
                <h2>Решения по типу объекта</h2>
                <div className='products-wrapper'>
                    <Link to='/office-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование офисов</p>
                            <div className='bg' style={{ backgroundImage: `url(${business2_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/production-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование производства</p>
                            <div className='bg' style={{ backgroundImage: `url(${kopirovat_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/warehouse-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование склада</p>
                            <div className='bg' style={{ backgroundImage: `url(${industry2_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/apartment-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование квартиры</p>
                            <div className='bg' style={{ backgroundImage: `url(${prodaja_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/cottage-home-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование коттеджа или дома</p>
                            <div className='bg' style={{ backgroundImage: `url(${home3_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/store-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование магазинов</p>
                            <div className='bg' style={{ backgroundImage: `url(${produktovyy_magazin_2_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/shopping-mall-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование торгового центра</p>
                            <div className='bg' style={{ backgroundImage: `url(${u3e2df1e1a73c4f5e30e2139e2d1a6e3d_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/air-conditioning-buildings'>
                        <div className='product'>
                            <p>Кондиционирование адм. зданий</p>
                            <div className='bg' style={{ backgroundImage: `url(${y730f30543e1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/gym-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование спортзала</p>
                            <div className='bg' style={{ backgroundImage: `url(${sport_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/air-conditioning-residential-buildings'>
                        <div className='product'>
                            <p>Кондиционирование жилых зданий</p>
                            <div className='bg' style={{ backgroundImage: `url(${zhil_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/school-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование школы</p>
                            <div className='bg' style={{ backgroundImage: `url(${shkola_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/winter-garden-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование зимнего сада</p>
                            <div className='bg' style={{ backgroundImage: `url(${zim_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/hotel-air-conditioning'>
                        <div className='product'>
                            <p>Кондиционирование гостиницы</p>
                            <div className='bg' style={{ backgroundImage: `url(${gost_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/air-conditioning-restaurants-cafes-bars'>
                        <div className='product'>
                            <p>Кондиционирование ресторанов, кафе, баров</p>
                            <div className='bg' style={{ backgroundImage: `url(${obsch_small})` }}></div>
                        </div>
                    </Link>
                </div>

                <h2>Услуги:</h2>
                <ul>
                    <Link to='/consultations'>
                        <li>Консультации</li>
                    </Link>
                    <Link to='/design-airconditioning-systems'>
                        <li>Проектирование систем кондиционирования</li>
                    </Link>
                    <Link to='/installation-air-conditioning-systems'>
                        <li>Монтаж систем кондиционирования</li>
                    </Link>
                    <Link to='/air-conditioner-maintenance'>
                        <li>Обслуживание кондиционеров</li>
                    </Link>
                    <Link to='/supply-climatic-equipment'>
                        <li>Поставка климатического оборудования</li>
                    </Link>
                    <Link to='/commissioning-air-conditioning-systems'>
                        <li>Пуско-наладка систем кондиционирования</li>
                    </Link>
                </ul>

                <h2>Каталог кондиционеров</h2>
                <div className='products-wrapper'>
                    <Link to='/'>
                        <div className='product'>
                            <p>Настенные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${sistemy_small1_small_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Кассетные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${d4_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Канальные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${d6_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Напольно-потолочные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${q357028497_w640_h640_lsm_h_tha2_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Потолочные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${fha35a_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Напольные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${d5_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/split-systems'>
                        <div className='product'>
                            <p>Сплит-системы</p>
                            <div className='bg' style={{ backgroundImage: `url(${e599_n4hl})` }}></div>
                        </div>
                    </Link>
                    <Link to='/multi-split-systems'>
                        <div className='product'>
                            <p>Мульти сплит-системы</p>
                            <div className='bg' style={{ backgroundImage: `url(${multi_split})` }}></div>
                        </div>
                    </Link>
                    <Link to='/multizone-vrf-vrv-sytems'>
                        <div className='product'>
                            <p>Мультизональные VRF и VRV системы</p>
                            <div className='bg' style={{ backgroundImage: `url(${multizonalnye})` }}></div>
                        </div>
                    </Link>
                    <Link to='/chillers-fancoils'>
                        <div className='product'>
                            <p>Чиллеры и фанкойлы</p>
                            <div className='bg' style={{ backgroundImage: `url(${chillery1_small12_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/roof-air-conditioners'>
                        <div className='product'>
                            <p>Крышные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${rooftop_packaged_units1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to='/precision-air-conditioners'>
                        <div className='product'>
                            <p>Прецизионные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${pretsizionnye})` }}></div>
                        </div>
                    </Link>
                    <Link to='/central-air-conditioners'>
                        <div className='product'>
                            <p>Центральные кондиционеры</p>
                            <div className='bg' style={{ backgroundImage: `url(${tsentralnye})` }}></div>
                        </div>
                    </Link>
                    <Link to='/'>
                        <div className='product'>
                            <p>Компрессорно-конденсаторный блок</p>
                            <div className='bg' style={{ backgroundImage: `url(${i_small})` }}></div>
                        </div>
                    </Link>
                </div>

                <h2>Производители</h2>
                <div className='products-wrapper'>
                    <Link to=''>
                        <div className='product'>
                            <p>Mitsubishi Heavy</p>
                            <div className='bg' style={{ backgroundImage: `url(${mitsubishi_heavy_industries_logo})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Daikin</p>
                            <div className='bg' style={{ backgroundImage: `url(${daikin_small1_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Fujitsu</p>
                            <div className='bg' style={{ backgroundImage: `url(${fujitsu_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Systemair</p>
                            <div className='bg' style={{ backgroundImage: `url(${systemair_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Ballu Machine</p>
                            <div className='bg' style={{ backgroundImage: `url(${ballu_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Lessar</p>
                            <div className='bg' style={{ backgroundImage: `url(${lessar_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Hitachi</p>
                            <div className='bg' style={{ backgroundImage: `url(${hitachi_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Kentatsu</p>
                            <div className='bg' style={{ backgroundImage: `url(${kentatsu_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Dantex</p>
                            <div className='bg' style={{ backgroundImage: `url(${dantex})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Gree</p>
                            <div className='bg' style={{ backgroundImage: `url(${gree_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Haier</p>
                            <div className='bg' style={{ backgroundImage: `url(${haier_small})` }}></div>
                        </div>
                    </Link>
                </div>
            </div>
            <Form />
        </>
    )
}

export default Conditioning