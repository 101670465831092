import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img1 from '../../../img/504321energosberegayushhie-sistemy-ventilyacii-razumnaya-ekonomiya-4.jpg'
import img2 from '../../../img/406280energosberegayushhie-sistemy-ventilyacii-razumnaya-ekonomiya-5.jpg'

const EnergyEfficientVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Энергоэффективная система вентиляции</h1>
                <p>Энергоэффективную вентиляцию Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Одной из главных составляющих для создания благоприятных условий для жизни и работы людей является эффективная вентиляция помещения. Первостепенной и очень важной задачей при проектировании зданий является обеспечение нормируемой работы систем вентиляции. Вентиляция является своего рода сложной схемой, в которую включены различные элементы, такие как устройства для нагнетания воздуха, шумоглушитель, воздуховод, решетки и др. При проектировании вентиляции традиционное предпочтение отдаётся наиболее простым из обеспечивающих заданные условия способам, при которых проектировщики стремятся уменьшить производительность систем, принимая целесообразные конструктивно-планировочные решения здания, внедряя технологические процессы с минимумом вредных выделений, устраивая укрытия мест образования вредных выделений.</p>
                <p>Практически во всех промышленных и бытовых зданиях есть система вентиляции, в жилых она в основном естественная, а в промышленных зачастую механическая. В холодный период года воздух необходимо подогревать, так как на нагрев воздуха тратится достаточно большое количество тепла, сравнимое с количество тепла на отопление помещения, то вопрос о энергосбережении вентиляции стоит очень остро. Приточная вентиляции часто может быть совмещена с системой кондиционирования, то есть смысл экономить и холод в летнее время. В помещения, где внутренний воздух не слишком загрязнен и если это допускают нормы проектирования можно использовать системы рекуперации тепла. Оборудования для рекуперации тепла в вентиляции существует довольно много, это пластинчатые и роторные теплообменники, теплообменники с промежуточным теплоносителем и многие другие. Наиболее эффективным является роторный рекуператор, наименее эффективным рекуператоры с промежуточным теплоносителем.</p>

                <h2>Вентиляция для комфорта</h2>
                <p>Показатели удобной вентиляционной системы:</p>
                <ul>
                    <li>Систематичный, гарантированный и необходимый воздухообмен в холодную погоду может осуществляться только при помощи контролируемой, удобной в управлении системы вентиляции;</li>
                    <li>Естественная вентиляция, происходит за счет инфильтрации воздуха сквозь щели в наружных конструкциях, то есть из-за не герметичности ограждающих конструкций здания, или проветривании помещений. Скорость ветра и температура наружного воздуха колеблются в широком диапазоне, также  меняется воздухообмен и при естественной вентиляции дома. Так как при слабом ветра воздухопроницаемость дома удовлетворяет необходимый воздухообмен, но при сильных ветрах воздухообмен становится чрезмерным, вызывая очень большие теплопотери. Такая вентиляция становится убыточной для жителей;</li>
                    <li>При отсутствии механической системы вентиляции нужный воздухообмен можно обеспечить только в условиях систематического «полного» проветривания, то есть при полном открытии окон (читайте о других способах проветривания с помощью приточного клапана в окне или в стене). Для достижения кратности воздухообмена 0,35 м3 / ч, нужно проветривать комнату на 5-10 минут через каждые 3:00 даже ночью. Естественно этого никто не делает. Соответственно, в доме ухудшается качество воздуха и увеличивается его влажность. А это, как вы уже знаете, способствует выпадению конденсата.</li>
                </ul>
                <p>При этом в отопительный период увеличивается энергопотребление.</p>

                <h2>Использование систем на базе рекуперации</h2>
                <p>Основной принцип, который применяется в вентсистемах с энергосбережением ‒ рекуперация тепла. Он основан на использовании вытяжных воздушных потоков. Они, отдавая тепло за счет специальных обменных агрегатов, изменяют внутрикомнатную температуру.</p>
                <p>Эффект от установок особо заметен в осенне-зимний период, когда воздух на улице гораздо холоднее, чем в помещениях. Рекуперация позволяет минимизировать теплопотери, одновременно уменьшая необходимость дополнительного обогрева комнат. Свежий воздух, подающийся внутрь, нагревается без каких-либо затрат. Потребление энергии (а с ним и расходы средств) зачастую уменьшается в разы.</p>
                <p>Для установок используют, как правило, несколько типов рекуперационного оборудования:</p>
                <ul>
                    <li>Организуется система каналов, стенки которых сделаны из алюминиевых пластин. За счет их теплопроводности, эффективно передают температуру приточным массам. У металла на поверхности есть особое покрытие, которое оптимизирует эксплуатационные характеристики. В системе предусмотрены байпас и два клапана, управляющиеся с одного двигателя.</li>
                    <li>Системы, основной элемент которых – ротор с регулируемой скоростью.</li>
                    <li>Системы с жидкостным теплоносителем (как правило, на этиленгликоле). Они располагаются в каналах (вытяжной, приточный).</li>
                    <li>Тепловые трубы ‒ передача происходит при изменении агрегатного состояния носителя.</li>
                </ul>
                <p>Энергосбережение в системах вентиляции и кондиционирования, организованное по данным схемам, распространено повсеместно. Различные бренды (шведские SYSTEMAIR и SWEGON, французские CARRIER, TRANE, «ВЕЗА») совершенствуют системы, и конструкции их постоянно обновляются.</p>
                <p>Выбор типа зависит от проекта, требований к воздуху и многих других условий. Подобное оборудование проявляет свои лучшие качества в комплексах с большими помещениями, высокой проходимостью ‒ это магазины, концертные залы и кинотеатры, спортивные и учебные заведения, рестораны, офисные центры. Также энергосберегающие системы вентиляции воздуха рекуперационного типа используются в индивидуальном строительстве домов.</p>
                <p>Распространенный стереотип о дороговизне установок не имеет под собой оснований ‒ они сильно варьируются по стоимости и быстро окупаются.</p>
                <img src={img1} alt='' className='img-width' />
                <p><strong>Преимущества вентиляции с рекуперацией</strong></p>
                <p>Одновременно с рекуперацией тепла, механическая вентиляция с рекуперацией тепла имеет ряд других преимуществ:</p>
                <ul>
                    <li>Устранение неприятных запахов.</li>
                    <li>Сохранение конструкций здания благодаря постоянной вентиляции, устранение плесени и т. п.</li>
                    <li>Непрерывный приток свежего отфильтрованного воздуха для обеспечения комфортного климата в помещениях, является большим преимуществом для людей, страдающих аллергией.</li>
                    <li>С подземным теплообменником (естественным кондиционером) вы экономите еще больше энергии, так как проходя через него зимой, воздух дополнительно нагревается, а летом — предварительно охлаждается.</li>
                    <li>Меньше шума внутри помещения (окна могут оставаться закрытыми), что способствует спокойному сну. Независимо от конструкции дома отпадает необходимость в централизованной системе отопления, поскольку контролируемая механическая система вентиляции с рекуперацией тепла способна обогревать весь дом.</li>
                </ul>

                <h2>Пассивный дом</h2>
                <p>По рекомендациям Института пассивного дома (Германия) соответствующая скорость обмена воздуха для жилых зданий должно быть 0,3 — 0,4 объема здания в час, с предпочтением более низкой скорости. Это гарантирует высокое качество воздуха в помещениях, обеспечивая комфортный уровень влажности и максимальную экономию энергии.</p>
                <p>Пакет планирования пассивных домов также рекомендует: для обеспечения высокого качества воздуха в жилых зданиях средняя скорость воздухообмена через вентиляцию должна составлять 30 м³ / ч на одного человека. Эти два показателя можно использовать для выбора размеров (производительности, мощности) оборудования приточно-вытяжной вентиляции с рекуперацией тепла в соответствии с различных конструкций пассивных домов.</p>
                <p>Например, при проектировании пассивного дома на семью из 5-ти человек нужно предусмотреть, чтобы в дом поставлялось 150 м³ свежего воздуха в чаc.</p>

                <h2>Рециркуляция и двигатели для «мертвых зон»</h2>
                <p>Еще два распространенных метода, с помощью которых организуют энергосберегающие системы вентиляции ‒ рециркуляция и особые электродвигатели, снижающие общие затраты.</p>
                <p>Потоки из помещений могут очищаться в установке от пыли, но не от продуктов дыхания или производства, болезнетворных бактерий и микробов. Это стоит помнить при выборе системы.</p>
                <p>Нужно соблюдать условия, разрешающие применение рециркуляции:</p>
                <ul>
                    <li>не менее десяти процентов должно приходиться на приточный воздух допустимой чистоты;</li>
                    <li>не более тридцати процентов от максимально допустимых концентраций вредных примесей может содержаться во вновь поступающих в помещения воздушных масс;</li>
                    <li>не допускается использование установок в пространствах, где есть вещества I-III классов опасности, возможно резкое повышение количества взрывоопасных газов или паров.</li>
                </ul>
                <img src={img2} alt='' className='img-width' />
                <p>В большинстве существующих электродвигателей энергосбережения в системах вентиляции, кондиционирования, устанавливаемых на вентиляторы, есть «мертвые зоны», в которых они не могут работать в заданном режиме ‒ нет нагрузки, низкое сопротивление в сетях.</p>
                <p>Другие возможности для организации энергоэффективной вентустановки В отдельных случаях применяются комбинированные или альтернативные методы сохранения энергии. Например, он нужны, если есть необходимость «спрятать» всю вентиляцию ‒ под подвесным потолком, в ограниченном пространстве.</p>
                <p>Энергоэффективную вентиляцию Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href="tel:+998903559091">+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default EnergyEfficientVentilation