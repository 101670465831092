import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img_1 from '../../../img/-1posledstviya-promerzaniya.jpg'
import img_11 from '../../../img/-1v-stalinkah-mozhno-uvidet-dve-ventilyatsionnyh-reshetki-v-pomeschenii-kuhni-odna-iz-nih-otkryvaetsya.jpg'
import img_27 from '../../../img/275239pritochnaja-1.jpg'
import img_10 from '../../../img/104952vitiaznaja.jpg'
import img_17 from '../../../img/-17e72922daa848272a7e3bc09077f1713.jpg'

const VentilationResidentialBuildings = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция жилых зданий</h1>
                <p>Вентиляцию жилого здания Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Назначение вентиляции – обеспечить санитарно-гигиенические условия для пребывания в помещении человека – температуру, относительную влажность, скорость движения воздуха (подвижность) и чистоту воздуха, для чего вентиляционные устройства должны ассимилировать или удалять избыточную теплоту, влагу, а также газы, пары, пыль с соблюдением при этом определенной подвижности воздуха в помещении. Системы вентиляции проектируются для обеспечения параметров воздуха в пределах рабочей зоны. Рабочей или обслуживаемой зоной помещения называют пространство высотой 2 м над уровнем пола или площадки, на которых находятся места постоянного или временного пребывания людей.</p>

                <h2>Влияние вентиляции помещений на человека</h2>
                <p>С позиций энергосбережения современными исследованиями доказано, что за счет вентиляционных систем в доме или же квартире – происходит до 30 процентов всех возможных здесь тепловых потерь.</p>
                <p>Установив в помещениях дома современные пластиковые окна, мы само собой существенно экономим здесь тепло, но тем же самым – мы также снижаем достаточно сильно и вентиляцию, то есть воздухообмен здесь. На человека такое усовершенствование наших жилищ, как правило, не ведет к улучшению условий его труда и отдыха, а скорее наоборот.</p>
                <p>Как показали современные специальные исследования, пониженный уровень вентилируемости в помещениях ведет к существенной нехватке здесь кислорода, а соответственно к быстрой нашей утомляемости при выполнении любой работы, нашим головным болям, а также плохому и не комфортному нашему отдыху и сну здесь.</p>
                <p>Также полностью доказано, что недостаточная вентиляция в помещении – всегда приводит к повышенной влажности в доме. В таком невентилируемом доме, как правило, окна и стены всегда «плачут», а особенно с наступлением холодов. На потолке и стенах комнат часто появляется плесень и грибок, их внешний вид явно портится и не приносит нам никакого удовлетворения.</p>
                <p>Кроме того, споры грибка со стен, распространяясь в воздухе дома – способны вызывать у человека различные, притом достаточно серьезные заболевания его органов дыхания. Мы сейчас превратили свои жилища в термос, где нам тепло, но совсем не комфортно находиться, да еще иногда и опасно для здоровья. Поэтому «правильная» вентиляция в доме – существенно повышает комфортность работы и проживания в нем.</p>

                <h2>Нормативные требования</h2>
                <p>Начнем с изучения действующих нормативных документов. Актуальные СНиП на вентиляцию жилых зданий — 2.04.05-91 «Отопление, вентиляция и кондиционирование» и 2.08.01-89 «Жилые здания».</p>
                <p>Для удобства читателя сведем ключевые требования документов воедино.</p>
                <p><strong>Температура</strong></p>
                <p>Для жилой комнаты она определяется температурой наиболее холодной пятидневки в году.</p>
                <ul>
                    <li>При ее значении выше -31С в комнатах необходимо поддерживать как минимум +18С.</li>
                    <li>При температуре самой холодной пятидневки ниже -31С требования несколько выше: в комнатах должно быть не менее +20С.</li>
                </ul>
                <p>Для угловых комнат, имеющих как минимум две общих стены с улицей, нормы на 2 градуса выше — +20 и +22С соответственно.</p>
                <img src={img_1} alt='' />
                <p>Последствия промерзания.</p>
                <p>Для санузлов минимальное значение температуры составляет +18С, для ванных и душевых — +24.</p>
                <p><strong>Нормы воздухообмена</strong></p>
                <p>Каковы нормы вентиляции жилых помещений (точнее, скорость воздухообмена в них)?</p>
                <p><strong>Дополнительные требования</strong></p>
                <p>Какие еще требования и рекомендации можно найти в СНиП на отопление и вентиляцию жилых зданий?</p>
                <ul>
                    <li>Схема вентиляции может предусматривать воздухообмен между отдельными помещениями. Проще говоря, можно организовать вытяжку в кухне, а приток воздуха — в спальне. Собственно, документ конкретизирует рекомендацию: вытяжную вентиляцию следует предусматривать в кухнях, санузлах, ванных, туалетах и сушильных шкафах.</li>
                </ul>
                <img src={img_11} alt='' className='img-width' />
                <p>В сталинках можно увидеть две вентиляционных решетки в помещении кухни. Одна из них открывается в дымоход: дома строились под печное отопление.</p>
                <ul>
                    <li>Вентиляция квартиры должна присоединяться к общему вентканалу не ниже чем в 2 метрах от уровня потолка. Инструкция призвана минимизировать вероятность опрокидывания тяги в ветреную погоду.</li>
                    <li>При использовании отдельных помещений в жилом доме для общественных нужд они снабжаются собственной системой вентиляции, не связанной с общедомовой.</li>
                    <li>При температуре самой холодной пятидневки ниже -40С для трехэтажных и более высоких зданий допускается оборудование приточной вентиляции системами подогрева.</li>
                    <li>Газовые котлы и колонки со сбросом продуктов сгорания в общую вентиляцию допускается устанавливать лишь в зданиях не выше пяти этажей. Твердотопливные котлы и водонагреватели и вовсе могут быть установлены лишь в одно- и двухэтажных зданиях.</li>
                    <li>Приточный воздух рекомендуется подавать в помещения с постоянным пребыванием людей. Что, собственно, опять-таки приводит нас к уже упомянутой схеме: приток воздуха через жилые комнаты и вытяжка через кухню и санузел.</li>
                </ul>

                <h2>Вентиляционные системы жилого дома</h2>
                <p>Представлена следующая классификация:</p>
                <ol>
                    <li>Естественная и искусственная</li>
                    <li>Приточная и вытяжная</li>
                    <li>Местная и общеобменная</li>
                    <li>Наборная и моноблочная</li>
                </ol>
                <p><strong>Естественная вентиляция</strong></p>
                <p>Характеризуется своей простотой. Не требует затрат денежных средств. Принцип работы следующий:</p>
                <p>Воздух поступает и выводится естественным путем через щели и другие легкодоступные места. Здесь действует физический закон, который гласит, что теплый воздух поднимается наверх и уходит в вентиляционный канал, а чистый поступает извне с улицы. Поэтому прямо зависит от внешних условий и погоды. Естественный воздухообмен может достигать 1 м³/час.</p>
                <p>Преимущества:</p>
                <ul>
                    <li>Дешёвая</li>
                    <li>Надёжная</li>
                    <li>Долговечная</li>
                </ul>
                <p>Проветривать жилое помещение необходимо около часа для того, чтобы поступил новый кислород. В зимнее время достаточно 15 минут, но холодной воздух опасен для здоровья. Есть риск заболеть.</p>
                <p>На заметку! Можно установить специальное устройство, так называемый клапан. Он запускает свежий воздух в жилое помещение.</p>
                <img src={img_27} alt='' className='img-width' />
                <p><strong>Приточная вентиляция</strong></p>
                <p>Главное свойство – принудительность. Воздух поступает через воздушный фильтр и очищается. Равномерно распределяется в помещении с помощью вентиляционных коробов. Следует устанавливать на балконах.</p>
                <p>Преимущество:</p>
                <ul>
                    <li>Автоматическое управление</li>
                    <li>Дополнительно помогает воздуху</li>
                    <li>Занимает мало места</li>
                    <li>Бесшумный корпус</li>
                    <li>Одновременная работа вытяжных вентиляторов</li>
                    <li>Эффективность</li>
                    <li>Предусмотрен пульт</li>
                </ul>
                <p>Приточная система позволяет нагревать воздух до необходимой температуры. Особенно в жаркое время чувствуется потребность в принудительном перемещении воздушных масс.</p>
                <img src={img_10} alt='' className='img-width' />
                <p><strong>Принудительная вытяжная вентиляция</strong></p>
                <p>Принцип работы состоит в том, что прогретый воздух удаляется через вентиляцию. При выборе нужно учитывать мощность и его шумность.</p>
                <p><strong>Приточно-вытяжная система вентиляции с рекуператором</strong></p>
                <p>Устройство забирает тепло из прогретых воздушных масс. Избавляет от влажности грибка и других проблем. Отличается своей экономичностью и технологичностью. Приточно-вытяжная система обеспечивает полноценную смену воздуха. Показатели воздухообмена варьируются 3-5 м³/час.</p>
                <p>Дополнительные преимущества:</p>
                <ul>
                    <li>Технология энергосбережения</li>
                    <li>Минимальный шум</li>
                    <li>Идеальное решение проблем вентиляции</li>
                </ul>
                <p><strong>Местная и общеобменная система вентиляции</strong></p>
                <p>Местная вентиляция подается на определенные места. Преимущественно используется на производстве. В жилом помещении — это кухонные вытяжки. Общеобменная вентиляция действует на всё помещение.</p>
                <h2>Проектирование вентиляции в жилом здании</h2>
                <p>Проектирование вытяжной вентиляции или любой другой – это в первую очередь грамотное расположение воздуховодов. Проект составляется на стадии проектирования самого дома и является неотъемлемой частью общего проекта. Поэтому вентиляционные каналы, особенно вытяжные, закладываются сразу на стадии сооружения дома.</p>
                <p>В первую очередь закладываются основные стояки в кухне, в ванной и туалете, котельной и других помещениях, оговоренных выше. Монтаж производят с подвала, то есть, труба вентиляции укладывается в фундамент дома и выводится в подвал с помощью отвода. То есть, ее устанавливают на стадии заливки бетонного раствора. Это, конечно, не строгое требование, потому что вариантов сооружения немало, просто это самый простой вариант.</p>
                <img src={img_17} alt='' className='img-width' />
                <p>В стадии проектирования необходимо учитывать объем воздухообмена, за счет чего подбираются сечения вентиляционных труб. Это важный момент, от которого зависит эффективность работы всей системы. Обязательно учитывается производительность вентиляторов и места их установки.</p>

                <h2>Расчет вентиляции</h2>
                <p>Рассмотрим расчет естественной вентиляции, как самой простой. Для этого надо обозначить два параметра: минимальное количество воздуха, поступающего снаружи (Qп) и минимальный объем для вывода из дома (Qв). Обе величины табличные из СП 54.13330.2011 первые в таблице №1, вторые в таблице №2.</p>
                <p>Обе они основаны на габаритах помещений дома. Поэтому вводные данные:</p>
                <ul>
                    <li>Площадь всех жилых комнат (их три) – 60 м².</li>
                    <li>Высота потолков – 3 м.</li>
                    <li>Пристроенная кладовка – 4,5 м².</li>
                    <li>В доме есть кухня, ванная и туалет, в которых воздухообмен соответственно: 90; 25; 25 м³/ч.</li>
                </ul>
                <p>В первую очередь определяется общий воздухообмен в комнатах, для чего надо перемножаются между собой воздухообмен жилых помещений, равный 30 м³/ч на количество комнат – 3. 60х3=180 м³/ч. Это значение приточного объема, который проходит через жилые помещения.</p>
                <p>Складываются значения воздухообменов всех подсобных помещений: 90+25+25=140 м³/ч.</p>
                <p>Находится частота смены воздуха в кладовке. Здесь используется кратность, равная 0,2. То есть, надо объем кладовки умножить на данный показатель: 4,5х3х0,2=2,7 м/ч.</p>
                <p>Теперь надо сложить два последних значения: 140+2,7=142,7 м³/ч. Это вытяжной объем воздуха. Далее надо сравнить вытяжной и приточный воздух: получается, что приточного больше. Его и берем за основу расчета.</p>
                <p>Теперь надо рассчитать сечение воздуховода. К примеру, если оно квадратное со сторонами 10 см или круглое диаметром 150 мм, то производительность такой трубы при естественной вентиляции составляет 30 м³/ч. Если в сооружении дома используются стояки этого сечения, то надо установить: 180/30=6 стояков. Чтобы уменьшить количество вытяжек, можно увеличить сечения с подбором по таблице производительности воздуховодов.</p>
                <p>Принципы расчета систем вентиляции другого типа основываются на тех же параметрах.</p>

                <h2>Монтаж</h2>
                <p>К монтажу вентиляции в жилом здании надо подходить с позиции – какая система была выбрана. Если это естественная модель, то главное – грамотно заложить стояки. С принудительной вентиляцией придется повозиться, особенно, если это разветвленная сеть. Самый простой вариант – установка стеновых вентиляторов, для чего просто в стенах делаются отверстия коронкой и перфоратором под диаметр трубы, куда и вставляется оборудование.</p>
                <p>С внешней стороны, то есть, с улицы, труба закрывается козырьком и решеткой. С внутренней устанавливается декоративная решетка. Здесь важно очень грамотно провести подключение вентилятора к питающей сети электрического тока. Для этого обычно проводят штробление стен, куда и закладывается питающий кабель от вентилятора до распаячной коробки. Правда, это делают на стадии ремонта или отделки. Если проводится установка в отремонтированном помещении, тогда рекомендуется проводку прокладывать в специальные пластиковые короба.</p>
                <p>Необходимо отметить, что приточные и вытяжные каналы могут оборудоваться вентиляторами, если в доме устраивается комплексная схема отвода воздуха. При этом, как и в случае расчета естественной вентиляции, определяется максимальный параметр из двух расчетных: вытяжка и приток. Именно на основе проделанных выкладок выбирается вентилятор, а точнее, его производительность.</p>
                <p>Несложно провести монтаж и моноблочного устройства. Главная задача – правильный выбор места установки. Как показывает практика, предпочтение отдается улице у стены дома. Хотя вариант в служебном помещении решает проблему замерзания оборудования. Удобен этот аппарат тем, что в него уже включены все необходимые приборы, которые отвечают не только за воздухообмен, но и за чистоту подаваемого воздушного потока.</p>
                <p>В общем, можно самому сделать вытяжную вентиляцию или приточную, если правильно провести предварительные расчеты. Нельзя на глаз выбирать оборудование и воздуховоды. Может случиться так, что их мощности и сечения будет недостаточным, чтобы справиться с объемом внутренних помещений.</p>

                <h2>Рекомендации специалистов</h2>
                <ol>
                    <li>Если нужна самая дешевая вентиляционная система без каких-либо сложных приборов, то выбирайте естественную. Правда, есть у нее пара недостатков: летом она практически не работает, зимой часто обмерзает.</li>
                    <li>Приточно-вытяжная схема – это большая длина воздуховодов часто со сложной разводкой вытяжного и приточного участков. Необходимо точно провести расчеты, чтобы выбрать вентиляторный блок. Сама сборка несложная, но монтажный процесс является трудоемким. Поэтому совет – не делайте монтаж своими руками, пусть этим занимаются специалисты.</li>
                    <li>Если решено организовывать вентиляцию дома своими руками, то лучше выбирайте стеновые канальные приборы или мини моноблочный шкаф.</li>
                </ol>
                <p>Вентиляцию жилого здания Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default VentilationResidentialBuildings