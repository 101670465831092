import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/288781288781-sistemy_otoplenia_torgovogo_centra.jpg'

const ShoppingMallHeating = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Система отопления торгового центра</h1>
                <p>Систему отопления торгового центра Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Владельцы торговых центров зачастую стараются учесть все факторы, влияющие на эффективность деятельности магазина. Одним из основополагающих факторов является комфорт посетителей. Именно с учетом данного фактора, подбирается дизайн интерьера, световое и звуковое оформление помещений, и, конечно же, отопление…</p>
                <p>Отопление торговых помещений необходимо для поддержания определенной температуры внутри здания не зависимо от времени года. Зайдя в магазин люди должны чувствовать себя свободно и непринужденно. В помещении не должно быть чрезмерно жарко или холодно, потому как это вызывает желание поскорей покинуть помещение. Вообще разница температур не должна быть сильно большой, конечно, если на улице не – 30 или + 40 градусов, потому как, в противном случае это вызовет дискомфорт.</p>
                <p>Подбирать тепловое оборудование не так просто, как может показаться на первый взгляд. Необходимо учитывать несколько важных факторов: размер помещения, необходимая мощность, природные условия (экстремальные холода или южные зимы) и так далее.</p>

                <h2>Виды отопления в ТЦ</h2>
                <p>Существует несколько видов отопительных систем:</p>
                <ul>
                    <li>традиционное водяное отопление;</li>
                    <li>системы воздушного отопления;</li>
                    <li>электрические отопительные приборы.</li>
                </ul>
                <p>Водяное отопление – это традиционный вид отопления помещений, в том числе и торговых. Преимущество заключается в том, что данный вид отопления можно применять практически в любом помещении, не зависимо от размера площади. По установленной системе трубопроводов протекает горячая вода к самим отопительным приборам. Наиболее распространенными приборами отопления являются конвекторы или радиаторы. Водяное отопление прекрасно подходит и для обогрева пола, что необходимо для детских игровых площадок и мест отдыха покупателей. Кроме того, такое отопление используют для обогрева ступеней на входе в здания, для предотвращения их обледенения. Единственная сложность в данном случае будет заключаться в прокладывании труб и подключении к центральной системе отопления района.</p>
                <p>Воздушное отопление выгодно использовать в крупных торговых центрах с большой площадью и высокими потолками. Иногда его используют как дополнение к водяным системам отопления, а иногда и самостоятельно. Принцип действия подобных систем отопления заключается в следующем: устанавливаются системы центрального кондиционирования, в которых происходит нагревание воздуха, который по специальным каналам воздуховодов направляется в торговое помещение. Направление теплого воздуха может регулироваться, например, его можно направить вверх, вниз или в определенное место. Преимуществом данного вида обогрева является тот факт, что можно сознательно регулировать температуру воздушного потока, а в теплое время года использовать его в качестве кондиционера.</p>
                <p>Последние годы можно часто наблюдать наличие воздушно-тепловых завес при входе в магазины и торговые центры. Данное изобретение является весьма популярным средством воздушного обогрева. Воздушно-тепловая завеса устанавливается на входе в магазин, и создает мощный теплый щит, тем самым, препятствуя проникновению холодного воздуха внутрь. Воздушно-тепловые завесы бывают двух типов: вертикальные и горизонтальные. Вертикальные, устанавливаются сбоку от дверных проемов и поток теплого воздуха подается по горизонтали. Горизонтальные системы устанавливают над дверными проемами и теплый воздух подается вертикально вниз по всей ширине проема. Популярность воздушно-тепловых завес постоянно растет, благодаря им можно тратить меньше средств на обогрев самих помещений, потому как они просто препятствуют проникновению холодного воздуха.</p>
                <p>Электрические отопительные приборы зачастую используют для отопления небольших помещений либо точечного отопления, к примеру, рабочего места продавцов. Существует большое количество электрических обогревательных приборов различной мощности и дизайна. Например, существуют масляные радиаторы, преимуществами которых является невысокая стоимость, функциональность, различные размеры и мощность, безопасность (возможность возгорания исключена) и довольно привлекательный дизайн. Существуют еще и тепловентиляторы, которые распыляют теплый воздух, тем самым, согревая помещение. Недостатками электрических обогревательных систем является большие затраты электроэнергии, которая сегодня стоит недешево и относительно небольшая площадь обогрева, хотя для кого-то может это наоборот преимущество.</p>
                <p>Разновидностью электрических обогревателей являются инфракрасные обогреватели. Данные обогреватели, в отличие от остальных электрических обогревателей могут использоваться для обогрева больших площадей. Кроме того, они позволяют повышать температуру в какой-либо определенной локальной зоне, и при этом сохранять нейтральной температуру остальной части помещения. Это очень удобно, когда в магазине используется спецодежда для продавцов. Потому как одежда чаще всего у них легкая и они мерзнут в помещениях, где посетителям в верхней одежде весьма комфортно. В противном же случае наоборот, посетители чувствуют себя некомфортно, там, где продавцам в легкой одежде довольно тепло. Еще одним достоинством инфракрасных обогревателей является меньшее потребление электроэнергии, по сравнению с обычными электрообогревателями.</p>

                <h2>Основные элементы системы отопления торговых центров</h2>
                <ol>
                    <li>Водогрейный котел. Котел - это прибор для нагрева теплоносителя. В зависимости от вида топлива он может быть: газовым, дизельным или твердотопливным. В последнее время также стали достаточно популярными котлы на отработанном масле. Данное оборудование наиболее успешно применяется при отоплении автоцентров и автомастерских.</li>
                    <li>Теплогенератор. Он является источником тепла для воздушной системы отопления. Принципы работы теплогенератора немногим отличается от водогрейного котла. Их принципиальное отличие заключается в принципе работы теплообменников данного оборудования. Если у котла, происходит процесс передачи тепловой энергии котловой воде, то у теплогенератора энергия передается воздуху.</li>
                    <li>Радиаторы и конвекторы. Данные приборы являются конечными точками водяной отопительной системы. Радиаторы как правило применяются в офисных помещениях торгового центра.</li>
                    <li>Воздуховоды. По воздуховодам происходит распределение теплого воздуха по торговым помещениям. Это особенно актуально для помещений большого объема, т.к. равномерно нагреть воздух в них при помощи радиаторов практически невозможно. Система воздуховодов очень схожа с системой вентиляции. Зачастую, к воздушному отоплению торгового центра, подключена система кондиционирования, и поэтому в летний период проблема, связанная с охлаждением воздуха отпадает сама собой.</li>
                    <li>Водяные калориферы. Эти приборы, считаются приборами комбинированного отопления, т.к. совмещают в себе обе системы отопления: воздушное и водяное. Принцип их работы заключается в следующем: теплоноситель, проходя через радиаторный элемент калорифера, нагревает его, а электрический вентилятор нагнетает горячий воздух, проходящий через него, в помещение.</li>
                </ol>
                <h2>Тепловентиляторы воздушного отопления торгово развлекательных центров</h2>
                <p>В настоящее время известно несколько систем воздушного отопления торгово-развлекательных комплексов и центров. К самому распространённому относится отопление помещения водяными тепловентиляторами или воздухонагревателями.</p>
                <p>Водяной тепловентилятор для развлекательных комплексов это изделие заводской готовности, представляющее собой отопительный прибор средних габаритов. Состоит из жёсткого корпуса, выполненного из стали или твёрдого пенополистирола (пенопласта). В корпусе тепловентилятора организованы:</p>
                <ul>
                    <li>теплообменник из нержавеющей стали,</li>
                    <li>вентиляторный блок,</li>
                    <li>система автоматики безопасности.</li>
                </ul>
                <p>Принцип работы основан на принудительной направленной подаче тёплого воздуха в помещение, нагреваемого за счёт горячей воды, питающей тепловентилятор через встроенный теплообменник. Температура воздуха, подаваемого за счёт вращения вентилятора регулируется отдельным термостатом и гидравлическим двухходовым или трёхходовым клапаном. Нагретый теплоноситель циркулирует по автономной трубопроводной системе при помощи гидравлического насоса. Монтаж воздухонагревателя осуществляется как правило на высоте в местах, позволяющим производить ежегодное сервисное техническое обслуживание, не доставляя при этом неудобств посетителям развлекательного центра и его персоналу.</p>
                <p>В зависимости от габаритов и объёма помещения теплотехник расчитывает потребности в тепле для искомого помещения. Подбирает нужное количество тепловентиляторов, а также их тепловую мощность, учитывая температуру подаваемого теплоносителя. Для бесперебойной стабильной работы воздухонагревателей с минимальными перепадами температуры особое внимание необходимо уделить гидравлическому расчёту с подбором циркуляционных насосов и запорнорегулирующей арматуры. Не забывайте, что от компетенции инженерно технического персонала. занятого в расчётах и выборе системы отопления для помещения развлекательного центра, ресторана или кафе, а также от квалификации монтажной организации зависит успех вашего бизнеса!</p>

                <h2>Перечень работ по организации систем отопления различных торговых площадок и центров или ее реконструкции</h2>
                <ul>
                    <li>выявление потребности при создании проекта с нуля, создание эскизной схемы теплоснабжения;</li>
                    <li>выбор способа и места монтажа трубопроводов;</li>
                    <li>подбор оборудования и материалов соответствующего качества;</li>
                    <li>тепловой и гидравлический расчет, определение технологии и проверка ее на требования СНиП;</li>
                    <li>возможность увеличения производительности, подключение дополнительного оборудования (если нужно);</li>
                    <li>расчет нагрузок и производительности системы в целом и по площади отапливаемых помещений;</li>
                    <li>при реконструкции объекта – подготовка площадок, фундамента и стен к последующему монтажу;</li>
                    <li>деффектовка участков системы отопления и оборудования;</li>
                    <li>расчет сроков и стоимости работ и оборудования, согласование смет;</li>
                    <li>поставка оборудования и исполнения работ точно в срок по заранее согласованной стоимости сметы.</li>
                </ul>
                <p>Иногда при проектировании системы отопления в торговых залах для достижения нормальной температуры воздуха достаточно сооружения системы водяных теплых полов, и установка дополнительных радиаторов не требуется.</p>
                <img src={img1} alt='' className='img-width' />

                <h2>Исходные параметры при разработке проектной документации</h2>
                <p>Чтобы система полностью соответствовала потребностям объекта, необходимо учитывать следующие параметры:</p>
                <ul>
                    <li>Размеры и геометрия торгового центра: общая площадь и объем, габариты отдельных помещений и торговых зон, способ зонирования (материал и высота перегородок) и прочие параметры. С учетом этих параметров подбирается технология отопления, точки размещения элементов системы, прокладываются теплотрассы и воздуховоды.</li>
                    <li>Планируемое количество персонала и посетителей. Расчет мощностей отопительной сети производится на основе нормативов, установленных на одного сотрудника либо посетителя.</li>
                    <li>Требования по энергосбережению. Для недопущения утечки тепла применяются различные технологии: тепловые завесы, направленные потоки воздуха и т.п. Также осуществляется теплоизоляция теплотрасс и воздуховодов, внедряются технологии рекуперации энергии.</li>
                    <li>Безопасность. Имеется достаточно обширный перечень противопожарных и гигиенических норм для отопления общественных зданий, которые необходимо учитывать при разработке проектной документации.</li>
                    <li>Особые зоны со специальными требованиями. Современные ТРЦ зачастую оборудуют развлекательными зонами вроде ледовой арены для катания на коньках, кинотеатра или даже аквапарка. Для всех этих объектов требуются особые режимы отопления. Также специфические требования имеются для складов, рабочих цехов и прочих технических, подсобных зон торгового центра, не предназначенных для посетителей.</li>
                </ul>

                <h2>Проектирование и монтаж котельных и систем отопления для розничной торговли и обслуживания</h2>
                <p>При строительстве или реконструкции объектов торговли и обслуживания согласно СНиПам и стандартам производится проектирование и гидравлический расчет систем отопления и горячего водоснабжения. Определяется вид топлива и тип собственной котельной, при возможности рассматриваются варианты подключения к централизованным сетям. В случае нахождения объекта внутри здания, система отопления и ГВС присоединяется через теплопункт к соответствующим системам здания.</p>
                <p>При проектировании внутренней системы отопления в продуктовых магазинах следует учитывать нормы к температуре воздуха в торговом зале, местах продажи скоропортящихся продуктов и т. п. Также свои микроклиматические нормы устанавливаются для различных предприятий из сферы обслуживания. Различные по техническому назначению зоны зданий должны иметь собственные сети отопления с приборами учета воды и тепла.</p>
                <p>Для отопления крупных торговых и развлекательных центров, залов с высокими потолками наряду с водяным используется воздушное отопление. Воздух подогревается теплоносителем и направляется по воздуховодам в складские помещения, холлы торговых центров и другие объекты. При наличии витринного остекления используются конвекционные системы его обогрева. На входных группах, где проходит большой поток людей, устанавливают тепловые завесы.</p>
                <p>Систему отопления торгового центра Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ShoppingMallHeating