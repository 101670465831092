import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import Navbar from './components/navbar/Navbar'
import Layout from './components/layout/Layout'
import Footer from './components/footer/Footer'

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Layout />
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App