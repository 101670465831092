import React from 'react'
import '../avtomationPages.css'
import Form from '../../../components/form/Form'
import { Link } from 'react-router-dom'

import img1 from '../../../img/28-05-2014 21-57-23.jpg'
import img2 from '../../../img/28-05-2014 22-00-49.jpg'
import img3 from '../../../img/28-05-2014 21-54-36.jpg'

const ProtectiveFunctionsRequirements = () => {
    return (
        <>
            <div className='avtomation-pages'>
                <h1>Требования к системам автоматики вентиляции и кондиционирования</h1>
                <p>Общие требования для всех систем автоматизации, независимо от объекта управления, определяются рядом общегосударственных, нормативных документов. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p><strong>К защитным функциям относятся:</strong></p>
                <ul>
                    <li>защита водяного калорифера от замораживания;</li>
                    <li>защита при выходе из строя вентиляторов или привода вентилятора;</li>
                    <li>защита при повышении перепада давления на фильтрах (засорение фильтров);</li>
                    <li>защита холодильной машины при отклонении от допустимых значений питающего напряжения, давлений, температур, токов;</li>
                    <li>защита электрокалорифера от перегрева и сгорания.</li>
                </ul>
                <img src={img1} alt='' />
                <img src={img2} alt='' />

                <h2>Общие требования, предъявляемые к системам автоматизации</h2>
                <p>Требования к системам автоматизации условно можно разделить на три группы:</p>
                <ul>
                    <li>общие требования для всех систем автоматизации;</li>
                    <li>требования, учитывающие специфику СКВ;</li>
                    <li>требования к системам автоматизации, определяемые конкретной СКВ.</li>
                </ul>
                <p>Общие требования для всех систем автоматизации, независимо от объекта управления, определяются рядом общегосударственных, нормативных документов. Главным из них являются: ДСТУ БА 2.4.-3-95 (ГОСТ 21.4.08-93), СНиП 3.05.07.85 «Системы автоматизации», «Правила устройства электроустановок (ПУЭ)» и ДНАОП 0.00-1.32-01. В ДСТУ БА 2.4.-3-95 (ГОСТ 21.4.0893) изложены нормы и правила выполнения рабочей документации автоматизации технологических процессов. Сборник норм и правил СНиП 3.05.07- 85 определяет порядок и правила выполнения всех работ, связанных с производством, монтажом и наладкой систем автоматизации технологических процессов и инженерного оборудования.</p>
                <p>В ПУЭ даны определения и общие указания по устройству электроустановок, выбору проводников и электрических аппаратов по способу их защиты. В ДНАОП 0.00-1.32-01 приведены правила устройств электрооборудования специальных установок, в т. ч. в разделах 2 и 3 — электрооборудования жилых, общественных, административных, спортивных и культурно-зрелищных зданий и сооружений, т. е. объектов, где установка СКВ обязательна. К отдельным положениям этих документов мы будем обращаться в разделах, посвященных технической документации, монтажу и наладке систем автоматики.</p>

                <h2>Требования, учитывающие специфику СКВ</h2>
                <p>Эти требования в общем виде представлены в разделе 9. СНиП 2.04.05- 91У «Отопление, вентиляция и кондиционирование» и регламентируют объем обязательных функций систем автоматизации: измерения, регулирования, сигнализации, автоматических блокировок и защиты технологического оборудования и т. п.</p>
                <p>Автоматическое регулирование параметров обязательно для воздушного отопления, приточной и вытяжной вентиляции, работающей с переменным расходом, переменной смесью наружного и рециркуляционного воздуха и тепловой мощности калориферов 50 кВти более, а также кондиционирования, холодоснабжения и местного доувлажнения воздуха в помещениях.</p>
                <p><strong>Основные контролируемые параметры СКВ:</strong></p>
                <ul>
                    <li>температура воздуха и теплоносителя (холодоносителя) на входе и на выходе устройств;</li>
                    <li>температура наружного воздуха и в контрольных точках помещения;</li>
                    <li>давление тепло- и холодоносителя до и после устройств, где давление изменяет свое значение;</li>
                    <li>расход теплоты, потребляемой системы отопления и вентиляции;</li>
                    <li>давление (разность давлений) воздуха в СКВ с фильтрами и теплоутилизаторами  по требованию технических условий на оборудование или по условию эксплуатации.</li>
                </ul>
                <p>Необходимость дистанционного контроля и регистрации основных параметров определяется технологическими требованиями.</p>
                <p>Датчики следует размещать в характерных точках в обслуживаемой (рабочей) зоне помещения, в местах, где они не подвергаются влиянию нагретых или охлажденных поверхностей или струй приточного воздуха. Допускается установка датчиков в воздуховодах, если параметры в них не отличаются от параметров воздуха в помещении или отличаются на постоянную величину. Если отсутствуют специальные технологические требования к точности, то точность поддержания в точках установки датчиков должна быть ±1 °С по температуре и ±7 % по относительной влажности. В случае применения местных кондиционеров-доводчиков с индивидуальными регуляторами прямого действия точность поддержания температуры ±2 °С.</p>
                <p><strong>Автоматическое блокирование предусматривается в:</strong></p>
                <ul>
                    <li>системах с переменным расходом наружного и приточного воздуха дляобеспечения минимально допустимой подачи воздуха;</li>
                    <li>теплообменниках первого подогрева и рекуператорах для предотвращения их замораживания;</li>
                    <li>контурах воздухообмена, циркуляции теплоносителя и хладагента для защиты теплообменников, ТЭН, компрессоров и др.;</li>
                    <li>системах противопожарной защиты и отключения оборудования в аварийных ситуациях.</li>
                </ul>
                <p>Причиной возможного замерзания воды в трубах является ламинарное движение воды при отрицательной температуре наружного воздуха и переохлаждении воды в аппарате. При диаметре трубки теплообменника dтр = 2,2 см и скорости воды меньшей 0,1 м/с скорость воды у стенки практически равна нулю. Вследствие малого термического сопротивления трубки температура воды у стенки приближается к температуре наружного воздуха. Особенно подвержена замерзанию вода в первом ряду трубок со стороны потока наружного воздуха.</p>
                <p><strong>Выделим три основных фактора, способствующих замерзанию воды:</strong></p>
                <ul>
                    <li>ошибки, допущенные при проектировании и связанные с завышенной поверхностью нагрева, обвязкой по теплоносителю и способом управления;</li>
                    <li>превышение температуры горячей воды и, как следствие, резкое снижение скорости движения воды, из-за чего создается опасность замерзания воды в теплообменнике;</li>
                    <li>перетекание холодного воздуха из-за негерметичности клапана наружного воздуха и при полном закрытии плунжера водяного клапана.</li>
                </ul>
                <p>Обычно защита от замерзания теплообменников выполняется на базе двухпозиционных регуляторов с датчиками температуры перед аппаратом и в обратном трубопроводе воды. Опасность замораживания прогнозируют по температуре воздуха перед аппаратом (tн  3 °С) и одновременным понижении температуры обратной воды, например, twmin 15 °С. При достижении указанных значений полностью открывают клапаны и останавливают приточный вентилятор. В нерабочее время клапан остается приоткрытым (5-25 %) при закрытой заслонке наружного воздуха. Приведенные выше регламентированные функции автоматики СКВ не исчерпывают всех особенностей процесса и оборудования воздухообработки.</p>
                <p>Практика наладки и эксплуатации таких систем показала необходимость выполнения еще целого ряда требований. Здесь следует, прежде всего, остановиться на обязательном прогреве воздухонагревателя первого прогрева перед пуском двигателя приточного вентилятора и соблюдении последовательности включения и останова рабочего оборудования системы. На рис. 1.13 показан типовой график включения и выключения аппаратов и устройств приточновытяжной системы. Первым полностью открывается клапан калорифера, после его прогрева в течение 120 с подается команда на открытие воздушных заслонок, еще через 40 с включается вытяжной вентилятор и только при полностью открытых заслонках — приточный вентилятор. Кроме того, должен быть предусмотрен индивидуальный пуск оборудования, которое необходимо включать при наладке и профилактических работах.</p>
                <img src={img3} alt='' />

                <h2>Требования, определяемые конкретными объектами</h2>
                <p>Эти требования формулируются на основе алгоритмов функционирования и управления СКВ. При этом выбор алгоритма управления определяется двумя основными качествами: точностью и экономичностью управления. Первое качество определяет выбор оптимального закона управления, второe — оптимальной программы управления. Другие показатели, такие как надежность,стоимость и т. д. накладываются как ограничения на выбранный критерий оптимальности первых двух факторов. И если определение оптимального закона управления производится специалистом по автоматизации, то определение оптимальной программы управления должно вестись совместно специалистами по кондиционированию и вентиляции и специалистами по автоматизации. При таком подходе учитываются как требования к системе автоматизации, так и к автоматизируемому объекту. На практике более распространено раздельное проектирование с выдачей технического задания или исходных данных на автоматизацию.</p>
                <p>В этих документах обычно оговаривается:</p>
                <ul>
                    <li>диапазон изменения возмущающих воздействий;</li>
                    <li>заданные параметры состояния воздуха и требования к точности их поддержания;</li>
                    <li>требования к поддержанию параметров воздуха в обслуживаемых помещениях в нерабочее время;</li>
                    <li>функциональная схема объекта с техническими характеристиками выбранных аппаратов и устройств тепловлажностной обработки воздуха;</li>
                    <li>данные о расчетных максимальных и минимальных тепловлажностных нагрузках объекта, режимах тепловлагообработки воздуха и условия перехода от одного режима к другому;</li>
                    <li>графики или диапазоны изменения нагрузок на протяжении суток, рабочей недели, месяца и т.п.</li>
                </ul>
                <p>Эти данные необходимы для реализации программного управления СКВ в указанные периоды с целью экономии электроэнергии, затрат тепла и холода. На основании описанных требований и исходных данных производится выбор технических средств автоматики и разрабатывается техническая документация на систему автоматизации.</p>
                <p>Общие требования для всех систем автоматизации, независимо от объекта управления, определяются рядом общегосударственных, нормативных документов. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ProtectiveFunctionsRequirements