import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img412 from '../../../img/412_small.jpg'
import img20 from '../../../img/20-05-2014 17-37-49.jpg'
import img36 from '../../../img/20-05-2014 21-47-36.jpg'
import img52 from '../../../img/20-05-2014 17-36-52.jpg'
import img29 from '../../../img/20-05-2014 17-38-29.jpg'
import img56 from '../../../img/25-05-2014 20-37-56.jpg'
import img15 from '../../../img/20-05-2014 21-43-15.jpg'

const VentilationHouseCottage = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция частного дома или загородного коттеджа</h1>
                <p>Вентиляцию дома или коттеджа Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции для дома по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <img src={img412} alt='' />
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <h2>Общие требования к системам вентиляции</h2>
                <ul>
                    <li>Вытяжной воздух выбрасываем наружу выше кровли. При естественной вытяжной вентиляции, все каналы выводят выше кровли. При механической вытяжной вентиляции – воздуховод так же выводят выше кровли либо внутри здания, либо снаружи.</li>
                    <li>Забор свежего воздуха при механической системе приточной вентиляции осуществляется с помощью заборной решетки. Ее необходимо размещать минимум на два метра выше уровня земли.</li>
                    <li>Движение воздуха необходимо организовывать таким образом, чтобы воздух из жилых помещений двигался в направлении помещений с выделением вредностей (санузел, ванная, кухня)</li>
                </ul>
                <p>Для размещения любой вентиляции, в любом помещении, следует обратиться к специалистам. От правильного инженерного решения, зависит комфортный микроклимат помещений.</p>

                <h2>Пять основных заблуждений относительно вентиляции</h2>
                <ol>
                    <li>В загородном доме вентиляция не нужна. Можно просто открывать окна, двери и форточки.</li>
                    <li>Вентиляция занимает полезное пространство дома, шумит и неэстетично выглядит. </li>
                    <li>Деревянные дома «дышат самостоятельно» и не нуждаются в оснащении дополнительным вентиляционном  оборудовании. </li>
                    <li>Чем больше помещение, тем больше в него надо подавать воздуха. </li>
                    <li>Вентиляция - это то, на чём можно сэкономить.</li>
                </ol>
                <p><strong>На самом деле:</strong></p>
                <img src={img20} alt='' className='img-width' />
                <p>1. Грамотно устроенная вентиляция необходима в любом доме (коттедже, квартире). Регулярный воздухообмен в помещении решает такие задачи:</p>
                <ul>
                    <li>Снижает концентрацию углекислого газа, выдыхаемого человеком;</li>
                    <li>Удаляет избыточную влажность.</li>
                    <li>Устраняет:</li>
                    <ul>
                        <li><strong>кухонные запахи;</strong></li>
                        <li><strong>табачный дым;</strong></li>
                        <li><strong>стойкие запахи загрязнения;</strong></li>
                        <li><strong>вредные выделения современных стройматериалов и бытовой химии.</strong></li>
                    </ul>
                </ul>
                <p>Проветривание при помощи  окон и форточек может решить проблему  лишь отчасти, и то - летом. Причем, дело это довольно хлопотное и некомфортное: сквозняки  вызывают простудные заболевания; с улицы в дом попадает гарь, машинные выхлопы, а также пыль и пыльцы – сильнейшие аллергены. Не доставляют удовольствия и проникающие извне шумы, а в летнее время – насекомые. А зимой такое проветривание превращается в существенный удар по бюджету, т.к. 50% тепла уходит на улицу, что сводит "на нет" всякую энергоэффективность.</p>
                <p>2. Использование системы гибких воздуховодов делает системы воздухораспределения невидимыми: всё оборудование располагается внутри стен, а снаружи - только вентиляционные решётки, которые, как раз, могут стать эффектным элементом декора. Что же касается шума, то современные установки бесшумны.</p>
                <p>3. Миф про «дышащие дома»  прочно прижился на просторах интернета, однако, с потребностью человека в свежем здоровом воздухе, он ничего общего не имеет. Те, кто ввели это понятие в обиход, имели в виду тот факт, что древесные строительные материалы не накапливают внутри влагу. Что же касается человека, то его потребности в свежем воздухе составляют от 25 м³/ час. Причем, в зависимости от вида деятельности, (например, при активном занятии спортом) и количества людей, находящихся в помещении,  приток свежего воздуха должен быть значительно больше. А такой приток свежего воздуха ни один "дышащий дом" не обеспечит.</p>
                <p>4. На самом деле наблюдается обратная зависимость. Чем меньше помещение, тем оно более требовательно к вентиляции, поскольку объём воздуха небольшой, и он быстро расходуется. Поэтому небольшие по площади помещения с низкими потолками нуждаются в дополнительном притоке свежего воздуха (естественная вентиляция, когда воздухообмен осуществляется за счет разницы температур, атмосферного давления снаружи и внутри квартиры, определенной силы и направления ветра, - крайне неэффективна!)</p>
                <p>5. Мы дышим каждый день 24 часа в сутки, и от того, каким будет воздух, попадающий в наш организм, напрямую зависит наше самочувствие. А экономить на своем здоровье крайне невыгодно!</p>

                <h2>Принцип работы естественной вентиляции</h2>
                <p>Естественная вентиляция – это проветривание помещения путем открывания окон и форточек, когда воздухообмен осуществляется за счет разницы температур, атмосферного давления снаружи и внутри квартиры, определенной силы и направления ветра. Такой способ  крайне неэффективен! В этом варианте вентиляция жилых зданий и во многом зависит от погодных условий.</p>
                <img src={img36} alt='' className='img-width' />
                <p>Отметим, что при использовании современных конструкций рам, которые обеспечивают высокую плотность закрытия окон, возникает дисбаланс между количеством вытяжного и приточного воздуха в помещении. Это приводит к снижению концентрации кислорода, повышению влажности и, как следствие, в помещении становится душно,  а в зимний период отпотевают окна. Кроме того, в доме накапливаются:</p>
                <ul>
                    <li>неприятные запахи,</li>
                    <li>табачный дым,</li>
                    <li>избыток СО2,</li>
                    <li>испарения от средств бытовой химии,</li>
                    <li>влажность.</li>
                </ul>
                <p>А в квартиру через раскрытое окно попадают пыль и грязь. Все это плохо сказывается на здоровье.</p>
                <p>Похожая ситуация наблюдается в загородных домах и коттеджах, где проектом не была предусмотрена грамотная вентиляция воздуха. Использование пластиковых окон, которые не пропускают свежий воздух в помещение, приводит к некомфортным условиям, духоте и сырости. Особенно это заметно в бильярдной, гараже, спортзале и каминной комнате, где пропадает тяга.</p>
                <p><strong>ГРАМОТНОЕ РЕШЕНИЕ!</strong></p>
                <p>Поскольку естественное воздухораспределение, осуществляемое посредством открывания форточек, окон и дверей, не решает проблемы, а только добавляет их в виде попадания в дом аллергенной пыльцы от цветущих растений, копоти, грязи; проникновению снаружи нежелательных звуков, охлаждению воздуха и сквознякам, то рассмотрим более эффективные способы вентиляции.</p>

                <h2>Механическая вентиляция</h2>
                <p>При механической вентиляции воздухообмен происходит за счет разности давления, создаваемой вентилятором или эжектором. Этот способ вентиляции более эффективен, так как воздух предварительно может быть очищен от пыли и доведен до требуемой температуры и влажности. В механических системах вентиляции используются такие приборы и оборудование, как: вентиляторы, электродвигатели, воздухонагреватели, шумоглушители, пылеуловители, автоматика и др., позволяющие перемещать воздух в больших пространствах. Такие системы могут подавать и удалять воздух из локальных зон помещения в необходимом количестве, независимо от изменяющихся условий окружающей воздушной среды. При необходимости воздух подвергают различным видам обработки (очистке, нагреванию, увлажнению и т.д.), что практически невозможно в системах естественной вентиляции.</p>
                <img src={img52} alt='' className='img-width' />
                <h2><strong>Типы систем вентиляции по назначению</strong></h2>
                <ul>
                    <li><strong>Вытяжная система</strong></li>
                </ul>
                <p>Служит для удаления из помещения отработанного воздуха. Самое простое и наименее затратное решение для вентиляции  загородного дома, коттеджа, квартиры. Вытяжную систему просто необходимо монтировать в таких помещениях, как кухня и санитарно-гигиенические узлы (туалетная и ванная комнаты). </p>
                <ul>
                    <li><strong>Приточная система</strong></li>
                </ul>
                <p>Система, подающая в помещение определенное количество воздуха, который может подогреваться в зимний период и охлаждаться в летний. Приточная вентиляция – более серьезное решение для обеспечения комфортного воздухообмена.</p>
                <ul>
                    <li><strong>Приточно-вытяжная система</strong></li>
                </ul>
                <p>Объединила плюсы двух систем вентилирования, став самым грамотным решением! Приточно-вытяжная система осуществляет как приток свежего воздуха, так и удаление уже отработанного. Помимо этого, она  позволяет подогревать подаваемый воздух или охлаждать его, используя специальные функции рекуперации тепла и систем центрального кондиционирования.</p>
                <p>Довольно часто в загородных домах  и коттеджах используется автономная система водяного обогрева, в этом случае, для подогрева приточного воздуха зимой можно использовать  тен (водяной калорифер). Его применение в конкретных случаях бывает экономически выгоднее, в отличие от применения электрического калорифера, требующего дополнительных энергетических затрат.</p>

                <h2>Плюсы приточно-вытяжной системы</h2>
                <ul>
                    <li>Постоянный воздухообмен, независящий от капризов погоды.</li>
                    <li>Экономия электроэнергии до 80%</li>
                    <li>Повышенный комфорт: летом – охлаждение, зимой – обогрев, плюс очищение воздуха.</li>
                    <li>Минимальная вероятность поломки оборудования</li>
                    <li>Простота монтажа, легкость и удобство в эксплуатации. Любая приточная вентиляция помещения (квартиры, коттеджа) комплектуется системой автоматики, которая управляет работой вентилятора, калорифера или, иначе, устройства для подогрева уличного воздуха зимой, а также информирует о степени загрязненности фильтрующих элементов.</li>
                </ul>
                <img src={img29} alt='' className='img-width' />
                <p><strong>Какую систему выбрать, зависит от каждого конкретного объекта, и ваших личных предпочтений.</strong></p>
                <p><strong>Однако всегда нудно учитывать:</strong></p>
                <ul>
                    <li>Мнение грамотного консультанта.</li>
                    <li>Особенности помещения, его профессиональную оценку, выполненную специалистом.</li>
                    <li>Точность в подборе оборудования</li>
                    <li>Качественность монтажа.</li>
                </ul>

                <h2>Основное оборудование, используемое для вентиляции в жилых помещениях</h2>
                <img src={img56} alt='' className='img-width' />
                <ul>
                    <li>Бытовые вытяжные вентиляторы + Канальные кондиционеры (комбинированный способ, который заключается в том, что бы объединить системы вентиляции и кондиционирования)</li>
                    <li>Наборные приточно-вытяжные установки (требуется наличие вентиляционной камеры)</li>
                    <li>Компактные приточно-вытяжные установки (это системы, которые полностью выполняют функцию вентиляции дома. При использовании их в системе вентиляции осуществляется приток, удаление воздуха, частичная фильтрация воздуха, при минимальном количестве используемой электроэнергии, за счет внедренных энергосберегающих технологий).</li>
                </ul>

                <h2><strong>Пример организации воздухообмена в частном доме</strong></h2>
                <p>На чердаке дома монтируется приточная установка ВУТ Г или ВУТ ЭГ. На момент отделочных работ в доме, скрытым способом (на чердаке, в стенах, за подвесными потолками) монтируются приточные и вытяжные магистральные воздуховоды. В помещениях с пребыванием людей устанавливаются воздухораспределительные устройства. Свежий воздух забирается с улицы через наружную решетку, в приточной установке воздух фильтруется, за счет тепла вытяжного воздуха подогревается и по разветвленной системе воздуховодов поступает в помещения. Загрязненный воздух по вытяжным воздуховодам поступает в рекуператор, отдает тепло приточному воздуху и выбрасывается на улицу через дефлектор, установленный на крыше дома. Таким образом, доме наблюдается постоянное присутствие свежего воздуха, происходит контролируемый воздухообмен, отсутствие сквозняков при открытии окон, отсутствие проникновения извне пыли и постороннего шума.</p>
                <img src={img15} alt='' />

                <h2>Что нужно знать для подбора системы вентиляции?</h2>
                <p>Как показывает практика и производимые расчеты, естественная вентиляция не справится с поставленными задачами — обеспечением внутреннего пространства дома здоровым  чистым и свежим воздухом - слишком большое количество воздушных масс требуется сначала подавать, а после выводить из помещения. Поэтому искусственная вентиляция — лучший выход для создания комфортного микроклимата.</p>
                <p><strong>Важные мелочи…</strong></p>
                <p>Что же важно знать, чтобы оптимально грамотно создать систему вентиляции?</p>
                <p>Для  проектирования конфигурации механической вентиляции важно получить обдуманные ответы на следующие  вопросы:</p>
                <ul>
                    <li>Количество людей, пребывающих в помещении.</li>
                    <li>План помещения. Необходимо составить подробную схему расположения комнат с указанием их назначения, в особенности, если возможна перепланировка.</li>
                    <li>Толщина и материал стен.</li>
                    <li>Особенности остекления.</li>
                    <li>Тип и высота потолков. Размер межпотолочного пространства при подвесных, подшивных, натяжных системах. Возможность монтажа фальшбалок.</li>
                    <li>Расположение мебели и тепловыделяющей бытовой техники.</li>
                    <li>Мощность и расположение осветительных и отопительных приборов.</li>
                    <li>Наличие, тип и состояние вентиляционных шахт.</li>
                    <li>Особенности и производительность инфильтрации, естественной вентиляции.</li>
                    <li>Наличие местной вытяжной вентиляции — шкаф, зонт.</li>
                    <li>Желаемая конфигурация приточной системы — наборная или моноблок.</li>
                    <li>Необходимость применения шумоизоляции.</li>
                    <li>Нужна или нет подготовка приточного воздуха.</li>
                    <li>Тип распределителей — регулируемые или нерегулируемые решётки, диффузоры.</li>
                    <li>Места установки распределителей воздуха — стена или потолок.</li>
                    <li>Характер управления системой — клавиши, щит, пульт, компьютер, умный дом.</li>
                </ul>
                <p>Вообще проектирование и реализацию вентиляции лучше осуществлять на этапе строительства коттеджа (дома) или во время капитального ремонта квартиры. Тогда есть возможность безболезненно решить многие конструктивные задачи, например устройство вентиляционной камеры, монтаж оборудования, разводка вентканалов и скрытие их подвесными потолками. Важно, чтобы вентиляционная система имела минимум точек пересечения с другими коммуникациями, такими как система отопления и водоснабжения, электрические сети, слаботочные кабели. Поэтому, если у вас идёт ремонт или строительство, для поиска общих технических решений необходимо пригласить на объект и представителей подрядчика — монтажников, электриков, сантехников, инженеров.</p>
                <p>От правильной постановки задач зависит результат совместной работы.</p>
                <p>На основании полученных данных специалист осуществит подбор оборудования определённой производительности, учитывая параметры вентиляционной сети и способы монтажа.</p>

                <h2>Расчет вентиляции. Методы, примеры.</h2>
                <p>Для того чтобы выбрать необходимую нам систему вентиляции, нужно знать, сколько же воздуха надо подавать или удалять с того или иного помещения. Простыми словами, необходимо узнать воздухообмен в помещении или в группе помещений. Это даст понять как рассчитать систему вентиляции, выбрать тип и модель вентилятора и произвести расчет воздуховодов.</p>
                <p>Существует много вариантов как рассчитать воздухообмен, например, на удаление излишков тепла, на удаление влаги, на разбавление загрязнений до ПДК (предельно допустимой концентрации). Все они требуют специальных знаний, умения пользоваться таблицами и диаграммами.</p>
                <p>Следует отметить, что существуют государственные нормативные документы, такие как СанПины, ГОСТы, СНиПы и ДБНы, в которых четко определено, какие должны быть системы вентиляции в тех или иных помещениях, какое оборудование должно в них использоваться и где оно должно располагаться. А также, какое количество воздуха, с какими параметрами и по какому принципу должно в них подаваться и удаляться.</p>
                <p>При проектировании систем вентиляции каждый инженер проводит расчеты согласно вышеупомянутых норм. Для расчета воздухообмена в жилых помещениях  следует руководствоваться этими нормами. Рассмотрим  самые простые методы нахождения воздухообмена:</p>
                <ul>
                    <li>по площади помещения,</li>
                    <li>по санитарно-гигиеническим нормам,</li>
                    <li>по кратностям</li>
                </ul>
                <p><strong>Расчет по площади помещения</strong></p>
                <p>Это самый простой расчет. Расчет вентиляции по площади делается на основании того, что для жилых помещений нормы регламентируют подавать 3 м3/час свежего воздуха на 1 м2 площади помещения, независимо от количества людей.</p>
                <p><strong>Расчет по санитарно-гигиеническим нормам</strong></p>
                <p>По санитарным нормам для общественных и административно-бытовых зданий на одного постоянно пребывающего в помещении человека необходимо 60 м3/час свежего воздуха, а на одного временного 20 м3/час.</p>
                <p><strong>Рассмотрим на примере:</strong></p>
                <p>Предположим, в доме живут 2 человека, проведем расчет по санитарным нормам согласно этим данным. Формула расчета вентиляции, включающая нужное количество воздуха выглядит так:</p>
                <p>L=n*V (м3/час) , где</p>
                <ul>
                    <li>n – нормируемая кратность воздухообмена, час-1;</li>
                    <li>V – объём помещения, м3</li>
                </ul>
                <p>Составив уравнение воздушных балансов ∑ Lпр = ∑ Lвыт:360  525 м3/час, видим, что количество вытяжного воздуха превышает приточный на ∆L=165 м3/час. Поэтому количество приточного воздуха необходимо увеличить на 165 м3/час. Поскольку помещения спальни, кабинета и гостиной сбалансированы то воздух необходимый для санузла, ванны и кухни можно подать в помещение смежное с ними, к примеру, в коридор, т.е. в таблицу добавится Lприт.коридор=165 м3/час. Из коридора воздухбудет перетекать в ванную, санузлы и кухню, а оттуда посредством вытяжных вентиляторов (если они установлены) или естественной тяги удалятся из квартиры. Такое перетекание необходимо для предотвращения распространения неприятных запахов и влаги. Таким образом, уравнение воздушных балансов ∑ Lпр = ∑ Lвыт: 525=525м3/час - выполняется.</p>
                <p><strong>Расчет по кратностям</strong></p>
                <p>Кратность воздухообмена - это величина, значение которой показывает, сколько раз в течение одного часа воздух в помещении полностью заменяется на новый. Она напрямую зависит от конкретного помещения (его объема). То есть, однократный воздухообмен это когда в течение часа в помещение подали свежий и удалили «отработанный» воздух в количестве равном одному объему помещения; 0,5 -кранный воздухообмен – половину объема помещения.</p>
                <p>Последовательность расчета вентиляции по кратностям следующая:</p>
                <ol>
                    <li>Считаем объем каждого помещения в доме (объем=высота*длина*ширина).</li>
                    <li>Подсчитываем для каждого помещения объем воздуха по формуле: L=n*V (n – нормируемая кратность воздухообмена, час-1; V – объём помещения, м3)</li>
                </ol>
                <p>Для этого предварительно выбираем из таблицы "Санитарно-гигиенические нормы. Кратности воздухообмена в помещениях жилых зданий" норму по кратности воздухообмена для каждого помещения. Для большинства помещений нормируется только приток или только вытяжка. Для некоторых, например, кухня-столовая и то и другое. Прочерк означает, что в данное помещение не нужно подавать (удалять) воздух.</p>
                <p>Для тех помещений, для которых в таблице вместо значения кратности воздухообмена указан минимальный воздухообмен (например, ≥90м3/ч для кухни), считаем требуемый воздухообмен равным этому рекомендуемому. В самом конце расчета, если уравнение баланса (∑ Lпр и ∑ Lвыт) у нас не сойдется, то значения воздухообмена для данных комнат мы можем увеличивать до требуемой цифры. Если в таблице нет какого-либо помещения, то норму воздухообмена для него считаем, учитывая что для жилых помещений нормы регламентируют подавать 3 м3/час свежего воздуха на 1 м2  площади помещения. Т.е. считаем воздухообмен для таких помещений по формуле: L=Sпомещения*3. Все значения L округляем до 5 в большую сторону, т.е. значения должны быть кратны 5.</p>
                <p>Суммируем отдельно L тех помещений, для которых нормируется приток воздуха, и отдельно L тех помещений, для которых нормируется вытяжка. Получаем 2 цифры: ∑ Lпр и ∑ Lвыт</p>
                <p>Составляем уравнение баланса ∑ Lпр = ∑ Lвыт. Если ∑ Lпр  ∑ Lвыт , то для увеличения ∑ Lвыт до значения ∑ Lпр увеличиваем значения воздухообмена для тех помещений, для которых мы в 3 пункте приняли воздухообмен равным минимально допустимому значению.</p>
                <p>Если ∑ Lпр ∑ Lвыт , то для увеличения ∑ Lвыт до значения ∑ Lпр увеличиваем значения воздухообмена для помещений.</p>
                <p><strong>Рассчет основных параметров при выборе оборудования</strong></p>
                <p>При выборе оборудования для системы вентиляции необходимо рассчитать следующие основные параметры:</p>
                <ul>
                    <li>Производительность по воздуху;</li>
                    <li>Мощность калорифера;</li>
                    <li>Рабочее давление, создаваемое вентилятором;</li>
                    <li>Скорость потока воздуха и площадь сечения воздуховодов;</li>
                    <li>Допустимый уровень шума.</li>
                </ul>
                <p>Ниже приводится упрощенная методика подбора основных элементов системы приточной вентиляции, используемой в бытовых условиях.</p>
                <p><strong>Производительность по воздуху</strong></p>
                <p>Проектирование системы вентиляции начинается с расчета требуемой производительности по воздуху или «прокачки», измеряемой в кубометрах в час. Для этого необходим поэтажный план помещений с экспликацией, в которой указаны наименования (назначения) каждого помещения и его площадь. Расчет начинается с определения требуемой кратности воздухообмена, которая показывает сколько раз в течение одного часа происходит полная смена воздуха в помещении.</p>
                <p>Например, для помещения площадью 50 м2 с высотой потолков 3 метра (объем 150 кубометров) двукратный воздухообмен соответствует 300 кубометров/час. Требуемая кратность воздухообмена зависит от назначения помещения, количества находящихся в нем людей, мощности тепловыделяющего оборудования и определяется СНиП (Строительными Нормами и Правилами).</p>
                <p>Для определения требуемой производительности необходимо рассчитать два значения воздухообмена: по кратности и по количеству людей, после чего выбрать большее из этих двух значений.</p>
                <p><strong>Расчет воздухообмена по кратности:</strong></p>
                <p>L = n * S * H, где</p>
                <ul>
                    <li>L — требуемая производительность приточной вентиляции, м3/ч;</li>
                    <li>n — нормируемая кратность воздухообмена: для жилых помещений n = 1, для офисов n = 2,5;</li>
                    <li>S — площадь помещения, м2;</li>
                    <li>H — высота помещения, м;</li>
                </ul>
                <p><strong>Расчет воздухообмена по количеству людей:</strong></p>
                <p>L = N * Lнорм, где</p>
                <ul>
                    <li>L — требуемая производительность приточной вентиляции, м3/ч;</li>
                </ul>
            </div>
            <Form />
        </>
    )
}

export default VentilationHouseCottage