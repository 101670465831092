import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './productionConditioning.css'

import o305 from '../../../img/30500376.jpg'
import o21270img_2912 from '../../../img/21270img_2912.jpg'

const ProductionConditioning = () => {
    return (
        <>
            <div className='production-conditioning-page'>
                <h1>Кондиционирование производства, производственных помещений</h1>
                <p>Систему кондиционирования производственных помещений Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Промышленные климатические системы – это целый комплекс мощных систем кондиционирования, вентиляции и отопления, предназначенный для создания комфортного микроклимата в помещениях большой площади.</p>
                <p>Они принципиально отличаются от бытового климатического оборудования. Их основное отличие в том, что это сложная инженерная система, "конструктор", специально спроектированная для каждого конкретного случая и смонтированная с учетом индивидуальных особенностей здания, для обеспечения наиболее комфортной климатической среды.</p>
                <p>Кроме того, такие системы требуют профессионального сервисного обслуживания.</p>
                <p><strong>Рассмотрим некоторые предлагаемые нами системы промышленного кондиционирования:</strong></p>

                <h2>Мультизональные VRF и VRV системы кондиционирования</h2>
                <p>В настоящее время все большее применение в строительстве в качестве альтернативы традиционным системам кондиционирования с жидким теплоносителем находят мультизональные VRV и VRF системы кондиционирования. Помимо более высокой энергетической эффективности, по сравнению с традиционными системами кондиционирования, они имеют еще одно неоспоримое преимущество.</p>
                <img src={o305} alt='' />
                <p>Это возможность индивидуального и локального регулирования тепловых параметров микроклимата в помещениях. Для этих систем максимальный перепад по высоте между наружным и внутренними блоками составляет 100 м.</p>
                <p>Наружные блоки располагаются на техническом этаже снаружи здания, например на специальных балконах, либо а крыше здания.</p>
                <p>Мультизональные VRF и VRV системы используются для кондиционирования зданий, имеющих большое число помещений. Широко применяются в современном строительстве и подходят для больших коттеджей, административных, гостиничных зданий и торговых центрах.</p>

                <p><strong>Преимущества мультизональных систем:</strong></p>
                <ul>
                    <li>Внутренние блоки могут быть различных типов: настенные, кассетные, канальные т. п.;</li>
                    <li>К одному наружному блоку подключаются большое количество внутренних (до 150) блоков, которые могут иметь разную мощьность;</li>
                    <li>Возможность работы на охлаждение и обогрев одновременно;</li>
                    <li>Внешние блоки имеют низкий уровень шума, что дает возможность использовать такие системы в квартирах и частных домах.</li>
                </ul>
                <p>Мультизональные системы кондиционирования подразумевают подключение нескольких внутренних блоков к одному наружному.</p>

                <h2>Центральный кондиционер</h2>
                <p>Центральные промышленные кондиционеры предназначены для обслуживания нескольких помещений или одного большого — большие складские комплексы, производственные цеха, гостиницы, театральные залы, музеи, закрытые стадионы и т.п. Центральные кондиционеры предназначены не только для охлаждения или нагрева воздуха, но также для его вентиляции, очистки и увлажнения. Такиекондиционеры не являются автономными агрегатами и для работы им требуются внешние источники холода или тепла (чиллеры, компрессорно-конденсационные блоки, системы центрального отопления и т.д.).</p>
                <img src={o21270img_2912} alt='' />
                <p>Центральные кондиционеры представляют собой набор модулей, за каждым из которых закреплена определенная функция.</p>
                <p><strong>К основным блокам центрального кондиционера можно отнести следующие:</strong></p>
                <ul>
                    <li>водяной или фреоновый теплообменник — секция охлаждения;</li>
                    <li>водяной или электрический нагреватель (ТЭН) — секция нагрева;</li>
                    <li>вентиляторная секция, где вентиляторы производят забор воздуха и обеспечивают его транспортировку по сети воздуховодов в помещения;</li>
                    <li>секция шумопоглощения — устраняет вибрации и шумы, создаваемые вентиляторами;</li>
                    <li>секция увлажнения — при необходимости повышает относительную влажность подаваемого в помещения воздуха, поэтому применения промышленного увлажнителя не требуется;</li>
                    <li>секция фильтрации — очищает проходящий через промышленный кондиционер воздух от пыли, пуха, насекомых и т.д.;</li>
                    <li>теплоутилизаторы (рекуператоры) — позволяют использовать тепловую энергию утилизированного воздуха для подогрева свежего воздуха.</li>
                </ul>
            </div>
            <Form />
        </>
    )
}

export default ProductionConditioning