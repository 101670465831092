import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img17 from '../../../img/-17469603_heineken-beer-spain.jpg'
import img14 from '../../../img/-14-7.jpg'
import img15 from '../../../img/-15-6.jpg'
import img13 from '../../../img/138149big_vent.jpg'
import img12 from '../../../img/-1244_54b68fe037bcc_big.jpg'
import img25 from '../../../img/25123promyshlennaya-ventilyatsiya-ne-vypolnyat-izmenenij-v-sisteme.jpg'
import img18 from '../../../img/-18-2.jpg'

const IndustrialVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Промышленные системы вентиляции</h1>
                <p>Промышленную вентиляцию Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку промышленных систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Для производственных условий различных масштабов характерно возникновение следующих, усложняющих выполнение обязанностей рабочим цехов, факторов: загрязнённый воздух и пыль, неблагоприятные температурные показатели.</p>
                <p>Однако помимо затруднения работы и угрозы безопасности здоровью человека, многие факторы способны оказывать неблагоприятное влияние на ход рабочих процессов в помещении. Именно поэтому одну из ключевых ролей в обеспечении безопасности и эффективности рабочего процесса на предприятии играет промышленная вентиляция.</p>

                <h2>Виды производственной вентиляции</h2>
                <p>Классификация промышленного вентилирования проводится по критериям локализации, направленности и способа функционирования. Рассмотрим подробнее.</p>
                <p><strong>По принципу функционирования</strong></p>
                <ul>
                    <li>Естественная. Основывается на природной циркуляции воздушных потоков с разными температурой, давлением, плотностью. Тяжелый холодный воздушный поток вытесняет более легкий и теплый. В промышленном помещении этот процесс может происходить через естественные зазоры, неплотности оконных дверных проемов, либо организованные приточные и вытяжные проемы, закрытые решетками, дефлекторами. Зависит от атмосферных условий, силы и направления ветра, времени года (зимой проветривание осуществляется лучше за счет сильной тяги). Данный способ подходит далеко не всем производствам, особенно там, где есть вредные выбросы от работающей техники. Может устанавливаться, например, в помещениях сельскохозяйственного направления.</li>
                    <li>Искусственная вентиляция. Если производство предполагает побочный эффект в виде токсичных теплогазовыделений, механическая вентиляция производственных помещений строго обязательна. Главная функция – отведение отработанного воздухопотока от рабочей зоны персонала, препятствие проникновению вредных паров в другие помещения, отсеки, а также подача свежего уличного воздуха (очищенного или неочищенного) общим потоком или адресно. Организовывается при помощи механических средств подачи-отведения воздушных масс (приточных, вытяжных вентиляторов, крышных установок). Является более эффективным способом очищения, циркуляции воздухопотока внутри промышленного цеха.</li>
                </ul>
                <img src={img17} alt='' className='img-width' />
                <p><strong>По принципу локализации</strong></p>
                <ul>
                    <li>Общеобменная. Рассчитана на равномерное очищение всего цеха от вредных технологических тепловыделений, нормализуя температурно-влажностный показатель, скорость движения воздуха. Быстро справляется с небольшим процентом загрязнения воздушных масс.</li>
                    <li>Местная вентиляция. Применяется, когда есть локализация большого количества токсинов, паров, задымленности и т.д. в определенном месте. Устанавливается непосредственно над источником повышенного теплогазовыделения. Могут использоваться вытяжные зонты или гибкий воздуховод, подключенный непосредственно к оборудованию. Применяется совместно с общей вентиляционной системой в качестве дополнительного очищающего воздух оборудования.</li>
                    <li>Аварийная. Устанавливается и применяется в дальнейшем при экстренных случаях, например, пожаре, чрезмерном выбросе ядовитых веществ промышленным оборудованием, высоком уровне задымленности и др.</li>
                </ul>
                <p><strong>По принципу направленности потока</strong></p>
                <ul>
                    <li>Установки приточной вентиляции. Принцип действия основан на вытеснении холодным притоком теплого отработанного воздуха через организованные вытяжные проемы вверху цеха. Могут быть как естественной организации, так и механической.</li>
                    <li>Установки вытяжной вентиляции удаляют отработанный воздухопоток вместе с частицами гари, дыма, ядовитыми парами, лишним теплом и т.д. Конструктивно могут быть общими или локальными, чаще всего с принудительным побуждением, так как естественным путем удалить загрязненный воздух довольно проблематично.</li>
                    <li>Приточно вытяжная установка применяется наиболее часто, обеспечивает необходимую циркуляцию воздушных масс внутри промышленного цеха. Чаще всего с механическим оснащением (приточные, вытяжные вентиляторы).</li>
                </ul>
                <p><strong>Естественная система вентиляции</strong></p>
                <p>Каждый из перечисленных видов может быть классифицирован на вентиляцию естественного и механического типов.</p>
                <p>Под естественной вентиляцией понимается побуждение перемещения воздушных масс в результате ветра или гравитации, то есть естественных условий. Потоки воздуха внутри помещений самостоятельно курсируют через отверстия, например, через щели из-за неплотного прилегания двери к дверному проёму. Для вытяжки в таких системах используется вытяжной воздухоотвод – несколько единиц в необходимом количестве, определить которое позволяет расчёт вентиляции.</p>
                <p><strong>Особенности механической вентиляции</strong></p>
                <p>Для обеспечения механической вентиляции характерно использование принудительных устройств, например, вентиляторов, которые осуществляют воздухообмен по специальным каналам.</p>
                <p>По направлению воздушного потока промышленные вентиляционные системы механического типа подразделяются на приточные, которые устраиваются в верхней части помещений, и вытяжные, которые располагают на уровне 1,5-2 м над уровнем пола.</p>
                <img src={img14} alt='' className='img-width' />
                <p>Для больших производственных площадок требуется  установка промышленной вентиляции смешанного типа, так как для удаления большого объёма воздуха естественным путём с помощью вытяжных отверстий необходимо присутствие в системе принудительных элементов. Поэтому некоторые предприятиям показана комбинированная промышленная вентиляция, монтаж которой позволяет максимально эффективно организовать процесс воздухообмена.</p>
                <p>Так, например, для покрасочных цехов будет недостаточно функционирования одной лишь общеобменной вентиляционной системы. Здесь потребуется также внедрение локальной вентиляции в местах образования вредных компонентов. Такая система позволит предотвратить нежелательное перемещение воздушных масс и гарантированно увеличить эффективность вентиляционных процессов в условиях минимального воздухооборота.</p>
                <img src={img15} alt='' className='img-width' />

                <h2>Оборудование для вентиляционных систем</h2>
                <p>Назначение и классификация промышленной вентиляции полностью зависят от целевого применения самого помещения. Но всё же сегодня чаще делается упор на принудительную циркуляцию воздуха.</p>
                <p>Но принудительная система предоставляет больше возможностей. Так, можно объединить промышленные системы вентиляции и кондиционирования, что позволит контролировать температуру воздуха в помещении. С этой же целью используются рекуператоры, которые являются частью вентиляционных установок. Такие устройства направлены на снижение затрат электроэнергии благодаря особенностям конструкции.</p>
                <img src={img13} alt='' className='img-width' />
                <p>Приточный и вытяжной воздух проводят по отдельным каналам через рекуператор. Система устроена так, что оба потока не смешиваются, но теплообмен между ними всё же происходит.</p>
                <p>Таким образом, вентиляция промышленных предприятий является не только эффективной, но ещё экономически выгодной. Рекуператоры — оборудование дорогостоящее, а их действие более заметно именно при обслуживании больших площадей.</p>
                <p>Ещё одним видом вентиляционных установок является наборная система. Для неё выделяется отдельное помещение. Это объясняется крупными габаритами данных устройств. Их конструкция может совмещать в себе и систему фильтрации. Наиболее дорогостоящим решением является совмещённая промышленная вентиляция и кондиционирование. Если нагрев воздуха в вентиляционной установке может осуществляться посредством калорифера, то охлаждение воздушных потоков происходит путём монтажа отдельной центральной или же совмещённой системы кондиционирования.</p>

                <h2>Требования к производственной вентиляции</h2>
                <p>Вентиляция и кондиционирование производственных помещений регулируется общими требованиями СанПиН, а также параметрами, характерными непосредственно данному цеху предприятия. К ним относятся:</p>
                <ul>
                    <li>механическая вентиляция производственных помещений должна отвечать правилам пожарной безопасности;</li>
                    <li>удаление опасных для здоровья веществ, выбросов без допуска в рабочую зону персонала;</li>
                    <li>обязателен гигиенический и пожарный сертификат о безопасности материалов, из которых произведены элементы вентсистемы;</li>
                    <li>антикоррозийное покрытие воздуховодов, либо они должны быть сделаны из материалов, устойчивых к подобным воздействиям;</li>
                    <li>толщина покрытия вентиляционных каналов горючей краской не должна превышать 0,2 мм;</li>
                    <li>для расположенных непосредственно внутри цеха рабочих зон персонала концентрация вредных веществ не должна составлять более 30 %;</li>
                    <li>влажностный, скоростной показатели воздухопотока не нормируются в летний период;</li>
                    <li>в зимний период температурный показатель воздуха внутри цеха с находящимся там персоналом – минимум 10⁰ С, при отсутствии людей – минимум 5⁰ С;</li>
                    <li>в летний период температурные показатели внутреннего и наружного воздухопотоков равны, либо внутренняя температура не превышает наружную более чем на 4⁰ С;</li>
                    <li>неиспользуемые летом цеха требования к производственной вентиляции не регламентируют по температурному показателю;</li>
                    <li>общий уровень шума внутри промышленного цеха не должен превышать 110 дБа, сюда включается и рабочий шум системы вентилирования.</li>
                </ul>
                <p>Приведенный перечень довольно общий. На практике требования к вентиляции производственных помещений дополняются индивидуальными параметрами производства, конструкции цеха, спецификой выпускаемой продукции и т.д. Кроме того, необходимо обязательно учитывать, как взаимодействуют отопление и вентиляция внутри цеха. А также следует принимать в расчет, что освещение и вентиляция производственных помещений также взаимосвязаны.</p>
                <img src={img12} alt='' className='img-width' />

                <h2>Какие ограничения у любой системы вентиляции?</h2>
                <p>Некоторые ограничения включают в себя:</p>
                <ul>
                    <li>Системы ухудшаются на протяжении многих лет из-за загрязнений, наращиваемых в рамках системы (особенно фильтров).</li>
                    <li>Требуют постоянного технического обслуживания.</li>
                    <li>Регулярное и плановое тестирование необходимо для выявления проблем на ранних стадиях (для своевременного принятия мер по исправлению).</li>
                    <li>Убедиться в эффективной работе системы вентиляции может только квалифицированный персонал, способный вносить в нее изменения</li>
                </ul>
                <p>Ниже приведен пример изменений, которые могут повлиять на то, как работает система:</p>
                <img src={img25} alt='' className='img-width' />
                <p>Капот и ветви добавляются к существующему трубопроводу. Местная вытяжная вентиляция тянет воздух в систему из нового места - это снижает поток воздуха из других мест, которые расположены дальше от вытяжного вентилятора.Опять же, поток воздуха затрагивает все элементы. В результате, можно гораздно быстрее подключить в систему воздушные потоки других колпаков, и этого может быть достаточно для адекватного удаления загрязняющих веществ.</p>

                <h2>Конструкция и принцип действия</h2>
                <p>Монтаж промышленной вентиляции необходим для качественной очистки загрязнённого воздуха, без которой невозможно соблюсти все санитарно-гигиенические требования к производственному процессу и безопасности рабочих.</p>
                <p>Системы промышленной вентиляции классифицируют по типу конструкции и принципу действия на следующие виды:</p>
                <ul>
                    <li>Гравитационные.</li>
                </ul>
                <p>К ним относят пылеосадочные камеры, применяемые на производствах, характеризующихся сильным образованием пыли. Такие системы способствуют осаждению крупных частиц.</p>
                <ul>
                    <li>Инерционные сухого циклонного и жалюзийного типа.</li>
                </ul>
                <p>Для таких систем свойственны различия по компактности и конструкции. Они позволяют очистить воздушные массы от сухой пыли.</p>
                <ul>
                    <li>Инерционные мокрого типа.</li>
                </ul>
                <p>Такие системы эффективно справляются с очисткой воздуха, благодаря удалению пыли путём увлажнения масс.</p>
                <ul>
                    <li>Тканевые фильтры.</li>
                </ul>
                <p>Принцип действия данных устройств заключается в фильтрации воздушных потоков, благодаря накоплению в порах фильтрующего элемента многочисленных загрязнений.</p>
                <ul>
                    <li>Электрофильтры.</li>
                </ul>
                <p>Очищение воздуха в таких системах происходит путём устранения механических примесей использованием электрического заряда, в результате которого происходит оседание загрязнений на электродах фильтра.</p>

                <h2>Проектирование и монтаж</h2>
                <p>Проектирование и монтаж промышленной вентиляции – сложные технологические процессы, для грамотной организации которых необходимо руководствоваться факторами</p>
                <ol>
                    <li>Расчёта воздухооборота в каждом из помещений предприятия.</li>
                    <li>Локализации образовывающихся опасных примесей в воздухе (достижение предельно допустимых значений концентрации вредных веществ).</li>
                    <li>Эксплуатации вентиляционных систем, подходящих для очистки воздушных потоков, в условиях конкретного производства.</li>
                    <li>Соответствия технико-экономическому обоснованию подходящего вентиляционного оборудования.</li>
                </ol>
                <p>Проектирование промышленной вентиляции требует предварительного составления технического задания, разработкой которого должны заниматься опытные специалисты. Только квалифицированные инженеры-проектировщики способны учесть в техзадании все необходимые факторы, среди которых нужно выделить:</p>
                <ul>
                    <li>Планировку помещений.</li>
                    <li>Строительные материалы, с которыми предстоит иметь дело (монтировать оборудование).</li>
                    <li>Толщину стен и мн. др.</li>
                </ul>
                <img src={img18} alt='' className='img-width' />
                <p>По завершении работ компетентный специалист обязан предоставить правила эксплуатации вентиляционных систем. Инструкция по эксплуатации содержит все необходимые рекомендации для контроля работы вентиляции в котельной, например, для управления котлом.</p>
                <p>Промышленную вентиляцию Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку промышленных систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default IndustrialVentilation