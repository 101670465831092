import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './airConditioningRestaurantsCafesBars.css'

const AirConditioningRestaurantsCafesBars = () => {
    return (
        <>
            <div className='air-conditioning-restaurants-cafes-bars-page'>
                <h1>Кондиционирование ресторанов, кафе, баров</h1>
                <p>Систему кондиционирования ресторана, кафе или бара Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p><strong>Кондиционирование ресторанов, баров и кафе</strong> состоит из нескольких частей: вентиляция кухни, горячего цеха; кондиционирование зала кафе и бара с танцзалом (причем, с разделением на курящий и некурящий залы); кондиционирование административных и бытовых помещений (санузлы, гардероб). Все эти помещения обладают различными характеристиками и предъявляют к системе кондиционирования свои требования, а потому, выбрать подходящую систему кондиционирования самостоятельно – более чем сложно. Нужно помнить и о том, что проектировать систему кондиционирования кафе или ресторана следует в строгом соответствии с санитарными нормами и правилами, в которых определены необходимые требования, а сам проект необходимо согласовать в СЭС. Поэтому, перед окончательным выбором кондиционера лучше всего обратиться за консультацией к специалисту.</p>
                <p>Для зала кафе или ресторана скорее всего подойдёт сплит-система кассетного или канального типа. Эти системы устанавливаются за подвесным или подшивным потолком, а потому не нарушают сложившегося интерьера. Обычно такие кондиционеры располагаются над центром помещения, что позволяет создавать максимально ровный температурный фон, а кроме того, они практически бесшумны.</p>
                <p>Преимущество кассетного кондиционера - равномерное распределение воздушного потока по четырем направлениям, что позволяет использовать всего один кондиционер для охлаждения большого помещения, и делает его незаменимым в помещениях сложной формы. Кассетный кондиционер не нарушает сложившегося интерьера - при его установке в помещении видна только декоративная решётка.</p>
                <p>При использовании сплит-системы канального типа распределение охлажденного воздуха осуществляется по системе воздуховодов. Принципиальное отличие канального кондиционера от остальных сплит-систем - в возможности подачи свежего воздуха с улицы в объёмах, необходимых для полноценной вентиляции кондиционируемых помещений. С помощью системы воздуховодов воздух подается в помещение одновременно с двух, трех, пяти, десяти и более сторон, а при необходимости его можно распределить сразу на несколько помещений.</p>
                <p>Если фальшпотолок отсутствует, подойдут и сплит-системы настенного типа. Настенная сплит-система - самая распространенная модель кондиционеров, её выпускают абсолютно все существующие сегодня компании. При своей относительно невысокой стоимости, настенные кондиционеры замечательно справляются со своими функциями. Они компактны и неплохо вписываются практически в любой интерьер. Правда, использовать настенную сплит-систему можно только в одном помещении – на два, или тем более три, её уже не хватит.</p>
                <p>В кафе, стены которых не приспособлены для крепления настенных внутренних блоков, а подвесных потолков нет, вполне подойдут напольно-потолочные кондиционеры. Устанавливаются они, как следует из названия, либо внизу стены, либо непосредственно на потолке. В первом случае, поток воздуха направляется вертикально вверх, а во втором — горизонтально вдоль потолка. Такая конструкция позволяет равномернее распределять охлажденный воздух по помещению и избегать попадания прямого потока на людей.</p>
                <p>Систему кондиционирования ресторана, кафе или бара Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default AirConditioningRestaurantsCafesBars