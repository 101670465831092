import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img212 from '../../../img/212_small.jpg'
import img_1 from '../../../img/-1vytjazhnaja-sistema-ventiljacii.jpg'
import img_11 from '../../../img/-1sistema-vytjazhnoj-ventiljacii.jpg'
import img_01 from '../../../img/26-05-2014 21-00-01.jpg'
import img_011 from '../../../img/26-05-2014 21-00-55.jpg'
import img_012 from '../../../img/26-05-2014 20-52-38.jpg'
import img_013 from '../../../img/26-05-2014 21-20-20.jpg'

const ExhaustVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вытяжная система вентиляции</h1>
                <p>Вытяжную систему вентиляции Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вытяжной вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <img src={img212} alt='' />
                <p>Вытяжная вентиляция удаляет из помещения (цеха, корпуса) загрязненный или нагретый отработанный воздух. В общем случае в помещении предусматриваются, как приточные системы вентиляции, так и вытяжные системы. Их производительность должна быть сбалансирована с учетом возможности поступления воздуха в смежные помещения или из смежных помещений. В помещениях может быть также предусмотрена только вытяжная или только приточная система вентиляции. В этом случае воздух поступает в данное помещение снаружи или из смежных помещений через специальные проемы или удаляется из данного помещения наружу, или перетекает в смежные помещения.</p>

                <h2>Классификация</h2>
                <p>Для того чтобы проектирование вытяжной вентиляции позволило создать качественную и надежную сеть, подходящую именно для конкретного случая, необходимо знать и разбираться в типах и видах вытяжной вентиляции помещения. Они разделяются по нескольким факторам. По методу приведения воздуха в движение вытяжная вентиляция помещения бывает:</p>
                <ul>
                    <li>с естественным побуждением;</li>
                    <li>с механическим побуждением.</li>
                </ul>
                <p>Также, сети классифицируют по тому, какой объем помещения они обслуживают. По этому принципу вентиляция бывает:</p>
                <ul>
                    <li>местная (локальные системы);</li>
                    <li>общеобменная.</li>
                </ul>
                <p><strong>Естественное побуждение</strong></p>
                <p>Сети с естественным побуждением работают под действием природных факторов. К ним относят наиболее распространенные в наших в наших домах и квартирах вытяжные каналы в стене. Воздух в них движется под действием разницы давлений между помещением и наружной атмосферой. Основной положительный фактор вентиляционных вытяжек естественного типа – простота устройства и дешевизна.</p>
                <img src={img_1} alt='' className='img-width' />
                <p>Но существуют и негативные свойства. В первую очередь – это зависимость от погодных условий. В некоторых случаях естественная вентиляция в многоквартирном доме или коттедже может полностью остановиться. Такое возможно, когда выравнивается давление между наружным и внутренним воздухом.</p>
                <p>Также как негативным свойствам естественных сетей относят трудность регулирования. Невозможно так изменить природные факторы, чтобы остановить или уменьшить скорость движения воздуха. Для этих целей применяются задвижки и заслонки. Например, клапан на вытяжную вентиляцию, который ставятся на входе в канал.</p>
                <p><strong>Естественная вытяжная вентиляция особенности устройства</strong></p>
                <p>Циркуляция воздуха в любом помещении зависит от особенностей, заложенных при строительстве. Например, естественная вытяжная вентиляция в частном доме формируется за счет специальных отверстий – форточек и щелей в дверных проемах, а также за счет особенностей строительного материала.</p>
                <p>Так, построенный из древесины дом обладает высокими показателями воздухопроницаемости и позволяет внешнему потоку проникать в помещение.</p>
                <p>А вот кирпич таким свойством не обладает, поэтому приточно-вытяжная естественная вентиляция в помещении будет осуществляться только за счет форточек и щелей в дверных и оконных проемах.</p>
                <p>В комнатах специального назначения, таких как кухня или санузел, организуется канальная естественная вытяжная вентиляция, способствующая более быстройподаче свежего притока и удалению отработанного.</p>
                <p>Благодаря такому принципу происходит циркуляция микроклимата в многоэтажных домах, где создается единый центральный канал с ответвлениями к каждой квартире.</p>
                <p><strong>Механическое побуждение</strong></p>
                <p>Механические сети появились позже естественных. Принудительная вытяжная вентиляция состоит из вентилятора, который подключается к электросети, а также дополнительных элементов. Эти элементы помогают регулировать движение воздуха и выполнять некоторые другие функции.</p>
                <p>Устройство их сложнее и дороже, чем естественного типа. В первую очередь – это связано с необходимостью приобретать комплектующие, монтировать их и подключать к электросети.</p>
                <p>На правах рекламы</p>
                <img src={img_11} alt='' className='img-width' />
                <p>Вытяжная вентиляция в квартире или доме принудительного типа в большинстве случаев включает в себя такие элементы:</p>
                <ul>
                    <li>Вытяжная вентиляционная установка. Это может быть вентилятор радиального или осевого типа.</li>
                    <li>Вытяжные трубы для вентиляции. Они предназначены для движения воздуха из рабочей зоны наружу.</li>
                    <li>Регуляторы производительности. В основном они регулируют скорость вентилятора, чтобы увеличить или уменьшить объем проходящего через него воздуха. Такие устройства производятся разных типов и применяются для вентиляции производственных помещений, жилых комнат, общественных зданий в равной мере.</li>
                    <li>Фильтры. Их основная сфера применения – промышленная вентиляция. Они предназначены для задержки вредных загрязнений и защиты от них атмосферы.</li>
                </ul>
                <p>Также, монтаж установки вытяжной вентиляции механического типа подразумевает применение большого количества фасонных элементов, зажимов, креплений, которые помогают качественно присоединить вентиляционную сеть к вентилятору.</p>

                <h2>Виды вытяжных вентиляционных систем</h2>
                <p>Местная вытяжная вентиляция служит для удаления локализованных вредных веществ:</p>
                <ul>
                    <li>газов,</li>
                    <li>едкого дыма от сгорания,</li>
                    <li>масляных туманов,</li>
                    <li>табачного дыма,</li>
                    <li>тепла, выделяемого оборудованием...</li>
                </ul>
                <p>Местная вентиляция вытяжного типа эффективна в небольших помещениях, где имеется возможность осуществлять забор и отвод загрязненного воздуха непосредственно от места его образования, например, в кухнях.</p>
                <img src={img_01} alt='' />
                <p>Для удаления вредностей применяют местные отсосы (укрытия в виде шкафов, зонты, бортовые отсосы, завесы, укрытия в виде кожухов у станков и др.).</p>
                <img src={img_011} alt='' />
                <p><strong>Основные требования, которым они должны удовлетворять:</strong></p>
                <ul>
                    <li>Место образования вредных выделений по возможности должно быть полностью укрыто.</li>
                    <li>Конструкция местного отсоса должна быть такой, чтобы отсос не мешал нормальной работе и не снижал производительность труда.</li>
                    <li>Вредные выделения необходимо удалять от места их образования в направлении их естественного движения (горячие газы и пары надо удалять вверх, холодные тяжелые газы и пыль — вниз).</li>
                </ul>
                <p><strong>Конструкции местных отсосов условно делят на три группы:</strong></p>
                <ul>
                    <li>Полуоткрытые отсосы (вытяжные шкафы, зонты). Объемы воздуха определяются расчетом.</li>
                    <li>Отсосы открытого типа (бортовые отсосы). Отвод вредных выделений достигается лишь при больших объемах отсасываемого воздуха.</li>
                    <li>Система с местными отсосами.</li>
                </ul>
                <img src={img_012} alt='' />
                <p>Основными элементами такой системы являются местные отсосы — укрытия (МО), всасывающая сеть воздуховодов (ВС), вентилятор (В) центробежного или осевого типа, ВШ — вытяжная шахта.</p>
                <p>При устройстве местной вытяжной вентиляции для улавливания пылевыделений удаляемый из цеха воздух, перед выбросом его в атмосферу, должен быть предварительно очищен от пыли. Наиболее сложными вытяжными системами являются такие, в которых предусматривают очень высокую степень очистки воздуха от пыли с установкой последовательно двух или даже трех пылеуловителей (фильтров).</p>
                <p>Местные вытяжные системы  весьма эффективны, так как позволяют удалять вредные вещества непосредственно от места их образования или выделения, не давая им распространиться в помещении. Благодаря значительной концентрации вредных веществ (паров, газов, пыли), обычно удается достичь хорошего санитарно-гигиенического эффекта при небольшом объеме удаляемого воздуха.</p>
                <p>Однако местные системы не могут решить всех задач, стоящих перед вентиляцией. Не все вредные выделения могут быть локализованы этими системами. Например, когда вредные выделения, рассредоточены на значительной площади или в объеме; подача воздуха в отдельные зоны помещения не может обеспечить необходимые условия воздушной среды, тоже самое если работа производится на всей площади помещения или ее характер связан с перемещением и т.д.</p>
                <p>Общеобменная вытяжная вентиляция применяется в помещениях большой площади, в которых постоянно образуются и перемещаются вредные вещества.</p>
                <img src={img_013} alt='' />
                <p>Самым простым видом систем общеобменной вентиляции являются системы вытяжной вентиляции жилых и административных зданий. Воздухообмен в помещениях этих зданий определяется в соответствии с кратностями воздухообмена в час, установленными санитарными нормами и правилами для этих зданий. Большинство таких систем вентиляции являются системами с естественным побуждением движения воздуха. Объясняется это относительно малыми объемами перемещаемого ими воздуха, а также низкими финансовыми затратами при устройстве таких систем.</p>
                <p>Однако системы с естественным побуждением обладают следующими серьезными недостатками:</p>
                <ul>
                    <li>нестабильность работы, в связи с постоянно изменяющимися в течение года параметрами наружного воздуха;</li>
                    <li>низкое качество создаваемого комфорта в обслуживаемых помещениях, в связи с отсутствием в данных системах средств фильтрации и нагрева приточного воздуха.</li>
                </ul>
                <p>Следующим видом систем общеобменной вентиляции являются системы вытяжной вентиляции общественных зданий, таких как спортивные залы, плавательные бассейны, торгово-развлекательные центры и др. Данные системы являются системами с механическим побуждением движения воздуха со сложной схемой воздухораспределения и характеризуются большими объемами перемещаемого воздуха и сложностью расчета необходимых воздухообменов в помещениях. Воздухообмен в этих случаях рассчитывается исходя из условия удаления избытков тепла и влаги, поступающих в обслуживаемые помещения.</p>
                <p>Наиболее важными и ответственными системами общеобменной вентиляции зданий являются системы вентиляции бассейнов, так как они предназначены не только для поддержания благоприятных для людей параметров воздуха, но также и для сохранения в целостности отделочных материалов помещения бассейна, благодаря тому, что препятствуют образованию конденсата на ограждающих конструкциях помещения бассейна.</p>
                <p>К следующему виду систем общеобменной вентиляции относятся системы вытяжной вентиляции производственных зданий. Данные системы являются самыми сложными системами общеобменной вентиляции, так как в производственных зданиях выделяются самые разнообразные вредные вещества, определяемые характером производственного процесса. В соответствии с этим для производственных помещений отсутствуют нормативные кратности воздухообменов, установленные санитарными нормами и правилами, а воздухообмены для каждого помещения определяются расчетом. Необходимость проведения многочисленных расчетов во время проектирования, а также необходимость контроля различных параметров воздуха помещения во время эксплуатации характеризует сложность систем вентиляции производственных сооружений.</p>
                <p>Таким образом, к общеобменной вентиляции относится большинство систем вентиляции, начиная от простых систем вентиляции жилых помещений и заканчивая сложными системами вентиляции производственных помещений. Но вне зависимости от вида обслуживаемого помещения при устройстве любой системы общеобменной вентиляции требуется проведение точных расчетов, состоящих не только из расчетов воздухообменов, но также и из аэродинамических расчетов вентиляционной сети и подбора вентиляционного оборудования. Выполнение данных расчетов в составе проекта системы вентиляции, позволит получить систему вентиляции, работающую с максимально возможной энергоэффективностью и обеспечивающую наиболее благоприятные параметры воздушной среды в обслуживаемом помещении.</p>

                <h2>Оборудование для локальной вытяжной вентиляции</h2>
                <p>Укрытия для комплектации вытяжной вентиляции делятся на три категории:</p>
                <ul>
                    <li>расположенные снаружи источника загрязнения;</li>
                    <li>полностью перекрывающие источник выделения;</li>
                    <li>передувки.</li>
                </ul>
                <p>Очень эффективен метод локализации вредных выделений с помощью укрытий, накрывающих источник. Но технологический процесс не всегда позволяет использовать такой принцип работы вытяжной вентиляции. Другие устройства вытяжной локальной вентиляции:</p>
                <ul>
                    <li>вытяжные шкафы;</li>
                    <li>отсосы;</li>
                    <li>вытяжные зонты;</li>
                    <li>отсосы бортового, фасонного и витринного типов;</li>
                    <li>удаление выделений непосредственно из полостей станков;</li>
                    <li>капсулирование (станок устанавливается в капсулу).</li>
                </ul>
                <p>Отсос должен вытягивать вредные примеси с наименьшим расходом воздуха. На производстве часто используются отсосы, например, в качестве местной вытяжной вентиляции для пайки. Важное условие: комплектующие для вытяжной вентиляции должны обеспечивать доступ к станкам, не мешая работе сотрудников.</p>
                <p>Вытяжной зонт – наиболее распространенный пример отсосов. Зонты устанавливают для сбора опасных примесей, поднимающихся вверх, например, в качестве местной вытяжной вентиляции столов для пайки. Вытяжные зонты могут работать на принудительной или естественной тяге.</p>
                <p>Вытяжной шкаф наилучшим образом удаляет вредные вещества, создавая минимальный обмен воздуха. Шкафы бывают:</p>
                <ul>
                    <li>с верхним отсосом для сбора влажного и горячего воздуха;</li>
                    <li>с нижним и комбинированным отсосами для сбора тяжелых паров и газов;</li>
                    <li>с боковым отсосом и вентилятором типа “улитка” для сбора пыли.</li>
                </ul>
                <p>Двигатель и вентилятор для вытяжной вентиляции создают завихрение воздуха, не позволяя пыли распространиться по помещению. В качестве примера можно привести вытяжную вентиляцию на сварочном посту. Для сварки небольших деталей на постах оборудуются вытяжные вентиляционные шкафы с верхним отсосом и надвижным укрытием.</p>
                <p>При работе с неядовитыми веществами скорость движения воздуха на входе в устройство должна составлять:</p>
                <ul>
                    <li>0,6 – 0,7 мсек,</li>
                    <li>до 1,1-1,5 мсек для отсоса ядовитых примесей (в том числе паров тяжелых металлов).</li>
                </ul>
                <p>Всасывающие панели целесообразно использовать, когда в воздух выделяются пыль, ядовитые газы и тепло. Панель размещают так, чтобы поток ядовитых веществ проходил как можно дальше от лица сотрудника и воздуховодами соединяют с двигателем вытяжной вентиляции. Панели устанавливаются, например, в качестве вытяжной вентиляции сварочных постов, где работают с крупными изделиями. Панели двух- или одностороннего всасывания, навешивают на расстоянии не более 3,5 м от места сварки. Для всасывающей панели скорость движения воздуха должна быть:</p>
                <ul>
                    <li>при работе с горячей пылью 3,6 – 4,5 мсек;</li>
                    <li>при работе с ядовитыми, непыльными выделениями – 2,1 – 3,5 мсек.</li>
                </ul>
                <p>Каждый квадратный метр панели должен вытягивать 3300 кубометра воздуха в час.</p>
                <p>Бортовые отсосы применяют в тех случаях, когда объект выделения вредностей удерживается вертикальными подъемниками, то есть пространство выше него нельзя занимать. Например, в гальванических цехах вредные вещества стелются над поверхностью раствора, налитого в ванну и постепенно засасываются в щель отсоса. Бортовые отсосы – это воздуховоды с узкими входными отверстиями диаметром до 100 мм, расположенные по краям ванны.</p>
                <p>Вытяжную систему вентиляции Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку вытяжной вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ExhaustVentilation