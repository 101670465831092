import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/637393580_shema_otopleniya_chastnogo_doma_svoimi_rukami-400x304.jpg'
import img2 from '../../../img/27974111_18-400x166.jpg'
import img3 from '../../../img/40166odnotrubnaya-ili-dvuhtrubnaya-sistema-otopleniya1-400x179.jpg'
import img4 from '../../../img/57807odnotrubnaya-sistema-400x261.jpg'
import img5 from '../../../img/6591711223_0-400x368.jpg'
import img6 from '../../../img/678550304a-39.jpg'
import img7 from '../../../img/417100304a-40.jpg'
import img8 from '../../../img/508810304a-41.jpg'
import img9 from '../../../img/567720304a-42.jpg'
import img10 from '../../../img/863810304a-43.jpg'
import img11 from '../../../img/892040304a-44.jpg'
import img12 from '../../../img/1049830304a-45.jpg'
import img13 from '../../../img/800190304a-46.jpg'
import img14 from '../../../img/953630304a-47.jpg'

const HeatingPrivateHouseCottage = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Отопление частного загородного дома или коттеджа</h1>
                <p>Отопление частного дома или коттеджа Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Одним из этапов постройки частного дома является проектирование и создание отопительной системы. Это сложный этап, так как нужно не просто спроектировать отопление, но и сэкономить на материалах. Немаловажным фактором является и то, что созданное отопление должно отличаться эффективностью и экономичностью.</p>

                <h2>Элементы системы отопления</h2>
                <p>В подавляющем большинстве случаев частные жилые дома обогреваются водяными системами отопления. Это традиционный подход к решению вопроса, имеющий неоспоримое достоинство – универсальность. То есть, тепло доставляется во все помещения посредством теплоносителя, а уж нагревать его можно с помощью различных энергоносителей. Их перечень мы рассмотрим далее, при выборе котла.</p>
                <p><i>Водяные системы также дают возможность организовать комбинированное отопление с использованием двух или даже трех видов энергоносителей.</i></p>
                <p>Любая система отопления, где передаточным звеном служит теплоноситель, делится на такие составные части:</p>
                <ul>
                    <li>источник тепла;</li>
                    <li>трубопроводная сеть со всем дополнительным оборудованием и арматурой;</li>
                    <li>приборы отопления (радиаторы или греющие контуры теплых полов).</li>
                </ul>
                <p>С целью обработки и регулирования теплоносителя, а также производства работ по обслуживанию в отопительных системах применяется дополнительное оборудование и запорно – регулирующая арматура. К оборудованию относятся следующие элементы:</p>
                <ul>
                    <li>расширительный бак;</li>
                    <li>циркуляционный насос;</li>
                    <li>гидравлический разделитель (гидрострелка);</li>
                    <li>буферная емкость;</li>
                    <li>распределительный коллектор;</li>
                    <li>бойлер косвенного нагрева;</li>
                    <li>приборы и средства автоматизации.</li>
                </ul>
                <p><i>Примечание. Обязательным атрибутом водяной системы отопления является расширительный бак, остальное оборудование устанавливается по мере необходимости.</i></p>
                <p>Общеизвестно, что при нагреве вода расширяется, а в замкнутом пространстве ее дополнительному объему деваться некуда. Во избежание разрыва соединений от повышенного давления в сети ставится расширительная емкость открытого или мембранного типа. Она и принимает лишнюю воду.</p>
                <p>Принудительную циркуляцию теплоносителя обеспечивает насос, а при наличии нескольких контуров, разделенных гидрострелкой или буферной емкостью, используется 2 и более перекачивающих агрегатов. Что касается буферной емкости, то она работает одновременно как гидравлический разделитель и теплоаккумулятор. Отделение котлового контура циркуляции от всех остальных практикуется в сложных системах коттеджей с несколькими этажами.</p>
                <p>Коллекторы для распределения теплоносителя ставятся в системах отопления с теплыми полами либо в случаях, когда применяется лучевая схема подключения батарей, об этом мы расскажем в следующих разделах. Бойлер косвенного нагрева – это резервуар со змеевиком, где вода для нужд ГВС подогревается от теплоносителя. Для визуального контроля над температурой и давлением воды в системе устанавливаются термометры и манометры. Средства автоматизации (датчики, терморегуляторы, контроллеры, сервоприводы) не только осуществляют контроль над параметрами теплоносителя, но и регулируют их в автоматическом режиме.</p>

                <h2>Запорная арматура</h2>
                <p>Кроме перечисленного оборудования, водяное отопление дома управляется и обслуживается с помощью запорно–регулирующей арматуры, отображенной в таблице:</p>
                <p>Когда вы ознакомились, из каких элементов состоит система отопления, можно приступать к первому шагу на пути к цели – расчетам.</p>

                <h2>Расчет системы отопления и подбор мощности котла</h2>
                <p>Осуществить подбор оборудования невозможно, не зная количества потребной на обогрев здания тепловой энергии. Определить его можно двумя способами: простым приближенным и расчетным. Первый способ любят использовать все продавцы отопительной техники, поскольку он достаточно прост и дает более-менее корректный результат. Это вычисление тепловой мощности по площади отапливаемых помещений.</p>
                <p>Берут отдельную комнату, измеряют ее площадь и полученное значение умножают на 100 Вт. Энергия, необходимая на весь загородный дом, определяется суммированием показателей для всех комнат. Мы предлагаем более точный метод:</p>
                <ul>
                    <li>на 100 Вт умножать площадь тех помещений, где с улицей контактирует только 1 стена, на которой имеется 1 окно;</li>
                    <li>если комната – угловая с одним окном, то ее площадь надо умножать на 120 Вт;</li>
                    <li>когда в помещении есть 2 наружных стены с 2 окнами и более, ее площадь умножается на 130 Вт.</li>
                </ul>
                <p>Если считать мощность приближенным методом, то жители северных регионов РФ могут недополучить тепла, а юга Украины – переплатить за слишком мощное оборудование. С помощью второго, расчетного способа выполняется проектирование отопления специалистами. Он более точен, так как дает четкое понимание, сколько теряется тепла через строительные конструкции любого здания.</p>
                <p>Прежде чем приступить к вычислениям, дом надо обмерить, выяснив площади стен, окон и дверей. Затем надо определить толщину слоя каждого строительного материала, из коего возведены стены, полы и кровля. Для всех материалов в справочной литературе или интернете следует найти значение теплопроводности λ, выражаемое в единицах Вт/(м · ºС). Его подставляем в формулу для расчета термического сопротивления R (м2 ºС / Вт):</p>
                <p>R = δ / λ, здесь δ – толщина материала стены в метрах.</p>
                <p>Теперь можно узнать количество тепла, уходящее сквозь внешнюю строительную конструкцию, по формуле:</p>
                <ul>
                    <li>QТП = 1/R х (tв – tн) х S, где:</li>
                    <li>QТП – теряемое количество теплоты, Вт;</li>
                    <li>S – это измеренная ранее площадь строительной конструкции, м2;</li>
                    <li>tв – сюда надо подставить величину желаемой внутренней температуры, ºС;</li>
                    <li>tн – уличная температура в самый холодный период, ºС.</li>
                </ul>
                <h2>Виды схем разводки труб</h2>
                <p>Существует очень много схем разводки труб отопления по частным домовладениям. Некоторые из них являются комбинированными, что позволяет повысить эффективность системы и добиться более равномерного прогрева всего дома. В нашем обзоре мы рассмотрим только самые основные схемы:</p>
                <ul>
                    <li>однотрубная горизонтальная схема;</li>
                    <li>однотрубная вертикальная схема;</li>
                    <li>схема «Ленинградка»;</li>
                    <li>двухтрубная система с нижней разводкой;</li>
                    <li>двухтрубная система с верхней разводкой;</li>
                    <li>лучевая система с коллекторами;</li>
                    <li>схемы с принудительной и естественной циркуляцией.</li>
                </ul>

                <h2>Однотрубные системы</h2>
                <img src={img1} alt='' />
                <p>В однотрубных системах отопления теплоноситель последовательно проходит по всем радиаторам.</p>
                <p>Создавая отопление частного дома своими руками, проще всего обустроить однотрубную систему отопления. Она обладает множеством преимуществ, например, экономичностью использования материалов. Здесь мы можем неплохо сэкономить на трубах и добиться доставки тепла в каждое помещение. Однотрубная система отопления предусматривает последовательную доставку теплоносителя в каждую батарею. То есть теплоноситель покидает котел, заходит в одну батарею, потом в другую, потом в третью, и так далее.</p>
                <p>Что происходит в последней батарее? Достигнув конца отопительной системы, теплоноситель разворачивается и отправляется обратно в котел по цельной трубе. В чем заключаются основные преимущества подобной схемы?</p>
                <ul>
                    <li>Легкость в монтаже – нужно последовательно провести теплоноситель по батареям и вернуть его обратно.</li>
                    <li>Минимальный расход материалов – это самая простая и дешевая схема.</li>
                    <li>Низкое расположение труб отопления – их можно смонтировать по уровню пола или вовсе опустить под полы (это можно увеличить гидравлическое сопротивление и потребовать применения циркуляционного насоса).</li>
                </ul>
                <p>Присутствуют и некоторые недостатки, с которыми приходится мириться:</p>
                <ul>
                    <li>ограниченная длина горизонтального участка – не более 30 метров;</li>
                    <li>чем дальше от котла, тем холоднее радиаторы.</li>
                </ul>
                <p>Впрочем, есть некоторые технические ухищрения, которые позволяют нивелировать эти недостатки. Например, с длиной горизонтальных участков можно справиться установкой циркуляционного насоса. Он же поможет сделать последние радиаторы более теплыми. Компенсировать падение температуры помогут и перемычки-байпасы на каждом из радиаторов. Давайте теперь обсудим отдельные разновидности однотрубных систем.</p>

                <h2>Однотрубная горизонтальная</h2>
                <img src={img2} alt='' />
                <p>Самый простой вариант однотрубной горизонтальной системы отопления с нижним подключением.</p>
                <p>При создании системы отопления частного дома своими руками схема с однотрубной разводкой может оказаться самой выгодной и дешевой. Она одинаково хорошо подходит как для одноэтажных домов, так и для двухэтажных. В случае с одноэтажным домом она выглядит очень просто – радиаторы соединяются последовательно – с целью обеспечения последовательного протекания теплоносителя. После последнего радиатора теплоноситель отправляется по цельной обратной трубе в котел.</p>
                <p><strong>Достоинства и недостатки схемы</strong></p>
                <p>Для начала мы рассмотрим основные достоинства схемы:</p>
                <ul>
                    <li>простота реализации;</li>
                    <li>отличный вариант для небольших домов;</li>
                    <li>экономия материалов.</li>
                </ul>
                <img src={img3} alt='' />
                <p>Однотрубная горизонтальная схема отопления - отличный вариант для небольших помещений с минимальным количеством комнат.</p>
                <p>Схема действительно очень простая и понятная, поэтому с ее реализацией сможет справиться даже новичок. Она предусматривает последовательное соединение всех устанавливаемых радиаторов. Это идеальная схема разводки отопления для частного дома небольших размеров. Например, если это однокомнатный или двухкомнатный дом, то «городить» более сложную двухтрубную систему не имеет особого смысла.</p>
                <p>Глядя на фото такой схемы, мы можем отметить, что обратная труба здесь цельная, она не проходит через радиаторы. Поэтому такая схема более экономичная в плане расхода материалов. Если у вас нет лишних денег, такая разводка станет для вас наиболее оптимальной – она сэкономит деньги и позволит обеспечить дом теплом.</p>
                <p>Что касается недостатков, то их мало. Главным недостатком является то, что последняя батарея в доме будет холоднее, чем самая первая. Это связано с последовательным проходом теплоносителя через батареи, где он отдает накопленное тепло в атмосферу. Еще одним недостатком однотрубной горизонтальной схемы является то, что при выходе из строя одной батареи придется отключать сразу всю систему.</p>
                <p>Несмотря на определенные недостатки, такая схема обогрева продолжает использоваться во многих частных домах небольшой площади.</p>
                <p><strong>Особенности монтажа однотрубной горизонтальной системы</strong></p>
                <p>Создавая водяное отопление частного дома своими руками, схема с однотрубной горизонтальной разводкой окажется самой простой для реализации. В процессе монтажа необходимо смонтировать батареи отопления, после чего соединить их отрезками трубы. После подключения самого последнего радиатора необходимо развернуть систему в обратном направлении – желательно, чтобы отводящая труба проходила по противоположной стене.</p>
                <img src={img4} alt='' />
                <p>Однотрубная горизонтальная схема отопления может использоваться и в двухэтажных домах, каждый этаж здесь подключается параллельно.</p>
                <p>Чем больше ваше домовладение, тем больше в нем окон и тем больше в нем радиаторов. Соответственно, растут и тепловые потери, в результате чего в последних комнатах становится ощутимо прохладнее. Компенсировать падение температуры можно путем увеличения количества секций на последних радиаторах. Но лучше всего смонтировать систему с байпасами или с принудительной циркуляцией теплоносителя – об этом мы расскажем чуть позже.</p>
                <p>Аналогичная схема отопления может быть использована для обогрева двухэтажных домов. Для этого создаются две цепочки радиаторов (на первом и втором этажах), которые подключаются параллельно друг другу. Обратная труба в этой схеме подключения батарей одна, она начинается от последнего радиатора на первом этаже. Туда же подключается обратная труба, спускающаяся со второго этажа.</p>

                <h2>Однотрубная вертикальная</h2>
                <p>Как еще можно отапливать однотрубной системой двухэтажные домовладения? Альтернатива действительно есть – это однотрубная вертикальная система отопления, которой пользуются многие люди, подыскивающие подходящую схему парового отопления в частном доме. Никаких сложностей в подобной схеме нет, нужно просто вывести подающую трубу с теплоносителем на второй этаж и подключить располагающиеся там батареи, после чего сделать отводы вниз, на первый этаж.</p>
                <p><strong>Достоинства и недостатки однотрубной вертикальной схемы</strong></p>
                <p>Как обычно, начнем с положительных черт:</p>
                <img src={img5} alt='' />
                <p>В однотрубных вертикальных системах отопления теплоноситель проходит от радиатора на верхнем этаже к нижним этажам.</p>
                <ul>
                    <li>более выраженная экономия на материалах;</li>
                    <li>сравнительно одинаковая температура воздуха на первом и втором этажах;</li>
                    <li>простота реализации.</li>
                </ul>
                <p>Список недостатков такой же, как и у предыдущей схемы. В него вошли потери тепла на последних радиаторах. А так как теплоноситель у нас подается через верхний этаж, то на первом этаже может быть прохладнее, чем на втором.</p>
                <p>Экономия на материалах получается более чем солидной. Наверх у нас отправляется всего одна труба, от которой теплоноситель распределяется по всем радиаторам второго этажа (не последовательно). От каждого верхнего радиатора трубы спускаются к радиаторам на первом этаже, после чего попадают в одну общую обратную трубу. Таким образом, данная схема предполагает минимальное использование материалов.</p>

                <h2>Что нужно для сборки – 3 главные детали</h2>
                <p>Любая система отопления состоит из трех базовых компонентов:</p>
                <ul>
                    <li>источника тепла – в этой роли может выступать котел, печь, камин;</li>
                    <li>теплопередающей магистрали – обычно в этом качестве выступает трубопровод, по которому циркулирует теплоноситель;</li>
                    <li>нагревательного элемента – в традиционных системах это классический радиатор, преобразующий энергию теплоносителя в тепловое излучение.</li>
                </ul>
                <p>Разумеется, существуют схемы, исключающие первый и второй элементы этой цепочки. Например, общеизвестное печное отопление, когда источник является и нагревательным элементом, а теплопередающая магистраль отсутствует в принципе. Или конвекционный обогрев, когда из цепочки исключают радиатор, поскольку источник греет до нужной температуры сам воздух в доме. Однако печная схема считалась устаревшей еще в начале ХХ века, а конвекционный вариант очень сложно реализовать своими руками без специальных знаний и специфических умений. Поэтому большинство бытовых систем строится на базе водогрейного котла и водяного контура (трубопровода-разводки).</p>
                <p>В итоге для строительства системы нам потребуется один котел, несколько радиаторов (обычно их количество равно числу окон) и арматура для трубопровода с сопутствующими фитингами. Причем, чтобы собрать отопление частного дома, вам придется своими руками соединить все эти компоненты в рамках одной системы. Но перед этим было бы неплохо разобраться с параметрами каждого элемента – от котла до труб и радиаторов, чтобы знать, что покупать для дома.</p>

                <h2>Какой котел выбрать и как подсчитать его мощность</h2>
                <p>Водяное отопление черпает энергию у особого котла, камеру сгорания которого окружает заполненная жидким теплоносителем рубашка. При этом в топке могут гореть любые продукты – от газа до торфа. Поэтому перед сборкой системы очень важно выбрать не только мощность, но и тип источника тепла. И выбирать придется между тремя вариантами:</p>
                <ul>
                    <li>Газовым котлом – он перерабатывает на тепло магистральное или баллонное топливо.</li>
                    <li>Твердотопливным нагревателем – он питается углем, дровами или топливными гранулами (пеллетами, брикетами).</li>
                    <li>Электрическим источником – он преобразует в тепло электроэнергию.</li>
                </ul>
                <p>Наилучшим вариантом из всех вышеперечисленных является газовый теплогенератор, работающий на магистральном топливе. Он дешев в эксплуатации и работает в непрерывном режиме, поскольку подача топлива осуществляется автоматически и в сколь угодно больших объемах. Причем у такого оборудования фактически нет недостатков, кроме высокой пожарной опасности, которая присуща всем котлам.</p>
                <p>Неплохим вариантом теплового генератора, отапливающего частный дом без газопровода, является твердотопливный котел. Особенно модели, рассчитанные под длительное горение. Топливо для таких котлов можно найти, где угодно, а особая конструкция позволяет сократить периодичность загрузки с двух раз в сутки до одного заполнения топки в 2-3 дня. Впрочем, от периодической чистки не избавлены даже такие котлы, поэтому данный момент является основным минусом подобного нагревателя.</p>
                <p>Наихудшим вариантом выбора из всех возможных является электрический котел. Недостатки такого предложения очевидны – трансформация электричества в энергию теплоносителя стоит слишком дорого. Кроме того, электрический котел нуждается в частой замене нагревателя и обустройстве усиленной линии электропроводки, а равно и заземления. Единственный плюс такого варианта – это полное отсутствие продуктов горения. Для электрокотла не нужен дымоход. Поэтому большинство домохозяйств выбирают либо газовый, либо твердотопливный вариант. Однако, помимо типа топлива, домовладельцу необходимо обратить внимание еще и на параметры самого теплового генератора, а точнее – на его мощность, которая должна компенсировать тепловые потери жилища в зимний период.</p>
                <p>Выбор котла по мощности начинается с подсчетов метража отапливаемых помещений. Причем на каждый квадратный метр должно приходиться не менее 100 ватт тепловой мощности. То есть для помещения на 70 квадратов нужен котел на 7000 ватт или 7 кВт. Кроме того, было бы неплохо заложить в мощность котла 15-процентный запас, который пригодится во время сильных холодов. В итоге для дома на 70 м2 нужен котел на 8,05 кВт (7кВт 15 %).</p>
                <p>Более точные расчеты мощности нагревателя оперируют не квадратами площади, а объемом дома. В этом случае принято считать, что энергетические затраты на обогрев одного кубического метра равны 41 ватту. И дом площадью 70 м2 с 3-метровой высотой потолков должно отапливать теплогенерирующее устройство мощностью 8610 ватт (70×3×41). А с учетом 15-процентного запаса мощности на сильные холода максимальная теплогенерирующая способность такого котла должна равняться 9901 ватту или, с учетом округления, 10 кВт.</p>

                <h2>Батареи и трубы – медь, пропилен или металлопластик?</h2>
                <p>Чтобы провести систему отопления по дому нам понадобятся трубы и радиаторы. Последние можно выбирать, даже исходя из эстетических предпочтений. В частном доме нет большого напора в системе, следовательно, отсутствуют и ограничения по прочностным характеристикам радиаторов. Однако требования к теплогенерирующей способности батарей все равно остаются. Поэтому при подборе радиаторов будет правильно ориентироваться не только на внешний вид, но и на теплоотдачу. Ведь мощность нагревательного элемента должна соответствовать площади или объему комнаты. Например, в помещении на 15 квадратов должна стоять батарея (или несколько радиаторов) мощностью 1,5 кВт.</p>
                <p>С трубами получается более сложная ситуация. Тут нужно брать во внимание не только эстетическую составляющую, но и возможность выполнить монтаж сети своими силами при минимальных знаниях и усилиях со стороны доморощенного слесаря. Поэтому в качестве кандидатов на роль идеальной арматуры для разводки мы можем рассматривать только три варианта:</p>
                <ul>
                    <li>Медные трубы – они используются при обустройстве и домашних, и промышленных систем отопления, но стоят очень дорого. Кроме того, такая арматура соединяется с помощью пайки, а эта операция знакома далеко не всем.</li>
                    <li>Полипропиленовые трубы – они стоят дешево, но для их монтажа нужен особый сварочный аппарат. Впрочем, такой аппарат может освоить даже ребенок.</li>
                    <li>Металлопластиковые трубы – такую систему можно собрать с помощью гаечного ключа. Кроме того, металлопластик обходится не дороже полипропиленовых труб и позволяет экономить на угловых фитингах.</li>
                </ul>
                <p>В итоге самодельное отопление лучше собирать на базе металлопластиковой арматуры, поскольку она не требует от исполнителя умения обращаться со сварочным аппаратом или паяльником. В свою очередь, цанговые фитинги металлопластикового трубопровода можно монтировать даже руками, помогая себе гаечными ключам только на 3-4 последних оборотах. Относительно габаритов арматуры, а точнее проходного диаметра, у опытных специалистов по обустройству систем обогрева сложилось следующее мнение: для системы с насосом можно выбрать трубу ½ дюйма – такого пропускного диаметра хватит для домашней системы с избытком.</p>
                <p>Ну а если напорное оборудование не будет использоваться (вода пойдет по трубам самотеком, побуждаемая гравитационной и тепловой конвекцией), то для такой системы будет достаточно трубы на 1¼  или 1½ дюйма. Покупать арматуру большего диаметра при таких обстоятельствах не нужно. А какую разводку выбрать – напорную или безнапорную, об этом мы поговорим ниже по тексту, заодно обсудив и оптимальные схемы подключения батарей к котлу.</p>

                <h2>Принцип работы печной системы отопления частного дома</h2>
                <p>Для того чтобы обогревать дом, в котором предполагается проживание людей только в теплое время года, достаточно соорудить камин или же тонкостенную печь. Однако для отопления жилища на протяжении всего года подойдет только толстостенная печь. Данная печная система отопления частного дома обладает значительно большей теплоотдачей, чем камин. Печь представляет собой достаточно сложное «устройство», рассчитанное не только на обогрев дома, но и на подогрев воды, а также приготовление пищи.</p>
                <img src={img6} alt='' />
                <br />
                <img src={img7} alt='' />
                <p>По своему назначению печи могут быть отопительными, отопительно-варочными, варочными. Кроме того, существуют русские печи и каменки для русской бани. Наиболее оптимальный вариант для частного дома — отопительная либо отопительно-варочная печь.</p>
                <p>Наружная поверхность такой печи не должна нагреваться до температуры, превышающей 60 С. Печь обычно нагревается медленно и в течение длительного времени сохраняет тепло. Толщина ее стенок должна составлять не меньше половины кирпича.</p>
                <p>Коэффициент теплоотдачи печного отопления частного дома находится в прямой зависимости от конструкции печи и имеет следующие показатели.</p>
                <ol>
                    <li>Для толстостенной оштукатуренной печи или в металлическом футляре -400-500 ккал × м2/ч.</li>
                    <li>Для толстостенной изразцовой печи – 500 — 600 ккал × м2/ч.</li>
                    <li>Для тонкостенной печи массой более 1000кг – 500 -600 ккал × м2/ч.</li>
                    <li>Для тонкостенной печи массой менее 1000кг – 450-550 ккал × м2/ч.</li>
                </ol>
                <p>При проектировании печной отопительной системы необходимо стремиться к рациональности размещения печей, стараясь свести к минимуму их количества. Одна печь обычно устраивается для обогрева трех смежных комнат.</p>
                <p>Если сооружается несколько печей, их группируют в тепловой узел, когда дымоходы объединены одним кирпичным стояком.</p>
                <p>Такое размещение отопительных сооружений позволяет снизить финансовые расходы на устройство сооружений.</p>
                <p><i>Устанавливаются отопительные печи только вдоль внутренних стен. Принцип их работы заключается в следующем: кислород, который необходим для сгорания топлива, через поддувало поступает в печной топливник.</i></p>
                <p>Учитывая принцип работы печного отопления, дверца поддувала всегда должна находиться в открытом или полуоткрытом положении. Сгорание топлива происходит в топочной камере, которая снизу посредством колосников сообщается с пространством поддувала, а сверху — с дымоходами, обеспечивающими отвод дымовых газов.</p>
                <p>На этих фото показано печное отопление частного дома:</p>
                <img src={img8} alt='' />
                <br />
                <img src={img9} alt='' />

                <h2>Устройство печного отопления в частном доме: конструкция современных печей</h2>
                <p>Главными конструктивными элементами в устройства печного отопления частного дома являются: фундамент, шанцы, зольниковая камера, топливник, дымовые каналы (дымообороты), дымовые трубы.</p>
                <img src={img10} alt='' className='img-width' />
                <p>Фундамент — это основание печи, принимающее на себя нагрузки от печи и дымоходов. Данный конструктивный элемент должен быть надежным, поскольку от его прочности зависит безопасность эксплуатируемого сооружения. Правильное размещение печного фундамента предполагает его отдельное расположение от фундамента дома. Минимальный промежуток между ними составляет 3 см, который заполняется песком.</p>
                <p>Прежде всего, роют скважину, которая затем заполняется мелкими обломками камня или обожженного кирпича, после чего все тщательно утрамбовывается. Таким образом, подготавливают подушку под фундамент. Затем в котлован заливается жидкий цементный раствор. Кладка кирпичного или каменного фундамента выполняется с перевязкой швов. Последний слой цементного раствора тщательно выравнивается.</p>
                <img src={img11} alt='' className='img-width' />
                <p>После возведения фундамента приступают к выполнению такого конструктивного элемента печи, как шланцы. Они представляют собой ряды кирпичной кладки, которая поднимает печь поверх фундамента. Для устройства шланцев выполняется два или три ряда кладки из кирпичей. Низ печи, таким образом, также задействуется в теплоотдаче.</p>
                <img src={img12} alt='' className='img-width' />
                <p>Такой элемент конструкции печей отопления, как поддувало, или зольниковая камера, служит для подвода воздуха в топливник и для накапливания поступающей из него золы. Между топливником и зольниковой камерой устанавливается специальная решетка в виде чугунных или стальных прутьев. Во время работы печи дверца камеры должна быть открытой, а по окончании топки ее закрывают во избежание быстрого остывания воздуха внутри печи.</p>
                <img src={img13} alt='' className='img-width' />
                <p>Топливник в устройстве печей отопления представляет собой печную камеру, в которой осуществляется сжигание топлива — дров и угля. В верхней части топливника устраивается специальное отверстие для отвода дымового газа. Размеры камеры подбираются с таким расчетом, чтобы была возможна загрузка в печь необходимого для разогрева печи количества топлива.</p>
                <img src={img14} alt='' className='img-width' />
                <p>В нижней части топливника устраивают скаты к решетке, обеспечивая свободное перемещение золы в поддувало. Во избежание выпадения углей и золы из камеры топки ее дверца устанавливается выше решетки на один ряд кирпичной кладки. Продлить срок службы топливника можно, выложив его огнеупорным кирпичом.</p>
                <p>Принцип работы печной отопительной системы в частном доме основан на заборе тепла дымовыми каналами, или дымооборотами. Они могут располагаться как вертикально, так и горизонтально, а также подниматься и опускаться. То, насколько эффективно работает печь, зависит от размеров дымооборотов и их расположения.</p>
                <p>Дымовой газ, проходя через канал, отдает энергию в виде тепла стенкам, которые и нагревают печь. С целью повышения теплоотдачи дымовые каналы выполняют таким образом, чтобы они были длинными и часто меняли направление.</p>
                <p>Дымообороты современного печного отопления частного дома могут быть сечением 13 х 13, 13 х 26, 26 х 26 см, их стенки делаются гладкими (они не оштукатуриваются, так как в случае разрушения штукатурки может произойти засорение каналов). Доступ к дымооборотам для их чистки от сажи осуществляется через специальные дверцы.</p>
                <p><i>Дымовые каналы в конструкции печей для отопления дома могут быть малооборотными и многооборотными. Первые при одном подъеме имеют один спуск или несколько, в то время как вторые являются сложной системой с чередующимися горизонтальными и вертикальными участками.</i></p>
                <p>Для получения тяги, способствующей удалению газов от сожженного топлива, устраивают дымовую трубу, которую размещают за пределами дома — на крыше. Чаще всего ее делают круглого сечения, поскольку в трубах с углами перемещение газа несколько затруднено. Кроме того, круглые трубы удобнее чистить. В качестве материала для их изготовления используются керамические либо асбестоцементные трубы.</p>

                <h2>Как и какую систему отопления выбрать для частного дома</h2>
                <p>Обладая информацией о принципах работы в частных домах систем отопления разных типов, вам предстоит выбрать наиболее оптимальную для вашего жилища.</p>
                <p>Если для дачного домика вполне подойдет электрическое теплоснабжение, то в деревянном доме, в котором проживание семьи будет постоянным, целесообразно использовать водяную систему. В таком случае теплоснабжение будет обеспечиваться местной котельной. Если с электроэнергией перебоев не наблюдается, возможна организация в таком доме электрического отопления.</p>
                <p>Важным условием того, какую систему отопления выбрать для частного дома, является получение всей необходимой информации об источнике тепловой энергии в данной местности.</p>
                <p><i>Следует учитывать, что система должна осуществлять подачу тепла на минимальном уровне в отсутствие жильцов. Кроме того, источник тепловой энергии должен находиться вблизи жилища. Перед монтажом отопительной системы следует также навести справки о виде используемого в данном регионе топлива.</i></p>
                <p>Помимо этого, еще одним важным моментом в выборе отопительной системы для частного дома является ее стоимость, которая зависит, в свою очередь, от цены трубопровода и топлива, а также стоимости необходимого оборудования, монтажных работ и обслуживания.</p>
                <p>Обязательно нужно учесть все затраты (как финансовые, так и трудовые), которые будут приходиться на используемое топливо — его доставку, хранение и заготовку (в том случае, если используется твердое топливо в виде угля или дров). Необходимо выполнить тщательный расчет, показывающий расход топлива. Здесь важны два аспекта: продолжительность отапливания (только в летнее время или на протяжении всего года) и объем помещений.</p>
                <p>Главное условие при выборе отопительной системы — ее способность создать комфортные условия для проживания в доме. Это следует учитывать в первую очередь, а уже затем — стоимость услуг теплоснабжения.</p>
                <p>Отопление частного дома или коттеджа Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HeatingPrivateHouseCottage