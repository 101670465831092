import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../ventilationPages.css'

import img1 from '../../../img/13986722-05-2014-22-28-08.jpg'
import img2 from '../../../img/3915322-05-2014-21-05-41.jpg'
import img3 from '../../../img/2601922-05-2014-21-07-47.jpg'
import img4 from '../../../img/3502422-05-2014-21-11-49.jpg'
import img5 from '../../../img/6790722-05-2014-20-20-36.jpg'
import img6 from '../../../img/6991122-05-2014-20-23-47.jpg'
import img7 from '../../../img/14068922-05-2014-22-23-33.jpg'

const VentilationSystemRestaurantCafeBar = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Система вентиляции в ресторане, кафе или баре</h1>
                <p>Систему вентиляции в ресторане, кафе или баре Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Вентиляция воздуха в ресторане или кафе — это необходимое условие для формирования хорошего отношения и лояльности публики к данному заведению.</p>
                <p>При создании систем вентиляции бара, ресторана и кафе среди приоритетных задач можно выделить две основные:</p>
                <ul>
                    <li><strong>комфорт посетителей заведения;</strong></li>
                    <li><strong>рабочий микроклимат в горячем цехе;</strong></li>
                </ul>
                <p>И если вопрос вентиляции в залах для посетителей достаточно прост, то с горячими цехами дело обстоит гораздо сложнее, хотя бы по той лишь причине, что методик для расчета воздухообмена горячих цехов существует несколько, а разница в результатах расчетов, и, как следствие, комфорт персонала, более чем значительна. Какая из методик расчета наиболее применима в том или ином случае зависит от ряда факторов, характерных для данного объекта, и этот выбор ложится на плечи проектировщика, квалификация и опыт которого, в конечном итоге, и определит результат.</p>

                <h2>Требования</h2>
                <p>Требования к вентиляции ресторанов, кафе описаны в нормативной документации и, в общем-то, они несложны.</p>
                <p>В предприятиях питания на 50 и менее мест приточную вентиляцию обеденного зала и горячего цеха допускается проектировать как единую систему.</p>
                <p>Системы вытяжной вентиляции должны проектироваться самостоятельными для следующих групп помещений:</p>
                <ul>
                    <li>помещения для посетителей;</li>
                    <li>горячих цехов и моечных;</li>
                    <li>производственных и административных помещений;</li>
                    <li>уборных, умывальных и душевых;</li>
                    <li>охлаждаемых камер для хранения овощей и фруктов, мяса и рыбы;</li>
                    <li>охлаждаемых камер для хранения пищевых отходов.</li>
                </ul>

                <h2>Нормы воздухообмена для кафе, ресторанов, столовых</h2>
                <p>Простые, базовые цифры для оценки расхода воздуха вентиляции кафе или ресторана:</p>
                <ol>
                    <li>Производительно вентиляционной системы для зала, где допускается курение - 6-10 крат (т.е. объем помещения умноженный на 6-10). Цифра 10 - это очень густонасыщенное заведение с курением, 6 - ресторан. Если в заведении не курят, то достаточно подавать 60 м3/ч на одного человека.</li>
                    <li>Производительность вентиляции кухни: электропотребление всех приборов на кухне в кВт умножить на 80-100 м3/ч. Вентиляция кухни рассчитывается по электропотреблению конкретного прибора (мармит, плита и т.п.), но в среднем можно брать эти цифры. Это с небольшим запасом. </li>
                </ol>
                <p><strong>Расстановка столов и посадочных мест в обеденном зале</strong></p>
                <p>Как будет организовано кондиционирование обеденного зала, во многом зависит от расстановки столов и посадочных мест. Как правило, расстановка предполагает как можно более плотное размещение посетителей — для обеспечения максимальной вместительности зала, что, очевидно, усложняет решение задачи вентиляции и кондиционирования помещения.</p>
                <p><strong>В целом следует руководствоваться следующими принципами:</strong></p>
                <ul>
                    <li>Не подавать холодный воздух непосредственно в зону посадки людей («не задувать» холодом).</li>
                    <li>Использовать по возможности оборудование скрытого монтажа (как правило, кассетные и канальные кондиционеры).</li>
                    <li>Помнить, что решетки канальных кондиционеров можно использовать и для вентиляции помещения</li>
                </ul>
                <p><strong>Учет курящих посетителей</strong></p>
                <p>При расчетах и проектировании систем вентиляции всегда следует учитывать курящих посетителей. Во-первых, на каждого курящего следует подавать больше приточного воздуха (100–120 м 3 /ч вместо обычных 30–60). Во-вторых, желательно так располагать приточные и вытяжные решетки, чтобы воздух из курящей зоны не попадал в зону для некурящих посетителей.</p>
                <img src={img1} alt='' className='img-width' />
                <p>Если в ресторане не предусмотрено разделение на такие зоны, то в техническом задании желательно прописать соотношение курящих и некурящих посетителей. Это позволит обоснованно подобрать вентиляционное оборудование и защитит от проблем и претензий со стороны заказчика.</p>
                <p>Если же зона для курящих в ресторане предусмотрена, то ее рекомендуется отделить воздушной завесой, реализованной при помощи щелевых приточных решеток. Воздушная завеса не позволит табачному дыму попасть в зону для некурящих посетителей.</p>

                <h2>Проблемы горячих цехов</h2>
                <p>Основная трудность здесь заключена в том, что экономия рабочих площадей в горячих цехах приводит к повышенной теплонапряжённости до 800 – 2000 Вт на м2. В то время, как существующие нормативы требуют, что бы теплонапряженность не превышала 210 Вт на 1 м2 производственной площади.</p>
                <p>Системы вентиляции в горячих цехах проектируются с применением приточно-вытяжных локализующих устройств.</p>
                <img src={img2} alt='' />

                <h2>Схема вентиляции перемешиванием</h2>
                <p>Чаще всего используется традиционная схема распределения воздуха (подача и удаление воздуха в верхней зоне помещения). При данном варианте (рис. 2) температура приточного воздуха должна быть ниже температуры воздуха в обслуживаемой зоне не более чем на б ° С.</p>
                <img src={img3} alt='' />

                <h2>Схема вентиляции вытеснением</h2>
                <p>Одним из вариантов, применяемым все чаще в последнее время, может быть схема вентиляции вытеснением. При такой схеме подача воздуха осуществляется снизу, с маленькой скоростью 0,2-0,3 м/ч и с температурой воздуха несколько ниже температуры внутреннего воздуха (2—3°С). Удаление воздуха происходит в верхней зоне помещения.</p>
                <img src={img4} alt='' />
                <p>Применение такой схемы исключает перемешивание воздуха, что особенно важно, когда в помещении курят посетители.</p>
                <p>Также при проектировании вентиляции горячего цеха и моечной следует учесть указания к прокладке воздуховодов, например:</p>
                <ul>
                    <li>предусмотреть установку фильтра;</li>
                    <li>обеспечить уклон воздуховода в сторону мойки;</li>
                    <li>установить воздуховод швом наверх;</li>
                    <li>предусмотреть установку устройства для сбора жировых отложений;</li>
                    <li>выполнить дренажное соединение для отвода обезжиривающего моющего раствора из вытяжной системы горячего цеха; и т.п.</li>
                </ul>
                <img src={img5} alt='' />
                <p>Если вытяжными воздуховодами планируется транспортировать из горячих цехов воздух с температурой 150 °С и выше, то эти воздуховоды должны быть термоизолированными.</p>
                <p><strong>Пример из практики:</strong></p>
                <p>В одном из ресторанов делали расчет ситем вентиляции: на 100 посетителей при расчёте вентиляции ресторана оказалось 7756 Вт теплоизбытков (с учётом одновременности работы и коэффициента загрузки) на горячий цех площадью 14м2. Указанные значения соответствовали теплонапряжённости 540 Вт на м2. При этом минимально допустимые значения воздухообмена для теплового модульного оборудования и из под потолка составили 2600м3/час, что соответствует 61 кратному воздухообмену. Опыт показывает, что предельная кратность воздухообмена в горячих цехах составляет 50-80. Температура воздуха в этом случае приближается к максимально допустимой для горячих цехов +26°С.</p>
                <img src={img6} alt='' />
                <p>Вызывают внимание два обстоятельства:</p>
                <ol>
                    <li>Указанные условия достигаются при расчётной температуре наружного воздуха +22,3°С</li>
                    <li>Согласно "Строительным нормам вентиляции  предприятий общественного питания", около 60% воздуха, подаваемого в горячий цех, следует подавать из обеденных залов для поддержания требуемого разрежения.</li>
                </ol>
                <p><strong>Что из этого следует на практике:</strong></p>
                <p>В действительности температура +22,3°С часто превышается в летнее время, (например,, что параметр Б для Москвы +28,5°С) и условия труда в цеху становятся невыносимыми (и не соответствующими нормативам). Просто повышать воздухообмен нецелесообразно, т.к. во-первых, люди будут чувствовать себя как в аэродинамической трубе, во- вторых, при высокой температуре наружного воздуха это не даст никакого эффекта, в-третьих – может негативно сказаться на процессе приготовления пищи, и, наконец, потребует больших сечений воздуховодов и вентустановок повышенной производительности.</p>
                <p>Наиболее эффективным выходом в данном случае, представляется мне ставить индивидуальную приточную установку на технологическую зону с охлаждением приточного воздуха. Это позволит удержать воздухообмен в допустимых пределах, и обеспечить приемлемые условия труда. Понятно, что указанная мера потребует от инвестора дополнительных расходов, но они несравнимо меньше стоимости снижения теплонапряжённости за счёт дополнительной площади, выделяемой под горячий цех. При этом нельзя забывать и о снижении производительности труда людей, вынужденных трудиться в экстремальных условиях.</p>
                <p>То, что часть приточного воздуха (что для нашего ресторана составило 1600 м3/час), поступающего в горячий цех, может идти перетоком из торговой зоны, существенно уменьшает общий воздухообмен, экономически выгодно. Но дело в том, что не всегда воздух из торговых залов может подаваться в цеха, где приготавливается пища, и об этом мы сейчас поговорим более подробно.</p>

                <h2>От чего зависит выбор той или иной системы вентиляции?</h2>
                <p>Всё зависит важного обстоятельства: курят ли в торговых залах или нет. В зависимости от этого применяемые технические решения могут сильно различаться.</p>
                <img src={img7} alt='' className='img-width' />

                <h2><strong>Основные данные о вентиляции ресторана</strong></h2>
                <p>Основные данные о вентиляции ресторана, на основе которых вы можете принимать решения:</p>
                <ul>
                    <li>Для заведений с количеством посадочных мест более 50 необходимы две независимые приточные установки - одна для зала, другая для кухни.</li>
                    <li>Вытяжка из зала должна быть меньше, чем приток, а вытяжка на кухне должна быть больше, чем приток. Это исключает поступление запахов из кухни в залы.</li>
                    <li>Вытяжные воздуховоды из зала и кухни должны выводиться на крышу, над коньком (или, в экзотических случаях, на 25 метров от здания). </li>
                    <li>Существует особо мощные, но к сожалению, дорогие фильтровальные комплексы (например, "Ятаган" и др. аналоги), которые могут полностью очищать выбросы, что может не потребовать вывода воздуховодов на кровлю.</li>
                    <li>На кухне рекомендуется устанавливать отдельный вытяжной вентилятор для зонтов и отдельный на общеобменную вентиляцию кухни. Поскольку атмосфера на кухне зависит, прежде всего, от качества работы зонтов, поэтому очень разумно к воздуховодам зонтов не подсоединять никакие другие решетки и диффузоры. </li>
                    <li>В качестве вытяжки от зонтов лучше всего использовать вентилятор с выносным двигателем, "улитка". Основная проблема вентиляторов - налипание жира от зонтов, поэтому эти вентиляторы должны быть легко доступны для чистки, а также замены. "Живут" вытяжные установки кухни не долго, редко больше двух лет. Повара, видя, что зонты не справляются, зачастую убирают жироуловители, что еще быстрее выводит систему из строя.</li>
                    <li>Вентиляция ресторана всегда имеет воздуховоды больших размеров в силу высоких расходов воздуха, поэтому вполне понятно желание дизайнеров их уменьшить. Пожалуйста, не позволяйте им уменьшать размеры слишком сильно - это негативно влияет на комфорт в зале.</li>
                    <li>Приточная вентиляция потребляет очень много тепла в зимнее время для прогрева забираемого воздуха с улицы до +18. Зачастую помещения, в которых вы хотите открыть ресторан, не может обеспечить вентиляцию необходимым количеством тепла. Расчет простой: общий расход приточного зала и кухни нужно умножить на 5, чтобы получить количество тепла. Если вентиляция кухни и зала 20 000 м3/ч, что вам понадобится 100 000 Вт тепла, т.е. 100 кВт. Обычно воздух обогревается с помощью "горячей" воды.</li>
                    <li>Приточная вентиляция потребляет очень много тепла в зимнее время для прогрева забираемого воздуха с улицы до +18. Зачастую помещения, в которых вы хотите открыть ресторан, не может обеспечить вентиляцию необходимым количеством тепла. Расчет простой: общий расход приточного зала и кухни нужно умножить на 5, чтобы получить количество тепла. Если вентиляция кухни и зала 20 000 м3/ч, что вам понадобится 100 000 Вт тепла, т.е. 100 кВт. Обычно воздух обогревается с помощью "горячей" воды.</li>
                </ul>
                <p>Систему вентиляции в ресторане, кафе или баре Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default VentilationSystemRestaurantCafeBar