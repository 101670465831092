import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/35722tp-1.jpg'
import img2 from '../../../img/64958tp-3.jpg'
import img3 from '../../../img/-11152680517.jpg'
import img4 from '../../../img/91021shema20teplovogo20punkta.png'

const ThermalPoint = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Тепловой пункт</h1>
                <p>Тепловой пункт Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Теплопунктами называются автоматизированные комплексы,    передающие тепловую энергию между внешними и внутренними сетями . Они состоят из теплового оборудования, а также измеряющих и контролирующих приборов.</p>
                <p><strong>Назначение тепловых пунктов:</strong></p>
                <ul>
                    <li>преобразование вида теплоносителя или его параметров;</li>
                    <li>контроль параметров теплоносителя;</li>
                    <li>учет тепловых нагрузок, расходов теплоносителя и конденсата;</li>
                    <li>регулирование расхода теплоносителя и распределение по системам потребления теплоты (через распределительные сети в ЦТП или непосредственно в системы ИТП);</li>
                    <li>защита местных систем от аварийного повышения параметров теплоносителя;</li>
                    <li>заполнение и подпитка систем потребления теплоты;</li>
                    <li>сбор, охлаждение, возврат конденсата и контроль его качества;</li>
                    <li>аккумулирование теплоты;</li>
                    <li>водоподготовка для систем горячего водоснабжения.</li>
                </ul>
                <img src={img1} alt='' />
                <img src={img2} alt='' />
                <p>В тепловом пункте в зависимости от его назначения и местных условий могут осуществляться все перечисленные мероприятия или только их часть. Приборы контроля параметров теплоносителя и учета расхода теплоты следует предусматривать во всех тепловых пунктах.</p>
                <p>Устройство ИТП ввода обязательно для каждого здания независимо от наличия ЦТП, при этом в ИТП предусматриваются только те мероприятия, которые необходимы для присоединения данного здания и не предусмотрены в ЦТП.</p>
                <p>В закрытых и открытых системах теплоснабжения необходимость устройства ЦТП для жилых и общественных зданий должна быть обоснована технико-экономическим расчетом.</p>

                <h2>Принцип работы тепловых пунктов</h2>
                <p>Тепловая энергия поступает на тепловые пункты от теплогенерирующих предприятий посредством тепловых сетей - первичных магистрельных теплосетей. Вторичные, или разводящие, теплосети соединяют ТП уже с конечным потребителем.</p>
                <p>Магистральные теплосети обычно имеют большую протяженность, соединяя источник тепла и непосредственно тепловой пункт, и диаметр (до 1400 мм). Зачастую магистральные тепловые сети могут объединять несколько теплогенерирующих предприятий, что увеличивает надежность обеспечения потребителей энергией.</p>
                <p>Перед поступление в магистральные сети вода проходит водоподготовку, которая приводит химические показатели воды (жесткость, рН, содержание кислорода, железа) в соответствии с нормативными требованиями. Это необходимо для того, чтобы снижать уровень коррозионного влияния воды на внутреннюю поверхность труб.</p>
                <p>Разводящие трубопроводы имеют сравнительно малую протяженность (до 500 м), соединяя тепловой пункт и уже конечного потребителя.</p>
                <p>Теплоноситель (холодная вода) поступает по подающему трубопроводу в тепловой пункт, где проходит через насосы системы холодного водоснабжения. Далее он (теплоноситель) использует первичные подогреватели ГВС и подается в циркуляционный контур системы горячего водоснабжения, откуда поступает уже к конечному потребителю и обратно в ТП, постоянно циркулируя. Для поддержания необходимой температуры теплоносителя, он постоянно подогревается в подогревателе второй ступени ГВС.</p>
                <p>Система отопления - это такой же замкнутый контур, как и система ГВС. В случае возникновения утечек теплоносителя, его объем восполняется из системы подпитки теплового пункта.</p>
                <p>Затем теплоноситель поступает в обратный трубопровод и поступает опять на теплогенерирующее предприятие по магистральным трубопроводам.</p>

                <h2>Типы тепловых пунктов</h2>
                <p>Тепловые пункты различаются по типу и количеству подключений, по способам размещения (непосредственно в здании или отдельно), и таким образом делятся на несколько типов:</p>
                <ul>
                    <li>центральный тепловой пункт (ЦТП);</li>
                    <li>индивидуальный тепловой пункт (ИТП);</li>
                    <li>модульный (или блочный) тепловой пункт  (БТП) — созданная на одной раме конструкция, может применяться для ИТП или ЦТП.</li>
                </ul>
                <img src={img3} alt='' />

                <h2>Виды и особенности тепловых пунктов:</h2>
                <p><strong>Индивидуальный тепловой пункт (ИТП или автоматизированный АИТП)</strong></p>
                <p>Эти устройства способны обслуживать, как целое здание, так и отдельные его части. Предназначены для обеспечения энергоресурсами (теплом, горячей водой) и вентиляцией жилые и производственные комплексы любого типа.</p>
                <p>ИТП  монтируются для обслуживания многоквартирных домов и больших промышленных/производственных зданиий. Имеют мощность 50кВт-2МВт.</p>
                <p>Преимущества АТП:</p>
                <ul>
                    <li>строго дозируется поступление энергоносителей в помещение, что позволяет сэкономить до 15% от общих расходов на отопление (ведется учет);</li>
                    <li>автоматически поддерживается заданная температура в помещении, давление воды, теплоносителей и наружного воздуха;</li>
                    <li>уменьшается длина трубопровода и соответственно затраты на его покупку и укладку.</li>
                </ul>
                <p><strong>Центральный тепловой пункт (ЦТП)</strong></p>
                <p>Предназначен для круглогодичного обеспечения теплом и горячей водой нескольких совмещенных зданий. Включает в себя следующие установки:</p>
                <ul>
                    <li>теплообменник (подбирается индивидуально для каждого проекта);</li>
                    <li>насосы (отопительный, циркуляционный и противопожарный);</li>
                    <li>механические счетчики тепла и воды;</li>
                    <li>электронные измерительные приборы (КИП) и запорно-регулирующую арматуру.</li>
                </ul>
                <p><strong>Блочный/модульный тепловой пункт (БТП и МТП)</strong></p>
                <p>Представляет собой сложное заводское изделие, при помощи которого в самые сжатые сроки подсоединяется к теплосетям реконструируемых или новых зданий.</p>
                <p>Преимущества: БТП и МТП уже оснащены всем необходимым для выполнения своих задач, являются полностью автоматизированными установками, компактны, просты в монтаже и помогают сэкономить до 20-30% тепла. </p>
                <p>Устанавливая тепловой пункт, Вы получаете:</p>
                <ul>
                    <li>полностью автоматизированную установку, работающую абсолютно бесшумно;</li>
                    <li>снижение затрат на оплату теплоносителя, электроэнергии, обслуживающий персонал (ТП не нуждаются в постоянном контроле работы), ремонт и плановое обслуживание (эксплуатационные затраты снижаются на 50-60%);</li>
                    <li>оборудование, которое можно установить даже в условиях ограниченного пространства (к примеру, в подвальном помещении);</li>
                    <li>быстрый монтаж (осуществляется путем подсоединения только к внешним проводам).</li>
                </ul>
                <p>Вы тоже хотите экономить на энергоресурсах и максимально эффективно ими пользоваться? Мы всегда готовы предоставить Вам бесплатную консультацию и помощь в вопросах выбора и особенностей теплового оборудования, а также разработаем и воплотим в жизнь даже самый сложный проект по инсталляции теплового пункта (АТП, ЦТП, ИТП, БТП, МТП) в любое здание или комплекс сооружений.</p>

                <h2>Преимущества установки тепловых пунктов в системе теплоснабжения потребителей</h2>
                <p>Среди преимуществ тепловых пунктов можно назвать следующие:</p>
                <ul>
                    <li>минимизация тепловых потерь</li>
                    <li>сравнительно низкие эксплуатационные затраты, экономичность</li>
                    <li>возможность выбора режима теплоснабжения и теплопотребления в зависимости от времени суток и сезона</li>
                    <li>бесшумная работа, малые габариты (по сравнению с другим оборудованием системы теплообеспечения)</li>
                    <li>автоматизация и диспетчеризация процесса эксплуатации</li>
                    <li>возможность изготовления по индивидуальному заказу</li>
                </ul>
                <p>Тепловые пункты могут иметь разные тепловые схемы, типы систем теплопотребления и характеристики используемого оборудования, что зависит от индивидуальных требований Заказчика. Комплектация ТП определяется на основе технических параметров тепловой сети:</p>
                <ul>
                    <li>тепловые нагрузки на сеть</li>
                    <li>температурный режим холодной и горячей воды</li>
                    <li>давление систем тепло- и водоснабжения</li>
                    <li>возможные потери давления</li>
                    <li>климатические условия и т.д.</li>
                </ul>

                <h2>Принципиальная схема теплового пункта</h2>
                <p>Схема теплового пункта зависит, с одной стороны, от особенностей потребителей тепловой энергии, обслуживаемых тепловым пунктом, с другой стороны, от особенностей источника, снабжающего ТП тепловой энергией. Далее, как наиболее распространённый, рассматривается ТП с закрытой системой горячего водоснабжения и независимой схемой присоединения системы отопления.</p>
                <p>Теплоноситель, поступающий в ТП по подающему трубопроводу теплового ввода, отдает свое тепло в подогревателях систем ГВС и отопления, а также поступает в систему вентиляции потребителей, после чего возвращается в обратный трубопровод теплового ввода и по магистральным сетям отправляется обратно на теплогенерирующее предприятие для повторного использования. Часть теплоносителя может расходоваться потребителем. Для восполнения потерь в первичных тепловых сетях на котельных и ТЭЦ существуют системы подпитки, источниками теплоносителя для которых являются системы водоподготовки этих предприятий.</p>
                <p>Водопроводная вода, поступающая в ТП, проходит через насосы ХВС, после чего часть холодной воды отправляется потребителям, а другая часть нагревается в подогревателе первой ступени ГВС и поступает в циркуляционный контур системы ГВС. В циркуляционном контуре вода при помощи циркуляционных насосов горячего водоснабжения движется по кругу от ТП к потребителям и обратно, а потребители отбирают воду из контура по мере необходимости. При циркуляции по контуру вода постепенно отдает своё тепло и для того, чтобы поддерживать температуру воды на заданном уровне, её постоянно подогревают в подогревателе второй ступени ГВС.</p>
                <p>Система отопления также представляет замкнутый контур, по которому теплоноситель движется при помощи циркуляционных насосов отопления от ТП к системе отопления зданий и обратно. По мере эксплуатации возможно возникновение утечек теплоносителя из контура системы отопления. Для восполнения потерь служит система подпитки теплового пункта, использующая в качестве источника теплоносителя первичные тепловые сети.</p>
                <img src={img4} alt='' className='img-width' />

                <h2>Тепловые пункты промышленных предприятий</h2>
                <p>Промышленное предприятие должно, как правило, иметь один центральный тепловой пункт (ЦТП) для регистрации, учета и распределения теплоносителя, получаемого из тепловой сети. Количество и размещение вторичных (цеховых) тепловых пунктов (ИТП) определяется размерами и взаимным размещением отдельных цехов предприятия. ЦТП предприятия должен быть размещен в отдельном помещении; на крупных предприятиях, особенно при получении кроме горячей воды также и пара, - в самостоятельном здании.</p>
                <p>Предприятие может иметь цехи как с однородным характером внутренних тепловыделений (удельный вес в общей нагрузке), так и с разным. В первом случае температурный режим всех зданий определяется в ЦТП, во втором – различным и устанавливаться на ИТП. Температурный график для промышленных предприятий должен отличаться от бытового, по которому обычно работают городские теплосети. Для подгонки температурного режима в тепловых пунктах предприятий должны устанавливаться смесительные насосы, которые при единообразии характера тепловыделений по цехам могут быть установлены в одном ЦТП, при отсутствии единообразия – в ИТП.</p>
                <p>Проектирование тепловых систем промышленных предприятий должно проводиться с обязательным использованием вторичных энергоресурсов, под которыми понимаются:</p>
                <ul>
                    <li>отходящие от печей горячие газы;</li>
                    <li>продукты технологических процессов (нагретые слитки, шлаки, раскаленный кокс и пр.);</li>
                    <li>низкотемпературные энергоресурсы в виде отработавшего пара, горячей воды от различных охлаждающих устройств и производственные тепловыделения.</li>
                </ul>
                <p>Для теплоснабжения обычно используются энергоресурсы третьей группы, которые имеют температуры в пределах от 40 до 130°С. Предпочтительным является их использование для нужд ГВС, поскольку эта нагрузка имеет круглогодичный характер.</p>
                <p>При большой рассредоточенности цехов по территории предприятия желательно иметь систему диспетчерского контроля за параметрами сетевой воды в узловых точках тепловой сети и цеховых ИТП, что особенно необходимо при переменном характере тепловых нагрузок в течение суток, связанного со сменностью работы предприятий.</p>

                <h2>Этапы монтажа тепловых пунктов (ИТП, ЦТП)</h2>
                <p><strong>Узел ввода</strong></p>
                <p>При монтаже ИТП или ЦТП в первую очередь нужно оборудовать узел ввода, обеспечивающий распределение теплоносителя (как правило, воды) из теплосети между остальными узлами теплового пункта. Узел ввода оснащается запорной арматурой (шаровыми кранами), а так же сетчатым фильтром. В закрытых системах сетчатый фильтр монтируется только на подающем трубопроводе, а в открытых - на подающем и обратном. Для защиты сетчатого фильтра от повреждения перед ним допустима установка грязевика.</p>
                <p><strong>Узел учета</strong></p>
                <p>После завершения монтажа узла ввода на него устанавливается прибор учета тепловой энергии потребляемой абонентами или как его еще называют узел учета. Узел учета является обязательной частью оборудования ТП. На основании данных полученных от расходомеров и преобразователей прибор учета рассчитывает теплопотребление. Величина теплопотребления используется как для расчетов с поставщиком теплоснабжения, так и для управления тепловыми системами потребителей (например, для автоматического ограничения теплопотребления).</p>
                <p><strong>Узел согласования давления</strong></p>
                <p>Следующим этапом монтажа ТП является установка узла согласования давления. Оборудование узла выполняет ряд функций обеспечивающих стабильную работу как самого теплового пункта, так и систем отопления и горячего водоснабжения обслуживаемых объектов. Основной задачей данного узла является поддержание давления в различных системах и коммуникациях на необходимом уровне, а так же предотвращение аварий, возникающих из-за перепадов давления.</p>
                <p>После того как произведен монтаж оборудования перечисленного выше можно приступать к установке узлов подключения инженерных систем</p>
                <p><strong>Узел подключения горячего водоснабжения</strong></p>
                <p>Существуют два основных способа приготовления воды для ГВС – открытый и закрытый, в зависимости от выбранного способа в ТП монтируют соответствующее оборудование.</p>
                <p>При закрытой схеме для нагрева водопроводной воды в тепловом пункте устанавливают скоростные водоподогреватели представляющие собой трубчатые или пластинчатые теплообменники.</p>
                <p>При открытом способе, вода из теплосети поступает непосредственно в систему горячего водоснабжения. Для того чтобы температура воды в системе соответствовала принятым санитарным нормам в ИТП или ЦТП монтируют специальное оборудование предназначенное для смешивания воды из подающего и обратного трубопровода – трехходовой смесительные клапан либо проходной регулирующий клапан.</p>
                <p>Выбор того или иного способа зависит от принятой в районе строительства схемы теплоснабжения.</p>
                <p><strong>Узел подключения отопительной системы</strong></p>
                <p>В зависимости от типа подключения в ТП производят монтаж различного оборудования.</p>
                <p>Зависимое подключение системы отопления более простое, так как устанавливается меньше оборудования. При данном типе подключения основным элементом узла будет насос обеспечивающий автоматизацию и возможность использования в системе радиаторов с терморегуляторами. Преимуществом данной схемы является простота монтажа и невысокая стоимость оборудования, а так же сохранение отопления при отключении электроэнергии за счет давления в тепловой сети.</p>
                <p>При независимой схеме подключения сетевая вода подается в теплообменник, в котором происходит нагрев теплоносителя для отопительной системы. В этом случае система отопления представляет собой отдельный контур, не подсоединенный напрямую к теплосети. Для того чтобы обеспечить циркуляцию теплоносителя в закрытом контуре в тепловом пункте устанавливают циркуляционный насос. Управление температурой при независимом типе подключения осуществляется за счет изменения расхода воды из теплосети через теплообменник. Преимуществами данного типа подключения является защищенность системы отопления от загрязнений присутствующих в воде из тепловой сети и скачков давления. Недостатком является зависимость от электричества, большое количество оборудования которое необходимо установить (теплообменник, циркуляционный насос) и его цена.</p>
                <p><strong>Узел подпитки</strong></p>
                <p>Если проектом ТП предусмотрена независимая схема монтажа отопительной системы необходимо будет произвести монтаж узла подпитки. Оборудование узла подпитки – это расширительные баки обеспечивающие компенсацию колебаний объема теплоносителя при его нагреве и охлаждении.</p>
                <p>Системы автоматики и диспетчеризации</p>
                <p><strong>Последним этапом монтажных работ в ИТП/ЦТП является установка устройств автоматизации и диспетчеризации</strong></p>
                <p>Электронные устройства автоматики позволяют контролировать различные параметры работы ТП и управлять всеми приборами и узлами теплового пункта. Зачет встроенных модулей связи можно объединять контролирующие и управляющие устройства в сеть. Это дает возможность внедрять любые алгоритмы работы ТП, например, автоматически изменять температуру теплоносителя в системе отопления в зависимости от температуры воздуха на улице, ограничивать поступление теплоносителя по сигналу прибора учета, автоматически поддерживать давление в системе на заданном уровне и многое другое.</p>
                <p>Монтаж системы диспетчеризации в ИТП и ЦТП позволяет осуществлять удаленный контроль и управление работой теплового пункта посредством ЛВС или Интернет.</p>
                <p>Тепловой пункт Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ThermalPoint