import React from 'react'
import { useSelector } from 'react-redux';
import { Link, Route, Routes } from 'react-router-dom';
import AutomationControlFunctions from '../../pages/avtomation-pages/automation-control-functions/AutomationControlFunctions';
import AutomationVentilationConditioning from '../../pages/avtomation-pages/automation-ventilation-conditioning/AutomationVentilationConditioning';
import PopularAutomationVentilationConditioningSystems from '../../pages/avtomation-pages/popular-automation-ventilation-conditioning-systems/PopularAutomationVentilationConditioningSystems';
import ProtectiveFunctionsRequirements from '../../pages/avtomation-pages/protective-functions-requirements/ProtectiveFunctionsRequirements';
import SupplyVentilationAutomation from '../../pages/avtomation-pages/supply-ventilation-automation/SupplyVentilationAutomation';
import AirConditionerMaintenance from '../../pages/conditioner-pages/air-conditioner-maintenance/AirConditionerMaintenance';
import AirConditioningBuildings from '../../pages/conditioner-pages/air-conditioning-buildings/AirConditioningBuildings';
import AirConditioningResidentialBuildings from '../../pages/conditioner-pages/air-conditioning-residential-buildings/AirConditioningResidentialBuildings';
import AirConditioningRestaurantsCafesBars from '../../pages/conditioner-pages/air-conditioning-restaurants-cafes-bars/AirConditioningRestaurantsCafesBars';
import ApartmentAirConditioning from '../../pages/conditioner-pages/apartment-air-conditioning/ApartmentAirConditioning';
import CatalagAirConditioners from '../../pages/conditioner-pages/catalag-air-conditioners/CatalagAirConditioners';
import CentralAirConditioners from '../../pages/conditioner-pages/central-air-conditioners/CentralAirConditioners';
import ChillersFancoils from '../../pages/conditioner-pages/chillers-fancoils/ChillersFancoils';
import CommercialAirConditioners from '../../pages/conditioner-pages/commercial-air-conditioners/CommercialAirConditioners';
import CommissioningAirConditioningSystems from '../../pages/conditioner-pages/commissioning-air-conditioning-systems/CommissioningAirConditioningSystems';
import ConditionersWithVentilation from '../../pages/conditioner-pages/conditioners-with-ventilation/ConditionersWithVentilation';
import Consultations from '../../pages/conditioner-pages/consultations/Consultations';
import CottageHomeAirConditioning from '../../pages/conditioner-pages/cottage-home-air-conditioning/CottageHomeAirConditioning';
import DesignAirConditioningSystems from '../../pages/conditioner-pages/design-air-conditioning-systems/DesignAirConditioningSystems';
import GymAirConditioning from '../../pages/conditioner-pages/gym-air-conditioning/GymAirConditioning';
import HotelAirConditioning from '../../pages/conditioner-pages/hotel-air-conditioning/HotelAirConditioning';
import HouseholdSemiAirConditioners from '../../pages/conditioner-pages/household-semi-air-conditioners/HouseholdSemiAirConditioners';
import HowChooseConditioner from '../../pages/conditioner-pages/how-choose-conditioner/HowChooseConditioner';
import IndustrialAirConditioners from '../../pages/conditioner-pages/industrial-air-conditioners/IndustrialAirConditioners';
import InstallationAirConditioningSystems from '../../pages/conditioner-pages/installation-air-conditioning-sytems/InstallationAirConditioningSystems';
import MultiSplitSytems from '../../pages/conditioner-pages/multi-split-sytems/MultiSplitSytems';
import MultizoneVrfVrvSytems from '../../pages/conditioner-pages/multizone-vrf-vrv-sytems/MultizoneVrfVrvSytems';
import OfficeAirConditioning from '../../pages/conditioner-pages/office-air-conditioning/OfficeAirConditioning';
import PrecisionAirConditioners from '../../pages/conditioner-pages/precision-air-conditioners/PrecisionAirConditioners';
import ProductionConditioning from '../../pages/conditioner-pages/production-conditioning/ProductionConditioning';
import RoofAirConditioners from '../../pages/conditioner-pages/roof-air-conditioners/RoofAirConditioners';
import SchoolAirConditioning from '../../pages/conditioner-pages/school-air-conditioning/SchoolAirConditioning';
import ShoppingMallAirConditioning from '../../pages/conditioner-pages/shopping-mall-air-conditioning/ShoppingMallAirConditioning';
import SplitSystems from '../../pages/conditioner-pages/split-sytems/SplitSystems';
import StoreAirConditioning from '../../pages/conditioner-pages/store-air-conditioning/StoreAirConditioning';
import SupplyClimaticEquipment from '../../pages/conditioner-pages/supply-climatic-equipment/SupplyClimaticEquipment';
import UsefulInformation from '../../pages/conditioner-pages/useful-information/UsefulInformation';
import VentillationDesign from '../../pages/conditioner-pages/ventillation-design/VentillationDesign';
import WarehouseAirConditioning from '../../pages/conditioner-pages/warehouse-air-conditioning/WarehouseAirConditioning';
import WinterGardenAirConditioning from '../../pages/conditioner-pages/winter-garden-air-conditioning/WinterGardenAirConditioning';
import BoilerRoom from '../../pages/heating-pages/boiler-room/BoilerRoom';
import DehumidificationDesign from '../../pages/heating-pages/dehumidification-design/DehumidificationDesign';
import DehumidifierMaintenance from '../../pages/heating-pages/dehumidifier-maintenance/DehumidifierMaintenance';
import HeatingDesign from '../../pages/heating-pages/heating-design/HeatingDesign';
import HeatingIndustrialPremises from '../../pages/heating-pages/heating-industrial-premises/HeatingIndustrialPremises';
import HeatingInstallation from '../../pages/heating-pages/heating-installation/HeatingInstallation';
import HeatingMaintenance from '../../pages/heating-pages/heating-maintenance/HeatingMaintenance';
import HeatingPrivateHouseCottage from '../../pages/heating-pages/heating-private-house-cottage/HeatingPrivateHouseCottage';
import HeatingRepair from '../../pages/heating-pages/heating-repair/HeatingRepair';
import HeatingResidentialBuildings from '../../pages/heating-pages/heating-residential-buildings/HeatingResidentialBuildings';
import HumidificationDesign from '../../pages/heating-pages/humidification-design/HumidificationDesign';
import InstallationDehumidifiers from '../../pages/heating-pages/installation-dehumidifiers/InstallationDehumidifiers';
import InstallationHumidificationSystem from '../../pages/heating-pages/installation-humidification-system/InstallationHumidificationSystem';
import OfficeBuildingHeating from '../../pages/heating-pages/office-building-heating/OfficeBuildingHeating';
import PoolDehumidifiers from '../../pages/heating-pages/pool-dehumidifiers/PoolDehumidifiers';
import ShoppingMallHeating from '../../pages/heating-pages/shopping-mall-heating/ShoppingMallHeating';
import StoreHeating from '../../pages/heating-pages/store-heating/StoreHeating';
import ThermalPoint from '../../pages/heating-pages/thermal-point/ThermalPoint';
import WarehouseHeating from '../../pages/heating-pages/warehouse-heating/WarehouseHeating';
import WinterGardenHeating from '../../pages/heating-pages/winter-garden-heating/WinterGardenHeating';
import WorkshopHeating from '../../pages/heating-pages/workshop-heating/WorkshopHeating';
import ApartmentVentilation from '../../pages/ventilation-pages/apartment-ventilation/ApartmentVentilation';
import CalculationVentilationSystem from '../../pages/ventilation-pages/calculation-ventilation-system/CalculationVentilationSystem';
import CentralVentilation from '../../pages/ventilation-pages/central-ventilation/CentralVentilation';
import ConsultationsVentilation from '../../pages/ventilation-pages/consultations-ventilation/ConsultationsVentilation';
import DiagnosisExaminationVentilation from '../../pages/ventilation-pages/diagnosis-examination-ventilation/DiagnosisExaminationVentilation';
import EnergyEfficientVentilation from '../../pages/ventilation-pages/energy-efficient-ventilation/EnergyEfficientVentilation';
import ExhaustVentilation from '../../pages/ventilation-pages/exhaust-ventilation/ExhaustVentilation';
import ForcedVentilation from '../../pages/ventilation-pages/forced-ventilation/ForcedVentilation';
import GymFitnessCenterVentilation from '../../pages/ventilation-pages/gym-fitness-center-ventilation/GymFitnessCenterVentilation';
import HeatRecoveryVentilation from '../../pages/ventilation-pages/heat-recovery-ventilation/HeatRecoveryVentilation';
import HotelVentilation from '../../pages/ventilation-pages/hotel-ventilation/HotelVentilation';
import IndustrialVentilation from '../../pages/ventilation-pages/industrial-ventilation/IndustrialVentilation';
import InstallationVentilation from '../../pages/ventilation-pages/installation-ventilation/InstallationVentilation';
import ModernizationReconstructionVentilation from '../../pages/ventilation-pages/modernization-reconstruction-ventilation/ModernizationReconstructionVentilation';
import OfficeVentilation from '../../pages/ventilation-pages/office-ventilation/OfficeVentilation';
import ProductionVentilation from '../../pages/ventilation-pages/production-ventilation/ProductionVentilation';
import RecirculationVentilation from '../../pages/ventilation-pages/recirculation-ventilation/RecirculationVentilation';
import SchoolVentilation from '../../pages/ventilation-pages/school-ventilation/SchoolVentilation';
import ShopVentilation from '../../pages/ventilation-pages/shop-ventilation/ShopVentilation';
import ShoppingMallVentilation from '../../pages/ventilation-pages/shopping-mall-ventilation/ShoppingMallVentilation';
import SmokeRemoval from '../../pages/ventilation-pages/smoke-removal/SmokeRemoval';
import SupplyExhaustVentilation from '../../pages/ventilation-pages/supply-exhaust-ventilation/SupplyExhaustVentilation';
import VentilationDesign from '../../pages/ventilation-pages/ventilation-design/VentilationDesign';
import VentilationHouseCottage from '../../pages/ventilation-pages/ventilation-house-cottage/VentilationHouseCottage';
import VentilationMaintenance from '../../pages/ventilation-pages/ventilation-maintenance/VentilationMaintenance';
import VentilationRepair from '../../pages/ventilation-pages/ventilation-repair/VentilationRepair';
import VentilationResidentialBuildings from '../../pages/ventilation-pages/ventilation-residential-buildings/VentilationResidentialBuildings';
import VentilationSystemRestaurantCafeBar from '../../pages/ventilation-pages/ventilation-system-restaurant-cafe-bar/VentilationSystemRestaurantCafeBar';
import WarehouseVentilation from '../../pages/ventilation-pages/warehouse-ventilation/WarehouseVentilation';
import AboutCompany from '../about-company/AboutCompany';
import AvtoMenus from '../all-menus/AvtoMenus';
import HeatMenu from '../all-menus/HeatMenu';
import KondMenus from '../all-menus/KondMenus';
import OsushMenu from '../all-menus/OsushMenu';
import OtherMenu from '../all-menus/OtherMenu';
import UvlaMenus from '../all-menus/UvlaMenus';
import VentMenus from '../all-menus/VentMenus';
import Automation from '../automation/Automation';
import Conditioning from '../conditioning/Conditioning';
import Contact from '../contact/Contact'
import Dehumidification from '../dehumidification/Dehumidification';
import DeliveryPayment from '../delivery-payment/DeliveryPayment';
import DiscountStock from '../discount-stock/DiscountStock';
import EngineeringSystems from '../engineering-systems/EngineeringSystems';
import EquipmentCatalog from '../equipment-catalog/EquipmentCatalog';
import Form from '../form/Form';
import Harranty from '../harranty/Harranty';
import Heating from '../heating/Heating';
import Moisturizing from '../moisturizing/Moisturizing';
import OurObjects from '../our-objects/OurObjects';
import Ventilation from '../ventilation/Ventilation';
import './layout.css'

const Layout = () => {

    const { menu: menuName } = useSelector(state => state.menu)

    const isCond = menuName === 'cond' ? <KondMenus /> : null
    const isVent = menuName === 'vent' ? <VentMenus /> : null
    const isAvto = menuName === 'avto' ? <AvtoMenus /> : null
    const isHeat = menuName === 'heat' ? <HeatMenu /> : null
    const isUvla = menuName === 'uvla' ? <UvlaMenus /> : null
    const isOsush = menuName === 'osush' ? <OsushMenu /> : null
    const isOther = menuName === 'other' ? <OtherMenu /> : null

    return (
        <div className='layout-component'>
            <div className='left-menu'>
                {isCond}
                {isVent}
                {isAvto}
                {isHeat}
                {isUvla}
                {isOsush}
                {isOther}
            </div>

            <div className='pages-wrapper'>
                <Routes>
                    <Route path='/equipment-catalog' element={<EquipmentCatalog />} />
                    <Route path='/engineering-systems' element={<EngineeringSystems />} />
                    <Route path='/our-objects' element={<OurObjects />} />
                    <Route path='/about-company' element={<AboutCompany />} />
                    <Route path='/delivery-payment' element={<DeliveryPayment />} />
                    <Route path='/guarantee' element={<Harranty />} />
                    <Route path='/discount-stock' element={<DiscountStock />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/' element={<Conditioning />} />
                    <Route path='/ventilation' element={<Ventilation />} />
                    <Route path='/automation' element={<Automation />} />
                    <Route path='/heating' element={<Heating />} />
                    <Route path='/moisturizing' element={<Moisturizing />} />
                    <Route path='/dehumidification' element={<Dehumidification />} />

                    {/* CONDITIONER PAGES */}
                    <Route path='/how-to-choose-conditioner' element={<HowChooseConditioner />} />
                    <Route path='/conditioners-with-ventilation' element={<ConditionersWithVentilation />} />
                    <Route path='/household-semi-industrial-air-conditioners' element={<HouseholdSemiAirConditioners />} />
                    <Route path='/commercial-air-conditioners' element={<CommercialAirConditioners />} />
                    <Route path='/industrial-air-conditioners' element={<IndustrialAirConditioners />} />
                    <Route path='/useful-information' element={<UsefulInformation />} />
                    <Route path='/consultations' element={<Consultations />} />
                    <Route path='/design-airconditioning-systems' element={<DesignAirConditioningSystems />} />
                    <Route path='/ventillation-design' element={<VentillationDesign />} />
                    <Route path='/installation-air-conditioning-systems' element={<InstallationAirConditioningSystems />} />
                    <Route path='/air-conditioner-maintenance' element={<AirConditionerMaintenance />} />
                    <Route path='/supply-climatic-equipment' element={<SupplyClimaticEquipment />} />
                    <Route path='/commissioning-air-conditioning-systems' element={<CommissioningAirConditioningSystems />} />
                    <Route path='/catalag-air-conditioners' element={<CatalagAirConditioners />} />
                    <Route path='/split-systems' element={<SplitSystems />} />
                    <Route path='/multi-split-systems' element={<MultiSplitSytems />} />
                    <Route path='/multizone-vrf-vrv-sytems' element={<MultizoneVrfVrvSytems />} />
                    <Route path='/chillers-fancoils' element={<ChillersFancoils />} />
                    <Route path='/roof-air-conditioners' element={<RoofAirConditioners />} />
                    <Route path='/precision-air-conditioners' element={<PrecisionAirConditioners />} />
                    <Route path='/central-air-conditioners' element={<CentralAirConditioners />} />
                    <Route path='/office-air-conditioning' element={<OfficeAirConditioning />} />
                    <Route path='/production-conditioning' element={<ProductionConditioning />} />
                    <Route path='/warehouse-air-conditioning' element={<WarehouseAirConditioning />} />
                    <Route path='/apartment-air-conditioning' element={<ApartmentAirConditioning />} />
                    <Route path='/cottage-home-air-conditioning' element={<CottageHomeAirConditioning />} />
                    <Route path='/store-air-conditioning' element={<StoreAirConditioning />} />
                    <Route path='/shopping-mall-air-conditioning' element={<ShoppingMallAirConditioning />} />
                    <Route path='/air-conditioning-buildings' element={<AirConditioningBuildings />} />
                    <Route path='/gym-air-conditioning' element={<GymAirConditioning />} />
                    <Route path='/air-conditioning-residential-buildings' element={<AirConditioningResidentialBuildings />} />
                    <Route path='/school-air-conditioning' element={<SchoolAirConditioning />} />
                    <Route path='/winter-garden-air-conditioning' element={<WinterGardenAirConditioning />} />
                    <Route path='/hotel-air-conditioning' element={<HotelAirConditioning />} />
                    <Route path='/air-conditioning-restaurants-cafes-bars' element={<AirConditioningRestaurantsCafesBars />} />

                    {/* VENTILATION PAGES */}
                    <Route path='/consultations-ventilation' element={<ConsultationsVentilation />} />
                    <Route path='/ventilation-design' element={<VentilationDesign />} />
                    <Route path='/installation-ventilation' element={<InstallationVentilation />} />
                    <Route path='/ventilation-maintenance' element={<VentilationMaintenance />} />
                    <Route path='/diagnosis-examination-ventilation' element={<DiagnosisExaminationVentilation />} />
                    <Route path='/modernization-reconstruction-ventilation' element={<ModernizationReconstructionVentilation />} />
                    <Route path='/ventilation-repair' element={<VentilationRepair />} />
                    <Route path='/supply-exhaust-ventilation' element={<SupplyExhaustVentilation />} />
                    <Route path='/forced-ventilation' element={<ForcedVentilation />} />
                    <Route path='/exhaust-ventilation' element={<ExhaustVentilation />} />
                    <Route path='/heat-recovery-ventilation' element={<HeatRecoveryVentilation />} />
                    <Route path='/recirculation-ventilation' element={<RecirculationVentilation />} />
                    <Route path='/smoke-removal' element={<SmokeRemoval />} />
                    <Route path='/central-ventilation' element={<CentralVentilation />} />
                    <Route path='/industrial-ventilation' element={<IndustrialVentilation />} />
                    <Route path='/apartment-ventilation' element={<ApartmentVentilation />} />
                    <Route path='/ventilation-house-cottage' element={<VentilationHouseCottage />} />
                    <Route path='/office-ventilation' element={<OfficeVentilation />} />
                    <Route path='/shop-ventilation' element={<ShopVentilation />} />
                    <Route path='/shopping-mall-ventilation' element={<ShoppingMallVentilation />} />
                    <Route path='/ventilation-system-restaurant-cafe-bar' element={<VentilationSystemRestaurantCafeBar />} />
                    <Route path='/production-ventilation' element={<ProductionVentilation />} />
                    <Route path='/warehouse-ventilation' element={<WarehouseVentilation />} />
                    <Route path='/ventilation-residential-buildings' element={<VentilationResidentialBuildings />} />
                    <Route path='/gym-fitness-center-ventilation' element={<GymFitnessCenterVentilation />} />
                    <Route path='/school-ventilation' element={<SchoolVentilation />} />
                    <Route path='/hotel-ventilation' element={<HotelVentilation />} />
                    <Route path='/energy-efficient-ventilation' element={<EnergyEfficientVentilation />} />
                    <Route path='/calculation-ventilation-system' element={<CalculationVentilationSystem />} />

                    {/* AUTOMATION PAGES */}
                    <Route path='/protective-functions-requirements' element={<ProtectiveFunctionsRequirements />} />
                    <Route path='/supply-ventilation-automation' element={<SupplyVentilationAutomation />} />
                    <Route path='/automation-ventilation-conditioning' element={<AutomationVentilationConditioning />} />
                    <Route path='/popular-automation-ventilation-conditioning-systems' element={<PopularAutomationVentilationConditioningSystems />} />
                    <Route path='/automation-control-functions' element={<AutomationControlFunctions />} />

                    {/* HEATING PAGES */}
                    <Route path='/heating-design' element={<HeatingDesign />} />
                    <Route path='/heating-installation' element={<HeatingInstallation />} />
                    <Route path='/heating-maintenance' element={<HeatingMaintenance />} />
                    <Route path='/heating-repair' element={<HeatingRepair />} />
                    <Route path='/thermal-point' element={<ThermalPoint />} />
                    <Route path='/boiler-room' element={<BoilerRoom />} />
                    <Route path='/heating-private-house-cottage' element={<HeatingPrivateHouseCottage />} />
                    <Route path='/warehouse-heating' element={<WarehouseHeating />} />
                    <Route path='/heating-industrial-premises' element={<HeatingIndustrialPremises />} />
                    <Route path='/workshop-heating' element={<WorkshopHeating />} />
                    <Route path='/office-building-heating' element={<OfficeBuildingHeating />} />
                    <Route path='/store-heating' element={<StoreHeating />} />
                    <Route path='/shopping-mall-heating' element={<ShoppingMallHeating />} />
                    <Route path='/heating-residential-buildings' element={<HeatingResidentialBuildings />} />
                    <Route path='/winter-garden-heating' element={<WinterGardenHeating />} />
                    <Route path='/humidification-design' element={<HumidificationDesign />} />
                    <Route path='/installation-humidification-system' element={<InstallationHumidificationSystem />} />
                    <Route path='/dehumidification-design' element={<DehumidificationDesign />} />
                    <Route path='/installation-dehumidifiers' element={<InstallationDehumidifiers />} />
                    <Route path='/dehumidifier-maintenance' element={<DehumidifierMaintenance />} />
                    <Route path='/pool-dehumidifiers' element={<PoolDehumidifiers />} />

                    <Route path='/form' element={<Form />} />
                </Routes>
            </div>

            <div className='right-menu'>
                <a href='tel:+998903559091' className='tel'>+998 (90) 355-90-91</a>
                <Link to='/form'>
                    <button>Отправьте заявку и получите коммерческое предложения</button>
                </Link>
            </div>
        </div>
    )
}

export default Layout