import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import '../heatingPages.css'

import img1 from '../../../img/33418111_11.jpg'
import img2 from '../../../img/22416222_1.jpg'
import img3 from '../../../img/750353333_2-400x246.jpg'
import img4 from '../../../img/521243-4-300x149.png'

const HeatingInstallation = () => {
    return (
        <>
            <div className='heating-pages'>
                <h1>Монтаж систем отопления</h1>
                <p>Монтаж отопления Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Схемы монтажа системы отопления</h2>
                <p>На практике применяются два вида систем – схем (или видов разводки труб), а именно:</p>
                <ul>
                    <li>однотрубная;</li>
                    <li>двухтрубная.</li>
                </ul>
                <p>Каждая из них имеет свои преимущества, недостатки и используется в разных случаях.</p>
                <p><strong>Однотрубная система</strong></p>
                <p>Этот тип разводки более дешевый и простой. Система строится в виде кольца – все батареи соединяются последовательно друг с другом, и горячая вода движется от одного радиатора к другому, затем попадает снова в котел.</p>
                <img src={img1} alt='' />
                <p>Как видно на рисунке, все батареи соединены последовательно, а теплоноситель проходит каждую из них.</p>
                <p>Эта схема отопления весьма экономична в своем исполнении, ее просто монтировать и проектировать. Но в ней присутствует один существенный недостаток. Он настолько весомый, что многие отказываются от такой разводки и предпочитают более дорогую и сложную – двухтрубную. Проблема в том, что по мере продвижения теплоносителя он будет постепенно остывать. До последней батареи вода будет поступать чуть теплой. Если же увеличить мощность котла, то первый радиатор будет слишком сильно нагревать воздух. Такая неравномерность распределения тепла и заставляет отказываться от простой и дешевой однотрубной системы.</p>
                <p>Можно попробовать выйти из сложной ситуации, увеличив количество секций последнего радиатора, но и это не всегда эффективно. Отсюда напрашивается вывод, что однотрубную разводку можно использовать в том случае, когда количество соединенных последовательно батарей составляет не более трех.</p>
                <p>Некоторые выходят из ситуации следующим образом: подключают к котлу насос, тем самым заставляя воду двигаться в принудительном порядке. Жидкость не успевает остывать и проходит через все радиаторы, почти не теряя температуры. Но и в этом случае вас ждут некоторые неудобства:</p>
                <ul>
                    <li>насос стоит денег, а значит, растут затраты на установку системы;</li>
                    <li>увеличивается потребление электроэнергии, так как насос работает от электричества;</li>
                    <li>если отключат электричество, то не будет давления в системе, а значит, не будет и тепла.</li>
                </ul>
                <p>Вывод. Однотрубная система эффективна лишь для маленьких домиков с 1-2 комнатами, где используется небольшое количество радиаторов. Несмотря на свою простоту и надежность, она не оправдывает себя в загородных домах, где нужно устанавливать более трех радиаторов на всю жилую площадь.</p>
                <p><strong>Двухтрубная система</strong></p>
                <img src={img2} alt='' />
                <p>Горячая вода подается по одному трубопроводу, а остывшая – по другому. Таким образом обеспечивается равномерное распределение тепла по всем батареям.</p>
                <p>Такая разводка отопления в частном доме будет намного эффективнее и лучше однотрубной. Хотя она и дороже в исполнении, и сложнее в монтаже, но позволяет равномерно распределять тепло по всем батареям, что поможет создать комфортные условия. В отличие от однотрубной, в этой разводке труба с горячей водой подводится под каждый радиатор, а остывшая жидкость спускается по обратной линии в котел. Так как теплоноситель подается сразу на все батареи, то и последние нагреваются одинаково.</p>
                <p>Эта система не намного сложнее первой, придется покупать большее количество материалов, так как подводить трубы придется к каждому радиатору.</p>
                <p>Двухтрубная система может работать по двум схемам:</p>
                <ul>
                    <li>коллекторной;</li>
                    <li>лучевой.</li>
                </ul>
                <img src={img3} alt='' />
                <p>Лучевой вариант разводки более старый. В этом варианте подающая труба устанавливается в верхней точке дома, после чего делается разводка труб на каждую батарею. Благодаря такой конструкции схема и получила название – лучевая.</p>
                <p>Первая схема работает следующим образом: на чердаке необходимо установить коллектор (специальное устройство, состоящее из множества труб), который распределяет теплоноситель по трубам отопления. В этом же месте нужно установить запорную арматуру, которая будет отсекать контуры. Такая конструкция достаточно удобная, она облегчает ремонт всей линии и даже отдельного радиатора. Схема хотя и надежна, но у нее есть один существенный недостаток – сложный монтаж с большим количеством материалов (запорные арматуры, трубы, датчики, устройства контроля). Коллекторная схема разводки труб отопления схожа с лучевой, но более сложная и эффективная.</p>
                <p>В отличие от однотрубной системы, двухтрубная не нуждается в дополнительной принудительной циркуляции теплоносителя. Она и без насоса показывает высокую эффективность.</p>

                <h2>Какие трубы выбрать для оборудования отопительной системы?</h2>
                <p>Прежде чем проводить проектирование и монтаж систем отопления и водоснабжения надо определиться с типом используемых труб. Ведь каждый из видов имеет свои особенности использования. Как правило, магистрали изготавливают из меди, стали, полипропилена, металлопластика и т.п. Рассмотрим положительные качества и недостатки каждого из типа труб более подробно.</p>
                <p><strong>Медные трубы</strong></p>
                <p>Медь – материал сравнительно мягкий. Легко поддается обработке. Такие магистрали на вид достаточно привлекательны, поэтому прятать, красить их необязательно. Весят трубы из меди мало, что облегчает процесс монтажа. Эксплуатационный срок составляет около века. Но из-за такого минуса, как высокая цена монтаж систем отопления из медных труб может себе позволить не каждый. Также такие магистрали не сочетаются с алюминиевыми батареями.</p>
                <img src={img4} alt='' />
                <p><strong>Стальные батареи</strong></p>
                <p>Сталь оцинкованная – материал, который отличается высокой устойчивостью к коррозионным процессам. Так как трубы из стали не ржавеют, красить их не нужно. Из минусов можно назвать то, что есть ряд ограничений в использовании. Количество стыков большое, что увеличивает вероятность протечек. Из недостатков можно назвать и высокие цены на монтаж системы отопления: для установки необходимы специальные дорогостоящие приспособления.</p>
                <p><strong>Полипропиленовые и полиэтиленовые трубы</strong></p>
                <p>Полипропиленовые трубы устанавливать легко. Такие магистрали отлично сочетаются с элементами из других материалов. Можно отметить и демократичную стоимость. Из-за такого преимущества, как низкая цена монтаж отопления из полипропилена выбирают большинство хозяев домов. Но имеются некоторые недостатки. Например, изгибать трубу практически невозможно. Для подобных целей используют фитинги. Под воздействием высоких температур – более +95 градусов — материал начинает плавиться. В связи с этим лучше отдавать предпочтение армированным изделиям.</p>
                <p>Полиэтиленовые трубы имеют те же характеристики, что и полипропиленовые. Идеально подходят для создания системы теплого пола. Правда имеют еще один недостаток – необходимость использования при монтаже специального оборудования, стоит которое недешево. Также самостоятельно установить такой трубопровод не получится. А это дополнительные расходы, причем немалые. Ведь на монтаж отопления цена прайс недешевая.</p>
                <p><strong>Металлопластиковые трубы</strong></p>
                <p>Изделие из данного материала можно легко гнуть, поэтому монтаж отопления из металлопластиковых труб отличается удобством. Идеальный вариант для использования на участках со сложной конфигурацией. Металлопластик устойчив к влиянию низких температур. Минус один – для обжима фитингов понадобится специальное приспособление, но оно недорогое.</p>
                <p>Ответ на вопрос, какими трубами сделать монтаж отопления, зависит от предпочтений потребителя. Если на первом месте стоит долговечность конструкции, то трубопровод из меди либо металлопластика – оптимальный вариант. А если бюджет ограничен, то можно приобрести полипропиленовые изделия.</p>

                <h2>Установка отопительной системы</h2>
                <p>Вначале проводят монтаж нагревательного котла. Для него обустраивается бетонная или асбестоцементная подставка. После установки дымовой трубы все стыки и швы между ней и котлом герметизируют глиной. Цемент может потрескаться.</p>
                <p>Монтаж радиаторов отопления в комнатах начинается с установки кронштейнов, прикрепляемых дюбелями к внешней стене дома. Оптимальный вариант расположения отопительных приборов — подоконная зона. Основные нормы установки отопительных приборов:</p>
                <ul>
                    <li>удаленность от вертикальной поверхности, т. е. стены, — не менее 20 мм;</li>
                    <li>удаленность от нижней горизонтальной поверхности, т. е. пола, — от 70 до 150 мм.</li>
                </ul>
                <p>Монтаж радиаторов отопления проводят, соблюдая основные правила установки:</p>
                <ul>
                    <li>предварительная разметка под крепеж;</li>
                    <li>установка всех приборов на одинаковой высоте;</li>
                    <li>отсутствие уклонов, которые затруднят прохождение воды;</li>
                    <li>распаковка радиаторов только после установки и окончательного тестирования системы в целом.</li>
                </ul>

                <h2>Монтаж трубопровода</h2>
                <p>После установки радиаторов начинают монтаж труб отопления, а также других узлов. Сначала врезается сливная труба для воды. Она крепится в самой нижней точке системы между главным стояком и котлом. На 3 м (и более) выше котла устанавливается демпферная емкость. Оба узла снабжаются шаровыми кранами для перекрывания воды.</p>
                <p>Тепловой насос нужно установить со стороны притока отработанной воды в котел. Циркуляция настраивается соответствующим образом. На случай повреждения насоса нужно смонтировать дополнительные трубы в его обход, снабдив их шаровыми кранами. При необходимости эти трубы будут нести основную нагрузку во время ремонта или замены нагревательного котла.</p>
                <p>Установка шаровых кранов на самостоятельных составляющих отопительной системы обязательна. Это позволит производить мелкий ремонт и замену поврежденных элементов без особых проблем. Если в системе монтируется более 1 магистрального стояка, разводки труб снабжаются специальными вентилями. Это позволит сбалансировать гидравлическое сопротивление во всех ответвлениях отопительной системы.</p>
                <p>Когда монтаж системы отопления частного дома закончен, производится первый пробный запуск всей конструкции. Делать это нужно исключительно под наблюдением специалиста. Это позволит избежать серьезных аварий.</p>

                <h2>Газовое отопление</h2>
                <p>Монтаж газового отопления в небольшом доме или коттедже проводится в следующем порядке:</p>
                <ul>
                    <li>проведение расчетов и оформление проектных документов;</li>
                    <li>покупка требуемого оборудования;</li>
                    <li>монтирование и предварительное тестирование системы;</li>
                    <li>запуск.</li>
                </ul>
                <p>При проектировании нужно учитывать такие технические характеристики, как:</p>
                <ul>
                    <li>основные параметры котла;</li>
                    <li>производительность насоса;</li>
                    <li>мощность нагревательных приборов;</li>
                    <li>вероятность обустройства воздушного отопления и подогрева воды.</li>
                </ul>
                <p>Газовые отопительные котлы настенного образца удобнее для небольших построек. Их подвешивают на несущую стену без обустройства специального помещения. Напольные котлы устанавливают в особую комнату-котельную. Необходимое дополнительное оборудование приобретается в комплекте с котлом. Техникой безопасности запрещается монтаж газовых котлов в подвальных и цокольных помещениях.</p>
                <p>При желании можно обустроить газовую печь. Она имеет кирпичный корпус, устанавливаемый на бетонном фундаменте. К корпусу подсоединяется дымоходная труба.</p>
                <p>По характеру разводки (одно- или двухтрубная) подбирается газовый котел одноконтурного или двухконтурного образца. Одноконтурный котел дополняется устройством для нагревания воды под высоким давлением, т. е. бойлером. Особые выводы, подведенные к баку, служат для обустройства системы подачи нагретой воды.</p>
                <p>При установке двухконтурного котла, нагревание воды происходит во внутреннем парогенераторе. Дополнительно можно подключить воздушное отопление. Посредством установки  двухконтурного котла можно повысить эффективность обогрева помещений и снизить себестоимость оборудования.</p>
                <p>Вода разогревается в теплообменнике котла и закачивается по трубам в радиаторы. Перемещение воды обеспечивается насосом по принципу принудительной циркуляции. Отопительная установка с естественным кругооборотом воды предполагает монтаж демпферной емкости на максимально возможной высоте.</p>
                <p>Установка программируемого устройства облегчит управление газовой системой отопления. С его помощью задается требуемая температура, время работы оборудования и другие параметры.</p>
                <p>Монтаж отопления Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем отопления по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default HeatingInstallation