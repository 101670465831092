import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './precisionAirConditioners.css'

import precizion from '../../../img/1-precizion.jpg'
import zabora from '../../../img/24371-shema-zabora.jpg'

const PrecisionAirConditioners = () => {
    return (
        <>
            <div className='precision-air-conditioners-page'>
                <h1>Прецизионные кондиционеры</h1>
                <p>Прецизионные кондиционеры Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку прецизионных кондиционеров по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <p>Прецизионные кондиционеры являются разновидностью колонных (шкафных) кондиционеров. Они относятся к промышленным кондиционерам и применяются для обработки воздуха в помещениях, где требуется строгое соблюдение параметров воздуха для обеспечения сохранности ценных экспонатов в музеях или обеспечения работы точных приборов в лабораториях и в сфере высокотехнологичного производства.</p>
                <p>«Прецизионный» от английского слова «precision» - точный. Это автономный кондиционер колонного типа для точного поддержания параметров воздуха по температуре, относительной влажности, подвижности воздуха в обслуживаемом помещении.</p>
                <p>Прецизионные системы кондиционирования позволяют поддерживать требуемые температурно-влажностные параметры воздуха в помещении.</p>
                <h2>Область применения:</h2>
                <ul>
                    <li>Телефония (станции АТС, телекоммуникационные узлы, биллинговые центры);</li>
                    <li>Медицина (диагностические помещения, операционные, лаборатории);</li>
                    <li>Высокоточное производство (чистое производство в микроэлектронике, космической промышленности, фармацевтике);</li>
                    <li>Сектор информационных технологий (серверные помещения, аппаратные помещения, компьютерные залы, банки данных);</li>
                    <li>Музеи и библиотеки.</li>
                </ul>

                <h2>Задачи прецизионных кондиционеров</h2>
                <p>Основная задача прецизионного оборудования – это точное поддержание параметров микроклимата в помещении. Современные прецизионные кондиционеры могут обеспечить соблюдение следующих параметров:</p>
                <ul>
                    <li>точность поддержания температуры (+/- 0,5 °С);</li>
                    <li>точность поддержания влажности (+/- 2%);</li>
                    <li>возможность работы в широком диапазоне температур наружного воздуха от - 50 °С до + 45 °С;</li>
                    <li>схема работы 24 х 7 х 365, что означает работу  24 часа в сутки, 7 дней в неделю и 365 дней в году вне зависимости от внешних климатических условий;</li>
                    <li>полная совместимость с системами диспетчеризации зданий.</li>
                </ul>
                <p>Прецизионный кондиционер – это парокомпрессионная холодильная установка, работающая по обратному холодильному циклу (циклу Карно), в котором работа сжатия превышает работу расширения и за счет подведенной работы теплота передается от холодного источника к горячему. Основные элементы холодильного контура: компрессор, конденсатор, регулятор потока хладагента, испаритель и рабочее тело - хладагент (ГОСТ 29265—91 (ИСО 817—74)) – это все, чем похож прецизионный кондиционер на кондиционеры, бытового, полупромышленного или промышленного назначения. Прецизионные кондиционеры имеют множество исполнений и модификаций.</p>

                <h2>Виды кондиционеров прецизионного типа</h2>
                <p>Внешне прецизионный кондиционер похож на шкаф, что имеет отверстия для отбора нагретого воздуха из помещения и подачи холодных воздушных масс. Разнятся между собой они как конструктивным исполнением так и распределением воздуха. И выбирают их в зависимости от назначения.</p>
                <ul>
                    <li>функциональные исполнения Имеется функция охлаждения воздуха (-) Присутствует и охлаждение, и увлажнение воздуха (C) Охлаждение, нагрев и увлажнение воздуха (T). Нагревается воздух электрическими нагревателями.</li>
                    <li>по конструктивному изготовлению С охлаждением конденсатора водой (V), для общего функционирования с водоохладителями градирнями открытого или закрытого типа. Дороже в устройстве, но дешевле в эксплуатации. Без конденсатора (B), работают с выносным конденсаторам На охлажденной воде (C), совестно могут работать с чиллерами и фанкойлами.С инверторным компрессором.</li>
                </ul>
                <img src={precizion} alt='' className='img-width' />
                <ul>
                    <li>по поступлению и отводу воздушных масс из комнаты Отбор воздуха спереди и введение вперед (S) С верхним отбором и нижним поступлением воздушных масс (SX) Фронтальный отвод, подача вверх (QS)</li>
                </ul>
                <img src={zabora} alt='' className='img-width' />

                <h2>Преимущества</h2>
                <p>Как следует из самого названия, прецизионные являются кондиционерами, которые способны очень точно (прецизионно) регулировать параметры воздуха в помещении. Это обеспечивается тем, что они оборудованы системами точного микропроцессорного управления и способны очень точно поддерживать температуру, а при заказе кондиционера в исполнении с блоком регулирования влажности кондиционер способен поддерживать заданную влажность. Такие кондиционеры применяются в помещениях, где, наряду с кондиционированием воздуха, необходимо регулировать влажность прецизионные обладают следующими отличительными характеристиками:</p>
                <ul>
                    <li>точность поддержания температуры (+/-1°C);</li>
                    <li>точность поддержания влажности (+/2%);</li>
                    <li>повышенная надежность работы при непрерывной эксплуатации;</li>
                    <li>возможность работы в широком диапазоне температур наружного воздуха (до минус 35°С);</li>
                    <li>полная совместимость с системами диспетчерского контроля и системами управления микроклиматом здания.</li>
                </ul>

                <h2>Особенности</h2>
                <p>Прецизионные кондиционеры не являются массовым продуктом, это специальная техника, которая предназначена для решения специальных задач, соответственно изготавливаются в различных модификациях. Простая модификация обеспечивает только охлаждение, более сложные способны обеспечить регулирование и температуры и влажности воздуха в помещении.</p>
                <p>Эти кондиционеры, как и большинство промышленных кондиционеров, поставляются (и изготавливаются) под заказ. При формировании заказа учитываются параметры помещения и требуемые параметры воздуха, возможность подключения кондиционера к внешней охлаждающей машине (чиллеру) и другие параметры, исходя из этих данных, и подбирается прецизионный кондиционер для Вашего помещения.</p>
                <p>Практически все кондиционеры могут выполняться с нижней или верхней подачей подготовленного воздуха. Приток воздуха в кондиционер обеспечивается либо непосредственно из помещения, либо через небольшой патрубок из пространства подвесного потолка или системы воздуховодов. Воздух из помещения может также забираться через лицевую панель кондиционера. Установленный в кондиционере фильтр особенно необходим при непосредственной подаче обработанного воздуха в электронное оборудование.</p>
                <p>В кондиционерах с верхней подачей воздух подается либо непосредственно в помещение, либо системой воздуховодов через свободное пространство потолка. Воздух из помещения может забираться через лицевую панель, или нижнюю, или заднюю панель.</p>
                <p>Кондиционер может дополнительно оснащаться воздухораспределительной камерой, направляющей поток воздуха в помещение и устанавливаемой на верхнюю панель кондиционеров.</p>

                <h2>Схемы работы</h2>
                <p>Существуют четыре основных схемы работы:</p>
                <ul>
                    <li>Компрессор находится во внутреннем блоке и соединен с выносным конденсатором фреоновым контуром.</li>
                    <li>Во внутреннем блоке смонтированы компрессор, конденсатор, испаритель. Отвод тепла производится посредством воды в градирне.</li>
                    <li>Подключение к чиллеру. В этом варианте во внутреннем блоке отсутствует компрессор, и он работает аналогично фанкойлу.</li>
                    <li>Подключение к проточной воде. Компрессор находится внутри блока, тепло отдается проточной воде.</li>
                </ul>
                <p>Кондиционеры прецизионного типа поглощают прохладный воздух снаружи, затем он обрабатывается в приточной среде, после чего выходит в помещение с заданной температурой, влажностью и скоростью движения воздуха.</p>
                <p>В результате такие прецизионные кондиционеры являются своеобразным гибридом атмосферной установки и вентиляционной системы комнаты. В зависимости от вида охлаждения и числа контуров выделяют несколько принципов работы прецизионных установок.</p>
                <p>Устройства с воздушным блоком охлаждением обозначены более традиционной схемой охлаждающего цикла, аналогичного с работой сплит-системы. Фреон под напором уменьшается в компрессоре и переходит в конденсатор уже в жидком состоянии.</p>
                <p>Затем он двигается через терморегулирующий вентиль в испаритель, где снова переходит в газообразное состояние и попадает опять в компрессор. В связи с этим воздух охлаждается при движении через испаритель и выходит наружу.</p>
                <p>Этот вид работы установок почти не отличается от функционирования изделия с кулером. Только лишь охлаждение устройства получается не воздушное, а водяное.</p>
                <p>Во внутреннем блоке размещается теплообменник, к которому снаружи для охлаждения подключен драйкулер с водяной помпой. Через испаритель передается теплый воздух и затем совершается сброс наружу благодаря встроенному вентилятору.</p>
                <p>Если устройство с водяным охлаждением, то кондиционер, как правило, сочетается с чиллером (охлаждающей установкой)</p>
                <p>Прецизионные кондиционеры Вы можете заказать, позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку прецизионных кондиционеров по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default PrecisionAirConditioners