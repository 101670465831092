import React from 'react'
import { Link } from 'react-router-dom'
import './dehumidification.css'

import i2small from '../../img/2_small.jpg'
import danvex_small from '../../img/danvex_small.png'
import calorex_small from '../../img/calorex_small.png'
import i137302izmerenie from '../../img/137302izmerenie-i-normy-urovnya-vlazhnosti.jpg'
import o68648princip from '../../img/68648princip-raboty-osushitelya.jpg'
import o65791bytovoj from '../../img/65791bytovoj-osushitel.jpg'
import o67504promyshlennyj from '../../img/67504promyshlennyj-osushitel.jpg'
import o43175osushitel from '../../img/43175osushitel-dlya-bassejnov.jpg'
import o62854princip_raboty_osushitelei_danvex_ad from '../../img/62854princip_raboty_osushitelei_danvex_ad.jpg'
import Form from '../form/Form'

const Dehumidification = () => {
    return (
        <>
            <div className='dehumidification-component'>
                <h1>Осушение воздуха в помещении</h1>
                <p>Промышленные осушители Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка систем осушения воздуха по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <p>Влажность воздуха — важный показатель микроклимата, напрямую влияющего на самочувствие и работоспособность человека. Поэтому при разработке санитарных норм, влажности уделено не меньше внимания, чем температуре. Но эти показатели не всегда соответствуют принятым требованиям. Увеличить или уменьшить влажность воздуха в помещении помогают специально разработанные для этих целей приборы. Также есть способы, которые можно назвать «народными».</p>
                <img src={i2small} alt='' style={{ marginBottom: '20px' }} />

                <h2>Производители осушителей</h2>
                <div className='products-wrapper'>
                    <Link to=''>
                        <div className='product'>
                            <p>Danvex</p>
                            <div className='bg' style={{ backgroundImage: `url(${danvex_small})` }}></div>
                        </div>
                    </Link>
                    <Link to=''>
                        <div className='product'>
                            <p>Calorex</p>
                            <div className='bg' style={{ backgroundImage: `url(${calorex_small})` }}></div>
                        </div>
                    </Link>
                </div>

                <h2>Измерение и нормы уровня влажности</h2>
                <p>При оценке состояния атмосферного воздуха необходимо ориентироваться на требования ГОСТ и СНиП. Допустимый и оптимальный уровень влажности в помещениях разного назначения регламентирован ГОСТ 30494-96. Для оценки состояния воздуха используется соотношение количества водяных паров к объему помещения. Таким образом узнают относительную влажность воздуха, исчисляемую в процентах. Допустимый диапазон — 40-60%.</p>
                <p>Оптимальные значения для помещений:</p>
                <ul>
                    <li>кухня — 40-60%</li>
                    <li>спальня — 40-50%</li>
                    <li>комнаты с компьютерной и электронной техникой — 30-45%</li>
                    <li>детские — 45-60%</li>
                </ul>
                <img src={i137302izmerenie} alt='' className='img1' />
                <p>Уровень влажности напрямую зависит от температуры воздуха. Поэтому оптимальные значения меняются в зависимости от сезона. В холодное время года допустимые показатели — 30-45%, в теплое — 30-60%.</p>
                <p>Для измерения используют бытовые и профессиональные гигрометры. Эти приборы бывают нескольких видов:</p>
                <ul>
                    <li>волосные;</li>
                    <li>конденсационные;</li>
                    <li>керамические;</li>
                    <li>весовые;</li>
                    <li>пленочные.</li>
                </ul>
                <p>Бытовые гигрометры выпускают в виде настольных или настенных приборов. Помимо своей основной функции, они могут быть оснащены дополнительными опциями:</p>
                <ul>
                    <li>показывать время и дату;</li>
                    <li>атмосферное давление;</li>
                    <li>температуру воздуха.</li>
                </ul>
                <p>Комплексные приборы, сочетающие в себе функции барометра и термометра, выгоднее тем, что позволяют осуществлять полноценный контроль за состоянием воздуха.</p>
                <p>Разнообразие моделей настолько велико, что не составит труда выбрать наиболее гармонично сочетающийся со стилем конкретного помещения.</p>
                <p>Альтернативные методы измерения влажности</p>
                <ol>
                    <li>В аптеках реализуют психрометрические гигрометры, показания которых вычисляют с помощью специальной таблицы Ассмана (входит в комплектацию).</li>
                    <li>Понять, каков уровень влажности воздуха, можно с помощью обычного стакана с водой. Его ставят в холодильник и охлаждают до -3-5°С. Переносят в комнату и через 5 минут оценивают ситуацию. Если конденсат на стенках стакана полностью высох — воздух сухой, влажность ниже нормы. Если остались капли среднего размера — соответствие норме. Если по стенкам стекают ручьи воды — влажность повышена.</li>
                </ol>

                <h2>Простые методы снижения концентрации влаги</h2>
                <p>Осушить воздух в помещении можно разными способами. Прежде чем решать, каким именно, рекомендуется найти причину повышенного уровня влажности. Это необходимо в том случае, если различие между состоянием воздуха на улице и в квартире/доме значительное.</p>
                <p>Сырость в большинстве случаев является результатом плохой вентиляции.</p>
                <p>Поэтому для нормализации микроклимата в первую очередь устраивают качественный воздухообмен между помещениями и улицей. Циркуляцию воздушных масс обеспечивают с помощью бытовых приборов:</p>
                <ul>
                    <li>вентиляторов;</li>
                    <li>кондиционеров;</li>
                    <li>кухонных вытяжек.</li>
                </ul>
                <p>Если необходимо осушение не только воздуха, но и стен, используют тепловые пушки и обогреватели. При сезонных изменениях уровня влажности и в случаях ее периодического повышения, хороший способ осушения — применение специальных таблеток. Эти препараты устанавливают в небольшое устройство, которое подключают к электросети. Самые популярные таблетки;</p>
                <ul>
                    <li>«Стоп Влага» от Ceresit;</li>
                    <li>«Stop-Влажность» от Henkel.</li>
                </ul>
                <p>Но ни одни таблетки не дадут столь быстрого и долговременного эффекта, как специализированное оборудование, разработанное с целью нормализации микроклимата в жилых и общественных помещениях.</p>

                <h2>Принцип работы и строение устройства осушителя</h2>
                <p>В основе деятельности любого осушителя заложен эффект конденсации – противоположный испарению, когда вещество переходит из газообразного состояния в жидкое.</p>
                <p>Работает такой бытовой прибор просто: вентиляторы гонят влажный воздух внутрь устройства, где он попадает под действие испарителя – радиатора. Температура испарителя всегда ниже параметра климата в помещении, поэтому из-за резкого перепада температуры проходящий влажный воздух преобразуется в конденсат, стекающий в специальную емкость. После этого достаточно охлажденный воздух начинает нагреваться до температурного режима комнаты и выводится через специальное отверстие в комнату.</p>
                <img src={o68648princip} alt='' className='img1' />
                <p>Воздух, попадая в осушительный прибор, проходит три стадии: охлаждение, конденсат, подогрев.</p>
                <p>Как устроен современный осушитель? Конструкция осушительного бытового прибора состоит из четырех основных элементов: испарителя, компрессора, конденсора и капиллярной трубы.Но конструкция может отличаться у различных типов такого прибора.</p>

                <p><strong>Ассимиляционный осушитель воздуха</strong></p>
                <p>Этот тип применяется практически только на производстве, когда требуется осушать большие объемы воздуха. Работа осушителя воздуха ассимиляционного типа заключается в обеспечении постоянного воздухообмена, в результате которого влажный воздух из помещения выводится, уступая место более сухому воздуху, подаваемому снаружи.</p>
                <p>Главным недостатком данного осушителя является тот факт, что он наименее энергоэффективен из всех типов. Дело в том, что вместе с удаляемым нагретым воздухом выбрасывается «в трубу» много ценного тепла, плюс к этому надо добавить затраты энергии на обработку подаваемого воздуха. Кроме того, ассимиляционный осушитель воздуха просто не будет работать в местности, где постоянно наблюдается высокий уровень влажности воздуха, например, на морском побережье.</p>

                <p><strong>Адсорбционный осушитель воздуха</strong></p>
                <p>Адсорбционный осушитель воздуха, наряду с конденсационным, относится к числу наиболее распространенных типов осушителей. Принцип действия осушителя воздуха адсорбционного типа основан на способности определенных веществ поглощать (адсорбировать) влагу непосредственно из воздуха. Самым известным для потребителей веществом с подобными свойствами является силикагель, который, наверное, каждый из нас видел в коробках с новой обувью - такие маленькие пакетики.</p>
                <p>Правда, просто впитать водяной пар мало, т.к. адсорбирующий материал нужно периодически освобождать от собранной влаги, чтобы вернуть ему водопоглощающие свойства. Чаще всего в адсорбционных осушителях воздуха для обеспечения циклического процесса поглощения-регенерации используют специальные вращающиеся барабаны или роторы. Барабан чаще всего изготавливается из профилированного алюминия, образующего осевые капилляры, поверхность которых покрыта влагопоглощающим веществом.</p>
                <p>Влажный воздух проходит через такой ротор и оставляет на нем излишки водяного пара, которые адсорбируются, а воздух выходит из осушителя уже сухим. Ротор приводится в движение электродвигателем, который обеспечивает его вращение. Надо отметить, что влажный воздух подается только на часть поверхности ротора - один из сегментов барабана в этом процессе не задействован.</p>
                <p>Именно в этом сегменте происходит процесс отбора из адсорбирующего материала излишков влаги для восстановления его работоспособности. Для этого на данный сегмент подается встречный поток нагретого до 120°C воздуха, который и «собирает» в себя накопленную влагу. Полученный влажный теплый воздух выводится за пределы осушаемого помещения. А ротор оказывается опять готовым к работе.</p>
                <p>Важно, чтобы адсорбирующий состав ротора мог выдержать значительное количество циклов адсорбции-регенерации. Для нагрева регенерационного воздуха применяют паровые, электрические или газовые нагреватели.</p>
                <p>Адсорбционный осушитель воздуха менее эффективен, чем конденсационный, зато, в отличие от последнего, способен осушать воздух, имеющий низкую температуру. Поэтому устройства данного типа используются для осушения холодных помещений. Например, холодильных камер или ледовых арен.</p>

                <p><strong>Конденсационный осушитель воздуха</strong></p>
                <p>Пожалуй, это самый распространенный тип осушителей воздуха. Принцип действия конденсационного осушителя воздуха основан на свойстве воздуха самостоятельно избавляться от излишков влаги в процессе охлаждения. При охлаждении воздуха снижается максимально возможное количество влаги, которое он способен в себе содержать, и лишняя влага выпадает в виде конденсата.</p>
                <p>Вероятно, каждый из нас знаком с этим физическим процессом. Именно вследствие этого образуется утренняя роса - воздух за ночь остывает и конденсирует излишки влаги в виде капелек воды. Как видите, все очень просто.</p>
                <p>Поэтому главной задачей конденсационного осушителя воздуха является охлаждение осушаемого воздуха. Охлаждение воздуха в таком осушителе происходит так же, как и в обычном кондиционере, поэтому их устройство схоже. По сути, конденсационный осушитель воздуха представляет собой кондиционер, в котором не реализована функция отвода тепла из помещения.</p>
                <p>Осушаемый воздух последовательно проходит через испаритель (или холодный теплообменник), где охлаждается ниже точки росы. При этом влага из воздуха конденсируется на ребрах испарителя. А затем через конденсатор (или горячий теплообменник), в котором осушенный холодный воздух нагревается и затем выводится из осушителя с температурой незначительно выше, чем была на входе. Накопленный конденсат или собирается в специальную емкость, или отводится в канализацию.</p>
                <p>Это наиболее эффективный и недорогой способ осушения воздуха. Но тоже имеющий свои недостатки. У конденсационных осушителей воздуха ограниченный диапазон рабочих температур, так как, если температура воздуха в помещении уже довольно низкая, охладить его еще больше просто нельзя - сконденсированная вода замерзнет.</p>

                <h2>Какие бывают осушители?</h2>
                <p>Осушители воздуха различаются по их применению и могут быть элементом вентиляции, бытовым прибором и климатическим комплексом, выполняющим сразу несколько функций.</p>
                <p>Сейчас выбрать осушитель можно для различных целей и в помещениях с разным назначением.</p>

                <p><strong>Бытовые</strong></p>
                <p>Такие приборы могут быть использованы в квартирах, домах, где не требуется высокой производительности. Могут быть установлены в жилых помещениях, ванных комнатах, офисах, гаражах, небольших торговых точках. Основные требования к таким приборам – мобильность. Прибор не может быть огромных размеров, он должен быть компактным, чтобы человек смог запросто переносить его самостоятельно в другое помещение. Бытовые влагоудалители могут удалить за сутки более 12 литров воды. Они не требуют установки и могут оснащаться дополнительными функциями с разнообразными внешними характеристиками и интересным дизайном.</p>
                <img src={o65791bytovoj} alt='' className='img1' />

                <p><strong>Промышленные</strong></p>
                <p>Такие приборы просто незаменимы на производстве. Например, они облегчат работу в прачечных, где конденсат от мокрого белья просто зашкаливает. Не обойтись без них на любой строительной площадке, так как они могут быстро высушить материал для отделки. Производительность таких осушителей очень высока, они удаляют за короткое время тысячи литров воды и не перестают работать даже при минусовых температурах.</p>
                <img src={o67504promyshlennyj} alt='' className='img1' />

                <p><strong>Для бассейнов</strong></p>
                <p>Все аквапарки, бассейны, сауны страдают от высокой влажности воздуха. Чтобы прекратить испарение воды, устанавливают специальные влагоудалители, которые делают воздух комфортным для посетителей этих заведений.</p>
                <img src={o43175osushitel} alt='' className='img1' />
                <p>По способу размещения различают настенный, напольный и настольный осушители.</p>
                <p>Различаются осушители и по принципу работы.</p>
                <p>Все приборы рассматриваются с точки зрения механизма выведения влаги из проходящего через осушитель воздуха.</p>
                <ol>
                    <li>Устройства, которые могут использовать метод ассимиляции – воздухообмена. В этих приборах влага будет выходить на улицу, а с улицы начнет поступать свежий воздух. Затраты при таком приборе будут огромные, а для сильно влажных помещений подобный осушитель не подойдет. Особенность этого прибора основана на правиле физики – теплый воздух привлекает к себе большее количество влаги, поэтому в таком приборе он просто выводится за пределы помещения.</li>
                    <li>Осушители с адсорбционным действием. Влажный воздух проходит через специальные вещества – сорбенты, которые имеют пористую структуру. Такие устройства отлично используются в производственных помещениях, туннелях, ледовых площадках, в помещениях типа склада, где хранятся овощи – фрукты. Недостаток этих влагоудалителей недолгое использование сорбентов.</li>
                    <li>Конденсационные осушители воздуха. Их принцип работы сильно похож на деятельность кондиционеров. Воздух, поступая внутрь охлаждается, а влага превращается в конденсат. Только при минусовых температурах его нельзя использовать. На выходе воздух будет теплее температуры помещения градусов на четыре. Для больших помещений особых изменений вы не заметите, а вот в маленькой комнате создается не совсем комфортный климат.</li>
                </ol>

                <p><strong>Как снизить влажность на складе - варианты решения проблемы</strong></p>
                <p>Во-первых, для решения указанной проблемы необходимо экстренное вмешательство в конструкцию вентиляции. Неорганизованный приток воздуха двойной кратности нивелирует саму возможность снижения влажности до 30-40 %. Поэтому, в первую очередь, необходимо заменить естественный приток на механический.</p>
                <p>Во-вторых, обустроив механический приток, можно переходить к контролю температуры и влажности во «входящем» потоке. Ведь подогревая холодный воздух, отбираемый с улицы в зимнее время, мы отбираем большую часть водяных паров. Поэтому в приточную ветвь придется интегрировать фанкойл или канальный кондиционер, усилив эти агрегаты адсорбционным осушителем.</p>
                <img src={o62854princip_raboty_osushitelei_danvex_ad} alt='' className='img1' />
                <p><i>Решив проблему зимнего осушения, необходимо позаботится и о летнем варианте, когда конденсация паров путем нагрева приточного потока будет уже не возможна. В этом случае нам придется охлаждать приточный поток, разогревая эту среду впоследствии, перед «закачиванием» в склад. Для этого нам понадобится пара двухконтурных фанкойлов, работающих на первичное охлаждение (температура охладителя 8-9 °С) и последующий  обогрев потока до заданной температуры 22-24 градуса Цельсия.</i></p>
                <p>В-четвертых, в самом помещении необходимо установить моноблочные осушители, производительностью 2000-3000 м3/час, закрепив их над проблемными зонами на высоте 5-6 метров от уровня пола. Причем эти агрегаты  должны работать лишь с «внутренним» воздухом, черпая осушаемую среду прямо из помещения.</p>
                <p>В-пятых, для снижения уровня влажности до нижнего предела – 30 % – понадобятся не только вышеописанные агрегаты, но и уменьшение кратности воздухообмена. В этом случае замедляется скорость приточного потока и череда фанкойлов, кондиционеров и  адсорбционных осушителей успевает «отобрать» нужный объем водяного пара.</p>
                <p>Промышленные осушители Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Проектирование и поставка систем осушения воздуха по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default Dehumidification