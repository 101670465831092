import React from 'react'
import { Link } from 'react-router-dom'
import logoPng from '../../img/logo.png'
import iconCond from '../../img/cond.png'
import iconVent from '../../img/vent.png'
import iconAvto from '../../img/avto.png'
import iconHeat from '../../img/heat.png'
import iconUvl from '../../img/uvl.png'
import iconOsush from '../../img/osush.png'
import './navbar.css'
import { useDispatch, useSelector } from 'react-redux'
import { menu } from '../../redux/actions/actions'

const Navbar = () => {

    const { menu: menuName } = useSelector(state => state.menu)

    const dispatch = useDispatch()
    const changeMenu = (name) => {
        dispatch(menu(name))
    }

    return (
        <div className='navbar-component'>
            <div className='top-navbar'>
                <Link to='/' onClick={() => changeMenu('cond')}>
                    <div className='logo-wrap'>
                        <img src={logoPng} alt='' className='logo' />
                        <h2>FIDCLIMAT</h2>
                    </div>
                </Link>
                <div className='phone-wrap'>
                    <span>Звоните: Пн-пт: 09.00 — 18.00</span>
                    <a href='tel:+998903559091'>+998 (90) 355-90-91</a>
                </div>
                <div className='address-wrap'>
                    <Link to='/form'>
                        <span>Заказать звонок</span>
                    </Link>
                    <Link to='/contact'>
                        <span>Uzbekistan, Tashkent, Sergeli</span>
                    </Link>
                    <a href='tel:+998903559091' className='tel'>+998 (90) 355-90-91</a>
                </div>
            </div>
            <div className='links-wrapper'>
                <Link to='/equipment-catalog' onClick={() => changeMenu('other')}>Каталог оборудования</Link>
                <div className='line'></div>
                <Link to='/engineering-systems' onClick={() => changeMenu('other')}>Инженерные системы</Link>
                <div className='line'></div>
                <Link to='/our-objects' onClick={() => changeMenu('other')}>Наши объекты</Link>
                <div className='line'></div>
                <Link to='/about-company' onClick={() => changeMenu('other')}>О компании</Link>
                <div className='line'></div>
                <Link to='/delivery-payment' onClick={() => changeMenu('other')}>Доставка и оплата</Link>
                <div className='line'></div>
                <Link to='/guarantee' onClick={() => changeMenu('other')}>Гарантия</Link>
                <div className='line'></div>
                <Link to='/discount-stock' onClick={() => changeMenu('other')}>Скидки и акции</Link>
                <div className='line'></div>
                <Link to='/contact' onClick={() => changeMenu('other')}>Контакты</Link>
            </div>
            <div className='menus-wrapper'>
                <Link to='/' onClick={() => changeMenu('cond')}>
                    <div className={menuName === 'cond' ? 'menu active-menu' : 'menu'}>
                        <img src={iconCond} alt='' />
                        <p>Кондиционирование</p>
                    </div>
                </Link>
                <Link to='/ventilation' onClick={() => changeMenu('vent')}>
                    <div className={menuName === 'vent' ? 'menu active-menu' : 'menu'}>
                        <img src={iconVent} alt='' />
                        <p>Вентиляция</p>
                    </div>
                </Link>
                <Link to='/automation' onClick={() => changeMenu('avto')}>
                    <div className={menuName === 'avto' ? 'menu active-menu' : 'menu'}>
                        <img src={iconAvto} alt='' />
                        <p>Автоматика</p>
                    </div>
                </Link>
                <Link to='/heating' onClick={() => changeMenu('heat')}>
                    <div className={menuName === 'heat' ? 'menu active-menu' : 'menu'}>
                        <img src={iconHeat} alt='' />
                        <p>Отопление</p>
                    </div>
                </Link>
                <Link to='/moisturizing' onClick={() => changeMenu('uvla')}>
                    <div className={menuName === 'uvla' ? 'menu active-menu' : 'menu'}>
                        <img src={iconUvl} alt='' />
                        <p>Увлажнение</p>
                    </div>
                </Link>
                <Link to='/dehumidification' onClick={() => changeMenu('osush')}>
                    <div className={menuName === 'osush' ? 'menu active-menu' : 'menu'}>
                        <img src={iconOsush} alt='' />
                        <p>Осушение</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Navbar