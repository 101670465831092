import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../form/Form'
import './ourObjects.css'

const OurObjects = () => {
    return (
        <>
            <div className='our-objects-component'>
                <h1>Наши объекты</h1>
                <ul>
                    <Link to=''>
                        <li>Офисы при складском комплексе общей площадью 650 м2</li>
                    </Link>
                    <Link to=''>
                        <li>Офис с девятью комнатами общей площадью 320 м2</li>
                    </Link>
                    <Link to=''>
                        <li>Этаж под офисные помещения в бизнес центре общей площадью 450 м2</li>
                    </Link>
                </ul>

                <h2>Список всех объектов:</h2>
                <p><strong>Государственные учреждения</strong></p>
                <ul>
                    <li>Центральный исполнительный комитет Партии "Единая Россия"</li>
                    <li>Пресс-служба ЦИК Партии "Единая Россия"</li>
                </ul>

                <p><strong>Банки</strong></p>
                <ul>
                    <li>Банк Кремлевский</li>
                    <li>Промрегионбанк</li>
                </ul>
            </div>
            <Form />
        </>
    )
}

export default OurObjects