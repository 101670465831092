import React from 'react'
import '../ventilationPages.css'
import Form from '../../../components/form/Form'
import { Link } from 'react-router-dom'

import img23 from '../../../img/23-05-2014 16-42-13.jpg'
import img39 from '../../../img/23-05-2014 16-39-58.jpg'

const ShopVentilation = () => {
    return (
        <>
            <div className='ventilation-pages'>
                <h1>Вентиляция для магазинов</h1>
                <p>Монтаж системы вентиляции для магазина Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции магазинов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>

                <h2>Требования к системам вентиляции магазинов</h2>
                <p>Система вентиляции торгового комплекса должна служить для следующих целей:</p>
                <ul>
                    <li>Очистка воздуха от механических загрязнений;</li>
                    <li>Обеспечение требуемого воздухообмена в помещении;</li>
                    <li>Нагрев или охлаждение подаваемого в помещение воздуха;</li>
                    <li>Комфортная раздача воздуха в помещениях.</li>
                </ul>
                <p>Кроме этого, система не должна создавать проблем обслуживающему персоналу, быть удобной в обслуживании, легко поддаваться регулировкам и не создавать большого шума.</p>
                <img src={img23} alt='' className='img-width' />

                <h2>Проектирование вентиляции магазинов и торговых центров</h2>
                <p>Вентиляцию и аварийную противодымную вентиляцию магазинов, торговых центров и супермаркетов следует проектировать в соответствии со СНиП.</p>
                <ul>
                    <li>При размещении в здании нескольких магазинов для каждого из них следует проектировать отдельные ветви системы вентиляции.</li>
                    <li>В продовольственных и непродовольственных магазинах торговой площадью до 400м2 допускается проектировать вентиляции с естественным побуждением.</li>
                    <li>В помещениях магазинов торговой площадью 400м2 и более, оборудованных вентиляцией с механическим побуждением, объем вытяжки должен быть полностью компенсирован.</li>
                    <li>В том случае, если проектируется вентиляция торговых центров, имеющих торговые площади в 3500м2 и более, обязательно в систему включается установка для кондиционирования воздуха. Эти требования действительны для магазинов, расположенных в средней полосе. В том случае, если торговый комплекс строится в южных областях, кондиционирование воздуха следует предусмотреть  в том случае, если торговый зал  занимает более 1000 квадратов.</li>
                    <li>Количество людей, которые находятся в торговых помещениях можно определить по технологическому заданию или по площади торгового зала, занимаемой одним человеком, а именно:</li>
                </ul>
                <p>6м2 на 1 чел.  - для магазинов мебели, музыкальных, аудио-, видео-, бытовой и оргтехники, книжных, спортивных, ювелирных;</p>
                <p>5м2 на 1 чел.  - для других непродовольственных магазинов, а также для продовольственных магазинов.</p>
                <ul>
                    <li>Количество рабочих мест кассиров в магазинах самообслуживания принимается исходя из расчета:</li>
                </ul>
                <p>в магазинах, торгующих продуктами питания, один кассир приходится на 100 м²;</p>
                <p>в непродовольственных магазинах один кассир приходится на 160 м².</p>
                <ul>
                    <li>На одного покупателя принятая норма подачи воздуха – 20 м³/ч, а на одного работника – 60 м³/ч.</li>
                    <li>При ориентации на юг, восток и юго-восток остекленных проемов торговых залов и служебных помещений магазинов допускается предусматривать установку оконных или комнатных кондиционеров.</li>
                    <li>В торговых залах магазинов, кроме торговых залов с химическими, синтетическими или пахучими веществами и горючими жидкостями, допускается применять рециркуляцию воздуха.</li>
                    <li>Системы вентиляции магазинов, встроенно-пристроенных, встроенных в жилые здания, а также в здания иного назначения или пристроенных к ним не допускается объединять с системами вентиляции этих зданий.</li>
                    <li>В помещениях кладовых следует, как правило, предусматривать естественную систему вентиляции с самостоятельными каналами.</li>
                    <li>Допускается подсоединение системы вытяжной вентиляции кладовых к общеобменной системе механической вентиляции подсобных помещений при условии установки огнезадерживающих клапанов.</li>
                </ul>
                <p>Не стоит забывать:</p>
                <p>Во время проектирования системы вентиляции многоэтажных торговых сооружений необходимо иметь ввиду два фактора:</p>
                <ol>
                    <li>На верхних этажах ниже посещаемость, чем на первом, поэтому необходимо учитывать меньшую плотность посетителей</li>
                    <li>Для привлечения покупателей на верхних этажах размещают рестораны и закусочные, что создает определенные трудности по вентиляции и отводу отработанного воздуха</li>
                </ol>
                <p>Для выполнения проекта вентиляции магазина необходимы следующие исходные данные:</p>
                <ul>
                    <li>Характеристики производственного процесса в производственных помещениях (пекарня, кухни): тип и количество производственного оборудования; тепло, пыле, газо и влаго выделения от технологических процессов</li>
                    <li>Тип, мощность и количество ламп искусственного освещения.</li>
                    <li>Технические условия на  подключение к смежным инженерным системам (электроснабжению и   теплоснабжению).</li>
                    <li>Тип воздухонагревателя - водяной или электрический.</li>
                    <li>Требуется ли охлаждение приточного воздуха летом.</li>
                    <li>Расчетное количество посетителей и служащих в магазине.</li>
                    <li>Ориентация магазина, (торгового центра) по сторонам света.</li>
                    <li>Тип, состав и характеристики окон, стен.</li>
                    <li>Наличие и тип конструктивных элементов здания: колонн, балок и  ригелей.</li>
                    <li>Предполагаемые места забора и выброса воздуха, размещения вентиляционного оборудования.</li>
                    <li>Возможность использования кровли для узлов прохода системы вентиляции.</li>
                    <li>Противопожарные решения в здании магазина, торгового центра.</li>
                </ul>
                <p>В основном торговые центры оснащаются центральными кондиционерами модульного типа. При этом состав модулей кондиционера выбирается согласно потребностям конкретного помещения.</p>
                <img src={img39} alt='' className='img-width' />
                <p>Учитывая то, что основная задача систем вентиляции в магазине, торговом центре - это подача, смешивание, перемещение, охлаждение/нагревание, очистка, осушение/увлажнении воздуха, используются следующие системы воздухораспределения:</p>
                <ul>
                    <li>Небольшой магазин до 400 м2 - самое простое и бюджетное решение - приточно-вытяжная вентиляция с электирческим или водяным подогревом. Система кондиционирования может быть установлена отдельно.</li>
                    <li>Торговое помещение, ТЦ площадью приближенной к значению 400м2 - возможно применение приточно-вытяжной системы вентиляции с рекуперациекй тепла, которая позволяет подогревать подаваемый воздух, а также: экономит до 80% электроэнергии, имеет минимальную вероятность поломки оборудования, проста в монтаже и обслуживании. Можно предусмотреть  и секцию со встроенным охлаждением - центрального кондиционирования, (в том случае, если это единое помещение, не разбитое на зоны, и нагрузка по посещению людей распределена равномерно).</li>
                    <li>Торговый центр с площадью свыше 400м2 - в подобных помещениях устанавливается несколько приточно-вытяжных систем, которые лучше всего применять вместе с чиллером для экономии электроэнергии,  повышения надежности системы, простоты монтажа и эксплуатации</li>
                </ul>
                <p>Монтаж системы вентиляции для магазина Вы можете заказать "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем вентиляции магазинов по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default ShopVentilation