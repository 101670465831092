import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../../../components/form/Form'
import './schoolAirConditioning.css'

import img11 from '../../../img/11_small.jpg'
import img26 from '../../../img/262186kondicioner-dlya-shkoly.png'

const SchoolAirConditioning = () => {
    return (
        <>
            <div className='school-air-conditioning-page'>
                <h1>Кондиционирование школы</h1>
                <p>Систему кондиционирования для школы Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
                <Link to='/form'>
                    <button>Отправьте заявку и получите КП</button>
                </Link>
                <img src={img11} alt='' />
                <p>Кондиционирование школы является главным элементом технического обеспечения здания. Благодаря системе проветривания, в учебном заведении комфортно находиться ученикам и преподавателям. Она отвечает за температурные показатели, воздухообмен и обеспечивает безопасность людей во время задымления.</p>
                <p>Основное назначение кондиционеров в школе состоит в создании комфортного и безопасного пребывания учеников в аудиториях и других помещениях. В учебных заведениях при этом поддерживается оптимальный микроклимат и воздухообмен для большого количества человек. А также школа должна быть оборудована системой противопожарной вентиляции и отдельными приточно-вытяжными установками в специализированных помещениях.</p>
                <p>В конце концов, конечно, учебная среда не менее важна, чем обучение. Подобно тому, как технологии и другие проекты имеют приоритет в школах - особенно с точки зрения финансирования - физический комфорт в жаркие недели учебного года должен быть одним из первых пунктов списка, когда школы рассматривают свой годовой бюджет - и не без оснований. Дело в том, что это может оказать заметное влияние на производительность и поведение ученика - то, что может повлиять на позицию, которую школа занимает в местных органах власти. Всегда было так, что лучшие школы привлекают лучшие возможности для финансирования, а школы, которые смотрят на более общую картину, когда дело доходит до создания оптимальной учебной среды, обязательно воспользуются преимуществами.</p>

                <h2>Преимущества использования кондиционера для образовательных учреждений</h2>
                <p>Правильно подобранный кондиционер для школы, детского сада или ВУЗа оказывает благотворное влияние на учебный процесс. Помимо этого система обладает целым рядом преимуществ:</p>
                <ul>
                    <li>Комфортный микроклимат в помещении. Классы в учебных заведениях или группы в детских садах предполагают одновременное нахождение большого количества людей в ограниченном пространстве. Очень быстро в таких условиях становится душно, особенно это касается регионов, где преобладает жаркий климат. Система кондиционирования обеспечит надлежащий уровень комфорта.</li>
                    <li>Более качественный процесс обучения. В прохладном помещении мозг работает продуктивнее, получаемая информация усваивается лучше. Если в аудитории жарко, организм переключается с учебного процесса на попытки охладить тело человека.</li>
                    <li>Дополнительные функции. Система кондиционирования помимо охлаждения может осуществлять обогрев помещений. Эта опция позволяет использовать кондиционер зимой. Также определенные модели обладают возможностью фильтрации, ионизации, подмеса свежего воздуха.</li>
                    <li>Повышение качества работы персонала. В классе, оборудованном охлаждающей установкой, можно проводить самые сложные занятия даже в очень жаркий сезон, что невозможно без кондиционера.</li>
                </ul>
                <img src={img26} alt='' className='img-width' />
                <p><strong>Здоровье детей</strong></p>
                <p>Школьники, регулярно подвергающиеся воздействию грязного воздуха, демонстрируют менее выдающиеся результаты в учебе. Из-за токсичных веществ не только ухудшаются оценки. Повышается риск различных заболеваний, в т.ч. дыхательной системы. Заболевший школьник вынужден пропускать уроки, из-за чего начинает отставать от одноклассников. В жаркую погоду случаев теплового удара становится больше.</p>
                <p>Поэтому так важно не только охлаждение, но и фильтрация воздуха. Чаще заменяйте фильтры, чтобы обеспечить максимальную эффективность работы климатической техники.</p>
                <p><strong>Эффективность обучения</strong></p>
                <p>Исследование, проведенное в средних школах Нью-Йорка и охватившее 4,5 млн учеников, показали зависимость эффективности учебы от температуры воздуха. Если в классе жарко, вероятность получения плохой оценки на экзамене повышается на 12,3%. Количество ошибок экспоненциально возрастает в зависимости от повышения температуры воздуха. Когда детям жарко, они работают более небрежно.</p>
                <p><strong>Сосредоточенность</strong></p>
                <p>Согласно еще одному исследованию, когда классная комната слишком горячая или холодная, мозг ученика не может сфокусироваться на поставленных перед ним задачах. Вместо этого он пытается решить, как заставить тело остыть или разогреться. Как школьник может демонстрировать успехи в образовании, когда постоянно думает о чем-то другом? Жара в данном случае становится серьезным отвлекающим фактором.</p>
                <p>Чтобы дети эффективно усваивали знания, школы должны позаботиться об установке систем кондиционирования. При этом важно учитывать правильное расположение климатической техники, чтобы потоки холодного воздуха не вредили здоровью учеников. Рекомендуется воспользоваться услугами профессиональных проектировщиков.</p>

                <h2>Требования к системе кондиционирования</h2>
                <p>Помимо обеспечения комфортных условий во время пребывания детей, учащихся и персонала в кабинете, система кондиционирования должна соответствовать законодательным нормам. Есть определенные требования, которые учитывают при проектировании:</p>
                <ul>
                    <li>В различных помещениях, в зависимости от их назначения, температура окружающего воздуха должна поддерживаться в диапазоне 18℃-24℃.</li>
                    <li>Должны отсутствовать сквозняки. Этому способствует правильная настройка оборудования.</li>
                    <li>Безопасность. Кондиционер для школы, детского сада или ВУЗа должен быть смонтирован в соответствии с СанПиН. В качестве хладагента допустимо использование экологичных и безопасных для здоровья жидкостей.</li>
                    <li>Бесшумная работа. Излишний шум и высокий уровень вибрации мешают учебному процессу. Поэтому оборудование должно работать как можно тише.</li>
                    <li>Высокая энергоэффективность. Во многих заведениях бюджет ограничен. Чтобы снизить затраты на электроэнергию можно приобрести установку инверторного типа.</li>
                </ul>
                <p>Систему кондиционирования для школы Вы можете заказать с монтажом "под ключ", позвонив по телефону в Ташкент: <a href='tel:+998903559091'>+998 (90) 355-90-91</a>. Осуществляем проектирование и поставку систем кондиционирования по Узбекистан. Письменную заявку просим Вас отправить на email <a href='mailto:fidclimat@mail.ru'>fidclimat@mail.ru</a> или через <Link to='/form'>форму</Link> на сайте.</p>
            </div>
            <Form />
        </>
    )
}

export default SchoolAirConditioning