import React, { useState } from 'react'
import ReactInputMask from 'react-input-mask'
import './form.css'

const Form = () => {

    const [fullname, setFullname] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [region, setRegion] = useState('')
    const [text, setText] = useState('')

    const submitForm = (e) => {
        e.preventDefault()

        let token = '5769747385:AAE81jiDiWekYcuIF0Wesaw7ptMB-_oFiZ8';
        let chatID = '-812079886';

        let message = `<b>📬 Online ariza</b>%0A<b>👤 Ismi: </b><i>${fullname}</i>%0A<b>📞 Tel. raqami: </b><i>${phone}</i>%0A<b>📧 Email: </b><i>${email}</i>%0A<b>🏢 Region: </b><i>${region}</i>%0A<b>📝 Text: </b><i>${text}</i>`;
        let url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatID}&text=${message}&parse_mode=html`;

        let apibot = new XMLHttpRequest();
        apibot.open("GET", url, true);
        apibot.send();
        alert(`Ваш запрос успешно отправлен`);
    }

    return (
        <form className='request-form-component' onSubmit={submitForm}>
            <h1>Отправьте заявку и получите КП</h1>
            <p>Подберем оборудование, удешевим смету, проверим проект, доставим и смонтируем в срок.</p>
            <div className='wrapper'>
                <div className='left'>
                    <div>
                        <label>Имя Фамилия</label>
                        <input
                            type="text"
                            required
                            placeholder="Имя..."
                            value={fullname}
                            onChange={(e) => setFullname(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Телефон</label>
                        <ReactInputMask
                            type="text"
                            required
                            mask="+\9\9\8 (99) 999-99-99"
                            placeholder='+998'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Эл.адрес</label>
                        <input
                            type="email"
                            required
                            placeholder="Эл.адрес"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <label>Город расположения объекта</label>
                        <input
                            type="text"
                            required
                            placeholder="Город"
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                        />
                    </div>
                </div>
                <div className='right'>
                    <label>Интересующий вас вопрос</label>
                    <textarea placeholder="Напишите..." value={text} onChange={(e) => setText(e.target.value)}></textarea>
                </div>
            </div>
            <button type='submit'>Отправить заявку</button>
        </form>
    )
}

export default Form